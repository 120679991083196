import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

import useSearchState from "../../../../hooks/useSearchState";
import { selectFilter, setValues } from "../../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../../store/reducers/search/search.types";

interface BSSelectProps {
    id: SearchStateKeys;
    label: string;
    unit?: string;
}

export function BSSelect({ id, label, unit}: BSSelectProps) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectFilter(id));

    const handleChange = (value: string) => {
        dispatch(setValues({ key: id, value: [{ value, excluded: false }] }));
    };

    return (
        <FormControl fullWidth>
            <InputLabel sx={{ fontSize: 14 }} size="small" id={id}>
                {label}
            </InputLabel>
            <Select
                id={id}
                size="small"
                label={label}
                value={values[0]?.value || ""}
                onChange={(e) => handleChange(e.target.value)}
                MenuProps={{
                    sx: {
                        "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                            fontSize: "14px",
                        },
                    },
                }}
            >
                {Object.entries(options).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                        {value} {unit}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
