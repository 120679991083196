import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import AutocompleteWithExclusion from "../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectJobFunction, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";

export function JobFunction({ id }: { id: SearchStateKeys }) {
    const { isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectJobFunction);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value,
            })
        );
    };

    return (
        <AutocompleteWithExclusion
            size="small"
            label={isEasyGrowth ? "Business function" : "Job function"}
            placeholder={isEasyGrowth ? "Business function" : "Job function"}
            value={values}
            options={Object.keys(options).map((i) => i)}
            onChange={handleChange}
            loading={isLoading}
            error={error}
        />
    );
}
