import { useDispatch } from "react-redux";

import { useOutreachParams } from "./useOutreachParams.hook";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { useAddWorkflowStepMutation } from "../../../store/apis/outreach/outreach.api";
import { AddWorkflowResponse } from "../../../store/apis/outreach/outreach.types";

type UseAddWorkflowProp = {
    successCallback?: (data: AddWorkflowResponse) => void;
};

const useAddWorkflowStep = ({ successCallback }: UseAddWorkflowProp) => {
    const dispatch = useDispatch();
    const [addWorkflowStepMutation, { ...saveMutationState }] = useAddWorkflowStepMutation();
    const { projectId } = useOutreachParams();

    const addWorkflowStep = (type: "email" | "SMS" | "follow-up" | "linkedin") => {
        addWorkflowStepMutation({
            projectId: Number(projectId),
            eventName: type,
        })
            .unwrap()
            .then((res) => {
                successCallback?.(res);
            })
            .catch((err) => {
                console.log({ err });
                dispatch(setErrorNotification("An error occured , please try after some time"));
            });
    };

    return {
        addWorkflowStep,
        saveMutationState,
    };
};

export default useAddWorkflowStep;
