import { ChipProps } from "@mui/joy/Chip";
import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";

import { ProjectFromList } from "../../store/apis/projects/projects.schema";
import { EChannel } from "../../store/apis/super-inbox/super-inbox.enums";
import { TChannel, TFilters, TFiltersState, TSentiment, TStatus } from "../../store/apis/super-inbox/super-inbox.types";
import { IProjectStatus } from "@/store/reducers/project/project.types";

export function openMessagesPane() {
    if (typeof window !== "undefined") {
        document.body.style.overflow = "hidden";
        document.documentElement.style.setProperty("--MessagesPane-slideIn", "1");
    }
}

export function closeMessagesPane() {
    if (typeof window !== "undefined") {
        document.documentElement.style.removeProperty("--MessagesPane-slideIn");
        document.body.style.removeProperty("overflow");
    }
}

export function toggleMessagesPane() {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue("--MessagesPane-slideIn");
        if (slideIn) {
            closeMessagesPane();
        } else {
            openMessagesPane();
        }
    }
}

export function openFiltersPane() {
    if (typeof window !== "undefined") {
        document.body.style.overflow = "hidden";
        document.documentElement.style.setProperty("--FiltersPane-slideIn", "1");
    }
}

export function closeFiltersPane() {
    if (typeof window !== "undefined") {
        document.documentElement.style.removeProperty("--FiltersPane-slideIn");
        document.body.style.removeProperty("overflow");
    }
}

export function toggleFiltersPane() {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue("--FiltersPane-slideIn");
        if (slideIn) {
            closeFiltersPane();
        } else {
            openFiltersPane();
        }
    }
}

export function formatMessageDate(date?: string | Dayjs | Date, showFullTime?: boolean) {
    if (!date) return "";

    const selectedDate = dayjs(date);

    if (!selectedDate.isValid()) return "invalid date!";

    const today = dayjs();
    const isToday = selectedDate.isSame(today, "day");
    const isCurrentYear = selectedDate.isSame(today, "year");

    if (showFullTime) {
        switch (true) {
            case isToday:
                return selectedDate.format("h:mm A");
            case isCurrentYear:
                return selectedDate.format("D MMM h:mm A");
            default:
                return selectedDate.format("D MMM YYYY h:mm A");
        }
    }

    switch (true) {
        case isToday:
            return selectedDate.format("h:mm A");
        case isCurrentYear:
            return selectedDate.format("D MMM");
        default:
            return selectedDate.format("D MMM YYYY");
    }
}

export function validateChannel(channel: string) {
    const validChannels: TChannel[] = [EChannel.EMAIL, EChannel.SMS, EChannel.LINKEDIN];

    if (!channel) return false;

    return validChannels.includes(channel as TChannel);
}

type applyFilterArgs = Pick<
    TFiltersState,
    "projectId" | "channels" | "status" | "sentiment" | "searchQuery" | "page" | "limit" | "userId"
>;

export function generateFilters(filtersState: applyFilterArgs) {
    const extractEnabledKeys = (filter: { [key: string]: boolean }) => {
        return Object.entries(filter)?.reduce((acc: string[], [key, value]) => {
            if (value) acc.push(key);
            return acc;
        }, []);
    };

    const filters = {
        projectIds: [filtersState.projectId].filter(Boolean),
        userId: filtersState.userId,
        channels: extractEnabledKeys(filtersState.channels) as TChannel[],
        status: extractEnabledKeys(filtersState.status) as TStatus[],
        sentiment: extractEnabledKeys(filtersState.sentiment) as TSentiment[],
        search: filtersState.searchQuery,
        start: filtersState.page,
        limit: filtersState.limit,
    };

    // console.log({ filters });
    return filters;
}

export function filtersUpdated(currFilters: TFilters, prevFilters?: TFilters): boolean {
    if (!prevFilters) return false;

    const clonedCurrFilters = { ...currFilters };
    const clonedPrevFilters = { ...prevFilters };

    // @ts-ignore
    delete clonedCurrFilters.start;
    // @ts-ignore
    delete clonedPrevFilters.start;

    for (const key in clonedCurrFilters) {
        const currFilterVal = (clonedCurrFilters as any)[key];
        const prevFilterVal = (clonedPrevFilters as any)[key];

        if (!isEqual(currFilterVal, prevFilterVal)) return true;
    }

    return false;
}

export function getProjectChipColor(status: IProjectStatus): ChipProps["color"] {
    switch (status) {
        case "ACTIVE":
            return "success";
        case "ON_HOLD":
            return "neutral";
        case "CLOSED":
            return "danger";
        default:
            return "neutral";
    }
}

export function removeUnknownTags(htmlString: string) {
    const parser = new DOMParser();

    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, "text/html");

    const allElements = doc.body.getElementsByTagName("*");
    for (let i = allElements.length - 1; i >= 0; i--) {
        const tagName = allElements[i].tagName.toLowerCase();
        // Check if the tag name is not recognized
        if (
            ![
                "a",
                "abbr",
                "acronym",
                "address",
                "applet",
                "area",
                "article",
                "aside",
                "audio",
                "b",
                "base",
                "basefont",
                "bdi",
                "bdo",
                "bgsound",
                "big",
                "blink",
                "blockquote",
                "body",
                "br",
                "button",
                "canvas",
                "caption",
                "center",
                "cite",
                "code",
                "col",
                "colgroup",
                "content",
                "data",
                "datalist",
                "dd",
                "decorator",
                "del",
                "details",
                "dfn",
                "dir",
                "div",
                "dl",
                "dt",
                "element",
                "em",
                "embed",
                "fieldset",
                "figcaption",
                "figure",
                "font",
                "footer",
                "form",
                "frame",
                "frameset",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "head",
                "header",
                "hgroup",
                "hr",
                "html",
                "i",
                "iframe",
                "image",
                "img",
                "input",
                "ins",
                "isindex",
                "kbd",
                "keygen",
                "label",
                "legend",
                "li",
                "link",
                "listing",
                "main",
                "map",
                "mark",
                "marquee",
                "menu",
                "menuitem",
                "meta",
                "meter",
                "nav",
                "nobr",
                "noframes",
                "noscript",
                "object",
                "ol",
                "optgroup",
                "option",
                "output",
                "p",
                "param",
                "picture",
                "plaintext",
                "pre",
                "progress",
                "q",
                "rp",
                "rt",
                "rtc",
                "ruby",
                "s",
                "samp",
                "script",
                "section",
                "select",
                "shadow",
                "slot",
                "small",
                "source",
                "spacer",
                "span",
                "strike",
                "strong",
                "style",
                "sub",
                "summary",
                "sup",
                "table",
                "tbody",
                "td",
                "template",
                "textarea",
                "tfoot",
                "th",
                "thead",
                "time",
                "title",
                "tr",
                "track",
                "tt",
                "u",
                "ul",
                "var",
                "video",
                "wbr",
                "xmp",
            ].includes(tagName)
        ) {
            // Remove the element if its tag name is not recognized
            allElements[i]?.parentNode?.removeChild(allElements[i]);
        }
    }

    // Return the modified HTML string
    return replaceFirstTwoNewLinesWithBr(doc.body.innerHTML);
}

function replaceFirstTwoNewLinesWithBr(text: string) {
    const newlinePattern = /\n/g;

    // Replace only the first two occurrences of newline characters with <br>
    let replacedText = text.replace(newlinePattern, "<br>");

    // Remove additional <br> tags if there are more than two consecutive <br> tags
    replacedText = replacedText.replace(/(<br>\s*){3,}/g, "<br>");

    return replacedText;
}

export function sortProjectsByStatus(projects?: ProjectFromList[]) {
    const statusOrder: any = { ACTIVE: 1, ON_HOLD: 2, CLOSED: 3 };
    const cloned = Array.from(Array.isArray(projects) ? projects : []);
    return cloned.sort((a, b) => statusOrder[a.dashboardStatus] - statusOrder[b.dashboardStatus]);
}
