import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FileWithPath } from "react-dropzone";
import { NavigateFunction } from "react-router-dom";

import { TCVSource } from "../../../components/Navbar/CreateProject/CVSource";

import { SubmitValuePropositionPayload } from "../../../easy-growth/components/AddValueProposition";
import { RootState } from "../..";
import { IActionPayload } from "../loaders.reducer";

import { IMarketing, IProject } from "@/store/reducers/project/project.types";

export interface Option {
    label: string;
    value: string;
}

export type ICreateProjectErrors = {
    projectName?: string;
    projectJd?: string;
    liCookie?: string;
};

type IRootState = {
    projectName: string;
    purpose: string;
    projectJd: File | null;
    csvFile: FileWithPath | null;
    liCookie: string;
    detailScrape: boolean;
    canShowAddProject: boolean;
    canShowIntegratedProjectModal: boolean;
    canScrapeEmail: boolean;
    errors: ICreateProjectErrors;
    canShowUpdateJd: boolean;
    isSalesNav: boolean;
    showCsvHeaderDialog: boolean;
    unlockTalentMarketing: boolean;
    intent: undefined | string;
    showVettingCriteriaText: boolean;
};

export type ICreateJdProjectPayload = {
    navigate: NavigateFunction;
    mappedCsvHeaders?: any;
    objective?: string;
    platform?: string;
    callback?: any;
    bdrRegions?: any;
};

export type IUpdateMarketingProjectPayload = {
    projectId: string | number;
    marketing: IMarketing;
};

export type ICreateProjectResponse = {
    message: string;
    data: IProject;
};

export interface UploadCSVPayload {
    projectId: string;
    onSuccess?: () => void;
    mappedCsvHeaders?: any;
    cvSource?: TCVSource;
}

export type CreateProjectPayload = PayloadAction<IActionPayload & ICreateJdProjectPayload>;

export type MarketingMode = "ON" | "OFF";

const initialState: IRootState = {
    projectName: "",
    purpose: "",
    projectJd: null,
    csvFile: null,
    liCookie: "",
    detailScrape: false,
    canShowAddProject: false,
    canShowIntegratedProjectModal: false,
    errors: {
        projectName: "",
        projectJd: "",
        liCookie: "",
    },
    canScrapeEmail: false,
    canShowUpdateJd: false,
    isSalesNav: false,
    showCsvHeaderDialog: false,
    unlockTalentMarketing: false,
    intent: "enhance",
    showVettingCriteriaText: false,
};

const reducers = {
    addContactListToProject(_state: IRootState, _action: PayloadAction<string>) {},
    setShowCsvHeaderDialog(state: IRootState, { payload }: { payload: boolean }) {
        state.showCsvHeaderDialog = payload;
    },
    submitValuePropositionInputs(_state: IRootState, _action: SubmitValuePropositionPayload) {},
    setErrors(state: IRootState, { payload }: { payload: any }) {
        state.errors = payload;
    },
    setCanShowAddProject(state: IRootState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.errors = {
                projectName: "",
                projectJd: "",
                liCookie: "",
            };
        }
        state.canShowAddProject = payload;
    },
    setCanShowIntegratedProjectModal(state: IRootState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.errors = {
                projectName: "",
                projectJd: "",
                liCookie: "",
            };
        }
        state.canShowIntegratedProjectModal = payload;
    },
    setProjectName(state: IRootState, { payload }: { payload: string }) {
        state.projectName = payload;
        state.errors.projectName = "";
    },
    setPurpose(state: IRootState, { payload }: { payload: string }) {
        state.purpose = payload;
    },
    setProjectJd(state: IRootState, { payload }: { payload: File }) {
        state.projectJd = payload;
        state.errors.projectJd = "";
    },
    setCsvFile(state: IRootState, { payload }: { payload: FileWithPath | null }) {
        state.csvFile = payload;
    },
    setDetailScrape(state: IRootState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.errors.liCookie = "";
        }
        state.detailScrape = payload;
    },
    setCanScrapeEmail(state: IRootState, { payload }: { payload: boolean }) {
        state.canScrapeEmail = payload;
    },
    setLiCookie(state: IRootState, { payload }: { payload: string }) {
        state.liCookie = payload;
    },
    createProject(_state: IRootState, _action: CreateProjectPayload) {},
    updateMarketingProject(_state: IRootState, _action: PayloadAction<IUpdateMarketingProjectPayload>) {},
    uploadCsv(_state: IRootState, _action: PayloadAction<UploadCSVPayload>) {},
    setCanShowUpdateJd(state: IRootState, { payload }: { payload: boolean }) {
        state.canShowUpdateJd = payload;
        state.canShowAddProject = payload;
    },
    updateJd(_state: IRootState, _action: PayloadAction<{ objective?: string; platform?: string }>) {},
    setIsSalesNav(state: IRootState, { payload }: { payload: boolean }) {
        state.isSalesNav = payload;
    },
    resetState(_state: IRootState) {
        return initialState;
    },
    leavePage(_state: IRootState) {},
    setTalentMarketing(state: IRootState, action: { payload: boolean }) {
        state.unlockTalentMarketing = action.payload;
    },
    setIntent(state: IRootState, { payload }: { payload: string }) {
        state.intent = payload;
    },
    setVettingCriteriaText(state: IRootState, { payload }: { payload: boolean }) {
        state.showVettingCriteriaText = payload;
    },
};

export const page = createSlice({
    name: "createProject",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    addContactListToProject,
    submitValuePropositionInputs,
    setErrors,
    setProjectName,
    setPurpose,
    setCsvFile,
    setLiCookie,
    createProject,
    setDetailScrape,
    setCanScrapeEmail,
    uploadCsv,
    setCanShowAddProject,
    setCanShowIntegratedProjectModal,
    resetState,
    setProjectJd,
    setCanShowUpdateJd,
    updateJd,
    setIsSalesNav,
    leavePage,
    setShowCsvHeaderDialog,
    setTalentMarketing,
    setIntent,
    updateMarketingProject,
    setVettingCriteriaText,
} = page.actions;

export const selectCreateProjectName = (state: RootState) => state.createProject.projectName;
export const selectDetailScrape = (state: RootState) => state.createProject.detailScrape;
export const selectCanShowAddProject = (state: RootState) => state.createProject.canShowAddProject;
export const selectCanShowIntegratedProjectModal = (state: RootState) =>
    state.createProject.canShowIntegratedProjectModal;
export const selectCanShowUpdateJd = (state: RootState) => state.createProject.canShowUpdateJd;
export const selectErrors = (state: RootState) => state.createProject.errors;
export const selectCsvFile = (state: RootState) => state.createProject.csvFile;
export const selectProjectName = (state: RootState) => state.createProject.projectName;
export const selectShowCsvHeaderDialog = (state: RootState) => state.createProject.showCsvHeaderDialog;
export const selectUnlockTalentMarketing = (state: RootState) => state.createProject.unlockTalentMarketing;
export const selectIntent = (state: RootState) => state.createProject.intent;
export const selectVettingCriteriaText = (state: RootState) => state.createProject.showVettingCriteriaText;
export const selectPurpose = (state: RootState) => state.createProject.purpose;
