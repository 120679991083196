import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import useSearchState from "../../../../hooks/useSearchState";
import { selectFilter, setValues } from "../../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../../store/reducers/search/search.types";

// Define props for the reusable component
interface BSRangeProps {
    id: SearchStateKeys;
    label: string;
    startLabel?: string;
    endLabel?: string;
}

// Reusable BSRange Component with numerical input fields
export function BSRange({ id, label, startLabel = "From", endLabel = "To" }: BSRangeProps) {
    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectFilter(id));

    const defaultValue = { value: "", excluded: false };

    const [start, end] = [
        { ...defaultValue, ...values[0] },
        { ...defaultValue, ...values[1] },
    ];

    const handleChangeStartValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        start.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    const handleChangeEndValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        end.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    return (
        <FormControl component={Box} fullWidth>
            <FormLabel sx={{ mb: 1, fontSize: 14 }}>{label}</FormLabel>
            <Box display="flex" alignItems="center" gap={1}>
                <TextField
                    type="number"
                    fullWidth
                    size="small"
                    value={start.value}
                    onChange={handleChangeStartValue}
                    placeholder={startLabel}
                    InputProps={{ sx: { fontSize: 14 } }}
                />
                <span>-</span>
                <TextField
                    type="number"
                    fullWidth
                    size="small"
                    value={end.value}
                    onChange={handleChangeEndValue}
                    placeholder={endLabel}
                    InputProps={{ sx: { fontSize: 14 } }}
                />
            </Box>
        </FormControl>
    );
}
