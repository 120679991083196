import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/joy/Tooltip";
import { ReactNode, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { AutoPilotTable } from "./AutoPilotTable";

import useOnClickOutside from "../../hooks/useClickOutside";
import {
    useGetPrevAutoPilotDataQuery,
    useGetProjectAutopilotDataQuery,
} from "../../store/apis/autopilot/autopilot.api";
import { TAutoPilotStats } from "../../store/apis/autopilot/autopilot.types";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { JoyProvider } from "../JoyProvider";
import Loader from "../Loader/Loader";

interface AutoPilotTableWrapperProps {
    data?: TAutoPilotStats[];
    isLoading: boolean;
    isFetching: boolean;
    enableActionHeader: boolean;
    children?: ReactNode;
}

function AutoPilotTableWrapper(props: AutoPilotTableWrapperProps) {
    const { data, isLoading, isFetching, children, ...rest } = props;

    if (isLoading) {
        return (
            <div className="flex flex-row ">
                <div className="mr-2">
                    <Loader size="sm" />{" "}
                </div>
                <div className="mt-0.5">
                    <span> Loading searches...</span>
                </div>
            </div>
        );
    }

    if (rest.enableActionHeader && !data?.length) {
        return <span>No searches found!</span>;
    }

    return (
        <div className="flex flex-col gap-1">
            {data?.map((profile, index) => (
                <AutoPilotTable key={index} data={profile} isLoading={isFetching} {...rest} />
            ))}
            {children}
        </div>
    );
}

function AutoPilotPrevTables() {
    const { id } = useParams();
    const { data, ...rest } = useGetPrevAutoPilotDataQuery(Number(id));

    return <AutoPilotTableWrapper data={data} enableActionHeader={false} {...rest} />;
}

function AutoPilotTables() {
    const { id } = useParams();
    const { data, ...rest } = useGetProjectAutopilotDataQuery(Number(id), { refetchOnMountOrArgChange: true });

    return (
        <div className="flex flex-col gap-1">
            <AutoPilotTableWrapper data={data} enableActionHeader={true} {...rest}>
                <AutoPilotPrevTables /> {/* Render previous tables after current table is mounted */}
            </AutoPilotTableWrapper>
        </div>
    );
}

export function AutoPilot() {
    const { id } = useParams();
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useOnClickOutside(ref, () => setIsOpen(false));

    if (!id) return null;

    return (
        <ErrorBoundary>
            <JoyProvider>
                <Tooltip
                    open={isOpen}
                    ref={ref}
                    sx={{ maxWidth: "55vw", maxHeight: "80vh", overflowY: "scroll" }}
                    title={<AutoPilotTables />}
                    placement="bottom-start"
                    variant="soft"
                >
                    <button
                        onMouseEnter={() => setIsOpen(true)}
                        onClick={() => setIsOpen((open) => !open)}
                    >
                        <InfoOutlined style={{ fontSize: "22px" }} htmlColor="#6c6c6c" />
                    </button>
                </Tooltip>
            </JoyProvider>
        </ErrorBoundary>
    );
}
