import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/Save";
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseModal from "./BaseModal";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { CustomTab, CustomTabsContainer } from "../../../components/CustomTabs";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { LINKED_CONN_REQ_WORD_LIMIT } from "../../../utils/Constants";
import { isWordsLimitExceeded } from "../../../utils/helper";
import CustomMUIInput from "../../project/components/CustomMUIInput";

import { createNewTemplate, editTemplate, selectTemplateListPerType } from "@/store/reducers/template/template.reducer";
import { TemplateTypes } from "@/store/reducers/template/template.types";

const tabStyle = {
    padding: "0 0.5rem",
    height: "fit-content",
    minHeight: "33px",
    margin: "5px",
    borderRadius: "2.5rem",
    fontSize: 14,
    flex: 1,
};

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Stack mt={1} mb={2}>
                    {children}
                </Stack>
            )}
        </div>
    );
}

interface ISaveTemplateProps {
    type: TemplateTypes;
    subject?: string;
    editorVal?: string;
}

export default function SaveTemplate(props: ISaveTemplateProps) {
    const { type, subject, editorVal } = props;

    const dispatch = useDispatch();
    const templateList = useSelector(selectTemplateListPerType(type));
    const isTemplateSaving = useSelector(checkIfLoading(createNewTemplate.type));
    const isTemplateUpdating = useSelector(checkIfLoading(editTemplate.type));

    const [title, setTitle] = useState("");
    const [templateId, setTemplateId] = useState("");
    const [mode, setMode] = useState(0);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);

    const isLoading = isTemplateSaving || isTemplateUpdating;

    const showTemplateModal = () => {
        if (type === "CONNECTION_REQUEST" && editorVal) {
            const isMoreWords = isWordsLimitExceeded(editorVal, LINKED_CONN_REQ_WORD_LIMIT);

            if (isMoreWords) {
                dispatch(setErrorNotification(`Please keep the word count under ${LINKED_CONN_REQ_WORD_LIMIT}`));
                return;
            }
        }

        if ((type === "EMAIL" || type === "INMAIL") && !subject) {
            dispatch(setErrorNotification("Subject can't be empty"));
            return;
        }

        if (!editorVal?.replace(/<[^>]*>/g, "")) {
            dispatch(setErrorNotification("Body can't be empty"));
            return;
        }

        setOpenTemplateModal(true);
    };

    const closeTemplateModal = () => {
        setTitle("");
        setTemplateId("");
        setOpenTemplateModal(false);
    };

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string;
        setTemplateId(value);
    };

    function handleSave() {
        if (!editorVal) {
            return;
        }

        if (mode === 0) {
            if (!title) {
                dispatch(setErrorNotification("Template name can't be empty"));
                return;
            }
            dispatch(
                createNewTemplate({
                    name: title,
                    body: editorVal,
                    type,
                    subject,
                    onSuccess: closeTemplateModal,
                })
            );
        } else {
            if (!templateId) {
                dispatch(setErrorNotification("Select template to be updated"));
                return;
            }
            const templateName = templateList.find((item) => item._id === templateId)?.name || "";
            dispatch(
                editTemplate({
                    name: templateName,
                    body: editorVal,
                    type,
                    subject,
                    active: true,
                    _id: templateId,
                    onSuccess: closeTemplateModal,
                })
            );
        }
    }

    return (
        <>
            <Tooltip title="Save as Template">
                <IconButton onClick={showTemplateModal}>
                    <SaveIcon
                        style={{
                            color: "#0891b2",
                        }}
                    />
                </IconButton>
            </Tooltip>
            {openTemplateModal && (
                <BaseModal hideCloseButton={true} onClose={closeTemplateModal}>
                    <Stack spacing={2}>
                        <Typography fontSize={18} fontWeight={500} color="#334d6e">
                            Save Template
                        </Typography>
                        <CustomTabsContainer
                            value={mode}
                            onChange={(_, val) => setMode(val)}
                            sx={{
                                borderRadius: "2.5rem",
                                minHeight: "fit-content",
                                width: "100%",
                            }}
                        >
                            <CustomTab
                                label="Create New"
                                icon={<AddRoundedIcon fontSize="small" />}
                                iconPosition="start"
                                sx={tabStyle}
                            />
                            <CustomTab
                                label="Update Existing"
                                icon={<EditRoundedIcon fontSize="small" />}
                                iconPosition="start"
                                sx={tabStyle}
                            />
                        </CustomTabsContainer>

                        <TabPanel value={mode} index={0}>
                            <CustomMUIInput
                                fullWidth
                                placeholder="Name of your template"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </TabPanel>
                        <TabPanel value={mode} index={1}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Choose template</InputLabel>
                                <Select value={templateId} label="Choose template" onChange={handleChange}>
                                    {templateList.map(({ _id, name }) => (
                                        <MenuItem value={_id}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TabPanel>
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                            <Button variant="outlined" onClick={closeTemplateModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" disabled={isLoading} onClick={handleSave}>
                                <ButtonTextWithLoading text={mode === 0 ? "Create" : "Update"} isLoading={isLoading} />
                            </Button>
                        </Stack>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}
