import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EditLogo from "./EditLogo";
import UploadVideo from "./UploadVideo";

import { checkIfLoading } from "../../../../../store/reducers/loaders.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import styles from "../MarketingPage.module.css";

import {
    createProject,
    selectCanShowUpdateJd,
    selectCsvFile,
    selectIntent,
    selectUnlockTalentMarketing,
    setShowCsvHeaderDialog,
    updateJd,
    updateMarketingProject,
} from "@/store/reducers/create-project/CreateProject.reducer";
import {
    resetState,
    selectCompanyLogo,
    selectCompanyVideo,
    selectCompanyVideoLink,
    selectMarketingProjectId,
    selectOverview,
    selectShowUploadMarketingCompanyPage,
    setMarketingOverview,
    setShowUploadMarketingCompanyPage,
} from "@/store/reducers/marketing-page/MarketingPage.reducer";


const UploadMarketingPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showUploadCompanyPage = useSelector(selectShowUploadMarketingCompanyPage);
    const isUnlock = useSelector(selectUnlockTalentMarketing);
    const intent = useSelector(selectIntent);
    const video = useSelector(selectCompanyVideo) ?? "";
    const videoLink: string | null = useSelector(selectCompanyVideoLink) ?? "";
    const logo = useSelector(selectCompanyLogo) ?? "";
    const csvFile = useSelector(selectCsvFile);
    const canShowUpdateJd = useSelector(selectCanShowUpdateJd);
    const isProjectCreating = useSelector(checkIfLoading(createProject.type));
    const overview = useSelector(selectOverview);
    const projectId = useSelector(selectMarketingProjectId);
    const isProjectUpdating = useSelector(checkIfLoading(updateMarketingProject.type));

    const [editOverview, setEditOverview] = useState(false);
    const [showEditLogoPopup, setShowEditLogoPopup] = useState(false);
    const [showEditVideoPopup, setShowEditVideoPopup] = useState(false);
    const [marketingLinkId, setMarketingLinkId] = useState<number | null>(projectId ?? null);

    const isEdit = Boolean(projectId) || Boolean(marketingLinkId);

    const handleOnClick = () => {
        if (!overview && !video && !videoLink && !logo) {
            dispatch(setErrorNotification("Atleast one of the field is required!"));
        }
        switch (true) {
            case canShowUpdateJd:
                dispatch(updateJd({}));
                break;
            case !!csvFile:
                dispatch(setShowCsvHeaderDialog(true));
                break;
            case isEdit:
                if (projectId || marketingLinkId) {
                    dispatch(
                        updateMarketingProject({
                            projectId: projectId || (marketingLinkId as number),
                            marketing: {
                                logo,
                                video,
                                videoLink,
                                overview,
                            },
                        })
                    );
                }
                break;
            default:
                dispatch(
                    createProject({
                        action: createProject.type,
                        navigate,
                        callback: (id: number) => {
                            setMarketingLinkId(id);
                        },
                    })
                );
                break;
        }
    };

    const handleClose = () => {
        dispatch(setShowUploadMarketingCompanyPage(false));
        if (marketingLinkId) {
            setTimeout(() => {
                navigate("/projects/" + marketingLinkId);
            }, 1500);
        }
        dispatch(resetState({}));
    };

    return (
        <Dialog
            open={showUploadCompanyPage}
            fullWidth
            onClose={handleClose}
            classes={{
                paper: styles.uploadDialog,
            }}
        >
            <DialogTitle sx={{ padding: "20px 40px 25px 40px" }}>
                <Grid container>
                    <Grid item xs={11} sx={{ alignItems: "center", display: "flex" }}>
                        <Typography className={styles.uploadTitle} sx={{ fontSize: "24px !important" }}>
                            {isEdit ? "Update" : "Create"} talent marketing page
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ alignItems: "center", display: "flex", justifyContent: "end" }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ padding: "40px" }}>
                <Stack flexDirection="row">
                    <Stack width="100%">
                        <Stack flexDirection="row" alignItems="center">
                            {logo ? (
                                <img
                                    alt="projectLogo"
                                    src={logo}
                                    height={40}
                                    width={40}
                                    className={styles.companyLogo}
                                    style={{ marginLeft: 10 }}
                                />
                            ) : (
                                <Typography className={styles.uploadLogoText} variant="button">
                                    Upload company logo*
                                </Typography>
                            )}
                            <Tooltip title="Edit logo">
                                <IconButton aria-label="delete" onClick={() => setShowEditLogoPopup(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Typography className={styles.uploadTitle} variant="h5">
                            Welcome
                        </Typography>
                        <Typography className={styles.uploadTitle} variant="h6">
                            Company Overview
                            {editOverview ? (
                                <Button
                                    variant="outlined"
                                    sx={{
                                        marginLeft: "10px",
                                        width: "fit-content",
                                        alignSelf: "flex-end",
                                        padding: "0px",
                                    }}
                                    onClick={() => setEditOverview(false)}
                                >
                                    Save
                                </Button>
                            ) : (
                                <IconButton aria-label="delete" onClick={() => setEditOverview(true)}>
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Typography>
                        {/* {!!editOverview ? ( */}
                        <TextField
                            value={overview}
                            multiline
                            rows={4}
                            onChange={(e) => dispatch(setMarketingOverview(e.target.value))}
                        />
                        {/* ) : (
                            <></>
                        )} */}
                    </Stack>
                    <Stack pl="20px" width="100%" justifyContent="space-between">
                        {video && (
                            <video key={video} controls style={{ maxWidth: "100%" }}>
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {videoLink && (
                            <iframe
                                src={videoLink}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            />
                        )}
                        <Button
                            variant="outlined"
                            sx={{
                                marginBottom: "50px",
                                marginTop: "50px",
                                width: "fit-content",
                                alignSelf: "center",
                            }}
                            onClick={() => setShowEditVideoPopup(true)}
                        >
                            {video || videoLink ? "Update video" : "Upload video"}
                        </Button>
                    </Stack>
                </Stack>
                {showEditLogoPopup && <EditLogo onClose={() => setShowEditLogoPopup(false)} />}
                {showEditVideoPopup && <UploadVideo onClose={() => setShowEditVideoPopup(false)} />}
            </DialogContent>
            <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
                <Stack flexDirection="row" justifyContent="flex-end" width="100%">
                    <Button
                        variant="outlined"
                        sx={{
                            marginRight: "10px",
                            width: "fit-content",
                            alignSelf: "flex-end",
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            width: "fit-content",
                            alignSelf: "flex-end",
                        }}
                        onClick={handleOnClick}
                    >
                        {isProjectUpdating || isProjectCreating ? "Saving...." : "Save"}
                    </Button>
                </Stack>
                {isEdit && (
                    <>
                        <Box
                            sx={{
                                width: "100%",
                                py: 2,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <a href={`/marketingPage/${marketingLinkId}`}>
                                <Typography sx={{ fontSize: 16 }} variant="body2">
                                    Your Talent Marketing Page link{" "}
                                </Typography>
                            </a>
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(
                                        `${window.location.origin}/marketingPage/${marketingLinkId}`
                                    );
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                py: 2,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}
                        >
                            {!projectId && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "fit-content",
                                        alignSelf: "flex-end",
                                    }}
                                    onClick={() => {
                                        if (isUnlock) {
                                            if (intent === "enhance") {
                                                dispatch(
                                                    setSuccessNotification(
                                                        "Former employees at your company will be added to this project over the next 24 hours"
                                                    )
                                                );
                                            } else {
                                                dispatch(
                                                    setSuccessNotification(
                                                        "Your current employees will be added to this project over the next 24 hours"
                                                    )
                                                );
                                            }
                                        }
                                        handleClose();
                                    }}
                                >
                                    Complete project setup
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UploadMarketingPage;
