import { alpha, styled } from "@mui/material";
import TextField from "@mui/material/TextField";

const CustomMUIInput = styled(TextField)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        fontFamily: "Poppins",
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#0891b2" : "#0891b2",
        fontSize: 14,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            boxShadow: `${alpha("#0891b2", 0.25)} 0 0 0 0.2rem`,
            borderColor: "#0891b2", // Changed to directly use the color code instead of theme.palette.primary.main
        },
    },
}));

export default CustomMUIInput;
