import jwt_decode from "jwt-decode";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import store from "..";
import {
    doNotCallAction,
    listenExtension,
    resetSSE,
    setIsExtensionInstalled,
    setSSECandidate,
} from "@/store/reducers/app-container/AppContainer.reducer";
import {
    HQ_EASYSOURCE_EXTENSION,
    HQ_EXTENSION_TOKEN,
    INSIGHTS_GENERATION_RESULT,
    LINKEDIN_PER_PROFILE_RESULT,
    SCRAP_REQ_SEARCH_RESULTS,
    STAR_RATING_RESULT,
} from "../../utils/Constants";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";

import { getProject, setProject } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    addSSECandidateDetails,
    setCandidate,
    setSelectAllCandidates,
    setSingleCandidateInsightGenerating,
} from "@/store/reducers/project/project.reducer";
import { ISSEEventPayload } from "@/store/reducers/project/project.types";

function* setSSECandidateSaga({ payload }: { payload: ISSEEventPayload }): SagaIterator {
    try {
        const state = yield select();
        const currentProject = get(state, "allProjects.project");
        const currentProjectId = !isEmpty(currentProject) ? currentProject._id : null;
        const { event, projectStatus, processCompleted, projectId, projectData } = payload;
        const isStatusComplete = projectStatus === "COMPLETED";
        const isLiProfileResult = event === LINKEDIN_PER_PROFILE_RESULT;
        const isScrapListResult = event === SCRAP_REQ_SEARCH_RESULTS;
        const isInsightsGenerationResult = event === INSIGHTS_GENERATION_RESULT;
        const isStarRatingResult = event === STAR_RATING_RESULT;

        if (currentProjectId !== Number(projectId)) return;

        if (isStatusComplete || processCompleted || isScrapListResult) {
            yield put(
                getProject({
                    projectId: String(projectId),
                    action: getProject.type,
                    doNotShowLoader: true,
                })
            );
            yield put(setSingleCandidateInsightGenerating({ candidateId: null }));
            return;
        }
        if (!isEmpty(payload)) {
            if (isLiProfileResult) {
                yield put(addSSECandidateDetails(payload));
            }
            if (isStarRatingResult) {
                yield put(setCandidate(payload));
            }
        }
        if (isInsightsGenerationResult && !isEmpty(projectData)) {
            yield put(setProject(projectData));
        }
        yield put(setSelectAllCandidates(false));
    } catch (err: unknown) {
        console.error("***setSSECandidateSagaError=", err);
        handleException(err);
    }
}

/* eslint-disable */
function* listenExtensionSaga(): SagaIterator {
    try {
        window.addEventListener(
            "message",
            (event) => {
                const { from, type, data } = event.data;
                const isEventFromHqExtension = from === HQ_EASYSOURCE_EXTENSION;

                if (!isEventFromHqExtension) return;
                const isExtTokenEvent = type === HQ_EXTENSION_TOKEN;
                if (isExtTokenEvent) {
                    const token: { linkedInId: string } = jwt_decode(data);
                    const { linkedInId } = token || {};
                    if (!linkedInId) return;

                    document.cookie = `accessToken=${data}`;
                    store.dispatch(setIsExtensionInstalled(true));
                }
            },
            false
        );
    } catch (err: unknown) {
        console.error("***listenExtensionSagaError=", err);
        handleException(err);
    }
}
/* eslint-enable */

export default function* rootSagas(): Generator<ForkEffect> {
    const tasks = [
        // @ts-ignore
        yield takeLatest(setSSECandidate.type, setSSECandidateSaga),
        // @ts-ignore
        yield takeLatest(listenExtension.type, listenExtensionSaga),
    ];
    //@ts-ignore
    yield takeLatest(doNotCallAction.type, CancelSagas, tasks);
    // @ts-ignore
    yield takeLatest(resetSSE.type, CancelSagas, tasks);
}
