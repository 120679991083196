import React from "react";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { Box, Divider, IconButton } from "@mui/material";

import CallBlockingModal from "../../CallBlockingModal";

const SecurityAndPrivacy: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);

    return (
        <>
            <div style={{ marginTop: 10, fontSize: 16 }}>Security & Privacy</div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <Box component="div">
                    <Box component="div" sx={{ marginTop: "10px", fontSize: 14 }}>
                        {" "}
                        Call Blocking{" "}
                    </Box>
                    View and manage the list of blocked numbers.
                </Box>
                <Box component="div" sx={{ fontSize: 14 }} onClick={() => setModalOpen(true)}>
                    <ArrowForwardOutlined />
                </Box>
            </Box>
            <Divider />
            <Box sx={{ fontSize: 14 }}>
                <div style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}> Terms and Conditions </div>
                While using the calling feature you agree to Twilio terms and conditions which can be accessed here at{" "}
                <a
                    href="https://www.twilio.com/en-us/legal/aup"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0891b2" }}
                >
                    Acceptable Use Policy.
                </a>
            </Box>
            <CallBlockingModal open={modalOpen} onClose={() => setModalOpen(false)} />
        </>
    );
};

export default SecurityAndPrivacy;
