import { alpha } from "@mui/material";
import { blue } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";

import CustomButton from "../../../components/CustomButton";
import useMenu from "../../../hooks/useMenu";
import PersonalizedTagsMenu from "../molecules/PersonalizedTagsMenu";
import { useTemplateEditorValues } from "../TemplateEditorProvider";

export function PersonalizedMenu() {
    const { actions, customField, defaultCustomActions, signatureAction, rb2bAction } = useTemplateEditorValues();
    const { handleClick, handleClose, getMenuProps: getDefaultMenuProps } = useMenu();

    const { customFieldActions, isLoadingCustomFieldData, isErrorCustomFieldData } = customField;

    return (
        <>
            <div>
                <Tooltip title="Add personalization tags">
                    <CustomButton
                        onClick={handleClick}
                        variant="outlined"
                        sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(13),
                            padding: "0.4rem 1rem",
                            borderRadius: "0.3rem",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: "#0891B2",
                            "&:hover": {
                                opacity: 1,
                                backgroundColor: "#0891B2",
                                color: "white",
                            },
                            alignSelf: "flex-end",
                        })}
                    >
                        Personalize
                    </CustomButton>
                </Tooltip>
            </div>
            <PersonalizedTagsMenu
                actions={actions}
                defaultCustomActions={defaultCustomActions}
                customActions={customFieldActions}
                isLoadingCustomFieldData={isLoadingCustomFieldData}
                rb2bAction={rb2bAction}
                isErrorCustomFieldData={isErrorCustomFieldData}
                signatureAction={signatureAction}
                menuProps={{
                    ...getDefaultMenuProps(),
                    onClose: handleClose,
                }}
            />
        </>
    );
}
