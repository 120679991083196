import { Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
    selectCandidates,
    selectCandidatesFetchStatus,
    selectTotalCandidates,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import AddListToProject from "../AddListToProject";
import CandidateCard from "../CandidateCard";
import { DisplayMessage } from "../molecules/DisplayMessage";
import { TypingLoader } from "../molecules/TypingLoader";

export function CandidateList() {
    const candidates = useSelector(selectCandidates);
    const totalCandidates = useSelector(selectTotalCandidates);
    const candidatesFetchStatus = useSelector(selectCandidatesFetchStatus);
    const projectId = useSearchParams()[0].get("project");
    if (candidatesFetchStatus === "IDLE") {
        return null;
    }

    if (candidatesFetchStatus === "LOADING") {
        return <TypingLoader />;
    }

    return (
        <Stack height="100%">
            <Stack
                bgcolor="white"
                sx={{ height: "calc(100vh - 155px)" }}
                borderRadius={2}
                p={2}
                gap={1}
                border="1px solid rgba(0,0,0,0.1)"
            >
                {candidates?.length ? (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography fontWeight={600}>Candidates Preview</Typography>
                            </Stack>
                            <Typography component={"i"} variant="caption">
                                {totalCandidates} {projectId && `Net new candidates`}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack sx={{ overflow: "auto" }} gap={1}>
                            {candidates?.map((candidate) => (
                                <CandidateCard  {...candidate} key={candidate._id} />
                            ))}
                        </Stack>
                    </>
                ) : (
                    <DisplayMessage text="No candidates found for this search." />
                )}
            </Stack>
            <Stack mt={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <AddListToProject />
            </Stack>
        </Stack>
    );
}
