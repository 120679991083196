import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useSelector } from "react-redux";

import useSearchState from "@/hooks/useSearchState";
import { selectFilter, setValues } from "@/store/reducers/search/search.slice";
import { SearchStateKeys } from "@/store/reducers/search/search.types";

function valuetext(value: number) {
    return `${value}`;
}

interface BSRangeSliderProps {
    min?: number;
    max?: number;
    id: SearchStateKeys;
    label?: string;
}

function BSRangeSlider(props: BSRangeSliderProps) {
    const { min = 0, max = 100, id, label = "Select range" } = props;

    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectFilter(id));

    const value = [
        typeof values[0]?.value === "number" ? values[0]?.value : 0,
        typeof values[1]?.value === "number" ? values[1]?.value : 0,
    ];

    const handleChange = (_e: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            const nextValue = [Math.min(newValue[0], value[1] - min), value[1]];
            dispatch(
                setValues({
                    key: id,
                    value: nextValue.map((value) => ({ value, excluded: false })),
                })
            );
        } else {
            const nextValue = [value[0], Math.max(newValue[1], value[0] + min)];
            dispatch(
                setValues({
                    key: id,
                    value: nextValue.map((value) => ({ value, excluded: false })),
                })
            );
        }
    };

    return (
        <Box>
            <Typography mb={1} fontSize={14} fontWeight={400}>
                {label} {value[1] ? `(${value[0]} - ${value[1]})` : ""}
            </Typography>
            <Box px={1}>
                <Slider
                    getAriaLabel={() => "Experience range"}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    min={min}
                    max={max}
                    disableSwap
                />
            </Box>
        </Box>
    );
}

export default BSRangeSlider;
