import SensorOccupiedRoundedIcon from "@mui/icons-material/SensorOccupiedRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { isUndefined } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PersonalizedTagsMenu from "./PersonalizedTagsMenu";

import useMenu from "../../../hooks/useMenu";
import usePersonalizeValues from "../../../hooks/usePersonalizeValues";
import BaseModal from "../components/BaseModal";
import { EmptyTemplatePlaceholder } from "../components/HyperPersonalizationPane";

import { selectEnableInMailReminderMsg, setEnableInMailReminderMsg } from "@/store/reducers/template/template.reducer";
import { changeCustomBody, selectBaseTemplatesCustomInMailReminderMsg } from "@/store/reducers/trigger-workflow/customTemplates.slice";


export default function EditInMailReminderMessage() {
    const dispatch = useDispatch();
    const inMailReminderMsg = useSelector(selectBaseTemplatesCustomInMailReminderMsg);
    const enableInMailReminderMsg = useSelector(selectEnableInMailReminderMsg);
    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const body = inMailReminderMsg?.body;

    const {
        actions,
        subjectRef,
        calendly: { calendlyActions, showCalendlyMenu },
    } = usePersonalizeValues({
        handleBodyChange(cursorPosition, label) {
            if (body) {
                const newBody = body.substring(0, cursorPosition) + label + body.substring(cursorPosition);
                dispatch(
                    changeCustomBody({
                        key: "inMailReminderMsg",
                        body: newBody,
                    })
                );
            }
        },
    });

    const {
        handleClick: handleCalendlyClick,
        getMenuProps: getCalendlyMenuProps,
        handleClose: handleCalendlyMenuClose,
    } = useMenu();

    const [openEditModal, setOpenEditModal] = useState(false);

    if (isUndefined(body)) {
        return <EmptyTemplatePlaceholder />;
    }

    function closeEditModal() {
        setOpenEditModal(false);
    }

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCustomBody({
                key: "inMailReminderMsg",
                body: value,
            })
        );
    };

    const handleReminderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        dispatch(setEnableInMailReminderMsg(value));
        if (value) {
            setOpenEditModal(true);
        }
    };

    return (
        <>
            <Tooltip
                arrow
                title="This message will be sent when someone accepts your InMail but does not reply to you."
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={enableInMailReminderMsg}
                            onChange={handleReminderChange}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    }
                    sx={{
                        "& .MuiFormControlLabel-label": {
                            fontSize: 14,
                        },
                    }}
                    label={`${enableInMailReminderMsg ? "Disable" : "Enable"} InMail reminder message`}
                />
            </Tooltip>
            {openEditModal && (
                <BaseModal overlayStyles={{ minWidth: 500 }} hideCloseButton={true} onClose={closeEditModal}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                                Edit InMail reminder message
                            </Typography>
                            <Stack direction="row" alignItems={"center"}>
                                {showCalendlyMenu && (
                                    <IconButton onClick={handleCalendlyClick} sx={{ alignSelf: "flex-end" }}>
                                        <TodayRoundedIcon
                                            fontSize="large"
                                            sx={{
                                                height: "28px",
                                                width: "28px",
                                            }}
                                        />
                                    </IconButton>
                                )}
                                <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                                    <SensorOccupiedRoundedIcon
                                        fontSize="large"
                                        sx={{ height: "28px", width: "28px" }}
                                    />
                                </IconButton>
                            </Stack>
                            <PersonalizedTagsMenu
                                actions={calendlyActions}
                                menuProps={{
                                    ...getCalendlyMenuProps(),
                                    onClose: handleCalendlyMenuClose,
                                }}
                            />
                            <Menu
                                sx={menuSX}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                {actions.map(({ key, label, onClick }) => {
                                    return (
                                        <MenuItem
                                            key={key}
                                            onClick={() => {
                                                onClick?.();
                                                handleClose();
                                            }}
                                        >
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </Stack>
                        <TextField
                            fullWidth
                            multiline
                            size="small"
                            minRows={5}
                            maxRows={6}
                            inputRef={subjectRef}
                            placeholder="Enter your message"
                            value={body}
                            onChange={(e) => handleBodyChange(e.target.value)}
                            helperText="This message will be sent when someone accepts your InMail but does not reply to you."
                            sx={{
                                "& .MuiInputBase-input": {
                                    fontSize: "14px",
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: "4px 0 0",
                                },
                            }}
                        />
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                            <Button variant="outlined" onClick={closeEditModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={closeEditModal}>
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}
