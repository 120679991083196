import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectLinkedinAuthIframeUrl } from "@/store/reducers/signin/Signin.reducer";
import { ArkoseCaptchaIntegration } from "../../utils/ArkonseCaptchaIntegration";
import handleException from "../../utils/sentry";

/*
@example: "https://iframe.arkoselabs.com/3117BF26-4762-4F5A-8ED9-A85E69209A46/index.html?data=O%2Blxf3luHUeNe8af.hQc3BhF5BWylFpkXejI8SCI1pNq9GlhSUKAgCqZQL6QOXLIVnZwLhYOZWg951vgYE36ylYN0WT3v3BOXMOq8hZOmR7%2Fpnf4PMDlOo3qlOPiN%2FRLXdqOAWwSPpCnpWuxW1bNdAX94w7oxTdG%2FL0NtNpKmgVzl9Gf8DMN8ZAAbZ%2B77xbJ%2B1q%2F6rxYi4pMrtEe8Ytx6wL0GPXn5DCoNVHkZ48TLzKiPMZXon%2FKBogJSTGGqukWo3%2BEdWUBVXQOvNSNvABbIC7W5n3Ga8m3xah80eafFwsANhUsaMZ5ATtsinxeXF9v5GbVlLanOqPjdBFu9x1%2FN55med4MPi5tbMakqyWAfR6ykpBJkgg%3D%3D&mkt=en";
*/

interface CaptchaModalProps {
    onSubmit: (token: string) => void;
}

export function CaptchaModal(props: CaptchaModalProps) {
    const { onSubmit } = props;
    const url = useSelector(selectLinkedinAuthIframeUrl);

    const [token, setToken] = useState<string | null>(null);

    const publicKey = url?.split?.("/")?.[3];
    const data = decodeURIComponent(url?.split("?data=")?.[1]?.split("&mkt=")?.[0]);

    useEffect(() => {
        if (!publicKey || !data) return;

        // 1. Instanciate the captcha frame and logic
        const captcha = new ArkoseCaptchaIntegration(publicKey, data);

        // 2. Setup the callbacks
        captcha.onLoaded = () => {
            // console.log("Captcha has been successfully loaded into the DOM !");
        };

        captcha.onSuccess = (token: string) => {
            setToken(token);
            // console.log("Captcha resolution successful !");
            captcha.unload();
        };

        captcha.onError = () => {
            handleException(new Error("Linkedin Authorization: Captcha resolution failed !"));
            // console.log("Captcha resolution failed !", error);
        };

        // 3. Load the captcha
        captcha.load("captcha-frame");

        return () => {
            captcha.unload();
        };
    }, [publicKey, data]);

    // this effect is required since captcha.onSuceess is called multiple times and we need to submit the token only once
    useEffect(() => {
        if (token) {
            onSubmit(token);
            setToken(null);
        }
    }, [token]);

    return (
        <Stack minHeight={300} alignItems={"center"} justifyContent={"center"}>
            <div id="captcha-frame" />
        </Stack>
    );
}
