import { zodResolver } from "@hookform/resolvers/zod";
import { PaperProps } from "@mui/material";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { ActivityColumn, PriorityType } from "./ContactOverviewActivities.types";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { CustomFormHelperText } from "../../../../components/CustomFormHelperText";
import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { useAppDispatch } from "../../../../store";
import {
    prioritySchema,
    referToSchema,
    useCreateActivityMutation,
    useFetchOrganizationMembersQuery,
    useUpdateContactOverviewActivityMutation,
} from "../../../../store/apis/all-candidates/all-candidates.api";
import { useGetCandidatesByProjectQuery } from "../../../../store/apis/projects/projects.api";
import { POPOVER_BOX_SHADOW } from "../../../../utils/Constants";
import { selectAllProjects } from "../../../allProjects/index.reducer";
import { checkEasyGrowth } from "../../../Signin/Signin.reducer";
import { useFetchActivityTags } from "../../AllCandidatesContent";
import { CustomTextField } from "../CustomTextField";

import { Button as CustomButton } from "@/ui";

export const criticalityOptions: PriorityType[] = ["HIGH", "MEDIUM", "LOW"];

export const projectSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

export type ProjectOption = z.infer<typeof projectSchema>;

const candidateSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

type CandidateOption = z.infer<typeof candidateSchema>;

export const createActivityFormSchema = z.object({
    // tag: z.string().trim().min(1, { message: "Tag is required" }),
    tag: z.string().trim().optional(),
    body: z.string().trim().min(1, { message: "Please enter body" }),
    priority: prioritySchema
        .refine(
            (value) => {
                return ["LOW", "MEDIUM", "HIGH", ""].includes(value);
            },
            {
                message: "Priority must be one of 'LOW', 'MEDIUM', or 'HIGH'",
            }
        )
        .optional(),
    referTo: referToSchema,
    project: projectSchema,
    candidate: candidateSchema,
    dueDate: z
        .custom<Dayjs>((val) => {
            if (!(val instanceof dayjs)) return false; // Basic instance check

            return true;
        }, "Enter valid time")
        .nullable(),
});

type EditActivityFormType = z.infer<typeof createActivityFormSchema>;

function useEditActivityFormState() {
    const { ...rest } = useFormContext<EditActivityFormType>();

    return { ...rest };
}

export const commonPaperProps: PaperProps["sx"] = {
    boxShadow: POPOVER_BOX_SHADOW,
    // borderRadius: "0.5rem",
    // maxHeight: "100px",
    "& .MuiAutocomplete-option": {
        fontSize: "14px",
    },
    "& .MuiAutocomplete-noOptions": {
        fontSize: "14px",
    },
    marginTop: "10px",
    marginBottom: "10px",
};

function ReferToMenu() {
    const { isLoading, data = [] } = useFetchOrganizationMembersQuery();
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="referTo"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            disabled={isSubmitting}
                            value={value}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={data}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            size="small"
                            sx={{
                                "&.MuiAutocomplete-root": {
                                    "& .MuiTextField-root": {
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.7rem",
                                        },
                                    },
                                },
                            }}
                            slotProps={{
                                paper: {
                                    sx: commonPaperProps,
                                },
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    label="Assigned to"
                                    error={error !== undefined}
                                    legendWidth={70}
                                    isLoading={isLoading}
                                    inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                                />
                            )}
                        />
                        {error !== undefined && (
                            <CustomFormHelperText>{error?.message || "Please select assignee"}</CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

function ActivityTypeMenu() {
    const { control } = useEditActivityFormState();
    const { isLoading, data = [] } = useFetchActivityTags();
    return (
        <Controller
            control={control}
            name="tag"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                                option.toLowerCase().includes(params.inputValue.toLowerCase())
                            );
                            if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
                                filtered.push(`Add "${params.inputValue}"`);
                            }
                            return filtered;
                        }}
                        freeSolo
                        disabled={isSubmitting}
                        value={value}
                        options={data}
                        getOptionLabel={(option) => {
                            return option;
                        }}
                        onChange={(e, newValue) => {
                            e.stopPropagation();
                            if (
                                typeof newValue === "string" &&
                                newValue.startsWith('Add "') &&
                                newValue.endsWith('"')
                            ) {
                                onChange(newValue.slice(5, -1));
                            } else {
                                onChange(newValue);
                            }
                        }}
                        size="small"
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                            },
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextField
                                {...params}
                                legendWidth={70}
                                error={error !== undefined}
                                label="Activity Tag"
                                isLoading={isLoading}
                                inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                            />
                        )}
                    />
                    {error !== undefined && <CustomFormHelperText>Please select tag</CustomFormHelperText>}
                </FormControl>
            )}
        />
    );
}

function Priority() {
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl
                        error={Boolean(error)}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormLabel-root ": {
                                    ...fontSize,
                                    top: "-5px",
                                    '&[data-shrink="true"]': {
                                        top: "1px",
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    width: "43px",
                                },
                                "& .MuiSelect-select": {
                                    ...fontSize,
                                    padding: "0.7rem",
                                },
                            };
                        }}
                    >
                        <InputLabel>Priority</InputLabel>
                        <Select
                            error={Boolean(error)}
                            disabled={isSubmitting}
                            size="small"
                            value={value}
                            label="Priority"
                            onChange={(e) => onChange(e.target.value as PriorityType)}
                        >
                            <MenuItem
                                value=""
                                sx={{
                                    "&.MuiMenuItem-root": {
                                        fontSize: "12px",
                                    },
                                }}
                            >
                                <em>None</em>
                            </MenuItem>
                            {criticalityOptions.map((i) => {
                                if (!i) {
                                    return null;
                                }

                                return (
                                    <MenuItem
                                        key={i}
                                        value={i}
                                        sx={{
                                            "&.MuiMenuItem-root": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >
                                        {i}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {error !== undefined && (
                            <CustomFormHelperText>Please select valid priority value</CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

function DueDate() {
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="dueDate"
            render={({ field, fieldState: { error } }) => {
                const value = field.value;
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            {...field}
                            disablePast
                            label="Due Date"
                            ampm={false}
                            slotProps={{
                                field: { clearable: true },
                                actionBar: {
                                    actions: ["clear", "accept"],
                                },
                                textField: {
                                    error: Boolean(error),
                                    helperText: error !== undefined ? "Please select valid time value" : null,
                                    FormHelperTextProps: { sx: { marginX: 0 } },
                                },
                                clearButton: {
                                    size: "small",
                                    sx: {
                                        top: "1px",
                                        right: "1px",
                                    },
                                },
                            }}
                            sx={(theme) => {
                                const fontSize = theme.typography.body2;
                                return {
                                    marginTop: "0.2rem",
                                    "& .MuiFormLabel-root ": {
                                        ...fontSize,
                                        top: "-4px",
                                        '&[data-shrink="true"]': {
                                            top: "1px",
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        ...fontSize,
                                        padding: "0.7rem",
                                        color: value ? theme.palette.text.primary : theme.palette.text.disabled,
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        fontSize: "13px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline legend": {
                                        width: "57px",
                                    },
                                };
                            }}
                        />
                    </LocalizationProvider>
                );
            }}
        />
    );
}

function ActivityBody() {
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="body"
            render={({ field, fieldState: { error } }) => {
                return (
                    <TextField
                        {...field}
                        label="Body"
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        minRows={3}
                        multiline
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormHelperText-root": {
                                    margin: "4px 0 0",
                                },
                                "& .MuiFormLabel-root": {
                                    top: "-8px",
                                    ...fontSize,
                                    '&[data-shrink="true"]': {
                                        top: "0px",
                                    },
                                },
                                "& .MuiInputBase-root": {
                                    padding: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "0.7rem",
                                    maxHeight: "200px",
                                    overflow: "auto !important",
                                    ...fontSize,
                                },
                            };
                        }}
                    />
                );
            }}
        />
    );
}

function SelectProject({ projectOptions = [] }: { projectOptions?: ProjectOption[] }) {
    const allProjects = useSelector(selectAllProjects);

    const mappedProjectOptions: ProjectOption[] = allProjects.map((i) => ({
        value: String(i._id),
        label: i.name,
    }));
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="project"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                const options = projectOptions?.length > 0 ? projectOptions : mappedProjectOptions;
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            disabled={isSubmitting}
                            value={value}
                            getOptionLabel={(option) => option.label}
                            options={options}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            size="small"
                            sx={{
                                "&.MuiAutocomplete-root": {
                                    "& .MuiTextField-root": {
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.7rem",
                                        },
                                    },
                                },
                            }}
                            slotProps={{
                                paper: {
                                    sx: commonPaperProps,
                                },
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    label="Select Projects"
                                    error={error !== undefined}
                                    legendWidth={84}
                                    inputProps={{ ...params.inputProps, onClick: (e) => e.stopPropagation() }}
                                />
                            )}
                        />
                        {error !== undefined && <CustomFormHelperText>Please select project</CustomFormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function SelectCandidate({ shouldDisabled }: { shouldDisabled?: boolean }) {
    const { watch, control } = useEditActivityFormState();
    const isEasyGrowth = useSelector(checkEasyGrowth);

    const project = watch("project");
    const { data: candidates = [], isLoading } = useGetCandidatesByProjectQuery(
        {
            projectId: project.value,
        },
        { skip: !project?.value }
    );
    const mappedCandidateOptions: CandidateOption[] = candidates.map((i) => ({
        value: i._id,
        label: i.name,
    }));
    return (
        <Controller
            control={control}
            name="candidate"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        disabled={isSubmitting || shouldDisabled}
                        value={value}
                        getOptionLabel={(option) => option.label}
                        getOptionKey={(option) => option.value}
                        options={mappedCandidateOptions}
                        onChange={(e, v) => {
                            e.stopPropagation();
                            if (v) {
                                onChange(v);
                            }
                        }}
                        size="small"
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                            },
                        }}
                        renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        )}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextField
                                {...params}
                                error={error !== undefined}
                                label={isEasyGrowth ? "Select Prospect" : "Select Candidate"}
                                legendWidth={98}
                                isLoading={isLoading && !shouldDisabled}
                                inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                            />
                        )}
                    />
                    {error !== undefined && (
                        <CustomFormHelperText>
                            {/* TODO */}
                            {/* {error?.label?.message || "Please Select Project."} */}
                            {isEasyGrowth ? "Please select prospect" : "Please select candidate"}
                        </CustomFormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}

type EditActivityFormProps = {
    closeEdit?: () => void;
    variant?: "EDIT" | "CREATE" | "SUPER_INBOX_ACTIVITY_CREATE" | "PROJECT_ACTIVITY_CREATE";
    data?: ActivityColumn;
    projects?: ProjectOption[];
    selectedCandidate?: CandidateOption;
};

const getOptionInitialValue = (
    variant: EditActivityFormProps["variant"] = "EDIT"
): { label: string; value: string } => {
    switch (variant) {
        case "SUPER_INBOX_ACTIVITY_CREATE":
        case "CREATE": {
            return { label: "", value: "" };
        }
        case "EDIT":
        case "PROJECT_ACTIVITY_CREATE": {
            return { label: "-1", value: "-1" };
        }
        default:
            return { label: "", value: "" };
    }
};

function getEditInitialValues(data: ActivityColumn) {
    const {
        activityType,
        activityDetails: { body: originalBody, priority, dueDate },
        assignTo,
    } = data;
    return {
        candidate: getOptionInitialValue("EDIT"),
        project: getOptionInitialValue("EDIT"),
        body: originalBody,
        tag: activityType,
        dueDate: dueDate ? dayjs(dueDate) : null,
        priority: priority || undefined,
        referTo: { _id: assignTo?._id ?? -1, name: assignTo?.name ?? "" },
    };
}

export function EditActivityForm(props: EditActivityFormProps) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const numericalProjectId = Number(id);

    const { data: editData, closeEdit, variant = "EDIT", projects, selectedCandidate } = props;
    const isCreate = variant !== "EDIT" && variant !== "PROJECT_ACTIVITY_CREATE";

    const methods = useForm<EditActivityFormType>({
        resolver: zodResolver(createActivityFormSchema),
        defaultValues:
            editData !== undefined
                ? getEditInitialValues(editData)
                : {
                      body: "",
                      tag: "",
                      dueDate: null,
                      candidate:
                          variant === "SUPER_INBOX_ACTIVITY_CREATE" || variant === "PROJECT_ACTIVITY_CREATE"
                              ? selectedCandidate
                              : getOptionInitialValue(variant),
                      project: getOptionInitialValue(variant),
                      priority: undefined,
                      referTo: { _id: -1, name: "" },
                  },
    });
    const [updateContactOverviewActivityMutation, { isLoading: isUpdating }] =
        useUpdateContactOverviewActivityMutation();
    const [createActivity, { isLoading: isCreating }] = useCreateActivityMutation();
    const isLoading = isCreating || isUpdating;

    const onSubmit: SubmitHandler<EditActivityFormType> = (data) => {
        const { body, tag, dueDate, priority, referTo, candidate, project } = data;
        switch (variant) {
            case "SUPER_INBOX_ACTIVITY_CREATE":
            case "CREATE": {
                createActivity({
                    body,
                    dueDate: dueDate === null ? "" : dueDate?.toISOString(),
                    title: !tag ? "notes" : tag,
                    priority,
                    referTo: referTo?._id,
                    projectId: Number(project.value),
                    candidateId: candidate.value,
                })
                    .unwrap()
                    .then(() => {
                        closeEdit?.();
                        dispatch(setSuccessNotification("Activity created successfully"));
                    });
                break;
            }
            case "EDIT": {
                if (editData?._id) {
                    const { _id } = editData;
                    updateContactOverviewActivityMutation({
                        activityId: _id,
                        dueDate: dueDate === null ? "" : dueDate?.toISOString(),
                        priority,
                        referTo: referTo?._id ?? undefined,
                        title: !tag ? "notes" : tag,
                        body,
                    })
                        .unwrap()
                        .then(() => {
                            closeEdit?.();
                            dispatch(setSuccessNotification("Activity updated successfully"));
                        });
                }
                break;
            }
            case "PROJECT_ACTIVITY_CREATE": {
                createActivity({
                    body,
                    dueDate: dueDate === null ? "" : dueDate?.toISOString(),
                    title: !tag ? "notes" : tag,
                    priority,
                    referTo: referTo?._id,
                    projectId: numericalProjectId,
                    candidateId: candidate.value,
                })
                    .unwrap()
                    .then(() => {
                        closeEdit?.();
                        dispatch(setSuccessNotification("Activity created successfully"));
                    });
                break;
            }

            default:
                break;
        }
    };

    return (
        <FormProvider {...methods}>
            <Stack component="form" gap={2} onSubmit={methods.handleSubmit(onSubmit)}>
                {isCreate && <SelectProject projectOptions={projects} />}
                {isCreate && <SelectCandidate shouldDisabled={variant === "SUPER_INBOX_ACTIVITY_CREATE"} />}
                <Priority />
                <DueDate />
                <ActivityTypeMenu />
                <ReferToMenu />
                <ActivityBody />
                <CustomButton className="self-end" disabled={isLoading}>
                    <ButtonTextWithLoading isLoading={isLoading} text="Save" progressSize={16} />
                </CustomButton>
            </Stack>
        </FormProvider>
    );
}
