import { TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { submitRocketReachSearch } from "../../../store/reducers/search/search.slice";
import { selectSearch } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { BSAutocompleteGeography } from "../Filters/components/BSAutocompleteGeography";
import { BSAutoCompleteTypeAhead } from "../Filters/components/BSAutoCompleteTypeAhead";
import { BSAutocompleteWithoutExclusion } from "../Filters/components/BSAutocompleteWithoutExclusion";
import { BSRange } from "../Filters/components/BSRange";
import { BSSelect } from "../Filters/components/BSSelect";

import Loader from "@/components/Loader/Loader";
import { getProject, selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { Button, Typography } from "@/ui";

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectName = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitRocketReachSearch.type));
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const handleSubmit = () => dispatch(submitRocketReachSearch({ navigate }));

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <div className="bg-white rounded-md items-center p-10">
                    <Loader />
                </div>
            ) : (
                <div
                    className={`flex flex-col p-3 pb-0 space-y-2 bg-white border border-[rgba(0,0,0,0.1)] rounded-md ${disabled ? "pointer-events-none" : ""
                        }`}
                    ref={ref}
                >
                    {projectId ? (
                        <Typography className="text-[14px]">Edit {currProjectName} project</Typography>
                    ) : (
                        <TextField
                            InputLabelProps={{ sx: { fontSize: 14 } }}
                            inputProps={{ sx: { fontSize: 14 } }}
                            autoFocus
                            size="small"
                            label="Project Name"
                            variant="standard"
                            value={projectName}
                            error={projectNameErr}
                            helperText={errorInSearch}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setSearchParams({
                                        name: e.target.value,
                                    });
                                } else {
                                    searchParams.delete("name");
                                    setSearchParams(searchParams);
                                }
                            }}
                        />
                    )}

                    <div className="flex flex-row items-center gap-1.5">
                        <BSAutocompleteGeography id="rocketReachLocation" freeSolo />
                        <BSSelect id="rocketReachRadius" label="Radius" unit="miles" />
                    </div>

                    <Typography className="text-[12px]" component={"strong"}>
                        Format for location input
                    </Typography>
                    <div className="px-2">
                        <ul>
                            <Typography className="text-[12px]" component={"i"}>
                                <li>
                                    Write <b>Fayetteville, NC, US</b> to find candidates in the city Fayetteville, North
                                    Carolina.{" "}
                                </li>
                            </Typography>
                        </ul>
                    </div>

                    <BSAutoCompleteTypeAhead id="rocketReachJobTitle" label="Job title" placeholder="Job title" />
                    <BSAutoCompleteTypeAhead
                        id="rocketReachSpecialization"
                        label="Specialization"
                        placeholder="Specialization"
                    />
                    <BSAutocompleteWithoutExclusion id="rocketReachDegree" label="Degree" placeholder="Degree" />
                    <BSAutoCompleteTypeAhead id="rocketReachSkills" label="Skills" placeholder="Skills" />
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "25%", marginRight: "2%" }}>
                            <BSAutoCompleteTypeAhead
                                id="rocketReachContactMethodType"
                                label="Select type"
                                placeholder="Select type"
                                multiple={false}
                            />
                        </div>
                        <div style={{ width: "73%" }}>
                            <BSAutocompleteWithoutExclusion
                                id="rocketReachContactMethod"
                                label="Contact method"
                                placeholder="Contact method"
                            />
                        </div>
                    </div>

                    <BSRange id="rocketReachExperienceRange" label="Select experience (in years)" />

                    {!disabled ? (
                        <div className="flex justify-center bg-white sticky gap-2 bottom-0 border-t border-[rgba(0,0,0,0.1)] py-1">
                            <Button variant="default" onClick={handleSubmit} disabled={isLoading}>
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" />
                            </Button>
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
}
