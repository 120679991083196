import dayjs from "dayjs";
import { isNull, isObject, isUndefined } from "lodash";
import xss from "xss";

import { openExtensionLink } from "./API";
import handleCleverTap from "./clevertap";
import { HQ_EASYSOURCE_WEBSITE, HQ_EXTENSION_ON_BOARDING, HQ_OPEN_IN_NEW_PAGE_EXTENSION } from "./Constants";

import store from "../store";

import { IMessage } from "@/store/reducers/message/message.types";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { IProjectExperience } from "@/store/reducers/project/project.types";
import { signinType } from "@/store/reducers/signin/Signin.types";

export const POPOVER_BOX_SHADOW = "rgba(149, 157, 165, 0.2) 0px 8px 24px";

export function splitSpaceOnce(input: string): string[] {
    // Split the input string by spaces
    const parts = input.trim().split(/\s+/);

    // Check if the input has more than two parts (to account for middle names)
    if (parts.length > 2) {
        // Join the middle name(s) with the last name, keeping the first name separate
        return [parts[0], parts.slice(1).join(" ")];
    } else {
        // If there are only two parts, return them as is
        return parts;
    }
}

export const objectValues = <T extends object>(obj: T): T[keyof T][] => Object.values(obj) as T[keyof T][];

export const objectKeys = <T extends object>(obj: T): (keyof T)[] => Object.keys(obj) as (keyof T)[];

export const objectEntries = <T extends object>(obj: T): [keyof T, T[keyof T]][] => {
    return Object.entries(obj) as [keyof T, T[keyof T]][];
};

export function sanitizeTemplateBody(text: string) {
    //replace all <p><br></p> with "\n"
    // let resultWithSpaces = text.replaceAll("<p><br></p>", "\n");

    // if after replacing, if result === \n, then return 0;

    // else pass result to removeHTMLTags function then return it's output length
    // const result = removeHtmlTags(resultWithSpaces);
    let result = xss(text, {
        whiteList: {}, // empty, means filter out all tags
        onIgnoreTag: (tag, html) => {
            if (tag === "br") {
                return "\n";
            } else {
                return "";
            }
        },
    });

    if (result === "\n") {
        return "";
    }

    // handle case when there is "\n\n"
    if (result.startsWith("\n")) {
        result = result.slice(1);
    }

    return result;
}

/**
 * Extracts text enclosed in curly braces from the input string.
 *
 * @param {string} input - The input string to extract text from.
 * @return {string[]} An array of text enclosed in curly braces.
 */
export function extractTextInCurlyBraces(input: string): string[] {
    const sanitizedInput = sanitizeTemplateBody(input);
    const regex = /\{([^{}]+)\}/g;
    const matches = [];
    let match;

    while ((match = regex.exec(sanitizedInput)) !== null) {
        matches.push(match[1].trim());
    }

    return matches;
}

export function extractStringsInRoundBrackets(input: string): string[] {
    const sanitizedInput = sanitizeTemplateBody(input);
    const regex = /\(([^)]+)\)/g;
    const matches = [];
    let match;

    while ((match = regex.exec(sanitizedInput)) !== null) {
        matches.push(match[1]);
    }

    return matches;
}

export function calculateQuillCharacterCount(text: string) {
    return sanitizeTemplateBody(text).length || 0;
}

export const sendExtensionEvent = (url: string, type: string) => {
    handleCleverTap(`source candidates ${type}`);
    window.postMessage(
        {
            type: url === openExtensionLink ? HQ_OPEN_IN_NEW_PAGE_EXTENSION : HQ_EXTENSION_ON_BOARDING,
            from: HQ_EASYSOURCE_WEBSITE,
            url,
        },
        "*"
    );
};

const experienceFields = {
    "0-1": "Less than 1 year",
    "1-2": "1 to 2 Years",
    "3-5": "3 to 5 Years",
    "6-10": "5 to 10 Years",
    "10": "More than 10 years",
};

export const nameInitials = (name: string) =>
    name && name !== "-"
        ? name
              .split(" ")
              .slice(0, 2)
              .map((word: string) => word.charAt(0).toLocaleUpperCase())
        : "N/A";

export const getExperience = (str: IProjectExperience) => experienceFields[str];

export function formatDate(timestamp: string, format: string = "D MMM, YYYY"): string {
    if (!dayjs(timestamp).isValid()) return "Invalid date";

    return dayjs(timestamp).format(format || "D MMM, YYYY");
}

export function formatSignupEmail(email: string) {
    if (!email) return;

    return (
        email[0] +
        email.substring(1, email.indexOf("@")).replace(/./g, "*") +
        email.substring(email.indexOf("@"), email.length)
    );
}

export function numberOfNewMessages(messages: IMessage[]) {
    let count = 0;

    for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i];

        if (!message.sentByRecruiter && !message.read) {
            count++;
        } else {
            break; // Stop counting when a message doesn't meet the criteria
        }
    }

    return count;
}

export function removeHtmlTags(text?: string) {
    if (!text) return;
    return text.replace(/<[^>]*>/g, "");
}

export function shortenTotalExperience(str: string) {
    const [num, unit] = str.split(" ");
    if (!num || !unit) return str;

    const isYear = unit.includes("year");
    let months;
    if (isYear) {
        const [, , num, unit] = str.split(" ");
        if (num && unit) {
            const isMonth = unit.includes("month");
            if (isMonth) months = num;
        }
    }
    const converted = isYear ? (months ? (Number(num) * 12 + Number(months)) / 12 : Number(num)) : Number(num) / 12;
    const formatted = Math.trunc(converted * 10) / 10;
    return `${formatted} yrs`;
}

export function checkPlaceholdersFilled(str?: string) {
    if (!str) return;

    const regex = /\[(.*?)\]/g; // regular expression to match text between square brackets
    const matches = str.match(regex); // array of matches found in the input text

    if (matches?.length) {
        // no matches found, all placeholders are already filled
        return {
            value: false,
            matches: matches,
        };
    }

    return {
        value: true,
    };
}

export const getCreditPercentage = (user: signinType) => {
    const totalCredits = user.orgData?.allCredits.emailFetchCredit || 0;
    const currentCredits = user.orgData?.usedCredits.emailFetchCredit || 0;
    const remainingCredits = totalCredits ? ((totalCredits - currentCredits) / totalCredits) * 100 : 0;

    return {
        totalCredits,
        currentCredits,
        remainingCredits,
    };
};

export const exportToCsvc = (data: Record<string, any>[], name: string = "") => {
    const csvContent = convertToCsvString(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", name ? `${name}.csv` : "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
    store.dispatch(setSuccessNotification("Data fetched successfully"));
};

const convertToCsvString = (data: Record<string, any>[]) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item) => {
        const rowValues = Object.keys(data[0]).map((key) => {
            let value = item[key];
            if (isUndefined(value) || isNull(value)) {
                value = "-";
            } else if (Array.isArray(value) || isObject(value)) {
                value = JSON.stringify(value).replace(/,/g, " | ").replace(/"/g, "");
            }
            return `"${value}"`;
        });
        return rowValues.join(",");
    });
    return header + "\r\n" + rows.join("\r\n");
};

export function randomEmail() {
    const domains = ["gmail.com", "yahoo.com", "outlook.com"];
    const domain = domains[Math.floor(Math.random() * domains.length)];
    const usernameLength = Math.floor(Math.random() * 3) + 5;
    const username = "*".repeat(usernameLength);
    const email = `${username}@${domain}`;
    return email;
}

export function isWordsLimitExceeded(customValue: string, wordLimit: number) {
    return (customValue.match(/\S+/g)?.length || 0) > wordLimit;
}

export function isValidPersonName(name: string) {
    if (!name) return;

    const regName = /^[a-zA-Z ]+$/;
    return regName.test(name);
}

export function isValidEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const baseStyle = {
    flex: 1,
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    width: "100%",
    outline: "none",
    transition: "border .24s ease-in-out",
};

export const focusedStyle = {
    borderColor: "#0891B2",
};

export const acceptStyle = {
    borderColor: "#00e676",
};

export const rejectStyle = {
    borderColor: "#ff1744",
};
