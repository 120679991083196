import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectFilter, setValues } from "../../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../../store/reducers/search/search.types";
import { dispatchEnterKeyboardEvent } from "../../../../utils/keyboardEvent";

interface BSAutoCompleteProps {
    id: SearchStateKeys;
    label: string;
    placeholder: string;
    multiple?: boolean;
}

export function BSAutoComplete({
    id,
    label,
    placeholder,
    multiple = true
}: BSAutoCompleteProps) {
    const { values, options, error } = useSelector(selectFilter(id));
    const ref = useRef(null);


    const dispatch = useDispatch();

    const handleChange = (value) => {
        const updatedValue = Array.isArray(value)
            ? value.map((value) => ({ value, excluded: false }))
            : [{ value: value || "", excluded: false }];

        dispatch(
            setValues({
                key: id,
                value: updatedValue,
            })
        );
    };

    return (
        <Autocomplete
            multiple={multiple}
            freeSolo
            fullWidth
            size="small"
            ChipProps={{ color: "success" }}
            value={multiple ? values.map(({ value }) => value) : values[0]?.value || ""}
            options={Object.keys(options).map((i) => i)}
            onChange={(_, value) => {
                handleChange(value)
            }}
            onBlur={() => {
                dispatchEnterKeyboardEvent(ref.current);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    ref={ref}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    label={label}
                    placeholder={placeholder}
                    error={Boolean(error)}
                    helperText={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                        sx: { fontSize: 14 },
                    }}
                />
            )}
        />
    );
}