import { useCallback, useMemo, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DescriptionIcon from "@mui/icons-material/Description";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import { IconButton, Tooltip as JoyTooltip } from "@mui/joy";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import type { ActionMeta, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import { Option } from "../../../../common";
import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { JoyProvider } from "../../../../components/JoyProvider";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../../utils/helper";
import validatePhone, { validatePhoneDigits } from "../../../../utils/validatePhone";
import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";
import { editCandidate } from "@/store/reducers/project/project.reducer";
import { ICandidate, IEditCandidatePayload } from "@/store/reducers/project/project.types";
import { useCandidateModalContext } from "../candidateModal/Context";

type CvUploadProps = {
    handleCvUpload: (key: File[]) => void;
};

function CvUpload({ handleCvUpload }: CvUploadProps) {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleCvUpload(files);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "application/pdf": [".pdf"],
            },
            onDrop,
        });

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <Stack key={file.path} direction={"row"} alignItems={"center"} spacing={0.5}>
            <DescriptionIcon />
            <Typography>{file.path}</Typography>
        </Stack>
    ));

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? "Drop CV here ..." : "Click to upload your CV or drag and drop (Allowed formats: .pdf)"}
            </div>
            <Stack mt={1} spacing={0.5}>
                {acceptedFileItems}
            </Stack>
        </>
    );
}

type EditCandidateModalProps = {
    open: boolean;
    onClose: () => void;
    candidate?: ICandidate;
    onEdit: (candidate: IEditCandidatePayload) => void;
};

function EditCandidateModal({ open, onClose, candidate, onEdit }: EditCandidateModalProps) {
    // const { currentCandidate, updateCandidate } = useCandidateModalContext();
    const { t } = useTranslation();
    const {
        _id: candidateId,
        name: candidateName,
        profileUrl: candidateLinkedIn,
        resume: candidateResume,
        email = [],
        professional_email = [],
        phone: defaultPhoneNumbers,
    } = candidate;

    const phoneNumbers = (Array.isArray(defaultPhoneNumbers) ? defaultPhoneNumbers : [defaultPhoneNumbers]).filter(
        validatePhone
    );

    const dispatch = useDispatch();
    const isEditingCandidate = useSelector(checkIfLoading(editCandidate.type));

    const isEasyGrowth = useSelector(checkEasyGrowth);

    const [name, setName] = useState(candidateName);
    const [linkedIn, setLinkedIn] = useState(candidateLinkedIn);
    const [phone, setPhone] = useState(phoneNumbers);
    const [resume, setResume] = useState<File | null>(null);
    const [selectedEmails, setSelectedEmails] = useState(() => [...email, ...professional_email]);

    const handleSubmit = () => {
        const data: IEditCandidatePayload = {
            name,
            linkedIn,
            candidateId,
            onSuccess: onClose,
            emails: selectedEmails,
            phone,
        };
        if (resume instanceof File) {
            data.resume = resume;
        }
        onEdit(data);

        // updateCandidate({
        //     ...candidate,
        //     name,
        //     profileUrl: linkedIn,
        //     email: selectedEmails,
        //     phone,
        //     resume: resume ? "-" : candidateResume,
        // });
    };

    const handleSelectedEmailsChange = (_: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
        if (actionMeta.action === "create-option") {
            setSelectedEmails((prev) => prev.concat(actionMeta.option.value));
        }

        if (actionMeta.action === "remove-value") {
            setSelectedEmails((prev) => prev.filter((email) => email !== actionMeta.removedValue.value));
        }
    };

    const handleDelete = (values: string[], selectedValue: string) => {
        const updatedValue = values.filter((value) => value !== selectedValue);
        setPhone(updatedValue);
    };

    const hasInvalidPhone = phone.some((number) => !validatePhoneDigits(number));

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle fontSize={20} fontWeight={500} color="#334d6e">
                {t("projectsPage.editCandidate")}

                <JoyProvider>
                    <IconButton
                        onClick={onClose}
                        size="sm"
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            background: "white",
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </JoyProvider>
            </DialogTitle>
            <DialogContent>
                <Stack mt={1} mb={2} spacing={2}>
                    <TextField label="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField
                        label="LinkedIn Url"
                        type="url"
                        value={linkedIn}
                        onChange={(e) => setLinkedIn(e.target.value)}
                    />
                    <CreatableSelect
                        styles={{
                            input: (base, props) => ({
                                padding: "0.7rem",
                            }),
                        }}
                        isClearable
                        isMulti
                        placeholder="Enter Emails"
                        value={selectedEmails.map((value) => ({
                            value,
                            label: value,
                        }))}
                        onChange={handleSelectedEmailsChange}
                    />
                    <Autocomplete
                        multiple
                        freeSolo
                        fullWidth
                        size="small"
                        value={phone}
                        options={[]}
                        renderTags={(values: string[]) => {
                            return values.map((value) => {
                                const error = !validatePhoneDigits(value);
                                return (
                                    <Tooltip
                                        title={
                                            error &&
                                            "Caution: Please double-check the format of this phone number. If it seems correct, click 'Submit' to save the phone number"
                                        }
                                    >
                                        <Chip
                                            sx={{ m: 0.5 }}
                                            size="small"
                                            variant={error ? "outlined" : "filled"}
                                            color={error ? "error" : "default"}
                                            onDelete={() => handleDelete(values, value)}
                                            label={value}
                                        />
                                    </Tooltip>
                                );
                            });
                        }}
                        onChange={(_, value: string[]) => {
                            const sanitizedPhone = value.filter((v) => validatePhone(v));
                            setPhone(sanitizedPhone);
                        }}
                        renderInput={(params) => (
                            <TextField
                                label="Phone"
                                {...params}
                                error={hasInvalidPhone}
                                placeholder="Press enter to save phone number"
                                helperText={
                                    hasInvalidPhone ? (
                                        "Caution: Please double-check the format of this phone number. If it seems correct, click 'Submit' to save the phone number"
                                    ) : (
                                        <List sx={{ p: 0, listStyle: "decimal", pl: 4 }}>
                                            <ListItem sx={{ display: "list-item", p: 0 }}>
                                                <Typography sx={{ fontSize: "14px" }}>
                                                    Please do not include any characters apart from numerical digits,
                                                    '+' sign, or space
                                                </Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: "list-item", p: 0 }}>
                                                <Typography sx={{ fontSize: "14px" }}>
                                                    Please press enter after each phone number
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    )
                                }
                            />
                        )}
                    />
                </Stack>
                {candidateResume && candidateResume !== "-" ? (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>Existing Resume:</Typography>
                        <JoyProvider>
                            <IconButton size="sm" href={candidateResume}>
                                <PictureAsPdfRoundedIcon fontSize="small" />
                            </IconButton>
                        </JoyProvider>
                    </Stack>
                ) : null}
                {!isEasyGrowth && <CvUpload handleCvUpload={(val) => setResume(val[0])} />}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={isEditingCandidate} onClick={handleSubmit}>
                    <ButtonTextWithLoading isLoading={isEditingCandidate} text="Submit" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

type EditCandidateProps = {
    candidate: ICandidate;
    onEditCandidate: (candidate: IEditCandidatePayload) => void;
};

export default function EditCandidate({ candidate, onEditCandidate }: EditCandidateProps) {
    const [openEditModal, setOpenEditModal] = useState(false);
    const { t } = useTranslation();

    const showEditModal = () => {
        setOpenEditModal(true);
    };
    const closeEditModal = () => setOpenEditModal(false);

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <JoyProvider>
                <JoyTooltip
                    arrow
                    title={t("projectsPage.editButtonTooltip")}
                    sx={{
                        fontSize: "12px",
                    }}
                >
                    <IconButton size="sm" onClick={showEditModal}>
                        <EditRoundedIcon fontSize="small" />
                    </IconButton>
                </JoyTooltip>
            </JoyProvider>
            {openEditModal && (
                <EditCandidateModal
                    onEdit={onEditCandidate}
                    open={openEditModal}
                    onClose={closeEditModal}
                    candidate={candidate}
                />
            )}
        </div>
    );
}
