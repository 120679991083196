import Avatar from "@mui/joy/Avatar";
import Badge from "@mui/joy/Badge";
import Box from "@mui/joy/Box";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/joy/ListItemButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getHighlightedText } from "../../../../atoms/HighlightedText";
import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { TCandidate } from "../../../../store/apis/super-inbox/super-inbox.types";
import { useSuperInboxContext } from "../../Context";
import { formatMessageDate, toggleMessagesPane } from "../../utils";
import ChannelIcon from "../channels/ChannelIcon";

type ChatListItemProps = ListItemButtonProps & TCandidate;

export default function ChatListItem(props: ChatListItemProps) {
    const {
        filters: { searchQuery },
        candidateParamId,
    } = useSuperInboxContext();
    const {
        candidateName,
        candidateId,
        photo,
        lastMessageTime,
        lastMessageSnippet,
        lastMessageChannel,
        // projectIds,
        threadRead,
    } = props;
    const [isThreadRead, setIsThreadRead] = useState<boolean>(threadRead);

    const navigate = useNavigate();

    const navigateToConversations = () => {
        navigate(`/inbox/${candidateId}/${lastMessageChannel}`);
        if (!isThreadRead) setIsThreadRead(true);
    };

    const onClickCard = () => {
        toggleMessagesPane();
        navigateToConversations();
    };

    const selected = candidateId === candidateParamId;

    return (
        <>
            <ListItem>
                <ListItemButton
                    onClick={onClickCard}
                    selected={selected}
                    color="neutral"
                    sx={{
                        flexDirection: "column",
                        alignItems: "initial",
                        gap: 1,
                    }}
                >
                    <Stack direction="row" spacing={1.5}>
                        <Avatar size="sm" src={photo?.startsWith("data:") ? candidateName : photo} />
                        <Box sx={{ flex: 1 }}>
                            <HoverTruncateText>{getHighlightedText(candidateName, searchQuery)}</HoverTruncateText>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1.5,
                                lineHeight: 1.5,
                            }}
                        >
                            <Typography level="body-xs" noWrap>
                                {formatMessageDate(lastMessageTime)}
                            </Typography>
                            <Badge size="sm" invisible={isThreadRead} color="success">
                                <ChannelIcon channel={lastMessageChannel} />
                            </Badge>
                        </Box>
                    </Stack>
                    <Typography
                        level="body-sm"
                        sx={{
                            wordBreak: "break-word",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <span
                            style={{ fontSize: "inherit" }}
                            dangerouslySetInnerHTML={{
                                __html: lastMessageSnippet,
                            }}
                        />
                    </Typography>
                </ListItemButton>
            </ListItem>
            <ListDivider sx={{ margin: 0 }} />
        </>
    );
}
