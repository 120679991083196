import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import WorkspacesRoundedIcon from "@mui/icons-material/WorkspacesRounded";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import style from "./accountMenu.module.scss";

import { setModal } from "../../../store/reducers/modals.slice";
import { baseUrl } from "../../../utils/API";
import ProfileAvatar from "../../ProfileAvatar/ProfileAvatar";

import { setAuthorizeModal } from "@/store/reducers/mail-alert/mailAlert.reducers";
import {
    authLogin,
    checkAllowPasswordReset,
    checkCalendlyAuthorized,
    checkIsLinkedinAuthorized,
    selectUser,
    setLinkedinAuthStatus,
    signOut,
} from "@/store/reducers/signin/Signin.reducer";
import { Typography } from "@/ui";
import { Popover } from "@/ui/Popover/Popover";

export const CALENDLY_URI = `https://auth.calendly.com/oauth/authorize?client_id=${import.meta.env.VITE_REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${baseUrl}/auth/calendly`;

// ? IAccountMenuProps is optional because we are not using it in the navbar for now
type IAccountMenuProps = {
    setShowReferralModal?: React.Dispatch<React.SetStateAction<boolean>>;
    startWalkthrough?: () => void;
};

const GenerateNavigationLinks = ({
    isCalendlyAuthorized,
    allowPasswordReset,
}: {
    isCalendlyAuthorized: boolean;
    allowPasswordReset: boolean;
}) => {
    const links = [
        {
            href: "/my-account/details",
            title: "Workspace settings",
            subtitle: "AI, data, profile, team and more",
            icon: (
                <WorkspacesRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        },
        {
            href: "/notifications",
            title: "Notifications",
            subtitle: "All notifications on EasySource activity",
            icon: (
                <NotificationsRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        },
        {
            href: "https://forms.gle/PHMMSKnn98msnWGs9",
            target: "_blank",
            title: "Share feedback",
            subtitle: "Fill form or write to use at customer-support@hirequotient.com",
            icon: (
                <ThumbUpAltRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        },
    ];

    if (allowPasswordReset) {
        links.push({
            href: "/reset-password?step=1",
            target: "_blank",
            title: "Reset your password",
            subtitle: "Quickly reset your password in 2 steps",
            icon: (
                <LockOpenIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        });
    }

    if (!isCalendlyAuthorized) {
        links.push({
            href: CALENDLY_URI,
            target: "_blank",
            title: "Connect Calendly",
            subtitle: "Connect to your calendly account",
            icon: (
                <CalendarTodayRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        });
    }

    return links;
};

export default function AccountMenu({ setShowReferralModal, startWalkthrough }: IAccountMenuProps) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isCalendlyAuthorized = useSelector(checkCalendlyAuthorized);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const emailAuthorized = user.emailAuthorized;
    const allowPasswordReset = useSelector(checkAllowPasswordReset);
    const showAuthorizeModal = () => {
        dispatch(setAuthorizeModal(true));
    };
    const onClickLinkedinAuthRefresh = (e: any) => {
        e.preventDefault();
        dispatch(authLogin({ action: authLogin.type, user }));
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openEl = Boolean(anchorEl);

    const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

    const closeMenu = () => setAnchorEl(null);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    const NavigationLinks = GenerateNavigationLinks({
        isCalendlyAuthorized: Boolean(isCalendlyAuthorized),
        allowPasswordReset: Boolean(allowPasswordReset),
    });

    const id = openEl ? "user-popover" : undefined;

    return (
        <>
            <ProfileAvatar onClick={toggleMenu} />
            <Popover
                id={id}
                open={openEl}
                anchorEl={anchorEl}
                onClose={closeMenu}
                className="mr-3 mt-5 rounded-md shadow-[0_2px_8px_0_rgba(99,99,99,0.2)]"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div className={style["acc__navigationContainer"]}>
                    <div className="flex flex-row p-[15px] gap-2">
                        <ProfileAvatar />
                        <div className="flex flex-col">
                            <Typography className="text-[14px] font-medium">{user?.displayName}</Typography>
                            <Typography className="text-[13px] text-gray-600 italic">{user.email}</Typography>
                        </div>
                    </div>
                    <div className="flex flex-col" style={{ borderTop: "0.7px solid #e0e0e0" }}>
                        {NavigationLinks.map(({ href, title, subtitle, icon, target }) => {
                            return (
                                <div
                                    key={href}
                                    className="flex flex-row gap-1 p-0 pt-4 pb-4 hover:cursor-pointer hover:bg-gray-200 group"
                                >
                                    <div className="pl-3">{icon}</div>

                                    <div className="flex flex-col ml-1">
                                        <Link target={target && target} style={{ textDecoration: "none" }} to={href}>
                                            <Typography className="link-title text-[14px] text-[black]">
                                                {title}
                                            </Typography>
                                            <Typography className="text-[13px] text-gray-600 italic">
                                                {subtitle}
                                            </Typography>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                        {!emailAuthorized && (
                            <div className="flex flex-row p-0 gap-1  pt-4 pb-4 hover:cursor-pointer hover:bg-gray-200 group">
                                <div className="pl-3">
                                    <EmailRoundedIcon
                                        className="icon"
                                        sx={(theme) => ({
                                            width: "20px",
                                            height: "20px",
                                            color: theme.palette.grey[500],
                                            marginTop: 0.25,
                                        })}
                                    />
                                </div>

                                <div className="flex flex-col">
                                    <div style={{ textDecoration: "none" }} onClick={showAuthorizeModal}>
                                        <Typography className="link-title text-[14px] text-[black]">
                                            Authorize Mail
                                        </Typography>
                                        <Typography className="text-[13px] text-gray-600 italic">
                                            Connect to your mail account
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isLinkedInAuthorized && (
                            <div className="flex flex-row p-0 gap-1 pt-4 pb-4 hover:cursor-pointer hover:bg-gray-200 group">
                                <div className="pl-3">
                                    <EmailRoundedIcon
                                        className="icon"
                                        sx={(theme) => ({
                                            width: "20px",
                                            height: "20px",
                                            color: theme.palette.grey[500],
                                            marginTop: 0.25,
                                        })}
                                    />
                                </div>

                                <div className="flex flex-col">
                                    <div
                                        style={{ textDecoration: "none" }}
                                        onClick={() => {
                                            dispatch(
                                                setModal({
                                                    key: "showLinkedInAuthorizeModal",
                                                    value: true,
                                                })
                                            ),
                                                dispatch(setLinkedinAuthStatus(null));
                                        }}
                                    >
                                        <div className="flex flex-row gap-0.5 items-center">
                                            <Typography className="link-title text-[14px] text-[black]">
                                                Authorize Linkedin
                                            </Typography>
                                            <button onClick={onClickLinkedinAuthRefresh}>
                                                <CachedRoundedIcon
                                                    className="icon"
                                                    sx={(theme) => ({
                                                        width: "20px",
                                                        height: "20px",
                                                        color: theme.palette.grey[500],
                                                        marginTop: 0.25,
                                                    })}
                                                />
                                            </button>
                                        </div>

                                        <Typography className="text-[13px] text-gray-600 italic">
                                            Connect to your linkedin account
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className="flex flex-row p-0 gap-1  pt-4 pb-4 hover:cursor-pointer hover:bg-gray-200"
                            onClick={handleSignOut}
                        >
                            <div className="pl-3">
                                <LogoutRoundedIcon
                                    className="icon"
                                    sx={(theme) => ({
                                        width: "20px",
                                        height: "20px",
                                        color: theme.palette.grey[500],
                                        marginTop: 0.25,
                                    })}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Typography className="link-title text-[14px] text-[black]">Logout</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
}
