import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { EQueryKeys } from "./super-inbox.enums";
import {
    TCandidateEmail,
    TCandidateLinkedin,
    TCandidateSMS,
    TCandidatesList,
    TCandidatesListResponse,
    TChannel,
    TFilters,
} from "./super-inbox.types";

import { fetchBaseQueryWithZod } from "../utils";

import { IProject } from "@/store/reducers/project/project.types";

type CandidateChannelProps = {
    candidateId: string;
    projectIds: number[];
    userId: number;
};

const { SMS_CONVERSATIONS, EMAIL_CONVERSATIONS, LINKEDIN_CONVERSATIONS, CANDIDATES, PROJECTS, CANDIDATE_INFO } =
    EQueryKeys;

export const superInboxApi = createApi({
    reducerPath: "superInboxApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [SMS_CONVERSATIONS, EMAIL_CONVERSATIONS, LINKEDIN_CONVERSATIONS, CANDIDATES, PROJECTS, CANDIDATE_INFO],
    endpoints: (builder) => ({
        getProjects: builder.query<IProject[], void>({
            query: () => ({
                url: "v2/project/list",
                method: "GET",
            }),
            providesTags: [PROJECTS],
            transformResponse(baseQueryReturnValue: { data: IProject[] }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getEmailConversations: builder.query<TCandidateEmail, CandidateChannelProps>({
            query: ({ candidateId, ...rest }) => ({
                url: `/inbox/candidate/${candidateId}/email?${queryString.stringify(rest)}`,
                method: "GET",
            }),
            transformResponse(baseQueryReturnValue: { data: TCandidateEmail }) {
                return baseQueryReturnValue?.data;
            },
            providesTags: [EMAIL_CONVERSATIONS],
        }),
        getLinkedinConversations: builder.query<TCandidateLinkedin, CandidateChannelProps>({
            query: ({ candidateId, ...rest }) => ({
                url: `/inbox/candidate/${candidateId}/linkedin?${queryString.stringify(rest)}`,
                method: "GET",
            }),
            transformResponse(baseQueryReturnValue: { data: TCandidateLinkedin }) {
                return baseQueryReturnValue.data;
            },
            providesTags: [LINKEDIN_CONVERSATIONS],
        }),
        getSMSConversations: builder.query<TCandidateSMS, CandidateChannelProps>({
            query: ({ candidateId, ...rest }) => ({
                url: `/inbox/candidate/${candidateId}/sms?${queryString.stringify(rest)}`,
                method: "GET",
            }),
            transformResponse(baseQueryReturnValue: { data: TCandidateSMS }) {
                return baseQueryReturnValue.data;
            },
            providesTags: [SMS_CONVERSATIONS],
        }),
        getCandidatesList: builder.query<TCandidatesList, TFilters>({
            query: (filters) => ({
                url: `/inbox/candidate/list?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            transformResponse(baseQueryReturnValue: TCandidatesListResponse) {
                return baseQueryReturnValue.data;
            },
            providesTags: [CANDIDATES],
            // extraOptions: {
            //     dataSchema: candidatesListResponseSchema,
            // },
        }),
        getCandidatesListInfinite: builder.query<TCandidatesList, TFilters>({
            query: (filters) => ({
                url: `/inbox/candidate/list?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            transformResponse(baseQueryReturnValue: TCandidatesListResponse) {
                return baseQueryReturnValue.data;
            },
            providesTags: [CANDIDATES],
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.isFiltersUpdated) {
                    currentCache.candidates = newItems.candidates;
                    currentCache.totalCandidates = newItems.totalCandidates;
                } else {
                    // for mobile pagination we need to merge next page content for scroll view
                    currentCache.candidates.push(...newItems.candidates);
                    currentCache.totalCandidates = newItems.totalCandidates;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            // extraOptions: {
            //     dataSchema: candidatesListResponseSchema,
            // },
        }),
        sendSMS: builder.mutation<void, { candidateId: string; smsBody: string; projectId: number }>({
            query: (payload) => {
                return {
                    url: "/sms/send-sms",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: [SMS_CONVERSATIONS],
        }),

        sendEmail: builder.mutation<void, { candidate: string; threadId: string; subject: string; body: string }>({
            query: (payload) => {
                return {
                    url: "/communication/email-response",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: [EMAIL_CONVERSATIONS],
        }),

        markAsUnread: builder.mutation<
            { success: boolean; message: string },
            { projectId: number; candidateId: string; channel: TChannel; userId: number }
        >({
            query: (payload) => {
                return {
                    url: "/inbox/mark-as-unread",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        
        syncEmail: builder.mutation<void, { candidate: string; threadId: string; }>({
            query: (payload) => {
                return {
                    url: "/communication/sync-email-thread",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: [EMAIL_CONVERSATIONS],
        }),
    }),
});

export const {
    // queries
    useGetProjectsQuery,
    useGetEmailConversationsQuery,
    useGetLinkedinConversationsQuery,
    useGetSMSConversationsQuery,
    useGetCandidatesListQuery,
    useGetCandidatesListInfiniteQuery,
    // lazy queries
    useLazyGetCandidatesListQuery,
    useLazyGetCandidatesListInfiniteQuery,
    useLazyGetLinkedinConversationsQuery,
    useLazyGetProjectsQuery,
    useLazyGetSMSConversationsQuery,
    usePrefetch,
    // mutations
    useSendSMSMutation,
    useSendEmailMutation,
    useSyncEmailMutation,
    useMarkAsUnreadMutation,
} = superInboxApi;
