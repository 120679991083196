import ChatIcon from "@mui/icons-material/Chat";
import { ReactNode } from "react";

interface ChatsPaneEmptyState {
    children: ReactNode;
}

export default function ChatsPaneEmptyState({ children }: ChatsPaneEmptyState) {
    return (
        <div className="flex flex-1 items-center justify-center p-2 text-[12px]">
            <ChatIcon style={{ fontSize: 50 }} />
            {children}
        </div>
    );
}
