import { useDispatch, useSelector } from "react-redux";

import { useOutreachParams } from "./useOutreachParams.hook";

import {
    useFetchOutreachByIdQuery,
    useGetWarningStatusQuery,
    useSaveOutreachTemplateMutation,
} from "../../../store/apis/outreach/outreach.api";
import { OutreachEventValue, WorkflowStepValue } from "../../../store/apis/outreach/outreach.types";

import { selectOutReachFlow, setWarningModalOpen } from "@/store/reducers/outreach/outreach.slice";
import { selectUnsubscribeEmail } from "@/store/reducers/workflow/workflow.reducer";

type UseSaveOutreachTemplateProps = {
    successCallback?: (data: OutreachEventValue) => void;
    errorCallback?: (error: any) => void;
};

export function useSaveOutreachTemplate({ errorCallback, successCallback }: UseSaveOutreachTemplateProps = {}) {
    const [saveTemplateMutation, { ...saveMutationState }] = useSaveOutreachTemplateMutation();
    const { outreachId, projectId } = useOutreachParams();
    const unsubscribeEmail = useSelector(selectUnsubscribeEmail);

    const saveTemplate = (template: WorkflowStepValue) => {
        const { _id, isNew, stepId, isSaved, ...rest } = template;
        saveTemplateMutation({
            projectId: Number(projectId),
            _id,
            isNew,
            stepId: isNew && isNew === true ? undefined : stepId,
            workflowId: outreachId,
            unsubscribeEmail,
            ...rest,
        })
            .unwrap()
            .then((res) => {
                successCallback?.(res);
            })
            .catch((err) => {
                console.log({ err });
                errorCallback?.(err);
            });
    };

    return {
        saveTemplate,
        saveMutationState,
    };
}

export function useSaveMultipleOutreachTemplates({ errorCallback, successCallback }: UseSaveOutreachTemplateProps) {
    const outreach = useSelector(selectOutReachFlow);
    const { outreachId, projectId } = useOutreachParams();
    const { data: isWarning } = useGetWarningStatusQuery(projectId);
    const dispatch = useDispatch();

    const { data: { workflowSteps = [] } = {} } = useFetchOutreachByIdQuery(outreachId);

    const { saveTemplate, saveMutationState } = useSaveOutreachTemplate({ errorCallback, successCallback });
    const saveAllTemplates = (isNotSaved = true) => {
        const duplicateEventBodies = [];
        outreach.forEach((outreachElement) => {
            const workflowStep = workflowSteps.find((step) => step._id === outreachElement._id);
            if (workflowStep && workflowStep.eventBody.body.trim() === outreachElement.eventBody.body.trim()) {
                duplicateEventBodies.push(workflowStep.eventName);
            }
        });
        if (duplicateEventBodies.length > 0 && !isWarning?.warningIgnoredByUser && isNotSaved) {
            dispatch(setWarningModalOpen({ duplicates: duplicateEventBodies, open: true }));
            return;
        }
        outreach.forEach((outreachElement) => {
            saveTemplate(outreachElement);
        });
    };

    return {
        saveAllTemplates,
        saveMutationState,
    };
}
