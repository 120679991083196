import { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import CallEndIcon from "@mui/icons-material/CallEnd";
import Close from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { Grid, IconButton, Box as JoyBox, Button as JoyButton } from "@mui/joy";
import { Alert, Autocomplete, Box, Modal, Paper, Popper, TextField, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { useDispatch } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { CountryCodes } from "../../../../constant/countryCodes";
import { useCallContext } from "./TwilloCallContext";

export function CallModal({
    modalOpen,
    toggleModal,
    candidate,
    editPhoneNumber,
    setEditPhoneNumber,
    handleBlockCandidate,
}) {
    return (
        <Modal
            open={modalOpen}
            onClose={toggleModal}
            aria-labelledby="call-modal-title"
            aria-describedby="call-modal-description"
        >
            <CallModalContent
                candidate={candidate}
                editPhoneNumber={editPhoneNumber}
                setEditPhoneNumber={setEditPhoneNumber}
                handleBlockCandidate={handleBlockCandidate}
                block={true}
                powerDialer={false}
            />
        </Modal>
    );
}

export function CallModalContent({
    candidate,
    editPhoneNumber,
    setEditPhoneNumber,
    handleBlockCandidate,
    block,
    powerDialer,
}) {
    const dispatch = useDispatch();
    const [isMuted, setIsMuted] = useState(false);

    const {
        duration,
        setDuration,
        phoneNumber,
        setPhoneNumber,
        makeOutgoingCall,
        callData,
        hangUpCall,
        callInProgress,
        selectedCountry,
        setSelectedCountry,
    } = useCallContext();
    useEffect(() => {
        if (candidate && !powerDialer) {
            // Find the first valid phone number and set it as the phone number
            const validNumber = candidate.phone?.find((phone) => phone !== null);

            if (validNumber) {
                setPhoneNumber(validNumber);
            }
        }
    }, [candidate, editPhoneNumber, powerDialer]);

    const handleButtonClick = (number: string) => {
        if (callInProgress) {
            sendDMTF(number);
        }
        setPhoneNumber(phoneNumber + number);
    };
    const handlePhoneNumberChange = (event) => {
        const input = event.target.value;
        const cleanedInput = input.replace(/[\s()\-]/g, ""); // Remove spaces, brackets, and dashes
        const phoneNumberPattern = /^[0-9+]*$/;

        if (!phoneNumberPattern.test(cleanedInput)) {
            dispatch(setErrorNotification("Invalid phone number"));
        } else if (cleanedInput.length > 15) {
            dispatch(setErrorNotification("Invalid phone number"));
        } else {
            if (callInProgress) {
                sendDMTF(input.slice(-1));
            }
            setPhoneNumber(input);
        }
    };
    const sendDMTF = (digits: string) => {
        callData.sendDigits(digits);
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (callInProgress) {
            timer = setInterval(() => {
                setDuration((prevDuration: number) => prevDuration + 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [callInProgress]);

    const formatDuration = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };
    const handleMute = () => {
        callData.mute();
    };

    const handleUnmute = () => {
        callData.mute(false);
    };

    const handleMuteToggle = () => {
        setIsMuted((prev) => !prev);
        if (isMuted) {
            handleUnmute();
        } else {
            handleMute();
        }
    };
    useEffect(() => {
        if (candidate?.phone?.length > 0 && !phoneNumber) {
            // Check if there's an initial phone number from the candidate's data
            const initialPhoneNumber = candidate.phone.find((phone) => phone !== null);

            if (initialPhoneNumber) {
                // Find the matched country for the initial phone number
                const matchedCountry = CountryCodes.find(
                    (country) =>
                        initialPhoneNumber.startsWith(country.dial_code) ||
                        initialPhoneNumber.startsWith(country.dial_code.replace("+", ""))
                );

                if (matchedCountry) {
                    setSelectedCountry(matchedCountry.dial_code);
                    setPhoneNumber(initialPhoneNumber.replace(matchedCountry.dial_code, ""));
                } else {
                    setPhoneNumber(initialPhoneNumber);
                }
            }
        } else if (phoneNumber) {
            // If phoneNumber has a value, find the country based on the entered phone number
            const matchedCountry = CountryCodes.find((country) => phoneNumber.startsWith(country.dial_code));

            if (matchedCountry) {
                setSelectedCountry(matchedCountry.dial_code);
                setPhoneNumber(phoneNumber.replace(matchedCountry.dial_code, ""));
            }
        }
    }, [candidate?.phone, phoneNumber]);

    const handleCountryChange = (newCountryCode) => {
        setSelectedCountry(newCountryCode);
    };

    const StyledPopper = styled(Popper)({
        width: "300px !important", // Width of the dropdown list
    });

    const StyledPaper = styled(Paper)({
        width: 300, // Same width as StyledPopper to ensure consistency
    });

    return (
        <Box>
            <Box
                sx={{
                    position: !powerDialer ? "absolute" : null,
                    top: !powerDialer ? "50%" : null,
                    left: !powerDialer ? "50%" : null,
                    transform: block ? "translate(-50%, -50%)" : !powerDialer ? "translate(-20%, -40%)" : null,
                    width: {
                        xs: "95%",
                        sm: "90%",
                        md: !powerDialer ? "300px" : "200px",
                        lg: !powerDialer ? "400px" : "300px",
                    },
                    maxWidth: "400px",
                    maxHeight: !block && !powerDialer ? "80%" : "100%",
                    overflowY: "auto",
                    bgcolor: "background.paper",
                    boxShadow: !powerDialer ? 24 : null,
                    p: { xs: 2, sm: 3 },
                    borderRadius: 2,
                }}
            >
                {!callInProgress ? (
                    <Typography
                        id="call-modal-title"
                        variant="h6"
                        component="h2"
                        textAlign="center"
                        mb={!block ? 0 : 2}
                    >
                        {candidate?.name ? `Call  ${candidate?.name}` : "Unknown"}
                    </Typography>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <PhoneInTalkIcon sx={{ fontSize: 16, color: "success.main", mb: "4px" }} />
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{ fontSize: 16, color: "success.main", ml: 1 }}
                        >
                            Connected with {candidate?.name ? ` ${candidate?.name}` : "Unknown"}
                        </Typography>
                    </div>
                )}

                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <Box sx={{ width: { xs: 60, sm: 80 }, height: { xs: 60, sm: 80 } }}>
                        {candidate?.profileImage ? (
                            <img
                                src={candidate?.profileImage}
                                alt={candidate?.name}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                }}
                            />
                        ) : (
                            <AccountCircleIcon sx={{ width: "100%", height: "100%" }} />
                        )}
                    </Box>
                </Box>
                {callInProgress && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
                            {formatDuration(duration)}
                        </Typography>
                    </div>
                )}

                {candidate?.phone?.length > 0 && (
                    <Typography
                        variant="subtitle1"
                        component="h3"
                        sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 2 }}
                    >
                        {candidate?.phone?.find((phone) => phone !== null)}
                        {!powerDialer && (
                            <JoyProvider>
                                <IconButton size="sm" onClick={() => setEditPhoneNumber(true)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </JoyProvider>
                        )}
                    </Typography>
                )}

                {editPhoneNumber && (
                    <JoyProvider>
                        <JoyBox sx={{ width: "100%", mb: 2 }}>
                            {candidate?.phone?.find((phone) => phone !== null) && (
                                <JoyBox sx={{ display: "flex", justifyContent: "flex-end", mb: { lg: 1, md: 0 } }}>
                                    <IconButton
                                        onClick={() => setEditPhoneNumber(false)}
                                        sx={{ fontSize: { lg: 20, md: "10px" } }}
                                    >
                                        <Close />
                                    </IconButton>
                                </JoyBox>
                            )}

                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <BackspaceOutlinedIcon
                                    onClick={() => setPhoneNumber(phoneNumber.slice(0, -1))}
                                    sx={{ cursor: "pointer", fontSize: "20px", mb: 1 }}
                                />
                            </div>

                            <MaterialProvider>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <Autocomplete
                                        options={CountryCodes}
                                        getOptionLabel={(option) => `${option.flag} ${option.dial_code}`}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.code}>
                                                {option.flag} {option.name} ({option.dial_code})
                                            </li>
                                        )}
                                        value={CountryCodes.find((country) => country.dial_code === selectedCountry)}
                                        onChange={(event, newValue) => {
                                            handleCountryChange(newValue ? newValue.dial_code : "");
                                        }}
                                        disableClearable
                                        PopperComponent={StyledPopper}
                                        PaperComponent={(props) => <StyledPaper {...props} />}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Country"
                                                sx={{
                                                    mb: 2,
                                                    width: 120,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                                size={!block ? "small" : "medium"}
                                            />
                                        )}
                                    />

                                    <TextField
                                        value={phoneNumber}
                                        onChange={(event) => handlePhoneNumberChange(event)}
                                        placeholder="Phone Number"
                                        fullWidth
                                        sx={{
                                            mb: 2,
                                            width: "70%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        hidden
                                        size={!block ? "small" : "medium"}
                                    />
                                </div>
                            </MaterialProvider>

                            <Grid container spacing={1}>
                                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "0", "#"].map((number) => (
                                    <Grid
                                        item
                                        xs={4}
                                        key={number}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                        component="div"
                                    >
                                        <JoyButton
                                            variant="outlined"
                                            onClick={() => handleButtonClick(number)}
                                            sx={{
                                                width: { lg: "100%", md: "100%" },
                                                color: "#0891B2",

                                                fontSize: { lg: 20, md: 16 },
                                            }}
                                            size={!block ? "sm" : "md"}
                                        >
                                            {number}
                                        </JoyButton>
                                    </Grid>
                                ))}
                            </Grid>
                        </JoyBox>
                    </JoyProvider>
                )}
                {!callInProgress ? (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <JoyProvider>
                            <IconButton
                                onClick={() => makeOutgoingCall(candidate, "DIALER")}
                                disabled={callInProgress || !phoneNumber}
                                sx={{
                                    backgroundColor: "#5fb45f",
                                    color: "white",
                                    border: "1px solid #5fb45f",
                                    "&:hover": {
                                        backgroundColor: "#5fb45f",
                                        border: "1px solid #5fb45f",
                                        color: "white",
                                    },
                                    "&:disabled": {
                                        backgroundColor: "#5fb45f",
                                        border: "1px solid #5fb45f",
                                        color: "white",
                                    },
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "24px",
                                }}
                            >
                                <PhoneIcon sx={{ color: "white" }} />
                            </IconButton>
                        </JoyProvider>
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <JoyProvider>
                            <IconButton
                                onClick={hangUpCall}
                                sx={{
                                    backgroundColor: "#d32f2f",
                                    color: "white",
                                    border: "1px solid #d32f2f",
                                    "&:hover": {
                                        backgroundColor: "#d32f2f",
                                        border: "1px solid #d32f2f",
                                        color: "white",
                                    },
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                }}
                            >
                                <CallEndIcon sx={{ color: "white" }} />
                            </IconButton>
                            <IconButton
                                onClick={handleMuteToggle}
                                sx={{
                                    backgroundColor: isMuted ? "#f57c00" : "#1976d2",
                                    color: "white",
                                    border: `1px solid ${isMuted ? "#f57c00" : "#1976d2"}`,
                                    "&:hover": {
                                        backgroundColor: isMuted ? "#f57c00" : "#1976d2",
                                        border: `1px solid ${isMuted ? "#f57c00" : "#1976d2"}`,
                                        color: "white",
                                    },
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "24px",
                                }}
                            >
                                {isMuted ? <MicOffIcon sx={{ color: "white" }} /> : <MicIcon sx={{ color: "white" }} />}
                            </IconButton>
                        </JoyProvider>
                    </div>
                )}

                {block && candidate?.phone?.find((phone) => phone !== null) && (
                    <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
                        {/* <AlertTitle>Warning</AlertTitle> */}

                        <span
                            style={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                color: "#a73b3b",
                                textDecoration: "underline",
                            }}
                            onClick={handleBlockCandidate}
                        >
                            Block Candidate
                        </span>
                    </Alert>
                )}
            </Box>
        </Box>
    );
}
