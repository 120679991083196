export enum EQueries {
    MULTIPLE_USER_FUNNEL = "multiple-users-funnel",
    FUNNEL_OVERVIEW = "Funnel_overview",
    OUTREACH_STATS = "Outreach_stats",
    PROJECTS_STATUS = "Projects_status",
    CREDIT_USAGE = "Credit_usage",
    SOURCE_GRAPH = "Source_graph",
    CHANNEL_GRAPH = "Channel_graph",
    GRAPH = "Graph",
    TOP_PROJECT_DETAILS = "Top_project_details",
    RECENT_PROJECT_DETAILS = "Recent_project_details",
    EMAIL_OUTREACH_STATS = "Email_outreach_stats",
    LINKEDIN_OUTREACH_STATS = "Linkedin_outreach_stats",
    SMS_OUTREACH_STATS = "Sms_outreach_stats",
    CALLING_OUTREACH_STATS = "Calling_outreach_stats",
    RB2B_OUTREACH_STATS = "RB2B_outreach_stats",
    EMAIL_OUTREACH_STATS_DETAILED = "Email_outreach_stats_detailed",
    LINKEDIN_OUTREACH_STATS_DETAILED = "Linkedin_outreach_stats_detailed",
    SMS_OUTREACH_STATS_DETAILED = "Sms_outreach_stats_detailed",
    CALLING_OUTREACH_STATS_DETAILED = "Calling_outreach_stats_detailed",
    RB2B_OUTREACH_STATS_DETAILED = "RB2B_outreach_stats_detailed",
    LEADER_BOARD = "Leader_board",
}

export enum EFunnelOverview {
    MEETINGS_BOOKED = "meetingsBooked",
    POSITIVE_RESPONSES = "positiveResponses",
    RELEVANT_CANDIDATES = "relevantCandidates",
    RESPONSES_RECEIVED = "responsesReceived",
    TOTAL_CONTACTED = "totalContacted",
}

export enum EChannel {
    SMS = "sms",
    EMAIL = "email",
    LINKEDIN = "linkedin",
    CALLING = "calling",
}

export const EChannelLabel = {
    [EChannel.SMS]: "SMS",
    [EChannel.LINKEDIN]: "LinkedIn",
    [EChannel.EMAIL]: "Email",
} as const;

export const EFunnelOverviewLabel = {
    [EFunnelOverview.MEETINGS_BOOKED]: "Meetings booked",
    [EFunnelOverview.POSITIVE_RESPONSES]: "Positive responses",
    [EFunnelOverview.RELEVANT_CANDIDATES]: "Relevant candidates",
    [EFunnelOverview.RESPONSES_RECEIVED]: "Responses received",
    [EFunnelOverview.TOTAL_CONTACTED]: "Total contacted",
} as const;

export const EFunnelOverviewLabelEasyGrowth = {
    [EFunnelOverview.MEETINGS_BOOKED]: "Meetings booked",
    [EFunnelOverview.POSITIVE_RESPONSES]: "Positive responses",
    [EFunnelOverview.RELEVANT_CANDIDATES]: "Total prospects",
    [EFunnelOverview.RESPONSES_RECEIVED]: "Responses received",
    [EFunnelOverview.TOTAL_CONTACTED]: "Total contacted",
};

export enum ECustomDateLabel {
    TODAY = "Today",
    LAST_7_DAYS = "Last 7 days",
    LAST_4_WEEKS = "Last 4 weeks",
    LAST_3_MONTHS = "Last 3 months",
    CUSTOM = "Custom",
    NONE = "none",
}
