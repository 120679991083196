import { Stack } from "@mui/material";

import Candidates from "./Components/Candidates/Candidates";
import { Filters } from "./Components/Filters/Filters";

export const AdvancedFilters = () => {
    return (
        <Stack direction="row" spacing={2} height="97%">
            <Filters />
            <Candidates />
        </Stack>
    );
};
