import { zodResolver } from "@hookform/resolvers/zod";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Skeleton from "@mui/joy/Skeleton";
import Stack, { StackProps } from "@mui/joy/Stack";
import { styled } from "@mui/joy/styles";
import Textarea from "@mui/joy/Textarea";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { Control, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { CandidateInfoContentContainer } from "./ContactInfo";
import CustomAccordion, { CustomAccordionDetails, CustomAccordionSummary } from "./CustomAccordion";

import {
    Reference,
    useCreateReferencesMutation,
    useDeleteReferenceMutation,
    useFetchReferencesQuery,
    useUpdateReferencesMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";

import {
    editReferences,
    initialEditReferencesState,
    selectReferencesFormMode,
    selectReferencesState,
    setEditDrawer,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import {
    CreateReferenceRequestPayload,
    ReferencesFormState,
    ReferencesKeys,
    referencesFormStateSchema,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.types";

export function References() {
    return (
        <CandidateInfoContentContainer enableEdit={true} editComponent={<ReferencesSidebar />}>
            <AllCandidatesReachOutBodyContainer>
                <ReferencesHeader />
                <Divider />
                <ReferencesContainer>
                    <ReferencesMain />
                </ReferencesContainer>
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

const ReferencesContainer = styled((props: StackProps) => {
    return (
        <Stack
            m={2}
            pb={2}
            gap={1}
            sx={{
                overflow: "auto",
                height: "calc(100vh - 225px)",
                borderBottom: 1,
                borderColor: "divider",
                ...props.sx,
            }}
            {...props}
        />
    );
})({});

function ReferencesLoading() {
    return (
        <ReferencesContainer>
            {Array(10)
                .fill(1)
                .map((_, idx) => (
                    <Skeleton variant="rectangular" height={60} key={`references-loading-${idx}`} />
                ))}
        </ReferencesContainer>
    );
}

function useReferences() {
    const params = useParams();
    const { data = [], ...rest } = useFetchReferencesQuery(params?.id || "");

    return { data, ...rest };
}

function ReferencesMain() {
    const { isError, data, isLoading } = useReferences();

    if (isLoading) {
        return <ReferencesLoading />;
    }

    if (isError || !data.length) {
        return <EmptyReferencesPlaceholder />;
    }

    return (
        <>
            {data.map((i) => {
                return <ReferenceCard {...i} key={i._id} />;
            })}
        </>
    );
}

function ReferencesHeader() {
    const dispatch = useDispatch();
    const openDrawer = () => dispatch(setEditDrawer("TOGGLE"));
    return (
        <Stack direction="row" justifyContent="space-between" p={1.2} sx={{ paddingRight: "3.1rem" }}>
            <Button startDecorator={<AddCircleRoundedIcon sx={{ color: "white" }} />} onClick={openDrawer}>
                Add References
            </Button>
        </Stack>
    );
}

function ReferenceCard(props: Reference) {
    const dispatch = useDispatch();
    const [deleteReference, { isLoading }] = useDeleteReferenceMutation();
    const [isExpanded, setExpanded] = useState(false);
    const { referrerEmail, referrerLinkedinUrl, referrerName, referrerPhoneNo, body, relation, _id, title } = props;

    const handleEditReferences = () => {
        dispatch(
            editReferences({
                contact: referrerPhoneNo || "",
                linkedin: referrerLinkedinUrl || "",
                email: referrerEmail || "",
                name: referrerName,
                networkType: title || "",
                notes: body || "",
                id: _id,
                relation,
            })
        );
    };

    return (
        <CustomAccordion expanded={isExpanded} onChange={() => setExpanded((prev) => !prev)}>
            <CustomAccordionSummary
                indicator={
                    <Tooltip title="More">
                        <UnfoldMoreDoubleIcon fontSize="small" />
                    </Tooltip>
                }
                isExpanded={isExpanded}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Stack>
                        <Typography level="body-sm" fontWeight={600}>
                            {referrerName}
                        </Typography>
                        <Typography level="body-sm" fontWeight={600}>
                            {relation}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <Tooltip title="Delete">
                            <IconButton
                                disabled={isLoading}
                                size="sm"
                                sx={{
                                    width: "fit-content",
                                    height: "fit-content",
                                }}
                                onClick={() => deleteReference(_id)}
                            >
                                <DeleteOutlineRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Create">
                            <IconButton
                                size="sm"
                                sx={{
                                    width: "fit-content",
                                    height: "fit-content",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditReferences();
                                }}
                            >
                                <CreateRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </CustomAccordionSummary>
            <CustomAccordionDetails isExpanded={isExpanded}>
                <Stack gap={0.7}>
                    <ReferenceCardItem label="Email" value={referrerEmail} />
                    {/* <ReferenceCardItem label="Relation" value={relation} /> */}
                    <ReferenceCardItem label="Contact" value={referrerPhoneNo} />
                    <ReferenceCardItem label="LinkedIn" value={referrerLinkedinUrl} />
                    <ReferenceCardItem label="Notes" value={body} />
                </Stack>
            </CustomAccordionDetails>
        </CustomAccordion>
    );
}

function ReferenceCardItem({ label, value }: { label: string; value?: string | null }) {
    if (!value) {
        return null;
    }

    return (
        <Box sx={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
            <Typography level="body-sm">{label}</Typography>
            <Typography
                level="body-sm"
                sx={{
                    color: grey["A700"],
                    wordBreak: "break-word",
                }}
            >
                {value}
            </Typography>
        </Box>
    );
}

type ReferencesInputProps<T> = {
    control: Control<ReferencesFormState>;
    name: T;
};

const keyToLabelMapping: Record<ReferencesKeys, string> = {
    contact: "Contact Number",
    email: "Email",
    linkedin: "LinkedIn",
    name: "Name",
    networkType: "Network Type",
    notes: "Notes",
    relation: "Relation",
    id: "",
};

function ReferencesInput({ control, name }: ReferencesInputProps<keyof ReferencesFormState>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => {
                const isError = error !== undefined;
                return (
                    <FormControl error={isError}>
                        <FormLabel>{keyToLabelMapping[name]}</FormLabel>
                        {name === "notes" ? (
                            <Textarea
                                {...field}
                                size="sm"
                                minRows={15}
                                style={{ width: "100%" }}
                                error={error !== undefined}
                                value={field.value || ""}
                            />
                        ) : (
                            <Input {...field} error={isError} value={field.value || ""} size="sm" />
                        )}
                        {isError && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function ReferencesSidebar() {
    const dispatch = useDispatch();
    const params = useParams();
    const [createReferences, { isLoading: isCreatingReferences, isSuccess: isCreatingReferencesSuccess }] =
        useCreateReferencesMutation();
    const [updateReferences, { isLoading: isUpdatingReferences, isSuccess: isUpdatingReferencesSuccess }] =
        useUpdateReferencesMutation();
    const isLoading = isCreatingReferences || isUpdatingReferences;
    const referenceFormState = useSelector(selectReferencesState);
    const submitMode = useSelector(selectReferencesFormMode);
    const methods = useForm<ReferencesFormState>({
        defaultValues: referenceFormState,
        resolver: zodResolver(referencesFormStateSchema),
    });

    useEffect(() => {
        if (submitMode === "EDIT") {
            methods.reset(referenceFormState);
        }

        if (submitMode === "CREATE") {
            methods.reset(initialEditReferencesState);
        }
    }, [referenceFormState, submitMode]);

    useEffect(() => {
        if (isCreatingReferencesSuccess || isUpdatingReferencesSuccess) {
            methods.reset(initialEditReferencesState);
            dispatch(setEditDrawer("CLOSE"));
        }
    }, [isCreatingReferencesSuccess, isUpdatingReferencesSuccess, methods]);

    const getReferencesInputProps = <T extends keyof ReferencesFormState>(name: T): ReferencesInputProps<T> => ({
        control: methods.control,
        name,
    });

    const onSubmit: SubmitHandler<ReferencesFormState> = (data) => {
        const { name, contact, email, linkedin, networkType, notes, relation, id } = data;
        const requestPayload: CreateReferenceRequestPayload = {
            referrerName: name,
            referrerPhoneNo: contact,
            relation: relation,
            candidateId: params?.id ? params.id : "",
        };

        if (email) {
            requestPayload.referrerEmail = email;
        }

        if (linkedin) {
            requestPayload.referrerLinkedinUrl = linkedin;
        }

        if (networkType) {
            requestPayload.title = networkType;
        }

        if (notes) {
            requestPayload.body = notes;
        }

        if (submitMode === "CREATE" && requestPayload?.candidateId) {
            createReferences(requestPayload);
        }

        if (submitMode === "EDIT" && id) {
            updateReferences({
                ...requestPayload,
                referralId: id,
            });
        }
    };

    const handleCancel = () => {
        dispatch(setEditDrawer("CLOSE"));
        methods.reset(initialEditReferencesState);
    };

    const btnText = submitMode === "EDIT" ? "Update" : "Create";

    return (
        <ReferencesContainer
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            p={1}
            pr={2}
            spacing={1}
            sx={{
                height: "calc(100vh - 157px)",
                overflow: "auto",
                borderBottom: 1,
                borderColor: "divider",
            }}
        >
            <ReferencesInput {...getReferencesInputProps("name")} />
            <ReferencesInput {...getReferencesInputProps("email")} />
            <ReferencesInput {...getReferencesInputProps("contact")} />
            <ReferencesInput {...getReferencesInputProps("relation")} />
            <ReferencesInput {...getReferencesInputProps("linkedin")} />
            <ReferencesInput {...getReferencesInputProps("networkType")} />
            <ReferencesInput {...getReferencesInputProps("notes")} />
            <Stack direction="row" alignSelf="flex-end" gap={1}>
                <Button variant="solid" type="reset" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="solid" type="submit" disabled={isLoading}>
                    {btnText}
                </Button>
            </Stack>
        </ReferencesContainer>
    );
}

function EmptyReferencesPlaceholder() {
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: grey[400] }} />}
            title="No References found"
            message="References has not been added for this candidate on this project."
        />
    );
}
