import { Collapse, Skeleton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useRef } from "react";
import { Draggable, type DraggableProvided } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

import { DisableSMSSwitch } from "../../../components/DisableSMSSwitch";
import DisableWorkflowComponentOverlay from "../../../components/DisableWorkflowComponentOverlay";
import { chevronDownIconBlue } from "../../../constant/Constant";
import style from "../../../pages/editWorkflow/components/reminder/reminder.module.scss";
import { fieldName } from "../../editWorkflow/components/reminder/Reminder";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { checkSMSEnabled } from "@/store/reducers/signin/Signin.reducer";
import { selectEnableSMSSwitch } from "@/store/reducers/template/template.reducer";
import { setEditTemplatesModal } from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import { selectHyperPersonalizeMode } from "@/store/reducers/workflow/workflow.reducer";

interface reminderPropsType {
    isOpen: boolean;
    index: number;
    editorVal?: string;
    setEditorVal?: (tarInd: number, newValue: string) => void;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    type?: string;
    readable?: boolean;
    isLoading?: boolean;
    handleBlurChange?: () => void;
    status?: React.ReactNode;
    expectedStartTime?: string;
}

export function SMS(props: reminderPropsType) {
    const dispatch = useDispatch();
    const { styles } = useThemeContext();
    const {
        index,
        editorVal = "",
        setEditorVal,
        isOpen,
        toggleCollapse,
        type = "sms",
        isLoading,
        handleBlurChange,
        readable,
        handleDuplicate,
        handleRemove,
        status,
        expectedStartTime,
    } = props;
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);
    const enableConnectionReq = useSelector(selectEnableSMSSwitch);
    const bodyRef = useRef(null);

    if (readable || !handleDuplicate || !handleRemove || !setEditorVal) {
        return (
            <div className={style["rem__container"]}>
                <div className={`${style["rem__legendBox"]} ${index === 0 ? style["rem__legendBox-start"] : ""}`}>
                    <p
                        className={style["rem__legend"]}
                        onClick={() => toggleCollapse(index)}
                        style={{ display: "flex" }}
                    >
                        {type && fieldName(type, isOpen)}{" "}
                        <img
                            className={isOpen ? style["rem__invert"] : ""}
                            src={chevronDownIconBlue}
                            alt=""
                            style={{ marginLeft: "10px" }}
                        />
                        {status}
                    </p>
                    {expectedStartTime?.length > 1 && (
                        <div
                            style={{
                                marginLeft: "600px",
                                marginTop: "7px",
                                position: "absolute",
                                fontSize: "14px",
                                color: "#0891B2",
                            }}
                        >
                            <p>
                                {dayjs(expectedStartTime).isAfter(dayjs()) ? "Starting on: " : "Started on: "}
                                {dayjs(expectedStartTime).format("MMM D, h:mm A")}
                            </p>
                        </div>
                    )}
                </div>

                <Collapse in={isOpen}>
                    <div className={style["rem__body"]}>
                        {readable ? (
                            <Typography
                                style={{
                                    width: "100%",
                                    resize: "none",
                                    padding: 10,
                                    border: "1px solid #ccc",
                                    fontSize: "14px",
                                    color: styles.color,
                                }}
                                dangerouslySetInnerHTML={{ __html: editorVal }}
                            />
                        ) : (
                            <textarea
                                // ref={bodyRef}
                                value={editorVal}
                                disabled={readable}
                                rows={5}
                                style={{
                                    width: "100%",
                                    resize: "none",
                                    padding: 10,
                                }}
                            />
                        )}
                    </div>
                </Collapse>
            </div>
        );
    }

    if (!isSMSEnabled) {
        return null;
    }

    const handleReactQuillClick = () => {
        dispatch(setEditTemplatesModal(true));
    };

    return (
        <Draggable draggableId={`rem${index}`} index={index}>
            {(dragProvider: DraggableProvided) => (
                <div
                    className={style["rem"]}
                    ref={dragProvider.innerRef}
                    {...dragProvider.dragHandleProps}
                    {...dragProvider.draggableProps}
                >
                    <div className={style["rem__container"]} id="workflow-reminder">
                        <div className={style["rem__legendBox"]}>
                            <p
                                className={style["rem__legend"]}
                                onClick={() => toggleCollapse(index)}
                                style={{ display: "flex" }}
                            >
                                {type && fieldName(type)}{" "}
                                <img
                                    className={isOpen ? style["rem__invert"] : ""}
                                    src={chevronDownIconBlue}
                                    alt=""
                                    style={{ marginLeft: "10px" }}
                                />
                            </p>
                        </div>
                        <Collapse in={isOpen}>
                            {
                                <div className={style["rem__body"]}>
                                    {isLoading ? (
                                        <Skeleton variant="rounded" width="100%" height={200} />
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem",
                                                }}
                                                onClick={isHyperPersonalized ? handleReactQuillClick : undefined}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                    onBlur={handleBlurChange}
                                                >
                                                    {!enableConnectionReq && (
                                                        <DisableWorkflowComponentOverlay>
                                                            Enable this step to send sms.
                                                        </DisableWorkflowComponentOverlay>
                                                    )}
                                                    <ReactQuill
                                                        ref={bodyRef}
                                                        value={editorVal}
                                                        className={isHyperPersonalized ? "sms-quill" : ""}
                                                        onChange={(value, delta, source, editor) => {
                                                            if (source === "user") {
                                                                setEditorVal?.(index, value);
                                                            }
                                                        }}
                                                        readOnly={isHyperPersonalized}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <Stack
                                        style={{
                                            maxHeight: "50px",
                                            padding: "0.3rem 1rem",
                                            position: "absolute",
                                            top: "-40px",
                                            right: "50px",
                                            backgroundColor: "white",
                                            borderTopLeftRadius: "0.7rem",
                                            borderTopRightRadius: "0.7rem",
                                        }}
                                    >
                                        <DisableSMSSwitch />
                                    </Stack>
                                </div>
                            }
                        </Collapse>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
