import { Contact, Order } from "@/store/apis/all-contacts/all-contacts.types";

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === "boolean" || typeof bValue === "boolean") {
        return 0; // Ignore boolean properties
    }

    if (aValue === "-" && bValue === "-") {
        return 0;
    }

    if (aValue === "-") {
        return -1;
    }

    if (bValue === "-") {
        return 1;
    }

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof Contact>(
    order: Order,
    orderBy: Key
): (a: Contact, b: Contact) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
