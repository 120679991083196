import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import { CustomTextField } from "./Styles";

import { submitLinkedinOTP } from "@/store/reducers/signin/Signin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import CustomButton from "../CustomButton";

interface OTPModalProps {
    onSubmit: (otp: string) => void;
}

export function OTPModal(props: OTPModalProps) {
    const { onSubmit } = props;
    const isLoading = useSelector(checkIfLoading(submitLinkedinOTP.type));

    return (
        <Stack spacing={2}>
            <Stack
                spacing={1.5}
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    onSubmit(formData.get("otp") as string);
                }}
            >
                <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: "1.5" }}>
                    Enter the OTP you may have received on your registered email address or phone number
                </Typography>
                <CustomTextField name="otp" required fullWidth placeholder="Enter OTP" />
                <CustomButton disabled={isLoading} variant="contained" type="submit" sx={{ alignSelf: "center" }}>
                    {isLoading ? "Submitting..." : "Submit"}
                </CustomButton>
            </Stack>
        </Stack>
    );
}
