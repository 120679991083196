import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    fullWidth?: boolean;
    variant?: "standard" | "outlined" | "filled";
    label?: string;
    onChange?: (value: string) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, error, fullWidth, variant = "outlined", label, onChange, ...props }, ref) => {
        const [focused, setFocused] = React.useState(false);
        const [hasValue, setHasValue] = React.useState(!!props.value);

        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            setFocused(true);
            props.onFocus?.(e);
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            setFocused(false);
            props.onBlur?.(e);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setHasValue(value !== "");
            onChange?.(value);
        };

        const inputStyles = cn(
            "peer w-full bg-transparent text-base text-gray-900 transition-all",
            "focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            fullWidth ? "w-full" : "w-auto",
            variant === "standard" && "border-0 border-b-2 border-gray-300 px-0 py-2 focus:border-cyan-600",
            variant === "outlined" && "border border-gray-300 rounded px-3 py-2 focus:border-cyan-600",
            variant === "filled" &&
                "border-0 border-b-2 border-gray-300 bg-gray-100 px-3 pt-6 pb-2 focus:bg-gray-50 focus:border-cyan-600",
            error && "border-red-500 focus:border-red-500",
            className
        );

        const labelStyles = cn(
            "absolute left-0 transition-all pointer-events-none",
            variant === "standard" && "left-0",
            variant === "outlined" && "left-3",
            variant === "filled" && "left-3",
            (focused || hasValue) && variant === "standard" && "-top-3.5 text-sm",
            (focused || hasValue) && variant === "outlined" && "-top-3 text-sm bg-white px-1",
            (focused || hasValue) && variant === "filled" && "text-sm top-2",
            !focused && !hasValue && variant === "standard" && "top-2 text-base",
            !focused && !hasValue && variant === "outlined" && "top-2 text-base",
            !focused && !hasValue && variant === "filled" && "top-4 text-base",
            error ? "text-red-500" : "text-gray-600 peer-focus:text-cyan-600",
            "peer-disabled:text-gray-500"
        );

        const wrapperStyles = cn("relative inline-flex", fullWidth ? "w-full" : "w-auto");

        return (
            <div className={wrapperStyles}>
                <input
                    className={inputStyles}
                    ref={ref}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    {...props}
                />
                {label && <label className={labelStyles}>{label}</label>}
            </div>
        );
    }
);

Input.displayName = "Input";

export { Input };
