import { Skeleton } from "@/ui";

function SkeletonLoader() {
    return (
        <div className="m-auto flex items-center max-w-full space-y-3 gap-2 pt-1 px-2">
            <Skeleton className="ml-2 w-[48px] h-[48px] rounded-full bg-[#c1cad3]" />
            <div className="w-full flex-1 ml-5">
                <Skeleton className="h-[20px] w-[60%] bg-[#c1cad3]" />
                <Skeleton className="mt-3 h-[20px] w-[80%]  bg-[#c1cad3]" />
            </div>
        </div>
    );
}

interface IChatsLoader {
    size?: number;
}

export default function ChatsLoader({ size = 15 }: IChatsLoader) {
    return (
        <>
            {Array.from(Array(size)).map((_, idx) => (
                <div key={idx}>
                    <SkeletonLoader />
                </div>
            ))}
        </>
    );
}
