import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useCallContext } from "../../project/components/TwilloModule/TwilloCallContext";
import {
    getDeletePowerDialerCandidates,
    getTwilloPowerCallCandidateList,
    selectTwilloPowerCallCandidateList,
} from "../../project/index.reducer";

interface Data {
    id: string;
    name: string;
    profileUrl: string;
    phone: string;
    callStatus: string;
}

const createData = (id: string, name: string, profileUrl: string, phone: string, callStatus: string): Data => ({
    id,
    name,
    profileUrl,
    phone,
    callStatus,
});

type Order = "asc" | "desc";

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
    { id: "callStatus", numeric: false, disablePadding: false, label: "Call Status" },
    { id: "id", numeric: false, disablePadding: false, label: "Action" },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;

    order: Order;
    orderBy: string;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ padding: 10, height: 10 }}>
            <TableRow>
                <TableCell align="left" padding="checkbox"></TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const TableData: React.FC<{
    twilloPowerCallCandidateList: any[];
    isLoading: boolean;
    isCampaignStarted: boolean;
    campaignId: string;
    resumeIndex: number;
    currentIndex: number;
    setTwilloPowerCallCandidateList: any;
}> = ({
    twilloPowerCallCandidateList = [],
    isLoading,
    isCampaignStarted,
    resumeIndex,
    currentIndex,
    campaignId,
    setTwilloPowerCallCandidateList,
}) => {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("name");

    const [dense, setDense] = React.useState(false);
    const dispatch = useDispatch();
    const [rowsData, setRowsData] = React.useState<Data[]>([]);
    const twilloPowerCallCandidateListFromStore = useSelector(selectTwilloPowerCallCandidateList);

    React.useEffect(() => {
        const rows = twilloPowerCallCandidateList?.length
            ? twilloPowerCallCandidateList.map((candidate) =>
                  createData(
                      candidate._id,
                      candidate.name,
                      candidate.profileUrl,
                      candidate.phone.length ? candidate.phone[0] : "N/A",
                      candidate.callStatus.length ? "Completed" : "Pending"
                  )
              )
            : [];
        setRowsData(rows);
    }, [twilloPowerCallCandidateList]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleDelete = (id: string) => {
        const updatedRows = rowsData.filter((row) => row.id !== id);
        setRowsData(updatedRows);

        dispatch(getDeletePowerDialerCandidates({ campaignId: campaignId, candidateIdsToRemove: [id] }));

        dispatch(getTwilloPowerCallCandidateList({ campaignId: id }));
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedRows = Array.from(rowsData);
        const [removed] = reorderedRows.splice(result.source.index, 1);
        reorderedRows.splice(result.destination.index, 0, removed);

        setRowsData(reorderedRows);

        // Update the Twilio candidate list based on the new row order
        const updatedCandidates = reorderedRows
            .map((row) => {
                return twilloPowerCallCandidateListFromStore?.candidates?.find((c) => c._id === row.id);
            })
            .filter(Boolean); // Keep only existing candidates

        setTwilloPowerCallCandidateList(campaignId, updatedCandidates);
    };

    const { isDialing } = useCallContext();
    const isAnyCampaignDialing = Object.values(isDialing).some((dialing) => dialing);

    return (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400 }}>
                        <CircularProgress />
                    </Box>
                ) : twilloPowerCallCandidateList?.length === 0 ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400 }}></Box>
                ) : (
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" isDropDisabled={isCampaignStarted}>
                                {(provided) => (
                                    <Table
                                        stickyHeader
                                        sx={{ minWidth: 1000, padding: 2 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        />
                                        <TableBody>
                                            {rowsData.map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                const isResumeRow = resumeIndex !== null && index === resumeIndex;
                                                const isCurrentRow = index === currentIndex;
                                                return (
                                                    <Draggable
                                                        key={row.id}
                                                        draggableId={row.id}
                                                        index={index}
                                                        isDragDisabled={isCampaignStarted}
                                                    >
                                                        {(provided) => (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                    padding: 2,
                                                                    backgroundColor: isResumeRow
                                                                        ? "rgba(255, 215, 0, 0.3)" // Light gold for resume row
                                                                        : isCurrentRow
                                                                          ? "rgb(71 168 210 / 27%)" // Light green for current row
                                                                          : "inherit",
                                                                }}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <TableCell align="left">
                                                                    <DragIndicatorIcon />
                                                                </TableCell>

                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    <Link
                                                                        to={`/candidate-reachout/${row.id}`}
                                                                        target="_blank"
                                                                        style={{
                                                                            textDecoration: "none",
                                                                            color: "#0891b2",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        {row.name}
                                                                    </Link>
                                                                    <IconButton
                                                                        component="a"
                                                                        href={row.profileUrl}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        sx={{ ml: 1 }}
                                                                    >
                                                                        <LinkedInIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                                <TableCell align="left">{row.phone}</TableCell>
                                                                <TableCell align="left">
                                                                    <Chip label={row.callStatus} size="small" />
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Tooltip title="Delete">
                                                                        <IconButton
                                                                            onClick={() => handleDelete(row.id)}
                                                                            disabled={isAnyCampaignDialing}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </TableBody>
                                    </Table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
};

export default TableData;
