import DescriptionIcon from "@mui/icons-material/Description";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

import HoverTruncateText from "../../atoms/HoverTruncateText";
import { SearchCandidate } from "../../store/reducers/searchCandidates/searchCandidates.types";
import DocumentViewer from "../DocumentViewer/DocumentViewer";

import { Modal, Typography } from "@/ui";
import { Avatar } from "@/ui/Avatar/Avatar";

interface CandidateCardProps extends SearchCandidate {
    hideAvatar?: boolean;
}

export default function CandidateCard(props: CandidateCardProps) {
    const {
        desireMaxSalary,
        desireMinSalary,
        latestjobTitle,
        desiredjobTitle,
        company,
        location,
        name,
        photo,
        profileUrl,
        stability,
        title,
        resume,
        hideAvatar,
    } = props;

    const desktop1000 = useMediaQuery("(min-width:1000px)");
    const desktop1100 = useMediaQuery("(min-width:1100px)");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getMaxWidth = () => {
        switch (true) {
            case desktop1000:
                return 300;
            case desktop1100:
                return 350;
            default:
                return 400;
        }
    };

    const maxWidth = getMaxWidth();

    if (company?.name === null || !name) {
        return null;
    }

    return (
        <div
            className={`rounded-md p-3 grid gap-4 border border-[rgba(0,0,0,0.1)]`}
            style={{
                gridTemplateColumns: hideAvatar ? "2fr" : "80px 2fr",
                height: "120px",
            }}
        >
            {!hideAvatar && (
                <Avatar
                    src={!photo || photo.startsWith("data:") ? undefined : photo}
                    className="w-[60px] h-[60px] mt-2 ml-3"
                    fallback={
                        <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                        </svg>
                    }
                />
            )}
            <div
                className="grid gap-4"
                style={{
                    gridTemplateColumns: "1fr 1px 1fr",
                    gridTemplateRows: "1fr",
                }}
            >
                <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <HoverTruncateText maxWidth={maxWidth} fontSize={16} sx={{ fontWeight: 600, paddingLeft: 0 }}>
                            {name}
                        </HoverTruncateText>

                        {profileUrl && (
                            <button className="text-blue-500 hover:text-blue-600 p-1">
                                <LinkedInIcon fontSize="small" />
                            </button>
                        )}
                        {resume && (
                            <button onClick={handleClick} className="p-1">
                                <DescriptionIcon fontSize="small" sx={{ color: "#316e84", fontSize: "19px" }} />
                            </button>
                        )}
                    </div>
                    {title && title !== "-" && (
                        <HoverTruncateText maxWidth={maxWidth} fontSize={14} sx={{ fontWeight: 500, paddingLeft: 0 }}>
                            {title}
                        </HoverTruncateText>
                    )}

                    {company?.position && company?.position !== "-" && (
                        <HoverTruncateText maxWidth={maxWidth} fontSize={14} sx={{ fontWeight: 500, paddingLeft: 0 }}>
                            {company?.position}
                        </HoverTruncateText>
                    )}
                    {desiredjobTitle && (
                        <Typography className="text-[12px] text-sm" variant="body2">
                            <b>Desired job title</b>: {desiredjobTitle}{" "}
                        </Typography>
                    )}
                    {latestjobTitle && (
                        <Typography className="text-[12px] text-sm" variant="body2">
                            <b>Current job title</b>: {latestjobTitle}{" "}
                        </Typography>
                    )}

                    <Typography className="text-[12px] text-semibold">
                        {company?.name ? (
                            <a href={company.url} target="_blank" className="text-[#0288d1] text-inherit">
                                @ {company.name}
                            </a>
                        ) : null}
                    </Typography>
                </div>
                <div className="border-l border-gray-300 h-full"></div>
                <div>
                    <Typography className="flex items-center text-center gap-1 text-[12px] mb-1">
                        <LocationOnIcon color="disabled" fontSize="small" /> {location}
                    </Typography>
                    {(stability?.role || stability?.company) && (
                        <Typography className="flex items-center gap-1 text-[12px]">
                            <WorkIcon color="disabled" sx={{ fontSize: 16 }} />
                            {stability?.role && `${stability.role} in role`}
                            {stability?.role && stability?.company ? ", " : ""}
                            {stability?.company && `${stability.company} in company`}
                        </Typography>
                    )}
                    {typeof desireMinSalary === "number" && typeof desireMaxSalary === "number" ? (
                        <>
                            <Typography className="text-[12px]" variant="body2">
                                <b>Expected compensation range (USD)</b>
                            </Typography>
                            <Typography className="text-[12px]" variant="body2">
                                {desireMinSalary} (min) - {desireMaxSalary} (max)
                            </Typography>
                        </>
                    ) : null}
                </div>
            </div>
            {resume && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    className="grid place-items-center"
                >
                    <div
                        style={{
                            minHeight: "80vh",
                            minWidth: "55vw",
                            maxWidth: "55vw",
                            backgroundColor: "lightgray",
                        }}
                    >
                        <DocumentViewer
                            containerStyle={{
                                height: "80vh",
                                width: "100%",
                            }}
                            url={resume}
                            defaultScale={1.2}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
}
