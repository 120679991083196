import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useProjectData } from "./hooks";

import { addSearchCandidatesToProject } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

export default function AddListToProject() {
    const { projectId, projectName, dispatch, searchId, navigate, isAddingToProject, isScreeningWithAI, status } =
        useProjectData();
    const { t } = useTranslation();

    if (!searchId) return null;

    const isSearchCompleted = ["COMPLETED", "CANCELLED", "ZERO_PROFILE", "IN_PROGRESS"].includes(status || "");
    const isDisabled = isAddingToProject || isScreeningWithAI || !isSearchCompleted;

    const handleAddToProject = () => {
        dispatch(
            addSearchCandidatesToProject({
                projectId,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep: false,
            })
        );
    };

    return (
        <Button variant="outlined" disabled={isDisabled} onClick={handleAddToProject}>
            <ButtonTextWithLoading
                isLoading={isAddingToProject}
                text={t("blendSearch.addTo")}
                progressStyle={{ color: "#0891b2" }}
            />
        </Button>
    );
}
