import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { startAction, stopAction } from "../../reducers/loaders.reducer";
import API from "../../../utils/API";
import { FETCH_CANDIDATE_NAMES } from "../../../utils/Constants";
import { objectKeys } from "../../../utils/helper";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import {
    setEnableConnectionReq,
    setEnableEmail,
    setEnableFollowUpEmail,
    setEnableInMail,
    setEnableInMailReminderMsg,
    setEnableReminderMsg,
    setEnableSMS,
} from "@/store/reducers/template/template.reducer";
import {
    cancelActions,
    fetchBaseTemplates,
    fetchProjectBaseTemplates,
    setBaseTemplates,
    setErrorWhileFetchingBaseTemplates,
    submitBaseTemplates,
    submitProjectBaseTemplates,
    submitSwitchesStatus,
} from "@/store/reducers/trigger-workflow/customTemplates.slice";
import {
    clearAllGeneratedTemplates,
    fetchCandidateWithUpdatedOutReachContent,
    setCandidateIndex,
    setTemplatesView,
    submitPersonalizedInputs,
} from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import {
    BaseTemplateState,
    ExtraFollowups,
    FetchBaseTemplatesPayload,
    FetchBaseTemplatesResponse,
    FetchProjectBaseTemplatesPayload,
    SubmitBaseTemplatesPayload,
    SubmitProjectBaseTemplatesPayload,
    SubmitSwitchesStatusPayload,
} from "@/store/reducers/trigger-workflow/customTemplate.types";
import { Candidate } from "@/pages/triggerWorkflow/types";

function* fetchBaseTemplatesSaga(action: FetchBaseTemplatesPayload): SagaIterator {
    try {
        console.log("fetch base templates called");
        const { projectId, candidateIds, ...rest } = action.payload;
        const state = yield select();
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");
        const baseTemplateState: BaseTemplateState = get(state, "customTemplates");

        if (baseTemplateState?.id) {
            return;
        }

        yield put(setErrorWhileFetchingBaseTemplates(false));
        yield put(startAction({ action: action.type }));

        const response: FetchBaseTemplatesResponse = yield call(new API().post, "/base-template/get", {
            projectId,
        });

        yield put(
            setBaseTemplates({
                delaySms: response.data.delaySms || 1440,
                delayConn: response.data.delayConn || 60,
                delayFollowupEmail: response.data.delayFollowupEmail || 1440,
                sms: response.data.sms,
                email: response.data.email,
                followUp: response.data.followupEmail,
                inMail: response.data.inMail,
                linkedInReq: response.data.connectionReq,
                reminderMsg: response.data.reminderMsg,
                inMailReminderMsg: response.data.inMailReminderMsg,
                id: response.data._id,
                numberOfExtraFollowUp: response.data.numberOfExtraFollowUp,
                extraInitialFollowups: response.data?.extraFollowups?.map(({ body, status }) => {
                    return {
                        body,
                        status,
                    };
                }),
            })
        );

        if ("type" in response.data && response.data.type === "LOCAL") {
            // yield put(setTemplatesView({ view: "HYPER_PERSONALIZED" }));
            yield put(
                submitPersonalizedInputs({
                    candidateIDs: candidateIds,
                    projectID: projectId,
                    fetchNamesAction: FETCH_CANDIDATE_NAMES,
                    ...rest,
                })
            );
        }

        if ("enableInMail" in response.data) {
            yield put(setEnableInMail(response.data.enableInMail));
        }

        if ("enableConnectionReq" in response.data) {
            yield put(setEnableConnectionReq(response.data.enableConnectionReq));
        }

        if ("enableFollowUpEmail" in response.data) {
            yield put(setEnableFollowUpEmail(response.data.enableFollowUpEmail));
        }

        if ("enableEmail" in response.data) {
            yield put(setEnableEmail(response.data.enableEmail));
        }

        if ("enableReminderMsg" in response.data) {
            yield put(setEnableReminderMsg(response.data.enableReminderMsg));
        }

        if ("enableInMailReminderMsg" in response.data) {
            yield put(setEnableInMailReminderMsg(response.data.enableInMailReminderMsg));
        }

        if ("enableSMS" in response.data) {
            yield put(setEnableSMS(response.data.enableSMS && isSMSEnabledForUser ? true : false));
        }
    } catch (error) {
        handleException(error);
        console.error("**error while fetching base templates", { error });
        yield put(setErrorWhileFetchingBaseTemplates(true));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitBaseTemplatesSaga(action: SubmitBaseTemplatesPayload): SagaIterator {
    try {
        const { payload, type } = action;
        const { projectId, candidateIds, isAllCandidatesSelected } = payload;
        const state = yield select();
        const baseTemplateState: BaseTemplateState = get(state, "customTemplates");
        yield put(startAction({ action: type }));
        const response: FetchBaseTemplatesResponse = yield call(new API().post, "/base-template/edit", {
            _id: baseTemplateState.id,
            projectId,
            email: {
                subject: baseTemplateState.email.subject,
                body: baseTemplateState?.email?.body,
            },
            followupEmail: {
                body: baseTemplateState?.followUp?.body,
            },
            inMail: {
                subject: baseTemplateState.inMail.subject,
                body: baseTemplateState?.inMail?.body,
            },
            connectionReq: {
                body: baseTemplateState.linkedInReq.body,
            },
            reminderMsg: {
                body: baseTemplateState?.reminderMsg?.body || "",
            },
            inMailReminderMsg: {
                body: baseTemplateState?.inMailReminderMsg?.body || "",
            },
            sms: {
                body: baseTemplateState.sms.body,
            },
            delaySms: baseTemplateState.delaySms,
            delayConn: baseTemplateState.delayConn,
            delayFollowupEmail: baseTemplateState.delayFollowupEmail,
        });

        const candidates: Candidate[] = get(state, "personalizedWorkflow.candidates");

        if (candidates?.length) {
            yield put(
                setBaseTemplates({
                    delaySms: response.data.delaySms || 1440,
                    delayConn: response.data.delayConn || 60,
                    delayFollowupEmail: response.data.delayFollowupEmail || 1440,
                    email: response.data.email,
                    followUp: response.data.followupEmail,
                    inMail: response.data.inMail,
                    linkedInReq: response.data.connectionReq,
                    reminderMsg: response.data.reminderMsg,
                    inMailReminderMsg: response.data.inMailReminderMsg,
                    id: response.data._id,
                    sms: response.data.sms,
                })
            );

            const candidate: Candidate = get(state, "personalizedWorkflow.candidate");
            const index: number = get(state, "personalizedWorkflow.index");

            yield put(
                fetchCandidateWithUpdatedOutReachContent({
                    action: `${setCandidateIndex.type}_${candidate.id}`,
                    id: candidate.id,
                    index,
                    projectId,
                })
            );

            yield put(clearAllGeneratedTemplates());
        } else {
            yield put(
                submitPersonalizedInputs({
                    candidateIDs: candidateIds,
                    projectID: projectId,
                    fetchNamesAction: FETCH_CANDIDATE_NAMES,
                    isAllCandidatesSelected,
                })
            );
        }

        yield put(setTemplatesView({ view: "HYPER_PERSONALIZED" }));
    } catch (error) {
        handleException(error);
        console.error("**error while submitting base templates", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitSwitchesStatusSaga(action: SubmitSwitchesStatusPayload): SagaIterator {
    try {
        const { projectId } = action.payload;
        const state = yield select();
        const baseTemplatesId = get(state, "customTemplates.id");
        const enableEmail = get(state, "template.enableEmail");
        const enableReminderMsg = get(state, "template.enableReminderMsg");
        const enableInMailReminderMsg = get(state, "template.enableInMailReminderMsg");
        const enableConnectionReq = get(state, "template.enableConnectionReq");
        const enableFollowUpEmail = get(state, "template.enableFollowUpEmail");
        const enableSMS = get(state, "template.enableSMS");
        const enableInMail = get(state, "template.enableInMail");
        const extraFollowups: ExtraFollowups = get(state, "customTemplates.extraFollowups");
        const delaySms = get(state, "customTemplates.delaySms");
        const delayConn = get(state, "customTemplates.delayConn");
        const delayFollowupEmail = get(state, "customTemplates.delayFollowupEmail");

        const extraFollowupsAsArray = objectKeys(extraFollowups).map((key) => {
            const value = extraFollowups[key];
            return {
                status: value.isEnabled,
                body: value.body,
            };
        });

        yield call(new API().post, "/base-template/edit", {
            projectId,
            _id: baseTemplatesId,
            enableConnectionReq,
            enableFollowUpEmail,
            enableEmail,
            enableInMail,
            enableReminderMsg,
            enableInMailReminderMsg,
            enableSMS,
            extraFollowups: extraFollowupsAsArray,
            delaySms: delaySms,
            delayConn: delayConn,
            delayFollowupEmail: delayFollowupEmail,
        });
    } catch (error) {
        handleException(error);
        console.error("**submitSwitchesStatus", { error });
    }
}

// used in blend search
function* fetchProjectBaseTemplatesSaga(action: FetchProjectBaseTemplatesPayload): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const { projectId } = action.payload;
        const response: FetchBaseTemplatesResponse = yield call(new API().post, "/base-template/get", { projectId });

        yield put(
            setBaseTemplates({
                delaySms: response.data.delaySms || 1440,
                delayConn: response.data.delayConn || 60,
                delayFollowupEmail: response.data.delayFollowupEmail || 1440,
                inMailReminderMsg: response.data.inMailReminderMsg,
                sms: response.data.sms,
                email: response.data.email,
                followUp: response.data.followupEmail,
                inMail: response.data.inMail,
                linkedInReq: response.data.connectionReq,
                reminderMsg: response.data.reminderMsg,
                id: response.data._id,
                extraInitialFollowups: response.data?.extraFollowups?.map(({ body, status }) => {
                    return {
                        body,
                        status,
                    };
                }),
            })
        );
    } catch (error) {
        handleException(error);
        console.error("**error while fetching project base templates", {
            error,
        });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitProjectBaseTemplatesSaga(action: SubmitProjectBaseTemplatesPayload): SagaIterator {
    try {
        const { payload, type } = action;
        const { projectId } = payload;
        const state = yield select();
        const baseTemplateState: BaseTemplateState = get(state, "customTemplates");
        yield put(startAction({ action: type }));
        const response: FetchBaseTemplatesResponse = yield call(new API().post, "/base-template/edit", {
            _id: baseTemplateState.id,
            projectId,
            email: {
                subject: baseTemplateState.email.subject,
                body: baseTemplateState?.email?.body,
            },
            followupEmail: {
                body: baseTemplateState?.followUp?.body,
            },
            inMail: {
                subject: baseTemplateState.inMail.subject,
                body: baseTemplateState?.inMail?.body,
            },
            connectionReq: {
                body: baseTemplateState?.linkedInReq?.body,
            },
            reminderMsg: {
                body: baseTemplateState?.reminderMsg?.body || "",
            },
            inMailReminderMsg: {
                body: baseTemplateState?.inMailReminderMsg?.body || "",
            },
            delaySms: baseTemplateState.delaySms,
            delayConn: baseTemplateState.delayConn,
            delayFollowupEmail: baseTemplateState.delayFollowupEmail,
        });
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        handleException(error);
        console.error("**error while submitting base templates", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchBaseTemplates.type, fetchBaseTemplatesSaga),
        // @ts-ignore
        yield takeLatest(submitBaseTemplates.type, submitBaseTemplatesSaga),
        // @ts-ignore
        yield takeLatest(submitSwitchesStatus.type, submitSwitchesStatusSaga),
        // @ts-ignore
        yield takeLatest(fetchProjectBaseTemplates.type, fetchProjectBaseTemplatesSaga),
        // @ts-ignore
        yield takeLatest(submitProjectBaseTemplates.type, submitProjectBaseTemplatesSaga),
    ];

    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
