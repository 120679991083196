import { Box, TableCell, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";

import {
    handleAllContactsRequestSort,
    handleAllContactsSelectAllClick,
    numberOfSelectedRowsInAllContacts,
    selectAllContactsFilters,
    selectAllContactsTableOrder,
    selectAllContactsTableOrderBy,
    selectAllContactsTotalCount,
} from "../../store/apis/all-contacts/all-contacts.slice";
import { Contact } from "../../store/apis/all-contacts/all-contacts.types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.common.black,
        fontWeight: "500",
    },
}));

export default function AllContactsTableHead() {
    const dispatch = useDispatch();
    const numSelected = useSelector(numberOfSelectedRowsInAllContacts);
    const rowCount = useSelector(selectAllContactsTotalCount);
    const order = useSelector(selectAllContactsTableOrder);
    const orderBy = useSelector(selectAllContactsTableOrderBy);
    const filters = useSelector(selectAllContactsFilters);

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        dispatch(handleAllContactsSelectAllClick(value));
    };

    const createSortHandler = (property: keyof Contact) => (event: React.MouseEvent<unknown>) => {
        dispatch(handleAllContactsRequestSort(property));
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={handleSelect}
                    />
                </StyledTableCell>
                {(Object.keys(filters) as (keyof Contact)[]).map((filter) => {
                    const headCell = filters[filter];

                    if (!headCell || !headCell?.label) {
                        return null;
                    }

                    return (
                        <StyledTableCell
                            key={filter}
                            align={headCell.numeric ? "right" : "left"}
                            sortDirection={orderBy === filter ? order : false}
                        // sx={{
                        //     paddingRight:
                        //         headCell.id === "lastUpdated" ? "30px" : 0,
                        // }}
                        >
                            <TableSortLabel
                                active={orderBy === filter}
                                direction={orderBy === filter ? order : "asc"}
                                onClick={createSortHandler(filter)}
                            >
                                {headCell.label}
                                {orderBy === filter ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
