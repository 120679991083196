import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { RootState } from "../../../store";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useSaveOutreachTemplate } from "../hooks/useSaveOutreach.hook";

import { checkTempalateHaveUnsavedChanges, selectCurrentOutreachElement } from "@/store/reducers/outreach/outreach.slice";

export function ConfirmDelete({
    deleteOutreachElementId,
    onClose,
    onDelete,
}: {
    deleteOutreachElementId: string;
    onDelete: () => void;
    onClose: () => void;
}) {
    const { saveTemplate, saveMutationState } = useSaveOutreachTemplate({
        successCallback: () => {
            onDelete();
            onClose();
        },
    });
    const templateHaveUnsavedChanges = useSelector((state: RootState) =>
        checkTempalateHaveUnsavedChanges(state, deleteOutreachElementId)
    );
    const deleteOutreachElement = useSelector((state: RootState) =>
        selectCurrentOutreachElement(state, { templateId: deleteOutreachElementId })
    );

    const onDeleteOutreachElement = (shouldSave: boolean) => {
        if (shouldSave) {
            saveTemplate(deleteOutreachElement);
        } else {
            onDelete();
            onClose();
        }
    };

    const { isLoading } = saveMutationState;

    return (
        <BaseModal onClose={onClose}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography sx={{ fontSize: "20px" }} variant="h6">
                        Delete outreach element
                    </Typography>
                    <Typography variant="body2">
                        Are you sure you want to delete this element? You can add it back to your outreach flow with the{" "}
                        <span style={{ fontWeight: 500 }}>Add</span> button
                    </Typography>
                    {templateHaveUnsavedChanges && (
                        <>
                            <Typography
                                sx={{ mt: 0.75, fontStyle: "italic", fontSize: "12.5px" }}
                                color="#E4003A"
                                variant="body2"
                            >
                                Note: Your most recent changes to this template have not been saved{" "}
                            </Typography>
                        </>
                    )}
                </Stack>

                <Stack gap={1.3} width={"100%"} justifyContent={"flex-end"} flexDirection="row" mt={1}>
                    {templateHaveUnsavedChanges ? (
                        <CustomButton
                            onClick={() => onDeleteOutreachElement(false)}
                            variant="outlined"
                            sx={{
                                boxShadow: "none",
                                borderRadius: "0.25rem",
                                borderColor: "black",
                                color: "black",
                                ":hover": {
                                    borderColor: "black",
                                },
                            }}
                        >
                            Delete without saving
                        </CustomButton>
                    ) : (
                        <CustomButton
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                boxShadow: "none",
                                borderRadius: "0.25rem",
                                borderColor: "black",
                                color: "black",
                                ":hover": {
                                    borderColor: "black",
                                },
                            }}
                        >
                            Cancel
                        </CustomButton>
                    )}
                    {/* this button will  server two purpose  */}
                    {/* if unsaved chagnes => save and delete */}
                    {/* if saved changes => delete */}
                    <CustomButton
                        onClick={() => onDeleteOutreachElement(templateHaveUnsavedChanges)}
                        variant="contained"
                        sx={{
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                            background: "#FF4C4C",
                            border: "none",
                            ":hover": {
                                background: "#FF4C4C",
                            },
                        }}
                    >
                        <ButtonTextWithLoading
                            progressStyle={{ color: "white" }}
                            isLoading={isLoading}
                            text={templateHaveUnsavedChanges ? "Save and delete" : "Delete"}
                        />
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
