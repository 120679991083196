import { useEffect, useState } from "react";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useMenu from "../../../../hooks/useMenu";
import {
    getShowByMaxExperience,
    getShowByMinExperience,
    getShowByRecent,
    getShowByRelevance,
    resetRequestId,
    selectShowByMaxExperience,
    selectShowByMinExperience,
    selectShowByMostRecent,
    selectShowByRelevance,
} from "@/store/reducers/project/project.reducer";

export function SortCandidates() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const dispatch = useDispatch();
    const showByRelevance = useSelector(selectShowByRelevance);
    const showByMostRecent = useSelector(selectShowByMostRecent);
    const showByMaxExperience = useSelector(selectShowByMaxExperience);
    const showByMinExperience = useSelector(selectShowByMinExperience);

    const handleMenuClick = (callback: () => void) => {
        return (_: React.MouseEvent<HTMLElement>, index: number) => {
            setSelectedIndex(index);
            callback();
            handleClose();
            dispatch(resetRequestId());
        };
    };

    useEffect(() => {
        if (!showByRelevance) {
            dispatch(
                getShowByRelevance({
                    projectId: id,
                    showByRelevance: !showByRelevance,
                    action: getShowByRelevance.type,
                })
            );
        }
    }, []);

    const sortDropDown = [
        {
            handleClick: handleMenuClick(() => {
                if (!showByRelevance) {
                    dispatch(
                        getShowByRelevance({
                            projectId: id,
                            showByRelevance: !showByRelevance,
                            action: getShowByRelevance.type,
                        })
                    );
                }
            }),
            label: "Most Relevant",
        },
        {
            handleClick: handleMenuClick(() => {
                if (!showByMostRecent) {
                    dispatch(
                        getShowByRecent({
                            projectId: id,
                            action: getShowByRecent.type,
                            showByMostRecent: true,
                        })
                    );
                }
            }),
            label: "Most Recent",
        },
        {
            handleClick: handleMenuClick(() => {
                if (!showByMaxExperience) {
                    dispatch(
                        getShowByMaxExperience({
                            projectId: id,
                            showByMaxExperience: true,
                            action: getShowByMaxExperience.type,
                        })
                    );
                }
            }),
            label: "Max Experience",
        },
        {
            handleClick: handleMenuClick(() => {
                if (!showByMinExperience) {
                    dispatch(
                        getShowByMinExperience({
                            projectId: id,
                            showByMinExperience: true,
                            action: getShowByMinExperience.type,
                        })
                    );
                }
            }),
            label: "Min Experience",
        },
    ];

    return (
        <>
            <Tooltip placement="top" title={t("projectsPage.candidateSection.sortCandidates")}>
                <IconButton onClick={handleClick}>
                    <FilterListRoundedIcon fontSize="medium" sx={{ color: "#6C6C6C" }} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                sx={menuSX}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {sortDropDown.map(({ label, handleClick }, index) => {
                    return (
                        <MenuItem
                            selected={index === selectedIndex}
                            key={label}
                            onClick={(event) => handleClick(event, index)}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
