import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet, { SheetProps } from "@mui/joy/Sheet";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProjectsDropdown from "../../../../components/Dropdowns/ProjectsDropdown";
import MembersDropdown from "../../../../components/MembersDropdown";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import { EChannelLabel, ESentimentLabel, EStatusLabel } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { useSuperInboxContext } from "../../Context";
import { closeFiltersPane } from "../../utils";
import Scrollable from "../Scrollable";

import { isSuperAdminUser, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Checkbox } from "@/ui";

interface FilterContainerProps extends Omit<SheetProps, "onChange"> {
    onChange?: (checked: boolean, key: string) => void;
    options?: { [key: string]: boolean };
    labelMap?: { [key: string]: string };
    multiSelect?: boolean;
    disabled?: boolean;
}

function FilterContainer(props: FilterContainerProps) {
    const { title, onChange, options, children, labelMap, multiSelect = true, disabled, ...rest } = props;
    return (
        <Sheet
            sx={{
                p: 2,
                my: 1,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 14,
            }}
            {...rest}
        >
            <p id={`${title}-group`} className="text-sm font-semibold pb-1">
                {title}
            </p>

            {options &&
                (multiSelect ? (
                    <div role="group" aria-labelledby={`${title}-group`}>
                        <List sx={{ pb: 0 }}>
                            {Object.entries(options).map(([key, value]) => (
                                <ListItem key={key} sx={{ py: 0, px: 0 }}>
                                    <Checkbox
                                        disabled={disabled}
                                        checked={value}
                                        onChange={(checked) => onChange(checked as boolean, key)}
                                        name={key}
                                        label={labelMap?.[key] ?? key}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                ) : (
                    <RadioGroup name="radio-buttons-group" sx={{ pt: 1 }}>
                        {Object.entries(options).map(([key, value]) => (
                            <Radio
                                disabled={disabled}
                                sx={{ fontSize: 14 }}
                                name={key}
                                key={key}
                                checked={value}
                                onChange={(e) => onChange(e.target.checked, key)}
                                value={key}
                                label={labelMap?.[key] ?? key}
                                variant="soft"
                            />
                        ))}
                    </RadioGroup>
                ))}

            {children}
        </Sheet>
    );
}

export function Filters() {
    const { t } = useTranslation();
    const {
        filters: {
            status,
            channels,
            sentiment,
            projectId,
            userId,
            setChannels,
            setStatus,
            setSentiment,
            setProjectId,
            setUserId,
        },
        totalCandidates,
        // applyFilters,
    } = useSuperInboxContext();
    const user = useSelector(selectUser);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isMemberDropdownVisible = user?.roleType === "ADMIN" || isSuperAdmin;

    const ref = useRef(null);
    useOnClickOutside(ref, () => closeFiltersPane(), [document.getElementById("filter-drawer-button")]);

    const handleChannelChange = (checked: boolean, key: string) => {
        // TODO: to be removed multiple select option once we figure out how to optimise all channel aggregations on BE
        setChannels({
            ...channels,
            [key]: checked,
        });
    };

    const handleStatusChange = (checked: boolean, key: string) => {
        setStatus({
            ...status,
            [key]: checked,
        });
    };

    const handleSentimentChange = (checked: boolean, key: string) => {
        setSentiment({
            ...sentiment,
            [key]: checked,
        });
    };
    return (
        <div ref={ref}>
            <Scrollable.Container sx={{ height: "calc(100dvh - 120px)" }}>
                <DialogTitle sx={{ p: 2 }}>Filters</DialogTitle>
                <Scrollable.Content>
                    <DialogContent sx={{ px: 2 }}>
                        {isMemberDropdownVisible && (
                            <FilterContainer title="Members">
                                <MembersDropdown value={userId} onChange={setUserId} />
                            </FilterContainer>
                        )}
                        <FilterContainer title={t("superInbox.projectFilterDropdownLable")}>
                            <ProjectsDropdown projectId={projectId} handleChange={setProjectId} userId={userId} />
                        </FilterContainer>
                        <FilterContainer
                            title="Channels"
                            onChange={handleChannelChange}
                            options={channels}
                            labelMap={EChannelLabel}
                        />
                        <FilterContainer
                            title="Status"
                            onChange={handleStatusChange}
                            options={status}
                            labelMap={EStatusLabel}
                        />
                        <FilterContainer
                            disabled={!status.responded}
                            title="Sentiment"
                            onChange={handleSentimentChange}
                            options={sentiment}
                            labelMap={ESentimentLabel}
                        />
                    </DialogContent>
                    <i className="text-xs text-center pb-1">
                        <b>{totalCandidates}</b> conversations found
                    </i>
                </Scrollable.Content>
            </Scrollable.Container>
        </div>
    );
}
