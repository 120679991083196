import { useSelector } from "react-redux";

import ChromeExtension from "../ChromeExtension/ChromeExtension";

import { selectIsExtensionInstalled } from "@/store/reducers/app-container/AppContainer.reducer";

type ExtensionBtnProps = {
    variant?: "default" | "low";
};

export default function ExtensionBtn({ variant = "default" }: ExtensionBtnProps) {
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);
    return <>{isExtensionInstalled ? null : <ChromeExtension variant={variant} />}</>;
}
