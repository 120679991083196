import TaskAltRounded from "@mui/icons-material/TaskAltRounded";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import NavigateBackButton from "../../atoms/NavigateBackButton/NavigateBackbutton";
import { DisplayMessage } from "../../components/BlendedSearch/molecules/DisplayMessage";
import { setSuccessNotification } from "../../components/Notification/index.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { RenderBaseTemplates } from "../triggerWorkflow/components/BaseTemplates";
import {
    fetchProjectBaseTemplates,
    submitProjectBaseTemplates,
} from "../triggerWorkflow/reducers/customTemplates.slice";

export function SetupOutreach() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const isFetchingBaseTemplates = useSelector(checkIfLoading(fetchProjectBaseTemplates.type));

    useEffect(() => {
        if (projectId) {
            dispatch(fetchProjectBaseTemplates({ projectId }));
        }
    }, []);

    if (!projectId) {
        return <DisplayMessage text="Project id is required to set up outreach." />;
    }

    const handleCompleteSetup = () => {
        dispatch(submitProjectBaseTemplates({ projectId }));
        navigate(`/projects/${projectId}?fromSearch=true`);
        dispatch(setSuccessNotification("Project completed successfully"));
    };

    return (
        <>
            {isFetchingBaseTemplates ? (
                <Stack
                    borderRadius={4}
                    bgcolor="white"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress
                        size={30}
                        style={{
                            color: "#0891b2",
                        }}
                    />
                </Stack>
            ) : (
                <Stack justifyContent="space-between" gap={2}>
                    <Stack bgcolor="white" borderRadius={4} height="fit-content" py={1.5} px={2}>
                        <Stack spacing={0.5} mb={2}>
                            <Typography fontSize={20} fontWeight={500} color="#334d6e">
                                Personalization templates
                            </Typography>
                            <Typography fontSize={14} color="rgba(0, 0, 0, 0.6)" fontStyle="italic">
                                Edit your outreach templates and click 'Save', then complete your project setup
                            </Typography>
                        </Stack>
                        <RenderBaseTemplates variant="SEARCH" />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" spacing={1} mb={2}>
                        <NavigateBackButton />
                        <Button
                            variant="contained"
                            onClick={handleCompleteSetup}
                            endIcon={<TaskAltRounded fontSize="inherit" />}
                        >
                            Complete Project Setup
                        </Button>
                    </Stack>
                </Stack>
            )}
        </>
    );
}
