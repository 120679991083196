import { Divider, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContactListContent from "./components/ContactListTableContent";
import CreateContactListModal from "./components/CreateContactListModal";
import DeleteLoading from "./components/DeleteLoading";
import ExportLoading from "./components/ExportLoading";

import MUILoader from "../../components/MUILoader";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { setModal } from "../../store/reducers/modals.slice";

import { fetchContactList, totalRowCount } from "@/store/reducers/contact-list/contact-list.slice";


const GridBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto 1fr",
    padding: "1rem",
    gap: "1rem",
}));

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));

function ContactListTitle() {
    const totalRows = useSelector(totalRowCount);
    const dispatch = useDispatch();

    const openCreateContactListModal = () => dispatch(setModal({ key: "showCreateContactListModal", value: true }));

    const openImportContactListModal = () => dispatch(setModal({ key: "showImportContactListModal", value: true }));

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack>
                    <Typography variant="h4" fontWeight={700} sx={{ fontSize: "20px" }}>
                        Lists
                    </Typography>
                    <Typography variant="body2" fontWeight={700}>
                        {totalRows} Lists{" "}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <DeleteLoading />
                    <ExportLoading />
                    <CustomButton variant="contained" onClick={openCreateContactListModal}>
                        Create list
                    </CustomButton>
                </Stack>
            </Stack>
            <CreateContactListModal />
        </>
    );
}

export default function ContactList() {
    const dispatch = useDispatch();
    const isFetchingContactList = useSelector(checkIfLoading(fetchContactList.type));

    useEffect(() => {
        dispatch(fetchContactList());
    }, []);

    if (isFetchingContactList) {
        return <MUILoader />;
    }

    return (
        <GridBox>
            <ContactListTitle />
            <Divider />
            <ContactListContent />
        </GridBox>
    );
}
