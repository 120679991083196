import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { OutreachErrorValue } from "./outreach.types";

// Extend dayjs with the customParseFormat plugin
dayjs.extend(customParseFormat);

export const INVALID_FOLLOW_UP_DAYS_AND_TIME =
    "Follow-up emails must be scheduled after the original email. Please adjust the time accordingly.";

export const INVALID_EMAIL_DAYS_AND_TIME =
    "Email must be scheduled before any follow-up emails. Please adjust the time accordingly";

export const initialErrors: OutreachErrorValue = {
    EMPTY_ERROR: "",
    CURLY_BRACES_ERROR: "",
    ROUND_BRACES_ERROR: "",
    CHARACTER_LIMIT_ERROR: "",
};

export type EVENT_KEYS = "email" | "follow-up" | "SMS" | "linkedin";

export const EVENT_NAMES: Record<EVENT_KEYS, EVENT_KEYS> = {
    email: "email",
    linkedin: "linkedin",
    "follow-up": "follow-up",
    SMS: "SMS",
} as const;

type EVENT_ENUM = 1 | 2 | 11 | 12 | 25;

export const EVENT_ENUMS: Record<EVENT_KEYS, EVENT_ENUM> = {
    email: 1,
    linkedin: 25,
    "follow-up": 12,
    SMS: 11,
} as const;

export const SOURCE_TEXT_MAP = {
    SendGrid:
        "Your email outreach will be executed via SendGrid. EasySource does not bill you separately for this premium service",
    SES: "Your email outreach will be executed via AWS SES, Amazon’s email sending service. EasySource does not bill you separately for this premium service",
    Gmail: "Emails will be sent from your gmail account when you click 'Schedule' here",
    Outlook: "Emails will be sent from your Outlook account when you click 'Schedule' here",
};
export const SOURCE_TEXT_MAP_EASYGROWTH = {
    SendGrid:
        "Your email outreach will be executed via SendGrid. EasyGrowth does not bill you separately for this premium service",
    SES: "Your email outreach will be executed via AWS SES, Amazon’s email sending service. EasyGrowth does not bill you separately for this premium service",
    Gmail: "Emails will be sent from your gmail account when you click 'Schedule' here",
    Outlook: "Emails will be sent from your Outlook account when you click 'Schedule' here",
};

export enum EQueries {
    GET_WARNINGS = "GET_WARNINGS",
}
