import styled from "@emotion/styled";
import { Stack, StackProps, SxProps, Theme, Typography } from "@mui/material";

const CustomStack = styled((props: StackProps & { visible?: boolean }) => (
    <Stack {...props} {...(!props.visible && { sx: { display: "none" } })} />
))(() => ({
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
}));

export function DisplayMessage({ text, sx, visible = true }: { text: string; sx?: SxProps<Theme>; visible?: boolean }) {
    return (
        <CustomStack visible={visible}>
            <Typography sx={sx} color="#0891b2" fontWeight={500} dangerouslySetInnerHTML={{ __html: text }} />
        </CustomStack>
    );
}
