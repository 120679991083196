import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

import { CustomFormHelperText } from "../../../../../components/CustomFormHelperText";
import { criticalityOptions } from "../../../../all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { useCreateActivityFormState } from "../NotesTab";

import { PriorityType } from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";

export default function PriorityMenu() {
    const { control } = useCreateActivityFormState();
    return (
        <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl
                        error={Boolean(error)}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                width: "200px",
                                "& .MuiFormLabel-root ": {
                                    ...fontSize,
                                    top: "-5px",
                                    '&[data-shrink="true"]': {
                                        top: "1px",
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    width: "43px",
                                },
                                "& .MuiSelect-select": {
                                    ...fontSize,
                                    padding: "0.7rem",
                                },
                            };
                        }}
                    >
                        <InputLabel>Priority</InputLabel>
                        <Select
                            error={Boolean(error)}
                            disabled={isSubmitting}
                            size="small"
                            value={value}
                            label="Priority"
                            onChange={(e) => onChange(e.target.value as PriorityType)}
                        >
                            <MenuItem
                                value=""
                                sx={{
                                    "&.MuiMenuItem-root": {
                                        fontSize: "12px",
                                    },
                                }}
                            >
                                <em>None</em>
                            </MenuItem>
                            {criticalityOptions.map((i) => {
                                if (!i) {
                                    return null;
                                }

                                return (
                                    <MenuItem
                                        key={i}
                                        value={i}
                                        sx={{
                                            "&.MuiMenuItem-root": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >
                                        {i}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {error !== undefined && (
                            <CustomFormHelperText>Please select valid priority value</CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}
