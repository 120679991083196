import "../../utils/pollyfills"
import { CircularProgress, IconButton, Stack, StackProps } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Document, Page, pdfjs } from "react-pdf";

import { JoyProvider } from "../JoyProvider";

import "./DocumentViewer.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

export interface DocumentViewerProps {
    url: string;
    defaultScale?: number;
    disableScaling?: boolean;
    containerStyle?: StackProps["style"];
}

const PDFViewerComponent: React.FC<DocumentViewerProps> = ({
    url,
    defaultScale = 1.5,
    disableScaling = false,
    containerStyle,
}) => {
    const [numPages, setNumPages] = useState(0);
    const [scale, setScale] = useState(defaultScale);
    return (
        <JoyProvider>
            <Stack sx={{ position: "relative" }}>
                {!disableScaling && (
                    <Stack
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            gap: "10px",
                            position: "absolute",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 3,
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            padding: "5px",
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setScale(scale - 0.1);
                            }}
                            sx={{
                                height: "30px",
                                width: "30px",
                                display: "inline-flex",
                                color: "white",
                                fontSize: "28px",
                            }}
                            disabled={scale <= 1}
                        >
                            -
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setScale(scale + 0.1);
                            }}
                            sx={{
                                height: "30px",
                                width: "30px",
                                display: "inline-flex",
                                color: "white",
                                fontSize: "28px",
                            }}
                            disabled={scale >= 2}
                        >
                            +
                        </IconButton>
                    </Stack>
                )}

                <Stack
                    sx={{
                        bgcolor: "lightgray",
                        alignItems: "center",
                        overflowY: "scroll",
                        height: "30vw",
                        border: "1px solid lightgray",
                        position: "relative",
                        padding: "10px",
                        ...containerStyle,
                    }}
                >
                    <Document
                        file={url}
                        onLoadSuccess={(pdf) => setNumPages(pdf.numPages)}
                        loading={<CircularProgress />}
                        onLoadError={(error) => {
                            throw Error("Error loading PDF: " + error);
                        }}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={scale}
                                className="pdf-viewer-page"
                            />
                        ))}
                    </Document>
                </Stack>
            </Stack>
        </JoyProvider>
    );
};

type FallBackComponentProps = DocumentViewerProps & FallbackProps;

const ErrorFallback: React.FC<FallBackComponentProps> = ({ url, resetErrorBoundary, containerStyle }) => {
    useEffect(() => {
        return () => {
            resetErrorBoundary();
        };
    }, []);

    return (
        <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            style={{ width: "80%", height: "80vh", margin: "auto", ...containerStyle }}
        />
    );
};

const DocumetViewer: React.FC<DocumentViewerProps> = (props) => {
    return (
        <ReactErrorBoundary
            FallbackComponent={(fallbackProps) => <ErrorFallback {...{ ...fallbackProps, ...props }} />}
        >
            <PDFViewerComponent {...props} />
        </ReactErrorBoundary>
    );
};

export default DocumetViewer;
