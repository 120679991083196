import UploadIcon from "@mui/icons-material/Upload";
import Input from "@mui/joy/Input";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import csvParser from "papaparse";
import { SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { easyGrowthMappingOptions, easySourceMappingOptions } from "./constants";

import CustomTable from "../../components/CustomTable/CustomTable";
import useWindowSize from "../../hooks/useWindowSize";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";
import { JoyProvider } from "../JoyProvider";

import { selectCustomFields } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    selectCsvFile,
    setShowCsvHeaderDialog,
    uploadCsv,
} from "@/store/reducers/create-project/CreateProject.reducer";
import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";

function camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

interface CsvHeadersProps {
    onSubmit: Function;
    options?: { value: string; label: string }[];
}

const CreateNewFieldModal = ({
    addToHeaderOptions,
    createCustomFieldModal,
    onClose,
    setMappedCsvHeaders,
    mappedCsvHeaders,
}: {
    addToHeaderOptions: (header: { label: string; value: string }) => void;
    createCustomFieldModal: string;
    onClose: () => void;
    setMappedCsvHeaders: (value: SetStateAction<{}>) => void;
    mappedCsvHeaders: any;
}) => {
    const [fieldName, setFieldName] = useState("");
    const fieldKey = `custom.${camelize(fieldName)}`;
    return (
        <BaseModal
            hideCloseButton={true}
            onClose={onClose}
            overlayStyles={{
                padding: "1.7rem 1.5rem",
            }}
        >
            <Stack gap={2} alignItems={"center"} direction={"row"}>
                <Typography>Add "{createCustomFieldModal}"</Typography>
                <JoyProvider>
                    <Input sx={{ flexGrow: 1 }} value={fieldName} onChange={(e) => setFieldName(e.target.value)} />
                </JoyProvider>
            </Stack>
            <Stack gap={1} mt={2} alignItems={"center"} justifyContent={"flex-end"} direction={"row"}>
                <Button size="small" variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                        addToHeaderOptions({ label: fieldName, value: fieldKey });
                        setTimeout(() => {
                            const newMappedHeaders: any = { ...mappedCsvHeaders };
                            newMappedHeaders[createCustomFieldModal] = fieldKey;
                            setMappedCsvHeaders(newMappedHeaders);
                            onClose();
                        }, 0);
                    }}
                    disabled={fieldName === ""}
                >
                    Submit
                </Button>
            </Stack>
        </BaseModal>
    );
};

export default function CsvHeaders({ onSubmit, options = [] }: CsvHeadersProps) {
    const isEasyGrowth = useSelector(checkEasyGrowth);

    if (options.length === 0) {
        if (isEasyGrowth) {
            options = easyGrowthMappingOptions;
        } else {
            options = easySourceMappingOptions;
        }
    }

    const dispatch = useDispatch();
    const csvFile = useSelector(selectCsvFile);
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [mappedCsvHeaders, setMappedCsvHeaders] = useState({});
    const isCsvUploading = useSelector(checkIfLoading(uploadCsv.type));
    const [createCustomFieldModal, setCreateCustomFieldModal] = useState<null | string>(null);
    const customFields = useSelector(selectCustomFields);
    const [headerOptions, setHeaderOptions] = useState([...options, ...(customFields || [])]);

    useEffect(() => {
        if (!csvFile) return;

        const processInitialMapping = (fields: string[]) => {
            const newMappedHeaders: { [key: string]: string } = {};
            fields.forEach((item: string) => {
                const recordValue = item.toLocaleLowerCase();
                const record = headerOptions.find(
                    (option) =>
                        option.value.toLocaleLowerCase() === recordValue ||
                        option.label.toLocaleLowerCase() === recordValue
                )?.value;
                if (record) newMappedHeaders[item] = record;
            });
            setMappedCsvHeaders(newMappedHeaders);
        };

        //@ts-ignore
        csvParser.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: any) {
                const { fields } = results?.meta;
                if (fields?.length) {
                    setCsvHeaders(fields);
                    processInitialMapping(fields);
                }
            },
        });
    }, []);

    const onSelectHeader = (value: string, index: number) => {
        const newMappedHeaders: any = { ...mappedCsvHeaders };
        newMappedHeaders[csvHeaders[index]] = value;
        setMappedCsvHeaders(newMappedHeaders);
    };

    const onSelectNone = (index: number) => {
        const newMappedHeaders: any = { ...mappedCsvHeaders };
        delete newMappedHeaders[csvHeaders[index]];
        setMappedCsvHeaders(newMappedHeaders);
    };

    const addToHeaderOptions = (header: { label: string; value: string }) => {
        setHeaderOptions((prevOptions) => [...prevOptions, header]);
    };

    const columns = [
        {
            title: "Csv headers",
            dataIndex: "orgName",
            minWidth: 250,
            canSort: true,
            maxTitleLength: 12,
            render: (record: any) => record,
        },
        {
            title: "Select headers",
            dataIndex: "orgName",
            minWidth: 250,
            canSort: true,
            maxTitleLength: 15,
            render: (record: any, index: number) => {
                //@ts-ignore
                const value = mappedCsvHeaders[record] || [];

                return (
                    <FormControl fullWidth>
                        <Select id="demo-simple-select" placeholder="Select" value={value}>
                            <MenuItem onClick={() => onSelectNone(index)}>None</MenuItem>
                            <Divider />

                            <MenuItem onClick={() => setCreateCustomFieldModal(record)}>
                                <Typography sx={{ fontWeight: 500 }}>+ Map to custom new field</Typography>
                            </MenuItem>

                            {headerOptions.map((option, idx) => (
                                <MenuItem
                                    key={`Mapping-option-${idx}`}
                                    value={option.value}
                                    onClick={() => onSelectHeader(option.value, index)}
                                    disabled={Object.values(mappedCsvHeaders).includes(option.value)}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            },
        },
    ];

    const size = useWindowSize();
    const tableHeight = size.height - 150;

    return (
        <Dialog open={true} onClose={() => dispatch(setShowCsvHeaderDialog(false))}>
            <DialogTitle>Set csv headers</DialogTitle>
            <DialogContent>
                <CustomTable
                    columns={columns}
                    total={csvHeaders?.length}
                    rows={csvHeaders}
                    tableHeight={tableHeight}
                    isLoading={!csvHeaders?.length}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => dispatch(setShowCsvHeaderDialog(false))}>
                    Cancel
                </Button>

                <Button
                    disabled={isCsvUploading}
                    variant="contained"
                    startIcon={<UploadIcon />}
                    onClick={() => {
                        onSubmit(mappedCsvHeaders);
                    }}
                >
                    <ButtonTextWithLoading isLoading={isCsvUploading} text="Upload" />
                </Button>
            </DialogActions>
            {createCustomFieldModal && (
                <CreateNewFieldModal
                    createCustomFieldModal={createCustomFieldModal}
                    onClose={() => setCreateCustomFieldModal(null)}
                    addToHeaderOptions={addToHeaderOptions}
                    mappedCsvHeaders={mappedCsvHeaders}
                    setMappedCsvHeaders={setMappedCsvHeaders}
                />
            )}
        </Dialog>
    );
};
