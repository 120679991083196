import MUIModal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EditSignature } from "../../OutReach/components/EditSignature";
import BaseModal from "../../triggerWorkflow/components/BaseModal";

import { isSignatureModalOpen, toggleEditSignatureModal } from "@/store/reducers/outreach/outreach.slice";


export default function EditSignatureHandler() {
    const dispatch = useDispatch();
    const editSignature = useSelector(isSignatureModalOpen);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (editSignature !== undefined) {
            setShowModal(editSignature);
        }
    }, [editSignature]);

    const handleClose = () => {
        setShowModal(false);
        dispatch(toggleEditSignatureModal(false));
    };
    return (
        <>
            <MUIModal open={showModal} onClose={handleClose}>
                <BaseModal onClose={handleClose} hideCloseButton={!false} overlayStyles={{ padding: 0, maxWidth: '26vw' }}>
                    <EditSignature />
                </BaseModal>
            </MUIModal>
        </>
    );
}