import AddToPhotosRoundedIcon from "@mui/icons-material/AddToPhotosRounded";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { useAsyncFn } from "../../../hooks/useAsyncFn";
import { selectCreateContactListFromAllContacts, setModal } from "../../../store/reducers/modals.slice";
import API from "../../../utils/API";
import handleException from "../../../utils/sentry";
import BaseModal from "../../triggerWorkflow/components/BaseModal";

import {
    selectAllContactsSearchResults,
    selectAllContactsSelected,
    selectSearchQueryInAllContacts,
    toggleSelectAllSearchResults,
} from "@/store/apis/all-contacts/all-contacts.slice";
import { CreateContactListPayload, CreateContactListResponse } from "@/store/apis/all-contacts/all-contacts.types";

function CreateContactListModal() {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const dispatch = useDispatch();
    const showCreateContactListModal = useSelector(selectCreateContactListFromAllContacts);
    const isAllSelected = useSelector(selectAllContactsSearchResults);
    const searchQuery = useSelector(selectSearchQueryInAllContacts);
    const selectedContacts = useSelector(selectAllContactsSelected);
    const navigate = useNavigate();
    const { status, submit } = useAsyncFn(async () => {
        try {
            const payload: CreateContactListPayload = {
                selectAll: isAllSelected,
                search: searchQuery,
                hubspotContactIds: isAllSelected ? [] : selectedContacts,
                name,
            };

            const response = (await new API().post("/contact-list/create", payload)) as CreateContactListResponse;

            if (response?.data && "_id" in response?.data) {
                const id = response.data._id;
                navigate(`/contact-lists/${id}`);
                dispatch(
                    setModal({
                        key: "showCreateContactListModalFromAllContacts",
                        value: false,
                    })
                );
                dispatch(toggleSelectAllSearchResults());
            } else {
                throw new Error("invalid response");
            }
        } catch (error) {
            handleException(error);
            throw new Error("something went wrong!");
        }
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submit();
    };

    const onClose = () =>
        dispatch(
            setModal({
                key: "showCreateContactListModalFromAllContacts",
                value: false,
            })
        );

    if (!showCreateContactListModal) {
        return null;
    }
    const isContactsSelected = !!selectedContacts.length || isAllSelected;
    const isLoading = status === "LOADING";
    const isDisabled = isLoading || !isContactsSelected || (isAllSelected && !searchQuery);

    return (
        <BaseModal onClose={onClose} hideCloseButton={isDisabled}>
            <Stack spacing={2}>
                <Typography variant="h6">{t("allContacts.createContactListBtnText")}</Typography>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    }}
                >
                    <TextField
                        type="text"
                        name="name"
                        variant="outlined"
                        sx={{ width: "100%", fontSize: "14px" }}
                        inputProps={{
                            style: { padding: "0.7rem", fontSize: "14px" },
                            maxLength: "50",
                            minLength: "1",
                        }}
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <CustomButton
                        type="submit"
                        variant="contained"
                        sx={{ alignSelf: "flex-end" }}
                        disabled={isDisabled}
                    >
                        <ButtonTextWithLoading isLoading={isLoading} text="create" />
                    </CustomButton>
                </form>
            </Stack>
        </BaseModal>
    );
}

export default function CreateContactList() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllSelected = useSelector(selectAllContactsSearchResults);
    const searchQuery = useSelector(selectSearchQueryInAllContacts);
    const selectedContacts = useSelector(selectAllContactsSelected);
    const isContactsSelected = !!selectedContacts.length || isAllSelected;

    const isDisabled = !isContactsSelected || (isAllSelected && !searchQuery);

    const handleOpen = () =>
        dispatch(
            setModal({
                key: "showCreateContactListModalFromAllContacts",
                value: true,
            })
        );

    return (
        <>
            <CustomButton
                variant="contained"
                startIcon={<AddToPhotosRoundedIcon />}
                onClick={handleOpen}
                disabled={isDisabled}
            >
                {t("allContacts.createContactListBtnText")}
            </CustomButton>
            <CreateContactListModal />
        </>
    );
}
