import { Box, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import NudgeArrow from "./NudgeArrow";

import { nudgeCloseIcon } from "../../constant/Constant";

import { INudge, editNudge } from "@/store/reducers/nudges/Nudges.reducer";

export type INudgeVariant = "left" | "right" | "top" | "bottom";

type IProps = {
    variant: INudgeVariant;
    text: string;
    wrapperStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    arrowStyle?: React.CSSProperties;
    closeIconStyle?: React.CSSProperties;
    type: INudge;
};

const getCloseIconStyles = (variant: INudgeVariant) => {
    switch (variant) {
        case "top": {
            return {
                top: 0,
                right: 0,
            };
        }
        case "bottom": {
            return {
                bottom: 0,
                right: 0,
            };
        }
        case "left": {
            return {
                top: 0,
                right: -20,
            };
        }
        case "right": {
            return {
                top: 0,
                left: -20,
            };
        }
    }
};

export default function Nudge({
    variant,
    text,
    wrapperStyle,
    containerStyle,
    arrowStyle,
    closeIconStyle,
    type,
}: IProps) {
    const dispatch = useDispatch();
    const handleClose = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(editNudge({ action: editNudge.type, type, value: true }));
    };

    return (
        <Box
            sx={{
                border: "1px solid #6042DF",
                padding: "0.5rem",
                backgroundColor: "#f6e8fc",
                zIndex: 2,
                ...wrapperStyle,
            }}
        >
            <img
                style={{
                    display: "inline-block",
                    width: 18,
                    height: 18,
                    position: "absolute",
                    cursor: "pointer",
                    ...getCloseIconStyles(variant),
                    ...closeIconStyle,
                }}
                src={nudgeCloseIcon}
                alt="nudge-close-icon"
                onClick={handleClose}
            />
            <Stack
                direction={
                    variant === "top" || variant === "right"
                        ? "row"
                        : variant === "bottom" || variant === "left"
                            ? "row-reverse"
                            : "row"
                }
                alignItems={"center"}
                sx={containerStyle}
            >
                <p
                    style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#6042DF",
                        fontStyle: "italic",
                    }}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                <NudgeArrow variant={variant} style={arrowStyle} />
            </Stack>
        </Box>
    );
}
