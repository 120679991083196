import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { IActionPayload, startAction, stopAction } from "../reducers/loaders.reducer";

import { getAllProjectsList } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    acceptInvite,
    addInvitation,
    changeInvitationStatus,
    changeOrgName,
    editMember,
    fetchInvitations,
    fetchMembers,
    fetchPendingInvitations,
    getNotification,
    inviteMembers,
    markReadNotification,
    rejectInvite,
    resetTeams,
    setInvitations,
    setMembers,
    setNotification,
    setOrgName,
    setPendingInvitations,
} from "@/store/reducers/manage-teams/manageTeams.reducers";
import {
    ChangeOrgNamePayload,
    IChangeNameResponse,
    IEditMember,
    IFetchInvitationsResponse,
    IInvitationUpdatePayload,
    IInvitationUpdateStatusResponse,
    IInvitationsTable,
    IInvitePayload,
    IInviteResponse,
    IMembersResponse,
    Member,
} from "@/store/reducers/manage-teams/manageTeams.types";

function* fetchMembersSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IMembersResponse = yield call(new API().get, "/organization/members");
        if (!response?.data) return;

        yield put(setMembers(response.data));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editMemberSaga({
    type,
    payload,
}: PayloadAction<IEditMember & { successCallback?: () => void }>): SagaIterator {
    try {
        const { successCallback, ...form } = payload;
        const state = yield select();
        const members = get(state, "teams.members");
        yield put(startAction({ action: type }));
        const response: any = yield call(new API().post, "/organization/member/edit", {
            ...form,
        });
        if (!response?.data) return;
        successCallback?.();
        yield put(
            setMembers({
                members: members.map((member: Member) =>
                    member._id === form.userId ? { ...member, ...form } : member
                ),
            })
        );
        yield put(setSuccessNotification(response?.message ?? "Project saved succesfully"));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* inviteMembersSaga({ payload }: { payload: IInvitePayload & IActionPayload }): SagaIterator {
    try {
        const { openSlackModal, successCallback } = payload;
        yield put(startAction({ action: payload.action }));
        const response: IInviteResponse = yield call(new API().post, "/invitations/create", {
            email: payload.email,
            name: payload.name,
            role: payload.role,
        });
        if (response.message) {
            yield put(setSuccessNotification(response.message));
        }
        if (!response.data) return;

        const invite: IInvitationsTable = {
            ...response.data.recipient,
            id: response.data._id,
            status: response.data.status,
            rejectedOn: response.data.updatedAt,
            org: response.data.orgId.name,
            senderName: response.data.sender.name,
        };

        yield put(addInvitation(invite));
        successCallback?.();
        openSlackModal?.();
        yield put(setSuccessNotification("Invitation sent successfully"));
        handleCleverTap("Invite Member", {
            email: payload.email,
            role: payload.role,
        });
    } catch (error) {
        yield put(setErrorNotification("error while sending invitation"));
        console.error("**inviteMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* changeOrgNameSaga({ payload }: { payload: IActionPayload & ChangeOrgNamePayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IChangeNameResponse = yield call(new API().post, "/organization/edit", {
            name: payload.name,
        });

        if (!response?.data) return;
        yield put(setOrgName({ name: response.data.name }));
        // yield put(addInvitation(response.data));
    } catch (error) {
        console.error("**changeOrgName", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchInvitationsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IFetchInvitationsResponse = yield call(new API().get, "/invitations/get");

        if (!response?.data) return;

        const invitations: IInvitationsTable[] = response.data.map(
            ({ updatedAt, status, _id, recipient: { ...rest }, orgId, sender }) => ({
                ...rest,
                status,
                rejectedOn: updatedAt,
                id: _id,
                org: orgId?.name,
                senderName: sender?.name,
            })
        );

        yield put(setInvitations(invitations));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* acceptInvitationSaga({ payload }: { payload: IActionPayload & IInvitationUpdatePayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IInvitationUpdateStatusResponse = yield call(new API().post, "/invitations/edit", {
            _id: payload._id,
            status: payload.status,
        });
        if (!response?.data) return;

        yield put(
            changeInvitationStatus({
                _id: response.data._id,
                status: response.data.status,
            })
        );

        yield put(getAllProjectsList({ action: getAllProjectsList.type }));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* rejectInvitationSaga({ payload }: { payload: IActionPayload & IInvitationUpdatePayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IInvitationUpdateStatusResponse = yield call(new API().post, "/invitations/edit", {
            _id: payload._id,
            status: payload.status,
        });
        if (!response?.data) return;

        yield put(
            changeInvitationStatus({
                _id: response.data._id,
                status: response.data.status,
            })
        );
    } catch (error) {
        yield put(stopAction({ action: payload.action }));
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    }
}

function* pendingInvitationsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IFetchInvitationsResponse = yield call(new API().get, "/invitations/pending");

        if (!response?.data) {
            throw new Error();
        }

        const invitations: IInvitationsTable[] = response.data.map(
            ({ updatedAt, status, _id, recipient: { ...rest }, orgId, sender }) => ({
                ...rest,
                status,
                rejectedOn: updatedAt,
                id: _id,
                org: orgId?.name,
                senderName: sender?.name,
            })
        );

        yield put(setPendingInvitations(invitations));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getNotificationSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: getNotification.type }));
        const response: IFetchInvitationsResponse = yield call(new API().get, "/notification/get");
        if (!response?.data) return;
        yield put(setNotification(response?.data));
    } catch (error) {
        console.error("**getNotificationSaga", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: getNotification.type }));
    }
}

function* markReadNotificationSaga({ payload }: { payload: string[] }): SagaIterator {
    try {
        yield put(startAction({ action: markReadNotification.type }));
        const response: IFetchInvitationsResponse = yield call(new API().put, `/notification/edit/`, {
            active: false,
            notificationIds: payload,
        });
        if (!response?.data) return;
        yield put(getNotification());
    } catch (error) {
        console.error("**markReadNotificationSaga", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: markReadNotification.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchMembers.type, fetchMembersSaga),
        // @ts-ignore
        yield takeLatest(editMember.type, editMemberSaga),
        // @ts-ignore
        yield takeLatest(inviteMembers.type, inviteMembersSaga),
        // @ts-ignore
        yield takeLatest(changeOrgName.type, changeOrgNameSaga),
        // @ts-ignore
        yield takeLatest(fetchInvitations.type, fetchInvitationsSaga),
        // @ts-ignore
        yield takeLatest(acceptInvite.type, acceptInvitationSaga),
        // @ts-ignore
        yield takeLatest(rejectInvite.type, rejectInvitationSaga),
        // @ts-ignore
        yield takeLatest(fetchPendingInvitations.type, pendingInvitationsSaga),
        // @ts-ignore
        yield takeLatest(getNotification.type, getNotificationSaga),
        // @ts-ignore
        yield takeLatest(markReadNotification.type, markReadNotificationSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetTeams.type, CancelSagas, tasks);
}
