import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { Stack, Toolbar, Tooltip, Typography, alpha } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddContacts from "./components/AddContacts";
import AddToExclusionList from "./components/AddToExclusionList";
import CreateContactList from "./components/CreateContactList";
import ExportContacts from "./components/ExportContacts";
import FetchLoadingWithData from "./components/FetchLoadingWithData";
import FiltersLoading from "./components/FiltersLoading";
import SearchLoading from "./components/SearchLoading";
import SelectionPrompt from "./components/SelectionPrompt";

import {
    fetchContacts,
    numberOfSelectedRowsInAllContacts,
    setAllContactsSearchQuery,
    toggleAllContactsFiltersDrawer,
} from "../../store/apis/all-contacts/all-contacts.slice";

const CustomOutlineInput = styled((props: OutlinedInputProps) => <OutlinedInput {...props} />)(() => ({
    borderRadius: "1rem",
    padding: 0,
    minWidth: "400px",
    "& .MuiOutlinedInput-input": {
        padding: "0.5rem 0.7rem",
        fontSize: "12px",
        borderRadius: "0.7rem",
    },
}));

function Search() {
    const dispatch = useDispatch();
    const inputRef = useRef<null | HTMLInputElement>();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        if (value) {
            dispatch(setAllContactsSearchQuery(value));
        } else {
            dispatch(fetchContacts());
        }
    };

    const handleClearText = () => {
        if (inputRef?.current) {
            inputRef.current.value = "";
            dispatch(fetchContacts());
        }
    };

    return (
        <CustomOutlineInput
            inputRef={inputRef}
            type="text"
            placeholder="Please enter text"
            onChange={debounce(handleChange, 500)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton type="submit" color="primary" onClick={handleClearText}>
                        <CancelRoundedIcon fontSize="small" />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}

export function AllContactsToolbar() {
    const dispatch = useDispatch();
    const numSelected = useSelector(numberOfSelectedRowsInAllContacts);

    const toggle = () => dispatch(toggleAllContactsFiltersDrawer());

    return (
        <Toolbar
            sx={{
                justifyContent: "end",
                // pl: { sm: 2 },
                // pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Stack sx={{ width: "100%" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" px={1.5} py={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {numSelected > 0 ? (
                            <Typography sx={{ flex: "1 1 10%" }} color="inherit" variant="subtitle1" component="div">
                                {numSelected} selected
                            </Typography>
                        ) : null}
                        <AddContacts />
                        <ExportContacts />
                        <CreateContactList />
                        <AddToExclusionList />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <FiltersLoading />
                        <SearchLoading />
                        <FetchLoadingWithData />
                        <Search />
                        {numSelected <= 0 && (
                            <Tooltip title="Filter list">
                                <IconButton onClick={toggle}>
                                    <FilterAltRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </Stack>
                <SelectionPrompt />
            </Stack>
        </Toolbar>
    );
}
