import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export interface Option {
    label: string;
    value: string;
}

type TInitialState = {
    candidates: any[];
    selectedCandidate: any[];
    canShowAddToProject: boolean;
    errors: { [key: string]: any };
    activeCandidateInfoModalIndex: number | null;
    activeModal: boolean;
    activeHiddenEditModal: boolean;
};

const initialState: TInitialState = {
    candidates: [],
    selectedCandidate: [],
    canShowAddToProject: false,
    errors: {},
    activeCandidateInfoModalIndex: null,
    activeModal: false,
    activeHiddenEditModal: false,
};

const pageSlice = createSlice({
    name: "advancedFilterCandidates",
    initialState,
    reducers: {
        setCandidates(state, action: PayloadAction<any[]>) {
            state.candidates = action.payload;
        },
        selectCandidate(state, action: PayloadAction<any[]>) {
            state.selectedCandidate = action.payload;
        },
        setCanShowAddToProject(state, action: PayloadAction<boolean>) {
            state.canShowAddToProject = action.payload;
        },
        addToProject(state, action: PayloadAction<number>) {
            const candidateId = action.payload;
            const candidate = state.candidates.find((c) => c.id === candidateId);
            if (candidate) {
                state.selectedCandidate.push(candidate);
                state.canShowAddToProject = true;
            }
        },
        createProject(state, action: PayloadAction<string>) {
            const projectName = action.payload;
            if (projectName) {
                state.selectedCandidate = [];
                state.canShowAddToProject = false;
            }
        },
        setActiveCandidateInfoModalIndex(state, action: PayloadAction<number | null>) {
            state.activeCandidateInfoModalIndex = action.payload;
        },
        setActiveModal(state, action: PayloadAction<boolean>) {
            state.activeModal = action.payload;
        },
        setActiveHiddenEditModal(state, action: PayloadAction<boolean>) {
            state.activeHiddenEditModal = action.payload;
        },
        leavePage(state) {
            state.candidates = [];
            state.selectedCandidate = [];
            state.canShowAddToProject = false;
            state.errors = {};
            state.activeCandidateInfoModalIndex = null;
            state.activeModal = false;
            state.activeHiddenEditModal = false;
        },
    },
});

export const {
    setCandidates,
    selectCandidate,
    setCanShowAddToProject,
    addToProject,
    createProject,
    setActiveCandidateInfoModalIndex,
    setActiveModal,
    setActiveHiddenEditModal,
    leavePage,
} = pageSlice.actions;

export default pageSlice.reducer;

export const selectCandidates = (state: RootState) => state.advancedFilterCandidates.candidates;
export const selectSelectedCandidate = (state: RootState) => state.advancedFilterCandidates.selectedCandidate;
export const selectCanShowAddToProject = (state: RootState) => state.advancedFilterCandidates.canShowAddToProject;
export const selectActiveCandidateInfoModalIndex = (state: RootState) =>
    state.advancedFilterCandidates.activeCandidateInfoModalIndex;
export const selectActiveModal = (state: RootState) => state.advancedFilterCandidates.activeModal;
export const selectActiveHiddenEditModal = (state: RootState) => state.advancedFilterCandidates.activeHiddenEditModal;
