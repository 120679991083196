import Hotjar from "@hotjar/browser";

import { signinType } from "@/store/reducers/signin/Signin.types";

const siteId = 3710443;
const hotjarVersion = 6;

export function loadHotjar(user: signinType) {
    const env = import.meta.env.VITE_REACT_APP_ENV;
    if (!user?._id || !user?.orgData?._id || env !== "production") return;
    const userId = user._id;
    const orgId = user.orgData?._id;
    Hotjar.init(siteId, hotjarVersion);
    Hotjar.identify(String(userId), {
        hqId: userId,
        orgId: orgId,
    });
}
