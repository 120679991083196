import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { CircularProgress, IconButton, IconButtonProps, Stack, Tooltip } from "@mui/joy";
import { useSelector } from "react-redux";

import { JoyProvider } from "../../../components/JoyProvider";

import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { updateProjectCandidatesCSReviewStage } from "@/store/reducers/project/project.reducer";
import { CS_STATUS } from "@/store/reducers/project/project.types";

const StageMap: { [key: string]: CS_STATUS } = {
    "CS Accepted": "ACCEPTED",
    "CS Pending": "PENDING",
    "CS Rejected": "REJECTED",
};

const StyledIconButton = (props: IconButtonProps) => {
    const { children, ...rest } = props;
    return (
        <IconButton size="md" {...rest}>
            {children}
        </IconButton>
    );
};

type CSReviewStatusProps = {
    variant?: "card" | "modal";
    stage?: string;
    handleStatusChange: (type: CS_STATUS) => void;
};

export function CSReviewStatus({ variant, stage, handleStatusChange }: CSReviewStatusProps) {
    const currentStatus: CS_STATUS = StageMap[stage as string];
    const isLoading = useSelector(checkIfLoading(updateProjectCandidatesCSReviewStage.type));

    const handleClickStatus = (status: CS_STATUS) => {
        handleStatusChange(currentStatus === status ? "PENDING" : status);
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <JoyProvider>
                <Stack direction="row" gap={2} alignItems={"center"}>
                    {isLoading && <CircularProgress size="sm" />}
                    <StyledIconButton
                        disabled={isLoading}
                        variant={currentStatus === "ACCEPTED" ? "solid" : "plain"}
                        onClick={() => handleClickStatus("ACCEPTED")}
                        color={currentStatus === "ACCEPTED" ? "primary" : "neutral"}
                    >
                        <Tooltip title="Approve">
                            <ThumbUpIcon sx={{ fontSize: 18 }} />
                        </Tooltip>
                    </StyledIconButton>

                    <StyledIconButton
                        disabled={isLoading}
                        variant={currentStatus === "REJECTED" ? "solid" : "plain"}
                        onClick={() => handleClickStatus("REJECTED")}
                        color={currentStatus === "REJECTED" ? "danger" : "neutral"}
                    >
                        <Tooltip title="Reject">
                            <ThumbDownIcon sx={{ fontSize: 18 }} />
                        </Tooltip>
                    </StyledIconButton>
                </Stack>
            </JoyProvider>
        </div>
    );
}
