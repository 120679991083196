import React, { useState } from "react";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import WorkspacesRoundedIcon from "@mui/icons-material/WorkspacesRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    authLogin,
    checkAllowPasswordReset,
    checkCalendlyAuthorized,
    checkIsLinkedinAuthorized,
    selectUser,
    setLinkedinAuthStatus,
    signOut,
} from "../../../pages/Signin/Signin.reducer";
import { setModal } from "../../../store/reducers/modals.slice";
import { baseUrl } from "../../../utils/API";
import { setAuthorizeModal } from "../../MailAlert/mailAlert.reducers";
import ProfileAvatar from "../../ProfileAvatar/ProfileAvatar";

export const CALENDLY_URI = `https://auth.calendly.com/oauth/authorize?client_id=${import.meta.env.VITE_REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${baseUrl}/auth/calendly`;

// ? IAccountMenuProps is optional because we are not using it in the navbar for now
type IAccountMenuProps = {
    setShowReferralModal?: React.Dispatch<React.SetStateAction<boolean>>;
    startWalkthrough?: () => void;
};

const GenerateNavigationLinks = ({
    isCalendlyAuthorized,
    allowPasswordReset,
}: {
    isCalendlyAuthorized: boolean;
    allowPasswordReset: boolean;
}) => {
    const links = [
        {
            href: "/my-account/details",
            title: "Workspace settings",
            subtitle: "AI, data, profile, team and more",
            icon: (
                <WorkspacesRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        },
        {
            href: "/notifications",
            title: "Notifications",
            subtitle: "All notifications on EasySource activity",
            icon: (
                <NotificationsRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        },
        {
            href: "https://forms.gle/PHMMSKnn98msnWGs9",
            target: "_blank",
            title: "Share feedback",
            subtitle: "Fill form or write to use at customer-support@hirequotient.com",
            icon: (
                <ThumbUpAltRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        },
    ];

    if (allowPasswordReset) {
        links.push({
            href: "/reset-password?step=1",
            target: "_blank",
            title: "Reset your password",
            subtitle: "Quickly reset your password in 2 steps",
            icon: (
                <LockOpenIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        });
    }

    if (!isCalendlyAuthorized) {
        links.push({
            href: CALENDLY_URI,
            target: "_blank",
            title: "Connect Calendly",
            subtitle: "Connect to your calendly account",
            icon: (
                <CalendarTodayRoundedIcon
                    className="icon"
                    sx={(theme) => ({
                        width: "20px",
                        height: "20px",
                        color: theme.palette.grey[500],
                        marginTop: 0.25,
                    })}
                />
            ),
        });
    }

    return links;
};

export default function AccountMenu({ setShowReferralModal, startWalkthrough }: IAccountMenuProps) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isCalendlyAuthorized = useSelector(checkCalendlyAuthorized);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const emailAuthorized = user.emailAuthorized;
    const allowPasswordReset = useSelector(checkAllowPasswordReset);
    const showAuthorizeModal = () => {
        dispatch(setAuthorizeModal(true));
    };
    const onClickLinkedinAuthRefresh = (e: any) => {
        e.preventDefault();
        dispatch(authLogin({ action: authLogin.type, user }));
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openEl = Boolean(anchorEl);

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e.currentTarget == null) return;
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => setAnchorEl(null);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    const NavigationLinks = GenerateNavigationLinks({
        isCalendlyAuthorized: Boolean(isCalendlyAuthorized),
        allowPasswordReset: Boolean(allowPasswordReset),
    });

    const id = openEl ? "user-popover" : undefined;

    return (
        <>
            <ProfileAvatar onClick={openMenu} />
            <Popover
                id={id}
                open={openEl}
                anchorEl={anchorEl}
                onClose={closeMenu}
                sx={{
                    "& .MuiPaper-elevation": {
                        borderRadius: "0.5rem",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    },
                }}
                style={{
                    borderRadius: "1rem",
                    marginTop: "10px",
                    zIndex: 99,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack
                    sx={{
                        width: "325px",
                    }}
                >
                    <Stack
                        sx={(theme) => ({
                            paddingBottom: 3,
                            borderBottom: "1px solid",
                            borderColor: theme.palette.grey[300],
                        })}
                        padding={2}
                        gap={1}
                        direction={"row"}
                        alignItems={"center"}
                    >
                        <ProfileAvatar />
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                {user?.displayName}
                            </Typography>
                            <Typography
                                sx={(theme) => ({
                                    fontSize: "13px",
                                    color: theme.palette.grey[600],
                                    fontStyle: "italic",
                                })}
                            >
                                {user.email}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        {NavigationLinks.map(({ href, title, subtitle, icon, target }) => {
                            return (
                                <Stack
                                    key={href}
                                    sx={(theme) => ({
                                        ":hover": {
                                            cursor: "pointer",
                                            background: theme.palette.grey[200],
                                            "& .link-title": {
                                                color: "#0891b2",
                                            },
                                            "& .icon": {
                                                color: "#0891b2",
                                            },
                                        },
                                    })}
                                    p={1.75}
                                    gap={1}
                                    direction={"row"}
                                >
                                    {icon}
                                    <Stack>
                                        <Link target={target && target} style={{ textDecoration: "none" }} to={href}>
                                            <Typography
                                                className="link-title"
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                }}
                                            >
                                                {title}
                                            </Typography>
                                            <Typography
                                                sx={(theme) => ({
                                                    fontSize: "13px",
                                                    color: theme.palette.grey[600],
                                                    fontStyle: "italic",
                                                })}
                                            >
                                                {subtitle}
                                            </Typography>
                                        </Link>
                                    </Stack>
                                </Stack>
                            );
                        })}
                        {!emailAuthorized && (
                            <Stack
                                sx={(theme) => ({
                                    ":hover": {
                                        cursor: "pointer",
                                        background: theme.palette.grey[200],
                                        "& .link-title": {
                                            color: "#0891b2",
                                        },
                                        "& .icon": {
                                            color: "#0891b2",
                                        },
                                    },
                                })}
                                p={1.75}
                                gap={1}
                                direction={"row"}
                            >
                                <EmailRoundedIcon
                                    className="icon"
                                    sx={(theme) => ({
                                        width: "20px",
                                        height: "20px",
                                        color: theme.palette.grey[500],
                                        marginTop: 0.25,
                                    })}
                                />
                                <Stack>
                                    <Box style={{ textDecoration: "none" }} onClick={showAuthorizeModal}>
                                        <Typography
                                            className="link-title"
                                            sx={{
                                                fontSize: "14px",
                                                color: "black",
                                            }}
                                        >
                                            Authorize Mail
                                        </Typography>
                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: "13px",
                                                color: theme.palette.grey[600],
                                                fontStyle: "italic",
                                            })}
                                        >
                                            Connect to your mail account
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        )}

                        {!isLinkedInAuthorized && (
                            <Stack
                                sx={(theme) => ({
                                    ":hover": {
                                        cursor: "pointer",
                                        background: theme.palette.grey[200],
                                        "& .link-title": {
                                            color: "#0891b2",
                                        },
                                        "& .icon": {
                                            color: "#0891b2",
                                        },
                                    },
                                })}
                                p={1.75}
                                gap={1}
                                direction={"row"}
                            >
                                <EmailRoundedIcon
                                    className="icon"
                                    sx={(theme) => ({
                                        width: "20px",
                                        height: "20px",
                                        color: theme.palette.grey[500],
                                        marginTop: 0.25,
                                    })}
                                />
                                <Stack>
                                    <Box
                                        style={{ textDecoration: "none" }}
                                        onClick={() => {
                                            dispatch(
                                                setModal({
                                                    key: "showLinkedInAuthorizeModal",
                                                    value: true,
                                                })
                                            ),
                                                dispatch(setLinkedinAuthStatus(null));
                                        }}
                                    >
                                        <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                                            <Typography
                                                className="link-title"
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                }}
                                            >
                                                Authorize Linkedin
                                            </Typography>
                                            <IconButton onClick={onClickLinkedinAuthRefresh} sx={{ padding: 0 }}>
                                                <CachedRoundedIcon
                                                    className="icon"
                                                    sx={(theme) => ({
                                                        width: "20px",
                                                        height: "20px",
                                                        color: theme.palette.grey[500],
                                                        marginTop: 0.25,
                                                    })}
                                                />
                                            </IconButton>
                                        </Stack>

                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: "13px",
                                                color: theme.palette.grey[600],
                                                fontStyle: "italic",
                                            })}
                                        >
                                            Connect to your linkedin account
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        )}
                        <Stack
                            sx={(theme) => ({
                                ":hover": {
                                    cursor: "pointer",
                                    background: theme.palette.grey[200],
                                    "& .icon": {
                                        color: "#0891b2",
                                    },
                                },
                            })}
                            p={2}
                            gap={1}
                            direction={"row"}
                            onClick={handleSignOut}
                        >
                            <LogoutRoundedIcon
                                className="icon"
                                sx={(theme) => ({
                                    width: "20px",
                                    height: "20px",
                                    color: theme.palette.grey[500],
                                    marginTop: 0.25,
                                })}
                            />
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: "black",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
}
