import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import styles from "../CompanyPage.module.css";

import { selectCompanyLogo, setCompanyLogo, updateCompanyPage } from "@/store/reducers/companyPage/CompanyPage.reducer";


const EditNameLogo = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const logo: any = useSelector(selectCompanyLogo);

    const isProjectUpdating = useSelector(checkIfLoading(updateCompanyPage.type));

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                <Typography className={styles.uploadTitle} variant="h5">
                    Select Logo
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "40px" }}>
                <Stack>
                    <TextField
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(setCompanyLogo(e.target.files))}
                    ></TextField>
                    <Stack flexDirection="row" justifyContent="flex-end" mt="30px">
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                                marginRight: "10px",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!logo?.size || isProjectUpdating}
                            onClick={() => {
                                dispatch(
                                    updateCompanyPage({
                                        fileType: "logo",
                                    })
                                );
                                onClose();
                            }}
                            variant="contained"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                            }}
                        >
                            {isProjectUpdating ? "Submiting...." : "Submit"}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default EditNameLogo;
