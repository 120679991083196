import { TooltipProps } from "@mui/material/Tooltip";

export const tooltipSetting: Pick<TooltipProps, "componentsProps" | "placement"> = {
    placement: "top",
    componentsProps: {
        tooltip: {
            sx: {
                fontSize: 14,
                padding: 1,
                bgcolor: "common.white",
                color: "rgba(0,0,0,0.7)",
                border: "1px solid rgba(0,0,0,0.2)",
            },
        },
    },
};
