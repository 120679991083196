import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    AddExtraFollowupsPayload,
    BaseTemplateState,
    ChangeExtraFollowupStatusPayload,
    CustomBody,
    CustomSubject,
    ExtraFollowupsPayload,
    FetchBaseTemplatesPayload,
    FetchProjectBaseTemplatesPayload,
    RemoveExtraFollowups,
    SetBaseTemplatesPayload,
    SetInitialFollowupsPayload,
    SubmitBaseTemplatesPayload,
    SubmitProjectBaseTemplatesPayload,
} from "./customTemplate.types";

import { RootState } from "@/store";

const initialState: BaseTemplateState = {
    extraFollowups: {},
    initialFollowups: [],
    isErrorWhileFetchingBaseTemplates: false,
    id: null,
    email: {
        body: null,
        subject: null,
    },
    followUp: {
        body: null,
    },
    inMail: {
        body: null,
        subject: null,
    },
    linkedInReq: {
        body: null,
    },
    reminderMsg: {
        body: null,
    },
    inMailReminderMsg: {
        body: null,
    },
    sms: {
        body: null,
    },
    numberOfExtraFollowUp: 0,
};

export const customTemplates = createSlice({
    name: "customTemplates",
    initialState,
    reducers: {
        setInitialFollowups(state, action: SetInitialFollowupsPayload) {
            state.initialFollowups = action.payload;
        },
        changeExtraFollowupStatus(state, action: ChangeExtraFollowupStatusPayload) {
            const { index, isEnabled } = action.payload;
            if (index in state.extraFollowups) {
                state.extraFollowups[index].isEnabled = isEnabled;
            }
        },
        removeExtraFollowups(state, action: RemoveExtraFollowups) {
            const { index } = action.payload;
            if (index in state.extraFollowups) {
                delete state.extraFollowups[index];
            }
        },
        setExtraFollowupBody(state, action: ExtraFollowupsPayload) {
            const { body, index } = action.payload;
            if (index in state.extraFollowups) {
                state.extraFollowups[index].body = body;
            }
        },
        addExtraFollowups(state, action: AddExtraFollowupsPayload) {
            action.payload.forEach((value) => {
                const { body, index, isEnabled } = value;
                state.extraFollowups[index] = {
                    index,
                    body,
                    isEnabled,
                };
            });
        },
        setErrorWhileFetchingBaseTemplates(state, action: PayloadAction<boolean>) {
            state.isErrorWhileFetchingBaseTemplates = action.payload;
        },
        fetchBaseTemplates(_, action: FetchBaseTemplatesPayload) {},
        changeCustomSubject(state, action: PayloadAction<CustomSubject>) {
            const { key, subject } = action.payload;
            if (key === "email" || key === "inMail") {
                state[key].subject = subject;
            }
        },
        changeDelay(state, action: PayloadAction<{ index: number; delay: number }>) {
            const { index, delay } = action.payload;
            if (index === 1) {
                state.delayConn = delay;
            } else if (index === 3) {
                state.delaySms = delay;
            }
            if (index === 5) {
                state.delayFollowupEmail = delay;
            }
        },
        changeCustomBody(state, action: PayloadAction<CustomBody>) {
            const { key, body } = action.payload;
            if (
                key !== "id" &&
                key !== "delayConn" &&
                key !== "delaySms" &&
                key !== "delayFollowupEmail" &&
                key !== "numberOfExtraFollowUp" &&
                key !== "isErrorWhileFetchingBaseTemplates" &&
                key !== "extraFollowups" &&
                key !== "initialFollowups"
            ) {
                state[key].body = body;
            }
        },
        setBaseTemplates(state, action: PayloadAction<SetBaseTemplatesPayload>) {
            const {
                email,
                followUp,
                inMail,
                linkedInReq,
                reminderMsg,
                inMailReminderMsg,
                id,
                sms,
                numberOfExtraFollowUp,
                extraInitialFollowups,
                delaySms,
                delayConn,
                delayFollowupEmail,
            } = action.payload;
            state.delayConn = delayConn;
            state.delayFollowupEmail = delayFollowupEmail;
            state.delaySms = delaySms;
            state.email.body = email.body;
            state.email.subject = email.subject;

            state.followUp.body = followUp.body;

            state.inMail.body = inMail.body;
            state.inMail.subject = inMail.subject;

            state.linkedInReq.body = linkedInReq.body;

            state.sms.body = sms.body;

            if (reminderMsg && reminderMsg?.body) {
                state.reminderMsg.body = reminderMsg.body;
            }
            if (inMailReminderMsg && inMailReminderMsg?.body) {
                state.inMailReminderMsg.body = inMailReminderMsg.body;
            }

            state.id = id;
            state.numberOfExtraFollowUp = numberOfExtraFollowUp;
            state.initialFollowups = extraInitialFollowups?.length ? extraInitialFollowups : [];
        },
        submitBaseTemplates(state, action: SubmitBaseTemplatesPayload) {},
        submitSwitchesStatus(state, action: PayloadAction<{ projectId: number }>) {},
        fetchProjectBaseTemplates(_, action: FetchProjectBaseTemplatesPayload) {},
        submitProjectBaseTemplates(_, action: SubmitProjectBaseTemplatesPayload) {},
        resetCustomTemplates() {
            return initialState;
        },
        cancelActions() {},
    },
});

export default customTemplates.reducer;

export const {
    setInitialFollowups,
    addExtraFollowups,
    changeExtraFollowupStatus,
    removeExtraFollowups,
    setExtraFollowupBody,
    setErrorWhileFetchingBaseTemplates,
    changeCustomBody,
    changeCustomSubject,
    resetCustomTemplates,
    cancelActions,
    fetchBaseTemplates,
    setBaseTemplates,
    submitBaseTemplates,
    submitSwitchesStatus,
    fetchProjectBaseTemplates,
    submitProjectBaseTemplates,
    changeDelay,
} = customTemplates.actions;

export const selectBaseTemplatesCustomEmail = (state: RootState) => state.customTemplates.email;
export const selectBaseTemplatesCustomInMail = (state: RootState) => state.customTemplates.inMail;
export const selectBaseTemplatesCustomLinkedInConnReq = (state: RootState) => state.customTemplates.linkedInReq;
export const selectBaseTemplatesCustomFollowUpEmail = (state: RootState) => state.customTemplates.followUp;
export const selectBaseTemplatesCustomReminderMsg = (state: RootState) => state.customTemplates.reminderMsg;
export const selectBaseTemplatesCustomInMailReminderMsg = (state: RootState) => state.customTemplates.inMailReminderMsg;
export const selectBaseTemplatesSMS = (state: RootState) => state.customTemplates.sms;
export const selectNumberOfExtraFollowUp = (state: RootState) => state.customTemplates.numberOfExtraFollowUp;
export const selectExtraFollowups = (state: RootState) => state.customTemplates.extraFollowups;
export const selectExtraInitialFollowups = (state: RootState) => state.customTemplates.initialFollowups;
export const checkErrorWhileFetchingBaseTemplates = (state: RootState) =>
    state.customTemplates.isErrorWhileFetchingBaseTemplates;
export const delaySms = (state: RootState) => state.customTemplates.delaySms;
export const delayFollowupEmail = (state: RootState) => state.customTemplates.delayFollowupEmail;
export const delayConn = (state: RootState) => state.customTemplates.delayConn;
