import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, cancelled, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import store from "..";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { startSse } from "../../utils/firebase";
import { exportToCsvc } from "../../utils/helper";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { IActionPayload, startAction, stopAction } from "../reducers/loaders.reducer";

import {
    setCurrentProjectStatus,
    setProject,
    setProjectInsightGenerationProgress,
    setProjectInsightGenerationStatus,
    setProjectStatus,
} from "@/store/reducers/allProjects/allProjects.reducer";
import { setUserEmail } from "@/store/reducers/message/message.slice";
import { IMessage } from "@/store/reducers/message/message.types";
import { fetchUserPlan } from "@/store/reducers/my-account/myAccount.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { setNudges } from "@/store/reducers/nudges/Nudges.reducer";
import {
    addCandidateFeedback,
    addToWorkflow,
    bulkUploadCandidatesCVs,
    changeCandidatePrimaryEmail,
    changeStarRating,
    copyToProject,
    editCandidate,
    excludeCandidates,
    findLinkedinProfiles,
    getAddtoBlocklist,
    getBlacklist,
    getCandidateDetails,
    getCandidateEmail,
    getCandidateWorkflows,
    getDeletePowerDialerCandidates,
    getLinkedinApplicantCandidates,
    getListOfPowerDialerCampaigns,
    getProjectCandidates,
    getProjectStats,
    getProjectWorkflows,
    getRemoveFromBlocklist,
    getSendosoGifts,
    getSendosoGiftsSend,
    getShowByMaxExperience,
    getShowByMinExperience,
    getShowByPersonalEmails,
    getShowByProjectFilters,
    getShowByRecent,
    getShowByRelevance,
    getSuperAdminCandEmails,
    getSuperAdminCandPhoneNumbers,
    getTwilloCall,
    getTwilloCallLogs,
    getTwilloCallsOutgoing,
    getTwilloPowerCallCandidateList,
    getTwilloPowerCallListCreate,
    getWorkflowEmailStats,
    getWorkflowLinkedInStats,
    getWorkflowsLogsById,
    leaveProject,
    linkedinUrlUpload,
    marketResearch,
    marketResearchFetchData,
    marketResearchStop,
    moveTo,
    reExtractCandidateCV,
    refreshCandidateRating,
    scrapeCandidates,
    searchCandidates,
    setAllSendosoProductsLoaded,
    setAssociatedReachoutsFetchErrorStatus,
    setBlacklist,
    setCandidate,
    setCandidateDetails,
    setCandidateEmails,
    setCandidatePhoneNumbers,
    setCandidateResumeInfo,
    setCandidateStarRating,
    setCandidateWorkflows,
    setCandidates,
    setCandidatesFetchErrorStatus,
    setEmailEnrich,
    setLinkedinEnrich,
    setMarketResearchData,
    setMarketResearchDataFetchPercentage,
    setMarketResearchDataFetchStatus,
    setPhoneEnrich,
    setProjectCandidate,
    setProjectStats,
    setProjectWorkflows,
    setRequestId,
    setSalesNavProfilesCount,
    setScrapeCandidateModal,
    setSelectAllCandidates,
    setSelectedCandidates,
    setSendosoGifts,
    setSentGiftsCandidates,
    setSingleCandidateInsightGenerating,
    setStatsFetchErrorStatus,
    setTotalCandidates,
    setTotalSearchCount,
    setTwillioToken,
    setTwilloCallLogs,
    setTwilloPowerCallCandidateList,
    setTwilloPowerCallListCreate,
    setUpdateBulkCallCampaignStatus,
    setWorkflowCount,
    setWorkflowEmailStats,
    setWorkflowLinkedInStats,
    unlockCandidatesResume,
    updateBulkCallCampaign,
    updateProjectCandidatesCSReviewStage,
} from "@/store/reducers/project/project.reducer";
import {
    ExtractEmails,
    ExtractPhone,
    GetSendosoGiftsAction,
    IAddCandidateFeedbackPayload,
    IAddToWorkflow,
    IAddToWorkflowPayload,
    IBulkUploadCandCVsPayload,
    ICandidate,
    ICandidateEmail,
    ICandidateEmailPayload,
    ICandidateEmailResponse,
    ICopyToProjectPayload,
    IEditCandidatePayload,
    IGetProjectCandidatesResponse,
    IGetProjectPayload,
    IGetProjectWorkflows,
    ILinkedinUrlUploadPayload,
    IMarketResearchPayload,
    IMoveToPayload,
    IProjectFiltersRequestPayload,
    IProjectStage,
    IRefreshCandidateRatingPayload,
    IRelevantCandPayload,
    ISelectedCandidateId,
    IShowByRelevanceResponse,
    candidateWrokflowsListType,
    displayStepsType,
    getCandidateWorkflowsType,
} from "@/store/reducers/project/project.types";
import { setUser } from "@/store/reducers/signin/Signin.reducer";
import { changeModalState } from "@/store/reducers/workflow/workflow.reducer";
import { WORKFLOW_MODES } from "@/store/reducers/workflow/workflow.types";

/*************  ✨ Codeium Command ⭐  *************/
/**
 * Gets candidate details for a given candidateId.
 *
 * @param {Object} payload - payload object containing candidateId
 * @property {string} candidateId - candidateId for which candidate details are to be fetched
 * @returns {SagaIterator} - iterator returned by the saga
 */
/******  2fdb938b-4039-4e72-b03d-518e1b124638  *******/ function* getCandidateDetailsSaga({
    payload,
}: {
    payload: any;
}): SagaIterator {
    try {
        yield put(startAction({ action: getCandidateDetails.type }));
        const response = yield call(new API().post, "/calls/getCandidateDetails", payload);
        if (!response) return;
        yield put(setCandidateDetails(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getCandidateDetails.type }));
    }
}
function* getBlacklistSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getBlacklist.type }));
        const response = yield call(new API().get, "/calls/getBlacklist");
        if (response) {
            yield put(setBlacklist(response.data));
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getBlacklist.type }));
    }
}
function* getAddToBlocklistSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getAddtoBlocklist.type }));
        const response = yield call(new API().post, "/calls/addToBlacklist", payload);

        if (response) {
            yield put(getBlacklist({}));
            yield put(setSuccessNotification(response.message || "Successfully added to blocklist"));
        } else {
            yield put(setErrorNotification("Failed to add to blocklist"));
        }

        // Assuming you have an action to update the state after adding to the blocklist
        // yield put(updateBlocklist(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
        yield put(setErrorNotification("An error occurred while adding to blocklist"));
    } finally {
        yield put(stopAction({ action: getAddtoBlocklist.type }));
    }
}

function* getRemoveFromBlocklistSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getRemoveFromBlocklist.type }));
        const response = yield call(new API().post, "/calls/removeFromBlacklist", payload);

        if (response) {
            yield put(getBlacklist({}));
            yield put(setSuccessNotification(response.message || "Successfully removed from blocklist"));
        } else {
            yield put(setErrorNotification("Failed to remove from blocklist"));
        }

        // Assuming you have an action to update the state after removing from the blocklist
        // yield put(updateBlocklist(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
        yield put(setErrorNotification("An error occurred while removing from blocklist"));
    } finally {
        yield put(stopAction({ action: getRemoveFromBlocklist.type }));
    }
}
function* getTwilloCallTokenSaga(action: PayloadAction<{ onSuccess: Function }>): SagaIterator {
    try {
        yield put(startAction({ action: getTwilloCall.type }));
        const response = yield call(new API().get, "/calls/voiceToken");
        if (!response?.token) return;

        yield put(setTwillioToken(response.token));
        action?.payload?.onSuccess?.(response.token);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getTwilloCall.type }));
    }
}
function* getListOfPowerDialerCampaignsSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getListOfPowerDialerCampaigns.type }));
        const response = yield call(new API().get, "/bulk-call-logs/get", {
            params: payload || {},
        });
        if (!response) return;

        yield put(setTwilloPowerCallListCreate(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getListOfPowerDialerCampaigns.type }));
    }
}
function* getTwilloPowerCallCandidateListSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getTwilloPowerCallCandidateList.type }));
        const response = yield call(new API().get, "/bulk-call-logs/getBulkCallCandidates", {
            params: payload,
        });
        if (!response?.data) return;
        console.log("response", response);
        yield put(setTwilloPowerCallCandidateList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getTwilloPowerCallCandidateList.type }));
    }
}
function* getTwilloPowerCallListCreateSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getTwilloPowerCallListCreate.type }));
        const response = yield call(new API().post, "/bulk-call-logs/create", {
            ...payload?.payload,
        });
        if (!response?.success) return;
        if (response?.success) {
            payload?.navigate(`/call-logs/power-dialer/${response?.campaign?._id}`);
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getTwilloPowerCallListCreate.type }));
    }
}

function* getTwilloCallLogsSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getTwilloCallLogs.type }));

        // Build query parameters based on the filters
        const params = new URLSearchParams();
        params.append("page", payload.page);
        params.append("limit", payload.limit);

        const filter = payload.filter || {};
        if (filter.from) params.append("from", filter.from);
        if (filter.to) params.append("to", filter.to);
        if (filter.callType) params.append("callType", filter.callType);
        if (filter.fromDate) params.append("fromDate", filter.fromDate);
        if (filter.toDate) params.append("toDate", filter.toDate);
        if (filter.candidateId) params.append("candidateId", filter.candidateId);

        // Make the API call with the dynamic query parameters
        const response = yield call(new API().get, `/calls/getCallLogs?${params.toString()}`);
        if (!response) return;
        yield put(setTwilloCallLogs(response));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getTwilloCallLogs.type }));
    }
}
function* getTwilloCallsOutgoingSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getTwilloCallsOutgoing.type }));
        const response = yield call(new API().post, "/calls/outgoingCall", payload);
        if (!response) return;
        console.log("response", response);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getTwilloCallsOutgoing.type }));
    }
}
function* getDeletePowerDialerCandidatesSaga({
    payload,
}: {
    payload: { campaignId: string; candidateIdsToRemove: string[] };
}): SagaIterator {
    try {
        yield put(startAction({ action: getDeletePowerDialerCandidates.type }));

        const response = yield call(new API().post, "/bulk-call-logs/removeCandidates", payload);
        if (!response) return;

        yield put(setSuccessNotification("Candidate removed successfully from the Power Dialer campaign."));
    } catch (err: unknown) {
        console.error(err);
        yield put(setErrorNotification("Failed to remove candidate from the Power Dialer campaign."));
        handleException(err);
    } finally {
        yield put(stopAction({ action: getDeletePowerDialerCandidates.type }));
    }
}
function* getSendosoGiftsSaga({ payload }: { payload?: GetSendosoGiftsAction }): SagaIterator {
    try {
        yield put(startAction({ action: getSendosoGifts.type }));

        //payload has 5 optional parameters - after, price_gte , price_lte, ship_to_country_codes , text_search call these in query params if it is passed
        const response = yield call(new API().get, "/sendoso/getAllProducts", {
            params: payload || {},
        });
        if (!response) return;

        if (isEmpty(response?.data?.products) && payload?.after) {
            yield put(setAllSendosoProductsLoaded(true));
            return;
        }

        //if payload has after then include the response data with the existing data else just set the response

        if (payload?.after) {
            const existingData = yield select((state) => state.project.sendosoGifts);

            if (existingData) {
                const updatedData = {
                    ...existingData,
                    products: [...existingData.products, ...response.data.products],
                    pagination: response.data.pagination,
                };

                yield put(setSendosoGifts(updatedData));
                yield put(setAllSendosoProductsLoaded(false));
            }
        } else {
            yield put(setSendosoGifts(response.data));
            yield put(setAllSendosoProductsLoaded(false));
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getSendosoGifts.type }));
    }
}

function* getLinkedinApplicantCandidatesSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const state = yield select();
        const { onSuccess, projectId } = payload;
        yield put(startAction({ action: getLinkedinApplicantCandidates.type }));
        const liCookie = get(state, "project.liCookie");
        const response = yield call(new API().post, "/v2/project/scrape-easy-apply-applicants", {
            ...payload,
            // cookie: liCookie,
        });
        if (!response) return;
        console.log("calling onsuccess");
        onSuccess();
        yield put(
            getProjectCandidates({
                projectId: String(projectId),
                start: 0,
                limit: 100,
                action: getProjectCandidates.type,
            })
        );
        yield put(setCandidate(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getLinkedinApplicantCandidates.type }));
    }
}

// /sendoso/sendProducts - post

function* sendSendosoGiftsSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getSendosoGiftsSend.type }));

        const response = yield call(new API().post, "/sendoso/sendProduct", payload?.payload);
        if (!response) return;
        if (isEmpty(response?.data)) {
            yield put(setErrorNotification(response.message));
            payload?.onClose();
            payload?.closeSendosoModal();
            return;
        }
        yield put(setSuccessNotification(response.message));
        yield put(setSentGiftsCandidates(payload?.payload?.candidateId));
        payload?.onClose();
        payload?.closeSendosoModal();

        // yield put(setErrorNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getSendosoGiftsSend.type }));
    }
}

function* copyToProjectSaga({ payload }: { payload: ICopyToProjectPayload }): SagaIterator {
    try {
        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");

        const triggerPayload = { ...payload };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response = yield call(new API().post, "/v2/project/add-candidate", {
            ...triggerPayload,
            cvSource: "DASHBOARD",
        });

        if (!response) return;
        yield put(setSelectAllCandidates(false));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* moveToSaga({ payload }: { payload: IMoveToPayload }): SagaIterator {
    try {
        const { type, ...rest } = payload;
        const response = yield call(new API().put, "/candidate/project-stage", rest);

        if (!response) return;

        const state = yield select();
        if (payload.stage) {
            if (type && type === "get-user-email") {
                const userEmailStateValue = get(state, "allProjects.userEmail");
                const tmpUserEmail = userEmailStateValue.map((item: IMessage) => {
                    if (payload.candidateIds.includes(item.candidate._id)) {
                        return {
                            ...item,
                            reviewStage: payload.stage,
                        };
                    }
                    return item;
                });
                yield put(setUserEmail(tmpUserEmail));
            } else {
                const candidateStateValue = get(state, "project.candidates");
                const tmpCandidates = candidateStateValue.map((item: any) => {
                    if (payload.candidateIds.includes(item._id)) {
                        const updatedItem = {
                            ...item,
                            reviewStage: payload.stage,
                            shortlist1: payload.shortlist1,
                            csReviewStage: payload?.csReviewStage ?? item?.csReviewStage,
                        };

                        return updatedItem;
                    }

                    return item;
                });
                yield put(setCandidates(tmpCandidates));
                yield put(
                    getProjectStats({
                        projectId: payload.projectId,
                        action: getProjectStats.type,
                    })
                );
            }
        } else {
            const candidateStateValue = get(state, "project.candidates");
            const tmpCandidates = candidateStateValue.map((item: any) => {
                if (payload.candidateIds.includes(item._id)) {
                    return {
                        ...item,
                        outreachStatus: payload.outreachStatus,
                        csReviewStage: payload?.csReviewStage ?? undefined,
                    };
                }
                return item;
            });
            yield put(setCandidates(tmpCandidates));
            yield put(
                getProjectStats({
                    projectId: payload.projectId,
                    action: getProjectStats.type,
                })
            );
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* addToWorkflowSaga({ payload }: { payload: IAddToWorkflowPayload }): SagaIterator {
    try {
        const { onSuccess, action, ...rest } = payload;
        yield put(startAction({ action }));

        const state = yield select();
        const workflowMode: WORKFLOW_MODES = get(state, "workflow.workflowMode");
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const characterCount = get(state, "personalizedWorkflow.characterCount");
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");
        // const customTemplates = get(state, "customTemplates");
        // const reminderMsg = customTemplates?.reminderMsg.body;

        const triggerPayload: IAddToWorkflow = {
            ...rest,
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidate = [];
        }

        const response = yield call(new API().post, "/workflow/trigger", {
            ...triggerPayload,
            hyperPersonalized: workflowMode === "PERSONALIZED_WORKFLOW" ? true : false,
            toneOfVoice: workflowMode === "PERSONALIZED_WORKFLOW" ? toneOfVoice : "",
            wordCount: workflowMode === "PERSONALIZED_WORKFLOW" ? characterCount : "",
            outreachIntent: "",
            // ...(reminderMsg && { reminderMsg }),
        });

        if (!response) return;

        if (onSuccess)
            onSuccess({
                workflowId: response.data.workflowModel._id,
                workflowCredits: response.data.candidateCreditCount,
            });

        const installExtension = get(state, "nudges.installExtension");
        const addToWorkflow = get(state, "nudges.addToWorkflow");
        const showEmail = get(state, "nudges.showEmail");

        const tmpNudge = {
            installExtension,
            addToWorkflow,
            showEmail,
        };
        tmpNudge.addToWorkflow = true;
        yield put(setNudges(tmpNudge));

        yield put(setSelectAllCandidates(false));
        yield put(changeModalState("DEFAULT"));
        handleCleverTap("OutReach Started Successfully", {
            triggerPayload,
        });
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCandidateEmailSaga({ payload }: { payload: IActionPayload & ICandidateEmailPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        const projectStateValue = get(state, "allProjects.project");
        const isSse =
            projectStateValue?.projectStatus === "IN_PROGRESS" || projectStateValue?.projectStatus === "PENDING";

        const response: ICandidateEmailResponse = yield call(new API().post, "/candidate/getEmail", {
            isSse,
            candidateIds: payload.candidateIds,
        });
        if (response?.code === 403) {
            throw new Error(response?.message);
        }
        if (response?.data?.warning) {
            yield put(setErrorNotification(response.message));
        }
        if (response?.firstEmailNA === "Mid") {
            yield put(
                setUser({
                    ...userStateValue,
                    firstEmailNA: response.firstEmailNA,
                })
            );
        }

        const installExtension = get(state, "nudges.installExtension");
        const addToWorkflow = get(state, "nudges.addToWorkflow");
        const showEmail = get(state, "nudges.showEmail");

        const tmpNudge = {
            installExtension,
            addToWorkflow,
            showEmail,
        };
        tmpNudge.showEmail = true;
        yield put(setNudges(tmpNudge));

        yield put(fetchUserPlan({ action: fetchUserPlan.type }));

        const personalEmails = response.data.showEmail.filter((item) => !isEmpty(item.personal_email));
        handleCleverTap("Show Email", {
            requestedEmails: payload.candidateIds.length,
            foundEmails: personalEmails?.length || 0,
        });

        yield put(setCandidateEmails({ ...response.data, type: "NORMAL" }));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getProjectStatsSaga({ payload }: { payload: { projectId: number } & IActionPayload }): SagaIterator {
    const cancelTokenSource = axios?.CancelToken?.source();
    try {
        yield put(setStatsFetchErrorStatus(false));
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, `/v2/project/stats/${payload.projectId}`, {
            cancelToken: cancelTokenSource?.token,
        });

        if (isEmpty(response?.result)) return;

        yield put(setProjectStats(response.result));
    } catch (err: unknown) {
        console.error("**getProjectStatsSaga", { err });
        yield put(setStatsFetchErrorStatus(true));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
        if (yield cancelled()) {
            cancelTokenSource?.cancel();
        }
    }
}

function* getShowByRelevanceSaga({ payload }: { payload: IRelevantCandPayload & IActionPayload }): SagaIterator {
    try {
        handleCleverTap("Show by relevance");

        yield put(startAction({ action: payload.action }));

        const response: IShowByRelevanceResponse = yield call(new API().get, `/relevancy/${payload.projectId}`);
        if (!response) return;

        yield put(setCandidates(response.candidateData));
        const candidateIds = response.candidateData.map((item) => item._id);
        yield put(
            setSelectedCandidates({
                candidateId: [...candidateIds],
                pageNo: 1,
            })
        );
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getProjectWorkflowsSaga({ payload }: { payload: IGetProjectWorkflows & IActionPayload }): SagaIterator {
    try {
        yield put(setAssociatedReachoutsFetchErrorStatus(false));
        yield put(startAction({ action: payload.action }));

        // const response = yield call(new API().get, `/workflow/get/${payload.projectId}`);
        const response = yield call(new API().get, `/workflow/get/${payload.projectId}`, {
            params: {
                start: payload.paginated.start,
                limit: payload.paginated.limit,
            },
        });

        if (!response?.data) return;

        yield put(setProjectWorkflows(response.data));
        yield put(setWorkflowCount(response.totalCount));
    } catch (err: unknown) {
        console.error("**getProjectsWorkflowSaga", err);
        yield put(setAssociatedReachoutsFetchErrorStatus(true));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getWorkflowsLogsByIdSaga({
    payload,
}: {
    payload: {
        workflowLogId: string;
        workflowId: string;
        workflowLogIds: string;
        type: string;
        callBack?: Function;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: getWorkflowsLogsById.type }));
        const response = yield call(new API().post, "/workflow/workflowStatsById", payload);
        if (isEmpty(response?.data)) {
            yield put(setErrorNotification("Data not found"));
            return;
        }
        exportToCsvc(response.data, payload?.type);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getWorkflowsLogsById.type }));
        payload?.callBack && payload?.callBack();
    }
}

function* getShowByProjectFiltersSaga({ payload }: { payload: IGetProjectWorkflows & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const pageSize = get(state, "project.pageSize");
        const projectFiltersStateValue = state.project.projectFilters;
        const showByMaxExperienceStateValue = state.project.showByMaxExperience;
        const showByMinExperienceStateValue = state.project.showByMinExperience;
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const showByRelevanceStateValue = state.project.showByRelevance;
        const showByMostRecent = state.project.showByMostRecent;
        const searchQuery = payload?.searchQuery || state.project.searchQuery;

        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);

        const sortBy = showByMaxExperienceStateValue
            ? "maxExp"
            : showByMinExperienceStateValue
              ? "minExp"
              : showByRelevanceStateValue
                ? "relevance"
                : showByMostRecent
                  ? "recent"
                  : "";

        const filterByPayload = [...appliedFilters];

        const requestPayload: IProjectFiltersRequestPayload = {
            filterBy: filterByPayload,
            sortBy,
        };

        if (searchQuery) {
            requestPayload.search = searchQuery;
        }
        const url = `/v2/project/get/${payload.projectId}/candidates?start=0&limit=${pageSize}`;
        const response: IGetProjectCandidatesResponse = yield call(new API().post, url, requestPayload);

        if (isEmpty(response?.data)) return;

        yield put(setCandidates(response.data.candidates));
        yield put(setTotalCandidates(response.data.totalCandidates));
        yield put(setSelectAllCandidates(false));
    } catch (err: any) {
        console.log("**getShowByProjectFiltersSaga", { err });
        yield put(setCandidatesFetchErrorStatus(true));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* updateBulkCallCampaignSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: updateBulkCallCampaign.type }));
        const response = yield call(new API().put, `/bulk-call-logs/update`, payload);
        if (!response) return;
        yield put(setUpdateBulkCallCampaignStatus(response.data));
        yield put(setSuccessNotification("Bulk call campaign updated successfully"));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
        yield put(setErrorNotification("Failed to update bulk call campaign"));
    } finally {
        yield put(stopAction({ action: updateBulkCallCampaign.type }));
    }
}

function* getProjectCandidatesSaga({ payload }: { payload: IActionPayload & IGetProjectPayload }): SagaIterator {
    const cancelTokenSource = axios?.CancelToken?.source();
    try {
        yield put(setCandidatesFetchErrorStatus(false));
        yield put(startAction({ action: payload.action }));

        const start = payload.start ? payload.start - 1 : 0;
        const limit = payload.limit ?? 100;
        const { sendRequestId } = payload;

        const state = yield select();
        const showByMaxExperienceStateValue = get(state, "project.showByMaxExperience");
        const showByMinExperienceStateValue = get(state, "project.showByMinExperience");
        const showByPersonalEmailsStateValue = get(state, "project.showByPersonalEmails");
        const showByRelevanceStateValue = get(state, "project.showByRelevance");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const requestId = get(state, "project.requestId");
        const searchQuery = state.project.searchQuery;

        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];

        const sortBy = showByMaxExperienceStateValue
            ? "maxExp"
            : showByMinExperienceStateValue
              ? "minExp"
              : showByRelevanceStateValue
                ? "relevance"
                : "";

        const requestPayload: IProjectFiltersRequestPayload = {
            filterBy: filterByPayload,
            sortBy,
        };

        if (searchQuery) {
            requestPayload.search = searchQuery;
        }

        if (requestId && sendRequestId && !filterByPayload?.length) {
            requestPayload.requestId = requestId;
        }

        const response: IGetProjectCandidatesResponse = yield call(
            new API().post,
            `/v2/project/get/${payload.projectId}/candidates?start=${start}&limit=${limit}`,
            requestPayload,
            {
                cancelToken: cancelTokenSource?.token,
            }
        );
        if (isEmpty(response?.data) && payload?.sse) {
            return;
        }
        if (response?.data?.jobScraperMsgForUser) {
            yield put(setErrorNotification(response?.data?.jobScraperMsgForUser));
        }

        if (isEmpty(response?.data)) {
            throw new Error();
        }

        if (response?.data?.enrichmentProgress?.email?.length || response?.data?.enrichmentProgress?.phone?.length) {
            if (response?.data?.enrichmentProgress?.email?.length) {
                const now = dayjs();
                const twoHoursAgo = now.subtract(2, "hour");
                const isEmailWithinTwoHours = response.data.enrichmentProgress.email.some((emailTime) => {
                    const emailEnrichTime = dayjs(emailTime);
                    return emailEnrichTime.isAfter(twoHoursAgo);
                });
                yield put(setEmailEnrich({ emailEnrich: isEmailWithinTwoHours }));
            }
            if (response?.data?.enrichmentProgress?.phone?.length) {
                const now = dayjs();
                const twoHoursAgo = now.subtract(2, "hour");
                const isPhoneWithinTwoHours = response.data.enrichmentProgress.phone.some((phoneTime) => {
                    const phoneEnrichTime = dayjs(phoneTime);
                    return phoneEnrichTime.isAfter(twoHoursAgo);
                });
                yield put(setPhoneEnrich({ phoneEnrich: isPhoneWithinTwoHours }));
            }
        } else {
            yield put(setPhoneEnrich({ phoneEnrich: false }));
            yield put(setEmailEnrich({ emailEnrich: false }));
        }

        yield put(setProjectStatus(response.data.projectStatus));
        yield put(setProjectInsightGenerationStatus(response.data.generateInsightsStatus));
        yield put(setProjectInsightGenerationProgress(response.data.insightProgress));
        yield put(setCurrentProjectStatus({ projectId: payload.projectId, status: response.data.projectStatus }));
        if (response.data.projectStatus === "COMPLETED" || response.data.projectStatus === "CANCELLED") {
            yield put(getProjectStats({ projectId: payload.projectId, action: getProjectStats.type }));
        }

        if (response.data?.linkedinEnrichmentProgress === "IN_PROGRESS") {
            yield put(
                setLinkedinEnrich({
                    linkedInEnrich: true,
                })
            );
        } else if (
            response.data?.linkedinEnrichmentProgress === "COMPLETED" ||
            response.data?.linkedinEnrichmentProgress === "PENDING"
        ) {
            yield put(
                setLinkedinEnrich({
                    linkedInEnrich: false,
                })
            );
        }

        yield put(setTotalSearchCount(response.data.totalSearchCount));
        yield put(setSalesNavProfilesCount(response.data?.salesNavProfilesCount || 0));
        yield put(setRequestId(response.data.requestId as any));
        yield put(setCandidates(response.data.candidates));
        yield put(setTotalCandidates(response.data.totalCandidates));
    } catch (err: unknown) {
        yield put(setCandidatesFetchErrorStatus(true));
        yield put(setProjectStatus("ERRORED"));
        yield put(setCurrentProjectStatus({ projectId: payload.projectId, status: "ERRORED" }));
        console.error("candidates fetch error", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
        if (yield cancelled()) {
            cancelTokenSource?.cancel();
        }
    }
}

function* getWorkflowEmailStatsSaga({
    payload,
}: {
    payload: IActionPayload & { workflowId: string; projectId: number };
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        let apiUrl;
        if (payload.workflowId !== "all") {
            apiUrl = `/workflow/${payload.workflowId}/stats/emails`;
        } else apiUrl = `/workflow/${payload.projectId}/${payload.workflowId}/stats/emails`;

        const response = yield call(new API().get, apiUrl);
        yield put(setWorkflowEmailStats(response.data));
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getWorkflowLinkedInStatsSaga({
    payload,
}: {
    payload: IActionPayload & { workflowId: string; projectId: number };
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        let apiUrl;
        if (payload.workflowId !== "all") {
            apiUrl = `/workflow/${payload.workflowId}/stats/linkedIn`;
        } else apiUrl = `/workflow/${payload.projectId}/${payload.workflowId}/stats/linkedIn`;

        const response = yield call(new API().get, apiUrl);
        yield put(setWorkflowLinkedInStats(response.data));
    } catch (err) {
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* bulkUploadCandidatesCVsSaga({
    payload,
}: {
    payload: IBulkUploadCandCVsPayload & IActionPayload;
}): SagaIterator {
    try {
        const { projectId, file, cvSource, onSuccess, action } = payload;

        yield put(startAction({ action }));

        const formData = new FormData();
        formData.append("projectId", projectId);
        formData.append("cvSource", JSON.stringify(cvSource));

        for (let i = 0; i < file?.length; i++) {
            formData.append(`files`, file[i]);
        }

        const response = yield call(new API().post, "/v2/project/upload-zip", formData);
        if (isEmpty(response?.data)) return;

        onSuccess();
        yield put(
            getProjectCandidates({
                projectId: String(projectId),
                start: 0,
                limit: 100,
                action: getProjectCandidates.type,
            })
        );
        yield put(setProject(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* linkedinUrlUploadSaga({ payload }: { payload: ILinkedinUrlUploadPayload & IActionPayload }): SagaIterator {
    try {
        const { projectId, url, cvSource, onSuccess, action } = payload;
        yield put(startAction({ action }));
        const sendPayload = {
            projectId,
            urls: url,
            cvSource,
        };
        const response = yield call(new API().post, "/v2/project/upload-linkedin-url", sendPayload);
        if (isEmpty(response?.data)) return;
        onSuccess();
        // yield put(
        //     getProjectCandidates({
        //         projectId: String(projectId),
        //         start: 0,
        //         limit: 100,
        //         action: getProjectCandidates.type,
        //     })
        // );
        yield put(setProject(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* refreshCandidateRatingSaga({
    payload,
}: {
    payload: IRefreshCandidateRatingPayload & IActionPayload;
}): SagaIterator {
    try {
        const { projectId, candidateIds, selectAll, onSuccess, action, allCandidates = false } = payload;
        if (candidateIds.length === 1) {
            yield put(setSingleCandidateInsightGenerating({ candidateId: candidateIds[0] }));
        }
        const state = yield select();
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const isEasySource = get(state, "signin.isEasySource");
        const searchQuery = get(state, "project.searchQuery");
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];
        const pageNo = get(state, "project.candidateCurrentPage", 0);
        const pageSize = get(state, "project.pageSize", 25);

        const data: {
            selectAll?: boolean;
            candidateIds: string[];
            filterBy: string[];
            allCandidates?: boolean;
            search?: string;
        } = {
            candidateIds,
            filterBy: allCandidates ? [] : filterByPayload,
        };

        if (searchQuery) {
            data.search = searchQuery;
        }

        if (selectAll) {
            data.selectAll = true;
            data.candidateIds = [];
        }

        yield put(startAction({ action }));

        const url = isEasySource
            ? `/v2/project/${projectId}/refresh-rating`
            : `/lead-score/${projectId}/calculateScore`;

        const response = yield call(new API().post, url, data);
        if (!response?.async) {
            throw new Error();
        }

        setTimeout(() => {
            store.dispatch(
                getProjectCandidates({
                    projectId,
                    start: pageNo,
                    limit: pageSize,
                    action: payload.action,
                })
            );
        }, 500);

        if (onSuccess) onSuccess();
        if (response.async) {
            if (response.data) {
                yield put(setProject(response.data));
            }
            // startSse((sseProjectId) => {
            //     console.log("sseProjectId", sseProjectId , projectId);
            //     if (sseProjectId == projectId) {
            //         store.dispatch(
            //             getProjectCandidates({
            //                 projectId,
            //                 start: pageNo,
            //                 limit: pageSize,
            //                 action: payload.action,
            //                 sse: true,
            //             })
            //         );
            //     }
            // });
        } else {
            yield put(setProjectCandidate(response.data));
        }

        // un-select all candidates
        yield put(setSelectAllCandidates(false));

        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error(err);
        handleException(err);
        // yield put(
        //     setErrorNotification(
        //         "Oops! Something went wrong while we were trying to generate insights. Please try again."
        //     )
        // );
    } finally {
        payload.onSuccess?.();
        yield put(stopAction({ action: payload.action }));
    }
}

function* changeCandidatePrimaryEmailSaga({
    payload,
}: {
    payload: { candidateId: string; email: string[]; primaryEmail: string };
}): SagaIterator {
    try {
        if (!payload?.primaryEmail) return;
        const newEmail = [...payload?.email];
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");
        yield put(startAction({ action: "changeCandidatePrimaryEmail" }));
        const index = newEmail.indexOf(payload?.primaryEmail);
        newEmail.splice(index, 1);
        newEmail.unshift(payload?.primaryEmail);
        const response = yield call(new API().put, `/candidate/edit/${payload?.candidateId}`, {
            emailObj: {
                primary: payload?.primaryEmail,
                personal: newEmail,
            },
            projectId: String(currentProject),
        });
        if (isEmpty(response?.data)) return;

        yield put(
            getProjectCandidates({
                projectId: String(currentProject),
                start: 0,
                limit: 100,
                action: getProjectCandidates.type,
            })
        );
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: "changeCandidatePrimaryEmail" }));
    }
}

function* scrapeCandidatesSaga(): SagaIterator {
    try {
        const state = yield select();
        const projectId = get(state, "allProjects.project._id");
        const selectedCandidates = get(state, "project.selectedCandidates");
        const searchQuery = get(state, "project.searchQuery");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates");
        const liCookie = get(state, "project.liCookie");

        if (isEmpty(selectedCandidates)) return;

        yield put(startAction({ action: scrapeCandidates.type }));

        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);

        const projectFiltersStateValue = get(state, "project.projectFilters");
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];

        const response = yield call(new API().put, `/v2/project/scrapeCandidates`, {
            candidateIds,
            liCookie,
            projectId,
            filterBy: filterByPayload,
            selectAll: isAllCandidatesSelected ? true : false,
            search: searchQuery,
        });

        if (isEmpty(response?.data)) return;

        yield put(setProject(response.data));
        yield put(
            getProjectCandidates({
                projectId: String(projectId),
                start: 0,
                limit: 100,
                action: getProjectCandidates.type,
            })
        );
        startSse();
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: scrapeCandidates.type }));
        yield put(setScrapeCandidateModal(false));
    }
}

function* marketResearchSaga(action: PayloadAction<IMarketResearchPayload>): SagaIterator {
    try {
        const state = yield select();
        const { titles, onSuccess, projectId } = action.payload;
        const selectedCandidates = get(state, "project.selectedCandidates");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates");
        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);

        yield put(startAction({ action: marketResearch.type }));
        yield call(new API().post, `/market-research/send`, {
            candidateIds,
            projectId,
            selectAll: isAllCandidatesSelected ? true : false,
            titles,
        });
        onSuccess();
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: marketResearch.type }));
    }
}

function* marketResearchFetchDataSaga(action: PayloadAction<IMarketResearchPayload>): SagaIterator {
    try {
        const { projectId } = action.payload;
        yield put(startAction({ action: marketResearchFetchData.type }));
        const response = yield call(new API().post, `/market-research/populate`, {
            projectId: +projectId,
        });
        yield put(setMarketResearchData(response?.data?.candidates));
        yield put(setMarketResearchDataFetchStatus(response?.data?.status));
        yield put(setMarketResearchDataFetchPercentage(response?.data?.percentage));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: marketResearchFetchData.type }));
    }
}

function* marketResearchStopSaga(action: PayloadAction<any>): SagaIterator {
    try {
        const { projectId, onSuccess } = action.payload;
        yield put(startAction({ action: marketResearchStop.type }));
        yield call(new API().post, `/market-research/stop`, {
            projectId: +projectId,
        });
        onSuccess();
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: marketResearchStop.type }));
    }
}

function* addCandidateFeedbackSaga(action: PayloadAction<IAddCandidateFeedbackPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { candidateId, feedback, onSuccess } = action.payload;
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");
        const currentCandidates = get(state, "project.candidates");
        const response = yield call(new API().post, `v2/project/candidate-feedback/`, {
            candidateId: candidateId,
            feedback: feedback,
            projectId: Number(currentProject),
        });
        if (isEmpty(response?.message)) return;
        const updatedCandidates = Array.isArray(currentCandidates)
            ? currentCandidates.map((c: any) => {
                  if (c._id === candidateId) {
                      return {
                          ...c,
                          feedback: feedback,
                      };
                  }
                  return c;
              })
            : [];
        yield put(setCandidates(updatedCandidates));
        onSuccess();
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* editCandidateSaga(action: PayloadAction<IEditCandidatePayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const { name, linkedIn, resume, candidateId, onSuccess, emails, phone } = action.payload;
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");

        const formData = new FormData();
        formData.append("name", name);
        formData.append("profileUrl", linkedIn);
        formData.append("projectId", String(currentProject));

        if (isEmpty(emails)) {
            formData.append("emails[]", "");
        } else {
            for (const email of emails) {
                formData.append("emails[]", email);
            }
        }

        if (isEmpty(phone)) {
            formData.append("phone[]", "");
        } else {
            for (const phoneNumber of phone) {
                formData.append("phone[]", phoneNumber);
            }
        }

        if (resume) {
            formData.append("resume", resume);
        }

        const response = yield call(new API().put, `/candidate/edit/${candidateId}`, formData);
        if (isEmpty(response?.data)) return;

        onSuccess();

        yield put(setCandidate(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getCandidateWorkflowsSaga({ payload }: { payload: getCandidateWorkflowsType }): SagaIterator {
    try {
        yield put(startAction({ action: getCandidateWorkflows.type }));
        const response = yield call(new API().post, `/workflow/candidateStats`, payload);

        if (isEmpty(response?.data)) return;
        const candidateWorkflows = response?.data.map((item: candidateWrokflowsListType) => {
            const { displaySteps, steps, emailFrom, triggerBy, triggerAt } = item || {};
            const workflow: any = {
                email: {},
                followupEmail: {},
                linkedin: {},
                sms: {},
                triggerBy: emailFrom || triggerBy,
                triggerAt,
            };

            if (displaySteps?.length) {
                displaySteps.map((item: displayStepsType, i: number) => {
                    if (item?.eventName === "Email") {
                        if (!isEmpty(workflow.email)) {
                            workflow.followupEmail = { index: i };
                        } else {
                            workflow.email = { index: i };
                        }
                    } else if (item?.eventName === "connection-request") {
                        workflow.linkedin = {
                            index: i,
                        };
                    } else if (item?.eventName === "sms") {
                        workflow.sms = {
                            index: i,
                        };
                    }
                });
            }

            const followupEmailData = steps[workflow?.followupEmail?.index];
            const linkedinData = steps[workflow?.linkedin?.index];
            const emailData = steps[workflow?.email?.index];
            const smsData = steps[workflow?.sms?.index];

            if (get(emailData, "data")) {
                workflow.email = {
                    index: workflow?.email.index,
                    ...emailData.data[0],
                };
            }
            if (get(followupEmailData, "data")) {
                workflow.followupEmail = {
                    index: workflow?.followupEmail.index,
                    ...followupEmailData.data[0],
                };
            }
            if (get(linkedinData, "data")) {
                workflow.linkedin = {
                    index: workflow?.linkedin.index,
                    ...linkedinData.data[0],
                };
            }
            if (get(smsData, "data")) {
                workflow.sms = {
                    index: workflow?.sms.index,
                    ...smsData.data[0],
                };
            }

            return workflow;
        });

        yield put(setCandidateWorkflows(candidateWorkflows));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getCandidateWorkflows.type }));
    }
}

function* changeStarRatingSaga({
    payload,
}: {
    payload: {
        value: number;
        label: string;
        description?: {
            text: string;
            intent: number;
            criterion: string;
        }[];
        candidateId: string;
        enableFixedVetting: boolean;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: changeStarRating.type }));
        yield put(startAction({ action: refreshCandidateRating.type }));
        const { value, label, candidateId, description, enableFixedVetting } = payload;
        const state = yield select();
        const projectId = get(state, "allProjects.project._id");
        const pageNo = get(state, "project.candidateCurrentPage", 0);
        const pageSize = get(state, "project.pageSize", 25);

        const updatedStarRating = {
            value: Number(value),
            label,
            description,
        };

        const response = yield call(new API().put, `/v2/project/editStarRating`, {
            candidateId,
            starRating: updatedStarRating,
            projectId,
            vettingFixed: enableFixedVetting,
        });
        if (isEmpty(response?.data)) return;

        yield put(
            setCandidateStarRating({
                candidateId,
                data: updatedStarRating,
            })
        );
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: changeStarRating.type }));
        // yield put(stopAction({ action: refreshCandidateRating.type }));
    }
}

function* getSuperAdminCandEmailsSaga(action: PayloadAction<ExtractEmails>): SagaIterator {
    const { type } = action;
    try {
        const { projectId, emailProviderConfig } = action.payload;
        yield put(startAction({ action: type }));

        const state = yield select();
        const selectedCandidates = get(state, "project.selectedCandidates");
        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const searchQuery = get(state, "project.searchQuery");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const pageNo = get(state, "project.candidateCurrentPage", 0);
        const pageSize = get(state, "project.pageSize", 25);
        const appliedFilters = Object.keys(projectFiltersStateValue).filter(
            (key) => projectFiltersStateValue[key as IProjectStage]
        );
        const filterByPayload = [...appliedFilters];

        const response: {
            data: ICandidateEmail[];
            message: string;
        } = yield call(new API().post, "/candidate/superAdmin/getEmails", {
            candidateIds: selectAllCandidatesStateValue ? [] : candidateIds,
            projectId: Number(projectId),
            emailProviderConfig,
            ...(selectAllCandidatesStateValue && filterByPayload && { filterBy: filterByPayload }),
            ...(selectAllCandidatesStateValue && searchQuery && { search: searchQuery }),
            ...(selectAllCandidatesStateValue && { selectAll: true }),
        });

        if ("error" in response) {
            throw new Error();
        }

        yield put(
            setCandidateEmails({
                showEmail: response.data,
                hiddenEmail: [],
                warning: false,
                type: "SUPERADMIN",
            })
        );
        yield put(setSuccessNotification(response.message));

        yield put(
            getProjectCandidates({
                projectId,
                start: pageNo,
                limit: pageSize,
                action: type,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
        yield put(setSelectAllCandidates(false));
    }
}

function* getSuperAdminCandPhoneNumbersSaga(action: PayloadAction<ExtractPhone>): SagaIterator {
    const { type } = action;
    try {
        yield put(startAction({ action: type }));

        const { emailProviderConfig } = action.payload;
        const state = yield select();
        const selectedCandidates = get(state, "project.selectedCandidates");
        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);
        const projectId = get(state, "project.projectStats.projectId");
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const searchQuery = get(state, "project.searchQuery");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const pageNo = get(state, "project.candidateCurrentPage", 0);
        const pageSize = get(state, "project.pageSize", 25);
        const appliedFilters = Object.keys(projectFiltersStateValue).filter(
            (key) => projectFiltersStateValue[key as IProjectStage]
        );
        const filterByPayload = [...appliedFilters];

        const response: {
            data: ICandidate[];
            message: string;
        } = yield call(new API().post, "/candidate/superAdmin/getPhoneNumbers", {
            candidateIds: selectAllCandidatesStateValue ? [] : candidateIds,
            projectId,
            emailProviderConfig,
            ...(selectAllCandidatesStateValue && filterByPayload && { filterBy: filterByPayload }),
            ...(selectAllCandidatesStateValue && searchQuery && { search: searchQuery }),
            ...(selectAllCandidatesStateValue && { selectAll: true }),
        });

        if ("error" in response) {
            throw new Error();
        }

        yield put(setCandidatePhoneNumbers(response.data));
        yield put(setSuccessNotification(response.message));

        yield put(
            getProjectCandidates({
                projectId,
                start: pageNo,
                limit: pageSize,
                action: type,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
        yield put(setSelectAllCandidates(false));
    }
}

function* unlockCandidatesResumeSaga(action: PayloadAction): SagaIterator {
    const { type } = action;
    try {
        yield put(startAction({ action: type }));

        const state = yield select();

        const selectedCandidates: ISelectedCandidateId[] = get(state, "project.selectedCandidates");
        const selectedCandidateIds = selectedCandidates.map((c) => c.candidateId);

        const candidates: ICandidate[] = get(state, "project.candidates");

        const resumeLibraryCandidateIds = candidates
            .filter((item) => selectedCandidateIds.includes(item._id) && item.resumeLibraryId)
            .map((item) => item._id);

        const projectId = get(state, "project.projectStats.projectId");

        const response: {
            data: ICandidate[];
            message: string;
        } = yield call(new API().post, "/searches/unlock-resumeLibrary-candidates", {
            candidateIds: resumeLibraryCandidateIds,
            projectId,
        });

        if ("error" in response) {
            throw new Error();
        }

        const allEmpty = response?.data?.every(isEmpty);

        if (allEmpty) {
            yield put(setErrorNotification("Sorry, profiles cannot be fetched at the moment"));
            return;
        }

        yield put(setCandidateResumeInfo(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* reExtractCandidateCVSaga(action: PayloadAction): SagaIterator {
    const { type } = action;
    try {
        yield put(startAction({ action: type }));
        const state = yield select();

        const projectId = get(state, "allProjects.project._id");
        const selectedCandidates = get(state, "project.selectedCandidates");
        const searchQuery = get(state, "project.searchQuery");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates");

        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);

        const projectFiltersStateValue = get(state, "project.projectFilters");
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];

        const payload = {
            candidateIds,
            projectId: Number(projectId),
            filterBy: filterByPayload,
            selectAll: !!isAllCandidatesSelected,
            search: searchQuery,
        };

        const response: {
            data: ICandidate[];
            message: string;
        } = yield call(new API().post, "/v2/project/re-extract-candidate-cvs", payload);

        if ("error" in response) {
            throw new Error();
        }
        yield put(setSuccessNotification(response.message));

        yield put(
            getProjectCandidates({
                projectId: String(projectId),
                start: 0,
                limit: 100,
                action: getProjectCandidates.type,
            })
        );
        yield put(setProject(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
        yield put(setScrapeCandidateModal(false));
    }
}

function* findLinkedinProfilesSaga(action: PayloadAction): SagaIterator {
    const { type } = action;
    try {
        yield put(startAction({ action: type }));
        const state = yield select();

        const projectId = get(state, "allProjects.project._id");
        const selectedCandidates = get(state, "project.selectedCandidates");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates");
        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);
        const pageSize = get(state, "project.pageSize", 25);
        const pageNo = get(state, "project.candidateCurrentPage", 0);

        const searchQuery = get(state, "project.searchQuery");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const appliedFilters = Object.keys(projectFiltersStateValue).filter(
            (key) => projectFiltersStateValue[key as IProjectStage]
        );
        const filterByPayload = [...appliedFilters];

        const payload = {
            candidateIds: isAllCandidatesSelected ? [] : candidateIds,
            projectId: Number(projectId),
            ...(isAllCandidatesSelected && filterByPayload && { filterBy: filterByPayload }),
            ...(isAllCandidatesSelected && searchQuery && { search: searchQuery }),
            ...(isAllCandidatesSelected && { selectAll: true }),
        };

        const response: {
            data: ICandidate[];
            linkedinEnrichmentProgress: string;
            message: string;
        } = yield call(new API().post, "/candidate/candidate-linkedin", payload);

        if ("error" in response) {
            throw new Error();
        }

        yield put(setSuccessNotification(response.message));

        yield put(
            getProjectCandidates({
                projectId,
                start: pageNo,
                limit: pageSize,
                action: getProjectCandidates.type,
            })
        );

        yield put(setSelectAllCandidates(false));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
        yield put(setScrapeCandidateModal(false));
    }
}

function* excludeCandidatesSaga(action: PayloadAction): SagaIterator {
    // exclusionList/exclude-candidates
    const { type } = action;
    try {
        yield put(startAction({ action: type }));

        const state = yield select();
        const selectedCandidates = get(state, "project.selectedCandidates");
        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);
        // NOT HANDLED ON THE BACKEND
        // const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        // const searchQuery = get(state, "project.searchQuery");
        // const projectFiltersStateValue = get(state, "project.projectFilters");
        // const appliedFilters = Object.keys(projectFiltersStateValue).filter(
        //     (key) => projectFiltersStateValue[key as IProjectStage]
        // );
        // const filterByPayload = [...appliedFilters];

        yield call(new API().post, "/exclusionList/exclude-candidates", {
            candidateIds: candidateIds,
        });

        yield put(setSuccessNotification("Selected candidates were added to exclusion list"));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* updateProjectCandidatesCSReviewStageSaga(action: PayloadAction<string>): SagaIterator {
    try {
        const { type } = action;
        yield put(startAction({ action: type }));
        const state = yield select();

        const projectId = get(state, "allProjects.project._id");
        const selectedCandidates = get(state, "project.selectedCandidates");
        const searchQuery = get(state, "project.searchQuery");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates");

        const candidateIds = selectedCandidates.map(({ candidateId }: { candidateId: string }) => candidateId);

        const projectFiltersStateValue = get(state, "project.projectFilters");
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];
        const csReviewStage = action.payload;

        const payload = {
            candidateIds,
            projectId: Number(projectId),
            filterBy: filterByPayload,
            selectAll: !!isAllCandidatesSelected,
            search: searchQuery,
            csReviewStage,
        };

        const response = yield call(new API().post, "/v2/project/update-cs-review-stage", payload);

        if ("error" in response) {
            throw new Error();
        }

        yield put(setSuccessNotification(response.message));

        yield put(
            getProjectCandidates({
                projectId: String(projectId),
                start: 0,
                limit: 100,
                action: getProjectCandidates.type,
            })
        );
        yield put(setProject(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(copyToProject.type, copyToProjectSaga),
        // @ts-ignore
        yield takeLatest(getSendosoGifts.type, getSendosoGiftsSaga),
        // @ts-ignore
        yield takeLatest(getSendosoGiftsSend.type, sendSendosoGiftsSaga),
        // @ts-ignore
        yield takeLatest(moveTo.type, moveToSaga),
        // @ts-ignore
        yield takeLatest(addToWorkflow.type, addToWorkflowSaga),
        // @ts-ignore
        yield takeLatest(getProjectStats.type, getProjectStatsSaga),
        // @ts-ignore
        yield takeEvery(getCandidateEmail.type, getCandidateEmailSaga),
        // @ts-ignore
        yield takeLatest(getProjectWorkflows.type, getProjectWorkflowsSaga),
        // @ts-ignore
        yield takeLatest(getShowByRecent.type, getShowByProjectFiltersSaga),
        // @ts-ignore
        yield takeLatest(getShowByRelevance.type, getShowByProjectFiltersSaga),
        // @ts-ignore
        yield takeLatest(searchCandidates.type, getShowByProjectFiltersSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByProjectFilters.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByMaxExperience.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByMinExperience.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByPersonalEmails.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(getProjectCandidates.type, getProjectCandidatesSaga),
        // @ts-ignore
        yield takeLatest(getWorkflowEmailStats.type, getWorkflowEmailStatsSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getWorkflowLinkedInStats.type,
            getWorkflowLinkedInStatsSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            bulkUploadCandidatesCVs.type,
            bulkUploadCandidatesCVsSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            linkedinUrlUpload.type,
            linkedinUrlUploadSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            refreshCandidateRating.type,
            refreshCandidateRatingSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            changeCandidatePrimaryEmail.type,
            changeCandidatePrimaryEmailSaga
        ),
        // @ts-ignore
        yield takeLatest(scrapeCandidates.type, scrapeCandidatesSaga),
        // @ts-ignore
        yield takeLatest(marketResearch.type, marketResearchSaga),
        // @ts-ignore
        yield takeLatest(marketResearchFetchData.type, marketResearchFetchDataSaga),
        // @ts-ignore
        yield takeLatest(marketResearchStop.type, marketResearchStopSaga),
        // @ts-ignore
        yield takeLatest(editCandidate.type, editCandidateSaga),
        // @ts-ignore
        yield takeLatest(getWorkflowsLogsById.type, getWorkflowsLogsByIdSaga),
        // @ts-ignore
        yield takeLatest(getCandidateWorkflows.type, getCandidateWorkflowsSaga),
        // @ts-ignore
        yield takeLatest(changeStarRating.type, changeStarRatingSaga),
        // @ts-ignore
        yield takeLatest(getSuperAdminCandEmails.type, getSuperAdminCandEmailsSaga),
        // @ts-ignore
        yield takeLatest(getSuperAdminCandPhoneNumbers.type, getSuperAdminCandPhoneNumbersSaga),
        // @ts-ignore
        yield takeLatest(unlockCandidatesResume.type, unlockCandidatesResumeSaga),
        // @ts-ignore
        yield takeLatest(reExtractCandidateCV.type, reExtractCandidateCVSaga),
        // @ts-ignore
        yield takeLatest(findLinkedinProfiles.type, findLinkedinProfilesSaga),
        // @ts-ignore
        yield takeLatest(addCandidateFeedback.type, addCandidateFeedbackSaga),
        // @ts-ignore
        yield takeLatest(excludeCandidates.type, excludeCandidatesSaga),
        // @ts-ignore
        yield takeLatest(getTwilloCall.type, getTwilloCallTokenSaga),
        // @ts-ignore
        yield takeLatest(getTwilloCallsOutgoing.type, getTwilloCallsOutgoingSaga),

        // @ts-ignore
        yield takeLatest(getTwilloCallLogs.type, getTwilloCallLogsSaga),

        //@ts-ignore
        yield takeLatest(getAddtoBlocklist.type, getAddToBlocklistSaga),

        // @ts-ignore
        yield takeLatest(getRemoveFromBlocklist.type, getRemoveFromBlocklistSaga),

        //@ts-ignore
        yield takeLatest(getBlacklist.type, getBlacklistSaga),

        // @ts-ignore
        yield takeLatest(getCandidateDetails.type, getCandidateDetailsSaga),

        // @ts-ignore
        yield takeLatest(getTwilloPowerCallListCreate.type, getTwilloPowerCallListCreateSaga),
        // @ts-ignore
        yield takeLatest(getListOfPowerDialerCampaigns.type, getListOfPowerDialerCampaignsSaga),
        // @ts-ignore
        yield takeLatest(getTwilloPowerCallCandidateList.type, getTwilloPowerCallCandidateListSaga),
        // @ts-ignore
        yield takeLatest(updateBulkCallCampaign.type, updateBulkCallCampaignSaga),
        //@ts-ignore
        yield takeLatest(getDeletePowerDialerCandidates.type, getDeletePowerDialerCandidatesSaga),
        // @ts-ignore
        yield takeLatest(getLinkedinApplicantCandidates.type, getLinkedinApplicantCandidatesSaga),
        // @ts-ignore
        yield takeLatest(updateProjectCandidatesCSReviewStage.type, updateProjectCandidatesCSReviewStageSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveProject.type, CancelSagas, tasks);
}
