import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { ReactNode } from "react";

import { tooltipSetting } from "../../utils";

import { cn } from "@/lib/utils";

interface ItemProps {
    children: ReactNode;
    subtitle?: boolean;
    info?: ReactNode;
}

export default function Item(props: ItemProps & React.HTMLAttributes<HTMLDivElement>) {
    const { children, subtitle, info, ...rest } = props;

    const itemClasses = cn("flex flex-row items-center gap-2", subtitle && "text-gray font-medium text-[18px]");
    return (
        <div className={itemClasses} {...rest}>
            {children}
            {info && (
                <Tooltip {...tooltipSetting} title={info}>
                    <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )}
        </div>
    );
}
