import { Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    handleRowClickInAllContacts,
    selectAllContacts,
    selectAllContactsFilters,
    selectAllContactsPage,
    selectAllContactsRowsPerPage,
    selectAllContactsSelected,
    selectAllContactsTableOrder,
    selectAllContactsTableOrderBy,
    selectSearchQueryInAllContacts,
} from "./all-contacts.slice";
import { AllContactsTableItemKeys, Contact } from "./all-contacts.types";
import { getComparator, stableSort } from "./all-contacts.utils";
import AllContactsTableHead from "./AllContactsTableHead";
import { AllContactsToolbar } from "./AllContactsToolbar";

function ContactListName({ labelId, id, name }: { labelId: string; id: string; name?: string }) {
    return (
        <TableCell component="th" id={labelId} scope="row" padding="none" align="left" sx={{ width: "30%" }}>
            <Stack direction="row" spacing={3} alignItems="center">
                <Link to={`/contact-reachout/${id}`} style={{ color: "#0891b2" }}>
                    <Typography variant="body2">{name || "-"}</Typography>
                </Link>

                {/* {isHover && (
                    <ContactListMenu
                        removeHover={() => setHover(false)}
                        id={id}
                        handleLinkClick={handleLinkClick}
                    />
                )} */}
            </Stack>
            {/* <CSVLink
                headers={headers}
                data={csvData}
                filename={`${name}.csv`}
                style={{ display: "none" }}
                ref={exportRef}
                target="_blank"
            /> */}
        </TableCell>
    );
}

function CustomTableCell(value?: string) {
    if (!value) {
        return null;
    }

    return <TableCell align="left">{value || "-"}</TableCell>;
}

function extractRowsBasedOnFilters(rows: Contact[], filters: (keyof Contact)[]) {
    const filteredRows = rows.map((row) => {
        const rowWithFilteredFields: Contact = {
            _id: row._id,
        };

        filters.forEach((key) => {
            const value = row[key];

            if (value === undefined) {
                rowWithFilteredFields[key] = "-";
            } else {
                rowWithFilteredFields[key] = value;
            }
        });

        return rowWithFilteredFields;
    });

    return filteredRows;
}

function TableContent() {
    const dispatch = useDispatch();
    const rows = useSelector(selectAllContacts);
    const filters = Object.keys(useSelector(selectAllContactsFilters)) as AllContactsTableItemKeys[];
    const page = useSelector(selectAllContactsPage);
    const order = useSelector(selectAllContactsTableOrder);
    const orderBy = useSelector(selectAllContactsTableOrderBy);
    const rowsPerPage = useSelector(selectAllContactsRowsPerPage);
    const selected = useSelector(selectAllContactsSelected);
    const searchQuery = useSelector(selectSearchQueryInAllContacts);

    const isRowSelected = (id: string): boolean => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(() => {
        const extractedRows = extractRowsBasedOnFilters(rows, filters);
        return stableSort(extractedRows, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [order, orderBy, page, rowsPerPage, searchQuery, rows, filters]);

    return (
        <>
            {visibleRows.map(({ _id, "First Name": firstName, ...rest }, index) => {
                if (!_id) {
                    return null;
                }
                const isItemSelected = isRowSelected(_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <Fragment key={_id}>
                        <TableRow hover tabIndex={-1} selected={isItemSelected} sx={{ cursor: "pointer" }}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onChange={(_) => dispatch(handleRowClickInAllContacts(_id))}
                                />
                            </TableCell>
                            <ContactListName labelId={labelId} id={_id} name={firstName} />
                            {Object.entries(rest).map(([, value]) => {
                                return (
                                    <TableCell align="left" sx={{ minWidth: "200px" }}>
                                        {value || "-"}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </Fragment>
                );
            })}
        </>
    );
}

export default function AllContactsContent() {
    return (
        <Stack
            sx={{
                maxWidth: "77vw",
            }}
        >
            <Paper sx={{ width: "100%", mb: 2 }}>
                <AllContactsToolbar />
                <TableContainer sx={{ maxHeight: "83vh" }}>
                    <Table sx={{ minWidth: 750 }} stickyHeader>
                        <AllContactsTableHead />
                        <TableBody>
                            <TableContent />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Stack>
    );
}
