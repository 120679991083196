import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectActiveCandidateInfoModalIndex } from "@/store/reducers/candidates/Candidates.reducer";
import { selectProjectCandidates } from "@/store/reducers/project/project.reducer";
import { ICandidate, IEditCandidatePayload, StarRating } from "@/store/reducers/project/project.types";

type CandidateModalContextProps = {
    localCandidates: ICandidate[];
    currentCandidate: ICandidate;
    currentCandidateIndex: number;
    starRating: StarRating;
    setStarRating: (starRating: StarRating) => void;
    updateCandidate: (candidate: IEditCandidatePayload) => void;
    handleStarRatingChange: (starRating: StarRating) => void;
};

const defaultCandidateModalContextValue = {
    localCandidates: [],
    currentCandidate: {} as ICandidate,
    currentCandidateIndex: 0,
    starRating: {} as StarRating,
    setStarRating: () => {},
    updateCandidate: () => {},
    handleStarRatingChange: () => {},
};

const CandidateModalContext = createContext<CandidateModalContextProps>(defaultCandidateModalContextValue);

export const useCandidateModalContext = () => useContext(CandidateModalContext);

interface CandidateModalProviderProps {
    children: ReactNode;
    projectId: string | number;
}

export default function CandidateModalContextProvider({ children, projectId: _ }: CandidateModalProviderProps) {
    const currentCandidateIndex = useSelector(selectActiveCandidateInfoModalIndex);

    // const localCandidates = candidates;
    /* This local state is required because when polling is enabled, 
    the candidates array is updated every 5 seconds since the currentCandidateIndex is not updated,
    the candidate objec is switched to the last selected candidate index and candidate info is changed.
    */
    // const candidates: ICandidate[] = useSelector(selectCurrentProjectCandidates(projectId));
    const candidates = useSelector(selectProjectCandidates);
    const [localCandidates, setLocalCandidates] = useState(candidates);
    const currentCandidate = localCandidates[currentCandidateIndex];
    const originalCurrentCandidate = candidates[currentCandidateIndex];

    const [starRating, setStarRating] = useState(currentCandidate?.starRating ?? ({} as StarRating));

    const updateCandidate = (candidate: IEditCandidatePayload) => {
        const updatedCandidates = Array.from(localCandidates);
        const { emails, linkedIn, name, phone } = candidate;

        updatedCandidates[currentCandidateIndex] = {
            ...currentCandidate,
            email: emails,
            profileUrl: linkedIn,
            name: name,
            phone: phone,
        };

        setLocalCandidates(updatedCandidates);
    };

    useEffect(() => {
        setStarRating(currentCandidate?.starRating ?? ({} as StarRating));
    }, [currentCandidate]);

    useEffect(() => {
        if (originalCurrentCandidate?._id === currentCandidate?._id) {
            const updatedCandidates = Array.from(localCandidates);
            updatedCandidates[currentCandidateIndex] = originalCurrentCandidate;
            setLocalCandidates(updatedCandidates);
        }
    }, [originalCurrentCandidate]);

    const handleStarRatingChange = (starRating: StarRating) => {
        setStarRating(starRating);

        const updatedCandidates = Array.from(localCandidates);
        updatedCandidates[currentCandidateIndex] = {
            ...currentCandidate,
            starRating,
        };

        setLocalCandidates(updatedCandidates);
    };

    const value: CandidateModalContextProps = useMemo(
        () => ({
            localCandidates,
            currentCandidate,
            currentCandidateIndex,
            starRating,
            setStarRating,
            updateCandidate,
            handleStarRatingChange,
        }),
        [localCandidates, currentCandidate, currentCandidateIndex, starRating]
    );

    return <CandidateModalContext.Provider value={value}>{children}</CandidateModalContext.Provider>;
}
