import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";

import { CustomTextField } from "./Styles";

import { authorizeLinkedin } from "@/store/reducers/signin/Signin.reducer";
import { IAuthorizeLinkedinPayload } from "@/store/reducers/signin/Signin.types";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import CustomButton from "../CustomButton";

interface LinkedInCredentialsModalProps {
    onSubmit: (creds: IAuthorizeLinkedinPayload) => void;
}

export function LinkedInCredentialsModal(props: LinkedInCredentialsModalProps) {
    const { onSubmit } = props;
    const isLoading = useSelector(checkIfLoading(authorizeLinkedin.type));
    const [passwordVisble, setPasswordVisible] = useState(true);

    return (
        <Stack
            gap={1}
            component="form"
            onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                onSubmit({
                    email: formData.get("email") as string,
                    password: formData.get("password") as string,
                });
            }}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
        >
            <CustomTextField
                name="email"
                required
                fullWidth
                placeholder="Enter email"
                autoComplete="new-password"
                type="email"
            />
            <CustomTextField
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={() => setPasswordVisible(!passwordVisble)}>
                            {passwordVisble ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    ),
                }}
                name="password"
                required
                type={passwordVisble ? "text" : "password"}
                fullWidth
                placeholder="Enter password"
                autoComplete="off"
                inputProps={{ minLength: "6" }}
                onBlur={(e) => e.target.setAttribute("type", "password")}
                onChange={(e) => {
                    if (e?.target?.value?.length === 1) {
                        setPasswordVisible(false);
                    }
                }}
            />
            <Typography variant="caption">
                By clicking Agree &amp; Join, you agree to the LinkedIn{" "}
                <a
                    href="https://www.linkedin.com/legal/user-agreement?trk=registration-frontend_join-form-user-agreement"
                    target="_blank"
                    rel="noreferrer"
                >
                    User Agreement
                </a>
                ,{" "}
                <a
                    href="https://www.linkedin.com/legal/privacy-policy?trk=registration-frontend_join-form-privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Privacy Policy
                </a>
                , and{" "}
                <a
                    href="https://www.linkedin.com/legal/cookie-policy?trk=registration-frontend_join-form-cookie-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Cookie Policy
                </a>
                .
            </Typography>
            <CustomButton
                disabled={isLoading}
                variant="contained"
                type="submit"
                sx={{
                    alignSelf: "center",
                    marginTop: "1rem",
                }}
            >
                {isLoading ? "Submitting..." : "Submit"}
            </CustomButton>
        </Stack>
    );
}
