import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { cn } from "@/lib/utils";

type ColorVariant = "primary" | "secondary" | "success" | "error" | "warning" | "info" | "cyan";

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
    color?: ColorVariant;
}

const colorVariants: Record<ColorVariant, { bg: string; thumb: string }> = {
    primary: { bg: "bg-[#79BDCE]", thumb: "bg-cyan-600" },
    secondary: { bg: "bg-purple-200", thumb: "bg-purple-600" },
    success: { bg: "bg-green-200", thumb: "bg-green-600" },
    error: { bg: "bg-red-200", thumb: "bg-red-600" },
    warning: { bg: "bg-yellow-200", thumb: "bg-yellow-600" },
    info: { bg: "bg-sky-200", thumb: "bg-sky-600" },
    cyan: { bg: "bg-[#79BDCE]", thumb: "bg-cyan-600" },
};

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
    ({ className, color = "primary", ...props }, ref) => (
        <SwitchPrimitives.Root
            className={cn(
                "peer inline-flex h-3 w-10 shrink-0 m-2 cursor-pointer items-center rounded-full transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
                `data-[state=checked]: ${colorVariants[color].bg}`,
                "data-[state=unchecked]:bg-gray-300",
                className
            )}
            {...props}
            ref={ref}
        >
            <SwitchPrimitives.Thumb
                className={cn(
                    "pointer-events-none block h-5 w-5 rounded-full shadow-sm shadow-gray-400 ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
                    `data-[state=checked]: ${colorVariants[color].thumb}`,
                    "data-[state=unchecked]:bg-white"
                )}
            />
        </SwitchPrimitives.Root>
    )
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
