import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import CandidateList from "./CandidateList";
import BDSidebarForm from "./SidebarForm";

import useBeforeUnloadConfirmation from "../../../hooks/useBeforeUnloadConfirmation";
import ImageUrl from "../../../molecules/ImageUrl/ImageUrl";
import { CandidatesList } from "../../../pages/BlendSearchCandidates/CandidatesList";
import { SidebarForm } from "../../../pages/BlendSearchCandidates/SidebarForm";
import { RootState } from "../../../store";
import { resetSearchState } from "../../../store/reducers/search/search.slice";
import {
    SearchType,
    resetSearchCandidatesState,
    selectSearchType,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ICompanySuggestion } from "../../../store/reducers/searchCandidates/searchCandidates.types";

import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";

export function CompanySuggestion(props: ICompanySuggestion) {
    const { label, imageUrl, handleAdd } = props;

    return (
        <Button
            onClick={handleAdd}
            sx={(theme) => ({
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                padding: "0.2rem 0.75rem",
                fontSize: 12,
                fontWeight: 400,
                color: theme.palette.grey[500],
                "& .MuiButton-startIcon": {
                    marginRight: 0.5,
                },
            })}
            startIcon={
                <Box
                    borderRadius={"4px"}
                    overflow="hidden"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin={"2px"}
                >
                    <ImageUrl src={imageUrl} height={24} width={24} />
                </Box>
            }
            endIcon={<AddRoundedIcon sx={{ width: 16, height: 16 }} />}
        >
            {label}
        </Button>
    );
}

function useShowUnloadConfirmation() {
    const searchState = useSelector((state: RootState) => state.search);
    const isShowConfirmation = Object.values(searchState).some(({ values }) => values?.length);
    return isShowConfirmation;
}

export function BDBlendSearchCandidates() {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const searchType = useSelector(selectSearchType);

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    const showUnloadConfirmation = useShowUnloadConfirmation();

    useBeforeUnloadConfirmation(showUnloadConfirmation);

    useEffect(() => {
        if (projectId) {
            dispatch(
                getProject({
                    projectId,
                    action: getProject.type,
                    doNotFetchCandidates: true,
                    setBlendSearchFilters: true,
                })
            );
        }

        return () => {
            dispatch(resetSearchState());
            dispatch(resetSearchCandidatesState());
        };
    }, []);

    const getComponentBySearchType = (searchType: SearchType) => {
        switch (searchType) {
            case "salesnavCompany":
                return (
                    <>
                        <SidebarForm disabled={false} />
                        <Box>
                            <CandidatesList />
                        </Box>
                    </>
                );

            default:
                return (
                    <>
                        <BDSidebarForm />
                        <Box style={{ height: "100%" }}>
                            <CandidateList />
                        </Box>
                    </>
                );
        }
    };

    const commonStyles = {
        background: "#f3f6f9",
        maxHeight: "100%",
        maxWidth: "100%",
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "30vw 2.5fr",
        gridTemplateRows: "1fr",
        padding: "1rem 0",
        gap: "1rem",
    };

    return <Box sx={commonStyles}>{getComponentBySearchType(searchType)}</Box>;
}
