import { Button, Dialog, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Candidates.module.css";


import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";


import { getAllProjectsList, selectAllProjects } from "@/store/reducers/allProjects/allProjects.reducer";
import { addToProject, createProject, selectCanShowAddToProject, setCanShowAddToProject } from "@/store/reducers/candidates/Candidates.reducer";
import { optionType } from "@/store/reducers/candidates/Candidates.type";
import { IProject } from "@/store/reducers/project/project.types";

const AddToProject = () => {
    const dispatch = useDispatch();
    const [canShowCreateProject, setCanShowCreateProject] = useState(false);
    const canShowAddToProject = useSelector(selectCanShowAddToProject);
    const projectList: IProject[] = useSelector(selectAllProjects);
    const [options, setOptions] = useState<optionType[]>([]);
    const isAddToProjectLoading = useSelector(checkIfLoading(addToProject.type));
    const isProjectsLoading = useSelector(checkIfLoading(getAllProjectsList.type));
    const [selectedProject, setSelectedProject] = useState<optionType>({
        value: "",
        label: "",
    });

    useEffect(() => {
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    useEffect(() => {
        const projects: optionType[] = projectList.map((item) => {
            return {
                value: item?._id.toString(),
                label: item?.name,
            };
        });

        setOptions(projects);
    }, [projectList]);

    const onSelectProject = (id: string) => {
        const selectedProject = options.find((item) => item.value === id);

        setSelectedProject(
            selectedProject
                ? selectedProject
                : {
                    value: "",
                    label: "",
                }
        );
    };

    const onAddToProject = () => {
        if (!selectedProject.value) return;
        const projectId = parseInt(selectedProject.value);
        dispatch(addToProject(projectId));
    };

    return (
        <Dialog
            onClose={() => dispatch(setCanShowAddToProject(false))}
            open={canShowAddToProject}
            classes={{
                paper: styles.addToProjectContainer,
            }}
        >
            {canShowCreateProject ? (
                <CreateProject />
            ) : (
                <>
                    <Typography variant="subtitle1" className={styles.addToProjectTitle}>
                        Add To Project
                    </Typography>

                    {isProjectsLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                sx={{ marginBottom: "20px" }}
                                onClick={() => setCanShowCreateProject(true)}
                            >
                                Create Project
                            </Button>
                            <Select
                                fullWidth
                                value={selectedProject.value}
                                onChange={(e) => onSelectProject(e.target.value)}
                                classes={{
                                    select: styles.addToProjectSelect,
                                }}
                            >
                                {options.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    )}

                    <Stack flexDirection="row" mt="20px" justifyContent="space-around">
                        <Button
                            onClick={() => dispatch(setCanShowAddToProject(false))}
                            className={styles.addToProjectCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={styles.addToProjectAdd}
                            variant="contained"
                            onClick={onAddToProject}
                            disabled={!selectedProject.value || isAddToProjectLoading}
                        >
                            {isAddToProjectLoading ? "Adding..." : "Add"}
                        </Button>
                    </Stack>
                </>
            )}
        </Dialog>
    );
};

const CreateProject = () => {
    const dispatch = useDispatch();
    const [projectName, setProjectName] = useState("");
    const isProjectsCreating = useSelector(checkIfLoading(createProject.type));

    const onCreateProject = () => {
        if (!projectName) return;
        dispatch(createProject(projectName));
    };

    return (
        <Stack>
            <Typography variant="subtitle1" className={styles.addToProjectTitle}>
                Create Project
            </Typography>
            <TextField placeholder="Project name" onBlur={(e) => setProjectName(e.target.value)} />
            <Stack flexDirection="row" mt="20px" justifyContent="space-around">
                <Button onClick={() => dispatch(setCanShowAddToProject(false))} className={styles.addToProjectCancel}>
                    Cancel
                </Button>
                <Button
                    className={styles.addToProjectAdd}
                    variant="contained"
                    onClick={onCreateProject}
                    disabled={!projectName || isProjectsCreating}
                >
                    {isProjectsCreating ? "Creating..." : "Create"}
                </Button>
            </Stack>
        </Stack>
    );
};

export default AddToProject;
