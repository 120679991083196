import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    EditProjectPayload,
    ICreateNotePayload,
    IDeleteProjectPayload,
    IEditNotePayload,
    IGetProjectPayload,
    IRemoveCandidateFromProjectPayload,
    IRootState,
    ISaveAndRunExclusionList,
    IUpdateProjectCreditsPayload,
    SetProjectModePayload,
    StopGenerateInsightsPayload,
    TEmailProviderConfig,
} from "./allProjects.types";

import { IActionPayload } from "../loaders.reducer";

import { Option } from "@/common";
import { RootState } from "@/store";
import { IProject } from "@/store/reducers/project/project.types";

const initialState: IRootState = {
    projectList: [],
    project: null,
    notes: [],
    activities: [],
    candidateEmail: null,
    candidate: null,
    projectsFetchError: false,
    query: "",
    emailProviderConfig: [],
    totalProjects: 0,
    currentPage: 1,
    pageSize: 50,
    customFields: [],
    projectsStatus: {},
    // add error status for all projects
};

const reducers = {
    setRb2bConnectState(state: any, { payload }: { payload: boolean }) {
        if (state.project?.rb2bMetaData) {
            state.project.rb2bMetaData.isProjectConnectedToRB2B = payload;
        }
    },
    setRB2BWebhookUrl(state: any, { payload }: { payload: string }) {
        state.rb2bWebhookUrl = payload;
    },
    setCurrentPage(state: any, { payload }: { payload: number }) {
        state.currentPage = payload;
    },
    setPageSize(state: any, { payload }: { payload: number }) {
        state.pageSize = payload;
    },
    setTotalProjects(state: any, { payload }: { payload: number }) {
        state.totalProjects = payload;
    },
    setProjectStatus(state: any, action: PayloadAction<string>) {
        state.project.projectStatus = action.payload;
    },
    setProjectInsightGenerationStatus(state: any, action: PayloadAction<string>) {
        state.project.generateInsightsStatus = action.payload;
    },
    setProjectInsightGenerationProgress(state: any, action: PayloadAction<{ current: number; total: number }>) {
        state.project.insightProgress = action.payload;
    },
    setCurrentProjectStatus(state: IRootState, action: PayloadAction<{ projectId: string | number; status: string }>) {
        const { projectId, status } = action.payload;
        if (projectId) {
            state.projectsStatus[projectId] = status;
        }
    },

    addToExclusionList(state: any, action: PayloadAction<Option[]>) {
        state.project.exclusionLists.push(...action.payload);
    },
    updateProjectName(state: any, action: PayloadAction<string>) {
        if (state.project?.name) {
            state.project.name = action.payload;
        }
    },
    updateProjectJdTitle(state: any, action: PayloadAction<string>) {
        state.project.jdTitle = action.payload;
    },
    updateProjectJdDescription(state: any, action: PayloadAction<string>) {
        state.project.jdDescription = action.payload;
    },
    updateProjectOnlyPersonalEmail(state: any, action: PayloadAction<boolean>) {
        state.project.onlyPersonalEmail = action.payload;
    },
    getAllProjectsList(
        state: any,
        {
            payload,
        }: {
            payload: IActionPayload & {
                paginated?: {
                    limit: number;
                    start: number;
                };
                userId?: string;
                showAdditionalStats?: boolean;
            };
        }
    ) {},
    setAllProjectsList(state: any, { payload }: { payload: any }) {
        const newProjects = payload.filter((item: any) => {
            if (item._id !== "9999") {
                return item;
            }
        });
        state.projectList = newProjects;
    },
    createNewProject(state: any, { payload }: { payload: any }) {},
    fetchRB2BWebhookUrl(state: any, { payload }: { payload: { projectId: number; onSuccess: () => void } }) {},
    editProject(state: any, { payload }: { payload: EditProjectPayload }) {},
    getProject(state: any, { payload }: { payload: IActionPayload & IGetProjectPayload }) {},
    setProjectsFetchError(state: any, action: PayloadAction<boolean>) {
        state.projectsFetchError = action.payload;
    },
    setProject(state: any, { payload }: { payload: IProject | {} }) {
        state.project = payload;
    },
    deleteProject(state: any, action: IDeleteProjectPayload) {},
    getNotes(state: any, { payload }: { payload: { id: string } & IActionPayload }) {},
    setNotes(state: any, { payload }: { payload: any }) {
        state.notes = payload;
    },
    createNewNote(state: any, { payload }: { payload: ICreateNotePayload & IActionPayload }) {},
    editNote(state: any, { payload }: { payload: IEditNotePayload & IActionPayload }) {},
    deleteNote(state: any, { payload }: { payload: any }) {},
    getActivities(state: any, { payload }: { payload: { id: string } & IActionPayload }) {},
    setActivities(state: any, { payload }: { payload: any }) {
        state.activities = payload;
    },
    getCandidateEmail(state: any) {},
    setCandidateEmail(state: any, { payload }: { payload: any }) {
        state.candidateEmail = payload;
    },
    getCandidate(state: any, { payload }: { payload: any }) {},
    removeCandidateFromProject(state: any, { payload }: { payload: IRemoveCandidateFromProjectPayload }) {},
    leaveAllProjects(state: any) {},
    updateProjectCredits(state: any, { payload }: { payload: IUpdateProjectCreditsPayload & IActionPayload }) {},
    setAutoSearchStatus(state: any, action: SetProjectModePayload) {
        state.project.autoSource = action.payload?.status;
    },
    submitAutoSearchStatus(state: any, action: SetProjectModePayload) {},
    stopGenerateInsights(state: any, action: StopGenerateInsightsPayload) {},
    uploadprojectStatsFile(state: any, { payload }: { payload: File }) {},
    saveAndRunExclusionList(state: any, action: PayloadAction<ISaveAndRunExclusionList>) {},

    setQuery(state: any, action: PayloadAction<string>) {
        state.query = action.payload;
    },
    clearQuery(state: any) {
        state.query = "";
    },

    setEmailProviderConfig(state: any, { payload }: { payload: TEmailProviderConfig | [] }) {
        state.emailProviderConfig = payload;
    },
    updateEmailProviderConfig(state: typeof initialState, { payload }: { payload: { key: string; value: boolean } }) {
        const { key, value } = payload;
        for (let i = 0; i < state.emailProviderConfig.length; ++i) {
            if (state.emailProviderConfig[i].type === key) {
                state.emailProviderConfig[i].status = value;
            }
        }
    },

    setCustomField(
        state: typeof initialState,
        { payload }: { payload: { customFields: { label: string; value: string }[] } }
    ) {
        const { customFields } = payload;
        state.customFields = customFields;
    },
    refreshProjectNetNew(
        state: typeof initialState,
        { payload }: { payload: { customType: string; projectId: number } }
    ) {},
    updateProjectNetNew(state: any, action: PayloadAction<{ projectId: number; newNet: number }>) {
        const { projectId, newNet } = action.payload;
        const newProjectList = [...state.projectList];
        for (let i = 0; i < newProjectList.length; ++i) {
            if (newProjectList[i]._id === projectId) {
                newProjectList[i].netNew = newNet;
            }
        }
        state.projectList = newProjectList;
    },
    setProjectAutoPilotStatus(state: any, action: PayloadAction<{ projectId: number; autoSource: boolean }>) {
        const { projectId, autoSource } = action.payload;
        const updatedValue = state.projectList.map((v) => (v._id === projectId ? { ...v, autoSource } : v));
        state.projectList = updatedValue;
    },
};

export const allProjectPage = createSlice({
    name: "allProjects",
    initialState,
    reducers,
});

export default allProjectPage.reducer;

export const {
    setProjectStatus,
    setProjectInsightGenerationStatus,
    setProjectInsightGenerationProgress,
    setCurrentProjectStatus,
    addToExclusionList,
    setProjectsFetchError,
    getAllProjectsList,
    setAllProjectsList,
    editProject,
    createNewProject,
    getProject,
    setProject,
    deleteProject,
    getNotes,
    setNotes,
    createNewNote,
    editNote,
    deleteNote,
    getActivities,
    setActivities,
    getCandidateEmail,
    setCandidateEmail,
    getCandidate,
    removeCandidateFromProject,
    leaveAllProjects,
    updateProjectCredits,
    stopGenerateInsights,
    updateProjectName,
    setAutoSearchStatus,
    submitAutoSearchStatus,
    updateProjectOnlyPersonalEmail,
    uploadprojectStatsFile,
    saveAndRunExclusionList,
    setEmailProviderConfig,
    setQuery,
    clearQuery,
    updateEmailProviderConfig,
    setTotalProjects,
    setCurrentPage,
    setPageSize,
    setCustomField,
    updateProjectJdTitle,
    updateProjectJdDescription,
    refreshProjectNetNew,
    updateProjectNetNew,
    setRB2BWebhookUrl,
    fetchRB2BWebhookUrl,
    setRb2bConnectState,
    setProjectAutoPilotStatus,
} = allProjectPage.actions;

export const checkProjectsFetchError = (state: RootState) => state.allProjects.projectsFetchError;

export const selectNotes = (state: RootState) => state.allProjects.notes;
export const selectActivities = (state: RootState) => state.allProjects.activities;
export const selectAllProjects = (state: RootState) => state.allProjects.projectList;
export const selectCurrProject = (state: RootState) => state.allProjects.project;
export const selectProjectName = (state: RootState) => state.allProjects.project?.name;
export const selectProjectAutoSearchStatus = (state: RootState) => state.allProjects.project?.autoSource;
export const selectProjectStatus = (state: RootState) => state.allProjects.project?.projectStatus;
export const selectProjectInsightGenerationStatus = (state: RootState) =>
    state.allProjects.project?.generateInsightsStatus;
export const selectProjectInsightGenerationProgress = (state: RootState) => state.allProjects.project?.insightProgress;
export const selectCurrentProjectStatus = (projectId: string | number) => (state: RootState) =>
    state.allProjects?.projectsStatus[projectId];
export const selectEmailProviderConfig = (state: RootState) => state.allProjects.emailProviderConfig;
export const selectQuery = (state: RootState) => state.allProjects.query;
export const selectTotalProjects = (state: RootState) => state.allProjects.totalProjects;
export const selectCurrentPage = (state: RootState) => state.allProjects.currentPage;
export const selectPageSize = (state: RootState) => state.allProjects.pageSize;
export const selectCustomFields = (state: RootState) => state.allProjects.customFields;
export const selectJdTitle = (state: RootState) => state.allProjects.project?.jdTitle;
export const selectJdDescription = (state: RootState) => state.allProjects.project?.jdDescription;
export const selectProjectRB2BMetaData = (state: RootState) => state.allProjects.project?.rb2bMetaData;
export const selectRB2BWebhookUrl = (state: RootState) => state.allProjects.rb2bWebhookUrl;
export const checkProjectConnectedToRB2B = (state: RootState) =>
    state.allProjects.project?.rb2bMetaData?.isProjectConnectedToRB2B;
