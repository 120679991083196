import { zodResolver } from "@hookform/resolvers/zod";
import InfoIcon from "@mui/icons-material/Info";
import {
    Box,
    FormControl,
    FormHelperText,
    FormHelperTextProps,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    TextFieldProps,
    Tooltip,
    styled,
} from "@mui/material";
import grey from "@mui/material/colors/grey";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { useAppDispatch } from "../../../store";
import {
    useFetchOrganizationMembersEmailQuery,
    useScheduleOutreachMutation,
} from "../../../store/apis/outreach/outreach.api";
import {
    BodyWithSubject,
    LinkedinSchema,
    ScheduleOutreachRequestPayload,
    WorkflowStepValue,
} from "../../../store/apis/outreach/outreach.types";
import { SOURCE_TEXT_MAP, SOURCE_TEXT_MAP_EASYGROWTH } from "../../../store/apis/outreach/outreach.utils";
import appendToOutReach from "../../../utils/appendToOutReach";
import { formatDate } from "../../../utils/helper";
import {
    formatTimeFromISOString,
    getDescriptiveTimeZoneName,
    getTimestampBasedOnTimezone,
    senderEmailSchema,
} from "../../../utils/outreach.helpers";
import {
    checkEasySource,
    checkRotatingMailBoxEnabled,
    isSESIntegrated,
    isSendGridEnabled,
    isSuperAdminUser,
    selectEmailSource,
    selectUser,
} from "../../Signin/Signin.reducer";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import UnsubscribeEmailToggle from "../../triggerWorkflow/molecules/UnsubscribeEmailToggle";
import { selectUnsubscribeEmail } from "../../workflow/index.reducer";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { useSaveOutreachTemplate } from "../hooks/useSaveOutreach.hook";
import {
    exclusionListIds,
    scheduleFirstElementToCurrent,
    selectFirstOutreachElement,
    selectOutReachFlow,
} from "../outreach.slice";

const CustomTextField = styled((props: TextFieldProps & { legendWidth?: string | number }) => {
    const { legendWidth = 50, ...rest } = props;
    return (
        <TextField
            variant="outlined"
            sx={
                legendWidth
                    ? {
                          "& .MuiOutlinedInput-notchedOutline legend": {
                              width: props.legendWidth,
                          },
                      }
                    : {}
            }
            {...rest}
        />
    );
})(({ theme }) => {
    const fontSize = theme.typography.body2;
    return {
        "& .MuiFormLabel-root": {
            ...fontSize,
            top: "-5px",
            '&[data-shrink="true"]': {
                top: "0px",
            },
        },
        "& .MuiInputBase-input": {
            padding: 0,
            ...fontSize,
        },
        "& .MuiInputBase-root": {
            padding: "0.7rem",
        },
        "& .MuiInputBase-input::placeholder": {
            ...fontSize,
        },
    };
});

const CustomFormHelperText = styled((props: FormHelperTextProps) => {
    return (
        <FormHelperText
            variant="outlined"
            {...props}
            sx={{
                marginX: 1,
                ...props.sx,
            }}
        />
    );
})(({ theme }) => ({
    fontSize: 13,
    color: theme.palette.error.main,
}));

function ScheduleOutreachDescription() {
    const isSESIntegratedEnabled = useSelector(isSESIntegrated);
    const isSendGridIntegrationEnabled = useSelector(isSendGridEnabled);
    const isEasySource = useSelector(checkEasySource);
    const emailSource = useSelector(selectEmailSource);
    const outreach = useSelector(selectOutReachFlow);
    const typeOfSource = isEasySource ? SOURCE_TEXT_MAP : SOURCE_TEXT_MAP_EASYGROWTH;
    const source = isSendGridIntegrationEnabled
        ? typeOfSource["SendGrid"]
        : isSESIntegratedEnabled
          ? typeOfSource["SES"]
          : emailSource
            ? typeOfSource[emailSource]
            : "";

    const firstElementTime = useSelector(selectFirstOutreachElement);
    const timestamp = firstElementTime.scheduledTime;
    const isEmailPresent = outreach.find((i) => i.eventName === "email");
    const now = dayjs();
    const thirtyMinutesLater = now.add(30, "minute");
    const isWithinHalfHour = dayjs(timestamp).isBefore(thirtyMinutesLater);

    return (
        <>
            {isEmailPresent && (
                <Typography variant="caption" sx={{ pt: 2, color: grey[600] }} fontStyle="italic">
                    {source}
                </Typography>
            )}
            <Typography py={1} variant="caption" sx={{ color: grey[600] }} fontStyle="italic">
                Your outreach campaign is set to begin at{" "}
                <span style={{ fontWeight: 500, color: "black" }}>
                    {formatTimeFromISOString(timestamp)} {getDescriptiveTimeZoneName(timestamp)},{" "}
                    {formatDate(timestamp)}
                </span>
                . {isWithinHalfHour ? "Do you want to start" : "Do you want to start this now instead?"}
            </Typography>
            <Typography variant="caption" sx={{ pt: 2, color: grey[600] }} fontStyle="italic">
                Your current time is {formatTimeFromISOString(now.toISOString())}{" "}
                {getDescriptiveTimeZoneName(now.toISOString())} , {formatDate(now.toISOString())}
            </Typography>
        </>
    );
}

export function Schedule() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { outreachId, projectId } = useOutreachParams();
    const isEasySource = useSelector(checkEasySource);
    const user = useSelector(selectUser);
    const outreach = useSelector(selectOutReachFlow);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isSESIntegratedEnabled = useSelector(isSESIntegrated);
    const isSendGridIntegrationEnabled = useSelector(isSendGridEnabled);
    const isRotatingMailBoxEnabled = useSelector(checkRotatingMailBoxEnabled);
    const emailSource = useSelector(selectEmailSource);

    const [scheduleOutreach, { isLoading }] = useScheduleOutreachMutation();
    const { saveTemplate, saveMutationState } = useSaveOutreachTemplate({
        successCallback: () => {
            handleSubmit(onSubmit)();
        },
    });

    const unsubscribeEmail = useSelector(selectUnsubscribeEmail);

    const isEmailPresent = outreach.find((i) => i.eventName === "email");
    const checkedItems = useSelector(exclusionListIds);
    const { data } = useFetchOrganizationMembersEmailQuery();

    const scheduleFormFieldsSchema = z.object({
        bccEmail: z.string().trim().email({ message: "Please enter a valid email " }).optional().or(z.literal("")),
        senderEmail: !isEmailPresent
            ? z.string().optional()
            : isSendGridIntegrationEnabled || emailSource === "SMTP"
              ? z.string().email({ message: "Please enter a valid email" })
              : senderEmailSchema,
        senderName: z.string().trim().optional(),
        cookie: z.string().trim().optional(),
        isScheduledNow: z.boolean().optional(),
    });

    type ScheduleFormFields = z.infer<typeof scheduleFormFieldsSchema>;

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<ScheduleFormFields>({
        resolver: zodResolver(scheduleFormFieldsSchema),
        defaultValues: {
            bccEmail: "",
            senderEmail: "",
            senderName: "",
            cookie: "",
            isScheduledNow: false,
        },
    });

    const onSubmit: SubmitHandler<ScheduleFormFields> = (data) => {
        const { bccEmail, cookie, senderEmail, senderName, isScheduledNow } = data;
        const email = outreach.find((i) => i?.eventName === "email");
        const followUpBody = outreach.find((i) => i?.eventName === "follow-up")?.eventBody.body || "";
        const smsBody = outreach.find((i) => i?.eventName === "SMS")?.eventBody.body || "";
        const linkedin = outreach.find((i) => i?.eventName === "linkedin")?.eventBody as LinkedinSchema;
        // TODO (so_hell) what is this
        const scheduleOutreachRequestPayload: ScheduleOutreachRequestPayload = {
            workflowId: outreachId,
            workflowSteps: outreach.map((i) => ({
                ...i,
                scheduledTime: getTimestampBasedOnTimezone(i.scheduledTime),
                attachments: i?.attachments || [],
            })),
            outreachIntent: appendToOutReach({
                email: {
                    body: email?.eventBody?.body || "",
                    subject: (email?.eventBody as BodyWithSubject)?.subject || "",
                },
                followUp: followUpBody,
                linkedin: {
                    linkedinBody: linkedin?.body || "",
                    inMailSubject: linkedin?.inMailSubject || "",
                    inMailBody: linkedin?.inMailBody || "",
                    inMailFollowUpSubject: linkedin?.inmailFollowupSubject || "",
                    inMailFollowUpBody: linkedin?.inmailFollowupBody || "",
                },
                sms: smsBody,
                outReachIntent: "",
            }),
            newExclusionListIds: checkedItems,
            unsubscribeEmail,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        if (isScheduledNow) {
            scheduleOutreachRequestPayload.isScheduledNow = true;
        }

        if (bccEmail) {
            scheduleOutreachRequestPayload.bccEmail = bccEmail;
        }

        if (cookie) {
            scheduleOutreachRequestPayload.cookie = cookie;
        }

        if (senderEmail) {
            if (senderEmail === "xyz@gmail.com") {
                scheduleOutreachRequestPayload.rotatingInbox = true;
            } else {
                scheduleOutreachRequestPayload.senderEmail = senderEmail;
            }
        }

        if (senderName) {
            scheduleOutreachRequestPayload.senderName = senderName;
        }
        scheduleOutreach(scheduleOutreachRequestPayload)
            ?.unwrap()
            .then((res) => {
                if (res.success) {
                    navigate(`/projects/${projectId}`);
                }
            })
            .catch((err) => {
                let errorMessage = err?.data?.error || err?.message;

                if (typeof errorMessage !== "string") {
                    errorMessage = "Please check your authorization or contact your account manager.";
                }

                dispatch(setErrorNotification(errorMessage));
            });
    };

    const saveFirstOutreachElement = (firstOutreachElementTemplate: WorkflowStepValue) => {
        saveTemplate(firstOutreachElementTemplate);
    };

    const firstElementTime = dayjs(useSelector(selectFirstOutreachElement).scheduledTime);
    const now = dayjs();
    const thirtyMinutesLater = now.add(30, "minute");
    const isWithinHalfHour = dayjs(firstElementTime).isBefore(thirtyMinutesLater);
    return (
        <BaseModal
            onClose={() => {
                navigate(-1);
            }}
            overlayStyles={{
                padding: "1.5rem",
                width: "450px",
            }}
            backdrop={isLoading}
        >
            <Stack gap={2.5}>
                <Typography variant="h6">Schedule Outreach</Typography>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    }}
                >
                    {isEmailPresent &&
                        (isSESIntegratedEnabled ||
                            isSendGridIntegrationEnabled ||
                            emailSource === "SMTP" ||
                            isRotatingMailBoxEnabled) && (
                            <Controller
                                control={control}
                                name="senderEmail"
                                render={({ field }) => (
                                    <FormControl
                                        sx={(theme) => ({
                                            fontSize: theme.typography.body2,
                                            padding: 0,
                                            ".MuiSelect-select": {
                                                padding: "10px",
                                                fontSize: theme.typography.body2,
                                            },
                                        })}
                                    >
                                        {isSendGridIntegrationEnabled ||
                                        emailSource === "SMTP" ||
                                        isRotatingMailBoxEnabled ? (
                                            <>
                                                <InputLabel
                                                    sx={(theme) => ({
                                                        fontSize: theme.typography.body2,
                                                        top: "-5px",
                                                        '&[data-shrink="true"]': {
                                                            top: "0px",
                                                        },
                                                    })}
                                                >
                                                    {"Select sender email"}
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        sx: {
                                                            ".MuiPaper-elevation": {
                                                                maxHeight: "300px",
                                                                overflowY: "scroll",
                                                            },
                                                            "& .MuiMenuItem-root": {
                                                                fontSize: "14px",
                                                            },
                                                        },
                                                    }}
                                                    label={"Select sender email"}
                                                    {...field}
                                                >
                                                    <MenuItem disabled value="">
                                                        {isSendGridIntegrationEnabled
                                                            ? `List of authorized SendGrid email addresses`
                                                            : `List of authorized custom email addresses`}
                                                    </MenuItem>

                                                    {isRotatingMailBoxEnabled && (
                                                        <MenuItem value="xyz@gmail.com">Use email rotation</MenuItem>
                                                    )}

                                                    {data &&
                                                        data.length &&
                                                        data.map((email) => {
                                                            return (
                                                                <MenuItem value={email} key={email}>
                                                                    {email}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </>
                                        ) : (
                                            <>
                                                <CustomTextField
                                                    variant="outlined"
                                                    label={
                                                        isSendGridIntegrationEnabled
                                                            ? "Select sender email"
                                                            : "Sender Email"
                                                    }
                                                    legendWidth={isSendGridIntegrationEnabled ? 115 : 75}
                                                    {...field}
                                                />
                                            </>
                                        )}

                                        <CustomFormHelperText>{errors?.["senderEmail"]?.message}</CustomFormHelperText>
                                    </FormControl>
                                )}
                            />
                        )}
                    {isEmailPresent && (isSESIntegratedEnabled || isSendGridIntegrationEnabled) && (
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Controller
                                    control={control}
                                    name="senderName"
                                    render={({ field }) => (
                                        <FormControl sx={{ width: "100%" }}>
                                            <CustomTextField
                                                variant="outlined"
                                                label="Enter display name"
                                                legendWidth={120}
                                                {...field}
                                            />
                                            <CustomFormHelperText>
                                                {errors?.["senderName"]?.message}
                                            </CustomFormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                            <Box sx={{ flexShrink: 0 }}>
                                <Tooltip title="This name will be visible to the email recipient. If you leave this blank, the recipient will see sender's email address">
                                    <InfoIcon sx={{ color: grey[500] }} />
                                </Tooltip>
                            </Box>
                        </Stack>
                    )}

                    {isEmailPresent && isEasySource && !user.sendGrid && (
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Controller
                                    control={control}
                                    name="bccEmail"
                                    render={({ field }) => (
                                        <>
                                            <FormControl sx={{ width: "100%" }}>
                                                <CustomTextField
                                                    placeholder="Bcc Email"
                                                    label="Bcc Email"
                                                    legendWidth={57}
                                                    {...field}
                                                />
                                                <CustomFormHelperText>
                                                    {errors?.["bccEmail"]?.message}
                                                </CustomFormHelperText>
                                            </FormControl>
                                        </>
                                    )}
                                />
                            </Box>
                            <Box sx={{ flexShrink: 0 }}>
                                <Tooltip title="The email address will be bcc'ed for all email outreach. Not recommended for high volume of outreach">
                                    <InfoIcon sx={{ color: grey[500] }} />
                                </Tooltip>
                            </Box>
                        </Stack>
                    )}

                    <Stack>
                        {isSuperAdmin && (
                            <Controller
                                control={control}
                                name="cookie"
                                render={({ field }) => (
                                    <FormControl>
                                        <CustomTextField
                                            variant="outlined"
                                            label="LinkedIn Cookie"
                                            legendWidth={90}
                                            {...field}
                                        />
                                        <CustomFormHelperText>{errors?.["cookie"]?.message}</CustomFormHelperText>
                                    </FormControl>
                                )}
                            />
                        )}
                        <ScheduleOutreachDescription />
                    </Stack>

                    <UnsubscribeEmailToggle />

                    <Stack alignItems={"center"} justifyContent={"flex-end"} direction={"row"} gap={1}>
                        <CustomButton
                            onClick={() => {
                                navigate(-1);
                            }}
                            variant="outlined"
                            sx={{
                                width: "fit-content",
                                boxShadow: "none",
                                borderRadius: "0.25rem",
                            }}
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            // onClick={() => {
                            //     if (isWithinHalfHour) {
                            //         setValue("isScheduledNow", true);
                            //     }
                            // }}
                            type="submit"
                            variant={isWithinHalfHour ? "contained" : "outlined"}
                            sx={{
                                width: "fit-content",
                                boxShadow: "none",
                                borderRadius: "0.25rem",
                            }}
                            disabled={isLoading}
                        >
                            <ButtonTextWithLoading
                                progressStyle={{ color: isWithinHalfHour ? "white" : "#0891B2" }}
                                isLoading={isLoading}
                                text={isWithinHalfHour ? "Yes, start" : "No, schedule outreach"}
                            />
                        </CustomButton>
                        {!isWithinHalfHour && (
                            <CustomButton
                                onClick={() => {
                                    setValue("isScheduledNow", true);
                                    dispatch(
                                        scheduleFirstElementToCurrent({
                                            onSuccess: (firstOutreachElementTemplate: WorkflowStepValue) => {
                                                setTimeout(() => {
                                                    saveFirstOutreachElement(firstOutreachElementTemplate);
                                                }, 0);
                                            },
                                        })
                                    );
                                }}
                                variant="contained"
                                sx={{
                                    width: "fit-content",
                                    boxShadow: "none",
                                    borderRadius: "0.25rem",
                                }}
                                disabled={saveMutationState.isLoading}
                            >
                                <ButtonTextWithLoading
                                    progressStyle={{ color: "white" }}
                                    isLoading={saveMutationState.isLoading}
                                    text="Yes, start"
                                />
                            </CustomButton>
                        )}
                    </Stack>
                </form>
            </Stack>
        </BaseModal>
    );
}
