function formatRecord(args: any) {
    if (Array.isArray(args)) {
        if (args.every((item) => typeof item === "string") || args.every((item) => typeof item === "number")) {
            // Sort strings in ascending order
            return [...args].sort();
        } else if (args.every((item) => typeof item === "object" && !Array.isArray(item))) {
            // Sort objects by keys in ascending order and apply the same to their values
            return args
                .map((obj) => formatRecord(obj))
                .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
        }
    } else if (args && typeof args === "object" && !Array.isArray(args)) {
        // Sort object keys in ascending order and apply the same to their values
        return Object.keys(args)
            .sort()
            .reduce((sortedObj, key) => {
                sortedObj[key] = formatRecord(args[key]);
                return sortedObj;
            }, {});
    }
    return args;
}

export { formatRecord };
