import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";

import useMenu from "../hooks/useMenu";
import { useAppDispatch } from "../store";

import { IProjectStatus } from "@/store/reducers/project/project.types";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";

export function ProjectStatus({
    status,
    menuClickHandler,
}: {
    status: IProjectStatus;
    menuClickHandler: (status: IProjectStatus) => void;
}) {
    const dispatch = useAppDispatch();
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();
    const isSuperAdmin = useSelector(isSuperAdminUser);

    const validateAction = (callback: () => void) => {
        return () => {
            if (status === "CLOSED") {
                if (!isSuperAdmin) {
                    dispatch(
                        setErrorNotification(
                            "Kindly contact your POC or customer-support@hirequotient.com to reactivate this project"
                        )
                    );
                    return;
                }
            }
            callback();
        };
    };

    const actions = [
        {
            id: nanoid(),
            label: "Active",
            handleClick: validateAction(() => menuClickHandler("ACTIVE")),
        },
        {
            id: nanoid(),
            label: "Closed",
            handleClick: () => menuClickHandler("CLOSED"),
        },
        {
            id: nanoid(),
            label: "On hold",
            handleClick: validateAction(() => menuClickHandler("ON_HOLD")),
        },
    ];

    const handleMenuItemClick = (callback: () => void) => {
        handleClose();
        callback();
    };

    const getColor = () => {
        switch (status) {
            case "ACTIVE":
                return "success";
            case "ON_HOLD":
                return "info";
            case "CLOSED":
                return "disabled";
            default:
                return "primary";
        }
    };

    return (
        <>
            <Box flexShrink={0}>
                <Button
                    size="small"
                    sx={{
                        fontSize: 10,
                        height: "22px",
                        minWidth: 48,
                        "& .MuiButton-endIcon": {
                            marginLeft: 0.5,
                        },
                    }}
                    variant="outlined"
                    color={getColor()}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                    }}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                >
                    {status === "ON_HOLD" ? "On hold" : capitalize(status)}
                </Button>
            </Box>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                {actions.map(({ id, label, handleClick }) => {
                    return (
                        <MenuItem
                            key={id}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleMenuItemClick(handleClick);
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
