import { useSelector } from "react-redux";

import TextLoading from "../../../components/TextLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { exportContactListCSV } from "@/store/reducers/contact-list/contact-list.slice";

export default function ExportLoading() {
    const isExporting = useSelector(checkIfLoading(exportContactListCSV.type));

    if (!isExporting) {
        return null;
    }

    return <TextLoading text="Exporting..." />;
}
