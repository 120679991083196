import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CircularProgress from "@mui/joy/CircularProgress";
import FormControl from "@mui/joy/FormControl";
import Textarea, { TextareaProps } from "@mui/joy/Textarea";
import Tooltip from "@mui/joy/Tooltip";
import { useRef, useState } from "react";

import { SMS_BODY_LIMIT, SMS_ERROR_MESSAGE } from "../../../../utils/Constants";
import { calculateQuillCharacterCount } from "../../../../utils/helper";
import { useSuperInboxContext } from "../../Context";

import { Button, Typography } from "@/ui";

interface MessageInputProps extends Omit<TextareaProps, "onSubmit"> {
    onSubmit: (body: string, callback: () => void) => void;
    isLoading?: boolean;
}

export default function MessageInput(props: MessageInputProps) {
    const { isMobile } = useSuperInboxContext();
    const { onSubmit, isLoading, ...rest } = props;
    const textAreaRef = useRef<HTMLDivElement>(null);
    const [textAreaValue, setTextAreaValue] = useState("");

    const characterCount = calculateQuillCharacterCount(textAreaValue);
    const isSMSLimitExceeded = characterCount > SMS_BODY_LIMIT;

    const handleClick = () => {
        if (textAreaValue.trim() !== "" && !isSMSLimitExceeded) {
            onSubmit(textAreaValue, () => setTextAreaValue(""));
        }
    };

    return (
        <div className="px-2 pb-3">
            <Tooltip open={isSMSLimitExceeded} variant="soft" color="danger" title={SMS_ERROR_MESSAGE}>
                <FormControl>
                    <Textarea
                        placeholder="Type something here…"
                        aria-label="Message"
                        ref={textAreaRef}
                        onChange={(e) => {
                            setTextAreaValue(e.target.value);
                        }}
                        value={textAreaValue}
                        minRows={3}
                        maxRows={10}
                        endDecorator={
                            <div className="flex flex-row justify-between items-center flex-grow p-1 border-t border-divider">
                                <Typography
                                    className={`text-sm ${isSMSLimitExceeded ? "text-red-500" : "text-gray-900"}`}
                                >
                                    {characterCount} / {SMS_BODY_LIMIT}
                                </Typography>

                                <Button
                                    size="sm"
                                    disabled={isLoading}
                                    style={{
                                        backgroundColor: "#0891b2",
                                        color: "#ffffff",
                                        cursor: isLoading ? "progress" : "auto",
                                        alignSelf: "center",
                                        borderRadius: "sm",
                                    }}
                                    endDecorator={
                                        isLoading ? (
                                            <CircularProgress sx={{ color: "#ffffff" }} size="sm" />
                                        ) : (
                                            <SendRoundedIcon />
                                        )
                                    }
                                    onClick={handleClick}
                                >
                                    {!isMobile && "Send"}
                                </Button>
                            </div>
                        }
                        onKeyDown={(event) => {
                            if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
                                handleClick();
                            }
                        }}
                        sx={{
                            "& textarea:first-of-type": {
                                minHeight: 72,
                            },
                        }}
                        {...rest}
                    />
                </FormControl>
            </Tooltip>
        </div>
    );
}
