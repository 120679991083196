import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addJazzHRJob,
    getJazzHRJobStages,
    getJazzHRJobs,
    pushJazzHRJobs,
    selectJazzHRJobStages,
    selectJazzHRJobs,
} from "../../../integrations/integrations.reducer";
import { IJazzHRJob, IJazzHRJobStage } from "../../../integrations/integrations.types";
import { selectUser } from "../../../Signin/Signin.reducer";
import { IProject } from "../../project.types";

import { Button } from "@/ui";

type JazzModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export default function JazzModal({ open, onClose, id, selectedCandidateIds, variant }: JazzModalProps) {
    const dispatch = useDispatch();
    const isVariantFetch = variant === "FETCH";

    const user: any = useSelector(selectUser);
    useEffect(() => {
        if (!open) return;
        if (user.jazzHR?.jazzHRAPIKey && user.jazzHR?.jazzHRFeedURL) {
            dispatch(getJazzHRJobs());
            dispatch(getJazzHRJobStages());
        }
    }, [open, isVariantFetch]);

    const jazzHRJobs = useSelector(selectJazzHRJobs);
    const isLoading = useSelector(checkIfLoading(addJazzHRJob.type));
    const isStageLoading = useSelector(checkIfLoading(pushJazzHRJobs.type));
    const data = useSelector<any, IProject>((state) => get(state, "allProjects.project"));
    const jazzHRMetaData = data?.jazzHRMetaData;
    const jazzHRStages = useSelector(selectJazzHRJobStages);
    const [jobStage, setJobStage] = useState<IJazzHRJobStage>(jazzHRStages?.[0]);

    const [selectedJazzHRJob, setSelectedJazzHRJob] = useState<IJazzHRJob>(() =>
        jazzHRMetaData && jazzHRMetaData.jobName !== "title"
            ? { id: jazzHRMetaData.jobId, title: jazzHRMetaData.jobName }
            : { id: "", title: "" }
    );

    const handleCloseModal = () => {
        onClose();
    };

    const handleJobChange = (_: any, newValue: any) => {
        if (newValue) {
            setSelectedJazzHRJob({ id: newValue.id, title: newValue.label });
        } else {
            setSelectedJazzHRJob({ id: "", title: "" });
        }
    };

    const handleStageChange = (_: any, newValue: any) => {
        if (newValue) {
            setJobStage({ jobId: newValue.id, jobName: newValue.label });
        }
    };

    const handleClose = () => {
        setSelectedJazzHRJob(
            jazzHRMetaData ? { id: jazzHRMetaData.jobId, title: jazzHRMetaData.jobName } : { id: "", title: "" }
        );
        handleCloseModal();
    };

    const handleJazzAdd = () => {
        if (isVariantFetch) {
            dispatch(
                addJazzHRJob({
                    id: selectedJazzHRJob.id ?? "",
                    title: selectedJazzHRJob.title ?? "",
                    onSuccess: () => handleCloseModal(),
                })
            );
        } else {
            if (!selectedJazzHRJob?.title) {
                dispatch(setErrorNotification("Please select a job"));
                return;
            }

            dispatch(
                pushJazzHRJobs({
                    id: selectedJazzHRJob.id ?? "",
                    stageID: jobStage?.jobId,
                    stageName: jobStage?.jobName,
                    title: selectedJazzHRJob.title ?? "",
                    candidateIds: selectedCandidateIds,
                    onSuccess: () => handleCloseModal(),
                })
            );
        }
    };

    const renderJobOption = (props: any, option: any) => (
        <Box component="li" {...props}>
            <Tooltip title={option.label} placement="left-start">
                <Box sx={{ width: "100%" }}>
                    <Typography variant="body2" fontWeight={500}>
                        {option.label}
                    </Typography>
                    {(option.city || option.state) && (
                        <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                            <LocationOnIcon
                                sx={{
                                    fontSize: 16,
                                    mr: 0.5,
                                    color: "rgba(0, 0, 0, 0.63)",
                                }}
                            />
                            <Typography variant="caption" color="text.secondary">
                                {[option.city, option.state].filter(Boolean).join(", ")}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Tooltip>
        </Box>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: { borderRadius: 1, p: 2 },
            }}
        >
            <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} JazzHR</DialogTitle>

            <DialogContent>
                <Stack spacing={3} sx={{ mt: 1 }}>
                    {!isVariantFetch ? (
                        jazzHRJobs?.length ? (
                            <>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography variant="body2">Select jazzHR job</Typography>
                                    <Tooltip
                                        title={selectedJazzHRJob?.title ?? "Select new job"}
                                        placement="right-start"
                                    >
                                        <Box sx={{ width: 200 }}>
                                            <Autocomplete
                                                value={
                                                    selectedJazzHRJob?.id
                                                        ? {
                                                              label: selectedJazzHRJob?.title,
                                                              id: selectedJazzHRJob?.id,
                                                              state: "",
                                                              city: "",
                                                          }
                                                        : null
                                                }
                                                onChange={handleJobChange}
                                                options={jazzHRJobs.map((item) => ({
                                                    label: item.title,
                                                    id: item.id,
                                                    state: item.state,
                                                    city: item.city,
                                                }))}
                                                getOptionLabel={(option) => option.label || ""}
                                                renderOption={renderJobOption}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Select job" size="small" />
                                                )}
                                                size="small"
                                            />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Typography variant="body2">Select Stage</Typography>
                                    <Box sx={{ width: 200 }}>
                                        <Autocomplete
                                            value={
                                                jobStage?.jobId ? { label: jobStage.jobName, id: jobStage.jobId } : null
                                            }
                                            onChange={handleStageChange}
                                            options={jazzHRStages.map((item) => ({
                                                label: item.jobName,
                                                id: item.jobId,
                                            }))}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    <Tooltip title={option.label} placement="left-start">
                                                        <Typography variant="body2">{option.label}</Typography>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Select stage" size="small" />
                                            )}
                                            size="small"
                                        />
                                    </Box>
                                </Stack>
                            </>
                        ) : (
                            <Typography variant="body2" align="center" sx={{ py: 1 }}>
                                No jobs found. Create one on your jazzHR dashboard, Or integrate jazzHR with easysource
                                first.
                            </Typography>
                        )
                    ) : jazzHRJobs?.length ? (
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body2">Map to jazzHR job</Typography>
                            <Tooltip title={selectedJazzHRJob?.title ?? "Select new job"} placement="right-start">
                                <Box sx={{ width: 200 }}>
                                    <Autocomplete
                                        value={
                                            selectedJazzHRJob?.title
                                                ? {
                                                      label: selectedJazzHRJob?.title,
                                                      id: selectedJazzHRJob?.id,
                                                      state: "",
                                                      city: "",
                                                  }
                                                : null
                                        }
                                        onChange={handleJobChange}
                                        options={jazzHRJobs.map((item) => ({
                                            label: item.title,
                                            id: item.id,
                                            state: item.state,
                                            city: item.city,
                                        }))}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderOption={renderJobOption}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select job" size="small" />
                                        )}
                                        size="small"
                                    />
                                </Box>
                            </Tooltip>
                        </Stack>
                    ) : (
                        <Typography variant="body2" align="center" sx={{ py: 1 }}>
                            No jobs found. Create one on your jazzHR dashboard, Or integrate jazzHR with easysource
                            first.
                        </Typography>
                    )}
                </Stack>
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button variant="outline" onClick={handleClose}>
                    Cancel
                </Button>
                {jazzHRJobs?.length > 0 && (
                    <Button onClick={handleJazzAdd} disabled={isLoading || isStageLoading}>
                        <ButtonTextWithLoading text="Submit" isLoading={isLoading || isStageLoading} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
