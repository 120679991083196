import React, { useState } from "react";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { keyframes, styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { getTwilloPowerCallListCreate } from "@/store/reducers/project/project.reducer";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const StyledBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(4),
    animation: `${fadeIn} 0.3s ease-out`,
    "&:focus": {
        outline: "none",
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    transition: "all 0.2s",
    borderRadius: 5,
    "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
}));

const AddToPowerDialerModal = ({
    open,
    handleClose,
    candidateIds,
}: {
    open: boolean;
    handleClose: () => void;
    candidateIds: string[];
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [campaignName, setCampaignName] = useState("");
    const params = useParams();
    const projectId = params.id;
    const handleCampaignCreate = () => {
        //check if the campaign name is not empty
        if (campaignName.trim() === "") {
            dispatch(setErrorNotification("Campaign name is required"));
            return;
        }

        dispatch(
            getTwilloPowerCallListCreate({
                payload: {
                    name: campaignName,
                    candidateIds: candidateIds,
                    projectId: projectId,
                },

                navigate: navigate,
            })
        );
    };
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <StyledBox>
                <Typography id="modal-title" variant="h5" component="h2" gutterBottom fontWeight="bold">
                    Create New Campaign
                </Typography>
                <Typography id="modal-description" variant="body1" color="text.secondary" paragraph>
                    Name your campaign and get ready to connect with your selected candidates.
                </Typography>
                <StyledTextField
                    fullWidth
                    variant="outlined"
                    label="Campaign Name"
                    placeholder="Enter campaign name"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                />
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2, mb: 3 }}>
                    You have selected <strong>{candidateIds.length} number of candidates</strong> with their phone
                    numbers.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                    <StyledButton variant="outlined" color="secondary" onClick={handleClose}>
                        Cancel
                    </StyledButton>
                    <StyledButton variant="contained" color="primary" onClick={handleCampaignCreate}>
                        Create Campaign
                    </StyledButton>
                </Box>
            </StyledBox>
        </Modal>
    );
};

export default function AddToPowerDialer({ candidateIds }: { candidateIds: string[] }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Tooltip title="Add to Power Dialer" placement="top">
                <IconButton onClick={handleOpen} color="primary" disabled={!Boolean(candidateIds?.length)}>
                    <AddIcCallIcon />
                </IconButton>
            </Tooltip>
            <AddToPowerDialerModal open={open} handleClose={handleClose} candidateIds={candidateIds} />
        </>
    );
}
