import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import useOAuth2 from "../../../hooks/useOAuth2/useOAuth2";
import { selectUser, setUser } from "@/store/reducers/signin/Signin.reducer";

import { Button, Modal, Typography } from "@/ui";

export default function LeverConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const leverRefreshToken = user.leverRefreshToken;

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: `${import.meta.env.VITE_REACT_APP_LEVER_AUTH_LINK}/authorize`,
        clientId: `${import.meta.env.VITE_REACT_APP_LEVER_CLIENT_ID}`,
        redirectUri: `${import.meta.env.VITE_REACT_APP_BASE_URL}/authorize-lever`,
        scope: "offline_access opportunities:write:admin postings:read:admin users:read:admin stages:read:admin uploads:write:admin resumes:read:admin",
        responseType: "code",
        extraQueryParameters: {
            prompt: "consent",
            audience: import.meta.env.VITE_REACT_APP_LEVER_AUDIENCE,
        },
        exchangeCodeForTokenServerURL: `${import.meta.env.VITE_REACT_APP_BASE_URL}/api/lever/token`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(
                setUser({
                    ...user,
                    leverRefreshToken: response.refresh_token,
                })
            );
            dispatch(setSuccessNotification(response.message));
        },
        onError: () => {
            dispatch(setErrorNotification("Error in connecting lever"));
        },
    });

    const handleConnect = () => getAuth();

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ maxWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Connect Lever</h2>
                <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                    <Typography className="text-sm ">
                        Lever is a top-rated recruiting software that helps your talent teams scale recruitment efforts
                        and hire people faster. Click the button below to connect your Lever account though OAuth
                    </Typography>
                </div>
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm border border-gray-300 p-2">
                        Cancel
                    </Button>
                    <Button
                        variant="default"
                        onClick={handleConnect}
                        className="rounded-sm"
                        disabled={loading || !!leverRefreshToken}
                    >
                        <ButtonTextWithLoading
                            isLoading={loading}
                            text={leverRefreshToken ? "Connected" : "Connect With OAuth"}
                        />
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

