import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { useDispatch } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import type { Config } from "redux-state-sync";

import { allCandidatesApi } from "./apis/all-candidates/all-candidates.api";
import { analyticsApi } from "./apis/analytics/analytics.api";
import { autoPilotApi } from "./apis/autopilot/autopilot.api";
import { chatbotApi } from "./apis/chatbot/chatbot.api";
import { companiesApi } from "./apis/companies/companies.api";
import { externalConnectApis } from "./apis/external-connect/external-connect.api";
import { invitationsApi } from "./apis/invitations/invitations.api";
import { linkedinWorkflowApi } from "./apis/linkedin-workflow/linkedin-workflow.api";
import { notificationApi } from "./apis/notification/notification.api";
import { organizationsApi } from "./apis/organizations/organizations.api";
import { outreachApi } from "./apis/outreach/outreach.api";
import { projectsApi } from "./apis/projects/projects.api";
import { searchApi } from "./apis/search/search.api";
import { superInboxApi } from "./apis/super-inbox/super-inbox.api";
import { userCreditApi } from "./apis/user-credit/user-credit.api";
import { cachingMiddleware, getPreloadedState } from "./middlewares/cachingMiddleware";
import reducers from "./reducers";
import { watchSagas } from "./sagas";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { setCalendlyState } from "@/store/reducers/signin/Signin.reducer";

const reduxSyncConfig: Config = {
    whitelist: [setSuccessNotification.type, setErrorNotification.type, setCalendlyState.type],
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    preloadedState: getPreloadedState(),
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: true,
                ignoredPaths: ["workflow.workflowData.date", "mailAlert.continueFlow", "payload.user"],
            },
        }).concat([
            outreachApi.middleware,
            companiesApi.middleware,
            allCandidatesApi.middleware,
            externalConnectApis.middleware,
            projectsApi.middleware,
            notificationApi.middleware,
            organizationsApi.middleware,
            analyticsApi.middleware,
            superInboxApi.middleware,
            linkedinWorkflowApi.middleware,
            autoPilotApi.middleware,
            invitationsApi.middleware,
            userCreditApi.middleware,
            sagaMiddleware,
            cachingMiddleware,
            chatbotApi.middleware,
            searchApi.middleware,
            createStateSyncMiddleware(reduxSyncConfig),
        ]),
});

initMessageListener(store);
sagaMiddleware.run(watchSagas);
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;

function UnloadHandler() {
    window.removeEventListener("unload", UnloadHandler, false);
}

window.addEventListener("unload", UnloadHandler, false);

window.addEventListener(
    "pageshow",
    function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    },
    false
);
