import { useSelector } from "react-redux";

import VirtualizedSelect from "./VirtualizedSelect";

import { useGetOrganizationMembersQuery } from "../store/apis/organizations/organizations.api";

import { selectUser } from "@/store/reducers/signin/Signin.reducer";

interface MembersDropdownProps {
    value: any;
    onChange: (value: any) => void;
    excludeAllMembers?: boolean;
}

function MembersDropdownContent(props: MembersDropdownProps) {
    const { onChange, value, excludeAllMembers } = props;

    const { data, isLoading } = useGetOrganizationMembersQuery();

    return (
        <VirtualizedSelect
            sx={{
                maxWidth: "150px",
            }}
            inputProps={{
                placeholder: "Search members",
            }}
            size="sm"
            variant="soft"
            value={value}
            disabled={isLoading}
            options={data?.members?.map((m) => ({ label: m.name, value: m._id.toString(), ...m })) ?? []}
            onChange={(option) => onChange(parseInt(option.value))}
            defaultOption={{ label: "All Members", value: "0" }}
            enableSearch={data?.members?.length > 10}
        />
    );
}

export default function MembersDropdown(props: MembersDropdownProps) {
    const user = useSelector(selectUser);
    const canAccessDropdown = user?.roleType === "ADMIN" || user?.isSuperAdmin;

    if (!canAccessDropdown) return null;

    return <MembersDropdownContent {...props} />;
}
