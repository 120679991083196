import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import style from "./candidateModal.module.scss";
import CandidateModalContextProvider, { useCandidateModalContext } from "./Context";

import Modal from "../../../../components/Modal/Modal";
import { useHighlightText } from "../../../../hooks/useHighlightText";
import useKeyboardNavigation from "../../../../hooks/useKeyboardNavigation";
import CandidateProfileCard from "../candidateProfileCard/CandidateProfileCard";
import CandidateWorkflows from "../CandidateWorkflows/CandidateWorkflows";
// import EmailTab from "../emailTab/EmailTab";
import GithubTab from "../githubTab/GithubTab";
import LinkedInWorkflowsTab from "../LinkedinWorkflowTab/LinkedinWorkflowTab";
import NotesTab from "../notesTab/NotesTab";
import ProfileTab from "../profileTab/ProfileTab";
import ResumeTab from "../resumeTab/ResumeTab";
import ScholarTab from "../scholarTab/ScholarTab";
import SelectWorkflowModal from "../selectWorkflowModal/SelectWorkflowModal";

import {
    selectActiveHiddenEditModal,
    setActiveCandidateInfoModalIndex,
} from "@/store/reducers/candidates/Candidates.reducer";
import { addToWorkflow, editCandidate, selectSearchQuery, setScrollToSkills } from "@/store/reducers/project/project.reducer";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { selectWorkflowData } from "@/store/reducers/workflow/workflow.reducer";
import { Pagination, Typography } from "@/ui";

function CardTitle() {
    const { t } = useTranslation();
    return (
        <Typography variant="h6">
            {t("projectsPage.candidateProfileCardName")}
        </Typography>
    );
}

function ArrowKeys() {
    const { t } = useTranslation();
    return <p className={style["arrowMessage"]}>{t("projectsPage.arrowMessage")}</p>;
}

export default function CandidateModalV2() {
    const { id: projectId } = useParams();

    return (
        <CandidateModalContextProvider projectId={projectId}>
            <CandidateModalV2Component />
        </CandidateModalContextProvider>
    );
}

function CandidateModalV2Component() {
    const { id: projectId } = useParams();
    const {
        localCandidates: myLocalCandidates,
        currentCandidate,
        currentCandidateIndex = 0,
        updateCandidate,
    } = useCandidateModalContext();
    const user = useSelector(selectUser);
    const searchQuery = useSelector(selectSearchQuery);

    const dispatch = useDispatch();
    const workflowData = useSelector(selectWorkflowData);
    const showEditPopup = useSelector(selectActiveHiddenEditModal);
    const [isSelectWkModalOpen, setIsSelectWkModalOpen] = useState(false);
    const [wkTemp, setWkTemp] = useState();
    const [wkDate, setWkDate] = useState("");
    const scrollRef = useRef<any>(null);
    const [currTab, setCurrTab] = useState<null | number>(null);

    useHighlightText([searchQuery], "candidate-modal");

    useKeyboardNavigation({
        pagination: {
            totalPage: myLocalCandidates.length,
            page: currentCandidateIndex,
            onNext(nextPage) {
                dispatch(setActiveCandidateInfoModalIndex(nextPage));
            },
            onPrevious(previousPage) {
                dispatch(setActiveCandidateInfoModalIndex(previousPage));
            },
            paginationSkip: showEditPopup,
        },
        scroll: {
            scrollContainerRef: scrollRef,
        },
    });

    const closeSelectWkModal = () => setIsSelectWkModalOpen(false);

    const handleTabChange = function (tab: number) {
        if (tab === currTab) return;
        setCurrTab(tab);
    };

    const handleAddToWk = () => {
        if (!wkTemp || !projectId) return;
        dispatch(
            addToWorkflow({
                workflowTemplateId: wkTemp,
                scheduledFor: dayjs(wkDate).toISOString(),
                project: Number(projectId),
                candidate: [currentCandidate._id],
                action: addToWorkflow.type,
                bcc: workflowData.bccEmail.value,
                unsubscribeEmail: workflowData.unsubscribeEmail,
            })
        );
        closeSelectWkModal();
    };

    const handleNavigation = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(setActiveCandidateInfoModalIndex(value - 1));
    };

    useEffect(() => {
        if (currentCandidate?._id) {
            if (currentCandidate?.gsMetaData?.scholarUrl) {
                handleTabChange(5);
            } else if (currentCandidate?.gitMetaData?.html_url) {
                handleTabChange(6);
            } else if (!currentCandidate.resume || currentCandidate.resume === "-") {
                handleTabChange(4);
            } else {
                handleTabChange(0);
            }
        }
    }, [currentCandidateIndex]);

    if (!currentCandidate?._id) {
        return <span />;
    }

    return (
        <div
            id="candidate-modal"
            style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "auto 1fr",
                maxHeight: "100%",
            }}
        >
            {" "}
            <div className={style["spcard__navigation"]}>
                <CardTitle />
                <Pagination
                    count={myLocalCandidates.length}
                    color="primary"
                    onChange={handleNavigation}
                    variant="contained"
                    page={currentCandidateIndex + 1}
                    size="small"
                />

                <ArrowKeys />
            </div>
            <div className={style["spcard__container"]} id="candidate-modal-card" ref={scrollRef}>
                <div className={style["spcard__card"]}>
                    <CandidateProfileCard
                        variant="modal"
                        projectId={projectId}
                        index={currentCandidateIndex}
                        candidate={currentCandidate}
                        handleSeeMore={() => dispatch(setScrollToSkills(true))}
                        onEditCandidate={(data) => {
                            dispatch(editCandidate(data));
                            updateCandidate(data);
                        }}
                    />
                </div>
                <div className={style["spcard__tabs"]}>
                    {currentCandidate?.gsMetaData?.scholarUrl && (
                        <div
                            className={`${style["spcard__tabLink"]} ${currTab === 5 ? style["spcard__tabLink--active"] : ""
                                }`}
                        >
                            <p
                                onClick={() => {
                                    handleTabChange(5);
                                }}
                            >
                                Scholar
                            </p>
                        </div>
                    )}
                    {currentCandidate?.gitMetaData?.html_url && (
                        <div
                            className={`${style["spcard__tabLink"]} ${currTab === 6 ? style["spcard__tabLink--active"] : ""
                                }`}
                        >
                            <p
                                onClick={() => {
                                    handleTabChange(6);
                                }}
                            >
                                Github
                            </p>
                        </div>
                    )}
                    {currentCandidate.resume && currentCandidate.resume !== "-" && (
                        <div
                            className={`${style["spcard__tabLink"]} ${currTab === 0 ? style["spcard__tabLink--active"] : ""
                                }`}
                        >
                            <p onClick={() => handleTabChange(0)}>Resume</p>
                        </div>
                    )}
                    {!currentCandidate?.gsMetaData?.scholarUrl && !currentCandidate.gitMetaData?.html_url && (
                        <div
                            className={`${style["spcard__tabLink"]} ${currTab === 4 ? style["spcard__tabLink--active"] : ""
                                }`}
                        >
                            <p onClick={() => handleTabChange(4)}>Profile</p>
                        </div>
                    )}

                    {/* <div className={`${style["spcard__tabLink"]} ${currTab == 1 ? style["spcard__tabLink--active"] : ""}`}>
                        <p onClick={() => handleTabChange(1)}>Emails</p>
                    </div> */}
                    <div
                        className={`${style["spcard__tabLink"]} ${currTab === 2 ? style["spcard__tabLink--active"] : ""
                            }`}
                    >
                        <p onClick={() => handleTabChange(2)}>Activities</p>
                    </div>
                    {user?.isSuperAdmin && (
                        <div
                            className={`${style["spcard__tabLink"]} ${currTab === 3 ? style["spcard__tabLink--active"] : ""
                                }`}
                        >
                            <p onClick={() => handleTabChange(3)}>Workflows</p>
                        </div>
                    )}
                    {currentCandidate?.ratingOfLinkedin && currentCandidate?.talentQuestionResponses && (
                        <div
                            className={`${style["spcard__tabLink"]} ${currTab === 7 ? style["spcard__tabLink--active"] : ""}`}
                        >
                            <p onClick={() => handleTabChange(7)}>LinkedIn Applicants Details</p>
                        </div>
                    )}

                    {/* <div
                        className={`${style["spcard__tabLink"]} ${
                            currTab === 3
                                ? style["spcard__tabLink--active"]
                                : ""
                        }`}
                    >
                        <p onClick={() => handleTabChange(3)}>Activity</p>
                    </div> */}
                    {/* <div className={`${style["spcard__tabLink"]} ${currTab == 4 ? style["spcard__tabLink--active"] : ""}`}>
                        <p onClick={() => handleTabChange(4)}>Events</p>
                    </div> */}
                </div>
                <div className={style["spcard__tab"]}>
                    {currentCandidate?.gsMetaData && currTab === 5 ? (
                        <ScholarTab
                            data={currentCandidate?.gsMetaData}
                            starRating={currentCandidate?.starRating}
                            leadScore={currentCandidate?.leadScore}
                            projectId={projectId ? projectId : ""}
                            candidateId={currentCandidate?._id}
                            vettingFixed={currentCandidate?.vettingFixed}
                        />
                    ) : null}
                    {currTab === 7 &&
                        currentCandidate?.ratingOfLinkedin &&
                        currentCandidate?.talentQuestionResponses ? (
                        <LinkedInWorkflowsTab
                            ratingOfLinkedin={currentCandidate.ratingOfLinkedin}
                            linkedInApplicantsQuestionnaire={currentCandidate.talentQuestionResponses}
                        />
                    ) : null}

                    {currentCandidate?.gitMetaData?.html_url && currTab === 6 ? (
                        <GithubTab
                            data={currentCandidate?.gitMetaData}
                            starRating={currentCandidate?.starRating}
                            leadScore={currentCandidate?.leadScore}
                            projectId={projectId ? projectId : ""}
                            candidateId={currentCandidate?._id}
                            vettingFixed={currentCandidate?.vettingFixed}
                        />
                    ) : null}

                    {currentCandidate?.resume && currentCandidate.resume !== "-" && currTab === 0 ? (
                        <ResumeTab projectId={projectId as string} candidate={currentCandidate} />
                    ) : null}
                    {/* {currTab === 1 ? <EmailTab id={currentCandidate._id} /> : null} */}
                    {currTab === 2 ? <NotesTab id={currentCandidate._id} name={currentCandidate.name} /> : null}
                    {/* {currTab === 3 ? <ActivityTab id={_id} /> : null}
                     */}
                    {currTab === 3 ? <CandidateWorkflows candidateId={currentCandidate._id} /> : null}
                    {currTab === 4 && !currentCandidate?.gsMetaData?.scholarUrl ? (
                        <ProfileTab data={currentCandidate} projectId={projectId ? projectId : ""} />
                    ) : null}
                </div>
                <Modal
                    open={isSelectWkModalOpen}
                    onClose={closeSelectWkModal}
                    title={"Add to Workflow"}
                    width={"400px"}
                >
                    <SelectWorkflowModal
                        closeModal={closeSelectWkModal}
                        wkDate={wkDate}
                        setWkDate={setWkDate}
                        wkTemp={wkTemp}
                        setWkTemp={setWkTemp}
                        handleAddToWk={handleAddToWk}
                    />
                </Modal>
            </div>
        </div>
    );
}
