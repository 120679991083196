import { createElement, useState } from "react";

import { Button, Card, CardContent, Typography } from "@/ui";

const CardComponent = (props) => {
  const [hovered, setHovered] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Card
        onMouseEnter={() => {
          setHovered(props.data.title);
        }}
        onMouseLeave={() => {
          setHovered(null);
        }}
        style={{
          width: 350,
          height: 230,
          paddingTop: "30px",
          cursor: "pointer",
          border: "0.5px solid lightgrey",
          borderWidth: "0.2px",
          backgroundColor: hovered === props.data.title ? "#f0f4f8" : "white",
        }}
      >
        <CardContent>
          <img
            src={props.data.logo}
            alt="logo"
            style={{
              width: props.data.imageSize ? "100px" : "40px",
              height: "40px",
            }}
          />
          <div>
            <h5 className="mt-1 text-sm font-semibold">{props.data.title}</h5>
          </div>
          <Typography className="mb-1.5 text-xs h-[60px] font-normal text-gray-600">
            {props.data.description}
          </Typography>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!props.data.comingSoon ? (
              <Button
                variant="outline"
                disabled={!props.data.isModal && !props.data.canEdit}
                style={{
                  fontSize: "12px",
                  padding: "5px 10px",
                  backgroundColor: !props.data.isModal ? "#73BBA3" : "white",
                  color: !props.data.isModal ? "white" : "primary.main",
                  borderColor: props.data.isModal ? "primary.main" : "#73BBA3",
                  ...(!props.data.isModal && {
                    "&.Mui-disabled": {
                      backgroundColor: "#73BBA3",
                      color: "white",
                      borderColor: "#73BBA3",
                    },
                  }),
                  ...(!props.data.isModal &&
                    props.data.canEdit && {
                    "&.MuiButton-outlined": {
                      "&:hover": {
                        backgroundColor: "#73BBA3",
                        color: "white",
                      },
                      "&:active": {
                        backgroundColor: "#73BBA3",
                        color: "white",
                      },
                    },
                  }),
                }}
                onClick={() => (props.data.isModalOpen ? setOpen(true) : null)}
                size="sm"
                label={props.data.isModal ? "Connect" : "Connected"}
              />
            ) : (
              <Button
                variant="outline"
                style={{ fontSize: "12px", padding: "5px 10px"}}
                disabled={props.data.comingSoon}
                size="sm"
                label="Coming soon"
              />
            )}
          </div>
        </CardContent>
      </Card>
      {open && props.data.component && createElement(props.data.component, { onClose: () => setOpen(false) })}
    </>
  );
};
export default CardComponent;
