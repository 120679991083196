import CachedIcon from "@mui/icons-material/Cached";
import { useDispatch, useSelector } from "react-redux";

import { useWorkflowLocationState } from "./BaseTemplates";

import { ErrorPlaceholder } from "../../../components/ErrorPlaceholder";
import { FETCH_CANDIDATE_NAMES } from "../../../utils/Constants";

import {
    checkFetchCandidateNamesError,
    fetchCandidateNames,
    submitPersonalizedInputs,
} from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";



export function OutreachErrorPlaceholder() {
    const dispatch = useDispatch();
    const isCandidateNamesFetchError = useSelector(checkFetchCandidateNamesError);
    const { projectId, candidateIds, isAllCandidatesSelected, filters, searchQuery, showByPersonalEmails } =
        useWorkflowLocationState();
    const handleClick = () => {
        dispatch(
            fetchCandidateNames({
                projectID: projectId,
                filters,
                searchQuery,
                candidateIDs: candidateIds,
                showByPersonalEmails,
                isAllCandidatesSelected,
            })
        );

        // calling fetch base templates again because it calls fetch hyper personalized templates
        dispatch(
            submitPersonalizedInputs({
                projectID: projectId,
                filters,
                searchQuery,
                candidateIDs: candidateIds,
                showByPersonalEmails,
                isAllCandidatesSelected,
                fetchNamesAction: FETCH_CANDIDATE_NAMES,
            })
        );
    };

    if (isCandidateNamesFetchError) {
        return (
            <ErrorPlaceholder
                reload={handleClick}
                containerProps={{
                    justifyContent: "center",
                }}
            >
                <CachedIcon fontSize="small" />
            </ErrorPlaceholder>
        );
    }

    return null;
}
