import { useSelector } from "react-redux";

import TextLoading from "../../../components/TextLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { setAllContactsSearchQuery } from "@/store/apis/all-contacts/all-contacts.slice";

export default function SearchLoading() {
    const isDeleting = useSelector(checkIfLoading(setAllContactsSearchQuery.type));

    if (!isDeleting) {
        return null;
    }

    return <TextLoading text="Searching..." />;
}
