import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import FactoryIcon from "@mui/icons-material/Factory";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import TimelineIcon from "@mui/icons-material/Timeline";
import WorkIcon from "@mui/icons-material/Work";
import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";
import {
    Avatar,
    Box,
    Chip,
    ChipProps,
    IconButton,
    Skeleton,
    SkeletonProps,
    Stack,
    StackProps,
    Tab,
    TabList,
    Tabs,
    Tooltip,
    styled,
    tabClasses,
} from "@mui/joy";
import TabPanel from "@mui/joy/TabPanel";
import { IconProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import Drawer from "@mui/material/Drawer";
import { Experimental_CssVarsProvider } from "@mui/material/styles";
import { isEmpty } from "lodash";
import { Fragment, SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { ProjectStatusDropDown, ProjectStatusDropDownAction } from "./ProjectStatusDropdown";
import { useFetchCandidateProjects } from "./SelectProject";

import { JoyProvider } from "../../../components/JoyProvider";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import TextWithEllipses from "../../../components/TextWithEllipses";
import {
    CandidateDetails as CandidateDetailsType,
    ProjectFromList,
    useFetchCandidateDetailsQuery,
    useUpdateProjectStatusMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { isLink } from "@/store/reducers/all-candidates/all-candidates.utils";

import { selectCandidateReachoutsEditDrawer, setEditDrawer } from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import {
    DetailedProjectStageOption,
    DetailedStageOptionKey,
    ProjectStageOption,
    ProjectStageOptionKey,
    detailedProjectStageOptionsList,
    detailedStageOptions,
    projectStageOptions,
    projectStageOptionsList,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.types";


function getChipColorBasedOnValue(value: string): ChipProps["color"] {
    const lowerCaseValue = value.toLowerCase();
    const otherVariants: ChipProps["color"][] = ["neutral", "danger", "primary", "warning", "success"];

    if (lowerCaseValue === "network") {
        return "primary";
    }

    if (lowerCaseValue === "candidate") {
        return "success";
    }

    const randomIndex = Math.floor(Math.random() * otherVariants.length);
    return otherVariants[randomIndex];
}

function CandidateInfoProject(props: ProjectFromList) {
    const { projectName, projectId, stage, detailedStage } = props;
    const handleClick = () => {
        window.open(`/projects/${projectId}`);
    };
    return (
        <Stack direction="row" borderRadius={1} sx={{ border: 1, borderColor: "divider", p: 1 }}>
            <Stack gap={1}>
                <Stack direction="row" alignItems="center">
                    <Experimental_CssVarsProvider>
                        <TextWithEllipses
                            typographyProps={{
                                height: "fit-content",
                                variant: "body2",
                                sx: (theme) => ({
                                    color: theme.palette.info.dark,
                                }),
                            }}
                        >
                            {projectName}
                        </TextWithEllipses>
                    </Experimental_CssVarsProvider>
                    <IconButton size="sm" sx={{ color: "#0891b2" }} onClick={handleClick}>
                        <OpenInNewRoundedIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                </Stack>
                <Stack direction="row" gap={0.7}>
                    <Experimental_CssVarsProvider>
                        <ProjectStage
                            initialStage={{
                                label: projectStageOptions[stage as ProjectStageOptionKey],
                                value: stage as ProjectStageOptionKey,
                            }}
                            projectId={projectId}
                        />
                        <DetailedProjectStage
                            projectId={projectId}
                            initialStage={{
                                label: detailedStageOptions[(detailedStage as DetailedStageOptionKey) || "in_play"],
                                value: (detailedStage as DetailedStageOptionKey) || "in_play",
                            }}
                        />
                    </Experimental_CssVarsProvider>
                </Stack>
            </Stack>
        </Stack>
    );
}
const drawerWidth = 380;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // transition: theme.transitions.create("margin", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    // }),
    marginRight: -drawerWidth,
    ...(open && {
        // transition: theme.transitions.create("margin", {
        //     easing: theme.transitions.easing.easeOut,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        marginRight: 0,
    }),
    position: "relative",
}));

type CandidateInfoContentContainerProps = {
    children: React.ReactNode;
    enableEdit?: boolean;
    editComponent?: React.ReactNode;
};

export function CandidateInfoContentContainer({
    children,
    enableEdit,
    editComponent,
}: CandidateInfoContentContainerProps) {
    const dispatch = useDispatch();

    const open = useSelector(selectCandidateReachoutsEditDrawer);

    const handleToggleDrawer = () => dispatch(setEditDrawer("TOGGLE"));

    if (enableEdit && editComponent === undefined) {
        throw new Error("edit component is required");
    }

    return (
        <JoyProvider>
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    height: "fit-content",
                }}
            >
                <Main open={open}>
                    {children}
                    {enableEdit && (
                        <IconButton
                            variant="solid"
                            onClick={handleToggleDrawer}
                            size="sm"
                            sx={{
                                height: "fit-content",
                                width: "fit-content",
                                position: "absolute",
                                top: "0.7rem",
                                right: "0.5rem",
                                backgroundColor: "#0891b2",
                                "&:hover": {
                                    backgroundColor: "#0677a1",
                                },
                            }}
                        >
                            {open ? (
                                <KeyboardArrowRightRoundedIcon sx={{ color: "common.white" }} />
                            ) : (
                                <KeyboardArrowLeftRoundedIcon sx={{ color: "common.white" }} />
                            )}
                        </IconButton>
                    )}
                </Main>
                <Experimental_CssVarsProvider>
                    {enableEdit && (
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                "& .MuiDrawer-paper": {
                                    position: "absolute",
                                    width: drawerWidth,
                                    display: open ? "block" : "none",
                                    zIndex: 10,
                                },
                            }}
                            variant="persistent"
                            anchor="right"
                            open={open}
                        >
                            <JoyProvider>{editComponent} </JoyProvider>
                        </Drawer>
                    )}
                </Experimental_CssVarsProvider>
            </Box>
        </JoyProvider>
    );
}

type CandidateInfoContainerProps = StackProps & {
    showBorder?: boolean;
};

const CandidateInfoContainer = styled(({ showBorder, sx, ...rest }: CandidateInfoContainerProps) => (
    <Stack
        m={2}
        sx={{
            ...(showBorder ? { borderBottom: 1, borderColor: "divider" } : {}),
            overflow: "auto",
            height: "calc(100vh - 380px)",
            ...sx,
        }}
        {...rest}
    />
))({});

type ProjectStageProps = {
    projectId: number;
    initialStage: ProjectStageOption;
    candidateIdProps?: string;
};

export function ProjectStage({ projectId, initialStage, candidateIdProps }: ProjectStageProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [updateProjectStatus, { isLoading }] = useUpdateProjectStatusMutation();
    const [projectStage, setProjectStage] = useState<ProjectStageOption>(() => initialStage);

    const setProjectStatus = (stage: ProjectStageOptionKey) => {
        const candidateId = candidateIdProps ? candidateIdProps : params?.id;
        if (candidateId) {
            updateProjectStatus({
                candidateIds: [candidateId],
                projectId,
                stage,
            })
                .unwrap()
                .then(() => {
                    dispatch(setSuccessNotification("Project stage updated successfully"));
                })
                .catch(() => {
                    dispatch(setErrorNotification("Error while updating project stage"));
                });
        }
    };

    const actions: ProjectStatusDropDownAction[] = projectStageOptionsList.map(({ label, value }) => ({
        label,
        value,
        handleClick: () => {
            setProjectStage({ label, value });
            setProjectStatus(value);
        },
    }));

    return <ProjectStatusDropDown selectedOption={projectStage} actions={actions} isLoading={isLoading} />;
}

type DetailedProjectStageOptionProps = {
    projectId: number;
    initialStage: DetailedProjectStageOption;
    candidateId?: string;
    successCallback?: (key: DetailedStageOptionKey) => void;
};

export function DetailedProjectStage({
    projectId,
    initialStage,
    candidateId,
    successCallback,
}: DetailedProjectStageOptionProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [updateProjectStatus, { isLoading }] = useUpdateProjectStatusMutation();
    const [projectStage, setProjectStage] = useState<DetailedProjectStageOption>(() => initialStage);

    const setProjectStatus = (stage: DetailedStageOptionKey) => {
        const candidate = candidateId || params?.id;
        if (candidate) {
            updateProjectStatus({
                candidateIds: [candidate],
                projectId,
                detailedStage: stage,
            })
                .unwrap()
                .then(() => {
                    if (successCallback) {
                        successCallback?.(stage);
                    } else {
                        dispatch(setSuccessNotification("Project stage updated successfully"));
                    }
                })
                .catch(() => {
                    dispatch(setErrorNotification("Error while updating project stage"));
                });
        }
    };

    const actions: ProjectStatusDropDownAction[] = detailedProjectStageOptionsList.map(({ label, value }) => ({
        label,
        value,
        handleClick: () => {
            setProjectStage({ label, value });
            setProjectStatus(value);
        },
    }));

    return <ProjectStatusDropDown selectedOption={projectStage} actions={actions} isLoading={isLoading} />;
}

function useFetchCandidateDetails() {
    const candidateInitialState: CandidateDetailsType = {
        labels: [],
        email: [],
        location: "",
        industry: "",
        jobTitle: "",
        company: "",
        currentTenure: "",
        yearsOfExperience: "",
        funding: "",
        phone: "",
        profileImage: "",
        name: "",
        profileUrl: "",
    };
    const params = useParams();
    const { data = candidateInitialState, ...rest } = useFetchCandidateDetailsQuery(params?.id || "");

    return { ...rest, data };
}

function CandidateInfoEmptyPlaceholder() {
    return (
        <Stack sx={{ p: 2 }}>
            <CandidatesReachoutEmptyPlaceholder
                icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: grey[400] }} />}
                title="No Details found"
                message="There are no details associated with this candidate."
            />
        </Stack>
    );
}

function CandidateDetails() {
    const { isLoading, data, isError } = useFetchCandidateDetails();

    if (isLoading) {
        return (
            <CandidateInfoContainer gap={2}>
                <CandidateInfoListLoading count={6} />
            </CandidateInfoContainer>
        );
    }

    if (isEmpty(data) || isError) {
        return <CandidateInfoEmptyPlaceholder />;
    }

    const { industry, funding, currentTenure, location, yearsOfExperience, profileUrl } = data;

    return (
        <CandidateInfoContainer gap={2}>
            <ContactInfoItem
                icon={<FmdGoodRoundedIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Location"
                value={location}
            />
            <ContactInfoItem
                icon={<LinkedInIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="LinkedIn"
                value={profileUrl}
            />
            <ContactInfoItem
                icon={<WorkHistoryRoundedIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Years of experience"
                value={yearsOfExperience}
            />
            <ContactInfoItem
                icon={<FactoryIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Industry"
                value={industry}
            />
            <ContactInfoItem
                icon={<AccountBalanceWalletIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="funding"
                value={funding}
            />
            <ContactInfoItem
                icon={<TimelineIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Current tenure"
                value={currentTenure}
            />
        </CandidateInfoContainer>
    );
}

export function CustomInfoTabs() {
    const [value, setValue] = useState(0);

    const handleChange = (_: SyntheticEvent<Element, Event> | null, value: string | number | null) => {
        if (typeof value === "number") {
            setValue(Number(value));
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} sx={{ bgcolor: "transparent" }}>
                    <TabList
                        sx={{
                            pl: 2,
                            [`&& .${tabClasses.root}`]: {
                                bgcolor: "transparent",
                                [`&.${tabClasses.selected}`]: {
                                    fontWeight: "600",
                                    "&::after": {
                                        height: "2px",
                                        bgcolor: "#0891b2",
                                    },
                                },
                            },
                        }}
                    >
                        <Tab sx={(theme) => ({ fontSize: theme.typography["body-sm"] })}>Snapshot</Tab>
                        <Tab sx={(theme) => ({ fontSize: theme.typography["body-sm"] })}>Projects</Tab>
                    </TabList>
                    <TabPanel value={0} sx={{ p: 0 }}>
                        <CandidateDetails />
                    </TabPanel>
                    <TabPanel value={1} sx={{ p: 0 }}>
                        <CandidateInfoProjectList />
                    </TabPanel>
                </Tabs>
            </Box>
        </Box>
    );
}

function CandidateInfoProjectList() {
    const { isLoading, data: projects } = useFetchCandidateProjects();

    if (isLoading) {
        return <ProjectListLoader />;
    }

    return (
        <CandidateInfoContainer gap={1} showBorder pb={2}>
            {projects.map((i) => {
                return <CandidateInfoProject key={i.projectId} {...i} />;
            })}
        </CandidateInfoContainer>
    );
}

function ProjectListLoader() {
    return (
        <CandidateInfoContainer gap={1}>
            {Array(10)
                .fill(1)
                .map((item) => {
                    return <ProjectCardSkelton key={item} />;
                })}
        </CandidateInfoContainer>
    );
}

function ProjectCardSkelton() {
    return (
        <Stack p={1} borderRadius={1} sx={{ border: 1, borderColor: "divider" }}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Stack direction="row" gap={0.7}>
                <Skeleton variant="text" width={40} />
                <Skeleton variant="text" width={40} />
            </Stack>
        </Stack>
    );
}

function getIconColor(): IconProps["sx"] {
    return {
        color: "#838383",
        height: 20,
        width: 20,
    };
}

const CandidateCardLabelsStack = styled(({ spacing, alignItems, direction, ...rest }: StackProps) => (
    <Stack spacing={1} alignItems="center" direction="row" {...rest} />
))({});

const ContainerStack = styled(({ ...rest }: StackProps) => <Stack {...rest} />)({
    p: 2,
});

function CandidateLabel({ text }: { text: string }) {
    return (
        <Chip
            variant="solid"
            sx={(theme) => ({
                padding: theme.spacing(0.5),
                height: "fit-content",
                width: "fit-content",
                "& .MuiChip-label": {
                    fontSize: theme.typography["body-xs"],
                    color: "common.white",
                },
            })}
            size="sm"
            color={getChipColorBasedOnValue(text)}
        >
            {text}
        </Chip>
    );
}

function ContactInfoItem({
    icon,
    toolTipTitle,
    value,
}: {
    toolTipTitle: string;
    icon: React.ReactElement;
    value: string | null;
}) {
    if (!value) {
        return null;
    }

    const isValueLink = isLink(value);

    const handleLinkClick = () => {
        window.open(value);
    };

    return (
        <CandidateCardLabelsStack alignItems="center">
            <Tooltip title={toolTipTitle}>{icon}</Tooltip>
            <Experimental_CssVarsProvider>
                <TextWithEllipses
                    typographyProps={{
                        onClick: isValueLink ? handleLinkClick : undefined,
                        variant: "body2",
                        sx: {
                            wordBreak: "break-word",
                            cursor: isValueLink ? "pointer" : "auto",
                        },
                    }}
                >
                    {value || "N/A"}
                </TextWithEllipses>
            </Experimental_CssVarsProvider>
        </CandidateCardLabelsStack>
    );
}

const ContactInfo = () => {
    const { isLoading, data } = useFetchCandidateDetails();

    // TODO: Add Meaningful placeholder
    if (isEmpty(data)) {
        return <div>Empty Contact info</div>;
    }

    const { profileImage, email, company, jobTitle, name, labels } = data;

    return (
        <JoyProvider>
            <Stack
                sx={(theme) => ({
                    background: theme.palette.common.white,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: "0.3rem",
                })}
            >
                <ContainerStack gap={2} sx={{ p: 2 }}>
                    {isLoading ? (
                        <CandidateInfoListLoading count={1} skeletonProps={{ height: 50, width: "50%" }} />
                    ) : (
                        <Stack alignItems="center" direction="row" gap={1}>
                            <Avatar sx={{ width: 60, height: 60 }} src={profileImage || ""} alt="candidate name" />
                            <Stack gap={0.3}>
                                <Stack direction="row" gap={1} alignItems="center">
                                    <Experimental_CssVarsProvider>
                                        <TextWithEllipses
                                            typographyProps={{
                                                variant: "body1",
                                            }}
                                        >
                                            {name}
                                        </TextWithEllipses>
                                    </Experimental_CssVarsProvider>
                                </Stack>
                                <Stack direction="row" gap={0.5}>
                                    {labels.map((i) => {
                                        return <CandidateLabel text={i} key={i} />;
                                    })}
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    <Stack gap={2}>
                        {isLoading ? (
                            <CandidateInfoListLoading count={3} />
                        ) : (
                            <>
                                <ContactInfoItem
                                    icon={<EmailIcon sx={{ ...getIconColor() }} />}
                                    toolTipTitle="Email"
                                    value={email[0]}
                                />
                                <ContactInfoItem
                                    icon={<BusinessIcon sx={{ ...getIconColor() }} />}
                                    toolTipTitle="Company"
                                    value={company}
                                />
                                <ContactInfoItem
                                    icon={<WorkIcon sx={{ ...getIconColor() }} />}
                                    toolTipTitle="Job title"
                                    value={jobTitle}
                                />
                            </>
                        )}
                    </Stack>
                </ContainerStack>
                <CustomInfoTabs />
            </Stack>
        </JoyProvider>
    );
};

function CandidateInfoListLoading({ count, skeletonProps }: { count: number; skeletonProps?: SkeletonProps }) {
    const skeleton = <Skeleton variant="rectangular" height={30} {...skeletonProps} />;

    return (
        <>
            {Array(count)
                .fill(1)
                .map((_, idx) => {
                    return <Fragment key={`candidate-info-loading-${idx}`}>{skeleton}</Fragment>;
                })}
        </>
    );
}

export default ContactInfo;
