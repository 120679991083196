import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../..";

type ITourState = {
    tourIsOpen: boolean;
    tourCurrentStep: number;
    relevanceToggle: boolean;
    openTriggerModal: boolean;
};

const initialState: ITourState = {
    tourIsOpen: false,
    tourCurrentStep: 0,
    relevanceToggle: false,
    openTriggerModal: false,
};

const tourSlice = createSlice({
    name: "tour",
    initialState,
    reducers: {
        setTourIsOpen: (state: ITourState, { payload }: { payload: boolean }) => {
            state.tourIsOpen = payload;
        },
        setTourCurrentStep: (state: ITourState, { payload }: { payload: number }) => {
            state.tourCurrentStep = payload;
        },
        setTourRelevanceToggle: (state: ITourState, { payload }: { payload: boolean }) => {
            state.relevanceToggle = payload;
        },
        setTourTriggerModal: (state: ITourState, { payload }: { payload: boolean }) => {
            state.openTriggerModal = payload;
        },
    },
});

export const setTourIsOpen = tourSlice.actions.setTourIsOpen;
export const setTourCurrentStep = tourSlice.actions.setTourCurrentStep;
export const setTourRelevanceToggle = tourSlice.actions.setTourRelevanceToggle;
export const setTourTriggerModal = tourSlice.actions.setTourTriggerModal;

export const selectTourIsOpen = (state: RootState) => state.tour.tourIsOpen;
export const selectTourCurrentStep = (state: RootState) => state.tour.tourCurrentStep;
export const selectTourRelevanceToggle = (state: RootState) => state.tour.relevanceToggle;
export const selectOpenTriggerModal = (state: RootState) => state.tour.openTriggerModal;

export default tourSlice.reducer;
