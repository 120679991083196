import { useSelector } from "react-redux";

import { checkEasySource } from "../../../Signin/Signin.reducer";

export const MarketConstants = () => {
    const isEasySource = useSelector(checkEasySource);
    const title = isEasySource ? "candidate" : "prospect";
    const titlePlural = isEasySource ? "candidate's" : "prospect's";
    return {
        Data: {
            title: "Select market research queries",
            description: `Click and select the queries from the set given below and run market research on the current company of the selected ${title} `,
            EGdescription: `Click and select the queries from the set given below and run market research on the current company of the selected ${title} `,
            children: [
                {
                    title: "LinkedinURL",
                    heading: "Linkedin URL",
                    description: `Get the LinkedIn url of the ${title}`,
                },
                {
                    title: "JobTitle",
                    heading: "Job Title",
                    description: `Get the current job title of the ${title}`,
                },
                {
                    title: "Location",
                    heading: "Location",
                    description: `Get the location of the ${title}`,
                },
                {
                    title: "Company",
                    heading: "Company",
                    description: `Get the current company of the ${title}`,
                },
                {
                    title: "CEO",
                    heading: "CEO",
                    description: `Get the name of the CEO of the ${titlePlural} company`,
                },
                {
                    title: "IPO",
                    heading: "IPO",
                    description: `Check if the ${titlePlural} company has gone public`,
                },
                {
                    title: "IPODate",
                    heading: "IPO Date",
                    description: `Get the date when the ${titlePlural} company went public`,
                },

                {
                    title: "FoundingYear",
                    heading: "Founding Year",
                    description: `Get the founding year of the ${titlePlural} company`,
                },
                {
                    title: "Revenue in USD",
                    heading: "Revenue in USD",
                    description: `Get the revenue of the ${titlePlural} company`,
                },
                {
                    title: "Industry",
                    heading: "Industry",
                    description: `Check which industry the ${titlePlural} company belongs to`,
                },
                {
                    title: "MarketCap",
                    heading: "Market Cap",
                    description: `Get the market capitalization of the ${titlePlural} company`,
                },
                {
                    title: "Employees",
                    heading: "Employees",
                    description: `Get the number of employees in the ${titlePlural} company`,
                },
                {
                    title: "Headquarters",
                    heading: "Headquarters",
                    description: `Get the location of the headquarters of the ${titlePlural} company`,
                },

                {
                    title: "Funding",
                    heading: "Funding",
                    description: `Check the funding details of the ${titlePlural} company`,
                },
                {
                    title: "ProductsandServices",
                    heading: "Products and Services",
                    description: `Find out the products and services offered by the ${titlePlural} company`,
                },
                {
                    title: "FreeTrial",
                    heading: "Free Trial",
                    description: `Check if the ${titlePlural} company offers a free trial of its products or services`,
                },
                {
                    title: "DemoOffering",
                    heading: "Demo Offering",
                    description: `Check if the ${titlePlural} company offers a demo of its products or services`,
                },
                {
                    title: "RecentlyLaunchedProducts",
                    heading: "Recently Launched Products",
                    description: `Find out the recently launched products of the ${titlePlural} company`,
                },
                {
                    title: "PricingPlans",
                    heading: "Pricing Plans",
                    description: `Check the pricing plans of the products or services offered by the ${titlePlural} company`,
                },
                {
                    title: "BusinessType",
                    heading: "Business Type",
                    description: `Check the type of business model of the ${titlePlural} company`,
                },
                {
                    title: "ParentCompany",
                    heading: "Parent Company",
                    description: `Find out if the ${titlePlural} company is a subsidiary of another company`,
                },
                {
                    title: "ChildCompanies",
                    heading: "Child Companies",
                    description: `Find out if the ${titlePlural} company has any subsidiaries`,
                },
                {
                    title: "CrunchbaseDetails",
                    heading: "Crunchbase Details",
                    description: `Get the Crunchbase details of the ${titlePlural} company`,
                },
                {
                    title: "VacantPositions",
                    heading: "Vacant Positions",
                    description: `Check if the ${titlePlural} company has any vacant positions`,
                },
                {
                    title: "PhoneNumber",
                    heading: "Phone Number",
                    description: `Get the phone number of the ${titlePlural} company`,
                },
                {
                    title: "DomainLink",
                    heading: "Domain Link",
                    description: `Get the domain link of the ${titlePlural} company`,
                },
                {
                    title: "Instagram",
                    heading: "Instagram",
                    description: `Get the Instagram handle of the ${titlePlural} company`,
                },
                {
                    title: "Twitter",
                    heading: "Twitter",
                    description: `Get the Twitter/X handle of the ${titlePlural} company`,
                },
                {
                    title: "YouTube",
                    heading: "YouTube",
                    description: `Get the YouTube page of the ${titlePlural} company`,
                },
                {
                    title: "FundingType",
                    heading: "Funding Type",
                    description: `Get the latest funding type of the ${titlePlural} company`,
                },

                {
                    title: "Competitors",
                    heading: "Competitors",
                    description: `Find the competitors of the ${titlePlural} company`,
                },

                {
                    title: "Tenure",
                    heading: "Tenure",
                    description: `Check how long ${title} has been in their current company`,
                },
            ],
        },
    };
};
