import { getlocalStorage } from "./useLocalStorage";

import { formatRecord } from "@/utils/format-data";

// The useRTKCachedState function is a custom hook that reads the state from local storage and returns it. If the data is not found in the local storage, it returns the default state from the query.

const useRTKQueryCachedState = <T extends (...args: any[]) => any, Args extends Parameters<T>>(
    config: { reducerPath: string; skip?: boolean },
    queryHook: T,
    ...args: Args
): ReturnType<T> => {
    // Pass the arguments to the queryHook.
    const result = queryHook(...args);

    if (result.status === "pending" && result.endpointName) {
        const args = formatRecord(result.originalArgs);
        const queryCacheArgs = JSON.stringify(args);
        const queryCacheKey = `${result.endpointName}(${queryCacheArgs})`;
        if (!config.skip) {
            const cachedData = getlocalStorage.read(`rtk/${config.reducerPath}/${queryCacheKey}`);
            if (cachedData) {
                result.data = cachedData.data;
                result.isLoading = false;
            }
        }
    }

    return result;
};

const clearRTKQueryCachedState = () => {
    const data = getlocalStorage.readAll();
    Object.entries(data).forEach(([key, value]) => {
        if (key.startsWith("rtk")) {
            const validity = Date.now() - value.timestamp;
            // if validity is more than 1 day, remove the data
            if (validity > 1000 * 60 * 60 * 24) {
                getlocalStorage.remove(key);
            }
        }
    });
};

export { useRTKQueryCachedState, clearRTKQueryCachedState };
