import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import { Box, Modal, Sheet, Stack, Typography as Typography1 } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import { TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import { cn } from "@/lib/utils";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { getSendosoGiftsSend } from "@/store/reducers/project/project.reducer";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button } from "@/ui";

const getFirstAndLastName = (fullName: string) => {
    const nameParts = fullName.trim().split(/\s+/);

    let first_name = "";
    let last_name = "";

    if (nameParts.length > 0) {
        first_name = nameParts[0];
    }

    if (nameParts.length > 1) {
        last_name = nameParts[nameParts.length - 1];
    }

    return { first_name, last_name };
};
type Payload = {
    recipient_first_name: string;
    recipient_last_name: string;
    recipient_email: string;
    variant_ids: any[];
    message: string;
    gift_exchange_enabled: boolean;
    projectId: any;
    candidateId: any;
    sender_first_name?: string;
    sender_last_name?: string;
    sender_email?: string;
    sender_organization_name?: string;
};
interface SendGiftModalProps {
    open: boolean;
    onClose: () => void;
    candidate: any;
    detailedView: any;
    closeSendosoModal: () => void;
}
export default function SendGiftModal({
    open,
    onClose,
    candidate,
    detailedView,
    closeSendosoModal,
}: SendGiftModalProps) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [physicalNoteCardMessage, setPhysicalNoteCardMessage] = useState("");
    const [editMode, setEditMode] = useState(true);
    const [consentChecked, setConsentChecked] = useState(false);
    const [allowRecipientToExchangeGift, setAllowRecipientToExchangeGift] = useState(false);
    const isLoading = useSelector(checkIfLoading(getSendosoGiftsSend.type));

    useEffect(() => {
        if (candidate?.email?.length > 0) {
            if (candidate?.email[0]) {
                setEmail(candidate?.email[0] ?? "");
                setEditMode(false);
            }
        } else if (candidate?.professional_email?.length > 0) {
            if (candidate?.professional_email[0]) {
                setEmail(candidate?.professional_email[0] ?? "");
                setEditMode(false);
            }
        } else {
            setEmail("");
        }

        if (candidate?.name) {
            const { first_name, last_name } = getFirstAndLastName(candidate?.name);
            setFirstName(first_name);
            setLastName(last_name);
        }
    }, [candidate]);
    const sendGift = () => {
        if (!email || !email.includes("@") || !email.includes(".")) {
            dispatch(setErrorNotification("Please fill the required fields"));
            return;
        }
        if (!user?.companyName?.trim()) {
            dispatch(setErrorNotification("Please visit Workspace settings and update your company name"));
            return;
        }
        const payload: Payload = {
            recipient_first_name: firstName,
            recipient_last_name: lastName,
            recipient_email: email,
            variant_ids: [detailedView?.variants[0]?.id],
            message: physicalNoteCardMessage,
            gift_exchange_enabled: allowRecipientToExchangeGift,
            projectId: candidate?.projectId,
            candidateId: candidate?.candidateId,
        };
        if (user.companyName && user.email && user.name) {
            const { first_name, last_name } = getFirstAndLastName(user.name);
            payload.sender_first_name = first_name;
            payload.sender_last_name = last_name ?? "";
            payload.sender_email = user.email;
            payload.sender_organization_name = user.companyName;
        }
        dispatch(
            getSendosoGiftsSend({
                payload: payload,
                closeSendosoModal: closeSendosoModal,
                onClose: onClose,
            })
        );

        //when the notification is closed, close the modal
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidEmail = validateEmail(email);

    return (
        <Modal open={open} onClose={onClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Sheet
                variant="plain"
                sx={{
                    width: "90vh",
                    borderRadius: "sm",
                    height: "65vh",
                    boxShadow: "lg",
                    overflow: "auto",
                    backgroundColor: "white",
                }}
            >
                <IconButton sx={{ m: 3, position: "absolute", right: 0, top: 0 }} onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>

                <Stack sx={{ display: "flex", flexDirection: "col", flexWrap: "wrap", p: "25px", marginTop: "20px" }}>
                    <MaterialProvider>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "25px" }}>
                            {candidate?.name}
                        </Typography>
                        {!editMode && isValidEmail ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="body1">{email}</Typography>
                                <JoyProvider>
                                    <IconButton onClick={() => setEditMode(!editMode)} size="sm">
                                        <ModeEditOutlineOutlinedIcon fontSize="small" sx={{ mb: 1 }} />
                                    </IconButton>
                                </JoyProvider>
                            </div>
                        ) : (
                            <div style={{ display: "flex" }}>
                                <TextField
                                    label="Email Address"
                                    variant="outlined"
                                    size="small"
                                    error={!isValidEmail && email.length > 0}
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{
                                        width: "100%",
                                        mt: 2,
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#0891B2", // Border color when focused
                                            },
                                        },

                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#0891B2", // Label color when focused
                                        },
                                    }}
                                />
                                {email && (
                                    <JoyProvider>
                                        <IconButton
                                            onClick={() => setEditMode(!editMode)}
                                            size="sm"
                                            sx={{ height: "30px", marginTop: "20px" }}
                                            disabled={!isValidEmail} // Disable icon if email is invalid
                                        >
                                            <SaveAsOutlinedIcon fontSize="small" sx={{ mb: 1 }} />
                                        </IconButton>
                                    </JoyProvider>
                                )}
                            </div>
                        )}

                        <Typography sx={{ mt: 2, fontSize: "14px" }}>Enter physical note card message</Typography>
                        <JoyProvider>
                            <Textarea
                                sx={{
                                    mt: 2,
                                    backgroundColor: "white",
                                    width: "100%",
                                    border: "1px solid #0891B2", // Default border color

                                    "&.Mui-focused": {
                                        borderColor: "#0891B2", // Ensuring the focus state works
                                    },
                                }}
                                placeholder="Type in here…"
                                value={physicalNoteCardMessage}
                                onChange={(event) => {
                                    if (event.target.value.length <= 1000) {
                                        setPhysicalNoteCardMessage(event.target.value);
                                    }
                                }}
                                minRows={4}
                                maxRows={4}
                                startDecorator={<Box sx={{ display: "flex", gap: 0.5, flex: 1 }} />}
                                endDecorator={
                                    <Typography1 level="body-xs" sx={{ ml: "auto" }}>
                                        {physicalNoteCardMessage.length} character(s) out of 1000 characters
                                    </Typography1>
                                }
                            />

                            <Typography1
                                level="body-xs"
                                sx={{
                                    fontStyle: "italic",
                                    color: "grey",
                                }}
                            >
                                {" "}
                                This note will be delivered to the candidate along with your gift !
                            </Typography1>
                        </JoyProvider>
                    </MaterialProvider>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "14px" }}>
                        <input
                            type="checkbox"
                            onChange={(e) => setAllowRecipientToExchangeGift(e.target.checked)}
                            className={`bg-${consentChecked ? "cyan-600" : "white"}`}
                        />
                        <Typography sx={{ ml: 2, fontSize: "12px" }}>Allow recipient to exchange gift</Typography>
                        <MaterialProvider>
                            <Tooltip
                                title="This allows the recipient to exchange the gift within the same price when they confirm or accept"
                                sx={{ ml: 1 }}
                            >
                                <InfoOutlinedIcon fontSize="small" sx={{ fontSize: "12px", ml: 1 }} />
                            </Tooltip>
                        </MaterialProvider>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "14px" }}>
                        <input
                            type="checkbox"
                            onChange={(e) => setConsentChecked(e.target.checked)}
                            className={`bg-${consentChecked ? "cyan-600" : "white"}`}
                        />
                        <Typography sx={{ ml: 2, fontSize: "12px" }}>
                            I confirm that I will pay the invoice for the selected gift upon receipt, and I consent to
                            my card on-file to be auto-deducted .
                        </Typography>
                    </div>
                    <Typography
                        sx={{
                            mt: 0.5,
                            fontSize: "10px",
                            fontStyle: "italic",
                        }}
                    >
                        {" "}
                        Once you confirm your purchase below, you shall receive an invoice at end of the month on your
                        email address
                    </Typography>

                    <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                        <Button
                            disabled={!consentChecked}
                            loading={isLoading}
                            className={cn("h-[36px] mt-[10px]", !consentChecked && "bg-gray-300 hover:bg-gray-300")}
                            onClick={() => sendGift()}
                        >
                            Confirm purchase & send gift
                        </Button>
                    </div>
                </Stack>
            </Sheet>
        </Modal>
    );
}
