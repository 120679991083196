import { formatRecord } from "./format-data";

async function generateCryptoHashId(args: any): Promise<string> {
    const sortedFilters = formatRecord(args);
    const str = JSON.stringify(sortedFilters);
    return new Promise((resolve) => {
        crypto.subtle.digest("SHA-256", new TextEncoder().encode(str)).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
            resolve(hashHex);
            return hashHex;
        });
    });
}

export { generateCryptoHashId };
