import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";

type IRootState = {
    showSupportTicket: boolean;
    supportTickets: any;
};

const initialState: IRootState = {
    showSupportTicket: false,
    supportTickets: [],
};

const reducers = {
    setShowSupportTicket(state: typeof initialState, { payload }: { payload: boolean }) {
        state.showSupportTicket = payload;
    },
    generateTicket(state: typeof initialState, { payload }: { payload: { title: string; description: string } }) {},
    getAllSupportTicket(state: typeof initialState) {},
    setAllSupportTicket(state: typeof initialState, { payload }: { payload: any }) {
        state.supportTickets = payload;
    },
    updateStatus(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                status: string;
                ticketId: string;
            };
        }
    ) {},
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "Support",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    setShowSupportTicket,
    generateTicket,
    getAllSupportTicket,
    setAllSupportTicket,
    updateStatus,
    leavePage,
} = page.actions;

export const selectShowSupportTicket = (state: RootState) => state.support.showSupportTicket;
export const selectSupportTickets = (state: RootState) => state.support.supportTickets;
