import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ComputerIcon from "@mui/icons-material/Computer";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import InfoIcon from "@mui/icons-material/Info";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Button } from "@mui/joy";
import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Popover,
    Stack,
    SvgIconProps,
    TextField,
    Theme,
    Tooltip,
    Typography,
    alpha,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import { AttachmentList, AttachmentsModal } from "./components/Attachments";
import { CalendlyMenu } from "./components/CalendlyMenu";
import { CandidatesMenu } from "./components/CandidatesMenu";
import { ConfirmDelete } from "./components/ConfirmDelete";
import { CustomStack } from "./components/CustomStack";
import { CustomTitle } from "./components/CustomTitle";
import ExclusionList from "./components/ExclusingList";
import { MimicWritingStyles } from "./components/MimicWritingStyles";
import { OutreachElementIcon } from "./components/OutreachElementIcon";
import { PersonalizedMenu } from "./components/PersonalizeMenu";
import { SaveModal } from "./components/SaveModal";
import { FetchFromTemplate } from "./components/TemplatesMenu";
import { TestEmailModal } from "./components/TestEmailModal";
import { WarningModal } from "./components/WarningModal";
import useAddWorkflowStep from "./hooks/useAddWorkflowStep.hook";
import { useOutreachParams } from "./hooks/useOutreachParams.hook";
import { useSaveMultipleOutreachTemplates } from "./hooks/useSaveOutreach.hook";

import errorImage from "../../assets/img/outreach-error.svg";
import CustomButton from "../../components/CustomButton";
import { JoyProvider } from "../../components/JoyProvider";
import MUILoader from "../../components/MUILoader";
import Navbar from "../../components/Navbar/Navbar";
import useDisclosure from "../../hooks/useDisclosure";
import useMenu from "../../hooks/useMenu";
import { RootState, useAppDispatch } from "../../store";
import {
    useDeleteWorkflowStepMutation,
    useFetchOutreachByIdQuery,
    useFetchOutreachCandidatesQuery,
    useFetchTimeRangeQuery,
    useFetchWritingStylesQuery,
} from "../../store/apis/outreach/outreach.api";
import {
    OutReachElementWithEventEnumAndName,
    OutReachEvent,
    OutreachElementProps,
    WorkflowStepValue,
} from "../../store/apis/outreach/outreach.types";
import {
    getElementDescription,
    getFutureTimestampWithSameTime,
    outreachTooltipMapping,
} from "../../utils/outreach.helpers";
import { TemplateEditorProvider } from "../OutReach/TemplateEditorProvider";

import { selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    addOutReachElement,
    checkEmailPresentInOutreachFlow,
    checkErrorInOutreachElement,
    checkErrorsInOutreach,
    checkIfOutreachReady,
    checkLinkedInPresent,
    checkSMSPresent,
    markCurrentTemplateAsSave,
    removeOutReachElement,
    selectCurrentOutreachElement,
    selectCurrentOutreachElementType,
    selectOutReachFlow,
    setIsScheduleRecruiterOnSameDay,
    setOutReachElementDays,
    setOutReachElementTime,
    shouldDisableDelete,
    toggleMobileView,
    validateOrdering,
} from "@/store/reducers/outreach/outreach.slice";
import {
    checkIsLinkedinAuthorized,
    checkRecruiterInMail,
    checkSMSEnabled,
    selectUser,
    selectUserOrgId,
} from "@/store/reducers/signin/Signin.reducer";
import { selectIsSuperAdminView } from "@/store/reducers/super-admin/superAdmin.reducer";
import { Badge } from "@/ui";

type MenuAction = OutReachElementWithEventEnumAndName & {
    handleClick: () => void;
    label: string;
    icon: React.ReactNode;
};

const smallFontSize: SvgIconProps["fontSize"] = "small";

function AddOutReachElementMenu() {
    const user = useSelector(selectUser);
    const { navigateToNextOutreachElement } = useOutreachParams();
    const dispatch = useAppDispatch();
    const {
        addWorkflowStep,
        saveMutationState: { isLoading: isSavingTemplate },
    } = useAddWorkflowStep({
        successCallback: (data) => {
            const { type, eventBody } = data;
            const { subject, body, inMailBody, inMailSubject, connectionReminderMessageBody } = eventBody;
            dispatch(
                addOutReachElement({
                    eventName: type,
                    subject,
                    body,
                    inMailBody,
                    inMailSubject,
                    connectionReminderMessageBody,
                    onSuccess: (newOutreachElementId) => {
                        navigateToNextOutreachElement(newOutreachElementId);
                        setTimeout(() => {
                            const selectedOutreachElement = document.getElementById(
                                `outreach-element-${newOutreachElementId}`
                            );
                            if (selectedOutreachElement) {
                                selectedOutreachElement.scrollIntoView({ behavior: "smooth" });
                            }
                        }, 0);
                    },
                })
            );
        },
    });
    const { isHyperPersonalizedView } = useOutreachParams();
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const isEmailPresent = useSelector((state: RootState) =>
        checkEmailPresentInOutreachFlow(state, isHyperPersonalizedView)
    );
    const isLinkedinPresent = useSelector(checkLinkedInPresent);
    const isSMSPresent = useSelector(checkSMSPresent);
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    const handleClickWithClose = (callback: () => void) => () => {
        callback();
        handleClose();
    };

    const actions: MenuAction[] = [
        {
            eventEnum: 1,
            eventName: "email",
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("email");
            }),
            label: "Email",
            icon: <OutreachElementIcon eventType="email" props={{ fontSize: smallFontSize }} />,
        },

        {
            eventName: "follow-up",
            eventEnum: 11,
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("follow-up");
            }),
            label: "Follow-up email",
            icon: <OutreachElementIcon eventType="follow-up" props={{ fontSize: smallFontSize }} />,
        },
        {
            eventName: "linkedin",
            eventEnum: 25,
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("linkedin");
            }),
            label: "Linkedin",
            icon: <OutreachElementIcon eventType="linkedin" props={{ fontSize: smallFontSize }} />,
        },
        {
            eventName: "SMS",
            eventEnum: 12,
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("SMS");
            }),
            label: "SMS",
            icon: <OutreachElementIcon eventType="SMS" props={{ fontSize: smallFontSize }} />,
        },
    ];

    return (
        <>
            <Tooltip title="Add outreach element">
                <CustomButton
                    onClick={handleClick}
                    variant="contained"
                    sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        padding: "0.4rem 1rem",
                        borderRadius: "0.3rem",
                        backgroundColor: "0891B2",
                        border: 0,
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "0891B2",
                        },
                    })}
                >
                    Add
                </CustomButton>
            </Tooltip>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiPaper-root": {
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {actions.map(({ label, handleClick, icon, eventName }) => {
                    if (eventName === "SMS" && isSMSPresent) {
                        return null;
                    }
                    if (eventName === "linkedin" && isLinkedinPresent) {
                        return null;
                    }

                    if (eventName === "email" && isEmailPresent) {
                        return null;
                    }

                    if (eventName === "follow-up" && !isEmailPresent) {
                        return null;
                    }
                    let tooltipTitle = "";
                    let disabled = false;
                    if (eventName === "SMS" && !isSMSEnabled) {
                        tooltipTitle = "Please contact your account manager to enable SMS";
                        disabled = true;
                    }

                    if (eventName === "SMS" && user.isRB2B) {
                        disabled = true;
                        tooltipTitle =
                            "Reach out to customer-support@hirequotient.com to upgrade and send SMS messages";
                    }
                    if (eventName === "linkedin" && user.isRB2B) {
                        disabled = true;
                        tooltipTitle = "Reach out to customer-support@hirequotient.com to upgrade and send LI messages";
                    }

                    return (
                        <Tooltip key={label} title={tooltipTitle} placement="right" arrow>
                            <span>
                                <MenuItem disabled={disabled} key={label} onClick={() => handleClick()}>
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: "0.9rem",
                                            },
                                        }}
                                    >
                                        {label}
                                    </ListItemText>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    );
                })}
            </Menu>
        </>
    );
}

function DeleteOutreachElement({ id }: { id: string }) {
    const {
        isOpen: isOpenConfirmDeleteModal,
        onOpen: onOpenConfirmDeleteModal,
        onClose: onCloseConfirmDeleteModal,
    } = useDisclosure();
    const { currentOutreachElementId, navigateToNextOutreachElement, isHyperPersonalizedView, outreachId } =
        useOutreachParams();
    const currentOutreachElement = useSelector((state: RootState) =>
        selectCurrentOutreachElement(state, { templateId: id })
    );
    const dispatch = useAppDispatch();
    const [deleteWorkflowSteps] = useDeleteWorkflowStepMutation();
    const disableDelete = useSelector((state: RootState) => shouldDisableDelete(state, id));

    if (isHyperPersonalizedView) {
        return null;
    }

    const handleDelete = () => {
        dispatch(markCurrentTemplateAsSave(currentOutreachElementId));
        setTimeout(() => {
            dispatch(
                removeOutReachElement({
                    currentSelectedTemplateId: currentOutreachElementId,
                    deleteTemplateId: id,
                    moveToOutreachElement: navigateToNextOutreachElement,
                    onDelete: (deletedIds: string[]) => {
                        setTimeout(() => {
                            deleteWorkflowSteps({
                                stepIds: [id, ...deletedIds],
                                workflowId: outreachId,
                            });
                        }, 0);
                        setTimeout(() => {
                            dispatch(validateOrdering());
                        }, 0);
                    },
                })
            );
        }, 0);
    };
    const isEmail = currentOutreachElement.eventName === "email";

    return (
        <>
            <Tooltip
                placement="right"
                title={
                    isEmail
                        ? "If you delete this email element, all follow-ups (if any) will also get deleted"
                        : "Delete"
                }
            >
                <IconButton color="error" onClick={onOpenConfirmDeleteModal} disabled={disableDelete}>
                    <DeleteOutlinedIcon sx={{ fontSize: 18 }} />
                </IconButton>
            </Tooltip>
            {isOpenConfirmDeleteModal && (
                <ConfirmDelete
                    deleteOutreachElementId={id}
                    onDelete={handleDelete}
                    onClose={onCloseConfirmDeleteModal}
                />
            )}
        </>
    );
}

const svgStyles: SvgIconProps["sx"] = {
    position: "absolute",
    top: "0.7rem",
    right: "0.5rem",
};

function getElementColor(eventName: WorkflowStepValue["eventName"], theme: Theme) {
    switch (eventName) {
        case "email": {
            return "#fd185d";
        }
        case "SMS": {
            return "#fc5910";
        }
        case "linkedin": {
            return "#196FA8";
        }
        default:
            return "#FFBF00";
    }
}

// interval should update time of the first element at every 1 minute
// if index === 1 then schedule a interval that makes a dispatch call to update time;
// if index !== 1 and there is previous interval scheduled then clear it;
// on day change or time change same useEffect is going to fire;
// it should be fire during the entire life time of the page
// every time scheduletime got updated, useEffect is going to fire
// for time this useEffect runs and index matches then check whether there is some previous interval
// that is scheduled if it is then clear it and schedule a new one
// also on the unmount clear interval
function OutReachElement({
    eventName,
    _id,
    scheduledTime,
    scheduledDay,
    isSaved,
    index,
    nthFollowup,
}: OutreachElementProps) {
    const dispatch = useAppDispatch();
    const { currentOutreachElementId, navigateToNextOutreachElement, isHyperPersonalizedView } = useOutreachParams();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [timeValue, setTimeValue] = useState<Dayjs | null>(() => {
        return dayjs(scheduledTime);
    });
    const [day, setDay] = useState(() => String(scheduledDay));
    const isErrorInOutreachElement = useSelector((state: RootState) => checkErrorInOutreachElement(state, _id));
    const intervalRef = useRef<null | NodeJS.Timeout>(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            const isScheduledTimeBeforeCurrentTime = dayjs(scheduledTime).isBefore(dayjs());
            if (scheduledDay === 1 && isScheduledTimeBeforeCurrentTime) {
                dispatch(
                    setOutReachElementTime({
                        newTimeStamp: dayjs().toISOString(),
                        templateId: _id,
                        shouldReorder: false,
                        shouldMarkUnSave: false,
                    })
                );
            }
        }, 1000);
        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [scheduledDay, scheduledTime, index, _id]);

    useEffect(() => {
        setTimeValue(dayjs(scheduledTime));
    }, [scheduledTime]);

    useEffect(() => {
        setDay(String(scheduledDay));
    }, [scheduledDay]);

    const handleElementClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        if (isErrorInOutreachElement && isHyperPersonalizedView) {
            dispatch(setErrorNotification("Cannot generate preview as there may be errors in the template"));
            return;
        }
        navigateToNextOutreachElement(_id);
    };

    const handleClockClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const debounceDispatchTime = useCallback(
        debounce((value: string) => {
            dispatch(
                setOutReachElementTime({
                    newTimeStamp: value,
                    templateId: _id,
                })
            );
        }, 400),
        [_id]
    );

    const debounceDispatchDay = useCallback(
        debounce((value: number) => {
            dispatch(setOutReachElementDays({ templateId: _id, days: value }));
        }, 400),
        [_id]
    );

    const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const valueAsNumber = Number(e.target.value);

        // don't allow zeros and empty values
        if (value === "0" || valueAsNumber < 0) {
            setDay((prev) => prev);
        } else {
            setDay(value);
        }

        if (!isNaN(valueAsNumber) && valueAsNumber > 0) {
            debounceDispatchDay(valueAsNumber);
        }
    };

    const handleTimeChange = (newValue: dayjs.Dayjs | null) => {
        if (day === "1" && dayjs(newValue).isBefore(dayjs())) {
            dispatch(setErrorNotification("Invalid time (please do not select a time in the past)"));
            return;
        }
        if (newValue) {
            setTimeValue(newValue);
            debounceDispatchTime(newValue.toISOString());
        }
    };

    const handleClose = () => {
        // if user leaves days input as empty then initial state got set
        if (day === "") {
            setDay(String(scheduledDay));
        }
        setAnchorEl(null);
    };

    const isMatching = currentOutreachElementId === _id;
    const description = getElementDescription({
        elementType: eventName,
        day: getFutureTimestampWithSameTime(scheduledDay - 1, timeValue?.toISOString() || "").toISOString(),
        time: timeValue?.toISOString() || "",
    });

    return (
        <>
            <Stack alignItems="center" sx={{ position: "relative" }}>
                <Tooltip title={outreachTooltipMapping[eventName]}>
                    <IconButton
                        // role="group"
                        data-component-name="Button"
                        onClick={handleElementClick}
                        sx={(theme) => ({
                            backgroundColor: isErrorInOutreachElement
                                ? theme.palette.error.main
                                : isMatching
                                  ? getElementColor(eventName, theme)
                                  : "transparent",
                            ":hover": {
                                backgroundColor: isErrorInOutreachElement
                                    ? theme.palette.error.main
                                    : getElementColor(eventName, theme),
                            },
                            ":hover *": {
                                color: "white",
                            },
                        })}
                        id={`outreach-element-${_id}`}
                    >
                        <OutreachElementIcon
                            eventType={eventName}
                            props={{
                                sx: (theme) => ({
                                    color: isErrorInOutreachElement
                                        ? "white"
                                        : isMatching
                                          ? "white"
                                          : getElementColor(eventName, theme),
                                    fontSize: "2rem",
                                }),
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={isSaved ? "Saved" : "Not saved yet"}>
                    {isSaved ? (
                        <DoneAllRoundedIcon fontSize="small" color="success" sx={svgStyles} />
                    ) : (
                        <PriorityHighRoundedIcon fontSize="small" color="primary" sx={svgStyles} />
                    )}
                </Tooltip>
                <Typography
                    textAlign={"center"}
                    variant="body2"
                    fontWeight={"600"}
                    pt={isMatching ? 1 : 0}
                    sx={(theme) => ({
                        color: theme.palette.grey[600],
                        width: theme.typography.pxToRem(150),
                    })}
                >
                    {outreachTooltipMapping[eventName]} {nthFollowup}
                </Typography>
                <Stack direction="row" alignItems="center" pl={2}>
                    <Tooltip title="Edit date and time">
                        <Typography
                            onClick={handleClockClick}
                            variant="body2"
                            fontStyle="italic"
                            sx={(theme) => ({
                                ":hover": {
                                    cursor: "pointer",
                                    fontWeight: 600,
                                },
                                color: isErrorInOutreachElement ? theme.palette.error.main : theme.palette.grey[600],
                                width: theme.typography.pxToRem(110),
                                fontSize: theme.typography.pxToRem(13),
                                textAlign: "center",
                            })}
                        >
                            Day {day}, {timeValue?.format("h:mm A")}
                        </Typography>
                    </Tooltip>
                    <Tooltip title="Remove element">
                        <DeleteOutreachElement id={_id} />
                    </Tooltip>
                </Stack>
            </Stack>
            <Popover
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: "630px",
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    },
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Stack p={2} alignItems="center">
                    <Stack gap={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" gap={1} alignItems="center">
                                <Typography variant="body2">Execute this outreach step on day</Typography>
                                <TextField
                                    value={day}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    onChange={handleDaysChange}
                                    sx={(theme) => ({
                                        width: "70px",
                                        "& .MuiInputBase-input": {
                                            fontSize: theme.typography.pxToRem(14),
                                        },
                                    })}
                                    inputProps={{ minLength: 1, maxLength: 2 }}
                                />
                                <Typography variant="body2">at time</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MultiSectionDigitalClock
                                        value={timeValue}
                                        onChange={handleTimeChange}
                                        timeSteps={{ hours: 1, minutes: 1 }}
                                        sx={{
                                            height: "50px",
                                            width: "fit-content",
                                            "& .MuiButtonBase-root": {
                                                padding: "0",
                                                fontSize: "14px",
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                        <Typography variant="body2" fontStyle="italic">
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </Typography>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
}

function OutReachFlow() {
    const { currentOutreachElementId, outreachId } = useOutreachParams();
    const stackRef = useRef<HTMLDivElement>(null);
    const { isHyperPersonalizedView } = useOutreachParams();
    const outreach = useSelector(selectOutReachFlow);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const user = useSelector(selectUser);
    const userId = user?._id;
    const isRecruiterInMail = useSelector(checkRecruiterInMail);
    const orgId = useSelector(selectUserOrgId);
    const dispatch = useDispatch();
    const { data = [] } = useFetchOutreachCandidatesQuery({
        outreachId,
    });
    const candidateCount = data?.length;

    const { data: timeData } = useFetchTimeRangeQuery({
        candidateCount,
        isSuperAdminView,
        userId,
    });
    const apiTime = timeData?.totalTime ?? "";
    const totalCandidates = data?.length;
    const totalMinutes = totalCandidates * 3;
    const roundedTime = Math.ceil(totalMinutes / 10) * 10;
    const displayTime =
        roundedTime >= 60
            ? `${Math.floor(roundedTime / 60)} hr${roundedTime % 60 > 0 ? ` ${roundedTime % 60} min` : ""}`
            : `${roundedTime} min`;
    const hasLinkedinOnDay1 = outreach?.some(
        (item: any) =>
            item?.eventName?.toLowerCase() === "linkedin" &&
            item?.scheduledDay === 1 &&
            item?.eventBody?.inMailStatus === true
    );

    const isSameDayInMailChecked = outreach?.some((item: any) => {
        return item?.eventName?.toLowerCase() === "linkedin" && item?.eventBody?.isScheduleRecruiterOnSameDay === true;
    });
    const linkedinElement = outreach?.find((item) => item.eventName === "linkedin");
    const linkedinElementId = linkedinElement?._id ?? linkedinElement?.stepId;

    const handleSameDayRecruiterInmails = () => {
        dispatch(setIsScheduleRecruiterOnSameDay({ currentOutreachElementId: linkedinElementId }));
    };

    let followupCount = 0;
    return (
        <CustomStack id="outreach-flow">
            <Stack
                sx={(theme) => ({
                    backgroundColor: theme.palette.grey[100],
                })}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                px={1.5}
                py={1.15}
            >
                <CustomTitle>Outreach Flow</CustomTitle>
                {!isHyperPersonalizedView && (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <AddOutReachElementMenu />
                        {/* <OutReachMode /> */}
                    </Stack>
                )}
            </Stack>
            <Divider />
            <Stack
                ref={stackRef}
                direction="column"
                paddingBottom={25}
                sx={(theme) => ({
                    maxWidth: "100%",
                    overflow: "auto",
                    background: theme.palette.grey[50],
                })}
            >
                <div
                    style={{
                        padding: "16px 0px",
                        height: "calc(100vh - 100px)",
                    }}
                >
                    {outreach?.map((element, index) => {
                        const isLast = index === outreach.length - 1 ? true : false;
                        const isFollowup = element?.eventName === "follow-up";
                        const nthFollowup = isFollowup ? ++followupCount : "";
                        const isMatching = currentOutreachElementId === element._id;
                        return (
                            <Stack alignItems="center" direction="column" p={2} gap={0.5} key={element._id}>
                                <div
                                    style={{
                                        position: "relative",
                                        borderRadius: "1rem",
                                        padding: "1rem",
                                        // @ts-ignore
                                        border: isMatching && "1px solid #A8ED1F",
                                        // @ts-ignore
                                        backgroundColor: isMatching && "#EFF2E9",
                                    }}
                                >
                                    <OutReachElement {...element} nthFollowup={String(nthFollowup)} index={index} />
                                    {hasLinkedinOnDay1 &&
                                        !isSameDayInMailChecked &&
                                        (isRecruiterInMail || orgId === 1292) &&
                                        element?.eventName === "linkedin" && (
                                            <Typography
                                                sx={{ textAlign: "center", fontSize: "10.5px", color: "#973643" }}
                                            >
                                                InMails triggered on first day of campaign will be queued to be sent on
                                                Day 2, to avoid automation detection.
                                            </Typography>
                                        )}

                                    {hasLinkedinOnDay1 &&
                                        isSameDayInMailChecked &&
                                        isRecruiterInMail &&
                                        element?.eventName === "linkedin" && (
                                            <Typography
                                                sx={{ textAlign: "center", fontSize: "10.5px", color: "#760010" }}
                                            >
                                                The InMails are scheduled to be sent today. To ensure successful
                                                outreach, kindly refrain from using your LinkedIn recruiter account for
                                                approximately{""}{" "}
                                                <strong style={{ textDecoration: "underline" }}>
                                                    {apiTime ?? displayTime}
                                                </strong>
                                                .
                                            </Typography>
                                        )}
                                    {isRecruiterInMail && hasLinkedinOnDay1 && element?.eventName === "linkedin" && (
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <div>
                                                    <p style={{ marginBottom: "4px" }}>
                                                        This feature sends InMails on the same day for LinkedIn
                                                        Recruiter accounts, even if it is triggered on Day 1. However,
                                                        you need to keep your LinkedIn Recruiter account inactive during
                                                        the specified time.
                                                    </p>
                                                    Note:
                                                    <ul>
                                                        <li style={{ marginBottom: "6px" }}>
                                                            a. Strictly close the active tabs for LinkedIn Recruiter for
                                                            specified time frame, after the scheduled time of sending
                                                            InMails.
                                                        </li>
                                                        <li style={{ marginBottom: "6px" }}>
                                                            b. If the daily limit of sending InMails (150/ day) is
                                                            exceeded, the remaining InMails will be scheduled for the
                                                            next day.
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginLeft: "15px",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "12px",
                                                        marginRight: "4px",
                                                        paddingTop: "10px",
                                                        color: "#757575",
                                                    }}
                                                >
                                                    Send InMail on day 1
                                                </p>
                                                <Checkbox
                                                    size="small"
                                                    checked={isSameDayInMailChecked}
                                                    onChange={handleSameDayRecruiterInmails}
                                                />
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>

                                {!isLast && (
                                    <Divider
                                        orientation="vertical"
                                        sx={{
                                            minHeight: `50px`,
                                        }}
                                    />
                                )}
                            </Stack>
                        );
                    })}
                </div>
            </Stack>
        </CustomStack>
    );
}

function TemplatesHeader({
    calendlyMenu,
    personalizationMenu,
}: {
    calendlyMenu?: React.ReactNode;
    personalizationMenu?: React.ReactNode;
}) {
    const dispatch = useDispatch();
    const isRecruiterInMail = useSelector(checkRecruiterInMail);
    const { currentOutreachElementId, isHyperPersonalizedView, projectId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const isMobileViewOn = useSelector((state: RootState) => state.outreach.isMobileViewOn);
    const templateType = outreachTooltipMapping[currentElementType as OutReachEvent];
    const text = isHyperPersonalizedView ? `${templateType} preview for` : `${templateType ?? ""} template`;

    const onClickToggle = () => {
        dispatch(toggleMobileView({ currentOutreachElementId }));
    };

    const padding = 0.9;

    const { data: writingStylesData = {} } = useFetchWritingStylesQuery({
        projectId,
    });

    return (
        <Stack
            sx={(theme) => ({
                backgroundColor: theme.palette.grey[100],
            })}
            px={1.5}
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="space-between"
        >
            <Stack direction="row" gap={0.5} alignItems="center" py={padding}>
                <Stack direction="row" alignItems="center" gap={1}>
                    {templateType && <CustomTitle>{text}</CustomTitle>}

                    {currentElementType === "linkedin" && (
                        <Tooltip
                            title={
                                isRecruiterInMail
                                    ? "LinkedIn allows you to send both connection requests and InMails to new people. With your LinkedIn Recruiter account, you can choose to send both or either one"
                                    : "LinkedIn allows you to send connection requests or send InMails to new people. InMails can only be sent if the other person has an 'Open to InMails' setting"
                            }
                        >
                            <InfoIcon sx={{ mr: 1, fontSize: "18px", color: "grey" }} />
                        </Tooltip>
                    )}
                </Stack>
                {isHyperPersonalizedView && templateType && <CandidatesMenu />}
                {isHyperPersonalizedView &&
                    (currentElementType === "email" ||
                        currentElementType === "follow-up" ||
                        currentElementType === "SMS") && (
                        <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            marginLeft={"1rem"}
                            sx={{ ":hover": { cursor: "pointer" }, display: "flex", flexDirection: "row", gap: 1.5 }}
                        >
                            {isMobileViewOn ? (
                                <Tooltip title="Desktop preview">
                                    <div onClick={onClickToggle}>
                                        <ComputerIcon sx={{ fontSize: "20px", color: "grey" }} />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Mobile view shows how your email will fit on a typical iPhone screen (iPhones are used by nearly 40% of the US population)">
                                    <div onClick={onClickToggle}>
                                        <PhoneAndroidIcon sx={{ fontSize: "20px", color: "grey" }} />
                                    </div>
                                </Tooltip>
                            )}
                            {isHyperPersonalizedView && writingStylesData?.applyStyle && (
                                <Tooltip title="Your writing style has been applied to this template">
                                    <Badge badgeContent={<DoneAllIcon sx={{ color: "green" }} fontSize="small" />}>
                                        <DesignServicesIcon
                                            sx={{ fontSize: "20px", color: "#0891B2" }}
                                            fontSize="small"
                                        />
                                    </Badge>
                                </Tooltip>
                            )}
                        </Stack>
                    )}
            </Stack>
            {!isHyperPersonalizedView && (
                <Stack direction="row" gap={1.5} alignItems="center">
                    <AttachmentList />
                    <Stack p={padding} direction="row" alignItems={"center"} gap={1.5}>
                        <MimicWritingStyles />
                        <FetchFromTemplate type={currentElementType} />
                        <AttachmentsModal />
                        <Stack id="calendly-personalization" direction="row" alignItems={"center"} gap={1.5}>
                            {calendlyMenu}
                            {personalizationMenu}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

export function TemplatePreview() {
    return (
        <TemplateEditorProvider>
            <CustomStack>
                <TemplatesHeader calendlyMenu={<CalendlyMenu />} personalizationMenu={<PersonalizedMenu />} />
                <Divider />
                <Box
                    id="outreach-template"
                    sx={(theme) => ({ backgroundColor: theme.palette.grey[50] })}
                    flexGrow={1}
                    overflow={"scroll"}
                    style={{
                        display: "flex",
                        gap: 2,
                    }}
                >
                    <Box width="100%">
                        <Outlet />
                    </Box>
                </Box>
                <TemplatesSubmission />
            </CustomStack>
        </TemplateEditorProvider>
    );
}

function TemplatesSubmission() {
    const [testEmailModal, setTestEmailModal] = useState(false);
    const dispatch = useAppDispatch();
    const {
        isHyperPersonalizedView,
        navigateToHyperPersonalizedTemplates,
        navigateToBaseTemplates,
        currentOutreachElementId,
    } = useOutreachParams();

    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const isErrorInOutreachElement = useSelector((state: RootState) =>
        checkErrorInOutreachElement(state, currentOutreachElementId)
    );

    const isErrorsInCurrentOutreachTemplate = useSelector((state: RootState) =>
        checkErrorInOutreachElement(state, currentOutreachElementId)
    );
    const isErrorInOutreach = useSelector(checkErrorsInOutreach);
    const {
        saveAllTemplates,
        saveMutationState: { isLoading: isSavingTemplate },
    } = useSaveMultipleOutreachTemplates({
        successCallback: (data) => {
            dispatch(setSuccessNotification("Template saved successfully"));
        },
    });

    const handlePreviewClick = () => {
        navigateToHyperPersonalizedTemplates();
    };

    const disableSaveButton = isSavingTemplate || isErrorInOutreach;

    const disablePreviewButton = isErrorsInCurrentOutreachTemplate;

    const onCloseTestEmailModal = () => {
        setTestEmailModal(false);
    };

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                p={1.5}
                gap={1.5}
                pr={2}
                sx={(theme) => ({
                    borderTop: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.grey[50],
                })}
            >
                {isHyperPersonalizedView ? (
                    <Tooltip title="Edit template" placement="top">
                        <CustomButton
                            onClick={navigateToBaseTemplates}
                            variant="contained"
                            sx={(theme) => ({
                                fontSize: theme.typography.pxToRem(13),
                                padding: "0.4rem 1rem",
                                borderRadius: "0.3rem",
                                backgroundColor: "#0891B2",
                                border: 0,
                                boxShadow: "none",
                                "&:hover": {
                                    backgroundColor: "#0891B2",
                                },
                            })}
                        >
                            Edit
                        </CustomButton>
                    </Tooltip>
                ) : (
                    <>
                        {(currentElementType === "email" || currentElementType === "follow-up") && (
                            <Tooltip
                                placement="top"
                                title={
                                    isErrorsInCurrentOutreachTemplate ? (
                                        <>
                                            Please resolve template errors (
                                            <WarningRoundedIcon sx={{ fontSize: "11px" }} /> marked in red) to test your
                                            email content and formatting.
                                        </>
                                    ) : (
                                        "Test your email content and formatting"
                                    )
                                }
                            >
                                <CustomButton
                                    onClick={() => setTestEmailModal(true)}
                                    disabled={isErrorInOutreachElement}
                                    variant="contained"
                                    sx={(theme) => ({
                                        fontSize: theme.typography.pxToRem(13),
                                        padding: "0.4rem 1rem",
                                        borderRadius: "0.3rem",
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                        color: "#0891B2",
                                        "&:hover": {
                                            opacity: 1,
                                            backgroundColor: "#0891B2",
                                            color: "white",
                                        },
                                        alignSelf: "flex-end",
                                    })}
                                >
                                    Test email
                                </CustomButton>
                            </Tooltip>
                        )}
                        <Stack direction="row" alignItems="center" gap={1}>
                            {isSavingTemplate && (
                                <Stack direction="row" alignItems="center" gap={0.5}>
                                    <CircularProgress size={14} />
                                    <Typography variant="body2">Saving....</Typography>
                                </Stack>
                            )}
                            <Tooltip
                                title={
                                    isErrorInOutreach ? (
                                        <>
                                            Please resolve template errors (
                                            <WarningRoundedIcon sx={{ fontSize: "11px" }} /> marked in red) to save
                                            workflow
                                        </>
                                    ) : (
                                        "Save your entire workflow, including all your templates and time schedule"
                                    )
                                }
                                placement="top"
                            >
                                <CustomButton
                                    disabled={disableSaveButton}
                                    onClick={saveAllTemplates}
                                    variant="contained"
                                    sx={(theme) => ({
                                        fontSize: theme.typography.pxToRem(13),
                                        padding: "0.4rem 1rem",
                                        borderRadius: "0.3rem",
                                        backgroundColor: "#0891B2",
                                        border: 0,
                                        boxShadow: "none",
                                        "&:hover": {
                                            backgroundColor: alpha("#0891B2", 0.7),
                                        },
                                    })}
                                >
                                    Save workflow
                                </CustomButton>
                            </Tooltip>
                        </Stack>
                        <Tooltip
                            title={
                                isErrorsInCurrentOutreachTemplate ? (
                                    <>
                                        Please resolve template errors (<WarningRoundedIcon sx={{ fontSize: "11px" }} />{" "}
                                        marked in red) to preview template
                                    </>
                                ) : (
                                    "Preview template"
                                )
                            }
                            placement="top"
                        >
                            <CustomButton
                                onClick={handlePreviewClick}
                                disabled={disablePreviewButton}
                                variant="contained"
                                sx={(theme) => ({
                                    fontSize: theme.typography.pxToRem(13),
                                    padding: "0.4rem 1rem",
                                    borderRadius: "0.3rem",
                                    backgroundColor: "#0891B2",
                                    border: 0,
                                    boxShadow: "none",
                                    "&:hover": {
                                        backgroundColor: alpha("#0891B2", 0.7),
                                    },
                                })}
                            >
                                Preview
                            </CustomButton>
                        </Tooltip>
                    </>
                )}
            </Stack>
            {testEmailModal && <TestEmailModal onClose={onCloseTestEmailModal} />}
        </>
    );
}

function TotalCandidatesCount() {
    const { t } = useTranslation();
    const { outreachId } = useOutreachParams();
    const { data = [], isLoading } = useFetchOutreachCandidatesQuery({
        outreachId,
    });

    const totalCandidates = data?.length;

    if (isLoading) {
        return <Skeleton variant="rounded" width={210} height={20} />;
    }

    return (
        <Tooltip
            arrow
            title={`${t("common")} in workflow may be fewer than selected ${t("common")}. This is because any ${t("common")} marked excluded will not be part of this workflow.`}
            placement="right"
        >
            <Chip
                label={
                    totalCandidates === 1
                        ? `${totalCandidates} ${t("commonSingular")} in workflow`
                        : `${totalCandidates} ${t("common")} in workflow`
                }
                sx={{
                    borderRadius: "0.3rem",
                    "& .MuiChip-label": {
                        fontSize: "12px",
                    },
                }}
            />
        </Tooltip>
    );
}

function OutReachHeader({ templateType }: { templateType: string }) {
    const projectName = useSelector(selectProjectName);
    const navigate = useNavigate();
    const location = useLocation();
    const isOutreachTemplatesReady = useSelector(checkIfOutreachReady);
    const outreach = useSelector(selectOutReachFlow);
    const user = useSelector(selectUser);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const isEmailAuthorized = user.emailAuthorized;

    const isEmailPresent = outreach.find((item) => item?.eventName === "email");
    const isLinkedinPresent = outreach.find((item) => item?.eventName === "linkedin");

    const { projectId } = useOutreachParams();

    const handleScheduleOutreach = () => {
        if (location.pathname[location.pathname.length - 1] === "/") {
            navigate(`${location.pathname}schedule`);
        } else {
            navigate(`${location.pathname}/schedule`);
        }
    };

    const handleBackClick = () => {
        navigate(`/projects/${projectId}/`);
    };

    const isDisabled =
        !isOutreachTemplatesReady ||
        !templateType ||
        (isEmailPresent && !isEmailAuthorized) ||
        (isLinkedinPresent && !isLinkedInAuthorized);

    const disableReason = !isDisabled ? (
        ""
    ) : !isOutreachTemplatesReady ? (
        <>
            Please <strong>Save Workflow</strong> before scheduling outreach
        </>
    ) : isEmailPresent && !isEmailAuthorized ? (
        "Email needs to be authorized first"
    ) : (
        "Linkedin needs to be authorized first"
    );

    return (
        <Stack
            p={0.5}
            py={0.7}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
                minHeight: "50px",
            })}
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <Tooltip title="Back">
                    <IconButton onClick={handleBackClick}>
                        <ArrowBackRoundedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <TotalCandidatesCount />
                {projectName && (
                    <Chip
                        label={projectName}
                        sx={{
                            backgroundColor: "white",
                            color: "#0891B2",
                            border: "1px solid #0891B2",
                            borderRadius: "0.3rem",
                            "& .MuiChip-label": {
                                fontSize: "12px",
                            },
                        }}
                    />
                )}
            </Stack>
            <Stack direction="row" gap={1.5}>
                <ExclusionList />
                <Tooltip title={isDisabled ? disableReason : ""}>
                    <CustomButton
                        id="schedule-outreach"
                        disabled={isDisabled}
                        onClick={handleScheduleOutreach}
                        variant="contained"
                        sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(13),
                            padding: "0.4rem 1rem",
                            borderRadius: "0.3rem",
                            mr: 1,
                            backgroundColor: green[500],
                            borderColor: green[500],
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: alpha(green[700], 0.7),
                            },
                            "&:disabled": {
                                backgroundColor: alpha(green[700], 0.7),
                            },
                        })}
                    >
                        Schedule Outreach
                    </CustomButton>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

function ErrorPlaceholder({ handleRefetch }: { handleRefetch: () => void }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
            }}
        >
            <img src={errorImage} height={500} width={500} alt="error" style={{ objectFit: "cover" }} />
            <Typography variant="h6" fontWeight={400}>
                Unexpected error occurred while fetching outreach details...
            </Typography>
            <CustomButton
                onClick={handleRefetch}
                variant="contained"
                sx={{ marginTop: "1rem", boxShadow: 1 }}
                startIcon={<RestartAltIcon />}
            >
                Try again
            </CustomButton>
        </Box>
    );
}

export function OutReachPage() {
    const { outreachId } = useOutreachParams();

    const [hideTourModal, setHideTourModal] = useLocalStorageState("outreach-tour-modal");
    const [, setShowTour] = useLocalStorageState("outreach-tour");

    const { isLoading, isError, refetch } = useFetchOutreachByIdQuery(outreachId);
    const { currentOutreachElementId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const templateType = outreachTooltipMapping[currentElementType as OutReachEvent];

    const handleRefetch = () => refetch();

    if (isError) {
        return <ErrorPlaceholder handleRefetch={handleRefetch} />;
    }

    if (isLoading) {
        return <MUILoader />;
    }

    return (
        <>
            <Stack sx={{ height: hideTourModal === undefined || hideTourModal === false ? "85%" : "100%" }}>
                <Navbar />
                {(hideTourModal === undefined || hideTourModal === false) && (
                    <Stack
                        sx={{
                            marginBottom: 1,
                            background: "#D1FFBD",
                            borderRadius: "0.5rem",
                        }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        padding={1}
                        direction={"row"}
                    >
                        <Typography sx={{ color: "black" }} textAlign={"center"} variant="body2">
                            Reaching out is now easier and more intuitive. Take a 15-second tour here
                        </Typography>
                        <JoyProvider>
                            <Stack gap={1} alignItems={"center"} direction={"row"}>
                                <Button onClick={() => setShowTour(true)} size="sm">
                                    Start tour
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setShowTour(false);
                                        setHideTourModal(true);
                                    }}
                                >
                                    <CloseRoundedIcon />
                                </Button>
                            </Stack>
                        </JoyProvider>
                    </Stack>
                )}

                <Stack
                    component={Paper}
                    elevation={0}
                    sx={{
                        border: 1,
                        borderColor: grey[300],
                        borderRadius: "0.3rem",
                        height: "93%",
                    }}
                >
                    <OutReachHeader templateType={templateType} />
                    <Divider />
                    <Box
                        sx={{
                            display: "grid",
                            height: "100%",
                            gridTemplateColumns: "250px auto 1fr",
                            maxHeight: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <OutReachFlow />
                        <Divider orientation="vertical" />
                        {templateType && <TemplatePreview />}
                    </Box>
                </Stack>
            </Stack>
            <WarningModal />
            <SaveModal />
        </>
    );
}
