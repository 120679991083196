import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CallModalContent } from "../../project/components/TwilloModule/CallModal";
import { selectCandidateDetails } from "@/store/reducers/project/project.reducer";

const CallTab: React.FC = () => {
    const candidate = useSelector(selectCandidateDetails);

    return (
        <CallModalContent
            candidate={candidate}
            editPhoneNumber={() => { }}
            setEditPhoneNumber={() => { }}
            handleBlockCandidate={() => { }}
            block={false}
            powerDialer={false}
        />
    );
};

export default CallTab;
