import { zodResolver } from "@hookform/resolvers/zod";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import { Experimental_CssVarsProvider, FormHelperTextProps, SxProps, Theme, Typography, styled } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import type { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import CustomButton from "../../../components/CustomButton";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { useAppDispatch } from "../../../store";
import { useSendReminderMailMutation } from "../../../store/apis/all-candidates/all-candidates.api";
import { POPOVER_BOX_SHADOW } from "../../../utils/Constants";
import handleException from "../../../utils/sentry";
import { SendReminderEmailRequest } from "@/store/reducers/all-candidates/all-candidates.types";

const HELPER_TEXT_MARGIN: FormHelperTextProps["sx"] = {
    marginX: "3px",
};

export const dateTimePickerStyles: SxProps<Theme> = (theme) => {
    const fontSize = theme.typography.body2;
    return {
        marginTop: "0.2rem",
        "& .MuiFormLabel-root ": {
            ...fontSize,
        },
        "& .MuiInputBase-input": {
            ...fontSize,
            padding: "0.7rem",
        },
        "& .MuiOutlinedInput-notchedOutline legend": {
            width: "88px",
        },
    };
};

const scheduleReminderEmailForActivityFormSchema = z.object({
    email: z.string({ required_error: '"Email" is required' }).email(),
    scheduledFor: z.custom<Dayjs>((val) => {
        if (!(val instanceof dayjs)) return false; // Basic instance check

        return true;
    }, "Enter valid time"),
});

type ScheduleReminderEmailForActivityFormType = z.infer<typeof scheduleReminderEmailForActivityFormSchema>;

const CustomTextField = styled((props: TextFieldProps) => {
    return <TextField variant="outlined" {...props} />;
})(({ theme }) => {
    const fontSize = theme.typography.body2;
    return {
        "& .MuiInputBase-input": {
            padding: "0.7rem",
            ...fontSize,
        },
        "& .MuiFormLabel-root": {
            ...fontSize,
            top: "-4px",
            '&[data-shrink="true"]': {
                top: "1px",
            },
        },
        "& .MuiInputBase-input::placeholder": {
            ...fontSize,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            ...fontSize,
        },
    };
});

function ScheduleReminderEmailForActivityForm({
    activityId,
    handleClose,
}: {
    activityId: string;
    handleClose: () => void;
}) {
    const dispatch = useAppDispatch();
    const [sendReminderEmail, { isLoading }] = useSendReminderMailMutation();
    const {
        control,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm<ScheduleReminderEmailForActivityFormType>({
        resolver: zodResolver(scheduleReminderEmailForActivityFormSchema),
        defaultValues: {
            email: "",
            scheduledFor: dayjs(),
        },
    });

    const onSubmit = (data: ScheduleReminderEmailForActivityFormType) => {
        const sendReminderEmailPayload: SendReminderEmailRequest = {
            activityId,
            emailId: data.email,
            scheduledFor: data.scheduledFor.toISOString(),
        };

        sendReminderEmail(sendReminderEmailPayload)
            .then(() => {
                dispatch(setSuccessNotification("Reminder email sent successfully"));
                reset();
                handleClose();
            })
            .catch((err) => {
                console.log({ err });
                handleException(err);
                dispatch(setErrorNotification("Error while sending reminder email please try again."));
            });
    };

    return (
        <Stack p={2} gap={1.7} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body1" fontWeight={500}>
                Send reminder email
            </Typography>
            <Stack component="form" gap={1.5}>
                <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                        <FormControl>
                            <CustomTextField
                                FormHelperTextProps={{
                                    sx: HELPER_TEXT_MARGIN,
                                }}
                                placeholder="Enter Email"
                                label="Email"
                                helperText={errors?.email?.message}
                                error={Boolean(errors?.email?.message)}
                                {...field}
                            />
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="scheduledFor"
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                ampm={false}
                                label="Schedule Send"
                                slotProps={{
                                    textField: {
                                        helperText: errors.scheduledFor?.message,
                                        FormHelperTextProps: {
                                            sx: HELPER_TEXT_MARGIN,
                                        },
                                        error: Boolean(errors?.scheduledFor?.message),
                                    },
                                }}
                                sx={dateTimePickerStyles}
                                {...field}
                            />
                        </LocalizationProvider>
                    )}
                />
                <CustomButton
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    sx={{ alignSelf: "flex-end", padding: "0.3rem 1.1rem" }}
                >
                    Submit
                </CustomButton>
            </Stack>
        </Stack>
    );
}

export function ScheduleReminderEmailForActivity({ activityId }: { activityId: string }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Experimental_CssVarsProvider>
            <IconButton
                onClick={handleClick}
                sx={{
                    padding: "0.3rem",
                }}
            >
                <ScheduleRoundedIcon fontSize="small" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-root": {
                        boxShadow: POPOVER_BOX_SHADOW,
                        minWidth: "300px",
                    },
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <ScheduleReminderEmailForActivityForm activityId={activityId} handleClose={handleClose} />
            </Popover>
        </Experimental_CssVarsProvider>
    );
}
