import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { IActionPayload, startAction, stopAction } from "../reducers/loaders.reducer";

import {
    addVirtualUserAlias,
    createCheckoutSession,
    deleteAlias,
    disconnectAlias,
    fetchAlias,
    fetchEmailCredit,
    fetchEmailEnrichmentCredit,
    fetchExclusionList,
    fetchLinkedinOutreachCredit,
    fetchOrganizationDetails,
    fetchPhoneEnrichmentCredit,
    fetchPlans,
    fetchProfileCreditInfo,
    fetchSmsOutreachCredit,
    fetchUserPlan,
    resetMyAccount,
    setAccountType,
    setCreditAlertPreference,
    setDefaultAlias,
    setEmailCredit,
    setEmailEnrichmentCredit,
    setEmailType,
    setExclusionList,
    setLinkedinOutreachCredit,
    setOrganizationDetails,
    setPhoneEnrichmentCredit,
    setPlans,
    setProfileCreditsInfo,
    setSmsOutreachCredit,
    setSmtpAuthorization,
    setUserPlan,
    setVirtualUser,
    setVirtualUserSignature,
    updateAlias,
} from "@/store/reducers/my-account/myAccount.reducer";
import {
    IAddVirtualUser,
    IAlias,
    ICheckoutPayload,
    IDefaultAlias,
    IDisconnectAlias,
    IEmailType,
    IOrgPlanResponse,
} from "@/store/reducers/my-account/myAccount.types";
import { editExclusionList } from "@/store/reducers/outreach/outreach.slice";

function* fetchUserPlanSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IOrgPlanResponse = yield call(new API().get, "/organization/get-credit");
        const totalCredits = response.data.totalEmailFetchCredit;
        const remainingCredits = response.data.remainingEmailFetchCredit;
        const currentCredits = totalCredits - remainingCredits;
        const remainingCreditsPercent = (remainingCredits / totalCredits) * 100;
        yield put(
            setUserPlan({
                totalCredits,
                remainingCredits,
                name: response.data.plan.name,
                createdAt: response.data.plan.createdAt,
                validTill: response.data.plan.validTill,
            })
        );
        yield put(
            setUserCredits({
                currentCredits,
                totalCredits,
                remainingCredits: remainingCreditsPercent,
            })
        );
    } catch (error) {
        console.error("**fetchUserPlanSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchPlansSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/payment/list-products");
        yield put(setPlans(response.data));
    } catch (error) {
        console.error("**fetchPlansSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchProfileCreditInfoSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/analytics/get-profile-credits", payload.creditPayload);
        yield put(setProfileCreditsInfo(response.data));
    } catch (error) {
        console.error("**fetchProfileCreditInfoSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchLinkedinOutreachCreditSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/analytics/get-linkedin-credits", payload.creditPayload);
        yield put(setLinkedinOutreachCredit(response.data));
    } catch (error) {
        console.error("**fetchLinkedinOutreachCreditSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchSmsOutreachCreditSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/analytics/get-sms-credits", payload.creditPayload);
        yield put(setSmsOutreachCredit(response.data));
    } catch (error) {
        console.error("**fetchSmsOutreachCreditSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchEmailCreditSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/analytics/get-email-credits", payload.creditPayload);
        yield put(setEmailCredit(response.data));
    } catch (error) {
        console.error("**fetchEmailCreditSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchOrganizationDetailsSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/organization/details");
        yield put(setOrganizationDetails(response.data));
    } catch (error) {
        console.error("**fetchOrganizationDetailsSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchEmailEnrichmentCreditSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/analytics/get-email-enrichment-credits", payload.creditPayload);

        if (payload?.creditPayload?.type === "EMAIL_TYPE") {
            // Transform the data to match the SplitChart component's data structure
            const transformedData = [
                {
                    name: "Total personal credits used",
                    totalCreditsUsed: response.data[0]?.totalPersonalCreditsUsed,
                    _id: 1,
                },
                {
                    name: "Total professional credits used",
                    totalCreditsUsed: response.data[0]?.totalProfessionalCreditsUsed,
                    _id: 2,
                },
            ];
            yield put(setEmailEnrichmentCredit(transformedData));
            return;
        }

        yield put(setEmailEnrichmentCredit(response.data));
    } catch (error) {
        console.error("**fetchEmailEnrichmentCreditSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchPhoneEnrichmentCreditSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/analytics/get-phone-enrichment-credits", payload.creditPayload);
        yield put(setPhoneEnrichmentCredit(response.data));
    } catch (error) {
        console.error("**fetchPhoneEnrichmentCreditSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* setCreditAlertPreferenceSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().post, "/analytics/credit-limit-notification", { type: payload.alertFor, value: payload.status });
        yield put(setSuccessNotification("Alert preference updated successfully"));
        yield put(fetchOrganizationDetails({ action: fetchOrganizationDetails.type }));
    } catch (error) {
        console.error("**setCreditAlertPreferenceSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred whien updating credit alert preference!"));
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* setEmailTypeSaga({ payload }: { payload: IActionPayload & IEmailType }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().post, "/v2/project/update-emailType", { type: payload.emailType });
        yield put(setSuccessNotification("Email preference updated successfully"));
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"));
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* setAccountTypeSaga({ payload }: { payload: IActionPayload & any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().post, "/v2/project/update-accountType", { type: payload.accountType });
        yield put(setSuccessNotification("Account type updated successfully"));
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"));
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addVirtualUserSaga(action: PayloadAction<IActionPayload & IAddVirtualUser>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/organization/virtual-user/add", action.payload);
        if (response?.message) {
            yield put(setSuccessNotification(response?.message));
            yield put(fetchAlias({ action: fetchAlias.type }));
        }
    } catch (error: any) {
        console.error("**setEmailTypeSagaError", { error });
        const message = error?.response?.data?.error;
        yield put(setErrorNotification(message));
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* setVirtualUserSignatureSaga(action: PayloadAction<IActionPayload & any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "/auth/update-user-info", action.payload);
        if (response?.message) {
            yield put(setSuccessNotification(response?.message));
            yield put(fetchAlias({ action: fetchAlias.type }));
        }
    } catch (error: any) {
        console.error("**setEmailTypeSagaError", { error });
        const message = error?.response?.data?.error;
        yield put(setErrorNotification(message));
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchAliasSaga(action: PayloadAction<IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();
        const user = get(state, "signin.user");
        const response = yield call(new API().get, "organization/members");
        const setData = response.data.members
            .filter((member) => member.virtualUser?.parentUserId === user?._id)
            .map(
                ({ _id, name, email, authorizedEmailAddress, emailAuthorized, createdAt, virtualUser, signature }) => ({
                    virtualUserId: _id,
                    alias: name,
                    availableEmail: email,
                    outboundEmail: authorizedEmailAddress,
                    authorizedStatus: emailAuthorized,
                    createdOn: createdAt,
                    default: virtualUser?.isDefault,
                    signature: signature,
                })
            );
        yield put(setVirtualUser(setData));
    } catch (error) {
        console.error("**fetchPlansSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* setDefaultAliasSaga(action: PayloadAction<IActionPayload & IDefaultAlias>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "organization/virtual-user/default", action.payload);
        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        }
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* setSmtpAuthorizationSaga(action: PayloadAction<{ payload: IActionPayload & any }>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/auth/update-smtp-auth", action.payload);
        yield put(setSuccessNotification(response?.message));
        yield put(fetchAlias());
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
        yield put(setErrorNotification("Error occurred!"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateAliasSaga(action: PayloadAction<{ payload: IActionPayload & IAlias }>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        yield call(new API().post, "/v2/project/update-alias", action.payload);
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* deleteAliasSaga(action: PayloadAction<IActionPayload & IDefaultAlias>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        yield call(new API().post, "/v2/project/delete-alias", action.payload);
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* disconnectAliasSaga(action: PayloadAction<IDisconnectAlias & IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { virtualUserId } = action.payload;
        const response = yield call(new API().get, `/auth/unauthorize-email?virtualUserId=${virtualUserId}`);
        yield put(setSuccessNotification(response?.message));
        yield put(fetchAlias());
    } catch (error) {
        console.error("**setEmailTypeSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* createCheckoutSessionSaga({ payload }: { payload: IActionPayload & ICheckoutPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/payment/create-checkout-session", {
            priceId: payload.default_price,
        });
        window.location.href = response.url;
    } catch (error) {
        console.error("**createCheckoutSessionSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* setExclusionListSaga(action: PayloadAction<IActionPayload & any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/workflow/save-exclusion-list", action.payload);
        // console.log("saving account list response", response.data.exclusionList);
        // yield put(editExclusionList({ exclusionListIds: response.data.exclusionList }));
        if (response?.message) {
            yield put(setSuccessNotification("Exclusion list saved successfully"));
        }
    } catch (error) {
        console.error("**setExclusionListSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchExclusionListSaga(action: PayloadAction<IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "organization/members-exclusion-list");
        if (response?.data?.exclusionList.length) {
            yield put(editExclusionList({ exclusionListIds: response.data.exclusionList }));
        }
    } catch (error) {
        console.error("**fetchExclusionListSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchUserPlan.type, fetchUserPlanSaga),
        // @ts-ignore
        yield takeLatest(fetchPlans.type, fetchPlansSaga),
        // @ts-ignore
        yield takeLatest(createCheckoutSession.type, createCheckoutSessionSaga),
        // @ts-ignore
        yield takeLatest(setEmailType.type, setEmailTypeSaga),
        // @ts-ignore
        yield takeLatest(setAccountType.type, setAccountTypeSaga),
        // @ts-ignore
        yield takeLatest(addVirtualUserAlias.type, addVirtualUserSaga),
        // @ts-ignore
        yield takeLatest(setDefaultAlias.type, setDefaultAliasSaga),
        // @ts-ignore
        yield takeLatest(setSmtpAuthorization.type, setSmtpAuthorizationSaga),
        // @ts-ignore
        yield takeLatest(fetchAlias.type, fetchAliasSaga),
        // @ts-ignore
        yield takeLatest(updateAlias.type, updateAliasSaga),
        // @ts-ignore
        yield takeLatest(deleteAlias.type, deleteAliasSaga),
        // @ts-ignore
        yield takeLatest(disconnectAlias.type, disconnectAliasSaga),
        // @ts-ignore
        yield takeLatest(setVirtualUserSignature.type, setVirtualUserSignatureSaga),
        // @ts-ignore
        yield takeLatest(fetchProfileCreditInfo.type, fetchProfileCreditInfoSaga),
        // @ts-ignore
        yield takeLatest(fetchLinkedinOutreachCredit.type, fetchLinkedinOutreachCreditSaga),
        // @ts-ignore
        yield takeLatest(fetchSmsOutreachCredit.type, fetchSmsOutreachCreditSaga),
        // @ts-ignore
        yield takeLatest(fetchEmailCredit.type, fetchEmailCreditSaga),
        // @ts-ignore
        yield takeLatest(fetchOrganizationDetails.type, fetchOrganizationDetailsSaga),
        // @ts-ignore
        yield takeLatest(fetchEmailEnrichmentCredit.type, fetchEmailEnrichmentCreditSaga),
        // @ts-ignore
        yield takeLatest(fetchPhoneEnrichmentCredit.type, fetchPhoneEnrichmentCreditSaga),
        yield takeLatest(setExclusionList.type, setExclusionListSaga),
        // @ts-ignore
        yield takeLatest(fetchExclusionList.type, fetchExclusionListSaga),
        // @ts-ignore
        yield takeLatest(setCreditAlertPreference.type, setCreditAlertPreferenceSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetMyAccount.type, CancelSagas, tasks);
}
