import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CSVReader from "./CSVReader/CSVReader";
import { setWarningNotification } from "./Notification/index.reducer";

import { ExclusionSelector } from "../easy-growth/components/BDBlendSearchCandidates/ExclusionSelector";
import useSearchState from "../hooks/useSearchState";
import { checkEasyGrowth } from "../pages/Signin/Signin.reducer";
import BaseModal from "../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../store/reducers/loaders.reducer";
import {
    addCustomCompaniesToAccountList,
    selectCompanyCSVUrl,
    selectCurrentCompany,
    setValues,
} from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";
import API from "../utils/API";
import handleException from "../utils/sentry";

interface CSVReaderModalType {
    onAdd: (data) => void;
    onClose: (e) => void;
    onUpload: (link: string[]) => void;
}

function CSVReaderModal({ onClose, onAdd, onUpload }: CSVReaderModalType) {
    const extractNumbers = (data) => {
        const numbers = [];

        data.forEach((item) => {
            item.forEach((subItem) => {
                const number = parseInt(subItem);
                if (!isNaN(number)) {
                    numbers.push(subItem);
                }
            });
        });

        return numbers;
    };

    async function uploadFile(file: File) {
        try {
            const formData = new FormData();
            formData.append("files", file);
            formData.append("projectId", "companyCSV");
            const response = await new API().post("upload/files", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (Array.isArray(response)) {
                onUpload(response);
            }
        } catch (error) {
            handleException(error);
            //eslint-disable-next-line no-console
            console.log(" csv upload failed ", error);
        }
    }

    return (
        <BaseModal onClose={onClose}>
            <CSVReader
                onDropFile={uploadFile}
                onSuccess={(response: any) => {
                    const data = response?.data;
                    const numbers = extractNumbers(data);
                    onAdd(numbers);
                }}
            />
        </BaseModal>
    );
}

const StyledBox = styled(Box)(() => ({
    ":hover": {
        border: "1px dashed #0891b2",
    },
}));

export function UploadCompanyList({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    // const { values } = useSelector(selectCompanyCSV);
    const { values: companyCSVUrlValues } = useSelector(selectCompanyCSVUrl);
    const { error: currentCompanyError } = useSelector(selectCurrentCompany);
    const [openCSVModal, setOpenCSVModal] = useState(false);
    const [accountListName, setAccountListName] = useState("");
    const [openAccountListModal, setOpenAccountListModal] = useState(false);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isCompaniesAvailable = true;
    const commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: 0.25,
    };

    const isAddingCustomCompaniesToAccountList = useSelector(checkIfLoading(addCustomCompaniesToAccountList.type));
    const handleSubmitAccountList = () => {
        if (!accountListName) {
            return dispatch(setWarningNotification("Please enter account list name"));
        }
        dispatch(
            addCustomCompaniesToAccountList({
                name: accountListName,
                successCallback() {
                    setOpenAccountListModal(false);
                },
            })
        );
    };
    const textColor = "rgba(0,0,0,0.6)";

    return (
        <>
            <Box {...commonStyle} justifyContent="space-between">
                {isEasyGrowth ? (
                    <Tooltip title={"Please add only company names in the csv"}>
                        <Button
                            fullWidth
                            variant="outlined"
                            size="small"
                            sx={{
                                justifyContent: "space-between",
                                textTransform: "none",
                                marginRight: "5px",
                            }}
                            onClick={() => setOpenCSVModal(true)}
                        >
                            Upload csv of companies
                            <ArrowRightIcon />
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title={"Please add only company names in the csv"}>
                        <StyledBox
                            {...commonStyle}
                            color={currentCompanyError ? "error" : textColor}
                            border="1px dashed"
                            borderColor={currentCompanyError ? "#D32F2F" : textColor}
                            borderRadius="4px"
                            p={0.2}
                            flex={1}
                            onClick={() => setOpenCSVModal(true)}
                            style={{
                                pointerEvents: "auto",
                                opacity: 1,
                                marginRight: "5px",
                            }}
                        >
                            <Tooltip title={"Upload pin codes (CSV)"}>
                                <span>
                                    <IconButton size="small">
                                        <UploadFileIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Typography color={textColor} fontSize={14}>
                                {companyCSVUrlValues?.length
                                    ? `CSV uploaded successfully`
                                    : "Upload csv list of companies"}
                            </Typography>
                        </StyledBox>
                    </Tooltip>
                )}

                {isCompaniesAvailable && (
                    <Box {...commonStyle}>
                        {!!companyCSVUrlValues?.length && (
                            <>
                                <ExclusionSelector
                                    status={companyCSVUrlValues[0].excluded ? "EXCLUDE" : "INCLUDE"}
                                    menuClickHandler={(e: any) => {
                                        dispatch(
                                            setValues({
                                                key: "companyCSVUrl",
                                                value: companyCSVUrlValues.map((item) => ({
                                                    ...item,
                                                    excluded: e === "EXCLUDE",
                                                })),
                                            })
                                        );
                                    }}
                                />

                                <Tooltip title="Download company list">
                                    <Link to={companyCSVUrlValues[0]?.value}>
                                        <IconButton>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>

                                <Tooltip title="Remove companies">
                                    <IconButton
                                        onClick={() => {
                                            dispatch(
                                                setValues({
                                                    key: "companyCSVUrl",
                                                    value: [],
                                                })
                                            );
                                        }}
                                    >
                                        <CancelIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Box>
                )}

                {openCSVModal && (
                    <CSVReaderModal
                        onAdd={() => {
                            setOpenCSVModal(false);
                        }}
                        onUpload={(link) => {
                            dispatch(
                                setValues({
                                    key: "companyCSVUrl",
                                    value: link.map((value) => ({ value, excluded: false })),
                                })
                            );
                        }}
                        onClose={() => {
                            setOpenCSVModal(false);
                        }}
                    />
                )}

                {openAccountListModal && (
                    <BaseModal
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 600,
                        }}
                        hideCloseButton
                        onClose={() => setOpenAccountListModal(false)}
                    >
                        <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                            Sure want to save as account list?
                        </Typography>

                        <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                            This list of uploaded and selected companies will be available in the account list section
                            for future use.
                        </Typography>
                        <TextField
                            required
                            size="small"
                            label="Account list name"
                            onChange={(e) => setAccountListName(e.target.value)}
                        />
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                            <Button
                                variant="outlined"
                                sx={{ borderRadius: "4px", fontSize: "12px" }}
                                onClick={() => setOpenAccountListModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: "4px", fontSize: "12px", border: "none" }}
                                onClick={handleSubmitAccountList}
                                disabled={isAddingCustomCompaniesToAccountList || !accountListName}
                            >
                                Save
                            </Button>
                        </Stack>
                    </BaseModal>
                )}
            </Box>
        </>
    );
}
