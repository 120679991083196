import { TCVSource } from "../../components/Navbar/CreateProject/CVSource";
import { csReviewStages, reviewStageObj } from "../../utils/Constants";
import { DetailedStageOptionKey } from "../all-candidates-reachout/all-candidates-reachout.types";
import { TEmailProviderConfig } from "../allProjects/allProjects.types";

import { optionType } from "@/store/reducers/filters/Filters.type";

export interface IGetProjectWorkflows {
    projectId: number;
    searchQuery?: string;
    paginated?: {
        start?: number;
        limit?: number;
    };
}

export interface GetSendosoGiftsAction {
    after?: string;
    price_gte?: number;
    price_lte?: number;
    ship_to_country_codes?: string[];
    text_search?: string;
}

export interface TisActionableClicked {
    4: boolean;
    5: boolean;
    PIPELINE: boolean;
    TO_BE_REVIEWED: boolean;
}

export interface IRootState {
    isErrorWhileFetchingStats: boolean;
    isErrorWhileFetchingCandidates: boolean;
    isErrorWhileFetchingAssociatedReachouts: boolean;
    marketResearchDataFetchStatus: string;
    marketResearchDataFetchPercentage: number;
    scrollToSkills: boolean;
    projectStats: IProjectStats | null;
    projectWorkflows: IProjectWorkflows[];
    workflowCount: number;
    showByRelevance: boolean;
    showByMaxExperience: boolean;
    showByMinExperience: boolean;
    showByPersonalEmails: boolean;
    showByMostRecent: boolean;
    projectFilters: IStageFilters;
    candidates: ICandidate[];
    projectCandidates: Record<number, ICandidate[]>;
    setAllProductsLoaded: boolean;
    totalCandidates: number;
    salesNavProfilesCount?: number;
    totalSearchCount: number;
    selectedCandidates: ISelectedCandidateId[];
    candidateCurrentPage: number;
    selectAllCandidates: boolean;
    emailStats: IEmailStats | null;
    linkedInStats: ILinkedInStats | null;
    pageSize: number;
    sendosoGifts: any;
    showSrapeCandidateModal: boolean;
    liCookie: string;
    sentGiftsCandidates: any;
    relevenceToggle: boolean;
    searchQuery: string;
    showCandidateSearch: boolean;
    candidateWorkflows: candidateWrokflowType[];
    gridViewColumns: optionType[];
    requestId: string;
    singleCandidateInsightGenerating: null | string;
    emailEnrich?: boolean;
    phoneEnrich?: boolean;
    linkedInEnrich?: boolean;
    marketResearchData?: any;
    twilloToken?: string;
    twilloCalls?: any;
    blockList?: any;
    candidateDetails?: any;
    twilloPowerCallListCreate?: any;
    twilloPowerCallCandidateList?: any;
    updateBulkCallCampaignStatus?: any;
    isActionableClicked?: TisActionableClicked;
}

export interface candidateWrokflowsListType {
    displaySteps: displayStepsType[];
    steps: any;
    emailFrom: string;
    triggerBy: string;
    triggerAt: string;
}

export interface displayStepsType {
    eventName: string;
}

export interface candidateWrokflowType {
    email: {
        index: number;
        projectId: number;
        success: boolean;
    };
    followupEmail: {
        index: number;
        projectId: number;
        success: boolean;
    };
    linkedin: {
        index: number;
        connReq: boolean;
        projectId: number;
        status: number;
        inmail: boolean;
    };
    sms: {
        index: number;
        projectId: number;
        body: boolean;
    };
    triggerBy: string;
    triggerAt: string;
}

export interface IEmailStats {
    emailSent: number;
    openRate: number;
    clickRate: number;
    responseRate: number;
}

export interface ILinkedInStats {
    sent: number;
    accepted: number;
    inMail: number;
}

export type IProjectStage =
    | "TO_BE_REVIEWED"
    | "NO_RATING"
    | "PIPELINE"
    | "CONTACTED"
    | "RESPONDED"
    | "POSITIVE_RESPONSE"
    | "SHORTLISTED++"
    | "SHORTLISTED"
    | "REJECTED"
    | "NOT_INTERESTED"
    | "ON_HOLD"
    | "COMPLETED"
    | "INCOMPLETE"
    | "CV_AVAILABLE"
    | "EXCLUDED"
    | "NOT_EXCLUDED"
    | "personalEmail"
    | "professionalEmail"
    | "bothPersonalProfessional"
    | "hasPhoneNumber"
    | "noPhoneNumber"
    | "noEmails"
    | "5"
    | "4"
    | "3"
    | "2"
    | "1"
    | "0"
    | "-1"
    | "outreachConnectionStatus.NOT_SENT,FAILED"
    | "outreachConnectionStatus.TRIGGERED"
    | "outreachConnectionStatus.SENT_WITH_NOTE,SENT_WITHOUT_NOTE"
    | "outreachConnectionStatus.ACCEPTED_WITH_NOTE,ACCEPTED_WITHOUT_NOTE"
    | "outreachConnectionStatus.MESSAGE_SENT"
    | "outreachConnectionStatus.RESPONDED"
    | "outreachInMailStatus.NOT_SENT,FAILED"
    | "outreachInMailStatus.TRIGGERED"
    | "outreachInMailStatus.SENT"
    | "outreachInMailStatus.OPENED"
    | "outreachEmailStatus.NOT_SENT,FAILED"
    | "outreachEmailStatus.BOUNCED,NOT_FOUND"
    | "outreachEmailStatus.TRIGGERED"
    | "outreachEmailStatus.SENT"
    | "outreachEmailStatus.OPENED"
    | "outreachEmailStatus.RESPONDED"
    | "outreachSMSStatus.NOT_SENT"
    | "outreachSMSStatus.OPTED_OUT"
    | "outreachSMSStatus.NOT_FOUND"
    | "outreachSMSStatus.UNDELIVERED,FAILED"
    | "outreachSMSStatus.QUEUED,ACCEPTED"
    | "outreachSMSStatus.SENT,DELIVERED"
    | "outreachSMSStatus.DELIVERED"
    | "outreachSMSStatus.RECEIVED"
    | "outreachFollowupEmailStatus.NOT_SENT"
    | "outreachFollowupEmailStatus.TRIGGERED"
    | "outreachFollowupEmailStatus.FAILED,BOUNCED,NOT_FOUND"
    | "outreachFollowupEmailStatus.SENT"
    | "outreachFollowupEmailStatus.OPENED"
    | "outreachFollowupEmailStatus.RESPONDED"
    | "csReviewStage.ACCEPTED"
    | "csReviewStage.REJECTED"
    | "csReviewStage.PENDING"
    | "source.AP"
    | "source.BS"
    | "externalSource.WELCOME_PAGE"
    | "FROM_ATS"
    | "SENT_TO_ATS"
    | "NOT_SENT_TO_ATS"
    | "feedback"
    | "exported.TRUE"
    | "exported.FALSE"
    | "OPEN_TO_WORK"
    | "linkedinUrlStatus.SUCCESS"
    | "linkedinUrlStatus.FAILED";

export type TWorkflowStatus = "PENDING" | "IN_PROGRESS" | "COMPLETED" | "CANCELLED" | "FAILED" | "STOPPED" | "RESUME";

export interface IProjectWorkflows {
    _id: string;
    workflowLogId: string;
    name: string;
    email: string;
    authorizedEmailAddress: string;
    numberOfCandidates: number;
    triggeredBy: number;
    triggeredAt: string;
    status: TWorkflowStatus;
    noOfSteps: number;
    steps: Step[];
    nextStep: number;
    nextTriggerOn: string;
    bounced: number;
    clicked: number;
    contacted: number;
    contactedArray: string[];
    candidateIds: string[];
    opened: number;
    smsSent: number;
    liConnectionRequestCount: number;
    liInmailCount: number;
    emailCount: number;
    followupEmailCount?: number;
    scheduledFor: string;
    parentSteps?: Step[];
}

export interface Step {
    eventEnum: number;
    eventName: string;
    eventBody: EventBody;
    status?: string;
    _id: string;
    isOpen?: boolean;
}

export interface EventBody {
    subject?: string;
    body?: string;
    hours?: string;
    weeks?: string;
    days?: string;
    mins?: string;
    duration?: number;
    inmailBody?: string;
    inmailStatus?: boolean;
    inmailSubject?: string;
    connReqStatus?: boolean;
}

export type CS_STATUS = keyof typeof csReviewStages;

export interface IMoveToPayload {
    candidateIds: string[];
    stage?: IProjectStage;
    shortlist1?: boolean;
    projectId: number;
    type?: string;
    outreachStatus?: OutReachStatus;
    csReviewStage?: CS_STATUS;
}

export type ICandidateSkills = string[];

export type IStageFilters = {
    [key: string]: any;
    TO_BE_REVIEWED: boolean;
    NO_RATING: boolean;
    PIPELINE: boolean;
    CONTACTED: boolean;
    RESPONDED: boolean;
    POSITIVE_RESPONSE: boolean;
    SHORTLISTED: boolean;
    REJECTED: boolean;
    NOT_INTERESTED: boolean;
    ON_HOLD: boolean;
    COMPLETED: boolean;
    INCOMPLETE: boolean;
    CV_AVAILABLE: boolean;
    EXCLUDED: boolean;
    NOT_EXCLUDED: boolean;
    personalEmail: boolean;
    professionalEmail: boolean;
    bothPersonalProfessional: boolean;
    noPhoneNumber: boolean;
    hasPhoneNumber: boolean;
    noEmails: boolean;
    5: boolean;
    4: boolean;
    3: boolean;
    2: boolean;
    1: boolean;
    0: boolean;
    "-1": boolean;
    "outreachConnectionStatus.NOT_SENT,FAILED": boolean;
    "outreachConnectionStatus.TRIGGERED": boolean;
    "outreachConnectionStatus.SENT_WITH_NOTE,SENT_WITHOUT_NOTE": boolean;
    "outreachConnectionStatus.ACCEPTED_WITH_NOTE,ACCEPTED_WITHOUT_NOTE": boolean;
    "outreachConnectionStatus.MESSAGE_SENT": boolean;
    "outreachConnectionStatus.RESPONDED": boolean;
    "outreachInMailStatus.NOT_SENT,FAILED": boolean;
    "outreachInMailStatus.TRIGGERED": boolean;
    "outreachInMailStatus.SENT": boolean;
    "outreachInMailStatus.OPENED": boolean;
    "outreachEmailStatus.NOT_SENT,FAILED": boolean;
    "outreachEmailStatus.BOUNCED,NOT_FOUND": boolean;
    "outreachEmailStatus.TRIGGERED": boolean;
    "outreachEmailStatus.SENT": boolean;
    "outreachEmailStatus.OPENED": boolean;
    "outreachEmailStatus.RESPONDED": boolean;
    "outreachFollowupEmailStatus.RESPONDED": boolean;
    "outreachSMSStatus.NOT_SENT": boolean;
    "outreachSMSStatus.OPTED_OUT": boolean;
    "outreachSMSStatus.NOT_FOUND": boolean;
    "outreachSMSStatus.UNDELIVERED,FAILED": boolean;
    "outreachSMSStatus.QUEUED,ACCEPTED": boolean;
    "outreachSMSStatus.SENT,DELIVERED": boolean;
    "outreachSMSStatus.DELIVERED": boolean;
    "outreachSMSStatus.RECEIVED": boolean;
    "outreachFollowupEmailStatus.NOT_SENT": boolean;
    "outreachFollowupEmailStatus.TRIGGERED": boolean;
    "outreachFollowupEmailStatus.FAILED,BOUNCED,NOT_FOUND": boolean;
    "outreachFollowupEmailStatus.SENT": boolean;
    "outreachFollowupEmailStatus.OPENED": boolean;
    "csReviewStage.ACCEPTED": boolean;
    "csReviewStage.REJECTED": boolean;
    "csReviewStage.PENDING": boolean;
    "source.AP": boolean;
    "source.BS": boolean;
    "externalSource.WELCOME_PAGE": boolean;
    FROM_ATS: boolean;
    SENT_TO_ATS: boolean;
    NOT_SENT_TO_ATS: boolean;
    feedback: boolean;
    ATS: boolean;
    OTHERS: boolean;
    OPEN_TO_WORK: boolean;
    "linkedinUrlStatus.SUCCESS": boolean;
    "linkedinUrlStatus.FAILED": boolean;
};

export type ReviewStage = keyof typeof reviewStageObj;

export enum ConnectionRequestStatus {
    NOT_SENT = "NOT_SENT",
    TRIGGERED = "TRIGGERED",
    FAILED = "FAILED",
    SENT_WITH_NOTE = "SENT_WITH_NOTE",
    SENT_WITHOUT_NOTE = "SENT_WITHOUT_NOTE",
    ACCEPTED_WITH_NOTE = "ACCEPTED_WITH_NOTE",
    ACCEPTED_WITHOUT_NOTE = "ACCEPTED_WITHOUT_NOTE",
    MESSAGE_SENT = "MESSAGE_SENT",
    MESSAGE_FAILED = "MESSAGE_FAILED",
}

export enum InEmailStatus {
    NOT_SENT = "NOT_SENT",
    TRIGGERED = "TRIGGERED",
    FAILED = "FAILED",
    SENT = "SENT",
    OPENED = "OPENED",
}

export enum EmailStatus {
    NOT_SENT = "NOT_SENT",
    TRIGGERED = "TRIGGERED",
    FAILED = "FAILED",
    NOT_FOUND = "NOT_FOUND",
    SENT = "SENT",
    OPENED = "OPENED",
    RESPONDED = "RESPONDED",
    BOUNCED = "BOUNCED",
}

export enum SMSStatus {
    ACCEPTED = "ACCEPTED",
    NOT_SENT = "NOT_SENT",
    OPTED_OUT = "OPTED_OUT",
    NOT_FOUND = "NOT_FOUND",
    FAILED = "FAILED",
    UNDELIVERED = "UNDELIVERED",
    QUEUED = "QUEUED",
    SENT = "SENT",
    DELIVERED = "DELIVERED",
    RECEIVED = "RECEIVED",
}

export enum FollowUpEmailStatus {
    NOT_SENT = "NOT_SENT",
    TRIGGERED = "TRIGGERED",
    FAILED = "FAILED",
    NOT_FOUND = "NOT_FOUND",
    SENT = "SENT",
    OPENED = "OPENED",
    RESPONDED = "RESPONDED",
    BOUNCED = "BOUNCED",
}

export type OutReachStatus = {
    connection?: ConnectionRequestStatus;
    inMail?: InEmailStatus;
    email?: EmailStatus;
    followUpEmail?: FollowUpEmailStatus;
    sms?: SMSStatus;
};

type Keys = "name" | "institute" | "duration" | "credentials" | "_id";

export type Certificate = Record<Partial<Keys>, string>;

export type FieldMatch = "employeeCount" | "industry" | "role" | "country" | "location";

export type TypeMatch = "mustNot" | "must" | "preferred" | "empty";
export type TypeMatchNotFound = "valueFound" | "valueNotFound";
export type LeadScoreCriteria = {
    fieldMatch: FieldMatch;
    typeMatch: TypeMatch;
    valueMatch: string;
    notMatch?: boolean;
    valueNotFound?: boolean;
    valueArray?: string[];
};

export type LeadScore = {
    score: {
        rate: number;
        criteria: LeadScoreCriteria[];
        label: string;
        value: number;
    };
};

export type GsMetaData = {
    scholarUrl: string;
    citations: any;
    relevanceScore: any;
    degreeType: string;
    hIndex: any;
    i10Index?: any;
    name?: string;
    homePage?: any;
    papers?: ResearchPaper[];
    Highlight?: string;
    rating?: any;
    labels?: string[];
};

export type GitMetaData = {
    html_url: string;
    login?: string;
    bio?: string;
    location?: string;
    email?: string;
    company?: string;
    name?: string;
    avatar_url?: string;
    userScore?: any;
    repos?: Repository[];
};

export type GreenhouseMetaData = {
    applications: any;
    id: any;
};

export type CandidateLanguage = { name: string; proficiency?: string; _id: string };

type Healthcare = {
    npi_number?: string;
    license_number?: string;
    credentials?: string;
    specialization?: string;
};

export interface ICandidate {
    healthcareData?: Healthcare;
    feedback?: object;
    cpSource?: string;
    cvSource?: any;
    accountHolders?: string[];
    csReviewStage?: CS_STATUS;
    certificates?: Certificate[];
    leadScore?: LeadScore;
    outreachStatus?: OutReachStatus;
    detailedStage?: DetailedStageOptionKey;
    reviewStage: ReviewStage;
    shortlist1?: boolean;
    _id: string;
    name: string;
    profileUrl: string;
    source: string;
    ratingOfLinkedin?: any;

    talentQuestionResponses?: any;

    title: string;
    location: string;
    profileImage?: string;
    description: string;
    email: string[];
    phone: string[];
    dob: string;
    resume?: string;
    scrapStatus: string;
    emailStatus: string;
    isDeleted: string;
    education: Education[];
    experience: Experience[];
    gsMetaData?: GsMetaData;
    gitMetaData?: GitMetaData;
    greenhouseMetaData?: GreenhouseMetaData;
    skills: Skill[];
    languages?: CandidateLanguage[];
    createdAt: string;
    updatedAt: string;
    __v: number;
    additionalInfo: AdditionalInfo;
    canShowEmail: boolean;
    joiningStatus: string;
    visa: string;
    totalExperience: TotalExperience;
    projectId: number;
    stage: string;
    userId: number;
    professional_email: string[];
    courses: any[];
    roleWarning?: RoleWarning;
    relevancy_skills: any[];
    linkedInExp: TotalExperience;
    isSample?: boolean;
    starRating: StarRating;
    primaryEmail?: string;
    conversationCount: {
        connectionCount: number;
        emailCount: number;
        inMailCount: number;
    };
    exclusionRating?: ExclusionRating;
    extractedSkills?: string[];
    vettingFixed: boolean;
    resumeLibraryId?: string;
    resumeLibraryCandidateHash?: string;
    minSalary?: number;
    maxSalary?: number;
    desiredJobTitle?: string;
    latestJobTitle?: string;
    retention?: {
        seenAt: string;
        capturedURL: string;
    };
    emailResponseStats?: any;
    openToWork?: any;
    linkedinUrlStatus?: string;
}

export interface StarRatingDescription {
    text: string;
    intent: number;
    criterion: string;
    tag?: "GOOD" | "MUST";
    delete?: boolean;
    _id?: string;
    type?: string;
    id?: string;
    appendText?: string;
}

export interface StarRating {
    label: string;
    value: number;
    updatedAt: string;
    description: Array<StarRatingDescription>;
}

export interface ExclusionRating {
    value: number;
    reason: string;
}

export interface RoleWarning {
    role: string;
    months: string;
}

export interface Education {
    institute: string;
    instituteLogo: string;
    instituteUrl: string;
    course: string;
    description: any;
    duration: string;
    _id: string;
}

export interface Experience {
    position: string;
    org: string;
    orgLogo: string;
    orgUrl: string;
    location: string;
    duration: string;
    description: string;
    totalMonths: number;
    _id: string;
}

export interface ResearchPaper {
    authors: string;
    citations: string;
    journal: string;
    paper_score: string;
    title: string;
    url: string;
    year: any;
    description: string;
}

export interface Repository {
    description: string;
    forks: string;
    language: string;
    last_updated: string;
    name: string;
    repoScore: any;
    stars: string;
}

export interface Skill {
    name: string;
    _id: string;
}

export interface AdditionalInfo {
    joiningStatus: string;
    visa: string;
    totalExperience: TotalExperience;
    updatedOn: number;
}

export interface TotalExperience {
    label: string;
    value: number;
}

export interface ICandidateEmailResponse {
    data: ICandidateEmailData;
    message: string;
    firstEmailNA: "Initial" | "Mid" | "Final";
    code: 200 | 403;
    error?: string;
}

export interface ICandidateEmailData {
    showEmail: Array<ICandidateEmail>;
    hiddenEmail: Array<ICandidateEmail>;
    warning: boolean;
    type: "NORMAL" | "SUPERADMIN";
}

export interface ICandidateEmail {
    _id: string;
    email: string[];
    personal_email: string[];
    professional_email: string[];
}

export interface IRelevantCandPayload {
    projectId?: string;
    showByRelevance: boolean;
}

export interface IMinExpPayload {
    projectId?: string;
    showByMinExperience: boolean;
}

export interface IMaxExpPayload {
    projectId?: string;
    showByMaxExperience: boolean;
}

export interface IMostRecentPayload {
    projectId?: string;
    showByMostRecent: boolean;
}

export interface IPersonalEmailPayload {
    projectId?: string;
    showByPersonalEmails: boolean;
}

export interface IProjectFiltersPayload {
    projectId?: string;
    projectFilters: IStageFilters;
    onSuccess?: () => void;
}

export interface IProjectFiltersRequestPayload {
    filterBy?: string[];
    sortBy?: string;
    search?: string;
    candidateIds?: string[];
    requestId?: string;
}

export interface IProjectStats {
    totalCandidate: number;
    contacted: number;
    responded: number;
    gender?: {
        male: number;
        female: number;
        others: number;
        unknown: number;
    };
    cvSource: string[];
    accountHolders: string[];
}

export type IProjectStatus = "ACTIVE" | "ON_HOLD" | "CLOSED";

export interface EmailTemplate {
    subject: string;
    body: string;
}

export interface IMarketing {
    logo?: string;
    video?: string;
    videoLink?: string;
    overview?: string;
}

export interface IProject {
    emailTemplate: EmailTemplate;
    autoSource: boolean;
    _id: string | number;
    name: string;
    location: ILocation[];
    postalCode: string[];
    postalCodeCSVUrl: string;
    companyCSVUrl: string;
    excludedCompanyCSVUrl: string;
    visibility: string;
    createdBy: {
        _id?: number;
        name: string;
        email: string;
    };
    belongsTo: number;
    candidates: string[];
    search: any[];
    jobTitle: string[];
    pastJobTitle?: string[];
    skills: string[];
    education: string[];
    experience: string[];
    industry: IIndustry[];
    isFixedSearch: boolean;
    currentCompanyHeadCount: IFilterObj[];
    currentCompanyExperience: IFilterObj[];
    currentPositionExperience: IFilterObj[];
    companyHeadQuarterLocation: IFilterObj[];
    school: IFilterObj[];
    degree: { label: string }[];
    profileLanguage: IFilterObj[];
    companyType: IFilterObj[];
    ipoStatus: IFilterObj[];
    fundingType: IFilterObj[];
    ipoDateRange: string[];
    fundingTotalAmountRange: string[];
    lastFundingTotalAmountRange: string[];
    lastFundingDateRange: string[];
    revenueRange: string[];
    seniorityLevel: IFilterObj[];
    // excluded
    excludedLocation: ILocation[];
    excludedJobTitle: string[];
    excludedCurrentCompany: string[];
    excludedIndustry: IIndustry[];
    excludedRoleFunction: string[];
    excludedPastJobTitle: string[];
    excludedPastCompany: string[];
    excludedCompanyHeadQuarterLocation: IFilterObj[];
    excludedSchool: IFilterObj[];
    excludedSeniorityLevel: IFilterObj[];
    //
    course: string[];
    projectStatus: string;
    visa: boolean;
    active: boolean;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
    dashboardStatus: IProjectStatus;
    netNew?: number;
    phoneNoCount?: number;
    emailAddCount?: number;
    source: string;
    totalCandidates: number;
    creditsRewarded: boolean;
    generateInsightsStatus: "IN_PROGRESS" | "COMPLETED" | null;
    associatedConversation?: string;
    vettingCriteria?: IVettingCriteria[];
    jd?: string;
    exclusionLists?: Array<{
        label: string;
        value: string;
    }>;
    logo: string;
    video: string;
    overview: string;
    onlyPersonalEmail?: boolean;
    linkedinResponded: number;
    engaged: number;
    notinterested: number;
    interested: number;
    currentCompany?: string[];
    pastCompany?: string[];
    jobFunction?: string;
    roleFunction?: IRoleFunction[];
    outreachChannel?: string;
    objective?: string;
    insightProgress: {
        current: number;
        total: number;
    };
    seniority?: ISeniority[];
    companyHeadcount?: ICompanyHeadcount[];
    scrapDetailedEducation?: boolean;
    scrapDetailedLanguages?: boolean;
    scrapDetailedCertifications?: boolean;
    scrapDetailedSkills?: boolean;
    useCustomVetting?: boolean;
    marketing?: IMarketing;
    jazzHRMetaData?: {
        jobId: string;
        jobName: string;
    };
    // search filters
    resumeLibrary?: {
        jobTypes: string[];
        education: string[];
        location: string;
        distance: string;
        minSalary: number;
        maxSalary: number;
        experience: string[];
        activeWithin: string;
        skills: string;
    };
    rocketReach?: {
        rocketReachJobTitle: string[];
        rocketReachDegree: string[];
        rocketReachSkills: string[];
        rocketReachContactMethod: string[];
        rocketReachContactMethodType: string;
        rocketReachRadius: string;
        rocketReachSpecialization: string[];
        rocketReachExperienceRange: string[];
        rocketReachLocation: string;

        // location: { type: [String] },
        // jobTitle: { type: [String] },
        // radius: { type: String },
        // title: { type: String },
        // degree: { type: [String] },
        // skills: { type: [String] },
        // specialization: { type: [String] },
        // credentials: { type: [String] },
        // experienceRange: { type: [String] },
        // contactMethodType: { type: String },
        // contactMethod: { type: [String] },
        // experience: { type: [String] },
    };
    monster?: {
        city: string;
        state: string;
        radius: string;
        jobTitle: string[];
        degree: string[];
        skills: string[];
        experienceRange: string[];
        location: string[];
    };
    advanceCompanySearch?: {
        total?: string;
        currency?: string;
        minRevenue?: string;
        maxRevenue?: string;
        departmentCount?: string;
        departmentGrowth?: string;
        noOfFollowers?: string[];
        fortune?: string[];
        minDepartmentHeadCount?: string;
        maxDepartmentHeadCount?: string;
        minDepartmentHeadCountGrowth?: string;
        maxDepartmentHeadCountGrowth?: string;
        mincompanyHeadGrowth?: string;
        maxcompanyHeadGrowth?: string;
        headquartersGeography?: IFilterObj[];
        excludedHeadquarterGeography?: IFilterObj[];
        technologiesUsed?: IFilterObj[];
        companyHeadcount?: IFilterObj[];
        industryCompany?: IIndustry[];
        excludedIndustryCompany?: IIndustry[];
        jobOpportunity?: string;
        recentActivities?: string[];
    };
    jdTitle: string;
    jdDescription: string;
    investorName: string[];
    rb2bMetaData?: {
        isPojectRB2B: boolean;
        isProjectConnectedToRB2B: boolean;
    };
}

export interface IVettingCriteria {
    _id: string;
    id: string;
    type: string;
    text: string;
    tag: "MUST" | "GOOD";
}

export interface IFilterObj {
    label: string;
    value: string;
    _id: string;
}

export type IIndustry = IFilterObj;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProjectStatsPayload {}

export interface CandidateProjectMap {
    _id: string;
    candidateId: string;
    projectId: number;
    __v: number;
    createdAt: string;
    relevancyStatus: string;
    stage: string;
    updatedAt: string;
    userId: number;
    relevancy: Relevancy;
}

export interface Relevancy {
    total_score: number;
    details: Details;
}

export interface Details {
    education_score: EducationScore;
    location: Location2;
    experience: Experience2;
    immediately_available: ImmediatelyAvailable;
    visa: Visa;
    skills: Skills;
    title_score: TitleScore;
}

export interface EducationScore {
    score: number;
    valid_education: any[];
    education_status: number;
}

export type ILocation = IIndustry;

export type IRoleFunction = IIndustry;

export type ISeniority = IIndustry;

export type ICompanyHeadcount = IIndustry;

export interface Location2 {
    score: number;
    JD_location: string;
    Profile_location: string;
    dist: string;
}

export interface Experience2 {
    score: number;
    exact_experience: number;
    experience_string: string;
    "total_non-overlapping_experience": number;
    experience_status: number;
}

export interface ImmediatelyAvailable {
    score: number;
    available_status: number;
}

export interface Visa {
    score: number;
    visa_truth: number;
    valid_visa: ValidVisa;
    visa_status: number;
}

export interface ValidVisa {
    H1B: number;
    Greencard: number;
    Citizen: number;
    OTP: number;
    STEM_OTP: number;
}

export interface Skills {
    score: number;
    matches: Matches;
    skill_status: number;
}

export interface Matches {
    "Direct Sales": Sale[];
}

export interface Sale {
    Profile_skill: string;
    JD_Profile_intersection: string[];
}

export interface TitleScore {
    score: number;
    Metadata: Metadata;
    title_status: number;
}

export interface Metadata {
    Intersection_Classes: any[];
}

export type IProjectExperience = "0-1" | "1-2" | "3-5" | "6-10" | "10";

export type ISelectedCandidatePayload = {
    candidateId: string[];
    deselectAll?: number;
    pageNo?: number;
    canSelectAll?: boolean;
};

export type ISelectedCandidateId = {
    candidateId: string;
    pageNo: number;
};

export interface IAddToWorkflow {
    workflowTemplateId: string;
    scheduledFor: string;
    project: number;
    candidate: string[];
    selectAll?: boolean;
    hyperPersonalized?: boolean;
    bcc: string[];
    unsubscribeEmail: boolean;
}

export type IAddToWorkflowPayload = {
    onSuccess?: ({ workflowId, workflowCredits }: { workflowId: string; workflowCredits: number }) => void;
    action: string;
} & IAddToWorkflow;

export interface ICopyToProjectPayload {
    projectId: string;
    candidateIds: string[];
    selectAll?: boolean;
}

export type IProjectView = "list" | "gridA" | "gridB" | "compact-list";

export interface ICandidateEmailPayload {
    candidateIds: string[];
}

export interface ExtractEmails {
    projectId: string;
    emailProviderConfig: TEmailProviderConfig;
}

export interface ExtractPhone {
    emailProviderConfig: TEmailProviderConfig;
}

export interface IGetProjectCandidatesResponse {
    data: {
        jobScraperMsgForUser: any;
        candidates: ICandidate[];
        projectStatus: string;
        totalCandidates: number;
        totalSearchCount: number;
        requestId: string;
        salesNavProfilesCount?: number;
        enrichmentProgress?: {
            phone?: string[];
            email?: string[];
        };
        linkedinEnrichmentProgress?: string;
        generateInsightsStatus?: string;
        insightProgress?: {
            current: number;
            total: number;
        };
    };
    message: string;
}

export interface IShowByRelevanceResponse {
    message: string;
    candidateData: ICandidate[];
}

export interface ISSEEventPayload extends ICandidate {
    event: number;
    message: string;
    scrapId: string;
    projectStatus: string;
    processCompleted: boolean;
    sourceId: string;
    projectData?: IProject;
}

export interface IBulkUploadCandCVsPayload {
    projectId: string;
    file: File[];
    cvSource: TCVSource;
    onSuccess: () => void;
}

export interface ILinkedinUrlUploadPayload {
    projectId: string;
    url: string[];
    cvSource: TCVSource;
    onSuccess: () => void;
}

export interface IRefreshCandidateRatingPayload {
    projectId: string;
    candidateIds: string[];
    selectAll?: boolean;
    onSuccess?: () => void;
    allCandidates?: boolean;
}

export interface IEditCandidatePayload {
    name: string;
    linkedIn: string;
    resume?: File;
    candidateId: string;
    emails: string[];
    phone: string[];
    onSuccess: () => void;
}

export interface IAddCandidateFeedbackPayload {
    candidateId: string;
    // projectId: number;
    feedback: {
        selectedOptions: string[];
        comments: string;
    };
    onSuccess: () => void;
}

export interface IMarketResearchPayload {
    titles: string[];
    onSuccess: () => void;
    projectId?: number;
    candidateId?: any[];
}

export type getCandidateWorkflowsType = {
    candidateId: string;
    projectId: number;
};

export const gridViewColumnsOption = [
    {
        label: "Company",
        value: "org",
    },
    {
        label: "Industry",
        value: "industry",
    },
    {
        label: "Job Title",
        value: "position",
    },
    {
        label: "Country",
        value: "location",
    },
    {
        label: "Company Size",
        value: "companySize",
    },
];

export const connectReqStatus = {
    TRIGGERED: "Request in queue",
    FAILED: "Request unsuccessful",
    SENT_WITH_NOTE: "Request sent with note",
    SENT_WITHOUT_NOTE: "Request sent without note",
    ACCEPTED_WITH_NOTE: "Request accepted",
    ACCEPTED_WITHOUT_NOTE: "Request accepted",
    MESSAGE_SENT: "Reminder message sent",
    MESSAGE_FAILED: "Request unsuccessful",
    RESPONDED: "Reply received on connect request",
};
export const inMailStatusString = {
    TRIGGERED: "InMail triggered",
    FAILED: "InMail not sent",
    SENT: "InMail sent",
    OPENED: "InMail responded",
};

type Connect_Request_Tags =
    | "NOT_SENT"
    | "TRIGGERED"
    | "FAILED"
    | "SENT_WITH_NOTE"
    | "SENT_WITHOUT_NOTE"
    | "ACCEPTED_WITH_NOTE"
    | "ACCEPTED_WITHOUT_NOTE"
    | "MESSAGE_SENT"
    | "MESSAGE_FAILED"
    | "RESPONDED";

type Values = {
    client: string;
    superAdmin: string;
};

type IConnectionRequestLabels = Record<Connect_Request_Tags, Values>;

export const connectionRequestLabels: IConnectionRequestLabels = {
    NOT_SENT: {
        client: "",
        superAdmin: "",
    },
    TRIGGERED: {
        client: "Request in queue",
        superAdmin: "Request in queue",
    },
    FAILED: {
        client: "",
        superAdmin: "Request unsuccessful",
    },
    SENT_WITH_NOTE: {
        client: "Request sent with note",
        superAdmin: "Request sent with note",
    },
    SENT_WITHOUT_NOTE: {
        client: "Request sent without note",
        superAdmin: "Request sent without note",
    },
    ACCEPTED_WITH_NOTE: {
        client: "Request accepted",
        superAdmin: "Request accepted",
    },
    ACCEPTED_WITHOUT_NOTE: {
        client: "Request accepted",
        superAdmin: "Request accepted",
    },
    RESPONDED: {
        client: "Reply received on connect request",
        superAdmin: "Reply received on connect request",
    },
    MESSAGE_SENT: {
        client: "Reminder message sent",
        superAdmin: "Reminder message sent",
    },
    MESSAGE_FAILED: {
        client: "",
        superAdmin: "Reminder message failed",
    },
};

type IN_MAIL_TAGS = "NOT_SENT" | "TRIGGERED" | "FAILED" | "SENT" | "OPENED";

type IInMailLabels = Record<IN_MAIL_TAGS, Values>;

export const inMailLabels: IInMailLabels = {
    NOT_SENT: {
        client: "",
        superAdmin: "",
    },
    TRIGGERED: {
        client: "InMail triggered",
        superAdmin: "InMail triggered",
    },
    FAILED: {
        client: "",
        superAdmin: "InMail not sent",
    },
    SENT: {
        client: "InMail sent",
        superAdmin: "InMail sent",
    },
    OPENED: {
        client: "InMail responded",
        superAdmin: "InMail responded",
    },
};

type EMAIL_TAGS = "NOT_SENT" | "TRIGGERED" | "FAILED" | "NOT_FOUND" | "SENT" | "OPENED" | "RESPONDED" | "BOUNCED";

type SMS_TAGS =
    | "ACCEPTED"
    | "NOT_SENT"
    | "OPTED_OUT"
    | "NOT_FOUND"
    | "FAILED"
    | "UNDELIVERED"
    | "QUEUED"
    | "SENT"
    | "DELIVERED"
    | "RECEIVED";

type IEmailLabels = Record<EMAIL_TAGS, Values>;

export const emailStatusLabels: IEmailLabels = {
    NOT_SENT: {
        client: "",
        superAdmin: "",
    },
    TRIGGERED: {
        client: "Email triggered",
        superAdmin: "Email triggered",
    },
    FAILED: {
        client: "",
        superAdmin: "Email unsuccessful",
    },
    NOT_FOUND: {
        client: "",
        superAdmin: "",
    },
    SENT: {
        client: "Email Sent",
        superAdmin: "Email Sent",
    },
    OPENED: {
        client: "Email opened",
        superAdmin: "Email opened",
    },
    RESPONDED: {
        client: "Reply received",
        superAdmin: "Reply received",
    },
    BOUNCED: {
        client: "",
        superAdmin: "Email Bounced",
    },
};

type IFollowUpEmailLabels = Record<EMAIL_TAGS, Values>;

export const followupEmailStatusLabels: IFollowUpEmailLabels = {
    NOT_SENT: {
        client: "",
        superAdmin: "",
    },
    TRIGGERED: {
        client: "Follow-up email in queue",
        superAdmin: "Follow-up email in queue",
    },
    FAILED: {
        client: "",
        superAdmin: "Follow-up email unsuccessful",
    },
    NOT_FOUND: {
        client: "",
        superAdmin: "Follow-up email not found",
    },
    SENT: {
        client: "Follow-up email sent",
        superAdmin: "Follow-up email sent",
    },
    OPENED: {
        client: "Follow-up email opened",
        superAdmin: "Follow-up email opened",
    },
    RESPONDED: {
        client: "Reply received on follow-up",
        superAdmin: "Reply received on follow-up",
    },
    BOUNCED: {
        client: "",
        superAdmin: "",
    },
};

export const SmsStatusLables: Record<SMS_TAGS, Values> = {
    ACCEPTED: {
        superAdmin: "Accepted to receive message",
        client: "",
    },
    NOT_SENT: {
        superAdmin: "",
        client: "",
    },
    OPTED_OUT: {
        superAdmin: "Opted out",
        client: "Opted out",
    },
    NOT_FOUND: {
        superAdmin: "Phone number not found",
        client: "",
    },
    FAILED: {
        superAdmin: "SMS failed",
        client: "",
    },
    UNDELIVERED: {
        superAdmin: "SMS undelivered",
        client: "SMS undelivered",
    },
    QUEUED: {
        superAdmin: "SMS queued",
        client: "SMS queued",
    },
    SENT: {
        superAdmin: "SMS sent",
        client: "SMS sent",
    },
    DELIVERED: {
        superAdmin: "SMS delivered",
        client: "SMS sent",
    },
    RECEIVED: {
        superAdmin: "SMS response received",
        client: "SMS response received",
    },
};

export const emailStatusStrings = {
    TRIGGERED: "Email triggered",
    FAILED: "Email unsuccessful",
    SENT: "Email sent",
    OPENED: "Email opened",
    RESPONDED: "Reply received",
    BOUNCED: "Email bounced",
};

export const followupEmailStatusStrings = {
    TRIGGERED: "Follow-up email in queue",
    FAILED: "Follow-up email unsuccessful",
    SENT: "Email sent",
    OPENED: "Follow-up email opened",
    RESPONDED: "Reply received on follow-up",
};

export const campaignObjective: { [key: string]: string } = {
    None: "",
    "Lead Generation": "Lead Generation",
    "Product Awareness": "Product Awareness",
    "Product Launch": "Product Launch",
    "Website Traffic": "Website Traffic",
    "App Installs": "App Installs",
    "Product Updates": "Product Updates",
    "Market Research": "Market Research",
    Branding: "Branding",
    Other: "Other",
};

export const outreachChannel: { [key: string]: string } = {
    None: "",
    Email: "Email",
    Linkedin: "Linkedin",
    Both: "Email & Linkedin",
};
export const outreachRegions: { [key: string]: string } = {
    AMER: "AMER",
    APAC: "APAC",
    IMEA: "IMEA",
    EUR: "EUR",
};
