import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";

import { getTracking, leavePage, setTracking } from "@/store/reducers/extension-tracking/extensionTrack.reducers";

function* getTrackingSaga({
    payload,
}: {
    payload: {
        userId: string;
        date: string;
    };
}): SagaIterator {
    try {
        const response = yield call(new API().post, "/super-admin/getUserExtTracking", {
            user: payload.userId,
            date: payload.date,
        });

        if (!response?.data) return;

        yield put(setTracking(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getTracking.type, getTrackingSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
