import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChannelBarChart from "./charts/ChannelBarChart";
import SourceBarChart from "./charts/SourceBarChart";
import { FunnelViewProps } from "./FunnelView";

import { EFunnelOverviewLabel, EFunnelOverviewLabelEasyGrowth } from "../../../../store/apis/analytics/analytics.enums";
import DataCard from "../../molecules/DataCard/DataCard";
import Section from "../../molecules/Section/Section";

import IntersectionObserverWrapper from "@/components/IntersectionObserverWrapper";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";

const { Item, ListItem } = DataCard;

interface InteractionsProps extends FunnelViewProps {}

export default function Interactions(props: InteractionsProps) {
    const { t } = useTranslation();
    const { selectedOverview } = props;
    const isEasySource = useSelector(checkEasySource);

    const title = selectedOverview?.key
        ? `${isEasySource ? EFunnelOverviewLabel[selectedOverview.key] : EFunnelOverviewLabelEasyGrowth[selectedOverview.key]} split`
        : "Split";

    return (
        <Section.Content py={0}>
            <DataCard disableHoverEffect>
                <ListItem>
                    <Item
                        subtitle
                        info={
                            <span>
                                {t("analytics.splitBySourceInfoTitle")} <br />
                                <br />
                                <i>
                                    <small>
                                        {t("analytics.splitBySourceInfoSubtitle")} <br />
                                    </small>
                                </i>
                            </span>
                        }
                    >
                        {title} by source
                    </Item>
                </ListItem>
                <IntersectionObserverWrapper style={{ height: 50 }}>
                    <SourceBarChart {...props} />
                </IntersectionObserverWrapper>
            </DataCard>

            <DataCard disableHoverEffect>
                <ListItem>
                    <Item subtitle info={t("analytics.splitBySourceInfoTitle")}>
                        {title} by channel
                    </Item>
                </ListItem>
                <IntersectionObserverWrapper style={{ height: 50 }}>
                    <ChannelBarChart {...props} />
                </IntersectionObserverWrapper>
            </DataCard>
        </Section.Content>
    );
}
