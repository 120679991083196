import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { startAction, stopAction } from "../reducers/loaders.reducer";

import { getRb2bWebhookUrl, leavePage, setRb2bWebhookUrl } from "@/store/reducers/internalRB2B/internalRB2B.reducer";
import API from "@/utils/API";
import { CancelSagas } from "@/utils/saga.utils";
import handleException from "@/utils/sentry";

function* getRb2bWebhookUrlSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `/rb2b/generateWebhookUrl`, action.payload);
        if (!response?.data) return;

        const res = response.data;

        yield put(setRb2bWebhookUrl(res.url));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [yield takeLatest(getRb2bWebhookUrl.type, getRb2bWebhookUrlSaga)];
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
