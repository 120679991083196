import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { MRT_TableInstance } from "material-react-table";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";

import { useAsyncFn } from "../../../../hooks/useAsyncFn";
import API from "../../../../utils/API";
import handleException from "../../../../utils/sentry";

import {
    selectAllCandidatesFilters,
    selectAllCandidatesSearchResults,
    selectSearchQueryInAllCandidates,
    toggleSelectAllSearchResults,
} from "@/store/reducers/all-candidates/all-candidates.slice";
import {
    Candidate,
    ExportWithSearchResultsRequestPayload,
    FetchCandidatesResponse,
    FilterBy,
} from "@/store/reducers/all-candidates/all-candidates.types";
import { getKeyFromRenameMapping } from "@/store/reducers/all-candidates/all-candidates.utils";

type Props = {
    table: MRT_TableInstance<Candidate>;
};

export function ExportCandidates({ table }: Props) {
    const dispatch = useDispatch();
    const csvLink = useRef();

    const selectedRows = table.getSelectedRowModel().rows.map(({ original }) => original);
    const filters = useSelector(selectAllCandidatesFilters);
    const filterKeys = Object.keys(filters);
    const heads = table
        .getHeaderGroups()[0]
        .headers.map(({ column: { columnDef } }) => columnDef.header)
        .filter((h) => h && h !== "Select" && !h.startsWith("mrt"));
    const headers = heads.map((field) => ({
        label: field,
        key: getKeyFromRenameMapping(field) || field,
    }));

    const [exportRows, setExportRows] = useState<any>([]);
    const isAllSelected = useSelector(selectAllCandidatesSearchResults);
    const searchQuery = useSelector(selectSearchQueryInAllCandidates);
    const handleLinkClick = () => {
        setTimeout(() => {
            // @ts-ignore
            csvLink.current.link.click();
        });
    };
    const { status, submit } = useAsyncFn(async () => {
        try {
            const appliedFilters: FilterBy = {};
            filterKeys.forEach((key) => {
                const values = filters[key]?.value.map(({ value }) => value);
                if (values?.length) {
                    appliedFilters[key] = values;
                }
            });
            const payload: ExportWithSearchResultsRequestPayload = {
                selectAll: isAllSelected,
                search: searchQuery,
                filterBy: appliedFilters,
            };

            const response = (await new API().post("/candidate/all", payload)) as FetchCandidatesResponse;

            if (response?.data?.candidates?.length) {
                setExportRows(response.data.candidates);
                handleLinkClick();
                dispatch(toggleSelectAllSearchResults());
            } else {
                throw new Error("invalid response");
            }
        } catch (error) {
            handleException(error);
            throw new Error("something went wrong!");
        }
    });

    const handleClick = () => {
        if (isAllSelected) {
            return submit();
        } else {
            setExportRows(selectedRows);
            handleLinkClick();
        }
    };

    const isLoading = status === "LOADING";
    const isContactsSelected = !!selectedRows.length || isAllSelected;

    const isDisabled = isLoading || !isContactsSelected;

    return (
        <>
            <Tooltip title="Export selected contacts">
                <span>
                    <IconButton onClick={handleClick} disabled={isDisabled}>
                        <FileDownloadRoundedIcon />
                    </IconButton>
                </span>
                {/* <CustomButton
                    variant="contained"
                    startIcon={<FileDownloadRoundedIcon />}
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    <ButtonTextWithLoading
                        isLoading={isLoading}
                        text="Export"
                    />
                </CustomButton> */}
            </Tooltip>
            <CSVLink
                headers={headers}
                data={exportRows}
                filename="exported_contacts.csv"
                style={{ display: "none" }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </>
    );
}
