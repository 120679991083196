import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { addContactsToExclusionList, selectAllContactsSelected } from "../../../store/apis/all-contacts/all-contacts.slice";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

export default function AddToExclusionList() {
    const dispatch = useDispatch();
    const isSubmitting = useSelector(checkIfLoading(addContactsToExclusionList.type));
    const selectedContacts = useSelector(selectAllContactsSelected);
    const isContactsSelected = !!selectedContacts?.length;
    const isDisabled = !isContactsSelected || isSubmitting;

    const handleClick = () => dispatch(addContactsToExclusionList(selectedContacts));

    return (
        <CustomButton variant="contained" startIcon={<GroupRemoveIcon />} disabled={isDisabled} onClick={handleClick}>
            <ButtonTextWithLoading isLoading={isSubmitting} text="Add to Exclusion list" />
        </CustomButton>
    );
}
