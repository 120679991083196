import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { IActionPayload, startAction, stopAction } from "../../store/reducers/loaders.reducer";
import { setErrorNotification, setSuccessNotification } from "../../store/reducers/notification/notification.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    FetchFromLeverResponse,
    IAddToATSPayload,
    IAddToBambooHRPayload,
    IAddToGreenhousePayload,
    IAddToLeverPayload,
    IAddToLeverResponse,
    IAddToNucleusPayload,
    IAddToRecruiterFlowPayload,
    IAddToUnifiedAtsPayload,
    IAddToZohoRecruitPayload,
    IFetchLaborEdgePayload,
    IGetFromBambooHRPayload,
    IGetFromGreenhouse,
    IGetFromLever,
    IGetFromRecruiterFlowPayload,
    IGetGreenhouseJobStagesPayload,
    IJazzHRJob,
    ILeverFetchCandidatePayload,
    ILeverPostCandidatePayload,
    ILoxoCredentials,
    ILoxoCredentialsResponse,
    IPostBambooHRCredentialsPayload,
    IPostGreenhouseCredentialsPayload,
    IPostJazzHRCredentialsPayload,
    IPostJobviteCredentialsPayload,
    IPostLaborEdgeCredentialsPayload,
    ISendSlackInvitationPayload,
    ISlackAuthResponse,
    IZohoAddPayload,
    LeverExportCandidate,
} from "../reducers/integrations/integrations.types";
import { getProjectCandidates } from "../reducers/project/project.reducer";
import { ICandidate } from "../reducers/project/project.types";
import { setUser } from "../reducers/signin/Signin.reducer";
import { signinType } from "../reducers/signin/Signin.types";

import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    addJazzHRJob,
    addToBambooHR,
    addToGreenhouseAgency,
    addToJobvite,
    addToLever,
    addToLoxoAgency,
    addToNucleus,
    addToOracle,
    addToRecruiterFlow,
    addToUnifiedAts,
    addToZohoRecruit,
    authorizeUnifiedAtsIntegration,
    fetchCandidatesFromUnifiedAts,
    fetchLoxoCredentials,
    getBambooHRJobs,
    getBambooHRStatuses,
    getCandidatesFromLabEdge,
    getFromBambooHR,
    getFromGreenhouse,
    getFromLever,
    getFromOracle,
    getFromRecruiterFlow,
    getFromZohoRecruit,
    getGreenhouseJobStages,
    getGreenhouseJobs,
    getJazzHRJobStages,
    getJazzHRJobs,
    getJobsFromUnifiedAts,
    getJobviteJobs,
    getLaborEdgeJobs,
    getLaborEdgeProfessions,
    getLaborEdgeSpecialties,
    getLaborEdgeStatuses,
    getLeverJobs,
    getLeverStages,
    getNucleusJobs,
    getNucleusProjects,
    getNucleusStages,
    getOracleJobs,
    getRecruiterFlowJobs,
    getRecruiterFlowStages,
    getStagesFromUnifiedAts,
    getUnifiedAtsIntegrations,
    getZohoHRJobs,
    getZohoHRStatuses,
    postBambooHRCredentials,
    postGreenhouseCredentials,
    postJazzHRCredentials,
    postJobviteCredentials,
    postLaborEdgeCredentials,
    postLoxoCredentials,
    pushJazzHRJobs,
    resetIntegration,
    revokeSlackInvitation,
    sendCandidatesToLabEdge,
    sendSlackInvitation,
    setBambooHRJobs,
    setBambooHRStatuses,
    setGreenhouseJobStages,
    setGreenhouseJobs,
    setJazzHRJobStages,
    setJazzHRJobs,
    setJobsFromUnifiedAts,
    setJobviteJobs,
    setLaborEdgeJobs,
    setLaborEdgeProfessions,
    setLaborEdgeSpecialties,
    setLaborEdgeStatuses,
    setLeverExportCandidates,
    setLeverJobs,
    setLeverStages,
    setLoxoCredentials,
    setNucleusJobs,
    setNucleusProjects,
    setNucleusStages,
    setOracleJobs,
    setOraclePhases,
    setOracleStates,
    setRecruiterFlowJobs,
    setRecruiterFlowStages,
    setStagesFromUnifiedAts,
    setUnifiedAtsIntegrations,
    setUnifiedIsAuthorizing,
    setZohoHRJobs,
    setZohoHRStatuses,
} from "@/store/reducers/integrations/integrations.reducer";

function* fetchLoxoCredentialsSaga({ payload }: { payload: ILoxoCredentials & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ILoxoCredentialsResponse = yield call(new API().get, "/loxo/get-creds");

        const loxoCreds = {
            agencySlug: response?.data.agencySlug || "",
            apiKey: response?.data.apiKey || "",
            apiPassword: response?.data.apiPassword || "",
        };
        yield put(setLoxoCredentials(loxoCreds));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}
function* getOracleJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: getOracleJobs.type }));
        const response: any = yield call(new API().get, "ats/oracle/getJobsRecruiter");

        yield put(setOracleJobs(response?.data?.items));
        yield put(setOraclePhases(response?.data?.currentPhaseMap));
        yield put(setOracleStates(response?.data?.currentStateMap));

    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: getOracleJobs.type }));
    }
}
function* getFromOracleSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response: { message: string } = yield call(new API().post, "/ats/oracle/getCandidates", payload);

        if (!response) return;
        yield put(setSuccessNotification(response.message));
        yield put(getProjectCandidates({ projectId: payload.projectId, action: getProjectCandidates.type }));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToOracleSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        payload.filterBy = projectFiltersPayloadValue;
        payload.search = projectSearchStateValue;

        if (selectAllCandidatesStateValue) {
            payload.selectAll = true;
            payload.candidateIds = [];
        }

        const response: { message: string } = yield call(new API().post, "/ats/oracle/uploadCandidates", payload);

        if (!response) return;
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postLoxoCredentialsSaga({ payload }: { payload: ILoxoCredentials & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ILoxoCredentialsResponse & ILoxoCredentials = yield call(new API().post, "/loxo/on-board", {
            agencySlug: payload.agencySlug,
            loxoAPIKey: payload.apiKey,
            loxoAPISecret: payload.apiPassword,
        });

        const loxoCreds = {
            agencySlug: payload.agencySlug,
            apiKey: payload.apiKey,
            apiPassword: payload.apiPassword,
        };
        yield put(setLoxoCredentials(loxoCreds));
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToLoxoAgencySaga({ payload }: { payload: IAddToATSPayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");

        const triggerPayload: IAddToATSPayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response: { message: string } = yield call(new API().post, "/loxo/add-to-agency", triggerPayload);

        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}
function* addToRecruiterFlowSaga({ payload }: { payload: IAddToRecruiterFlowPayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { candidateIds, projectId, jobId, stageId, stageName } = payload;
        const triggerPayload: IAddToRecruiterFlowPayload = {
            candidateIds,
            projectId,
        };
        if (stageId) triggerPayload.stageId = stageId;
        if (jobId) triggerPayload.jobId = jobId;
        if (stageName) triggerPayload.stageName = stageName;

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response: { message: string } = yield call(
            new API().post,
            "/ats/recruiterFlow/post-candidates",
            triggerPayload
        );

        // if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getFromRecruiterFlowSaga({
    payload,
}: {
    payload: IGetFromRecruiterFlowPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const { jobId, stageId, projectId, stageName } = payload;

        const response: { message: string } = yield call(new API().post, "/ats/recruiterFlow/get-candidates", {
            jobId,
            stageId,
            projectId,
            stageName,
        });

        yield put(setSuccessNotification(response.message));
        yield put(
            getProjectCandidates({
                projectId,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToGreenhouseAgencySaga(action: PayloadAction<IAddToGreenhousePayload>): SagaIterator {
    const { payload, type } = action;
    try {
        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { candidateIds, projectId, jobId, stageId, onSuccess } = payload;

        const triggerPayload: IAddToGreenhousePayload = {
            candidateIds,
            projectId,
        };
        if (stageId) triggerPayload.stageId = stageId;
        if (jobId) triggerPayload.jobId = jobId;

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        yield put(startAction({ action: type }));
        const response = yield call(new API().post, "/greenhouse/post-candidate", triggerPayload);
        if (!response?.data) return;

        if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* postGreenhouseCredentialsSaga({
    payload,
}: {
    payload: IPostGreenhouseCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/greenhouse/post-creds", {
            harvestApiKey: payload.harvestApiKey,
            apiKey: payload.apiKey,
            serviceUser: payload.serviceUser,
            clientGreenHouseUrl: payload?.clientGreenHouseUrl,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const { apiKey, serviceUser, harvestApiKey, clientGreenHouseUrl } = response.data;
        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                greenHouse: {
                    apiKey,
                    serviceUser,
                    harvestApiKey,
                    clientGreenHouseUrl,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postJazzHRCredentialsSaga({
    payload,
}: {
    payload: IPostJazzHRCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/jazzHR/post-creds", {
            jazzHRFeedURL: payload?.jazzHRFeedURL,
            jazzHRAPIKey: payload?.jazzHRAPIKey,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                jazzHR: {
                    jazzHRFeedURL: payload?.jazzHRFeedURL,
                    jazzHRAPIKey: payload?.jazzHRAPIKey,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postLaborEdgeCredentialsSaga({
    payload,
}: {
    payload: IPostLaborEdgeCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "ats/laborEdge/post-creds", {
            clientId: payload?.clientId,
            grantType: payload?.grantType,
            organizationCode: payload?.organizationCode,
            password: payload?.password,
            username: payload?.username,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                laborEdge: {
                    clientId: payload?.clientId,
                    grantType: payload?.grantType,
                    organizationCode: payload?.organizationCode,
                    password: payload?.password,
                    username: payload?.username,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.response.data.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getGreenhouseJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/greenhouse/fetch-jobs");
        if (!response) return;

        yield put(setGreenhouseJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}
function* getRecruiterFlowJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/ats/recruiterFlow/get-jobs");
        if (!response) return;

        yield put(setRecruiterFlowJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getRecruiterFlowStagesSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().post, "/ats/recruiterFlow/get-stages", {
            jobId: payload?.jobId,
        });
        if (!response) return;

        yield put(setRecruiterFlowStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getGreenhouseJobStagesSaga({ payload, type }: PayloadAction<IGetGreenhouseJobStagesPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, `/greenhouse/fetch-stages/${payload.jobId}`);
        if (!response?.data) return;

        yield put(setGreenhouseJobStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getFromZohoRecruitSaga({ payload, type }: PayloadAction<IZohoAddPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().post, "/zohoRecruit/getCandidates", {
            projectId: payload.projectId,
            jobId: payload.jobId,
            ...(payload.statusId && { statusId: payload.statusId }),
        });
        if (isEmpty(response.message)) {
            throw new Error("Error fetching the candidate data. Please try again after some time.");
        }
        if (!response.data.candidateCount) {
            throw new Error("No candidate found in the project.");
        }
        yield put(
            setSuccessNotification(
                `${response.message} , We have started fetching ${response.data.candidateCount} candidates`
            )
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        payload.onSuccess?.();
        yield put(stopAction({ action: type }));
    }
}

function* addToZohoRecruitSaga({ payload, type }: PayloadAction<IAddToZohoRecruitPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { projectId, jobId, statusId, candidateIds, subStatusId } = payload;

        const triggerPayload: any = {
            projectId,
            jobId,
            statusId,
            candidateIds,
            subStatusId,
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response = yield call(new API().post, "/zohoRecruit/postCandidates", triggerPayload);

        if (isEmpty(response.message)) {
            throw new Error("An error occured. Please try again after some time.");
        }

        yield put(setSuccessNotification(`${payload.candidateIds.length} candidates are begin pushed to zoho recruit`));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        payload.onSuccess?.();
        yield put(stopAction({ action: type }));
    }
}

function* sendSlackInvitationSaga({ payload }: { payload: ISendSlackInvitationPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ISlackAuthResponse = yield call(new API().get, "/slack/auth");
        const { data } = response;
        window.location.href = data;
    } catch (err) {
        console.error("error while authenticating with slack");
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* revokeSlackInvitationSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        const response = yield call(new API().get, "/slack/revoke-access");
        if (!response) return;

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                isSlackConnected: false,
            })
        );
    } catch (err) {
        console.error("error while authenticating with slack");
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getLeverJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/lever/get-data/jobs");
        if (!response) return;

        yield put(setLeverJobs(response.data));
    } catch (err: any) {
        console.log(err);
        yield put(setErrorNotification(err.respose.data.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getLeverStagesSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/lever/get-data/stages");
        if (!response) return;

        yield put(setLeverStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err?.response?.data?.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToLeverSaga({ payload }: { payload: IAddToLeverPayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );
        const triggerPayload: ILeverPostCandidatePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }
        if (payload.job.jobId) {
            triggerPayload.jobId = payload.job.jobId;
        }
        if (payload.stage.stageId) {
            triggerPayload.stageId = payload.stage.stageId;
        }

        const response: IAddToLeverResponse = yield call(new API().post, "/lever/post-candidate", triggerPayload);

        if (!response) return;

        payload.onSuccess();
        if (response?.duplicate) yield put(setErrorNotification(response.message));
        else yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getFromLeverSaga({ payload }: { payload: IGetFromLever & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const triggerPayload: ILeverFetchCandidatePayload = {
            jobId: payload.job.jobId,
        };

        if (payload?.stage?.stageId) {
            triggerPayload.stageId = payload.stage.stageId;
        }
        if (payload?.createdAtStart) {
            triggerPayload.createdAtStart = payload.createdAtStart;
        }
        if (payload?.createdAtEnd) {
            triggerPayload.createdAtEnd = payload.createdAtEnd;
        }
        if (payload?.projectId) {
            triggerPayload.projectId = payload.projectId;
        }
        const response: FetchFromLeverResponse = yield call(new API().post, "/lever/get-candidate", triggerPayload);

        if (!response) return;

        const exportLeverCandidates: LeverExportCandidate[] = response.data.map(
            ({ name, id, resume, emails, links }) => ({
                emails: emails.toString(),
                id,
                resume,
                name,
                linkedinUrl: links.toString(),
            })
        );

        yield put(setLeverExportCandidates(exportLeverCandidates));
        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(
            getProject({
                projectId: String(triggerPayload.projectId),
                action: getProject.type,
            })
        );
        // // if (response?.duplicate)
        // //     yield put(setErrorNotification(response.message));
        // else yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        console.log(err);
        yield put(setErrorNotification(err?.response?.data?.error));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postJobviteCredentialsSaga({
    payload,
}: {
    payload: IPostJobviteCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().post, "/job-vite/add-creds", {
            apiKey: payload.apiKey,
            apiSecret: payload.apiSecret,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                jobVites: {
                    apiKey: payload.apiKey,
                    apiSecret: payload.apiSecret,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToJobviteSaga({ payload }: { payload: IAddToGreenhousePayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");

        const triggerPayload: IAddToGreenhousePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }
        if (payload.jobId) triggerPayload.jobId = payload.jobId;

        const response = yield call(new API().post, "/job-vite/add-candidates", triggerPayload);
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getJobviteJobsSaga({ payload }: { payload: IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/job-vite/get-job");
        if (!response) return;

        yield put(setJobviteJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getFromGreenhouseSaga(action: PayloadAction<IGetFromGreenhouse>): SagaIterator {
    const { payload, type } = action;

    try {
        yield put(startAction({ action: type }));

        const triggerPayload: any = {
            jobId: payload.job,
            projectId: payload.projectId,
        };
        if (payload.stage) {
            triggerPayload.stageId = payload.stage;
        }
        if (payload.createdAtStart) {
            triggerPayload.createdAtStart = payload.createdAtStart;
        }
        if (payload.createdAtEnd) {
            triggerPayload.createdAtEnd = payload.createdAtEnd;
        }
        if (payload.isProspect) {
            triggerPayload.isProspect = payload.isProspect;
        }

        const response: {
            message: string;
            data: ICandidate[];
        } = yield call(new API().post, "/greenhouse/get-candidates", triggerPayload);

        if (!response?.data) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(
            getProject({
                projectId: payload.projectId.toString(),
                action: getProject.type,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* postBambooHRCredentialsSaga({ type, payload }: PayloadAction<IPostBambooHRCredentialsPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response: { data: signinType; message: string } = yield call(new API().post, "/bambooHR/postCreds", {
            apiKey: payload.apiKey,
            companyDomain: payload.companyDomain,
        });

        if (!response?.data) return;

        payload.onSuccess();
        yield put(setUser(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getBambooHRJobsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, "/bambooHR/fetchJobs");
        if (!response) return;

        yield put(setBambooHRJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getBambooHRStatusesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const response = yield call(new API().get, "/bambooHR/fetchStatuses");
        if (!response) return;

        yield put(setBambooHRStatuses(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getZohoHRJobsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/zohoRecruit/fetchJobs");
        if (!response) return;
        yield put(setZohoHRJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getZohoHRStatusesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/zohoRecruit/fetchStatuses");
        if (!response) return;
        yield put(setZohoHRStatuses(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getFromBambooHRSaga({ payload, type }: PayloadAction<IGetFromBambooHRPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const { onSuccess, ...rest } = payload;

        const response = yield call(new API().post, "/bambooHR/getCandidates", rest);

        if (!response?.data) return;

        onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(
            getProject({
                projectId: rest.projectId.toString(),
                action: getProject.type,
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getNucleusProjectsSaga(action: PayloadAction<IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "nucleus/get-projects");
        if (!response) return;

        yield put(setNucleusProjects(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getNucleusJobsSaga(action: PayloadAction<IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "nucleus/get-jobs");
        if (!response) return;

        yield put(setNucleusJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getNucleusStagesSaga(action: PayloadAction<IAddToNucleusPayload & IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().get, "/nucleus/get-stages");
        if (!response) return;

        yield put(setNucleusStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addToNucleusSaga(action: PayloadAction<IAddToNucleusPayload & IActionPayload>): SagaIterator {
    try {
        const { payload } = action;
        yield put(startAction({ action: action.type }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }
                return acc;
            },
            []
        );

        const triggerPayload: IAddToNucleusPayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
            jobId: payload.jobId,
            stageId: payload.stageId,
            nucleusProjectId: payload.nucleusProjectId,
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response = yield call(new API().post, "/nucleus/post-candidate", triggerPayload);

        if (!response) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addToBambooHRSaga({ payload, type }: PayloadAction<IAddToBambooHRPayload>): SagaIterator {
    try {
        yield put(startAction({ action: type }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const { onSuccess, candidateIds, jobId, projectId, statusId } = payload;

        const triggerPayload: any = {
            candidateIds,
            projectId,
            jobId,
            statusId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response = yield call(new API().post, "/bambooHR/postCandidates", triggerPayload);

        if (!response?.data) return;

        onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}
function* getJazzHRJobsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/jazzHR/get-jobs");
        if (!response) return;

        yield put(setJazzHRJobs(response.data));
        // yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        // yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}
function* getJazzHRJobStagesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "/jazzHR/get-stages");
        if (!response) return;

        yield put(setJazzHRJobStages(response.data));
        // yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        // yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addJazzHRJobsSaga(action: PayloadAction<IJazzHRJob>): SagaIterator {
    // const cancelTokenSource = axios.CancelToken.source();
    try {
        yield put(startAction({ action: action.type }));
        const { id, title, onSuccess } = action.payload;
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");

        const response = yield call(new API().post, "/jazzHR/job-project-map", {
            projectId: Number(currentProject),
            jobId: id,
            jobName: title,
        });

        if (!response) return;
        if (title === "") {
            yield put(setSuccessNotification("You have disconnected this EasySource project from JazzHR jobs"));
            if (onSuccess) onSuccess();
            return;
        }
        yield put(setSuccessNotification("Job mapped to EasySource project successfully"));
        if (onSuccess) onSuccess();
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}
// for add to ATS
function* pushJazzHRJobsSaga(action: PayloadAction<IJazzHRJob>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { id, candidateIds, stageID, stageName, title, onSuccess } = action.payload;
        const state = yield select();
        const currentProject = get(state, "allProjects.project._id");

        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            },
            []
        );

        const response = yield call(new API().post, "/jazzHR/post-candidate", {
            projectId: Number(currentProject),
            jobId: id,
            stageId: stageID,
            jobName: title,
            stageName: stageName,
            ...(selectAllCandidatesStateValue ? { candidateIds: [] } : { candidateIds }),
            ...(selectAllCandidatesStateValue && { filterBy: projectFiltersPayloadValue }),
            ...(selectAllCandidatesStateValue && { search: projectSearchStateValue }),
            ...(selectAllCandidatesStateValue && { selectAll: true }),
        });
        if (!response) return;

        yield put(setSuccessNotification(response?.message));
        if (onSuccess) onSuccess();
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getCandidatesFromLaborEdgeSaga(action: PayloadAction<IFetchLaborEdgePayload & IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/ats/laborEdge/getCandidates", {
            ...action.payload,
        });

        if (!response) return;
        if (action.payload.onSuccess) action.payload.onSuccess();
        yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}
function* getLaborEdgeStatusesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "ats/laborEdge/getCandidateStatuses");
        if (!response) return;

        yield put(setLaborEdgeStatuses(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}
function* getLaborEdgeJobsSaga({ payload, type }: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().post, "ats/laborEdge/getJobs", payload);
        if (!response) return;

        yield put(setLaborEdgeJobs(response.data));
    } catch (err: any) {
        yield put(
            setErrorNotification(
                err?.response?.data?.error || "Cannot fetch jobs at the moment. Please try again later"
            )
        );
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getLaborEdgeSpecialtiesSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "ats/laborEdge/getSpecialty");
        if (!response) return;

        yield put(setLaborEdgeSpecialties(response.data));
    } catch (err: any) {
        yield put(
            setErrorNotification(
                err?.response?.data?.error || "Cannot fetch specialties at the moment. Please try again later"
            )
        );
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getLaborEdgeProfessionsSaga({ type }: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, "ats/laborEdge/getProfessions");
        if (!response) return;

        yield put(setLaborEdgeProfessions(response.data));
    } catch (err: any) {
        yield put(
            setErrorNotification(
                err?.response?.data?.error || "Cannot fetch professions at the moment. Please try again later"
            )
        );
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* sendCandidatesToLabEdgeSaga({ payload, type }: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const response = yield call(new API().post, "ats/laborEdge/postCandidates", payload);
        if (!response) return;

        yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getUnifiedAtsIntegrationsSaga(action: PayloadAction<IActionPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const unifiedApiURL = import.meta.env.VITE_REACT_APP_UNIFIED_API_URL;
        const unifiedWorkspaceId = import.meta.env.VITE_REACT_APP_UNIFIED_WORKSPACE_ID;
        const unifiedApiKey = import.meta.env.VITE_REACT_APP_UNIFIED_API_KEY;
        const unifiedEnvironment = import.meta.env.VITE_REACT_APP_UNIFIED_ENV;

        const response = yield call(axios.get, `${unifiedApiURL}/unified/integration/workspace/${unifiedWorkspaceId}`, {
            params: {
                env: unifiedEnvironment,
                categories: 'ats',
            },
            headers: {
                Authorization: `Bearer ${unifiedApiKey}`
            }
        });

        if (!response || !Array.isArray(response?.data)) return;

        yield put(setUnifiedAtsIntegrations(response?.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* authorizeUnifiedAtsIntegrationSaga({ payload, type }: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        yield put(setUnifiedIsAuthorizing(payload?.integrationType));
        const state = yield select();
        const user = get(state, "signin.user");

        const response = yield call(new API().post, "ats/unified/authorize", payload);

        if (!response) return;
        yield put(setUser({ ...user, unifiedAts: response?.data }));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
        yield put(setUnifiedIsAuthorizing(null));
    }
}

function* getJobsFromUnifiedAtsSaga({ payload, type }: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const state = yield select();
        const user = get(state, "signin.user");
        const unfifedConnectionId = user?.unifiedAts?.[payload?.atsType?.toLowerCase()]?.connectionId;

        const unifiedApiURL = import.meta.env.VITE_REACT_APP_UNIFIED_API_URL;
        const unifiedApiKey = import.meta.env.VITE_REACT_APP_UNIFIED_API_KEY;
        const unifiedEnvironment = import.meta.env.VITE_REACT_APP_UNIFIED_ENV;

        const response = yield call(axios.get, `${unifiedApiURL}/ats/${unfifedConnectionId}/job`, {
            params: {
                env: unifiedEnvironment,
                categories: 'ats',
            },
            headers: {
                Authorization: `Bearer ${unifiedApiKey}`
            }
        });

        if (!response) return;
        yield put(setJobsFromUnifiedAts(response?.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getStagesFromUnifiedAtsSaga({ payload, type }: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: type }));
        const state = yield select();
        const user = get(state, "signin.user");
        const unfifedConnectionId = user?.unifiedAts?.[payload?.atsType?.toLowerCase()]?.connectionId;

        const unifiedApiURL = import.meta.env.VITE_REACT_APP_UNIFIED_API_URL;
        const unifiedApiKey = import.meta.env.VITE_REACT_APP_UNIFIED_API_KEY;
        const unifiedEnvironment = import.meta.env.VITE_REACT_APP_UNIFIED_ENV;

        const response = yield call(axios.get, `${unifiedApiURL}/ats/${unfifedConnectionId}/applicationstatus`, {
            params: {
                env: unifiedEnvironment,
                categories: 'ats',
            },
            headers: {
                Authorization: `Bearer ${unifiedApiKey}`
            }
        });

        if (!response) return;
        yield put(setStagesFromUnifiedAts(response?.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* fetchCandidatesFromUnifiedAtsSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { jobId, projectId, ats, onSuccess } = action.payload;
        const response = yield call(new API().post, "ats/unified/pull-candidates", {
            jobId,
            projectId,
            ats,
        });

        if (!response) return;
        yield put(setSuccessNotification(response?.message));
        yield put(
            getProjectCandidates({
                projectId,
                action: getProjectCandidates.type,
            })
        );

        if (onSuccess) onSuccess();
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addToUnifiedAtsSaga(action: PayloadAction<IAddToUnifiedAtsPayload & IActionPayload>): SagaIterator {
    try {
        const { payload } = action;
        yield put(startAction({ action: action.type }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const projectSearchStateValue = get(state, "project.searchQuery");
        const projectFiltersPayloadValue = Object.entries(projectFiltersStateValue)?.reduce(
            (acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }
                return acc;
            },
            []
        );

        const triggerPayload: IAddToUnifiedAtsPayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
            jobId: payload.jobId,
            stage: payload.stage,
            ats: payload.ats,
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
            triggerPayload.filterBy = projectFiltersPayloadValue;
            triggerPayload.search = projectSearchStateValue;
        }

        const response = yield call(new API().post, "/ats/unified/push-candidates", triggerPayload);

        if (!response) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchLoxoCredentials.type, fetchLoxoCredentialsSaga),
        // @ts-ignore
        yield takeLatest(postLoxoCredentials.type, postLoxoCredentialsSaga),
        // @ts-ignore
        yield takeLatest(addToLoxoAgency.type, addToLoxoAgencySaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postGreenhouseCredentials.type,
            postGreenhouseCredentialsSaga
        ),
        // @ts-ignore
        yield takeLatest(addToGreenhouseAgency.type, addToGreenhouseAgencySaga),
        // @ts-ignore
        yield takeLatest(getGreenhouseJobs.type, getGreenhouseJobsSaga),
        // @ts-ignore
        yield takeLatest(getGreenhouseJobStages.type, getGreenhouseJobStagesSaga),
        // @ts-ignore
        yield takeLatest(getFromGreenhouse.type, getFromGreenhouseSaga),
        // @ts-ignore
        yield takeLatest(sendSlackInvitation.type, sendSlackInvitationSaga),
        // @ts-ignore
        yield takeLatest(revokeSlackInvitation.type, revokeSlackInvitationSaga),
        // @ts-ignore
        yield takeLatest(getFromZohoRecruit.type, getFromZohoRecruitSaga),
        // @ts-ignore
        yield takeLatest(addToZohoRecruit.type, addToZohoRecruitSaga),
        // @ts-ignore
        yield takeLatest(getLeverJobs.type, getLeverJobsSaga),
        // @ts-ignore
        yield takeLatest(getLeverStages.type, getLeverStagesSaga),
        // @ts-ignore
        yield takeLatest(addToLever.type, addToLeverSaga),
        // @ts-ignore
        yield takeLatest(getFromLever.type, getFromLeverSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postJobviteCredentials.type,
            postJobviteCredentialsSaga
        ),
        // @ts-ignore
        yield takeLatest(getJobviteJobs.type, getJobviteJobsSaga),
        // @ts-ignore
        yield takeLatest(addToJobvite.type, addToJobviteSaga),
        // @ts-ignore
        yield takeLatest(postBambooHRCredentials.type, postBambooHRCredentialsSaga),
        // @ts-ignore
        yield takeLatest(postJazzHRCredentials.type, postJazzHRCredentialsSaga),
        // @ts-ignore
        yield takeLatest(postLaborEdgeCredentials.type, postLaborEdgeCredentialsSaga),
        // @ts-ignore
        yield takeLatest(getBambooHRJobs.type, getBambooHRJobsSaga),
        // @ts-ignore
        yield takeLatest(getBambooHRStatuses.type, getBambooHRStatusesSaga),
        // @ts-ignore
        yield takeLatest(getFromBambooHR.type, getFromBambooHRSaga),
        // @ts-ignore
        yield takeLatest(addToBambooHR.type, addToBambooHRSaga),
        // @ts-ignore
        yield takeLatest(getZohoHRJobs.type, getZohoHRJobsSaga),
        // @ts-ignore
        yield takeLatest(getZohoHRStatuses.type, getZohoHRStatusesSaga),
        // @ts-ignore
        yield takeLatest(getJazzHRJobs.type, getJazzHRJobsSaga),
        // @ts-ignore
        yield takeLatest(addJazzHRJob.type, addJazzHRJobsSaga),
        // @ts-ignore
        yield takeLatest(pushJazzHRJobs.type, pushJazzHRJobsSaga),
        // @ts-ignore
        yield takeLatest(getNucleusJobs.type, getNucleusJobsSaga),
        // @ts-ignore
        yield takeLatest(getNucleusStages.type, getNucleusStagesSaga),
        // @ts-ignore
        yield takeLatest(addToNucleus.type, addToNucleusSaga),

        // @ts-ignore
        yield takeLatest(getJazzHRJobStages.type, getJazzHRJobStagesSaga),

        // @ts-ignore
        yield takeLatest(getNucleusProjects.type, getNucleusProjectsSaga),
        // @ts-ignore
        yield takeLatest(getCandidatesFromLabEdge.type, getCandidatesFromLaborEdgeSaga),
        yield takeLatest(getLaborEdgeStatuses.type, getLaborEdgeStatusesSaga),
        // @ts-ignore
        yield takeLatest(getLaborEdgeSpecialties.type, getLaborEdgeSpecialtiesSaga),
        // @ts-ignore
        yield takeLatest(getLaborEdgeProfessions.type, getLaborEdgeProfessionsSaga),
        // @ts-ignore
        yield takeLatest(sendCandidatesToLabEdge.type, sendCandidatesToLabEdgeSaga),
        // @ts-ignore
        yield takeLatest(getLaborEdgeJobs.type, getLaborEdgeJobsSaga),
        // @ts-ignore
        yield takeLatest(addToRecruiterFlow.type, addToRecruiterFlowSaga),
        // @ts-ignore
        yield takeLatest(getRecruiterFlowJobs.type, getRecruiterFlowJobsSaga),
        //@ts-ignore
        yield takeLatest(getRecruiterFlowStages.type, getRecruiterFlowStagesSaga),
        // @ts-ignore
        yield takeLatest(getFromRecruiterFlow.type, getFromRecruiterFlowSaga),
        // @ts-ignore
        yield takeLatest(getOracleJobs.type, getOracleJobsSaga),
        // @ts-ignore
        yield takeLatest(addToOracle.type, addToOracleSaga),
        // @ts-ignore
        yield takeLatest(getFromOracle.type, getFromOracleSaga),
        // @ts-ignore
        yield takeLatest(getUnifiedAtsIntegrations.type, getUnifiedAtsIntegrationsSaga),
        // @ts-ignore
        yield takeLatest(authorizeUnifiedAtsIntegration.type, authorizeUnifiedAtsIntegrationSaga),
        // @ts-ignore
        yield takeLatest(getJobsFromUnifiedAts.type, getJobsFromUnifiedAtsSaga),
        // @ts-ignore
        yield takeLatest(fetchCandidatesFromUnifiedAts.type, fetchCandidatesFromUnifiedAtsSaga),
        // @ts-ignore
        yield takeLatest(getStagesFromUnifiedAts.type, getStagesFromUnifiedAtsSaga),
        // @ts-ignore
        yield takeLatest(addToUnifiedAts.type, addToUnifiedAtsSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetIntegration.type, CancelSagas, tasks);
}
