import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "../../Signin/Signin.reducer";

import { Button, Modal, Typography } from "@/ui";

interface LaborEdgeCredentials {
    username: string;
    password: string;
}

interface LaborEdgeConnectProps {
    onClose: () => void;
}

export default function LaborEdgeConnect({ onClose }: LaborEdgeConnectProps) {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const isCredsSubmitting = useSelector(checkIfLoading(updateUser.type));

    const [laborEdge, setLaborEdge] = useState<LaborEdgeCredentials>({
        username: user.laborEdge?.username ?? "",
        password: user.laborEdge?.password ?? "",
    });

    useEffect(() => {
        setLaborEdge({
            username: user.laborEdge?.username ?? "",
            password: user.laborEdge?.password ?? "",
        });
    }, [user.laborEdge]);

    const handleSubmit = () => {
        if (laborEdge.username.length === 0) {
            dispatch(setErrorNotification("Please enter LaborEdge username"));
            return;
        }
        if (laborEdge.password.length === 0) {
            dispatch(setErrorNotification("Please enter LaborEdge password"));
            return;
        }

        dispatch(
            updateUser({
                ...user,
                laborEdge: laborEdge,
                action: updateUser.type,
                onSuccess: onClose,
            })
        );
    };

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ minWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5"> Connect LaborEdge</h2>

                <div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Username</Typography>
                        <input
                            id="username"
                            placeholder="Enter Username"
                            value={laborEdge.username}
                            onChange={(e) =>
                                setLaborEdge((prev) => ({
                                    ...prev,
                                    username: e.target.value,
                                }))
                            }
                            className=" min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Password</Typography>
                        <input
                            id="password"
                            type="password"
                            placeholder="Enter Password"
                            value={laborEdge.password}
                            onChange={(e) =>
                                setLaborEdge((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                }))
                            }
                            className=" min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant="default"
                        onClick={handleSubmit}
                        disabled={isCredsSubmitting}
                        className="rounded-sm"
                        label={isCredsSubmitting ? "Submitting..." : "Submit"}
                    />
                </div>
            </div>
        </Modal>
    );
}
