import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { nanoid } from "@reduxjs/toolkit";
import { Fragment, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ContactListPagination from "./ContactlistPagination";
import ContactListToolbar from "./ContactListToolbar";
import DeleteContactListModal from "./DeleteContactListModal";
import EditContactListNameModal from "./EditContactListNameModal";
import EnhancedTableHead from "./EnhancedTableHead";

import useMenu from "../../../hooks/useMenu";
import { setModal } from "../../../store/reducers/modals.slice";
import dateFromTimestamp from "../../../utils/dateFromTimestamp";
import AddContactListToProjectModal from "../components/AddContactListToProjectModal";
import { getComparator, stableSort } from "../utils/utils";

import {
    exportContactListCSV,
    handleRowClick,
    selectContactListCSVData,
    selectContactListSearchQuery,
    selectPage,
    selectRows,
    selectRowsPerPage,
    selectSelected,
    selectTableOrder,
    selectTableOrderBy,
    setContactListIdForProject,
} from "@/store/reducers/contact-list/contact-list.slice";
import { ListItem } from "@/store/reducers/contact-list/contact-list.type";


const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));

function ContactListMenu({
    removeHover,
    id,
    handleLinkClick,
}: {
    removeHover: () => void;
    id: string;
    handleLinkClick: () => void;
}) {
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu({
        onClose: removeHover,
    });

    const dispatch = useDispatch();

    const setId = () => dispatch(setContactListIdForProject(id));

    const actions = [
        {
            id: nanoid(),
            label: "Add to project",
            handleClick: () => {
                setId();
                dispatch(
                    setModal({
                        key: "showAddContactListToProjectModal",
                        value: true,
                    })
                );
            },
        },
        {
            id: nanoid(),
            label: "Export",
            handleClick: () => dispatch(exportContactListCSV({ id, handleLinkClick })),
        },
        {
            id: nanoid(),
            label: "Edit Name",
            handleClick: () => {
                setId();
                dispatch(
                    setModal({
                        key: "showEditContactListNameModal",
                        value: true,
                    })
                );
            },
        },
        {
            id: nanoid(),
            label: "Delete",
            handleClick: () => {
                setId();
                dispatch(setModal({ key: "showDeleteContactListModal", value: true }));
            },
        },
    ];

    const handleMenuItemClick = (callback: () => void) => {
        callback();
        handleClose();
    };

    return (
        <>
            <CustomButton variant="outlined" endIcon={<KeyboardArrowDownRoundedIcon />} onClick={handleClick}>
                More
            </CustomButton>
            <Menu sx={menuSX} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {actions.map(({ id, label, handleClick }) => {
                    return (
                        <MenuItem key={id} onClick={() => handleMenuItemClick(handleClick)}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

const headers = [
    { label: "name", key: "name" },
    { label: "email", key: "email" },
    { label: "company", key: "company" },
    { label: "industry", key: "industry" },
    { label: "jobTitle", key: "jobTitle" },
    { label: "companySize", key: "companySize" },
    { label: "country", key: "country" },
];

function ContactListName({ labelId, id, name }: { labelId: string; id: string; name: string }) {
    const [isHover, setHover] = useState(false);
    const exportRef = useRef<null | HTMLAnchorElement>(null);
    const csvData = useSelector(selectContactListCSVData);

    const handleLinkClick = () => {
        if (exportRef?.current) {
            setTimeout(() => {
                // @ts-ignore
                exportRef?.current?.link?.click();
            });
        }
    };

    return (
        <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{ width: "65%" }}
        >
            <Stack direction="row" spacing={3} alignItems="center">
                <Link
                    to={`/contact-lists/${id}`}
                    style={{
                        fontWeight: "600",
                        textDecoration: isHover ? "underline" : "none",
                        color: "#0c6caa",
                    }}
                >
                    {name}
                </Link>
                {isHover && (
                    <ContactListMenu removeHover={() => setHover(false)} id={id} handleLinkClick={handleLinkClick} />
                )}
            </Stack>
            <CSVLink
                headers={headers}
                data={csvData}
                filename={`${name}.csv`}
                style={{ display: "none" }}
                ref={exportRef}
                target="_blank"
            />
        </TableCell>
    );
}

function searchItems(query: string, items: ListItem[]): ListItem[] {
    const searchQuery = query.toLowerCase(); // Convert the query to lowercase for case-insensitive matching

    if (!searchQuery) {
        return items;
    }

    return items.filter((item) => {
        const itemName = item?.name?.toLowerCase() || "";
        const itemCreator = item?.creator?.toLowerCase() || "";

        return itemName.includes(searchQuery) || itemCreator.includes(searchQuery);
    });
}

function TableContent() {
    const dispatch = useDispatch();
    const rows = useSelector(selectRows);
    const page = useSelector(selectPage);
    const order = useSelector(selectTableOrder);
    const orderBy = useSelector(selectTableOrderBy);
    const rowsPerPage = useSelector(selectRowsPerPage);
    const selected = useSelector(selectSelected);
    const searchQuery = useSelector(selectContactListSearchQuery);

    const isRowSelected = (id: string): boolean => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(() => {
        const filteredRowsBySearch = searchItems(searchQuery, rows);
        return stableSort(filteredRowsBySearch, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [order, orderBy, page, rowsPerPage, searchQuery, rows]);

    return (
        <>
            {visibleRows.map(({ id, name, creator, lastUpdated, listSize }, index) => {
                const isItemSelected = isRowSelected(id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <Fragment key={id}>
                        <TableRow hover tabIndex={-1} key={id} selected={isItemSelected} sx={{ cursor: "pointer" }}>
                            <TableCell padding="checkbox" sx={{ width: "5%" }}>
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onChange={(_) => dispatch(handleRowClick(id))}
                                />
                            </TableCell>
                            <ContactListName labelId={labelId} id={id} name={name} />
                            <TableCell align="right" sx={{ width: "10%" }}>
                                {listSize}
                            </TableCell>
                            {/* <TableCell align="right" sx={{ width: "20%" }}>
                                    {creator}
                                </TableCell> */}
                            <TableCell align="right" sx={{ paddingRight: "2rem", width: "20%" }}>
                                {dateFromTimestamp(lastUpdated)}
                            </TableCell>
                        </TableRow>
                    </Fragment>
                );
            })}
        </>
    );
}
export default function ContactListContent() {
    return (
        <Stack spacing={1}>
            <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <ContactListToolbar />
                    <TableContainer sx={{ maxHeight: "70vh" }}>
                        <Table sx={{ minWidth: 750 }} stickyHeader>
                            <EnhancedTableHead />
                            <TableBody>
                                <TableContent />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ContactListPagination />
                    <AddContactListToProjectModal />
                    <DeleteContactListModal />
                    <EditContactListNameModal />
                </Paper>
            </Box>
        </Stack>
    );
}
