import { Box, Button, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CardComponent from "./CardComponent";
import { MarketConstants } from "./MarketConstants";

import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { marketResearch } from "../../index.reducer";
import { IMarketResearchPayload } from "../../project.types";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
        </div>
    );
}
interface ChildrenData {
    logo: string;
    title: string;
    description: string;
    selected?: boolean;
}

interface InterfaceData {
    title: string;
    heading?: string;
    description: string;
    EGdescription?: string;
    subDescription?: string;
    navigation?: string;
    children: ChildrenData[];
}

function RenderData({ props, setParentData }: { props: InterfaceData; setParentData: any }) {
    const [data, setData] = useState<InterfaceData>(props);

    const selectCard = (index: number, selected: boolean) => {
        const updatedChildren = data?.children?.map((child, idx) => (idx === index ? { ...child, selected } : child));
        setData({ ...data, children: updatedChildren });
        setParentData(updatedChildren);
    };

    return (
        <div>
            <Typography sx={{ fontWeight: 600, fontSize: "20px", marginLeft: "15px" }} variant="h4">
                {props?.title}
            </Typography>

            <Typography sx={{ width: "80%", marginLeft: "15px", fontSize: "14px", marginTop: "10px" }} variant="h4">
                {props?.description}{" "}
            </Typography>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {data?.children?.length > 0 &&
                    data?.children?.map((integration, index) => {
                        return (
                            <div key={index} style={{ margin: "15px" }}>
                                <CardComponent key={index} data={integration} index={index} selectCard={selectCard} />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default function MarketResearchData() {
    const dispatch = useDispatch();
    const params = useParams();
    const [value, setValue] = useState<number>(0);
    const [marketData, setMarketData] = useState<any>();
    const tabs = ["All queries"];
    const queries = MarketConstants();
    const projectId = params?.id;
    const isLoading = useSelector(checkIfLoading(marketResearch.type));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleDataUpdate = (data: InterfaceData) => {
        setMarketData(data);
    };

    const titles = marketData
        ?.filter((item: any) => item?.selected)
        .map((item: any) => item?.title.replace(/\s+/g, ""));

    const payload: IMarketResearchPayload = {
        titles: titles,
        projectId: +projectId,
        onSuccess: () => {
            dispatch(setSuccessNotification("Successfully submitted the details"));
            window.open(`/market-research/${projectId}`);
        },
    };

    const handleSubmit = () => {
        dispatch(marketResearch(payload));
    };

    return (
        <>
            <Typography sx={{ fontWeight: 600, fontSize: "22px", padding: "0px 10px" }} variant="h4">
                Market research
            </Typography>
            <Tabs value={value} onChange={handleChange} sx={{ borderBottom: 1, borderColor: "divider" }}>
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => {
                return (
                    <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
                        <CustomTabPanel key={index} value={value} index={index}>
                            {tab === "All queries"
                                ? Object.keys(queries).map((key, index) => {
                                    return (
                                        <RenderData
                                            key={index}
                                            props={queries[key]}
                                            setParentData={handleDataUpdate}
                                        />
                                    );
                                })
                                : tabs[value]?.toLowerCase() === tab.toLowerCase() && (
                                    <RenderData props={queries[tab]} setParentData={handleDataUpdate} />
                                )}
                        </CustomTabPanel>
                    </div>
                );
            })}
            <div style={{ justifyContent: "right", display: "flex", marginTop: "10px" }}>
                <Button variant="contained" sx={{ minWidth: "max-content" }} onClick={handleSubmit}>
                    {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Submit"}
                </Button>
            </div>
        </>
    );
}
