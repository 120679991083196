import React, { useEffect, useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import { IconButton, Tooltip as JoyTooltip } from "@mui/joy";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { getAddtoBlocklist } from "@/store/reducers/project/project.reducer";
import { CallModal } from "./CallModal";
import { useCallContext } from "./TwilloCallContext";

const TwilioVoice: React.FC<any> = ({ candidate }) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { setPhoneNumber } = useCallContext();

    const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setPhoneNumber("");
        setEditPhoneNumber(false);
    };


    const handleBlockCandidate = async () => {
        const validNumber = candidate?.phone?.find((phone) => phone !== null);
        if (validNumber) {
            dispatch(getAddtoBlocklist({ candidateId: candidate._id }));
        } else {
            dispatch(setErrorNotification("Candidate cannot be blocked without a phone number"));
        }
    };
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CallModal
                modalOpen={modalOpen}
                toggleModal={toggleModal}
                candidate={candidate}
                editPhoneNumber={editPhoneNumber}
                setEditPhoneNumber={setEditPhoneNumber}
                handleBlockCandidate={handleBlockCandidate}
            />

            <div
                style={{
                    position: "relative",
                    display: "flex",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Box sx={{ width: "32px", alignContent: "center", borderRadius: "50%" }}>
                    <JoyProvider>
                        <JoyTooltip title="Place a call" placement="bottom" size="sm">
                            <IconButton
                                size="sm"
                                color="neutral"
                                onClick={(e) => {
                                    setModalOpen(true);
                                }}
                            >
                                <CallIcon />
                            </IconButton>
                        </JoyTooltip>
                    </JoyProvider>
                </Box>
            </div>
        </div>
    );
};

export default TwilioVoice;
