import CachedIcon from "@mui/icons-material/Cached";
import SensorOccupiedRoundedIcon from "@mui/icons-material/SensorOccupiedRounded";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isUndefined } from "lodash";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import CustomTextField from "./CustomTextField";
import HeaderTabs from "./HeaderTabs";
import { EmptyTemplatePlaceholder } from "./HyperPersonalizationPane";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { ErrorPlaceholder } from "../../../components/ErrorPlaceholder";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import useMenu from "../../../hooks/useMenu";
import usePersonalizeValues, { actionsWithSignature } from "../../../hooks/usePersonalizeValues";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    BASE_TEMPLATE_VALID_TAGS,
    MAX_LINKEDIN_CONN_REQ_CHAR_COUNT,
    SMS_BODY_LIMIT,
    USER_MAX_LINKEDIN_REQ_CHART_COUNT,
} from "../../../utils/Constants";
import {
    calculateQuillCharacterCount,
    extractStringsInRoundBrackets,
    extractTextInCurlyBraces,
    sanitizeTemplateBody,
} from "../../../utils/helper";
import CustomMUIInput from "../../project/components/CustomMUIInput";
import CalendlyMenu from "../molecules/CalendlyMenu";
import EditInMailReminderMessage from "../molecules/EditInMailReminderMessage";
import EditReminderMessage from "../molecules/EditReminderMessage";
import PersonalizedTagsMenu from "../molecules/PersonalizedTagsMenu";
import quillStyles from "../styles/hyperPersonalizedModal.module.scss";

import { checkFollowUpInMailAllowed, checkSMSEnabled, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { ValidateTags } from "@/store/reducers/trigger-workflow/customTemplate.types";
import {
    changeCustomBody,
    changeCustomSubject,
    checkErrorWhileFetchingBaseTemplates,
    fetchBaseTemplates,
    selectBaseTemplatesCustomEmail,
    selectBaseTemplatesCustomFollowUpEmail,
    selectBaseTemplatesCustomInMail,
    selectBaseTemplatesCustomLinkedInConnReq,
    selectBaseTemplatesSMS,
    submitBaseTemplates,
    submitProjectBaseTemplates,
} from "@/store/reducers/trigger-workflow/customTemplates.slice";
import { selectCustomHeaderTab } from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import { Variant } from "@/store/reducers/vetting-criteria/vettingCriteria.slice";

function getTabPanel(tabIndex: number) {
    switch (tabIndex) {
        case 0:
            return <EditBaseTemplatesCustomEmail />;
        case 1:
            return <EditBaseTemplatesCustomConnectionRequest />;
        case 2:
            return <EditBaseTemplatesFollowUpEmail />;
        case 3:
            return <EditBaseTemplatesCustomInMail />;
        case 4:
            return <EditBaseTemplatesSMS />;
        default:
            return null;
    }
}

type RenderBaseTemplatesProps = {
    variant?: Variant;
};

export function RenderBaseTemplates({ variant = "NORMAL" }: RenderBaseTemplatesProps) {
    const value = useSelector(selectCustomHeaderTab);

    return (
        <Stack spacing={2} sx={{ overflow: "hidden" }}>
            {/* <HeaderTabs value={value} handleChange={handleChange} /> */}
            {getTabPanel(value)}
            <SubmitCustomTemplates variant={variant} />
        </Stack>
    );
}

export default function BaseTemplates() {
    const dispatch = useDispatch();
    const { projectId, ...rest } = useWorkflowLocationState();
    const isFetchingBaseTemplates = useSelector(checkIfLoading(fetchBaseTemplates.type));
    const isErrorWhileFetchingBaseTemplates = useSelector(checkErrorWhileFetchingBaseTemplates);

    useEffect(() => {
        if (projectId) {
            dispatch(
                fetchBaseTemplates({
                    projectId,
                    ...rest,
                })
            );
        }
    }, [projectId]);

    if (isErrorWhileFetchingBaseTemplates) {
        return (
            <ErrorPlaceholder
                reload={() => {
                    dispatch(
                        fetchBaseTemplates({
                            projectId,
                            ...rest,
                        })
                    );
                }}
                containerProps={{
                    minHeight: "50vh",
                    justifyContent: "center",
                }}
            >
                <CachedIcon fontSize="small" />
            </ErrorPlaceholder>
        );
    }

    if (isFetchingBaseTemplates) {
        return (
            <Stack justifyContent="center" alignItems="center" py={20}>
                <CircularProgress
                    size={30}
                    style={{
                        color: "#0891b2",
                    }}
                />
            </Stack>
        );
    }

    return <RenderBaseTemplates />;
}

export function useWorkflowLocationState() {
    const location = useLocation();
    const candidateIds = location.state?.candidates;
    const filters = location?.state?.filters;
    const projectId = location?.state?.projectId;
    const isAllCandidatesSelected = location?.state?.isAllProjectCandidatesSelected;
    const searchQuery = location?.state?.searchQuery;
    const showByPersonalEmails = location?.state?.showByPersonalEmails;

    return {
        candidateIds,
        filters,
        projectId,
        isAllCandidatesSelected,
        searchQuery,
        showByPersonalEmails,
    };
}

export function ValidateWorkflowsPageLocationState({ children }: { children: React.ReactNode }) {
    const { projectId, candidateIds } = useWorkflowLocationState();

    if (!projectId || !candidateIds.length) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
}

function useSubmitBaseTemplatesPayload() {
    const location = useLocation();
    const projectId = location?.state?.projectId;
    const isAllCandidatesSelected = location?.state?.isAllProjectCandidatesSelected;
    const candidateIds = location.state?.candidates;

    return { projectId, isAllCandidatesSelected, candidateIds };
}

/**
 * Checks if the given text contains valid curly brace tags according to BASE_TEMPLATE_VALID_TAGS
 *
 * @param text - The text to check
 * @returns boolean - Returns true if the text contains only valid tags or one invalid tag. Returns false otherwise.
 */
function isTextWithValidCurlyBracesTags(text: string) {
    const curlyBracesTags = extractTextInCurlyBraces(text);

    const invalidTags = curlyBracesTags.filter((val) => !BASE_TEMPLATE_VALID_TAGS.includes(val.toLowerCase()));

    return invalidTags.length <= 1;
}

/**
 * Checks if the given text contains valid round brace tags according to BASE_TEMPLATE_ROUND_VALID_TAGS
 *
 * @param text - The text to check
 * @returns boolean - Returns true if the text does not contain any invalid round brace tags.
 */
function isTextWithValidRoundBracesTags(text: string) {
    const roundBracesTags = extractStringsInRoundBrackets(text);
    const invalidTagsWithRoundBrackets = roundBracesTags.filter((val) => BASE_TEMPLATE_VALID_TAGS.includes(val));

    return invalidTagsWithRoundBrackets.length === 0;
}

function validateCurlyBracesTags({ email, followUp, inMail, linkedInReq, sms }: ValidateTags) {
    return {
        Email: isTextWithValidCurlyBracesTags(email.body || "") && isTextWithValidCurlyBracesTags(email.subject || ""),
        "in-Mail":
            isTextWithValidCurlyBracesTags(inMail.body || "") && isTextWithValidCurlyBracesTags(inMail.subject || ""),
        "Follow-up email": isTextWithValidCurlyBracesTags(followUp.body || ""),
        "LinkedIn request": isTextWithValidCurlyBracesTags(linkedInReq.body || ""),
        SMS: isTextWithValidCurlyBracesTags(sms.body || ""),
    };
}

function validateRoundBracesTags({ email, followUp, inMail, linkedInReq, sms }: ValidateTags) {
    return {
        Email: isTextWithValidRoundBracesTags(email.body || "") && isTextWithValidRoundBracesTags(email.subject || ""),
        "in-Mail":
            isTextWithValidRoundBracesTags(inMail.body || "") && isTextWithValidRoundBracesTags(inMail.subject || ""),
        "Follow-up email": isTextWithValidRoundBracesTags(followUp.body || ""),
        "LinkedIn request": isTextWithValidRoundBracesTags(linkedInReq.body || ""),
        SMS: isTextWithValidRoundBracesTags(sms.body || ""),
    };
}

function validateTemplates({ sms }: { sms: string }) {
    const sanitizedSMS = sanitizeTemplateBody(sms);
    return {
        SMS: sanitizedSMS.length > SMS_BODY_LIMIT,
    };
}

function generateTagsError({ email, followUp, inMail, linkedInReq, sms }: ValidateTags) {
    const curlyBracesErrorTemplates: string[] = [];
    const roundBracesErrorTemplates: string[] = [];
    const validationErrorTemplates: string[] = [];
    const isErrorInCurlyBracesTags = validateCurlyBracesTags({
        email,
        followUp,
        inMail,
        linkedInReq,
        sms,
    });
    const isErrorInRoundBracesTags = validateRoundBracesTags({
        email,
        followUp,
        inMail,
        linkedInReq,
        sms,
    });

    const validationErrors = validateTemplates({
        sms: sms.body || "",
    });

    // validate sms here
    Object.entries(validationErrors).forEach(([key, val]) => {
        if (val === true) {
            validationErrorTemplates.push(key);
        }
    });

    Object.entries(isErrorInCurlyBracesTags).forEach(([key, val]) => {
        if (val === false) {
            curlyBracesErrorTemplates.push(key);
        }
    });

    Object.entries(isErrorInRoundBracesTags).forEach(([key, val]) => {
        if (val === false) {
            roundBracesErrorTemplates.push(key);
        }
    });

    return {
        curlyBracesErrorMessage: curlyBracesErrorTemplates.length
            ? `Formatting errors in: ${curlyBracesErrorTemplates.join(
                  "; "
              )} Template. Kindly ensure personalization elements contain {name}, {role}, or {company}. You may include one additional prompt inside '{}' brackets if needed.`
            : null,
        roundBracesErrorMessage: roundBracesErrorTemplates.length
            ? `Formatting errors in: ${roundBracesErrorTemplates.join(
                  "; "
              )} Template. Kindly Replace (job role) and (my name)`
            : null,
        validationErrors: validationErrorTemplates.length
            ? `${validationErrorTemplates.join(
                  "; "
              )} length exceeds ${SMS_BODY_LIMIT} characters. Please shorten your message.`
            : null,
    };
}

function validateTags(args: ValidateTags) {
    const curlyBracesErrors = validateCurlyBracesTags(args);
    const roundBracesErrors = validateRoundBracesTags(args);

    const isErrorInCurlyBracesTags = Object.values(curlyBracesErrors).every((val) => val === true);

    const isErrorInRoundBracesTags = Object.values(roundBracesErrors).every((val) => val === true);

    return isErrorInCurlyBracesTags && isErrorInRoundBracesTags;
}

type SubmitCustomTemplatesProps = {
    variant?: Variant;
};

function SubmitCustomTemplates({ variant = "NORMAL" }: SubmitCustomTemplatesProps) {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const projectIdFromUrl = searchParams.get("project");
    const value = useSelector(selectCustomHeaderTab);
    const connReq = useSelector(selectBaseTemplatesCustomLinkedInConnReq);
    const email = useSelector(selectBaseTemplatesCustomEmail);
    const inMail = useSelector(selectBaseTemplatesCustomInMail);
    const followUp = useSelector(selectBaseTemplatesCustomFollowUpEmail);
    const sms = useSelector(selectBaseTemplatesSMS);
    const followUpInMailAllowed = useSelector(checkFollowUpInMailAllowed);
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const user = useSelector(selectUser);
    const isTagsValid = validateTags({
        email,
        inMail,
        followUp,
        linkedInReq: connReq,
        sms: {
            // pass actual body if sms enabled
            body: isSMSEnabled ? sms.body : null,
        },
    });

    console.log({ value });

    const errorMessage = generateTagsError({
        email,
        inMail,
        followUp,
        linkedInReq: connReq,
        sms: {
            // pass actual body if sms enabled
            body: isSMSEnabled ? sms.body : null,
        },
    });
    const { candidateIds, isAllCandidatesSelected, projectId } = useSubmitBaseTemplatesPayload();

    const characterLength = calculateQuillCharacterCount(connReq.body || "");
    const smsLength = calculateQuillCharacterCount(sms?.body || "");
    const userMaxLinkedinReqChartCount = user?.userLiProfile?.accountType
        ? USER_MAX_LINKEDIN_REQ_CHART_COUNT[user.userLiProfile.accountType]
        : MAX_LINKEDIN_CONN_REQ_CHAR_COUNT;

    const isLinkedInCharLimitExceeded = characterLength > userMaxLinkedinReqChartCount;
    const isSMSLimitExceeded = smsLength > SMS_BODY_LIMIT;
    const isBlendSearchScreen = variant === "SEARCH";

    const isSubmitting = useSelector(
        checkIfLoading(isBlendSearchScreen ? submitProjectBaseTemplates.type : submitBaseTemplates.type)
    );

    const isSMSInValid = isSMSLimitExceeded && isSMSEnabled && Boolean(errorMessage?.validationErrors);
    const isDisable = isSubmitting || isLinkedInCharLimitExceeded || isSMSInValid;
    // || !isTagsValid;

    const isSMSTab = value === 4 && isSMSEnabled;

    const handleSubmit = () => {
        if (isBlendSearchScreen && projectIdFromUrl) {
            dispatch(
                submitProjectBaseTemplates({
                    projectId: projectIdFromUrl,
                })
            );
        } else {
            dispatch(
                submitBaseTemplates({
                    projectId,
                    candidateIds,
                    isAllCandidatesSelected,
                })
            );
        }
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
            <Stack maxWidth={550} gap={0.5}>
                {value === 1 && (
                    <Typography
                        variant="body2"
                        fontWeight={isLinkedInCharLimitExceeded ? 600 : 500}
                        sx={(theme) => ({
                            color: isLinkedInCharLimitExceeded ? theme.palette.error.light : "#000",
                        })}
                    >
                        {characterLength} / {userMaxLinkedinReqChartCount}{" "}
                        {isLinkedInCharLimitExceeded && "(LinkedIn connect request characters limit exceeded)"}
                    </Typography>
                )}
                {isSMSTab && (
                    <Typography
                        variant="body2"
                        fontWeight={isSMSLimitExceeded ? 600 : 500}
                        sx={(theme) => ({
                            color: isSMSLimitExceeded ? theme.palette.error.light : "#000",
                        })}
                    >
                        {smsLength} / {SMS_BODY_LIMIT}{" "}
                    </Typography>
                )}
                {!isTagsValid && errorMessage.curlyBracesErrorMessage && (
                    <Typography
                        textAlign="left"
                        variant="body2"
                        fontWeight={isLinkedInCharLimitExceeded ? 600 : 500}
                        sx={(theme) => ({
                            color: theme.palette.error.light,
                            fontWeight: theme.typography.fontWeightMedium,
                            maxWidth: "50vw",
                            fontSize: "12px",
                        })}
                    >
                        {errorMessage.curlyBracesErrorMessage}
                    </Typography>
                )}
                {!isTagsValid && errorMessage.roundBracesErrorMessage && (
                    <Typography
                        textAlign="left"
                        variant="body2"
                        fontWeight={isLinkedInCharLimitExceeded ? 600 : 500}
                        sx={(theme) => ({
                            fontSize: "12px",
                            color: theme.palette.error.light,
                            fontWeight: theme.typography.fontWeightMedium,
                            maxWidth: "50vw",
                        })}
                    >
                        {errorMessage.roundBracesErrorMessage}
                    </Typography>
                )}
                {errorMessage.validationErrors && isSMSEnabled ? (
                    <Typography
                        textAlign="left"
                        variant="body2"
                        fontWeight={isSMSLimitExceeded ? 600 : 500}
                        sx={(theme) => ({
                            fontSize: "12px",
                            color: theme.palette.error.light,
                            fontWeight: theme.typography.fontWeightMedium,
                            maxWidth: "50vw",
                        })}
                    >
                        {errorMessage.validationErrors}
                    </Typography>
                ) : null}
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ alignSelf: "flex-start" }}>
                {value === 1 && <EditReminderMessage />}

                {value === 3 && followUpInMailAllowed && <EditInMailReminderMessage />}
                <Button variant="contained" onClick={handleSubmit} disabled={isDisable}>
                    <ButtonTextWithLoading
                        isLoading={isSubmitting}
                        text={isBlendSearchScreen ? "Save" : "Save and Preview"}
                    />
                </Button>
            </Stack>
        </Stack>
    );
}

function EditBaseTemplatesCustomEmail() {
    const email = useSelector(selectBaseTemplatesCustomEmail);
    const subject = email.subject;
    const body = email.body;
    const dispatch = useDispatch();
    const {
        bodyRef,
        actions,
        setAddTo,
        subjectRef,
        calendly: { calendlyActions, showCalendlyMenu, handleAddCalendlyLink },
    } = usePersonalizeValues({
        actions: actionsWithSignature,
        handleSubjectChange: (cursorPosition, label) => {
            if (subject) {
                const newSubject = subject.substring(0, cursorPosition) + label + subject.substring(cursorPosition);
                dispatch(
                    changeCustomSubject({
                        key: "email",
                        subject: newSubject,
                    })
                );
            }
        },
    });
    const { handleClick, handleClose, getMenuProps: getDefaultMenuProps } = useMenu();

    const handleSubjectChange = (value: string) => {
        dispatch(
            changeCustomSubject({
                key: "email",
                subject: value,
            })
        );
    };

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCustomBody({
                key: "email",
                body: value,
            })
        );
    };

    if (isUndefined(subject) || isUndefined(body)) {
        return <EmptyTemplatePlaceholder />;
    }

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <HeaderTabs />
                <Stack direction="row">
                    {showCalendlyMenu && (
                        <CalendlyMenu
                            calendlyActions={calendlyActions}
                            addCalendlyTag={(text, link) => handleAddCalendlyLink(text, link)}
                        />
                    )}
                    <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                        <SensorOccupiedRoundedIcon fontSize="large" sx={{ height: "28px", width: "28px" }} />
                    </IconButton>
                </Stack>
            </Stack>
            <PersonalizedTagsMenu
                actions={actions}
                menuProps={{
                    ...getDefaultMenuProps(),
                    onClose: handleClose,
                }}
            />
            <Stack spacing={2}>
                <CustomTextField
                    inputRef={subjectRef}
                    label="Subject"
                    style={{ flex: 1 }}
                    value={subject}
                    onFocus={() => setAddTo("SUBJECT")}
                    onChange={(e) => handleSubjectChange(e.target.value)}
                />
                <RichTextEditor
                    enableImageModule
                    ref={bodyRef}
                    value={body?.replaceAll("\n", "<br>")}
                    className={quillStyles.hyperQuill}
                    onChange={(value) => handleBodyChange(value)}
                    onFocus={() => setAddTo("BODY")}
                />
            </Stack>
        </Stack>
    );
}

function EditBaseTemplatesCustomConnectionRequest() {
    const connReq = useSelector(selectBaseTemplatesCustomLinkedInConnReq);
    const dispatch = useDispatch();
    const body = connReq.body;
    const { bodyRef, setAddTo, actions } = usePersonalizeValues();

    const { handleClick, handleClose, getMenuProps } = useMenu();

    if (isUndefined(body)) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCustomBody({
                key: "linkedInReq",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <HeaderTabs />
                <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                    <SensorOccupiedRoundedIcon fontSize="large" sx={{ height: "28px", width: "28px" }} />
                </IconButton>
            </Stack>
            <PersonalizedTagsMenu actions={actions} menuProps={{ ...getMenuProps(), onClose: handleClose }} />
            <Stack spacing={2}>
                <ReactQuill
                    ref={bodyRef}
                    value={body?.replaceAll("\n", "<br>")}
                    className={quillStyles.hyperQuill}
                    onChange={(value) => handleBodyChange(value)}
                    onFocus={() => setAddTo("BODY")}
                />
            </Stack>
        </Stack>
    );
}

function EditBaseTemplatesFollowUpEmail() {
    const dispatch = useDispatch();
    const followUpEmail = useSelector(selectBaseTemplatesCustomFollowUpEmail);
    const body = followUpEmail.body;
    const {
        bodyRef,
        setAddTo,
        actions,
        calendly: { calendlyActions, showCalendlyMenu, handleAddCalendlyLink },
    } = usePersonalizeValues({ actions: actionsWithSignature });

    const { handleClick, handleClose, getMenuProps } = useMenu();

    if (isUndefined(body)) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCustomBody({
                key: "followUp",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <HeaderTabs />
                <Stack direction="row">
                    {showCalendlyMenu && (
                        <CalendlyMenu
                            calendlyActions={calendlyActions}
                            addCalendlyTag={(text, link) => handleAddCalendlyLink(text, link)}
                        />
                    )}
                    <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                        <SensorOccupiedRoundedIcon fontSize="large" sx={{ height: "28px", width: "28px" }} />
                    </IconButton>
                </Stack>
            </Stack>
            <PersonalizedTagsMenu
                actions={actions}
                menuProps={{
                    ...getMenuProps(),
                    onClose: handleClose,
                }}
            />
            <RichTextEditor
                enableImageModule
                ref={bodyRef}
                value={body?.replaceAll("\n", "<br>")}
                className={quillStyles.hyperQuill}
                onChange={(value) => handleBodyChange(value)}
                onFocus={() => setAddTo("BODY")}
            />
        </Stack>
    );
}

function EditBaseTemplatesCustomInMail() {
    const dispatch = useDispatch();
    const inMail = useSelector(selectBaseTemplatesCustomInMail);
    const body = inMail.body;
    const subject = inMail.subject;
    const {
        bodyRef,
        actions,
        setAddTo,
        subjectRef,
        calendly: { calendlyActions, handleAddCalendlyLink, showCalendlyMenu },
    } = usePersonalizeValues({
        handleSubjectChange: (cursorPosition, label) => {
            if (subject) {
                const newSubject = subject.substring(0, cursorPosition) + label + subject.substring(cursorPosition);
                dispatch(
                    changeCustomSubject({
                        key: "inMail",
                        subject: newSubject,
                    })
                );
            }
        },
    });

    const { handleClick, handleClose, getMenuProps: getDefaultMenuProps } = useMenu();

    if (isUndefined(inMail)) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleSubjectChange = (value: string) => {
        dispatch(
            changeCustomSubject({
                key: "inMail",
                subject: value,
            })
        );
    };

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCustomBody({
                key: "inMail",
                body: value,
            })
        );
    };

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image", "video"],
                ["clean"],
                [{ align: [] }],
            ],
            // handlers: {
            //     image: imageHandler,
            // },
        },
    };

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <HeaderTabs />
                <Stack direction="row">
                    {showCalendlyMenu && (
                        <CalendlyMenu
                            calendlyActions={calendlyActions}
                            addCalendlyTag={(text, link) => handleAddCalendlyLink(text, link)}
                        />
                    )}
                    <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                        <SensorOccupiedRoundedIcon fontSize="large" sx={{ height: "28px", width: "28px" }} />
                    </IconButton>
                </Stack>
            </Stack>
            {/* {!isLoading && !isError ? (
                <PersonalizedTagsMenu
                    actions={calendlyActions}
                    menuProps={{
                        ...getCalendlyMenuProps(),
                        onClose: handleCalendlyMenuClose,
                    }}
                />
            ) : null} */}
            <PersonalizedTagsMenu
                actions={actions}
                menuProps={{
                    ...getDefaultMenuProps(),
                    onClose: handleClose,
                }}
            />
            <CustomTextField
                onFocus={() => setAddTo("SUBJECT")}
                inputRef={subjectRef}
                label="Subject"
                style={{ flex: 1 }}
                value={subject}
                onChange={(e) => handleSubjectChange(e.target.value)}
            />
            <ReactQuill
                ref={bodyRef}
                value={body?.replaceAll("\n", "<br>")}
                className={quillStyles.hyperQuill}
                onChange={(value) => handleBodyChange(value)}
                onFocus={() => setAddTo("BODY")}
                modules={modules}
            />
        </Stack>
    );
}

function EditBaseTemplatesSMS() {
    const dispatch = useDispatch();
    const sms = useSelector(selectBaseTemplatesSMS);

    const body = sms.body;

    const { subjectRef, setAddTo, actions } = usePersonalizeValues({
        handleSubjectChange: (cursorPosition, label) => {
            if (body) {
                const newBody = body.substring(0, cursorPosition) + label + body.substring(cursorPosition);

                dispatch(
                    changeCustomBody({
                        key: "sms",
                        body: newBody,
                    })
                );
            }
        },
    });

    const { handleClick, handleClose, getMenuProps } = useMenu();

    if (isUndefined(body)) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCustomBody({
                key: "sms",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <HeaderTabs />
                <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                    <SensorOccupiedRoundedIcon fontSize="large" sx={{ height: "28px", width: "28px" }} />
                </IconButton>
            </Stack>
            <PersonalizedTagsMenu actions={actions} menuProps={{ ...getMenuProps(), onClose: handleClose }} />
            <Stack spacing={2}>
                <CustomMUIInput
                    inputRef={subjectRef}
                    value={body}
                    rows={5}
                    onChange={(e) => handleBodyChange(e.target.value)}
                    multiline
                    onFocus={() => setAddTo("SUBJECT")}
                    sx={{
                        "& MuiInputBase-input": {
                            backgroundColor: "#fff",
                        },
                        "& .MuiInputBase-root": {
                            padding: "0 !important",
                        },
                    }}
                />
            </Stack>
        </Stack>
    );
}
