import CampaignIcon from "@mui/icons-material/Campaign";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsightsIcon from "@mui/icons-material/Insights";
import StyleIcon from "@mui/icons-material/Style";
import { Box, Button } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography1 from "@mui/joy/Typography";
import {
    CircularProgress,
    Experimental_CssVarsProvider as CsssProvider,
    ToggleButtonGroupProps,
    Tooltip,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useApplyWritingStylesMutation, useSaveWritingStylesMutation } from "../../../store/apis/outreach/outreach.api";

import { selectWritingStyleChangePayload, setWritingStyleChangePayload } from "@/store/reducers/outreach/outreach.slice";

const GeneratedWritingStyleComponent = ({
    dataFormatOfStyles,
    projectId,
    onClose,
    isDataChanged,
    setIsDataChanged,
    refetch,
}: {
    dataFormatOfStyles: any;
    projectId: any;
    onClose: () => void;
    isDataChanged: boolean;
    setIsDataChanged: (value: boolean) => void;
    refetch: () => void;
}) => {
    const [hovered, setHovered] = useState<string | null>(null);
    const [alignment, setAlignment] = useState("");
    const dispatch = useDispatch();
    const writingStyleChangePayload = useSelector(selectWritingStyleChangePayload);
    const [saveWritingStyles, { isLoading }] = useSaveWritingStylesMutation();

    const [applyWritingStyles, { isLoading: isApplying }] = useApplyWritingStylesMutation();

    const saveWritingStyle = () => {
        saveWritingStyles({
            ...dataFormatOfStyles,
            projectId: projectId,
            Status: "yes",
        })
            .unwrap()
            .then(() => {
                setIsDataChanged(false);
                dispatch(
                    setWritingStyleChangePayload({
                        ...writingStyleChangePayload,
                        isSaved: !writingStyleChangePayload?.isSaved,
                    })
                );
            });
    };

    const handleAlignment = (newAlignment: ToggleButtonGroupProps["value"]) => {
        if (newAlignment === "yes") {
            setAlignment("yes");
            dispatch(
                setWritingStyleChangePayload({
                    ...writingStyleChangePayload,
                    isApplied: !writingStyleChangePayload?.isApplied,
                })
            );
        } else {
            setAlignment("no");
            dispatch(
                setWritingStyleChangePayload({
                    ...writingStyleChangePayload,
                    isApplied: !writingStyleChangePayload?.isApplied,
                })
            );
        }
        handleApplyStyle(newAlignment);
    };
    const handleApplyStyle = (status: string) => {
        applyWritingStyles({ projectId: projectId, Status: status })
            .unwrap()
            .then(() => {
                onClose();
                refetch();
            });
    };

    const icons: { [key: string]: JSX.Element } = {
        "Vocabulary and Language Pattern": <InsightsIcon fontSize="small" />,
        Style: <StyleIcon fontSize="small" />,
        Structure: <InsertChartIcon fontSize="small" />,
        Tone: <CampaignIcon fontSize="small" />,
    };
    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="start">
                <Typography1 level="title-md" sx={{ mb: 2, ml: 3.5 }}>
                    Your writing style, summarized
                </Typography1>
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: 600,
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                        gap: 3,
                        margin: "0 auto",
                    }}
                >
                    {Object?.keys(dataFormatOfStyles || {})?.map(
                        (key, index) =>
                            //if key is Notable Phrases or samples then skip
                            key !== "Notable Phrases" &&
                            key !== "applyStyle" &&
                            key !== "samples" && (
                                <Card
                                    variant="outlined"
                                    key={index}
                                    onMouseEnter={() => {
                                        setHovered(key);
                                    }}
                                    onMouseLeave={() => {
                                        setHovered(null);
                                    }}
                                    sx={{
                                        minHeight: 230,
                                        width: 290,
                                        backgroundColor: hovered === key ? "#f0f4f8" : "white",
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                        }}
                                    >
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Box sx={{ marginTop: "2px", marginRight: "5px" }}>{key && icons[key]}</Box>
                                            <Typography1 level="title-md">
                                                {key &&
                                                    (key === "Vocabulary and Language Patterns"
                                                        ? "Language Patterns"
                                                        : key)}{" "}
                                            </Typography1>
                                        </Box>
                                        <Typography1
                                            level="body-xs"
                                            sx={{
                                                mt: 2,
                                                textAlign: "justify",
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                                hyphens: "auto",
                                            }}
                                        >
                                            {dataFormatOfStyles?.[key]}
                                        </Typography1>
                                    </CardContent>
                                </Card>
                            )
                    )}
                </Box>
                {dataFormatOfStyles?.["Notable Phrases"]?.length > 0 && (
                    <Box sx={{ my: 2, ml: 3.5 }}>
                        <Typography1 level="title-md" sx={{ fontSize: "18px" }}>
                            Notable phrases you used
                        </Typography1>
                        {dataFormatOfStyles?.["Notable Phrases"]?.map((phrase: string, index: number) => (
                            <ul key={index}>
                                <li
                                    style={{
                                        textAlign: "justify",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        hyphens: "auto",
                                        fontSize: "13px",
                                        marginTop: "5px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    {phrase}
                                </li>
                            </ul>
                        ))}
                    </Box>
                )}
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mb: 2 }}>
                <Button
                    onClick={() => saveWritingStyle()}
                    sx={{
                        height: "36px",
                        bgcolor: "#0891b2",
                        "&:hover": {
                            bgcolor: "#0891b2e6",
                        },
                        "&.Mui-disabled": {
                            bgcolor: "#0891b2",
                            opacity: 0.5,
                        },
                        "& .MuiCircularProgress-root": {
                            color: "white",
                        },
                    }}
                    disabled={!isDataChanged}
                >
                    {isLoading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : isDataChanged ? (
                        "Save"
                    ) : (
                        "Saved"
                    )}
                </Button>
                <CsssProvider>
                    <ToggleButtonGroup
                        value={alignment}
                        disabled={isDataChanged}
                        exclusive
                        onChange={(event, newAlignment) => handleAlignment(newAlignment)}
                        aria-label="text alignment"
                        sx={{ ml: 2, height: "36px" }}
                        //disable hover effect
                        onMouseEnter={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <ToggleButton
                            value="no"
                            aria-label="centered"
                            disableRipple
                            disabled={isApplying}
                            disableFocusRipple
                            onMouseEnter={(event) => {
                                event.stopPropagation();
                            }}
                            sx={{
                                fontSize: "12px",
                                textTransform: "none",
                                // backgroundColor: alignment === "center" ? "#0b6bcb" : "inherit",
                                color: "red",
                            }}
                        >
                            {isApplying && alignment === "no" ? (
                                <CircularProgress size={20} sx={{ color: "#0891b2" }} />
                            ) : (
                                "Don't Apply Style"
                            )}
                        </ToggleButton>
                        <ToggleButton
                            value="yes"
                            onMouseEnter={(event) => {
                                event.stopPropagation();
                            }}
                            disabled={isApplying}
                            disableRipple
                            aria-label="left aligned"
                            sx={{
                                fontSize: "12px",
                                textTransform: "none",
                                // backgroundColor: alignment === "left" ? "#0b6bcb" : "inherit",
                                color: "#0891b2",
                                "&.Mui-selected": {
                                    color: "#0891b2",
                                },
                            }}
                        >
                            {isApplying && alignment === "yes" ? (
                                <CircularProgress size={20} sx={{ color: "#0891b2" }} />
                            ) : (
                                "Apply Style"
                            )}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </CsssProvider>
                <CsssProvider>
                    <Tooltip
                        title="Your writing style will be used to personalize your email messaging. Personalized messaging that is synced with your style leads to higher engagement and more meaningful connections being generated."
                        placement="bottom"
                    >
                        <InfoOutlinedIcon fontSize="medium" sx={{ ml: 1, mt: "5px" }} />
                    </Tooltip>
                </CsssProvider>
            </Box>
        </>
    );
};

export default GeneratedWritingStyleComponent;
