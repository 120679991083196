import { useSelector } from "react-redux";

import AnalyticsV2 from "./AnalyticsV2/AnalyticsV2";

import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";

export default function Analytics() {
    const isEasyGrowth = useSelector(checkEasyGrowth);

    // if (isEasyGrowth) return <AnalyticsV3 />;

    return <AnalyticsV2 />;
}
