import { Chip, ChipProps, Stack, Typography } from "@mui/material";
import { capitalize } from "lodash";

import { IProject } from "@/store/reducers/project/project.types";

function getProjectStatusChipColor(value: IProject["dashboardStatus"]): ChipProps["color"] {
    if (value === "ACTIVE") {
        return "success";
    }

    if (value === "ON_HOLD") {
        return "primary";
    }

    return "default";
}

type Props = {
    name?: string;
    status: IProject["dashboardStatus"];
};

export default function ProjectNameWithLabel({ name, status }: Props) {
    return (
        <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography>{name}</Typography>
            {status && (
                <Chip
                    color={getProjectStatusChipColor(status)}
                    label={capitalize(status)}
                    size="small"
                    sx={{
                        "& .MuiChip-label": {
                            fontSize: "10px",
                        },
                    }}
                />
            )}
        </Stack>
    );
}
