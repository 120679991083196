import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Graph from "./Graph";

import MembersDropdown from "../../../../components/MembersDropdown";
import { TDate } from "../../../../molecules/DateRangePicker/DateRangePicker";
import {
    useCachedGetCreditUsageQuery,
    useCachedGetGraphQuery,
    useCachedGetProjectsStatusQuery,
} from "../../../../store/apis/analytics/analytics.api";
import DataCard from "../../molecules/DataCard/DataCard";
import Section from "../../molecules/Section/Section";
import { defaultChartColors } from "../../utils";
import CustomDateRangePicker from "../AnalyticsDateRangePicker";
import ProjectsDropdown from "../ProjectsDropdown";

import IntersectionObserverWrapper from "@/components/IntersectionObserverWrapper";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";

const { Item, ListItem } = DataCard;

function UsageStatsContent() {
    const { t } = useTranslation();
    const isEasySource = useSelector(checkEasySource);

    const [projectId, setProjectId] = useState<number>(0);
    const [userId, setUserId] = useState<number>(0);
    const [date, setDate] = useState<TDate[]>([dayjs().subtract(3, "months").startOf("day"), dayjs().startOf("day")]);

    const filters = {
        projectId,
        userId,
        startDate: date[0]?.format(),
        endDate: date[1]?.format(),
    };

    const {
        data: projectsData,
        isLoading: loadingProjects,
        isFetching: fetchingProjects,
    } = useCachedGetProjectsStatusQuery(filters, { skip: !date[0] || !date[1] });

    const {
        data: creditUsageData,
        isLoading: loadingCreditUsage,
        isFetching: fetchingCreditUsage,
    } = useCachedGetCreditUsageQuery(filters, { skip: !date[0] || !date[1] });

    const {
        data: graphData,
        isLoading: loadingGraph,
        isFetching: fetchingGraph,
    } = useCachedGetGraphQuery(filters, { skip: !date[0] || !date[1] });

    const projectsStatusList = [
        {
            label: t("analytics.projectStatusTotalLabel"),
            value: projectsData?.projects,
            title: t("analytics.projectStatusTotalValue"),
        },
        {
            label: t("analytics.projectStatusActiveLabel"),
            value: projectsData?.activeProjects,
            title: t("analytics.projectStatusActiveValue"),
        },
        {
            label: t("analytics.projectStatusHoldLabel"),
            value: projectsData?.onHoldProjects,
            title: t("analytics.projectStatusHoldValue"),
        },
        {
            label: t("analytics.projectStatusClosedLabel"),
            value: projectsData?.closedProjects,
            title: t("analytics.projectStatusClosedValue"),
        },
    ];

    const creditUsageList = [
        {
            icon: <Box height={16} width={16} bgcolor={defaultChartColors[0]} />,
            label: t("analytics.creditUsageExtracted"),
            value: creditUsageData?.sourcedCandidates,
        },
        {
            icon: <Box height={16} width={16} bgcolor={defaultChartColors[1]} />,
            label: t("analytics.creditUsageQualifiedExtracted"),
            value: creditUsageData?.qualifiedCandidates,
        },
        { label: "Emails extracted", value: creditUsageData?.verifiedEmails },
        { label: "Phone numbers extracted", value: creditUsageData?.phoneNumbers },
    ];

    return (
        <>
            <Section.Header
                divider
                fetching={`${!loadingProjects && !loadingCreditUsage && !loadingGraph && (fetchingProjects || fetchingCreditUsage || fetchingGraph)}`}
            >
                <span style={{ paddingRight: "0.5rem" }}>
                    <MembersDropdown
                        value={userId}
                        onChange={(userId: number) => {
                            setUserId(userId);
                            setProjectId(0);
                        }}
                    />
                </span>

                <CustomDateRangePicker
                    value={date}
                    onChange={(dateRange) => {
                        setDate(dateRange.map((date) => date?.startOf("day")));
                    }}
                    defaultOption="Last 3 months"
                />
            </Section.Header>
            <Section.Content>
                <DataCard loading={`${loadingProjects}`}>
                    <ListItem disableHoverEffect>
                        <Item subtitle info={t("analytics.usageStatsSubtitle")}>
                            {isEasySource ? "Projects Status" : "Campaign Status"}
                        </Item>
                    </ListItem>
                    {projectsStatusList.map((item) => (
                        <ListItem key={item.label} title={item.title}>
                            <Item>{item.label}</Item>
                            <Item>{item.value}</Item>
                        </ListItem>
                    ))}
                </DataCard>
                <DataCard loading={`${loadingCreditUsage}`}>
                    <ListItem sx={`h-[42px]`} disableHoverEffect>
                        <Item subtitle>Credit usage</Item>
                        <ProjectsDropdown userId={userId} value={projectId} onChange={setProjectId} />
                    </ListItem>
                    {creditUsageList.map((item) => (
                        <ListItem key={item.label}>
                            <Item>
                                {item.icon} {item.label}
                            </Item>
                            <Item>{item.value}</Item>
                        </ListItem>
                    ))}
                </DataCard>
            </Section.Content>
            <Section.Content>
                <Graph loading={loadingGraph} graph={graphData} />
            </Section.Content>
        </>
    );
}

export default function UsageStats() {
    return (
        <Section height={800}>
            <IntersectionObserverWrapper style={{ height: 800 }}>
                <UsageStatsContent />
            </IntersectionObserverWrapper>
        </Section>
    );
}
