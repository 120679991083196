import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditLogo from "./EditLogo";
import UploadVideo from "./UploadVideo";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import styles from "../CompanyPage.module.css";

import { selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    selectOverview,
    selectShowUploadCompanyPage,
    setOverview,
    setShowUploadCompanyPage,
    updateCompanyPage,
} from "@/store/reducers/companyPage/CompanyPage.reducer";
import { Button } from "@/ui";

const UploadCompanyPage = () => {
    const dispatch = useDispatch();
    const [editOverview, setEditOverview] = useState(false);
    const [showEditLogoPopup, setShowEditLogoPopup] = useState(false);
    const [showEditVideoPopup, setShowEditVideoPopup] = useState(false);
    const showUploadCompanyPage = useSelector(selectShowUploadCompanyPage);
    const project = useSelector(selectCurrProject);
    const overview = useSelector(selectOverview);
    const isEdit = project?.logo || project?.video;

    const isProjectUpdating = useSelector(checkIfLoading(updateCompanyPage.type));
    const videoLink = project?.video;
    const isVideoFileType = videoLink && videoLink?.indexOf("mp4") > -1;

    useEffect(() => {
        if (project?.overview === overview) return;
        dispatch(setOverview(project?.overview || ""));
    }, [project?.overview]);

    const getHash = (videoLink: string) => {
        try {
            const urlObj = new URL(videoLink);
            // Handles youtube.com and youtu.be URLs
            if (urlObj.hostname.includes("youtube.com")) {
                return urlObj.searchParams.get("v");
            } else if (urlObj.hostname.includes("youtu.be")) {
                return urlObj.pathname?.slice(1);
            }
        } catch (error) {
            console.error("Invalid URL", error);
        }
        return null;
    };

    const newOverview = overview?.replaceAll("\n", "<br>") || "";
    return (
        <Dialog
            open={showUploadCompanyPage}
            fullWidth
            onClose={() => dispatch(setShowUploadCompanyPage(false))}
            classes={{
                paper: styles.uploadDialog,
            }}
        >
            <DialogTitle sx={{ padding: "20px 40px 25px 40px" }}>
                <Typography className={styles.uploadTitle} style={{ textAlign: "center", justifyContent: "center" }}>
                    {isEdit ? "Update" : "Upload"} Welcome Page
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "40px" }}>
                <Stack flexDirection="row">
                    <Stack width="100%">
                        <Typography className={styles.uploadTitle} variant="h5">
                            Company Logo
                            <Tooltip title="Edit logo">
                                <IconButton aria-label="delete" onClick={() => setShowEditLogoPopup(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        <Stack flexDirection="row" alignItems="center">
                            {project?.logo ? (
                                <img
                                    alt="logo"
                                    src={project?.logo}
                                    className={styles.companyLogo}
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        paddingLeft: "10px",
                                        maxWidth: "150px",
                                        maxHeight: "90px",
                                    }}
                                />
                            ) : (
                                <Button
                                    variant="outline"
                                    style={{
                                        marginBottom: "50px",

                                        width: "fit-content",
                                        alignSelf: "center",
                                    }}
                                    onClick={() => setShowEditLogoPopup(true)}
                                >
                                    {project?.logo ? "Update company logo" : "Upload company logo"}
                                </Button>
                            )}
                        </Stack>

                        <Typography className={styles.uploadTitle} variant="h6">
                            Company Overview
                            {editOverview ? (
                                <Button
                                    variant="outline"
                                    style={{
                                        marginLeft: "10px",
                                        width: "fit-content",
                                        alignSelf: "flex-end",
                                        padding: "0px",
                                    }}
                                    onClick={() => {
                                        setEditOverview(false);
                                        dispatch(updateCompanyPage({}));
                                    }}
                                    label="Save"
                                />
                            ) : (
                                <Tooltip title="Edit overview">
                                    <IconButton aria-label="delete" onClick={() => setEditOverview(true)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Typography>
                        {editOverview ? (
                            <TextField
                                value={overview}
                                multiline
                                rows={4}
                                onChange={(e) => {
                                    dispatch(setOverview(e.target.value));
                                }}
                            />
                        ) : (
                            <>
                                <p
                                    className={styles.uploadOverview}
                                    dangerouslySetInnerHTML={{
                                        __html: newOverview || "No Overview ",
                                    }}
                                />
                                {(project?.overview || project?.logo || project?.video) && (
                                    <a
                                        href={`/companyPage/${project?._id}`}
                                        target="_blank"
                                        className="mt-5 w-fit border border-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-100"
                                    >
                                        Click here for candidate form
                                    </a>
                                )}
                            </>
                        )}
                    </Stack>

                    <Stack pl="20px" width="100%" justifyContent="space-between">
                        <Typography className={styles.uploadTitle} variant="h5" sx={{ marginBottom: "-65px" }}>
                            Video
                            <Tooltip title="Edit video">
                                <IconButton aria-label="delete" onClick={() => setShowEditVideoPopup(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>

                        {videoLink && (
                            <>
                                {isVideoFileType ? (
                                    <video
                                        style={{ maxWidth: "100%", marginTop: "60px", height: "180px" }}
                                        controls
                                        src={project?.video}
                                    ></video>
                                ) : (
                                    <iframe
                                        style={{ marginTop: "60px", height: "180px" }}
                                        src={`https://youtube.com/embed/${getHash(videoLink)}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    />
                                )}
                            </>
                        )}

                        {!videoLink && (
                            <Button
                                variant="outline"
                                style={{
                                    marginBottom: "50px",
                                    marginTop: "5px",
                                    width: "fit-content",
                                    alignSelf: "left",
                                }}
                                onClick={() => setShowEditVideoPopup(true)}
                                label={project?.video ? "Update video" : "Upload video"}
                            />
                        )}

                        <Stack flexDirection="row" justifyContent="flex-end">
                            <Button
                                variant="outline"
                                style={{
                                    marginRight: "10px",
                                    width: "fit-content",
                                    alignSelf: "flex-end",
                                }}
                                onClick={() => dispatch(setShowUploadCompanyPage(false))}
                                label="Cancel"
                            />

                            <Button
                                disabled={isProjectUpdating}
                                variant="default"
                                style={{
                                    width: "fit-content",
                                    alignSelf: "flex-end",
                                }}
                                onClick={() => {
                                    dispatch(updateCompanyPage({}));
                                    dispatch(setShowUploadCompanyPage(false));
                                }}
                                label={isProjectUpdating ? "Submiting...." : "Submit"}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                {showEditLogoPopup && <EditLogo onClose={() => setShowEditLogoPopup(false)} />}
                {showEditVideoPopup && <UploadVideo onClose={() => setShowEditVideoPopup(false)} />}
            </DialogContent>
        </Dialog>
    );
};

export default UploadCompanyPage;
