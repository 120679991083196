import TaskAltRounded from "@mui/icons-material/TaskAltRounded";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import NavigateBackButton from "../../atoms/NavigateBackButton/NavigateBackbutton";
import { DisplayMessage } from "../../components/BlendedSearch/molecules/DisplayMessage";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { RenderBaseTemplates } from "../triggerWorkflow/components/BaseTemplates";

import Loader from "@/components/Loader/Loader";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    fetchProjectBaseTemplates,
    submitProjectBaseTemplates,
} from "@/store/reducers/trigger-workflow/customTemplates.slice";
import { Button, Typography } from "@/ui";

export function SetupOutreach() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const isFetchingBaseTemplates = useSelector(checkIfLoading(fetchProjectBaseTemplates.type));

    useEffect(() => {
        if (projectId) {
            dispatch(fetchProjectBaseTemplates({ projectId }));
        }
    }, []);

    if (!projectId) {
        return <DisplayMessage text="Project id is required to set up outreach." />;
    }

    const handleCompleteSetup = () => {
        dispatch(submitProjectBaseTemplates({ projectId }));
        navigate(`/projects/${projectId}?fromSearch=true`);
        dispatch(setSuccessNotification("Project completed successfully"));
    };

    return (
        <>
            {isFetchingBaseTemplates ? (
                <div className="rounded-lg bg-white w-full h-full items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <div className="justify-between gap-2 mt-2">
                    <div className="flex flex-col bg-white rounded-lg h-fit py-1.5 px-2">
                        <div className="flex flex-col gap-0.5 mb-2">

                            <Typography className="text-[20px] font-medium text-[#334d6e]">
                                Personalization templates
                            </Typography>
                            <Typography className="text-[14px] text-black/60 italic">
                                Edit your outreach templates and click 'Save', then complete your project setup
                            </Typography>
                        </div>
                        <RenderBaseTemplates variant="SEARCH" />
                    </div>
                    <div className="flex flex-row justify-end gap-1 mb-2 mt-4">
                        <NavigateBackButton />
                        <Button
                            variant="default"
                            onClick={handleCompleteSetup}
                            endDecorator={<TaskAltRounded style={{ fontSize: "18px" }} />}
                        >
                            Complete Project Setup
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}
