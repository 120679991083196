import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser } from "../../Signin/Signin.reducer";
import { postBambooHRCredentials } from "../integrations.reducer";
import { BambooHRKey, BambooHRState } from "../integrations.types";

import { Button, Modal, Typography } from "@/ui";

export default function BambooHRConnect({ onClose }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const isCredsSubmitting = useSelector(checkIfLoading(postBambooHRCredentials.type));

    const dispatch = useDispatch();

    const [credentials, setCredentials] = useState<BambooHRState>({
        apiKey: "",
        companyDomain: "",
    });
    const [error, setError] = useState({
        apiKey: false,
        companyDomain: false,
    });

    useEffect(() => {
        if (isEmpty(user)) return;
        setCredentials({
            apiKey: user.bambooHR?.apiKey ?? "",
            companyDomain: user.bambooHR?.companyDomain ?? "",
        });
    }, [user]);

    function validateField(field: BambooHRKey, isValid: boolean) {
        if (!isValid) {
            setError((prevError) => ({ ...prevError, [field]: true }));
            return;
        }
    }

    const handleSubmit = () => {
        validateField("apiKey", Boolean(credentials.apiKey));
        validateField("companyDomain", Boolean(credentials.companyDomain));

        dispatch(
            postBambooHRCredentials({
                apiKey: credentials.apiKey,
                companyDomain: credentials.companyDomain,
                onSuccess: onClose,
            })
        );
    };

    return (
        <Modal onClose={onClose} open={true}>
            <div style={{ minWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Connect BambooHR</h2>

                <div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Company Domain</Typography>
                        <div className="flex flex-col">
                            <input
                                value={credentials.companyDomain}
                                placeholder="Enter company domain"
                                onChange={(e) =>
                                    setCredentials((prev) => ({
                                        ...prev,
                                        companyDomain: e.target.value,
                                    }))
                                }
                                className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 
                                ${error.companyDomain ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"}`}
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {error.companyDomain && (
                                <span className="text-xs text-red-500 mt-1">Enter valid company domain</span>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">API Key</Typography>
                        <div className="flex flex-col">
                            <input
                                value={credentials.apiKey}
                                placeholder="Enter api key"
                                onChange={(e) =>
                                    setCredentials((prev) => ({
                                        ...prev,
                                        apiKey: e.target.value,
                                    }))
                                }
                                className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 
                                ${error.apiKey ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"}`}
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {error.apiKey && <span className="text-xs text-red-500 mt-1">Enter valid api key</span>}
                        </div>
                    </div>
                </div>

                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant="default"
                        onClick={handleSubmit}
                        disabled={isCredsSubmitting}
                        className="rounded-sm"
                        label={isCredsSubmitting ? "Submitting..." : "Submit"}
                    />
                </div>
            </div>
        </Modal>
    );
}
