import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Email from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import MessagesPaneHeaderLoader from "./MessagePaneHeaderLoader";

import CopyableText from "../../../../atoms/CopyableText";
import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import {
    CandidateDetails,
    useFetchCandidateProjectsQuery,
} from "../../../../store/apis/all-candidates/all-candidates.api";
import { useSuperInboxContext } from "../../Context";
import { sortProjectsByStatus, toggleMessagesPane } from "../../utils";

import { ContactTypeTooltip } from "@/components/ContactTypeTooltip";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Avatar } from "@/ui/Avatar/Avatar";

interface MessagesPaneHeaderProps {
    candidateProfile?: CandidateDetails;
    loading?: boolean;
}

const getPrefferedEmails = (
    emailSendType: string,
    emailWithTypes: { personal?: string[]; professional?: string[]; primary?: string }
) => {
    if (!emailWithTypes) return [];

    let preference;
    if (["ONLY_PROFESSIONAL", "PROFESSIONAL_PREFERRED"].includes(emailSendType)) {
        preference = "professional";
    } else if (["ONLY_PERSONAL", "PERSONAL_PREFERRED"].includes(emailSendType)) {
        preference = "personal";
    }

    // get all the emails of the preferred type
    const preferredEmails = emailWithTypes[preference] || [];

    return preferredEmails;
};

export default function MessagesPaneHeader(props: MessagesPaneHeaderProps) {
    const {
        candidateParamId,
        filters: { projectId },
    } = useSuperInboxContext();
    const { candidateProfile, loading } = props;
    const { data: candidateProjects, isLoading: loadingCandidateProjects } =
        useFetchCandidateProjectsQuery(candidateParamId);
    const user = useSelector(selectUser);

    const candidateActiveProjectId = sortProjectsByStatus(candidateProjects)?.[0]?.projectId;
    const activeProjectId = projectId || candidateActiveProjectId;

    if (loading || loadingCandidateProjects) return <MessagesPaneHeaderLoader />;

    const { emailSendType } = user;
    const userPrefferedEmails = getPrefferedEmails(emailSendType, candidateProfile?.emailWithTypes);

    const onClickCandidateName = () => {
        window.open(
            `/projects/${activeProjectId}?${queryString.stringify({
                query: candidateProfile?.name,
                candidateId: candidateParamId,
            })}`
        );
    };

    return (
        <div className="flex flex-row justify-between bg-white border-b border-divider bg-background-body py-2 px-1 md:px-2">
            <div className="flex flex-row items-center space-x-1 space-y-3 md:space-x-3 ">
                <button className="inline-flex sm:hidden text-neutral text-sm" onClick={() => toggleMessagesPane()}>
                    <ArrowBackIosNewRoundedIcon />
                </button>

                <Avatar
                    src={candidateProfile?.profileImage}
                    className="w-[48px] h-[48px]"
                    fallback={
                        <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                        </svg>
                    }
                />
                <div>
                    <div className="flex gap-0.5">
                        <HoverTruncateText
                            fontWeight="lg"
                            fontSize="lg"
                            component="h2"
                            maxWidth={250}
                            {...(activeProjectId && {
                                sx: { cursor: "pointer" },
                                onClick: onClickCandidateName,
                            })}
                        >
                            {candidateProfile?.name}
                        </HoverTruncateText>

                        {candidateProfile?.profileUrl && (
                            <Link style={{ display: "inline-flex" }} to={candidateProfile.profileUrl} target={"_blank"}>
                                <LinkedInIcon htmlColor="#0072B1" />
                            </Link>
                        )}
                    </div>
                    {candidateProfile?.phone && (
                        <CopyableText
                            fontSize={14}
                            message="Phone number copied successfully"
                            textToCopy={candidateProfile.phone}
                        >
                            <ContactTypeTooltip
                                profileUrl={candidateProfile.profileUrl}
                                phones={[candidateProfile.phone]}
                                contactType="phone"
                            >
                                <div>{candidateProfile.phone}</div>
                            </ContactTypeTooltip>
                        </CopyableText>
                    )}
                    {userPrefferedEmails?.length ? (
                        <CopyableText
                            fontSize={14}
                            message="email copied successfully"
                            textToCopy={candidateProfile?.email[0]}
                        >
                            <ContactTypeTooltip
                                profileUrl={candidateProfile?.profileUrl}
                                emails={candidateProfile?.email}
                                contactType="email"
                                emailResponseStats={candidateProfile?.emailResponseStats}
                                professionalEmailsWithoutEmptyValues={candidateProfile?.emailWithTypes[
                                    "professional"
                                ]?.filter((i: string) => Boolean(i))}
                            >
                                <div>{userPrefferedEmails[0]}</div>
                            </ContactTypeTooltip>
                        </CopyableText>
                    ) : (
                        <>
                            {!!candidateProfile?.email?.length && (
                                <ContactTypeTooltip
                                    profileUrl={candidateProfile?.profileUrl}
                                    emails={candidateProfile?.email}
                                    contactType="email"
                                    emailResponseStats={candidateProfile?.emailResponseStats}
                                    professionalEmailsWithoutEmptyValues={candidateProfile?.emailWithTypes[
                                        "professional"
                                    ]?.filter((i: string) => Boolean(i))}
                                >
                                    <Email sx={{ fontSize: "18px", marginLeft: "5px" }} />
                                </ContactTypeTooltip>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
