import { useSelector } from "react-redux";

import TextLoading from "../../../components/TextLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { applyAllContactsFilters } from "@/store/apis/all-contacts/all-contacts.slice";


export default function FiltersLoading() {
    const isDeleting = useSelector(checkIfLoading(applyAllContactsFilters.type));

    if (!isDeleting) {
        return null;
    }

    return <TextLoading text="Loading..." />;
}
