import { alpha, styled } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { forwardRef } from "react";
import { useSelector } from "react-redux";

import style from "./InputWithWordCount.module.scss";

import { selectHyperPersonalizeMode } from "@/store/reducers/workflow/workflow.reducer";

type Ref = HTMLInputElement;

type CustomTextFieldProps = TextFieldProps & {
    customValue: string;
    handleOnChange: (val: string) => void;
    wordLimit: number;
    wrapperStyle: React.CSSProperties;
    inputStyle?: React.CSSProperties;
};

const CustomMUIInput = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        padding: 0,
    },
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        fontFamily: "Poppins",
        borderRadius: 4,
        position: "relative",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 14,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const InputWithWordCount = forwardRef<Ref, CustomTextFieldProps>((props, ref) => {
    const { customValue, handleOnChange, wrapperStyle, wordLimit, inputStyle, ...rest } = props;
    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);
    const countOfWords = customValue?.length ? customValue.trim().split(" ")?.length : 0;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const liveWordCount = inputValue.trim().split(" ")?.length;
        if (liveWordCount > wordLimit) return;
        else handleOnChange(inputValue);
    };

    return (
        <div className={style["inputWithWordCount"]} style={wrapperStyle}>
            <CustomMUIInput
                multiline={true}
                inputRef={ref}
                value={customValue}
                onChange={handleInputChange}
                sx={{
                    marginRight: 1,
                    backgroundColor: isHyperPersonalized ? "#F3F6F9" : "transparent",
                    ...inputStyle,
                }}
                {...rest}
            />
            <span className={style["inputWithWordCount__count"]}>
                {countOfWords}/{wordLimit} words
            </span>
        </div>
    );
});

export default InputWithWordCount;
