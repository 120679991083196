import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useGetOrganizationMembersQuery } from "../../../../store/apis/organizations/organizations.api";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";

interface MembersDropdownProps {
    value: number;
    onChange: (value: number) => void;
    callback?: (value: string) => void;
    disabled?: boolean;
}

function MembersDropdownContent(props: MembersDropdownProps) {
    const { t } = useTranslation();
    const { data } = useGetOrganizationMembersQuery();
    const { onChange, callback, value, disabled } = props;

    return (
        <FormControl sx={{ my: 1, width: 220 }} size="small">
            <Select disabled={disabled} value={value}>
                <MenuItem
                    value={0}
                    selected={true}
                    onClick={() => {
                        onChange(0);
                        callback?.(t("analytics.memberListDropdownLabel"));
                    }}
                >
                    <Typography>All users</Typography>
                </MenuItem>
                {data?.members.map((member, i) => {
                    return (
                        <MenuItem
                            key={i}
                            value={member._id}
                            onClick={() => {
                                onChange(member._id);
                                callback?.(member.name);
                            }}
                        >
                            {member.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default function MembersDropdown(props: MembersDropdownProps) {
    const user = useSelector(selectUser);
    return <MembersDropdownContent {...props} />;
}
