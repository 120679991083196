import React, { useEffect } from "react";

import { cn } from "@/lib/utils";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    BackdropComponent?: React.ElementType;
    BackdropProps?: React.HTMLAttributes<HTMLElement>;
    disableBackdropClick?: boolean;
    className?: string;
    children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
    open,
    onClose,
    BackdropComponent = "div",
    BackdropProps = {},
    disableBackdropClick = false,
    className = "",
    children,
}) => {
    // Handle outside click
    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!disableBackdropClick && e.target === e.currentTarget) {
            onClose();
        }
    };

    // Close on Esc key
    useEffect(() => {
        const handleEsc = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose();
            }
        };
        if (open) {
            document.addEventListener("keydown", handleEsc);
        }
        return () => document.removeEventListener("keydown", handleEsc);
    }, [onClose, open]);

    // Lock background scroll when modal is open
    useEffect(() => {
        if (open) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        return () => document.body.classList.remove("overflow-hidden");
    }, [open]);

    if (!open) return null;

    return (
        <BackdropComponent
            onClick={handleOutsideClick}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 !ml-0"
            {...BackdropProps}
        >
            <div
                className={cn(`relative p-6 bg-white rounded-lg shadow-lg`, className)}
                role="dialog"
                aria-modal="true"
            >
                {/* Optional Close Button */}
                {/* <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-800 focus:outline-none"
                    aria-label="Close"
                >
                    ✕
                </button> */}

                {/* Modal Content */}
                {children}
            </div>
        </BackdropComponent>
    );
};
