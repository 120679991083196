import ChatIcon from "@mui/icons-material/Chat";
import { ReactNode } from "react";

interface MessagesPaneEmptyStateType {
    children: ReactNode;
}

export default function MessagesPaneEmptyState({ children }: MessagesPaneEmptyStateType) {
    return (
        <div
            className="flex flex-col flex-1 items-center justify-center px-2"
            style={{
                height: window.innerWidth >= 1024 ? "calc(98vh - var(--Header-height))" : "100vh",
            }}
        >
            <ChatIcon style={{ fontSize: 50, marginRight: "3px" }} />
            {children}
        </div>
    );
}
