import Divider from "@mui/joy/Divider";

import ChatsPaneContent from "./ChatsPaneContent";
import { ChatsPaneHeader } from "./ChatsPaneHeader";

import { WebPagination } from "../Pagination";
import Scrollable from "../Scrollable";

type ChatsPaneProps = {}; // eslint-disable-line

export default function ChatsPane(_props: ChatsPaneProps) {
    return (
        <Scrollable.Container
            sx={{
                borderRight: "1px solid",
                borderColor: "divider",
              
            }}
        >
            <ChatsPaneHeader />
            <Divider />
            <Scrollable.Content>
                <ChatsPaneContent />
            </Scrollable.Content>
            <WebPagination />
        </Scrollable.Container>
    );
}
