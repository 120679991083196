import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import { FormControl, InputLabel, MenuItem, Select, Stack, styled } from "@mui/material";
import { Experimental_CssVarsProvider } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EVENT_KEYS_TEMPLATE_TYPE_MAP } from "./TemplatesMenu";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { CustomTab, CustomTabsContainer } from "../../../components/CustomTabs";
import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { RootState } from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import CustomMUIInput from "../../project/components/CustomMUIInput";
import { createNewTemplate, editTemplate } from "../../template/index.reducer";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { selectCurrentOutreachElementType, selectOutreachTemplateContent } from "../outreach.slice";

import { Button, Typography } from "@/ui";

const tabStyle = {
    padding: "0 0.5rem",
    height: "fit-content",
    minHeight: "33px",
    margin: "5px",
    borderRadius: "2.5rem",
    fontSize: 14,
    flex: 1,
    "&:hover": {
        opacity: 1,
        color: "#0891b2",
    },
    "&.Mui-selected": {
        color: "white",
        backgroundColor: "#0891b2",
    },
};

const StyledFormControl = styled(FormControl)({
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0891b2",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0891b2",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#0891b2",
    },
});

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Stack mt={1} mb={2}>
                    {children}
                </Stack>
            )}
        </div>
    );
}

const SaveTemplate = ({
    templateList,
    isOpen,
    onClose,
    refetch,
}: {
    templateList: any[];
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
}) => {
    const { currentOutreachElementId } = useOutreachParams();
    const [activeTab, setActiveTab] = useState(0);
    const [templateName, setTemplateName] = useState("");
    const [templateId, setTemplateId] = useState("");
    const dispatch = useDispatch();
    const isTemplateSaving = useSelector(checkIfLoading(createNewTemplate.type));
    const isTemplateUpdating = useSelector(checkIfLoading(editTemplate.type));

    const template = useSelector((state: RootState) => selectOutreachTemplateContent(state, currentOutreachElementId));

    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const isLoading = isTemplateSaving || isTemplateUpdating;

    const closeSaveTemplateModal = () => {
        setTemplateId("");
        setTemplateName("");
        onClose();
    };

    function handleSave() {
        if (!template) {
            dispatch(setErrorNotification("Template not found"));
            return;
        }
        let body = "";
        let subject = "";
        let connectionReminderMessageBody = "";
        let inmailFollowupBody = "";
        let inMailBody = "";
        let inMailSubject = "";
        let inmailFollowupSubject = "";
        if ("body" in template) {
            body = template.body;
        }
        if ("subject" in template) {
            subject = template.subject;
        }
        if ("inMailBody" in template) {
            inMailBody = template.inMailBody;
        }
        if ("inMailSubject" in template) {
            inMailSubject = template.inMailSubject;
        }
        if ("connectionReminderMessageBody" in template) {
            connectionReminderMessageBody = template.connectionReminderMessageBody;
        }
        if ("inmailFollowupBody" in template) {
            inmailFollowupBody = template.inmailFollowupBody;
        }

        if ("inmailFollowupSubject" in template) {
            inmailFollowupSubject = template.inmailFollowupSubject;
        }

        if (activeTab === 0) {
            if (!templateName) {
                dispatch(setErrorNotification("Template name can't be empty"));
                return;
            }
            dispatch(
                createNewTemplate({
                    name: templateName,
                    body: body,
                    type: EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType],
                    ...(subject && { subject: subject }),
                    ...(inMailBody && { inMailBody: inMailBody }),
                    ...(inMailSubject && { inMailSubject: inMailSubject }),
                    ...(connectionReminderMessageBody && {
                        connectionReminderMessageBody: connectionReminderMessageBody,
                    }),
                    ...(inmailFollowupBody && { inmailFollowupBody: inmailFollowupBody }),
                    ...(inmailFollowupSubject && { inmailFollowupSubject: inmailFollowupSubject }),
                    onSuccess: () => {
                        closeSaveTemplateModal();
                        refetch();
                    },
                })
            );
        } else {
            if (!templateId) {
                dispatch(setErrorNotification("Select template to be updated"));
                return;
            }
            const templateName = templateList.find((item) => item._id === templateId)?.name || "";
            dispatch(
                editTemplate({
                    name: templateName,
                    body: body,
                    type: EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType],
                    ...(subject && { subject: subject }),
                    ...(inMailBody && { inMailBody: inMailBody }),
                    ...(inMailSubject && { inMailSubject: inMailSubject }),
                    ...(connectionReminderMessageBody && {
                        connectionReminderMessageBody: connectionReminderMessageBody,
                    }),
                    ...(inmailFollowupBody && { inmailFollowupBody: inmailFollowupBody }),
                    ...(inmailFollowupSubject && { inmailFollowupSubject: inmailFollowupSubject }),
                    active: true,
                    _id: templateId,
                    onSuccess: () => {
                        closeSaveTemplateModal();
                        refetch();
                    },
                })
            );
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={closeSaveTemplateModal}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: 450,
                    borderRadius: "md",
                    px: 3,
                    py: 2,
                    boxShadow: "lg",
                }}
            >
                <Typography
                    className="text-lg font-medium text-[#334d6e] pb-2"
                >
                    Save Template
                </Typography>

                <Experimental_CssVarsProvider>
                    <CustomTabsContainer
                        value={activeTab}
                        onChange={(_, val) => setActiveTab(val)}
                        sx={{
                            borderRadius: "2.5rem",
                            minHeight: "fit-content",
                            width: "100%",
                            marginBottom: 2,
                            border: "1px solid #0891b2",
                        }}
                    >
                        <CustomTab
                            label="Create New"
                            icon={<AddRoundedIcon fontSize="small" />}
                            iconPosition="start"
                            sx={tabStyle}
                        />
                        <CustomTab
                            label="Update Existing"
                            icon={<EditRoundedIcon fontSize="small" />}
                            iconPosition="start"
                            sx={tabStyle}
                        />
                    </CustomTabsContainer>

                    <TabPanel value={activeTab} index={0}>
                        <CustomMUIInput
                            fullWidth
                            placeholder="Name of your template"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <StyledFormControl fullWidth size="small">
                            <InputLabel>Choose template</InputLabel>
                            <Select
                                value={templateId}
                                label="Choose template"
                                onChange={(e) => {
                                    setTemplateId(e.target.value);
                                }}
                            >
                                {templateList.map(({ _id, name }) => (
                                    <MenuItem key={_id} value={_id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </TabPanel>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                        <Button size="sm" variant="outline" onClick={closeSaveTemplateModal}>
                            Cancel
                        </Button>
                        <Button size="sm" disabled={isLoading} onClick={handleSave}>
                            <ButtonTextWithLoading text={activeTab === 0 ? "Create" : "Update"} isLoading={isLoading} />
                        </Button>
                    </Stack>
                </Experimental_CssVarsProvider>
            </Sheet>
        </Modal>
    );
};

export default SaveTemplate;
