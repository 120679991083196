import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, FormControl, InputLabel, Link, MenuItem, Modal, Select } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { startSse } from "../../../../utils/firebase";
import { getLinkedinApplicantCandidates } from "../../index.reducer";

import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { Button as CustomButton, Input as TextField, Typography } from "@/ui";

export default function FetchFromLinkedin() {
    const [open, setOpen] = useState(false);
    const [linkedinUrl, setLinkedinUrl] = useState("");

    const isLoading = useSelector(checkIfLoading(getLinkedinApplicantCandidates.type));

    const [sortBy, setSortBy] = useState("RELEVANCE");

    const { id } = useParams();
    const dispatch = useDispatch();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSubmit = () => {
        const jobId = (() => {
            const parts = linkedinUrl?.split("/jobs/");
            if (parts?.[1]?.startsWith("view/")) {
                // Extract the jobId after "view/"
                return parts[1].split("view/")[1]?.split("/")?.[0];
            }
            // Default extraction logic
            return parts?.[1]?.split("/")?.[0];
        })();

        if (!jobId) {
            dispatch(setErrorNotification("Invalid LinkedIn Job URL"));
            return;
        }

        dispatch(
            getLinkedinApplicantCandidates({
                jobId: jobId,
                projectId: id,
                sortBy: sortBy,
                onSuccess: () => {
                    handleClose();
                    startSse();
                },
            })
        );
    };

    return (
        <>
            <Button variant="outlined" onClick={handleOpen}>
                {isLoading ? "Fetching..." : "Fetch from LinkedIn"}
            </Button>

            <Modal open={open} onClose={handleClose} aria-labelledby="linkedin-modal-title">
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 450,
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography className="mb-3 font-semibold text-lg">Fetch Candidates from LinkedIn</Typography>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                        <TextField
                            label="LinkedIn Job URL"
                            value={linkedinUrl}
                            onChange={(value) => setLinkedinUrl(value)}
                            fullWidth
                            variant="outlined"
                        />

                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Sort By</InputLabel>
                            <Select
                                value={sortBy}
                                label="Sort By"
                                size="small"
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                <MenuItem value="RELEVANCE">Relevance</MenuItem>
                                <MenuItem value="DATE_APPLIED">Date Applied</MenuItem>
                            </Select>
                        </FormControl>

                        {/* <FormControl fullWidth variant="outlined">
                            <InputLabel>Import</InputLabel>
                            <Select
                                value={importCount}
                                label="Import"
                                onChange={(e) => setImportCount(Number(e.target.value))}
                            >
                                <MenuItem value={100}>Top 100</MenuItem>
                                <MenuItem value={500}>Top 500</MenuItem>
                                <MenuItem value="all">All Candidates</MenuItem>
                            </Select>
                        </FormControl> */}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                        <Link
                            href="https://docs.hirequotient.com/en/articles/10102434-best-practices-to-use-easysource-s-linkedin-job-scraper"
                            target="_blank"
                            variant="body2"
                            sx={{ marginTop: "10px" }}
                        >
                            Read best practices <ArrowOutwardIcon fontSize="small" />
                        </Link>

                        <CustomButton onClick={handleSubmit}>Submit</CustomButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
