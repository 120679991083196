import { Stack, Typography } from "@mui/joy";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import BambooHRModal from "./bambooHRPostCandidate";
import GreenhouseModal from "./greenhousePostCandidate";
import JazzModal from "./jazzHRPostCadidate";
import JobviteModal from "./jobvitePostCandidate";
import LaborEdgeModal from "./laborEdge";
import LeverModal from "./leverPostCandidate";
import NucleusModal from "./nucleusPostCandidate";
import RecruiterFlowModal from "./recruiterFlowPostCandidates";
import ZohoHRModal from "./zohoIntegration";

import {
    RecruiterFlowIcon,
    bambooHRLogo,
    greenhouseLogo,
    jazzHrLogo,
    jobviteLogo,
    laborEdge,
    leverLogo,
    loxoAtsLogo,
    zohoRecruitLogo,
} from "../../../../constant/Constant";
import useMenu from "../../../../hooks/useMenu";
import { Nucleus } from "../../../../icons";
import ImageUrl from "../../../../molecules/ImageUrl/ImageUrl";
import { addToLoxoAgency } from "../../../integrations/integrations.reducer";
import { selectEnabledIntegrationsPull, selectEnabledIntegrationsPush } from "../../../Signin/Signin.reducer";
import { AtsKeys } from "../../../Signin/Signin.types";
import { selectSelectedCandidates } from "../../index.reducer";

const integrationItems: { label: string; key: AtsKeys; description?: string; imageUrl?: string | JSX.Element }[] = [
    {
        label: "Loxo",
        key: "loxo",
        imageUrl: loxoAtsLogo,
    },
    {
        label: "Greenhouse",
        key: "greenhouse",
        imageUrl: greenhouseLogo,
    },
    {
        label: "Zoho Recruit",
        key: "zoho",
        imageUrl: zohoRecruitLogo,
    },
    {
        label: "Lever",
        key: "lever",
        imageUrl: leverLogo,
    },
    {
        label: "Jobvite",
        key: "jobvite",
        imageUrl: jobviteLogo,
    },
    {
        label: "BambooHR",
        key: "bambooHR",
        imageUrl: bambooHRLogo,
    },
    {
        label: "JazzHR",
        key: "jazzHR",
        description: "Candidates are automatically fetched via JazzHR. Click to map a JazzHR job.",
        imageUrl: jazzHrLogo,
    },
    {
        label: "Labor Edge",
        key: "laborEdge",
        imageUrl: laborEdge,
    },
    {
        label: "Nucleus ATS",
        key: "nucleus",
        imageUrl: <Nucleus />,
    },
    {
        label: "RecruiterFlow",
        key: "recruiterFlow",
        imageUrl: RecruiterFlowIcon,
    },
];

export default function ATSIntegration() {
    const { anchorEl, handleClick: handleMenuClick, handleClose, menuSX, open } = useMenu();
    const { id } = useParams();
    const dispatch = useDispatch();
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const enabledIntegrations = useSelector(selectEnabledIntegrationsPush);
    const selectCount = selectedCandidates.length;
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);

    const [openGreenhouseModal, setOpenGreenhouseModal] = useState(false);
    const [openLeverModal, setOpenLeverModal] = useState(false);
    const [openJobviteModal, setOpenJobviteModal] = useState(false);
    const [openBambooHRModal, setOpenBambooHRModal] = useState(false);
    const [openZohoHRModal, setOpenZohoHRModal] = useState(false);
    const [openJazzHRModal, setOpenJazzHRModal] = useState(false);
    const [openLaborEdgeModal, setOpenLaborEdgeModal] = useState(false);
    const [openNucleusModal, setOpenNucleusModal] = useState(false);
    const [openRecruiterFlowModal, setOpenRecruiterFlowModal] = useState(false);

    const handleAddAts = (key: AtsKeys) => {
        if (!selectCount || !id) return;

        switch (key) {
            case "loxo": {
                dispatch(
                    addToLoxoAgency({
                        projectId: Number(id),
                        candidateIds: selectedCandidateIds,
                        action: addToLoxoAgency.type,
                    })
                );
                break;
            }
            case "greenhouse": {
                setOpenGreenhouseModal(true);
                break;
            }
            case "lever": {
                setOpenLeverModal(true);
                break;
            }
            case "jazzHR": {
                setOpenJazzHRModal(true);
                break;
            }
            case "jobvite": {
                setOpenJobviteModal(true);
                break;
            }
            case "bambooHR": {
                setOpenBambooHRModal(true);
                break;
            }
            case "zoho": {
                setOpenZohoHRModal(true);
                break;
            }
            case "laborEdge": {
                setOpenLaborEdgeModal(true);
                break;
            }
            case "nucleus": {
                setOpenNucleusModal(true);
                break;
            }
            case "recruiterFlow": {
                setOpenRecruiterFlowModal(true);
                break;
            }
        }
    };

    const enabledIntegrationItems = integrationItems?.filter(({ key }) => enabledIntegrations.includes(key));
    if (!enabledIntegrations.length) {
        return null;
    }

    return (
        <>
            <Button variant="outlined" disableElevation disabled={!selectCount} onClick={handleMenuClick}>
                Send to ATS
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={menuSX}>
                {enabledIntegrationItems.map(({ key, label, imageUrl, description }) => {
                    return (
                        <MenuItem
                            onClick={(e) => {
                                handleAddAts(key);
                                handleClose();
                            }}
                            key={key}
                        >
                            <Stack direction="row" alignItems="left">
                                {typeof imageUrl === "string" ? (
                                    <ImageUrl src={imageUrl} height={24} width={24} />
                                ) : (
                                    imageUrl
                                )}
                                <Stack direction="column" alignItems="left" paddingX={1}>
                                    {label}
                                </Stack>
                            </Stack>
                        </MenuItem>
                    );
                })}
            </Menu>
            {id ? (
                <>
                    <GreenhouseModal
                        open={openGreenhouseModal}
                        onClose={() => setOpenGreenhouseModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <LeverModal
                        open={openLeverModal}
                        onClose={() => setOpenLeverModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <JazzModal
                        open={openJazzHRModal}
                        onClose={() => setOpenJazzHRModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <JobviteModal
                        open={openJobviteModal}
                        onClose={() => setOpenJobviteModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <BambooHRModal
                        open={openBambooHRModal}
                        onClose={() => setOpenBambooHRModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <ZohoHRModal
                        open={openZohoHRModal}
                        onClose={() => setOpenZohoHRModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <LaborEdgeModal
                        open={openLaborEdgeModal}
                        onClose={() => setOpenLaborEdgeModal(false)}
                        selectedCandidateIds={selectedCandidateIds}
                    />

                    <RecruiterFlowModal
                        id={id}
                        open={openRecruiterFlowModal}
                        onClose={() => setOpenRecruiterFlowModal(false)}
                        selectedCandidateIds={selectedCandidateIds}
                    />

                    <NucleusModal
                        open={openNucleusModal}
                        onClose={() => setOpenNucleusModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                </>
            ) : null}
        </>
    );
}

export function FetchFromAts() {
    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const { id } = useParams();
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const enabledIntegrations = useSelector(selectEnabledIntegrationsPull);
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);

    const [openGreenhouseModal, setOpenGreenhouseModal] = useState(false);
    const [openLeverModal, setOpenLeverModal] = useState(false);
    const [openBambooHRModal, setOpenBambooHRModal] = useState(false);
    const [openJazzHRModal, setOpenJazzHRModal] = useState(false);
    const [openZohoModal, setOpenZohoModal] = useState(false);
    const [openLaborEdgeModal, setOpenLaborEdgeModal] = useState(false);
    const [openNucleusModal, setOpenNucleusModal] = useState(false);
    const [openRecruiterFlowModal, setOpenRecruiterFlowModal] = useState(false);

    const handleAddAts = (key: AtsKeys) => {
        if (!id) return;

        switch (key) {
            case "jazzHR": {
                setOpenJazzHRModal(true);
                break;
            }
            case "greenhouse": {
                setOpenGreenhouseModal(true);
                break;
            }
            case "lever": {
                setOpenLeverModal(true);
                break;
            }
            case "bambooHR": {
                setOpenBambooHRModal(true);
                break;
            }
            case "zoho": {
                setOpenZohoModal(true);
                break;
            }
            case "laborEdge": {
                setOpenLaborEdgeModal(true);
                break;
            }
            case "nucleus": {
                setOpenNucleusModal(true);
                break;
            }
            case "recruiterFlow": {
                setOpenRecruiterFlowModal(true);
                break;
            }
        }
    };

    const enabledIntegrationItems = integrationItems?.filter(({ key }) => enabledIntegrations.includes(key));

    if (!enabledIntegrations.length) {
        return null;
    }

    return (
        <>
            <Button variant="outlined" disableElevation onClick={handleClick}>
                Fetch from ATS
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={menuSX}>
                {enabledIntegrationItems.map(({ key, label, description, imageUrl }) => {
                    return (
                        <>
                            <MenuItem
                                onClick={() => {
                                    handleAddAts(key);
                                    handleClose();
                                }}
                                key={key}
                            >
                                <Stack direction="row" alignItems="left">
                                    {typeof imageUrl === "string" ? (
                                        <ImageUrl src={imageUrl} height={24} width={24} />
                                    ) : (
                                        imageUrl
                                    )}
                                    <Stack direction="column" alignItems="left" paddingX={1}>
                                        {label}
                                        {description && (
                                            <Typography
                                                width={300}
                                                sx={{
                                                    fontSize: "12px",
                                                    color: "gray",
                                                    textWrap: "balance",
                                                }}
                                            >
                                                {description}
                                            </Typography>
                                        )}
                                    </Stack>
                                </Stack>
                            </MenuItem>
                        </>
                    );
                })}
            </Menu>
            {id ? (
                <>
                    <GreenhouseModal
                        open={openGreenhouseModal}
                        onClose={() => setOpenGreenhouseModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />
                    <RecruiterFlowModal
                        open={openRecruiterFlowModal}
                        onClose={() => setOpenRecruiterFlowModal(false)}
                        id={id}
                        variant="FETCH"
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <JazzModal
                        open={openJazzHRModal}
                        onClose={() => setOpenJazzHRModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />

                    <LeverModal
                        open={openLeverModal}
                        onClose={() => setOpenLeverModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />
                    <BambooHRModal
                        open={openBambooHRModal}
                        onClose={() => setOpenBambooHRModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />
                    <ZohoHRModal
                        open={openZohoModal}
                        onClose={() => setOpenZohoModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />
                    <LaborEdgeModal
                        open={openLaborEdgeModal}
                        onClose={() => setOpenLaborEdgeModal(false)}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />
                    <NucleusModal
                        open={openNucleusModal}
                        onClose={() => setOpenNucleusModal(false)}
                        id={id}
                        selectedCandidateIds={selectedCandidateIds}
                        variant="FETCH"
                    />
                </>
            ) : null}
        </>
    );
}
