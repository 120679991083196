/* eslint-disable */
import Tap from "@tapfiliate/tapfiliate-js";
import firebase from "firebase/compat/app";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import store from "../../store";

import "firebase/compat/auth";

import { PayloadAction } from "@reduxjs/toolkit";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { setCanHideWalkThrough } from "../../components/InterActiveWalkthroughModal/interActiveWalkthorughModal.reducer";
import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { setNudges } from "../../components/Nudges/Nudges.reducer";
import { CREATE_DASHBOARD_USER, WRONG_PASSWORD } from "../../constant/Constant";
import { setShowLoginLoader } from "../../container/AppContainer/AppContainer.reducer";
import { objectToQuery } from "../../hooks/useOAuth2/tools";
import { IActionPayload, startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API, { openExtensionLink, openLinkedinRecruiter } from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { HQ_EASYSOURCE_WEBSITE, HQ_EXTENSION_ON_BOARDING } from "../../utils/Constants";
import { getCreditPercentage } from "../../utils/helper";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    abortAuthorizeLinkedin,
    authLogin,
    authorizeEmail,
    authorizeLinkedin,
    authorizeOutlook,
    checkEmailForCreateUser,
    createUser,
    getLinkedinAuthStatus,
    googleLogin,
    initialState,
    leaveSignin,
    sendJobFunctions,
    sendReferralCode,
    setIsRegisterApiCalled,
    setIsTokenExpired,
    setLinkedinAuthStatus,
    setSentInvite,
    setSignUpModalState,
    setUser,
    setUserCredits,
    signIn,
    signOut,
    signUp,
    submitLinkedinCaptcha,
    submitLinkedinOTP,
    superAdminCreateUser,
    updateGmail,
    getSaveRecruiterFlowAuth,
    updateOutlook,
    updateUser,
} from "./Signin.reducer";
import {
    AICONTROL_KEYS,
    IAuthLogin,
    IAuthorizeLinkedinPayload,
    IEmailSource,
    ILinkedinAuthStatus,
    ISignIn,
    ISignUp,
    IUpdateUserPayload,
    SendInviteResponse,
    SendJobFunctionsResponse,
    SendReferralCodePayload,
    signinType,
} from "./Signin.types";

function* sendJobFunctionsResponseSaga({ payload }: { payload: SendJobFunctionsResponse }): SagaIterator {
    try {
        const { action, jobFunctions } = payload;
        yield put(startAction({ action }));
        yield call(new API().post, "/job-list/save-jobs", {
            requiredJobs: jobFunctions,
        });
        yield put(setSignUpModalState("SHOW_LINKED_IN_MODE_MODAL"));
    } catch (error) {
        handleException(error);
        yield put(stopAction({ action: payload.action }));
    }
}
function* saveRecruiterFlowAuthSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getSaveRecruiterFlowAuth.type }));
        yield call(new API().post, "/ats/recruiterFlow/auth", payload);

        yield put(setSuccessNotification("RecruiterFlow Auth saved successfully"));
    } catch (error) {
        handleException(error);
        yield put(stopAction({ action: getSaveRecruiterFlowAuth.type }));
    }
}

function* signOutSaga(): SagaIterator {
    firebase
        .auth()
        .signOut()
        .then(function () {
            store.dispatch(setUser(initialState.user));
            localStorage.removeItem("user");
            window.location.href = "/signin";
        })
        .catch(function (error) {
            console.error("error", error);
            handleException(error);
        });
}

function* authLoginSaga({ payload }: { payload: IActionPayload & IAuthLogin }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const state = yield select();
        const isSignupClicked = get(state, "signin.isSignupClicked");
        const isRegisterApiCalled = get(state, "signin.isRegisterApiCalled");
        if (isSignupClicked && !payload.displayName) return;
        if (isRegisterApiCalled) return;
        yield put(setIsRegisterApiCalled(true));
        const searchParams = new URLSearchParams(window.location.search);
        const isRB2B = Boolean(searchParams.get("fromRB2B"));
        const response = yield call(new API().post, "/auth/register", {
            name: payload.displayName,
            email: payload.user.email,
            referralInviteCode: payload.refferalCode,
            isRB2B: isRB2B,
        });
        if (!response?.data) return;
        //@ts-ignore
        const { clevertap } = window;

        const isRegister = get(response, "data.isRegister");

        if (isRegister) {
            Tap.customer(response?.data?.user?.uuid, {
                meta_data: {
                    email: response?.data?.user?.email,
                    name: response?.data?.user?.name,
                },
            });
            yield put(setSignUpModalState("SHOW_PERSONALIZED_MODAL"));
        } else {
            yield put(setSignUpModalState("SHOW_LINKED_IN_MODE_MODAL"));
        }

        if (clevertap) {
            clevertap.onUserLogin.push({
                Site: {
                    Name: payload.displayName,
                    Identity: response?.data?.user?._id,
                    Email: payload.user.email,
                    Photo: response?.data?.user.photoURL,
                },
            });

            if (isRegister) {
                setTimeout(() => {
                    handleCleverTap("User Register", {
                        Name: payload.displayName,
                        "user id": response?.data?.user?._id,
                        Email: payload.user.email,
                    });
                }, 3000);
            }
        }

        const aiControls = response?.data?.user?.aiControls;
        const _aiControls: { [key in AICONTROL_KEYS]: boolean } = {
            claude3: false,
            gemini: false,
            llama3: false,
            perplexity: false,
        };
        Object.keys(_aiControls).forEach((key) => {
            const aiControlKey = key as AICONTROL_KEYS;
            _aiControls[aiControlKey] = Boolean(aiControls?.[aiControlKey]);
        });

        const user = {
            ...payload?.user?.multiFactor?.user,
            ...response?.data?.user,
            orgData: response?.data?.orgData,
            displayName: response?.data?.user.name,
            _id: response?.data?.user?._id,
            emailAuthorized: response?.data?.user?.emailAuthorized,
            checkLinkedinCookie: response?.data?.user?.checkLinkedinCookie,
            liCookie: response?.data?.user?.liCookie,
            isSlackConnected: response?.data?.user?.slackAuthorized ? true : false,
            aiControls: _aiControls,
        };
        new API().setLocalStorage({ key: "user", value: JSON.stringify(user) });
        yield put(setUser(user));
        yield put(setIsTokenExpired(false));
        yield put(setCanHideWalkThrough(response?.data?.user.canHideWalkThrough));
        yield put(
            setNudges({
                installExtension: response?.data?.user.installExtension || false,
                addToWorkflow: response?.data?.user.addToWorkflow || false,
                showEmail: response?.data?.user.showEmail || false,
            })
        );
        const { currentCredits, totalCredits, remainingCredits } = getCreditPercentage(user);
        yield put(setUserCredits({ currentCredits, totalCredits, remainingCredits }));

        const fromExtension = get(state, "signin.fromExtension");
        if (!fromExtension) return;
        const isRecruiter = fromExtension === "liRecruiter";
        window.postMessage(
            {
                type: HQ_EXTENSION_ON_BOARDING,
                from: HQ_EASYSOURCE_WEBSITE,
                url: isRecruiter ? openLinkedinRecruiter : openExtensionLink,
            },
            "*"
        );
    } catch (err: unknown) {
        yield put(stopAction({ action: payload.action }));
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}
function* handleAuthStateChanged(auth: any, googleProvider: any, payload: any) {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged(async (user: any) => {
            try {
                store.dispatch(setShowLoginLoader(false));
                if (!user) {
                    await auth.signInWithPopup(googleProvider);
                } else {
                    const currentUser: signinType = get(auth, "currentUser.multiFactor.user") || initialState.user;
                    resolve({ currentUser, payload });
                }
            } catch (error) {
                reject(error);
            }
        });
    });
}

function* processUserLogin({ currentUser, payload }: { currentUser: signinType; payload: string }) {
    if (payload === CREATE_DASHBOARD_USER) {
        const { email, uid } = currentUser;
        if (!email) return;

        yield put(
            createUser({
                email,
                uid,
                action: createUser.type,
            })
        );
    } else {
        yield put(
            authLogin({
                user: currentUser,
                action: authLogin.type,
                displayName: currentUser.displayName,
                dontRefresh: true,
            })
        );
    }
}

export function* googleLoginSaga({ payload }: { payload: string }): SagaIterator {
    try {
        const auth = firebase.auth();
        const googleProvider = new firebase.auth.GoogleAuthProvider();

        const { currentUser } = yield call(handleAuthStateChanged, auth, googleProvider, payload);

        if (currentUser) {
            yield call(processUserLogin, { currentUser, payload });
        }
    } catch (err: unknown) {
        console.error("googleLoginSaga failed", get(err, "response"));
        handleException(err);
    }
}
function* signUpSaga({ payload }: { payload: IActionPayload & ISignUp }): SagaIterator {
    const auth = getAuth();
    yield put(startAction({ action: payload.action }));

    createUserWithEmailAndPassword(auth, payload.email, payload.password)
        .then((userCredential) => {
            const user = userCredential.user;
            store.dispatch(
                authLogin({
                    user,
                    action: authLogin.type,
                    displayName: payload.name,
                    refferalCode: payload.refferalCode || "",
                })
            );
        })
        .catch((error) => {
            console.error({ code: error.code, message: error.message });
            store.dispatch(setErrorNotification(error.message));
            handleException(error);
        })
        .finally(() => {
            store.dispatch(stopAction({ action: payload.action }));
        });
}

function* signInSaga({ payload }: { payload: IActionPayload & ISignIn }): SagaIterator {
    const { email, password, action } = payload;
    yield put(startAction({ action }));

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            store.dispatch(
                authLogin({
                    user,
                    action: authLogin.type,
                    displayName: user.displayName,
                })
            );
        })
        .catch((error) => {
            const { code, message } = error;
            console.error({ code, message });
            handleException(error);

            let errMessage;
            switch (code) {
                case "auth/invalid-email": {
                    errMessage = "Please enter valid email ID to login.";
                    break;
                }
                case "auth/user-not-found": {
                    errMessage = "Account does not exist. Please create an account to get started.";
                    break;
                }
                case "auth/wrong-password": {
                    errMessage = "Password entered is incorrect.";
                    break;
                }
                default: {
                    errMessage = "Something wrong happened. Please try again later.";
                    break;
                }
            }
            store.dispatch(setErrorNotification(errMessage));
            handleException(error);
        })
        .finally(() => {
            store.dispatch(stopAction({ action }));
        });
}

function* authorizeEmailSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().get, "/auth/authorize-gmail");
        if (!response?.data) return;

        window.location.assign(response.data);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* updateGmailSaga({
    payload,
}: {
    payload: { authToken: string; emailSource: IEmailSource; userId: string };
}): SagaIterator {
    try {
        const getUserFromLS = new API().getLocalStorage("user");
        const localUser = JSON.parse(getUserFromLS || "");
        const queryParameters = objectToQuery(payload);
        const response = yield call(new API().get, `/auth/update-gmail-auth?${queryParameters}`);

        if (!response) return;
        localUser.emailAuthorized = true;

        new API().setLocalStorage({
            key: "user",
            value: JSON.stringify({
                ...localUser,
                emailAuthorized: true,
                emailSource: "Gmail",
            }),
        });
        if (!payload?.userId) {
            yield put(
                setUser({
                    ...localUser,
                    emailAuthorized: true,
                    emailSource: "Gmail",
                })
            );
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* authorizeOutlookSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().get, "/auth/authorize-outlook");
        if (!response?.data) return;
        window.location.assign(response.data);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* updateOutlookSaga({
    payload,
}: {
    payload: { authToken: string; emailSource: IEmailSource; userId: string };
}): SagaIterator {
    try {
        const getUserFromLS = new API().getLocalStorage("user");
        const localUser = JSON.parse(getUserFromLS || "");
        const response = yield call(new API().put, "/auth/update-gmail-auth", payload);

        if (!response) return;
        new API().setLocalStorage({
            key: "user",
            value: JSON.stringify({
                ...localUser,
                emailAuthorized: true,
                emailSource: "Outlook",
            }),
        });

        if (!payload?.userId) {
            yield put(
                setUser({
                    ...localUser,
                    emailAuthorized: true,
                    emailSource: "Outlook",
                })
            );
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* updateUserSaga({ payload }: { payload: IUpdateUserPayload & IActionPayload }): SagaIterator {
    try {
        const {
            action,
            name,
            orgName,
            companyName,
            companyLink,
            roleType,
            signature,
            sendGrid,
            sesIntegrated,
            skipWeekends,
            rotatingMailBox,
            showAllProjects,
            aiControls,
            jazzHR,
            twilioConfig,
            workingHours,
            onSuccess,
            laborEdge,
            nucleusApiKey,
        } = payload;

        yield put(startAction({ action }));

        const data: { [key: string]: any } = {
            name,
            orgName,
            signature,
        };

        if (companyName) {
            data.companyName = companyName;
        }

        if (aiControls) {
            data.aiControls = aiControls;
        }

        if (companyLink) {
            data.companyLink = companyLink;
        }

        if (roleType) {
            data.roleType = roleType;
        }

        if (signature) {
            data.signature = signature;
        }

        if (typeof sendGrid === "boolean") {
            data.sendGrid = sendGrid;
        }

        if (typeof sesIntegrated === "boolean") {
            data.sesIntegrated = sesIntegrated;
        }

        if (typeof skipWeekends === "boolean") {
            data.skipWeekends = skipWeekends;
        }

        if (typeof rotatingMailBox === "boolean") {
            data.rotatingMailBox = rotatingMailBox;
        }

        if (typeof showAllProjects === "boolean") {
            data.showAllProjects = showAllProjects;
        }
        if (jazzHR) {
            data.jazzHR = jazzHR;
        }
        if (twilioConfig) {
            data.twilioConfig = twilioConfig;
        }
        if (workingHours) {
            data.workingHours = workingHours;
        }
        if (laborEdge) {
            data.laborEdge = laborEdge;
        }
        if (nucleusApiKey) {
            data.nucleusApiKey = nucleusApiKey;
        }
        const response = yield call(new API().put, "/auth/update-user-info", data);

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        let updatedUser = {
            ...userStateValue,
        };

        if (response?.success) {
            const updatedOrgData: any = {};
            const updatedFeatureData: any = {};

            if (orgName) {
                updatedOrgData.name = orgName;
            }

            if (typeof rotatingMailBox === "boolean") {
                updatedFeatureData.rotatingMailBox = rotatingMailBox;
            }

            if (typeof skipWeekends === "boolean") {
                updatedFeatureData.skipWeekends = skipWeekends;
            }

            updatedUser = {
                ...updatedUser,
                features: {
                    ...userStateValue.features,
                    ...updatedFeatureData,
                },
                orgData: {
                    ...userStateValue.orgData,
                    ...updatedOrgData,
                },
            };

            if (name) {
                updatedUser.displayName = name;
            }
            if (companyName) {
                updatedUser.companyName = companyName;
            }
            if (companyLink) {
                updatedUser.companyLink = companyLink;
            }
            if (typeof showAllProjects === "boolean") {
                updatedUser.showAllProjects = showAllProjects;
            }
            if (aiControls) {
                updatedUser.aiControls = aiControls;
            }

            if (signature) {
                updatedUser.signature = signature;
            }

            if (typeof sendGrid === "boolean") {
                updatedUser.sendGrid = sendGrid;
            }

            if (typeof sesIntegrated === "boolean") {
                updatedUser.sesIntegrated = sesIntegrated;
            }

            if (laborEdge) {
                updatedUser.laborEdge = laborEdge;
            }

            if (jazzHR) {
                updatedUser.jazzHR = jazzHR;
            }
            if (twilioConfig) {
                updatedUser.twilioConfig = twilioConfig;
            }
            if (workingHours) {
                updatedUser.workingHours = workingHours;
            }
        }
        yield put(setUser(updatedUser));
        yield put(setSuccessNotification(response.message));
        if (onSuccess) onSuccess();
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* sendReferralCodeSaga({ payload }: { payload: SendReferralCodePayload }): SagaIterator {
    const { action, email } = payload;
    try {
        yield put(setSentInvite("NOT_SENT"));
        yield put(startAction({ action }));
        const response: SendInviteResponse = yield call(new API().post, "/communication/referral-invite", { email });

        if (!response?.data) {
            throw new Error();
        }

        if (response?.data.sentInvite) {
            yield put(setSentInvite("SENT"));
        }

        if (!response?.data.sentInvite) {
            yield put(setSentInvite("ALREADY_INVITED"));
        }

        handleCleverTap("Referral Invite", {
            email,
            alreadyInvited: !response?.data.sentInvite,
        });
    } catch (error) {
        yield put(setErrorNotification("Unexpected Error while sending referral code"));
        yield put(setSentInvite("ERROR"));
        console.error(error);
        handleException(error);
    } finally {
        yield put(stopAction({ action }));
    }
}

function* checkEmailForCreateUserSaga({
    payload,
}: {
    payload: {
        email: string;
        password: string;
        action: string;
    };
}): SagaIterator {
    const { password, email, action } = payload;
    try {
        const auth = getAuth();
        yield put(startAction({ action }));
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log("*****userCredential=", userCredential);
                store.dispatch(
                    createUser({
                        email,
                        password,
                        uid: user.uid,
                        action,
                    })
                );
            })
            .catch((error) => {
                const { code } = error;
                const isWrongPassword = code === "auth/wrong-password";
                const isNewUser = code === "auth/user-not-found";
                const toManyRequest = code === "auth/too-many-requests";
                if (isWrongPassword) {
                    store.dispatch(setErrorNotification(WRONG_PASSWORD));
                } else if (isNewUser) {
                    store.dispatch(
                        createUser({
                            email,
                            password,
                            action,
                        })
                    );
                } else {
                    store.dispatch(setErrorNotification(error.message));
                }
                handleException(error);

                console.error("***checkEmailForCreateUserSaga signin error=", error.code);
            });
    } catch (error) {
        handleException(error);
        console.error("******checkEmailForCreateUserSaga=", error);
    }
}

function* createUserSaga({
    payload,
}: {
    payload: {
        email: string;
        password?: string;
        action: string;
        uid?: string;
    };
}): SagaIterator {
    const { password, email, action, uid } = payload;
    try {
        yield put(startAction({ action }));
        const response = yield call(new API().post, "/auth/create-dashboard-user", {
            email,
            password,
            uid,
        });

        if (!response?.data) return;

        const user = {
            ...response?.data,
            orgData: response?.data?.orgData,
            displayName: response?.data?.name,
            _id: response?.data?._id,
            emailAuthorized: response?.data?.emailAuthorized,
            checkLinkedinCookie: response?.data?.checkLinkedinCookie,
            liCookie: response?.data?.liCookie,
            isSlackConnected: response?.data?.slackAuthorized ? true : false,
        };
        new API().setLocalStorage({ key: "user", value: JSON.stringify(user) });
        yield put(setUser(user));
        yield put(setIsTokenExpired(false));
        yield put(stopAction({ action }));
    } catch (error) {
        handleException(error);
        yield put(stopAction({ action }));
        console.error("******createUserSaga=", error);
    }
}

function* superAdminCreateUserSaga({
    payload,
}: {
    payload: { action: string; name: string; email: string; password: string; notify: boolean; onSuccess: () => void };
}): SagaIterator {
    const { notify, name, email, password, action, onSuccess } = payload;
    try {
        if (notify) {
            yield put(startAction({ action }));
        } else {
            yield put(startAction({ action: "createUserWithoutNotify" }));
        }
        const response = yield call(new API().post, "/auth/create-superadmin-user", {
            email,
            password,
            name,
            notify: notify,
        });

        if (response?.data?.success) {
            yield put(setSuccessNotification("User was created successfully"));
            onSuccess();
        } else {
            throw new Error("Some error occured while creating the user");
        }
    } catch (error) {
        handleException(error);
        // @ts-ignore
        yield put(setErrorNotification(error?.response?.data?.error || "Some error occured while creating user"));
        console.error("******SuperAdminCreateUser=", error);
    } finally {
        if (payload.notify) {
            yield put(stopAction({ action }));
        } else {
            yield put(stopAction({ action: "createUserWithoutNotify" }));
        }
    }
}

function* authorizeLinkedinSaga(action: PayloadAction<IAuthorizeLinkedinPayload>) {
    try {
        yield put(startAction({ action: authorizeLinkedin.type }));
        const { email, password } = action.payload;
        const response = yield call(new API().post, "/v2/liCookie/linkedin-login", {
            email,
            password,
        });
        if (response) {
            yield call(getLinkedinAuthStatusSaga);
        }
    } catch (error) {
        console.error("authorizeLinkedinSaga error", error);
        handleException(error);
    } finally {
        yield put(stopAction({ action: authorizeLinkedin.type }));
    }
}

function* abortAuthorizeLinkedinSaga(action: PayloadAction) {
    try {
        yield put(startAction({ action: abortAuthorizeLinkedin.type }));
        const response = yield call(new API().post, "/v2/liCookie/abort-linkedin-login", {});
        if (response) {
            yield call(getLinkedinAuthStatusSaga);
            yield put(setLinkedinAuthStatus(null));
        }
    } catch (error) {
        console.error("abortAuthorizeLinkedinSaga error", error);
        handleException(error);
    } finally {
        yield put(stopAction({ action: abortAuthorizeLinkedin.type }));
    }
}

function* submitLinkedinOTPSaga(action: PayloadAction<string>) {
    try {
        yield put(startAction({ action: submitLinkedinOTP.type }));
        const response = yield call(new API().post, "/v2/liCookie/linkedin-otp-update", { otp: action.payload });
        if (response) {
            yield call(getLinkedinAuthStatusSaga);
        }
    } catch (error) {
        console.error("submitLinkedinOTP error", error);
        handleException(error);
    } finally {
        yield put(stopAction({ action: submitLinkedinOTP.type }));
    }
}

function* submitLinkedInCaptchaSaga(action: PayloadAction<string>) {
    try {
        yield put(startAction({ action: submitLinkedinCaptcha.type }));
        const response = yield call(new API().post, "/v2/liCookie/linkedin-captcha-update", { token: action.payload });
        if (response) {
            yield call(getLinkedinAuthStatusSaga);
        }
    } catch (error) {
        console.error("submitLinkedInCaptchaSaga error", error);
        handleException(error);
    } finally {
        yield put(stopAction({ action: submitLinkedinCaptcha.type }));
    }
}

function* getLinkedinAuthStatusSaga() {
    try {
        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(startAction({ action: getLinkedinAuthStatus.type }));
        const response: {
            status: ILinkedinAuthStatus;
            iframeUrl?: string;
        } = yield call(new API().get, "/v2/liCookie/linkedin-auth-status");
        if (response) {
            if (response.status === "SUCCESS") {
                yield put(
                    setUser({
                        ...userStateValue,
                        isLiCookieExpired: false,
                    })
                );
                yield put(setSuccessNotification("Authentication successful"));
            }
            yield put(setLinkedinAuthStatus(response));
        }
    } catch (error) {
        console.error("getLinkedinAuthStatusSaga error", error);
        handleException(error);
    } finally {
        yield put(stopAction({ action: getLinkedinAuthStatus.type }));
    }
}

// superAdminCreateUser
export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(superAdminCreateUser, superAdminCreateUserSaga),
        // @ts-ignore
        yield takeLatest(googleLogin.type, googleLoginSaga),
        // @ts-ignore
        yield takeEvery(authLogin.type, authLoginSaga),
        // @ts-ignore
        yield takeLatest(authorizeEmail.type, authorizeEmailSaga),
        // @ts-ignore
        yield takeLatest(updateGmail.type, updateGmailSaga),
        //@ts-ignore
        yield takeLatest(signUp.type, signUpSaga),
        //@ts-ignore
        yield takeLatest(signIn.type, signInSaga),
        //@ts-ignore
        yield takeLatest(signOut.type, signOutSaga),
        //@ts-ignore
        yield takeLatest(authorizeOutlook.type, authorizeOutlookSaga),
        //@ts-ignore
        yield takeLatest(updateOutlook.type, updateOutlookSaga),
        //@ts-ignore
        yield takeEvery(sendReferralCode.type, sendReferralCodeSaga),
        //@ts-ignore
        yield takeLatest(updateUser.type, updateUserSaga),
        //@ts-ignore
        yield takeLatest(sendJobFunctions.type, sendJobFunctionsResponseSaga),
        //@ts-ignore
        yield takeLatest(
            //@ts-ignore
            checkEmailForCreateUser.type,
            checkEmailForCreateUserSaga
        ),
        //@ts-ignore
        yield takeLatest(createUser.type, createUserSaga),
        // @ts-ignore
        yield takeLatest(authorizeLinkedin.type, authorizeLinkedinSaga),
        // @ts-ignore
        yield takeLatest(getLinkedinAuthStatus.type, getLinkedinAuthStatusSaga),
        // @ts-ignore
        yield takeLatest(submitLinkedinOTP.type, submitLinkedinOTPSaga),
        // @ts-ignore
        yield takeLatest(submitLinkedinCaptcha.type, submitLinkedInCaptchaSaga),
        // @ts-ignore
        yield takeLatest(abortAuthorizeLinkedin.type, abortAuthorizeLinkedinSaga),
        //@ts-ignore
        yield takeLatest(getSaveRecruiterFlowAuth.type, saveRecruiterFlowAuthSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveSignin.type, CancelSagas, tasks);
}
