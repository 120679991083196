import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Render from "./Render";

import { useChatbotContext } from "../../Context";
import ChatbotLoader from "../ChatbotKit/ChatbotLoader";

import {
    getProjectCandidates,
    getShowByProjectFilters,
    initialFilters,
    selectCandidateCurrentPage,
    selectPageSize,
    selectProjectFilters,
} from "@/store/reducers/project/project.reducer";
import { IStageFilters } from "@/store/reducers/project/project.types";

interface ActionProviderProps {
    createChatBotMessage: any;
    setState: any;
    children: any;
    state: any;
}

const ActionProvider = ({ createChatBotMessage, setState, children, state }: ActionProviderProps) => {
    const { actionbot, setActionbot, savedMessageState, setSavedMessageState, isClearAll, setIsClearAll } =
        useChatbotContext();
    const dispatch = useDispatch();
    const params = useParams();
    const projectId = params.id;
    const pageSize = useSelector(selectPageSize);
    const pageNo = useSelector(selectCandidateCurrentPage);
    const filters = useSelector(selectProjectFilters);

    const addResponse = (message: any) => {
        setState((prevState: any) => {
            const messages = [...prevState.messages];
            const loaderId = message.loaderId;

            if (loaderId) {
                const loaderIndex = messages.findIndex((msg: any) => msg.loaderId === loaderId);
                if (loaderIndex !== -1) {
                    messages[loaderIndex] = message;
                } else {
                    messages.push(message);
                }
            } else {
                messages.push(message);
            }

            return { ...prevState, messages };
        });
    };

    useEffect(() => {
        if (state?.messages?.length >= savedMessageState?.messages?.length) {
            const newState = { ...state, projectId };
            setSavedMessageState(newState);
        }

        if (projectId !== savedMessageState?.projectId) {
            setSavedMessageState({ messages: [], projectId });
        }
    }, [state, projectId]);

    useEffect(() => {
        if (isClearAll) {
            setState({ messages: [] });
            setSavedMessageState({ messages: [] });
            setIsClearAll(false);
        }
    }, [isClearAll]);

    const handleOption = (option: string): void => {
        const message = createChatBotMessage(option);
        addResponse(message);
    };

    const isAskingForConfirmation = (message: string): boolean => {
        return message?.includes("I will") && message?.includes("I will learn") === false;
    };

    const greet = (): void => {
        const options = [
            { text: "Why to use", handler: () => handleOption("Why to use"), id: 1 },
            { text: "What to do", handler: () => handleOption("What to do"), id: 2 },
        ];
        const message = createChatBotMessage("Hello friend! How can I assist you today?", {
            widget: "options",
            options: options,
        });

        addResponse(message);
    };

    const reply = ({
        message,
        loaderId,
        triggerAction,
    }: {
        message: string;
        loaderId: string;
        type?: any;
        triggerAction?: any;
    }): void => {
        const botMessageProps = {
            delay: 0,
            widget: isAskingForConfirmation(message) ? "user-confirmation" : undefined,
        };

        const botMessage = createChatBotMessage(<Render message={message} />, botMessageProps);
        botMessage.loaderId = loaderId;
        addResponse(botMessage);
        if (message.includes("triggered successfully")) {
            setActionbot({ ...actionbot, threadId: undefined, reload: true });

            const interval = setInterval(() => {
                dispatch(
                    getProjectCandidates({
                        projectId: projectId,
                        action: getProjectCandidates.type,
                        start: pageNo,
                        limit: pageSize,
                    })
                );
            }, 2000);

            setTimeout(() => {
                clearInterval(interval);
            }, 5000);
        }

        if (triggerAction) {
            const handleSetFilters = (projectFilters: IStageFilters) => {
                dispatch(
                    getShowByProjectFilters({
                        projectId: triggerAction?.projectId,
                        projectFilters: {
                            ...filters,
                            ...projectFilters,
                        },
                        action: getShowByProjectFilters.type,
                    })
                );
            };

            if (triggerAction?.action === "APPLY_FILTERS") {
                handleSetFilters(triggerAction?.actionData?.APPLY_FILTERS?.filterBy);
            }

            if (triggerAction?.action === "RESET_FILTERS") {
                handleSetFilters(initialFilters);
            }
        }

        if (message.includes("Action cancelled")) {
            setActionbot({ ...actionbot, threadId: undefined, reload: true });
        }
    };

    const wait = (loaderId: string) => {
        const botMessage = createChatBotMessage(<ChatbotLoader />, {
            delay: 0,
        });
        botMessage.loaderId = loaderId;
        addResponse(botMessage);
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        createChatBotMessage,
                        setState,
                        greet,
                        reply,
                        wait,
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
