import { useSelector } from "react-redux";

import IntegrationsData from "./components/IntegrationData";

import Navbar from "../../components/Navbar/Navbar";

import { selectUser } from "@/store/reducers/signin/Signin.reducer";

export default function Integrations() {
    const user = useSelector(selectUser);
    return (
        <div style={{ height: user.emailAuthorized ? "calc(100% - 60px)" : "calc(100% - 86px)", overflowY: "auto" }}>
            <Navbar />
            <IntegrationsData />
        </div>
    );
}
