import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, IconButton, Switch } from "@mui/material";

// import { Dropdown } from "antd";
import { isArray } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Checkbox from "../../components/Checkbox/Checkbox";
import CustomDropdown, { CustomDropdownOptionsType } from "../../components/CustomDropdown/CustomDropdown";
import Navbar from "../../components/Navbar/Navbar";
import {
    SAMPLE_CANDIDATES,
    arrowDownIcon,
    filterIcon,
    trashGrayIcon,
} from "../../constant/Constant";
import ProjectCandidates from "../project/components/candidates/candidates";
import style from "../project/project.module.scss";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import {
    selectProjectCandidates,
    selectSelectedCandidates,
    setCandidates,
    setSelectedCandidates,
} from "@/store/reducers/project/project.reducer";
import { IProjectView } from "@/store/reducers/project/project.types";
import { selectTourRelevanceToggle } from "@/store/reducers/tour-container/TourContainer.reducer";

const sortDropDown: CustomDropdownOptionsType[] = [
    {
        label: <p className={style["sp__link"]}>Most Relevant</p>,
        key: "showByRelevance",
    },
    {
        label: <p className={style["sp__link"]}>Most Recent</p>,
        key: "showByMostRecent",
    },
    {
        label: <p className={style["sp__link"]}>Max Experience</p>,
        key: "showByMaxExperience",
    },
    {
        label: <p className={style["sp__link"]}>Min Experience</p>,
        key: "showByMinExperience",
    },
];

const getElement = (text: string) => <Checkbox>{text}</Checkbox>;

const filterDropDown: CustomDropdownOptionsType[] = [
    {
        label: getElement("In pipeline"),
        key: "0",
    },
    {
        label: getElement("Contacted"),
        key: "1",
    },
    {
        label: getElement("Responded"),
        key: "2",
    },
    {
        label: getElement("Shortlisted"),
        key: "3",
    },
    {
        label: getElement("Rejected"),
        key: "4",
    },
    {
        label: getElement("Not interested"),
        key: "5",
    },
    {
        label: getElement("Keep on hold"),
        key: "6",
    },
];

export default function ProjectWalkthrough() {
    const tourRelevanceToggle = useSelector(selectTourRelevanceToggle);
    const candidates = useSelector(selectProjectCandidates);
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const selectCount = selectedCandidates.length;
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);
    const candidateIds = candidates.map((item) => item._id);

    const selectAllCheck =
        !!candidateIds.length &&
        candidateIds.every((item) => {
            const value = selectedCandidateIds.length && selectedCandidateIds.includes(item) ? true : false;
            return value;
        });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeView, setActiveView] = useState<IProjectView>("list");
    const [showByEmailId, setShowByEmailId] = useState(false);
    // const [isMsgModalOpen, setIsMsgModalOpen] = useState<boolean>(false);
    const [openSortDropdown, setOpenSortDropdown] = useState<boolean>(false);
    const [value, setValue] = useState({
        subject: "",
        body: "",
    });

    useEffect(() => {
        dispatch(setCandidates(SAMPLE_CANDIDATES as any));
    }, []);

    useEffect(() => {
        setOpenSortDropdown(tourRelevanceToggle);
    }, [tourRelevanceToggle]);

    const handleSampleProject = () => {
        dispatch(setErrorNotification("This functionality is not available in the sample project"));
    };

    // const openMsgModal = () => setIsMsgModalOpen(true);
    // const closeMsgModal = () => setIsMsgModalOpen(false);

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const tmpCandidateIds = candidateIds;

            dispatch(
                setSelectedCandidates({
                    candidateId: [...tmpCandidateIds],
                    pageNo: 1,
                })
            );
        } else {
            dispatch(
                setSelectedCandidates({
                    candidateId: [],
                    deselectAll: 1,
                })
            );
        }
    };

    const handleAddToWorkflow = () => {
        if (!selectedCandidateIds.length) {
            dispatch(setErrorNotification("Please select candidates"));
            return;
        }
        navigate("/workflows", {
            state: {
                candidates: selectedCandidateIds,
                projectId: "9999",
            },
        });
    };

    // const handleMsgSubject = (val: string) => {
    //     setValue((prev) => ({
    //         ...prev,
    //         subject: val,
    //     }));
    // };

    // const handleMsgBody = (val: string) => {
    //     setValue((prev) => ({
    //         ...prev,
    //         body: val,
    //     }));
    // };

    const onToggleShowByEmail = (val: boolean) => {
        let tmpCandidates;
        if (val) {
            tmpCandidates = candidates.filter((item) => isArray(item.email) && item.email.length && item.canShowEmail);
        } else {
            tmpCandidates = candidates;
        }
        setShowByEmailId(val);
        dispatch(setCandidates(tmpCandidates));
    };

    return (
        <div className={style["sp"]}>
            <div className={style["sp__container"]}>
                <Navbar />
                <div className={style["sp__header"]}>
                    <div className={style["sp__header-left"]}>
                        <div className={style["sp__heading"]}>
                            <p>Sample Project</p>
                            <div className={style["sp__headingBtnBox"]}>
                                <IconButton>
                                    <InfoOutlinedIcon fontSize="small" htmlColor="#6c6c6c" />
                                </IconButton>
                                <IconButton>
                                    <MoreVertIcon fontSize="small" htmlColor="#6c6c6c" />
                                </IconButton>
                            </div>
                        </div>
                        <div className={style["sp__headerWrapper"]}>
                            <p className={style["sp__createDate"]}>
                                Created on -&nbsp;
                                {new Date().toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                    {/* <div className={style["sp__header-right"]}>
                        <div
                            className={`${
                                activeView === "list"
                                    ? style["sp__viewTab-active"]
                                    : ""
                            } ${style["sp__viewTab"]}`}
                            onClick={() => setActiveView("list")}
                        >
                            <FormatListBulletedIcon />
                            <p>List View</p>
                        </div>
                        <div
                            className={`${
                                activeView === "gridA"
                                    ? style["sp__viewTab-active"]
                                    : ""
                            } ${style["sp__viewTab"]}`}
                            onClick={() => setActiveView("gridA")}
                        >
                            <TableChartIcon />
                            <p>Grid View</p>
                        </div>
                    </div> */}
                </div>
                <div className={style["sp__actionbar"]}>
                    <div className={style["sp__actionItem"]}>
                        Pipeline -&nbsp;
                        <span className={style["sp__actionNumber"]}>3</span>
                    </div>
                    <div className={style["sp__actionItem"]}>
                        Contacted -&nbsp;
                        <span className={style["sp__actionNumber"]}>0</span>
                    </div>
                    <div className={style["sp__actionItem"]}>
                        Responded -&nbsp;
                        <span className={style["sp__actionNumber"]}>0</span>
                    </div>

                    <CustomDropdown options={filterDropDown} tooltipTitle="Filter">
                        <div className={`${style["sp__actionItem"]} ${style["sp__actionItem-filter"]}`}>
                            Filter <img className={style["sp__actionItem-img"]} src={arrowDownIcon} alt="" />
                        </div>
                    </CustomDropdown>
                </div>
                <div className={style["sp__body"]}>
                    <div className={style["sp__bodyAction"]}>
                        <Checkbox checked={selectAllCheck} onChange={handleSelectAll} className={style["sp__checkbox"]}>
                            {selectCount} results
                        </Checkbox>

                        <div className={style["sp__bodyActionList"]}>
                            <div className={style["sp__bodyActionCtas"]}>
                                <Button
                                    variant="contained"
                                    id="addToWorkflow"
                                    onClick={handleAddToWorkflow}
                                >
                                    Add to workflow
                                </Button>
                            </div>
                            <div className={style["sp__bodyActionItem-wrapper"]}>
                                <div className={style["sp__bodyActionItem"]} id="personalEmailToggle">
                                    Email IDs
                                    <Switch
                                        checked={showByEmailId}
                                        onChange={(e) => onToggleShowByEmail(e.target.checked)}
                                    />
                                </div>
                                {/* <div
                                    className={
                                        style["sp__bodyActionItem-wrapper"]
                                    }
                                >
                                    <IconButton
                                        disabled={selectCount === 0}
                                        onClick={openMsgModal}
                                        sx={{
                                            padding: "6px",
                                        }}
                                    >
                                        <img
                                            src={profileIcon}
                                            alt="send-email"
                                            width={28}
                                            height={28}
                                        />
                                    </IconButton>
                                    <div
                                        className={style["sp__bodyActionItem"]}
                                        style={{
                                            cursor: "default",
                                            opacity: 0.5,
                                        }}
                                    >
                                        <img
                                            src={cloneIcon}
                                            alt="copy-candidates"
                                        />
                                    </div>
                                    <div
                                        className={style["sp__bodyActionItem"]}
                                        style={{
                                            cursor: "default",
                                            opacity: 0.5,
                                        }}
                                    >
                                        <img
                                            src={trashGrayIcon}
                                            alt="delete-candidates"
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <Dropdown
                            open={openSortDropdown}
                            trigger={["click"]}
                            onOpenChange={(val) => setOpenSortDropdown(val)}
                            menu={{
                                items: sortDropDown,
                                onClick: () => {
                                    setOpenSortDropdown(false);
                                    handleSampleProject();
                                },
                            }}
                        >
                            <div className={style["sp__filter"]}>
                                Sort &nbsp;
                                <img src={filterIcon} alt="sortBy-icon" />
                            </div>
                        </Dropdown> */}
                        <CustomDropdown options={sortDropDown} tooltipTitle="Sort">
                            <div className={style["sp__filter"]}>
                                Sort &nbsp;
                                <img src={filterIcon} alt="sortBy-icon" />
                            </div>
                        </CustomDropdown>
                    </div>
                    <ProjectCandidates activeView={activeView} isSample={true} />
                </div>
            </div>
            {/* <Modal
                open={isMsgModalOpen}
                onClose={closeMsgModal}
                title={"Send Message"}
                width={"70%"}
            >
                <MsgModal
                    handleSendMsg={handleSampleProject}
                    msgSubject={value.subject}
                    msgValue={value.body}
                    setMsgSubject={(subject) => handleMsgSubject(subject as string)}
                    setMsgValue={(body) => handleMsgBody(body as string)}
                />
            </Modal> */}
        </div>
    );
}
