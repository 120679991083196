import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
    selectCandidates,
    selectCandidatesFetchStatus,
    selectTotalCandidates,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import AddListToProject from "../AddListToProject";
import CandidateCard from "../CandidateCard";
import { DisplayMessage } from "../molecules/DisplayMessage";
import { TypingLoader } from "../molecules/TypingLoader";

import { Typography } from "@/ui";

export function CandidateList() {
    const candidates = useSelector(selectCandidates);
    const totalCandidates = useSelector(selectTotalCandidates);
    const candidatesFetchStatus = useSelector(selectCandidatesFetchStatus);
    const projectId = useSearchParams()[0].get("project");

    if (candidatesFetchStatus === "IDLE") {
        return null;
    }

    if (candidatesFetchStatus === "LOADING") {
        return <TypingLoader />;
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col bg-white h-[calc(100%-55px)] rounded-md p-2 gap-1 border border-[rgba(0,0,0,0.1)]">
                {candidates?.length ? (
                    <>
                        <div className="flex flex-row items-center justify-between mb-1">
                            <div className="flex flex-row items-center gap-1">
                                <Typography className="font-semibold">Candidates Preview</Typography>
                            </div>
                            <Typography component={"i"} variant="caption">
                                {totalCandidates} {projectId && `Net new candidates`}
                            </Typography>
                        </div>
                        <hr className="border-t border-gray-300" />
                        <div className="flex flex-col overflow-y-auto">
                            {" "}
                            {candidates?.map((candidate) => <CandidateCard {...candidate} key={candidate._id} />)}
                        </div>
                    </>
                ) : (
                    <DisplayMessage text="No candidates found for this search." />
                )}
            </div>
            <div className="flex flex-row items-center justify-end mt-1 gap-2">
                <AddListToProject />
            </div>
        </div>
    );
}
