import { zodResolver } from "@hookform/resolvers/zod";
import { CssBaseline } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import { CssVarsProvider } from "@mui/joy/styles";
import Typography from "@mui/joy/Typography";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useAppDispatch } from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { inviteMembers } from "@/store/reducers/manage-teams/manageTeams.reducers";
import { Button } from "@/ui";

export const inviteFormSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    email: z.string().email("Please enter a valid email address."),
    role: z.union([z.literal(1), z.literal(0)], {
        errorMap: () => ({ message: "Choose a valid value: either 'admin' or 'member'." }),
    }),
});

type InviteForm = z.infer<typeof inviteFormSchema>;

export function InviteMembers() {
    const dispatch = useAppDispatch();
    const isSubmitting = useSelector(checkIfLoading(inviteMembers.type));
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm<InviteForm>({
        resolver: zodResolver(inviteFormSchema),
        defaultValues: {
            email: "",
            name: "",
        },
    });

    const handleClose = () => {
        navigate(-1);
    };

    const onSubmit: SubmitHandler<InviteForm> = (data) => {
        const { email, name, role } = data;
        dispatch(
            inviteMembers({
                email,
                name,
                role: role === 0 ? "ADMIN" : "MEMBER",
                action: inviteMembers.type,
                successCallback: handleClose,
            })
        );
    };

    return (
        <CssVarsProvider>
            <CssBaseline />
            <Modal open={true} onClose={handleClose}>
                <ModalDialog size="sm" variant="plain" sx={{ width: 500 }}>
                    <ModalClose />
                    <Typography level="title-lg">Invite Members</Typography>
                    <Card variant="plain" component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={1}>
                            <Controller
                                disabled={isSubmitting}
                                control={control}
                                name="name"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl error={!!error?.message}>
                                            <FormLabel>Name</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                disabled={isSubmitting}
                                control={control}
                                name="email"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl error={!!error?.message}>
                                            <FormLabel>Email</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                disabled={isSubmitting}
                                control={control}
                                name="role"
                                render={({ field, fieldState: { error } }) => {
                                    console.log({ error });
                                    const { onChange, ...rest } = field;
                                    return (
                                        <FormControl error={!!error?.message}>
                                            <FormLabel>User Role</FormLabel>
                                            <Select
                                                placeholder="Select a role"
                                                sx={{ minWidth: 200 }}
                                                onChange={(e: any) => {
                                                    onChange(e.target.id === "member" ? 1 : 0);
                                                }}
                                                {...rest}
                                            >
                                                <Option id="admin" value={0}>
                                                    Admin
                                                </Option>
                                                <Option id="member" value={1}>
                                                    Member
                                                </Option>
                                            </Select>
                                            {error?.message && (
                                                <FormHelperText>
                                                    Choose a valid value: either 'Admin' or 'Member'.
                                                </FormHelperText>
                                            )}
                                            <FormHelperText></FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                        </Stack>
                        <CardOverflow>
                            <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    color="neutral"
                                    onClick={() => reset()}
                                    label="Cancel"
                                />
                                <Button
                                    size="sm"
                                    variant="default"
                                    type="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    label="Invite"
                                />
                            </CardActions>
                        </CardOverflow>
                    </Card>
                </ModalDialog>
            </Modal>
        </CssVarsProvider>
    );
}
