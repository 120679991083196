// import React from "react";

// import { Modal } from "@/ui"; // Your custom Modal component

// type Props = {
//     onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
//     children: React.ReactNode;
//     overlayStyles?: string; // Tailwind classes for custom styles
//     backdrop?: boolean; // New prop to control backdrop click behavior
// };

// export default function BaseModal(props: Props) {
//     const { children, overlayStyles, onClose, backdrop = false } = props;

//     return (
//         <Modal
//             open={true}
//             className="overflow-y-auto"
//             onClose={() => {
//                 if (!backdrop) {
//                     onClose();
//                 }
//             }}
//         >
//             {/* Scrollable Content */}
//             <div className="overflow-y-auto h-full p-4">{children}</div>
//         </Modal>
//     );
// }

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import { CSSProperties } from "react";

import { cn } from "@/lib/utils";
import { Modal } from "@/ui";

const styles = `
    absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px]
    bg-white p-8 shadow-lg rounded-lg border-none outline-none 
    max-h-[90vh] md:zoom-[0.8]
`;

type Props = {
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: React.ReactNode;
    overlayStyles?: CSSProperties;
    hideCloseButton?: boolean;
    backdrop?: boolean; // New prop to control backdrop click behavior
};

export default function BaseModal(props: Props) {
    const { children, overlayStyles, onClose, hideCloseButton, backdrop = false } = props;
    return (
        <Modal
            open={true}
            onClose={() => {
                if (!backdrop) {
                    onClose();
                }
            }}
        >
            <div className={cn(styles)} style={{ ...overlayStyles }}>
                {children}
                {!hideCloseButton && (
                    <IconButton
                        onClick={onClose}
                        size="small"
                        sx={{
                            top: 8,
                            right: 8,
                            background: "white",
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{
                            position: "absolute",
                            marginTop: 0,
                            zIndex: 100,
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                )}
            </div>
        </Modal>
    );
}
