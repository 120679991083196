import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { CandidateDetails } from "../../../../store/apis/all-candidates/all-candidates.api";
import { useMarkAsUnreadMutation } from "../../../../store/apis/super-inbox/super-inbox.api";
import { EChannel, EChannelLabel } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { TChannel } from "../../../../store/apis/super-inbox/super-inbox.types";
import { ProjectOption } from "../../../all-candidates/components/ContactOverviewActivities/EditActivityForm";
import Tags, { TagsProps } from "../../atoms/Tags";
import { useSuperInboxContext } from "../../Context";
import { AddActivityNotes } from "../AddActivityNotes";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Button } from "@/ui";


type TSetTags = Dispatch<SetStateAction<TagsProps>>;

export interface ChannelProps {
    candidateProfile?: CandidateDetails;
    tags: TagsProps;
    setTags: TSetTags;
}

interface ChannelTabsProps {
    children: (channel: TChannel, tagsProps: { tags: TagsProps; setTags: TSetTags }) => JSX.Element | null;
}

export default function ChannelTabs(props: ChannelTabsProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [tags, setTags] = useState<TagsProps>({ projects: [], tags: [] });
    const projects: ProjectOption[] = tags.projects.map(({ _id, name }) => ({ label: name, value: String(_id) }));
    const [markAsUnread, { isLoading: markingAsUnread }] = useMarkAsUnreadMutation();

    const {
        filters: { channels, projectId, userId },
        channelParmaType,
        candidateParamId,
        isMobile,
    } = useSuperInboxContext();

    const activeChannels = Object.entries(channels);

    const index = activeChannels.findIndex(([channelName]) => channelName === channelParmaType);

    const handleChangeTab = (event: SyntheticEvent<Element, Event> | null, value: number | null | string) => {
        const target = event?.target as HTMLElement;
        if (typeof value === "number") {
            navigate(`/inbox/${candidateParamId}/${target?.dataset?.value}`);
        }
    };

    return (
        <>
            <Tabs style={{ paddingTop: 3 }} aria-label="Pipeline" value={index} onChange={handleChangeTab}>
                <TabList
                    sx={{
                        // pt: 1,
                        // justifyContent: "center",
                        [`&& .${tabClasses.root}`]: {
                            flex: "initial",
                            bgcolor: "transparent",
                            "&:hover": {
                                bgcolor: "transparent",
                            },
                            [`&.${tabClasses.selected}`]: {
                                color: "#0891b2",
                                "&::after": {
                                    height: 2,
                                    borderTopLeftRadius: 3,
                                    borderTopRightRadius: 3,
                                    bgcolor: "#0891b2",
                                },
                            },
                        },
                    }}
                >
                    {activeChannels.map(([channel]) => (
                        <Tab key={channel} indicatorInset data-value={channel}>
                            {EChannelLabel[channel as TChannel]}
                        </Tab>
                    ))}
                    {!isMobile && (
                        <HoverTruncateText maxWidth={500}>
                            <Tags {...tags} />
                        </HoverTruncateText>
                    )}
                    {projects.length ? <AddActivityNotes projects={projects} /> : null}
                    <Button
                        loading={markingAsUnread}
                        variant="outline"
                        color="neutral"
                        className="self-center justify-self-end mx-1 text-[#0891b2] "
                        size="sm"
                        onClick={() =>
                            markAsUnread({
                                projectId,
                                userId,
                                channel: channelParmaType as EChannel,
                                candidateId: candidateParamId,
                            }).then((response: any) => {
                                if (response?.data?.success) {
                                    dispatch(setSuccessNotification(response?.data?.message));
                                } else {
                                    dispatch(setErrorNotification(response?.data?.message));
                                }
                            })
                        }
                        label="Mark as unread"
                    />
                </TabList>
            </Tabs>
            {index !== -1 && props.children(channelParmaType as TChannel, { tags, setTags })}
        </>
    );
}
