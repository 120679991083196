import { Box, Divider, Typography } from "@mui/material";

function LinkedInWorkflowsTab({ ratingOfLinkedin, linkedInApplicantsQuestionnaire }) {
    return (
        <Box sx={{ padding: "1rem" }}>
            <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                LinkedIn Applicants Details
            </Typography>
            <Box sx={{ marginBottom: "1.5rem" }}>
                <Typography variant="subtitle1" color="textSecondary">
                    Selected Rating:
                </Typography>
                <Typography variant="body1">{ratingOfLinkedin || "UNRATED"}</Typography>
            </Box>
            <Divider sx={{ marginBottom: "1.5rem" }} />
            <Box>
                <Typography variant="subtitle1" sx={{ marginBottom: "1rem" }}>
                    Questionnaire Responses
                </Typography>
                {linkedInApplicantsQuestionnaire.map((qa, index) => (
                    <Box key={index} sx={{ marginBottom: "1rem" }}>
                        <Typography variant="body2" color="textSecondary">
                            {qa.question}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            {qa.answer}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
export default LinkedInWorkflowsTab;
