import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import CardComponent from "./CardComponent";

import { IntegrationConstants } from "../../../utils/IntegrationConstants";

import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="py-2">{children}</div>}
        </div>
    );
}

interface ChildrenData {
    logo: string;
    title: string;
    description: string;
}

interface InterfaceData {
    title: string;
    description: string;
    EGdescription: string;
    subDescription?: string;
    navigation?: string;
    children: ChildrenData[];
}

function RenderData({ props }: { props: InterfaceData }) {
    const isEasySource = useSelector(checkEasySource);
    return (
        <div className="mb-10">
            <h4 className="font-semibold text-lg ml-4 mt-2">{props?.title}</h4>
            <h4 className="w-4/5 ml-4 text-sm mt-2">
                {isEasySource ? props?.description : props?.EGdescription}{" "}
                {props?.title === "Our data partners" ? (
                    <>
                        <a
                            style={{ color: "#0891b2", cursor: "pointer", textDecoration: "none" }}
                            href="mailto:customer-support@hirequotient.com"
                        >
                            customer-support@hirequotient.com{" "}
                        </a>{" "}
                        to learn more about our data partners
                    </>
                ) : (
                    ""
                )}
            </h4>
            <h4 className="w-4/5 ml-4 text-sm mt-2">
                {props?.subDescription && (
                    <a style={{ color: "#0891b2", cursor: "pointer", textDecoration: "none" }} href={props?.navigation}>
                        Click Here{" "}
                    </a>
                )}
                {props?.subDescription}
            </h4>

            <div className="flex flex-wrap justify-center">
                {props?.children?.length > 0 &&
                    props?.children?.map((integration, index) => {
                        if (!isEasySource && integration?.title === "Resume Library") {
                            return null;
                        }
                        return (
                            <div key={index} style={{ margin: "15px" }}>
                                <CardComponent key={index} data={integration} />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default function IntegrationsData() {
    const isEasySource = useSelector(checkEasySource);
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const tabs = isEasySource
        ? ["All Integrations", "Data", "ATS", "Candidate Engagement", "AI Credits"]
        : ["All Integrations", "Data", "ATS", "Prospect Engagement", "AI Credits"];
    const integrations = IntegrationConstants();

    return (
        <>
            <h4 className="font-semibold text-xl px-2.5">Integrations</h4>
            <Tabs value={value} onChange={handleChange} sx={{ borderBottom: 1, borderColor: "divider" }}>
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => {
                return (
                    <CustomTabPanel key={index} value={value} index={index}>
                        {tab === "All Integrations"
                            ? Object.keys(integrations).map((key, index) => {
                                  if (!isEasySource && key === "Candidate Engagement") {
                                      return null; // Skip rendering for ATS and Candidate Engagement when isEasySource is false
                                  }
                                  if (isEasySource && key === "Prospect Engagement") {
                                      return null; // Skip rendering for Prospect Engagement when isEasySource is true
                                  }
                                  return <RenderData key={index} props={integrations[key]} />;
                              })
                            : tabs[value]?.toLowerCase() === tab.toLowerCase() && (
                                  <RenderData props={integrations[tab]} />
                              )}
                    </CustomTabPanel>
                );
            })}
        </>
    );
}
