import { Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";

import { OutReachStatus } from "@/store/reducers/project/project.types";

type OutreachStatusPopoverProps = {
    open: boolean;
    type: "email" | "inmail" | "sms" | null;
    outreachStatus: OutReachStatus | undefined;
    anchorEl: HTMLElement | null;
    handleOutreachPopoverClose: (event: React.MouseEvent<HTMLElement>) => void;
    handleOutreachPopoverOpen?: (event: React.MouseEvent<HTMLElement>) => void;
    handleOutreachPopoverToggle: (event: React.MouseEvent<HTMLElement>) => void;
    outreachTimestamps: any;
};

const TimeStampTooltipTitle = (sentAt: any[], receivedAt: any[], label?: string) => {
    const formattedReceivedAt = receivedAt[0]?.map((obj: any) => {
        return dayjs(obj?.eventTime).format("hh:mm A, MMM DD");
    });
    const formattedSentAt = sentAt[0]?.map((obj: any) => {
        return dayjs(obj?.eventTime).format("hh:mm A, MMM DD");
    });
    return (
        <Stack flexDirection="column" gap={1}>
            {formattedReceivedAt?.length ? (
                <Stack sx={{ marginBottom: "10px" }}>
                    <span style={{ fontSize: "12px" }}>
                        {label?.includes("accepted") ? "Accepted at " : "Received at "}
                    </span>
                    {formattedReceivedAt.map((date: string, i: any) => (
                        <Typography key={`lrec` + i} variant="body2" fontSize="12px">
                            {date}
                        </Typography>
                    ))}
                </Stack>
            ) : null}
            {formattedSentAt?.length ? (
                <Stack sx={{ marginBottom: "10px" }}>
                    <span style={{ fontSize: "12px" }}>Sent at </span>
                    {formattedSentAt?.map((date: string, i: any) => (
                        <Typography key={`lsent` + i} variant="body2" fontSize="12px">
                            {date}
                        </Typography>
                    ))}
                </Stack>
            ) : null}
        </Stack>
    );
};

function OutreachStatusPopover({
    open,
    type,
    outreachStatus,
    anchorEl,
    outreachTimestamps,
    handleOutreachPopoverClose,
    handleOutreachPopoverToggle,
}: OutreachStatusPopoverProps) {
    const { connection, email, followUpEmail, inMail, sms } = outreachStatus || {};
    return (
        <Popover
            sx={{
                pointerEvents: "none",
                "& .MuiPopover-paper": {
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    minWidth: "200px",
                    width: "auto",
                },
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleOutreachPopoverToggle}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            disableRestoreFocus
            disableEnforceFocus
        >
            <Stack
                onMouseLeave={handleOutreachPopoverClose}
                onMouseEnter={handleOutreachPopoverToggle}
                sx={{
                    p: 2,
                    bgcolor: "background.paper",
                    pointerEvents: "all",
                }}
                gap={2}
                onClick={(e) => e.stopPropagation()}
            >
                {type === "email" && (
                    <Stack>
                        <Typography sx={{ fontSize: "13px", fontWeight: "600" }} gutterBottom>
                            Email status
                        </Typography>

                        <Typography sx={{ color: "#6C6C6C", fontSize: "12px", fontWeight: "600" }} >
                            Email {email?.split("_").join(" ").toLowerCase()}
                        </Typography>
                        {TimeStampTooltipTitle(
                            [outreachTimestamps?.emailSentAt],
                            [outreachTimestamps?.emailRespondedAt]
                        )}

                        <Typography sx={{ color: "#6C6C6C", fontSize: "12px", fontWeight: "600" }} gutterBottom>
                            Follow-up email {followUpEmail?.split("_").join(" ").toLowerCase()}
                        </Typography>

                        {TimeStampTooltipTitle(
                            [outreachTimestamps?.followUpEmailSentAt],
                            [outreachTimestamps?.followUpEmailRespondedAt]
                        )}
                    </Stack>
                )}

                {type === "inmail" && (
                    <Stack>
                        <Typography variant="subtitle2" gutterBottom>
                            LinkedIn status
                        </Typography>

                        <Typography variant="subtitle2" sx={{ color: "#6C6C6C", fontSize: "12px" }} gutterBottom>
                            Connection {connection?.split("_").join(" ").toLowerCase()}
                        </Typography>
                        {TimeStampTooltipTitle(
                            [outreachTimestamps?.connectionSentAt],
                            [outreachTimestamps?.connectionRespondedAt]
                        )}

                        <Typography variant="subtitle2" sx={{ color: "#6C6C6C", fontSize: "12px" }} gutterBottom>
                            InMail {inMail?.split("_").join(" ").toLowerCase()}
                        </Typography>

                        {TimeStampTooltipTitle(
                            [outreachTimestamps?.inMailSentAt],
                            [outreachTimestamps?.inMailRespondedAt]
                        )}
                    </Stack>
                )}

                {type === "sms" && (
                    <Stack>
                        <Typography variant="subtitle2" gutterBottom>
                            SMS status
                        </Typography>

                        <Typography variant="subtitle2" sx={{ color: "#6C6C6C", fontSize: "12px" }} gutterBottom>
                            SMS {sms?.split("_").join(" ").toLowerCase()}
                        </Typography>

                        {TimeStampTooltipTitle([outreachTimestamps?.smsSentAt], [outreachTimestamps?.smsRespondedAt])}
                    </Stack>
                )}
            </Stack>
        </Popover>
    );
}

export default OutreachStatusPopover;
