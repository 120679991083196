import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    AtsKeys,
    IAuthLogin,
    IAuthorizeLinkedinPayload,
    IEmailSource,
    ILinkedinAuthStatus,
    IReferralResponse,
    IRootState,
    ISignIn,
    ISignUp,
    IUpdateUserPayload,
    IUserCredits,
    OnBoardModalStates,
    RecruiterFlowAuth,
    SendJobFunctionsResponse,
    SendReferralCodePayload,
    signinType,
} from "./Signin.types";

import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { AppDispatch, RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { config_mode } from "../../utils/Constants";

export const initialState: IRootState = {
    isEasySource: import.meta.env.VITE_REACT_APP_CONFIG_MODE === config_mode.EASY_SOURCE,
    user: {
        emailAuthorized: true,
        referralCode: "",
        sentInvite: "NOT_SENT",
        isSlackConnected: false,
        companyName: null,
        companyLink: null,
        twilioPhoneNumber: null,
        features: {
            smsEnabled: false,
            followUpInMail: false,
            emailExtraction: false,
            intercom: false,
            blendedSearch: "v1",
            superInboxResponse: false,
            aiSearch: false,
            resumeLibrary: false,
            memberFunnel: false,
            isLinkedinApplicantsFetchShow: false,
            sendosoIntegrated: false,
            isCreditMode: false,
            isV2Autopilot: false,
            showAdditionalStats: false,
            linkedinOutreach: false,
            allowPasswordReset: false,
            chatbot: false,
            rotatingMailBox: false,
            candidateListView: false,
            marketResearch: false,
            skipWeekends: false,
            blendSearchCompanies: false,
            rocketReach: false,
        },
        showAllProjects: true,
        aiControls: {
            claude3: false,
            gemini: false,
            llama3: false,
            perplexity: false,
        },
        twilioConfig: {
            isCallForwardingEnabled: false,
            forwardingNumber: "",
            twilioNoResponseText: "",
            isDndEnabled: false,
        },
    },
    isSignupClicked: false,
    fromExtension: false,
    fromInvitation: false,
    isTokenExpired: false,
    isRegisterApiCalled: false,
    userCredits: {
        currentCredits: 0,
        totalCredits: 0,
        remainingCredits: 0,
    },
    modalState: "IDLE",
    creditsReward: 0,
    linkedinAuthStatus: null,
};

const reducers = {
    setCalendlyState(state: typeof initialState, action: PayloadAction<boolean>) {
        state.user.calendlyAuthorized = action.payload;
    },
    sendJobFunctions(state: typeof initialState, { payload }: { payload: SendJobFunctionsResponse }) {},
    setFromExtension(state: typeof initialState, { payload }: { payload: boolean }) {
        state.fromExtension = payload;
    },
    setSignUpModalState(state: typeof initialState, { payload }: { payload: OnBoardModalStates }) {
        state.modalState = payload;
    },
    setFromInvitation(state: typeof initialState, { payload }: { payload: boolean }) {
        state.fromInvitation = true;
    },
    googleLogin(state: typeof initialState, { payload }: { payload: string }) {},
    signUp(state: typeof initialState, { payload }: { payload: IActionPayload & ISignUp }) {
        state.isSignupClicked = true;
    },
    signIn(state: typeof initialState, { payload }: { payload: IActionPayload & ISignIn }) {},
    signOut(state: typeof initialState) {
        document.cookie = `webAccessToken=`;
        document.cookie = `accessToken=`;
        document.cookie = `_id=`;
        document.cookie = `uuid=`;
        document.cookie = `email=`;
    },
    toggleIsSendosoIntegrated(state: typeof initialState, { payload }: { payload: boolean }) {
        state.user.features.sendosoIntegrated = payload;
    },

    authLogin(state: typeof initialState, { payload }: { payload: IActionPayload & IAuthLogin }) {},
    setUser(state: typeof initialState, { payload }: { payload: signinType }) {
        const { _id, uuid, email } = payload;

        const updatedUser = {
            ...state.user,
            ...payload,
        };

        state.user = updatedUser;
        state.isRegisterApiCalled = false;

        document.cookie = `_id=${_id}`;
        document.cookie = `uuid=${uuid}`;
        document.cookie = `email=${email}`;
    },
    setIsRegisterApiCalled(state: typeof initialState, { payload }: { payload: boolean }) {
        state.isRegisterApiCalled = payload;
    },
    authorizeEmail(state: typeof initialState) {},
    authorizeOutlook(state: typeof initialState) {},
    authorizeLinkedin(state: typeof initialState, action: PayloadAction<IAuthorizeLinkedinPayload>) {},
    abortAuthorizeLinkedin(state: typeof initialState, action: PayloadAction) {},
    submitLinkedinOTP(state: typeof initialState, action: PayloadAction<string>) {},
    submitLinkedinCaptcha(state: typeof initialState, action: PayloadAction<string>) {},
    getLinkedinAuthStatus(state: typeof initialState, action: PayloadAction) {},
    getSaveRecruiterFlowAuth(state: typeof initialState, action: PayloadAction<RecruiterFlowAuth>) {},
    setLinkedinAuthStatus(
        state: typeof initialState,
        action: PayloadAction<{ status: ILinkedinAuthStatus; iframeUrl?: string }>
    ) {
        state.linkedinAuthStatus = action.payload;
    },
    updateGmail(
        state: typeof initialState,
        { payload }: { payload: { code: string; emailSource: IEmailSource; userId: string } }
    ) {},
    updateOutlook(
        state: typeof initialState,
        { payload }: { payload: { authToken: string; emailSource: IEmailSource; userId: string } }
    ) {},
    setIsTokenExpired(state: typeof initialState, { payload }: { payload: boolean }) {
        state.isTokenExpired = payload;
    },
    setIsSignupClicked(state: typeof initialState, { payload }: { payload: boolean }) {
        state.isSignupClicked = payload;
    },
    updateUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IUpdateUserPayload & IActionPayload;
        }
    ) {},
    leaveSignin(state: typeof initialState) {},
    sendReferralCode(state: typeof initialState, { payload }: { payload: SendReferralCodePayload }) {},
    setSentInvite(state: typeof initialState, { payload }: { payload: IReferralResponse }) {
        state.user.sentInvite = payload;
    },
    setUserCredits(state: typeof initialState, { payload }: { payload: IUserCredits }) {
        state.userCredits.currentCredits = payload.currentCredits;
        state.userCredits.totalCredits = payload.totalCredits;
        state.userCredits.remainingCredits = payload.remainingCredits;
    },
    setCreditsReward(state: typeof initialState) {
        state.creditsReward += 1;
    },
    checkEmailForCreateUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                email: string;
                password: string;
                action: string;
            };
        }
    ) {},
    createUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                email: string;
                password?: string;
                action: string;
                uid?: string;
            };
        }
    ) {},
    // feature for superadmin to create user
    superAdminCreateUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                action: string;
                name: string;
                email: string;
                password: string;
                notify: boolean;
                onSuccess: () => void;
            };
        }
    ) {},
};

export const signinPage = createSlice({
    name: "signin",
    initialState,
    reducers,
});

export default signinPage.reducer;
export const {
    setCalendlyState,
    googleLogin,
    setUser,
    authLogin,
    authorizeEmail,
    updateGmail,
    signUp,
    signIn,
    signOut,
    leaveSignin,
    authorizeOutlook,
    updateOutlook,
    setFromExtension,
    setFromInvitation,
    setIsTokenExpired,
    sendReferralCode,
    setSentInvite,
    updateUser,
    toggleIsSendosoIntegrated,
    setIsSignupClicked,
    setUserCredits,
    setIsRegisterApiCalled,
    setSignUpModalState,
    sendJobFunctions,
    setCreditsReward,
    createUser,
    checkEmailForCreateUser,
    superAdminCreateUser,
    authorizeLinkedin,
    getLinkedinAuthStatus,
    setLinkedinAuthStatus,
    getSaveRecruiterFlowAuth,
    submitLinkedinOTP,
    submitLinkedinCaptcha,
    abortAuthorizeLinkedin,
} = signinPage.actions;

// Define a type for the argument
type CalendlyState = "ERROR" | "SUCCESS";

// Define the async thunk
export const updateCalendlyState = createAsyncThunk<void, CalendlyState, { dispatch: AppDispatch; state: RootState }>(
    "calendly/updateState", // Action type
    async (calendlyState, { dispatch }) => {
        if (calendlyState === "ERROR") {
            dispatch(setErrorNotification("Unexpected error occurred while connecting with calendly"));
        }

        if (calendlyState === "SUCCESS") {
            dispatch(setCalendlyState(true));
            dispatch(setSuccessNotification("Successfully connected to calendly"));
        }
    }
);

export const checkIsLinkedinAuthorized = (state: RootState) => {
    return !state.signin.user.isLiCookieExpired;
};
export const checkAllowPasswordReset = (state: RootState) => state.signin?.user?.features?.allowPasswordReset;

export const checkIfAccountRB2B = (state: RootState) => state.signin.user.isRB2B;
export const selectLiCookie = (state: RootState) => state.signin.user.liCookie;
export const checkEasySource = (state: RootState) => state.signin.isEasySource === true;
export const checkEasyGrowth = (state: RootState) => state.signin.isEasySource === false;
export const selectUser = (state: RootState) => state.signin.user;
export const selectUserRole = (state: RootState) => state.signin.user?.roleType;
export const isSuperAdminUser = (state: RootState) => state.signin.user.isSuperAdmin;
export const isCsMemberCheck = (state: RootState) => state.signin.user.isCSMember;
export const selectFromExtension = (state: RootState) => state.signin.fromExtension;
export const selectFromInvitation = (state: RootState) => state.signin.fromInvitation;
export const selectIsSignupClicked = (state: RootState) => state.signin.isSignupClicked;
export const selectIsRegisterApiCalled = (state: RootState) => state.signin.isRegisterApiCalled;
export const selectUserCredits = (state: RootState) => state.signin.userCredits;
export const selectSignupModalState = (state: RootState) => state.signin.modalState;
export const selectTokenExpired = (state: RootState) => state.signin.isTokenExpired;
export const selectCreditsReward = (state: RootState) => state.signin.creditsReward;
export const selectUserEmail = (state: RootState) => state.signin?.user?.email;
export const isSESIntegrated = (state: RootState) => state.signin?.user?.sesIntegrated;
export const isSendGridEnabled = (state: RootState) => state.signin?.user?.sendGrid;
export const isBlendSearchCompaniesEnabled = (state: RootState) => state.signin?.user?.features?.blendSearchCompanies;
export const selectEmailSource = (state: RootState) => state.signin?.user?.emailSource;
export const checkCalendlyAuthorized = (state: RootState) => state.signin?.user?.calendlyAuthorized;
export const checkSMSEnabled = (state: RootState) => state.signin?.user?.features?.smsEnabled;
export const checkFollowUpInMailAllowed = (state: RootState) => state.signin?.user?.features?.followUpInMail;
export const checkInboxVersion = (state: RootState) => state.signin?.user?.features?.inbox;
export const checkAnalyticsVersion = (state: RootState) => state.signin?.user?.features?.analytics;
export const checkBlendedSearchVersion = (state: RootState) => state.signin?.user?.features?.blendedSearch;
export const checkOutreachVersion = (state: RootState) => state.signin?.user?.features?.outreach;
export const checkIntercomEnabled = (state: RootState) => state.signin?.user?.features?.intercom;
export const checkChatbotEnabled = (state: RootState) => state.signin?.user?.features?.chatbot;
export const checkCreditsTrackingEnabled = (state: RootState) => state.signin?.user?.features?.creditsTracking;
export const checkCandidateListViewEnabled = (state: RootState) => state.signin?.user?.features?.candidateListView;
export const selectSMSPhoneNumber = (state: RootState) => state.signin?.user?.smsAuth?.phoneNumber;
export const selectLeverIntegration = (state: RootState) => state.signin?.user?.atsIntegrations?.lever;
export const selectGreenhouseIntegration = (state: RootState) => state.signin?.user?.atsIntegrations?.greenhouse;
export const selectZohoIntegration = (state: RootState) => state.signin?.user?.atsIntegrations?.zoho;
export const selectJobViteIntegration = (state: RootState) => state.signin?.user?.atsIntegrations?.jobvite;
export const selectLoxoIntegration = (state: RootState) => state.signin?.user?.atsIntegrations?.loxo;
export const selectUserOrgId = (state: RootState) => state.signin?.user?.orgData?._id;
export const selectZohoRefreshCreds = (state: RootState) => state.signin?.user?.zohoRecruitCreds;
export const selectEmailExtraction = (state: RootState) => state.signin?.user?.features?.emailExtraction;
export const selectShowAllProjects = (state: RootState) => state.signin?.user?.showAllProjects;
export const checkRecruiterInMail = (state: RootState) => state.signin?.user?.features?.recruiterInMail;
export const checkSuperInboxResponseEnabled = (state: RootState) => state.signin?.user?.features?.superInboxResponse;
export const checkLinkedinOutreachEnabled = (state: RootState) => state.signin?.user?.features?.linkedinOutreach;
export const checkShowAdditionalStats = (state: RootState) => state.signin?.user?.features?.showAdditionalStats;
export const checkAiSearchEnabled = (state: RootState) => state.signin?.user?.features?.aiSearch;
export const checkRotatingMailBoxEnabled = (state: RootState) => state.signin?.user?.features?.rotatingMailBox;
export const checkMemberFunnel = (state: RootState) => state.signin?.user?.features?.memberFunnel;
export const checkSendosoIntegrated = (state: RootState) => state.signin?.user?.features?.sendosoIntegrated;
export const checkCreditMode = (state: RootState) => state.signin?.user?.features?.isCreditMode;
export const checkIsV2Autopilot = (state: RootState) => state.signin?.user?.features?.isV2Autopilot;
export const checkResumeLibraryEnabled = (state: RootState) => state.signin?.user?.features?.resumeLibrary;
export const checkMarketResearchEnabled = (state: RootState) => state.signin?.user?.features?.marketResearch;
export const checkRocketReachEnabled = (state: RootState) => state.signin?.user?.features?.rocketReach;
export const checkUniverseCountEnabled = (state: RootState) => state.signin?.user?.features?.universeCount;

export const checkIsLinkedinApplicantsFetchShow = (state: RootState) =>
    state.signin?.user?.features?.isLinkedinApplicantsFetchShow;
export const selectEnabledIntegrationsPush = (state: RootState): AtsKeys[] => {
    const keys: Record<AtsKeys, boolean | undefined> = {
        lever: state.signin?.user?.atsIntegrations?.lever?.push,
        jazzHR: state.signin?.user?.atsIntegrations?.jazzHR?.push,
        zoho: state.signin?.user?.atsIntegrations?.zoho?.push,
        loxo: state.signin?.user?.atsIntegrations?.loxo?.push,
        greenhouse: state.signin?.user?.atsIntegrations?.greenhouse?.push,
        jobvite: state.signin?.user?.atsIntegrations?.jobvite?.push,
        bambooHR: state.signin?.user?.atsIntegrations?.bambooHR?.push,
        laborEdge: state.signin?.user?.atsIntegrations?.laborEdge?.push,
        nucleus: state.signin?.user?.atsIntegrations?.nucleus?.push,
        recruiterFlow: state.signin?.user?.atsIntegrations?.recruiterFlow?.push,
    };

    return Object.entries(keys)?.reduce((acc: AtsKeys[], [key, value]) => {
        if (value === true) {
            acc.push(key as AtsKeys);
        }

        return acc;
    }, []);
};

export const selectEnabledIntegrationsPull = (state: RootState): AtsKeys[] => {
    const keys: Record<AtsKeys, boolean | undefined> = {
        lever: state.signin?.user?.atsIntegrations?.lever?.pull,
        jazzHR: state.signin?.user?.atsIntegrations?.jazzHR?.pull,
        zoho: state.signin?.user?.atsIntegrations?.zoho?.pull,
        loxo: state.signin?.user?.atsIntegrations?.loxo?.pull,
        greenhouse: state.signin?.user?.atsIntegrations?.greenhouse?.pull,
        jobvite: state.signin?.user?.atsIntegrations?.jobvite?.pull,
        bambooHR: state.signin?.user?.atsIntegrations?.bambooHR?.pull,
        laborEdge: state.signin?.user?.atsIntegrations?.laborEdge?.pull,
        recruiterFlow: state.signin?.user?.atsIntegrations?.recruiterFlow?.pull,
    };

    return Object.entries(keys)?.reduce((acc: AtsKeys[], [key, value]) => {
        if (value === true) {
            acc.push(key as AtsKeys);
        }

        return acc;
    }, []);
};

export const selectLinkedinAuthStatus = (state: RootState) => state.signin.linkedinAuthStatus?.status;
export const selectLinkedinAuthIframeUrl = (state: RootState) => state.signin.linkedinAuthStatus?.iframeUrl;
