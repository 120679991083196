import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CircularProgress from "@mui/joy/CircularProgress";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Experimental_CssVarsProvider } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


import { JoyProvider } from "../components/JoyProvider";
import Navbar from "../components/Navbar/Navbar";
import { useAppDispatch } from "../store";
import { checkIfLoading } from "../store/reducers/loaders.reducer";

import {
    getNotification,
    markReadNotification,
    rejectInvite,
    selectNotification,
} from "@/store/reducers/manage-teams/manageTeams.reducers";
import { INotification } from "@/store/reducers/manage-teams/manageTeams.types";

function NotificationCard(props: INotification) {
    const dispatch = useAppDispatch();
    const isAcceptLoading = useSelector(checkIfLoading(markReadNotification.type));
    const isRejectLoading = useSelector(checkIfLoading(rejectInvite.type));
    const isDisable = isAcceptLoading || isRejectLoading;
    const { title, body, _id, candidateId, updatedAt, isSms, projectName, candidateName, redirectUrl } = props;

    const handleClick = () => {
        dispatch(markReadNotification([_id]));
        if (isSms) {
            window.open(`/inbox/${candidateId}/sms`);
        } else {
            if (redirectUrl) {
                window.open(`/candidate-reachout${redirectUrl}`);
            }
        }
    };

    return (
        <Card>
            <CardContent sx={{ position: "relative" }}>
                <Typography level="body-sm" sx={{ position: "absolute", top: 0, right: 0 }}>
                    {dayjs(updatedAt).format("MMM D, h:mm A")}
                </Typography>
                <Stack gap={1} maxWidth="80%">
                    <Stack gap={0.7}>
                        <Typography level="title-md">{title}</Typography>
                        <Typography level="body-md">{body}</Typography>
                        <Stack direction="row" gap={3}>
                            {projectName && <Typography level="body-sm">Project: {projectName}</Typography>}
                            <Divider orientation="vertical" />
                            {candidateName && (
                                <Typography level="body-sm">
                                    Candidate:{" "}
                                    <Typography
                                        component={Link}
                                        to={`/candidate-reachout/${candidateId}`}
                                        sx={{ color: "primary.500", textDecoration: "none" }}
                                    >
                                        {candidateName}
                                    </Typography>
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
                <Button
                    sx={{ position: "absolute", bottom: 0, right: 0 }}
                    size="sm"
                    disabled={isDisable}
                    onClick={handleClick}
                >
                    {isSms ? "View message" : "View on activity page"}
                </Button>
            </CardContent>
        </Card>
    );
}

function NotificationsLayout({ children }: { children: React.ReactNode }) {
    return (
        <JoyProvider>
            <Box sx={{ backgroundColor: "background.body", minHeight: "100vh" }}>
                <Box sx={{ pt: 1, pr: 1 }}>
                    <Experimental_CssVarsProvider>
                        <Navbar />
                    </Experimental_CssVarsProvider>
                </Box>
                {children}
            </Box>
        </JoyProvider>
    );
}

function NotificationsMain() {
    const notifications = useSelector(selectNotification);
    const isLoading = useSelector(checkIfLoading(getNotification.type));

    if (isLoading) {
        return (
            <Stack sx={{ height: "70vh" }} alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <Stack sx={{ height: "calc(100vh - 130px)", overflow: "auto" }} p={2} gap={1.5}>
            {notifications.map((i) => {
                return <NotificationCard key={i._id} {...i} />;
            })}
        </Stack>
    );
}

export function Notifications() {
    return (
        <NotificationsLayout>
            <Stack gap={1} sx={{ py: 1, px: 2 }}>
                <Typography level="h2">Notifications</Typography>
            </Stack>
            <Divider />
            <NotificationsMain />
        </NotificationsLayout>
    );
}
