import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useOutreachParams } from "./useOutreachParams.hook";

import { RootState } from "../../../store";
import { useCreatePreviewQuery } from "../../../store/apis/outreach/outreach.api";
import { EventBody, EventNames } from "../../../store/apis/outreach/outreach.types";

import {
    selectCurrentOutreachElementType,
    selectOutreachTemplateContent,
    selectWritingStyleChangePayload,
} from "@/store/reducers/outreach/outreach.slice";
import { selectUnsubscribeEmail } from "@/store/reducers/workflow/workflow.reducer";

type CreatePreviewArgs = {
    newCandidateId?: string;
    newEventName?: EventNames;
    newEventBody?: EventBody;
};

export function useCreatePreview() {
    const { candidateId, projectId, currentOutreachElementId } = useOutreachParams();
    const unsubscribeEmail = useSelector(selectUnsubscribeEmail);

    const template = useSelector((state: RootState) => selectOutreachTemplateContent(state, currentOutreachElementId));

    const writingStyleChangePayload = useSelector(selectWritingStyleChangePayload);
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const { ...rest } = useCreatePreviewQuery({
        candidateId,
        projectId,
        eventBody: template,
        eventName: currentElementType,
        unsubscribeEmail,
    });
    useEffect(() => {
        if (writingStyleChangePayload) {
            rest.refetch();
        }
    }, [writingStyleChangePayload]);

    return { template, currentElementType, ...rest };
}
