import { CircularProgress, Stack, styled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectAddContactListToProjectModal, setModal } from "../../../store/reducers/modals.slice";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { MUISelect } from "../../triggerWorkflow/components/PersonalizedInputsModal";

import { getAllProjectsList, selectAllProjects } from "@/store/reducers/allProjects/allProjects.reducer";
import { addContactListToProject, selectContactListIdForProject, setContactListIdForProject, } from "@/store/reducers/contact-list/contact-list.slice";

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));
export default function AddContactListToProjectModal() {
    const [project, setProject] = useState("");
    const allProjectsList = useSelector(selectAllProjects);
    const contactListId = useSelector(selectContactListIdForProject);
    const navigate = useNavigate();
    const isAdding = useSelector(checkIfLoading(addContactListToProject.type));
    const isGetAllProjectsLoading = useSelector(checkIfLoading(getAllProjectsList.type));

    const dispatch = useDispatch();
    const isContactListToProjectModalOpen = useSelector(selectAddContactListToProjectModal);

    useEffect(() => {
        return () => {
            dispatch(setContactListIdForProject(""));
        };
    }, []);

    useEffect(() => {
        if (!allProjectsList.length) {
            dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
        }
    }, [allProjectsList]);

    const onClose = () => dispatch(setModal({ key: "showAddContactListToProjectModal", value: false }));

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string;
        setProject(value);
    };

    const handleSubmit = () =>
        dispatch(
            addContactListToProject({
                contactListId,
                projectId: project,
                navigate: () => navigate(`/projects/${project}`),
            })
        );

    if (!isContactListToProjectModalOpen) {
        return null;
    }

    return (
        <BaseModal onClose={onClose}>
            <Stack spacing={1.3}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    Projects
                </Typography>
                {isGetAllProjectsLoading ? (
                    <Stack alignItems="center" justifyContent="center">
                        <CircularProgress size={25} />
                    </Stack>
                ) : (
                    <MUISelect
                        variant="filled"
                        inputProps={{
                            style: {
                                marginTop: 0,
                                fontSize: "16px",
                            },
                        }}
                        values={project}
                        handleChange={handleChange}
                        options={allProjectsList.map(({ _id, name }) => ({
                            id: String(_id),
                            value: String(_id),
                            label: name,
                        }))}
                    />
                )}
                <CustomButton type="submit" variant="contained" sx={{ alignSelf: "flex-end" }} onClick={handleSubmit}>
                    <ButtonTextWithLoading isLoading={isAdding} text="submit" />
                </CustomButton>
            </Stack>
        </BaseModal>
    );
}
