import React, { useEffect, useState } from "react";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import CallIcon from "@mui/icons-material/Call";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Button, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import CallHistoryTab from "./components/CallHistoryTab";
import CallTab from "./components/CallTab";
import CandidatePowerDialer from "./components/CandidatePowerDialer";
import CustomTabPanel from "./components/CustomTabPanel";
import SettingsTab from "./components/SettingsTab";
import PowerDialer from "./PowerDialer";

export default function CallLogs() {
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const tab = location.pathname.split("/")[2];
    const tabs = [
        { label: "Call", icon: <CallIcon />, index: 0 },
        { label: "Power-Dialer", icon: <AddIcCallIcon />, index: 1 },
        { label: "Call-History", icon: <ContactPhoneOutlinedIcon />, index: 2 },
        { label: "Settings", icon: <SettingsOutlinedIcon />, index: 3 },
    ];
    const value = tabs.findIndex((t) => t.label.toLowerCase() === tab) || 0;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(`/call-logs/${tabs[newValue].label.toLowerCase()}`);
    };


    return (
        <Stack height={user.emailAuthorized ? "calc(100vh - 60px)" : "calc(100vh - 86px)"} sx={{ overflowY: "auto" }}>
            <Navbar />
            <Box sx={{ width: "100%" }}>
                <Box>
                    {tabs.map((tab) => (
                        <Button
                            key={tab.index}
                            onClick={() => handleChange(null, tab.index)}
                            startIcon={tab.icon}
                            sx={{
                                marginRight: 2,
                                border: "none",
                                borderRadius: 1,
                                backgroundColor: value === tab.index ? "primary.main" : "transparent",
                                color: value === tab.index ? "common.white" : "common.black",
                                "&:hover": {
                                    backgroundColor: value === tab.index ? "primary.main" : "transparent",
                                    color: value === tab.index ? "common.white" : "common.black",
                                },
                            }}
                            variant="text"
                        >
                            {tab?.label?.replace("-", " ")}
                        </Button>
                    ))}
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <CallTab />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {id ? <CandidatePowerDialer /> : <PowerDialer />}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <CallHistoryTab />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <SettingsTab />
                </CustomTabPanel>
            </Box>
        </Stack>
    );
}
