import { Chip, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import useSearchState from "../../../../hooks/useSearchState";
import AutocompleteWithExclusion from "../../../../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectFilter, setValues } from "../../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../../store/reducers/search/search.types";

interface BSAutocompleteWithExclusionProps {
    id: SearchStateKeys;
    label: string;
    placeholder: string;
}

export function BSAutocompleteWithExclusion({ id, label, placeholder }: BSAutocompleteWithExclusionProps) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectFilter(id));

    const handleChange = (value: typeof values) => {
        dispatch(setValues({ key: id, value }));
    };

    return (
        <AutocompleteWithExclusion
            size="small"
            label={label}
            placeholder={placeholder}
            freeSolo
            value={values}
            options={Object.keys(options).map((i) => i)}
            onChange={handleChange}
            inputValue={query}
            onInputChange={setQuery}
            loading={isLoading}
            doNotFilter={true}
            autoSave
            renderLoading={() => (
                <Chip
                    size="small"
                    color="primary"
                    icon={<CircularProgress size={15} />}
                    label="Loading AI results"
                />
            )}
        />
    );
}