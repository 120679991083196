import CancelIcon from "@mui/icons-material/Cancel";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Input from "@mui/joy/Input";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { useSuperInboxContext } from "../../Context";
import { FilterDrawer } from "../filters/FilterDrawer";

import { Progress as LinearProgress } from "@/ui";

function SearchInput() {
    const {
        filters: { setSearchQuery },
    } = useSuperInboxContext();
    const [query, setQuery] = useState("");

    const debouncedSetSearchQuery = useCallback(debounce(setSearchQuery, 500), []);

    useEffect(() => {
        debouncedSetSearchQuery(query);
    }, [query]);

    return (
        <Input
            size="sm"
            startDecorator={<SearchRoundedIcon />}
            endDecorator={
                query ? (
                    <button onClick={() => setQuery("")}>
                        <CancelIcon />
                    </button>
                ) : null
            }
            placeholder="Search"
            aria-label="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
        />
    );
}

export function ChatsPaneHeader() {
    const { totalCandidates, isFetchingCandidates, isLoadingCandidates } = useSuperInboxContext();

    return (
        <div>
            <div className="flex flex-wrap items-center justify-between p-2 pb-1.5 gap-1">
                <div className="w-[83%]">
                    <h1 className="text-md md:text-lg font-semibold mr-auto">Messages</h1>
                    <i className="text-[11px]">
                        <b>{totalCandidates}</b> conversations found
                    </i>
                </div>
                <div className="w-[15] flex justify-end items-center">
                    <FilterDrawer />
                </div>
            </div>
            <div className="px-2 pb-1.5">
                <SearchInput />
            </div>
            <div className="h-4">
                {!isLoadingCandidates && isFetchingCandidates && <LinearProgress variant="indeterminate" />}
            </div>
        </div>
    );
}
