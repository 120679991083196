// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSuccessNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, setUser, updateUser } from "../../Signin/Signin.reducer";

import { Button, Modal, Typography } from "@/ui";

export default function NucleusConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {
        companyName,
        companyUrl,
        linkedinUrl,
        name,
        outboundEmail,
        userEmail,
        workspaceName,
        role,
        signature,
    }: any = user;
    const [apiKey, setApiKey] = useState("");

    const isLoading = useSelector(checkIfLoading(updateUser.type));

    useEffect(() => {
        if (isEmpty(user)) return;
        setApiKey(user.nucleusApiKey ?? "");
    }, [user]);

    const handleConnect = () => {
        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                nucleusApiKey: apiKey,
                onSuccess: onClose,
            })
        );
        dispatch(setUser({ ...user, nucleusApiKey: apiKey }));
        dispatch(setSuccessNotification("Successfully connected to Nucleus ATS"));
    };

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ maxWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Connect Nucleus ATS</h2>
                <div className=" space-x-2 mb-2">
                    <Typography className="text-sm">
                        Nucleus ATS is a powerful applicant tracking system. Enter your API key to connect your Nucleus
                        ATS account.
                    </Typography>
                </div>
                <input
                    placeholder="Nucleus API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    className={`min-w-[300px] text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2  border-gray-300 focus:ring-blue-500"}`}
                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6, width:"100%"}}
                />
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant="default"
                        onClick={handleConnect}
                        className="rounded-sm"
                        disabled={!apiKey || isLoading || !!user.nucleusApiKey}
                        label={user.nucleusApiKey ? "Connected" : "Connect"}
                    />
                </div>
            </div>
        </Modal>
    );
}
