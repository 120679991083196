const Loader = ({ size = "md", color="cyan" }) => {
    const sizeClasses = {
        sm: "w-6 h-6",
        md: "w-12 h-12",
        lg: "w-16 h-16",
        default: "w-6 h-6",
    };

    return (
        <div
            className={`${sizeClasses[size]} relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-4 border-${color}-600 border-t-transparent rounded-full animate-spin`}
        />
    );
};

export default Loader;
