import { isNil } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { setModal } from "../reducers/modals.slice";

import {
    SubmitAutoSearchLinksPayload,
    cancelSagas,
    fetchAutoSearchDetails,
    setAutoSearchState,
    submitAutoSearchDetails,
} from "@/store/reducers/edit-auto-search/editAutoSearch.slice";
import {
    FetchAutoSearchDetailsPayload,
    FetchAutoSearchDetailsResponse,
} from "@/store/reducers/edit-auto-search/editAutoSearch.types";

function* fetchAutoSearchDetailsSaga(action: FetchAutoSearchDetailsPayload): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response: FetchAutoSearchDetailsResponse = yield call(new API().get, `/auto-search/${action.payload}`);
        if (isNil(response?.data?.scrapeLinks)) {
            throw new Error();
        }

        yield put(setAutoSearchState(response.data.scrapeLinks));
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Auto search details unavailable"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitAutoSearchDetailsSaga(action: SubmitAutoSearchLinksPayload): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { id, scrapeLinks } = action.payload;
        const requestPayload = {
            projectId: id,
            scrapeLinks: scrapeLinks.map(({ _id, ...rest }) => ({ ...rest })),
        };
        const response: FetchAutoSearchDetailsResponse = yield call(
            new API().post,
            `/auto-search/edit/${id}`,
            requestPayload
        );

        if (isNil(response?.data?.scrapeLinks)) {
            throw new Error();
        }

        yield put(setModal({ key: "showEditAutoSearch", value: false }));

        yield put(setSuccessNotification("Auto Search Links Updated Successfully..."));
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("error occurred while updating auto search links"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(
            submitAutoSearchDetails.type,
            // @ts-ignore
            submitAutoSearchDetailsSaga
        ),
        // @ts-ignore
        yield takeLatest(
            fetchAutoSearchDetails.type,
            // @ts-ignore
            fetchAutoSearchDetailsSaga
        ),
    ];

    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
