import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

const WorkingHours: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [startTime, setStartTime] = React.useState<Dayjs | null>(dayjs());
    const [endTime, setEndTime] = React.useState<Dayjs | null>(dayjs());
    const [selectedZone, setSelectedZone] = React.useState<string>("UTC");

    React.useEffect(() => {
        setStartTime(user?.workingHours?.start ? dayjs(user.workingHours.start, "HH:mm") : startTime);
        setEndTime(user?.workingHours?.end ? dayjs(user.workingHours.end, "HH:mm") : endTime);
        setSelectedZone(user?.workingHours?.timezone || "UTC");
    }, [user]);

    const handleZoneChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newZone = e.target.value as string;
        setSelectedZone(newZone);
    };

    const handleWorkHours = () => {
        dispatch(
            updateUser({
                ...user,
                workingHours: {
                    start: dayjs(startTime).format("HH:mm"),
                    end: dayjs(endTime).format("HH:mm"),
                    timezone: selectedZone,
                },
                action: updateUser.type,
            })
        );
    };

    return (
        <>
            <div style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}> Working Hours </div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                Set your working hours to receive calls only during the specified time:
                <div style={{ display: "flex", marginBottom: 10 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            disabled={
                                user?.twilioConfig?.twilioNoResponseText?.length === 0 ||
                                !user?.twilioConfig?.twilioNoResponseText === undefined
                            }
                            onChange={(newValue) => setStartTime(newValue)}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <div style={{ marginLeft: 10, marginRight: 10, marginTop: 16 }}> to </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            disabled={
                                user?.twilioConfig?.twilioNoResponseText?.length === 0 ||
                                !user?.twilioConfig?.twilioNoResponseText === undefined
                            }
                            onChange={(newValue) => setEndTime(newValue)}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <FormControl sx={{ minWidth: 120, ml: 2 }}>
                        <InputLabel id="timezone-select-label">Timezone</InputLabel>
                        <Select
                            labelId="timezone-select-label"
                            id="timezone-select"
                            value={selectedZone}
                            disabled={
                                user?.twilioConfig?.twilioNoResponseText?.length === 0 ||
                                !user?.twilioConfig?.twilioNoResponseText === undefined
                            }
                            onChange={handleZoneChange}
                            label="Timezone"
                        >
                            <MenuItem value="UTC">UTC</MenuItem>
                            <MenuItem value="Asia/Kolkata">IST</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginTop: 10, fontSize: 14, marginBottom: 10 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={
                            user?.twilioConfig?.twilioNoResponseText?.length === 0 ||
                            !user?.twilioConfig?.twilioNoResponseText === undefined
                        }
                        style={{ marginLeft: 10, borderRadius: 5 }}
                        onClick={() => handleWorkHours()}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </>
    );
};

export default WorkingHours;