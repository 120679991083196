import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import Interactions from "./Interactions";
import OutreachStats from "./OutreachStats";
import Overview from "./Overview";

import ProjectsDropdown from "../../../../components/Dropdowns/ProjectsDropdown";
import { JoyProvider } from "../../../../components/JoyProvider";
import MembersDropdown from "../../../../components/MembersDropdown";
import { TDate } from "../../../../molecules/DateRangePicker/DateRangePicker";
import { useCachedGetFunnelOverviewQuery } from "../../../../store/apis/analytics/analytics.api";
import { EFunnelOverview } from "../../../../store/apis/analytics/analytics.enums";
import { TFunnelOverview, TFunnelOverviewKey } from "../../../../store/apis/analytics/analytics.types";
import Section from "../../molecules/Section/Section";
import { tooltipSetting } from "../../utils";
import CustomDateRangePicker from "../AnalyticsDateRangePicker";

import { Button } from "@/ui";


export interface FunnelViewProps {
    date: TDate[];
    data?: TFunnelOverview;
    loading?: "true" | "false";
    fetching?: "true" | "false";
    selectedOverview?: { key: TFunnelOverviewKey; value?: number };
    setSelectedOverview: Dispatch<SetStateAction<FunnelViewProps["selectedOverview"]>>;
    filters: {
        projectId: number;
        userId: number;
        startDate?: string;
        endDate?: string;
    };
}

export default function FunnelView({ onOpenGenerateReportModal }: { onOpenGenerateReportModal: () => void }) {
    const { t } = useTranslation();
    const [userId, setUserId] = useState<number>(0);
    const [projectId, setProjectId] = useState<number>(0);
    const [date, setDate] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [selectedOverview, setSelectedOverview] = useState<FunnelViewProps["selectedOverview"]>({
        key: EFunnelOverview.RELEVANT_CANDIDATES,
        value: 0,
    });

    const filters = {
        projectId,
        userId,
        startDate: date[0]?.startOf("day").format(),
        endDate: date[1]?.endOf("day").format(),
    };

    const { data, isLoading, isFetching } = useCachedGetFunnelOverviewQuery(filters, {
        skip: !date[0] || !date[1],
    });

    const props = {
        data,
        date,
        selectedOverview,
        setSelectedOverview,
        filters,
    };

    return (
        <Section
            title="Analytics overview"
            info={t("analytics.analyticsFunnelInfo")}
            loading={`${isLoading}`}
            fetching={`${!isLoading && isFetching}`}
        >
            <Section.Header divider>
                <div className="flex flex-row gap-1 pr-1">
                    <MembersDropdown
                        value={userId}
                        onChange={(userId: number) => {
                            setUserId(userId);
                            setProjectId(0);
                        }}
                    />
                    <ProjectsDropdown userId={userId} projectId={projectId} handleChange={setProjectId} />
                </div>
                <CustomDateRangePicker value={date} onChange={setDate} defaultOption="Last 3 months" />
                {dayjs(filters.startDate).isBefore("2023-12-01") && (
                    <Tooltip
                        title="Caution: Outreach tracking data before November 2023 may not be completely accurate"
                        {...tooltipSetting}
                    >
                        <ReportProblemIcon style={{ margin: "0 8px" }} htmlColor="#FF8C00" />
                    </Tooltip>
                )}
                <JoyProvider>
                    <Button size="sm" className="ml-auto" onClick={onOpenGenerateReportModal}>
                        Generate report
                    </Button>
                </JoyProvider>
            </Section.Header>
            <Overview {...props} />
            <Interactions {...props} />
            <OutreachStats {...props} />
        </Section>
    );
}
