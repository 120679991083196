import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

export interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
    label?: string;
    labelPosition?: "left" | "right";
    color?: "primary" | "secondary" | "error" | "warning" | "info" | "success";
    size?: "sm" | "md" | "lg";
    onChange?: (checked: boolean) => void;
}

const colorClasses = {
    primary: "data-[state=checked]:bg-cyan-600",
    secondary: "data-[state=checked]:bg-purple-500 data-[state=checked]:border-purple-500",
    error: "data-[state=checked]:bg-red-500 data-[state=checked]:border-red-500",
    warning: "data-[state=checked]:bg-yellow-500 data-[state=checked]:border-yellow-500",
    info: "data-[state=checked]:bg-sky-500 data-[state=checked]:border-sky-500",
    success: "data-[state=checked]:bg-green-500 data-[state=checked]:border-green-500",
};

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
    ({ className, label, onChange, labelPosition = "right", color = "primary", size = "md", ...props }, ref) => {
        const checkboxElement = (
            <CheckboxPrimitive.Root
                ref={ref}
                className={cn(
                    "relative flex items-center justify-center",
                    "w-5 h-5 rounded-sm border border-gray-300",
                    "focus:outline-none",
                    "transition-colors duration-200 ease-in-out",
                    "hover:bg-black/[0.04]",
                    "disabled:opacity-50 disabled:cursor-not-allowed",
                    colorClasses[color],
                    className
                )}
                onCheckedChange={onChange}
                {...props}
            >
                <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-white")}>
                    <Check className={cn("w-4 h-4 stroke-3", { "w-3 h-3": size === "sm" })} />
                </CheckboxPrimitive.Indicator>
            </CheckboxPrimitive.Root>
        );

        if (!label) {
            return checkboxElement;
        }

        return (
            <div className="flex items-center space-x-2">
                {labelPosition === "left" && (
                    <label htmlFor={props.id} className="text-sm font-medium text-gray-700 select-none">
                        {label}
                    </label>
                )}
                {checkboxElement}
                {labelPosition === "right" && (
                    <label htmlFor={props.id} className="text-sm font-medium text-gray-700 select-none">
                        {label}
                    </label>
                )}
            </div>
        );
    }
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
