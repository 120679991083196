import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    addCandidatesFromCSVResponse,
    addCandidatesToExclusionList,
    addContactsToProject,
    allCandidatesCSVUpload,
    cancelActions,
    clearAllSelectedCandidates,
    createListFromFilters,
    getContactReachout,
    getSavedFiltersList,
    setContactReachout,
    setSavedFiltersList,
} from "@/store/reducers/all-candidates/all-candidates.slice";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { setModal } from "../reducers/modals.slice";

import {
    AddCandidatesToExclusionListPayload,
    AllCandidatesCSVUploadPayload,
    Candidate,
    CandidateFilterValue,
    FetchCandidateData,
    FetchCandidateDataActivityValues,
    FetchCandidatesResponse,
} from "@/store/reducers/all-candidates/all-candidates.types";

function combineActivityValues(data: FetchCandidateDataActivityValues | undefined): string {
    if (!data || !data.length) {
        return "-";
    }

    return data.reduce((acc: string, { body }, idx) => {
        acc = `
                ${acc}
                ${idx !== 0 ? "<br />" : ""}
                ${body}
            `;

        return acc;
    }, "");
}

function castToContact(data: FetchCandidateData[]): Candidate[] {
    return data.map((i) => {
        const interview = combineActivityValues(i?.Activity?.Interview);
        const call = combineActivityValues(i?.Activity?.Call);
        const conversation = combineActivityValues(i?.Activity?.Conversation);
        const candidateComments = combineActivityValues(i?.Activity?.["Candidate comments"]);
        const notes = combineActivityValues(i?.Activity?.["Notes"]);

        const activities = combineActivityValues(i?.Activity?.Activities);

        const searchStatus = combineActivityValues(i?.Activity?.["Search Status"]);

        const referral = combineActivityValues(i?.Activity?.Referral);

        const nextSteps = combineActivityValues(i?.Activity?.["Next Steps"]);

        const clientComments = combineActivityValues(i?.Activity?.["Client Comments"]);

        const comments = combineActivityValues(i?.Activity?.["Referral/comments"]);

        const stats = combineActivityValues(i?.Activity?.Stats);

        const referralSource = combineActivityValues(i?.Activity?.["Referral Source"]);

        const vettingCourses = combineActivityValues(i?.Activity?.["Vetting Sources"]);

        const referencesSummary = combineActivityValues(i?.Activity?.["References Summary"]);

        return {
            _id: "",
            Projects: "",
            "List of projects": i["Projects"]?.[0]?.name || "-",
            "Project Stage": i["Projects"]?.[0]?.stage || "-",
            "Contact overview": i["Projects"]?.[0]?.detailedStage || "-",
            Interview: interview,
            Call: call,
            Conversation: conversation,
            Notes: notes,
            "Candidate comments": candidateComments,
            Activities: activities,
            "Search Status": searchStatus,
            Referral: referral,
            "Next Steps": nextSteps,
            "Client Comments": clientComments,
            "Referral/comments": comments,
            Stats: stats,
            "References Summary": referencesSummary,
            "Vetting Sources": vettingCourses,
            "Referral Source": referralSource,
            Activity: "",
        };
    });
}

function* allContactsCSVUploadSaga(action: AllCandidatesCSVUploadPayload): SagaIterator {
    try {
        const payload = action.payload;
        yield put(startAction({ action: action.type }));

        const response: FetchCandidatesResponse = yield call(
            new API().post,
            `/v2/project/upload-all-candidates`,
            payload
        );

        if (response?.data?.candidates?.length) {
            yield put(addCandidatesFromCSVResponse(castToContact(response.data.candidates)));

            yield put(setModal({ key: "showAllContactsCSVModal", value: false }));
        } else {
            throw new Error("invalid response");
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addContactsToExclusionListSaga(action: AddCandidatesToExclusionListPayload): SagaIterator {
    try {
        const payload = action.payload;
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().post, `/contact-list/contacts/add-to-opt-out-list`, {
            contactIds: payload,
        });

        if (response?.data) {
            yield put(setSuccessNotification("Contacts successfully added to the exclusion list."));

            yield put(clearAllSelectedCandidates());
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
        yield put(setErrorNotification("An error occurred when trying to add contacts to the exclusion list."));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getContactReachoutSaga({ payload }: { payload: string }): SagaIterator {
    try {
        yield put(startAction({ action: getContactReachout.type }));

        const response = yield call(new API().post, `/contact-list/contact-reachout`, { contactId: payload });

        if (!response?.data) return;

        yield put(setContactReachout(response.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getContactReachoutSaga", error);
    } finally {
        yield put(stopAction({ action: getContactReachout.type }));
    }
}

function* createListFromFiltersSaga(
    action: PayloadAction<{
        projectName: string;
        filters: CandidateFilterValue;
        onSuccess: () => void;
    }>
): SagaIterator {
    try {
        const { projectName, filters, onSuccess } = action.payload;
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/candidate/save-filters", {
            projectName,
            filters,
        });
        onSuccess();
        yield put(setSuccessNotification("Filters were saved successfully."));
    } catch (error) {
        handleException(error);
        console.error("***error in getContactReachoutSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getSavedFiltersListSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "/candidate/get-saved-filters");
        yield put(setSavedFiltersList(response.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getContactReachoutSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addContactsToProjectSaga(
    action: PayloadAction<{
        projectId: string;
        candidateIds: string[];
        selectAll: boolean;
        projectName?: string;
        onSuccess?: () => void;
    }>
): SagaIterator {
    try {
        yield put(startAction({ action: addContactsToProject.type }));
        const { payload } = action;
        const state = yield select();
        const filtersRaw = get(state, "allCandidates.filters.values");
        const filters = Object.entries(filtersRaw).reduce(
            (acc, [filterKey, filterValue]: [string, any]): Record<string, string[]> => {
                if (Array.isArray(filterValue?.value) && filterValue.value.length) {
                    acc[filterKey] = filterValue.value.map(({ value }) => value);
                }
                return acc;
            },
            {}
        );

        const searchQuery = get(state, "allCandidates.tableState.searchQuery");
        const sorting = get(state, "allCandidates.sorting");
        const columnFilter = get(state, "allCandidates.columnFilter");
        const pagination = get(state, "allCandidates.pagination");

        const body = {
            ...payload,
            start: pagination?.pageIndex,
            limit: pagination?.pageSize,
            filters,
            search: searchQuery,
            sortBy: sorting,
            filterByColumn: columnFilter,
        };

        const response = yield call(new API().post, `/candidate/add-to-project`, body);
        if (response.data) {
            yield put(setSuccessNotification(response.message));
            action.payload.onSuccess?.();
        }
    } catch (error) {
        handleException(error);
        console.error("***addContactsToProjectSaga err=", error);
    } finally {
        yield put(stopAction({ action: addContactsToProject.type }));
    }
}

export default function* rootSagas() {
    // @ts-ignore
    const tasks = [
        // @ts-ignore
        yield takeLatest(getSavedFiltersList.type, getSavedFiltersListSaga),
        // @ts-ignore
        yield takeLatest(createListFromFilters.type, createListFromFiltersSaga),
        // @ts-ignore
        yield takeLatest(allCandidatesCSVUpload.type, allContactsCSVUploadSaga),
        // @ts-ignore
        yield takeLatest(addCandidatesToExclusionList.type, addContactsToExclusionListSaga),
        // @ts-ignore
        yield takeLatest(getContactReachout.type, getContactReachoutSaga),
        // @ts-ignore
        yield takeLatest(addContactsToProject.type, addContactsToProjectSaga),
    ];

    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
