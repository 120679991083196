import { Stack } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { Box, Experimental_CssVarsProvider, Tab, Tabs } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AnalyticsReportTemplate from "./AnalyticsReportTemplate";

import { JoyProvider } from "../../../../components/JoyProvider";
import MembersDropdown from "../../../../components/MembersDropdown";
import { TDate } from "../../../../molecules/DateRangePicker/DateRangePicker";
import {
    useGetCreditUsageQuery,
    useGetFunnelOverviewQuery,
    useGetGraphQuery,
    useGetMultipleUserFunnelQuery,
    useGetOutreachStatsQuery,
    useGetProjectsStatusQuery,
} from "../../../../store/apis/analytics/analytics.api";
import { CustomTabPanel } from "../../../project/components/upcomingEvents/UpcomingEvents";
import { checkEasySource, selectUser } from "../../../Signin/Signin.reducer";
import CustomDateRangePicker from "../AnalyticsDateRangePicker";
// import MembersDropdown from "../MembersDropdown";
import MultiSelectComponent from "../MultiSelect";
import ProjectsDropdown from "../ProjectsDropdown";

import { Button } from "@/ui"

function transformObjectToArray(obj: any) {
    const label: string[] = [];
    const data: number[] = [];

    Object.keys(obj).forEach(function (key, _index) {
        label.push(key);
        // @ts-ignore
        data.push(obj[key]);
    });
    return {
        label,
        data,
    };
}

function transformGraphData(obj: any) {
    const label: string[] = [];
    const relevantData: number[] = [];
    const extractedData: number[] = [];

    for (const key in obj) {
        label.push(dayjs(key).format("MMM D, YYYY"));
        const element = obj?.[key];
        relevantData.push(element.qualifiedCandidates || 0);
        extractedData.push(element.sourcedCandidates || 0);
    }

    return { label, extractedData, relevantData };
}

function transformFunnelData(obj: any) {
    const order = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses", "meetingsBooked"];

    const label: string[] = [];
    const data: number[] = [];

    for (let i = 0; i < order.length; ++i) {
        const key = order[i];
        label.push(key);
        data.push(obj[key]);
    }

    return {
        label,
        data,
    };
}

function transformMultipleUserFunnelData(arr: any[]) {
    const labelMapping = {
        relevantCandidates: "Relevant Candidates",
        totalContacted: "Total Contacted",
        responsesReceived: "Responses Received",
        positiveResponses: "Positive Responses",
    };

    const defaultChartColors = ["#00B7C7", "#EBDC78", "#0D88E6", "#7C1158", "#4421AF", "#5AD45A", "#8BE04E", "#EBDC78"];

    const transformedData: { label: string; data: any[]; backgroundColor: string }[] = [];

    // Extract display labels from the mapping object
    const displayLabels = Object.values(labelMapping);

    if (!arr || arr.length === 0 || !arr[0]?.data?.counts) {
        return {
            labels: displayLabels,
            datasets: [],
        };
    } else {
        arr.forEach((item) => {
            const data = item?.data?.counts;
            const user = item?.userName;
            const userLabel = user ? user : "User";
            const userLabelData = [];

            // Use the keys of the labelMapping object to get the data
            for (const key in labelMapping) {
                userLabelData.push(data[key]);
            }

            transformedData.push({
                label: userLabel,
                data: userLabelData,
                backgroundColor: defaultChartColors[transformedData.length % defaultChartColors.length],
            });
        });
        return {
            labels: displayLabels,
            datasets: transformedData,
        };
    }
}

function transformChannelSplitData(obj: any) {
    const order = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses", "meetingsBooked"];

    let linkedin: number[] = [];
    let email: number[] = [];
    let sms: number[] = [];
    for (let i = 0; i < order.length; ++i) {
        const data = obj[order[i]];
        const _linkedin = data?.find((elem: any) => elem?._id === "linkedin")?.count || 0;
        const _email = data?.find((elem: any) => elem?._id === "email")?.count || 0;
        const _sms = data?.find((elem: any) => elem?._id === "sms")?.count || 0;
        linkedin.push(_linkedin);
        email.push(_email);
        sms.push(_sms);
    }

    const sumArr = [0, 0, 0, 0, 0];
    linkedin.forEach((item, index) => {
        sumArr[index] += item;
    });
    email.forEach((item, index) => {
        sumArr[index] += item;
    });
    sms.forEach((item, index) => {
        sumArr[index] += item;
    });
    linkedin = linkedin.map((item, index) => {
        const sum = sumArr[index];
        if (sum === 0) return item;
        return (item / sum) * 100;
    });

    email = email.map((item, index) => {
        const sum = sumArr[index];
        if (sum === 0) return item;
        return (item / sum) * 100;
    });
    sms = sms.map((item, index) => {
        const sum = sumArr[index];
        if (sum === 0) return item;
        return (item / sum) * 100;
    });

    return {
        label: order,
        data: {
            linkedin,
            email,
            sms,
        },
    };
}

function transformSourceSplitData(obj: any, platformName: string) {
    const order = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses", "meetingsBooked"];
    const transformedData: any = {};
    for (let i = 0; i < order.length; ++i) {
        const key = order[i];
        obj?.[key]?.forEach((item: any) => {
            let sourceKey = item?._id;
            if (!sourceKey) {
                sourceKey = platformName;
            }
            transformedData[sourceKey] = [0, 0, 0, 0, 0];
        });
    }

    const processCategory = (category: any, index: number) => {
        obj?.[category]?.forEach((item: any) => {
            let sourceKey = item?._id;
            if (!sourceKey) {
                sourceKey = platformName;
            }
            transformedData[sourceKey][index] = transformedData[sourceKey][index] + item?.count;
        });
    };
    for (let i = 0; i < order.length; ++i) {
        const key = order[i];
        processCategory(key, i);
    }
    const sumArr = [0, 0, 0, 0, 0];
    Object.keys(transformedData).forEach((key) => {
        transformedData?.[key].forEach((value: number, index: number) => {
            sumArr[index] += value;
        });
    });
    Object.keys(transformedData).forEach((key) => {
        transformedData?.[key].forEach((value: number, index: number) => {
            if (sumArr[index] !== 0) {
                const newValue = (value / sumArr[index]) * 100;
                transformedData[key][index] = newValue;
            }
        });
    });

    return {
        label: order,
        data: transformedData,
    };
}

const GenerateReportModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    const [userIdFunnel, setUserIdFunnel] = useState<number>(0);
    const [userNameFunnel, setUserNameFunnel] = useState<string>(t("analytics.memberListDropdownLabel"));
    const [userIdStats, setUserIdStats] = useState<number>(0);
    const [userNameStats, setUserNameStats] = useState<string>(t("analytics.memberListDropdownLabel"));
    const [projectIdFunnel, setProjectIdFunnel] = useState<number>(0);
    const [projectNameFunnel, setProjectNameFunnel] = useState<string>(t("analytics.projectListDropdownLabel"));
    const [projectIdStats, setProjectIdStats] = useState<number>(0);
    const [projectNameStats, setProjectNameStats] = useState<string>(t("analytics.projectListDropdownLabel"));
    const [dateFunnel, setDateFunnel] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [dateStats, setDateStats] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [dateMultiUserFunnel, setDateMultiUserFunnel] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [downloading, setDownloading] = useState(false);
    const [userProjects, setUserProjects] = useState([]);

    const user = useSelector(selectUser);
    const isEasySource = useSelector(checkEasySource);
    const platformName = isEasySource ? "EasySource" : "EasyGrowth";

    const dateFunnelText = `${dateFunnel[0]?.format("MMM D, YYYY") ?? "--"} - ${dateFunnel[1]?.format("MMM D, YYYY") ?? "--"
        }`;
    const dateStatsText = `${dateStats[0]?.format("MMM D, YYYY") ?? "--"} - ${dateStats[1]?.format("MMM D, YYYY") ?? "--"
        }`;

    const pdfLink = useRef<HTMLAnchorElement | null>(null);
    const [activeTab, setActiveTab] = useState(0);

    const filtersFunnels = {
        projectId: projectIdFunnel,
        userId: userIdFunnel,
        startDate: dateFunnel[0]?.format(),
        endDate: dateFunnel[1]?.format(),
    };

    const filterStats = {
        userId: userIdStats,
        projectId: projectIdStats,
        startDate: dateStats[0]?.format(),
        endDate: dateStats[1]?.format(),
    };

    const multiUserFilters = {
        userProjects,
        startDate: dateMultiUserFunnel[0]?.format(),
        endDate: dateMultiUserFunnel[1]?.format(),
    };

    const { data: funnelData, isFetching: fetchingFunnelData } = useGetFunnelOverviewQuery(filtersFunnels, {
        skip: !dateFunnel[0] || !dateFunnel[1],
    });

    const { data: multiUsersFunnelData, isFetching: fetchingMultipleUsersFunnelData } = useGetMultipleUserFunnelQuery(
        multiUserFilters,
        {
            skip: !dateMultiUserFunnel[0] || !dateMultiUserFunnel[1],
        }
    );

    const { data: outreachData, isFetching: fetchingOutreach } = useGetOutreachStatsQuery(filtersFunnels, {
        skip: !dateFunnel[0] || !dateFunnel[1],
    });

    const { data: projectsData, isFetching: fetchingProjects } = useGetProjectsStatusQuery(filterStats, {
        skip: !dateStats[0] || !dateStats[1],
    });

    const { data: creditUsageData, isFetching: fetchingCreditUsage } = useGetCreditUsageQuery(filterStats, {
        skip: !dateStats[0] || !dateStats[1],
    });

    const { data: graphData, isFetching: fetchingGraph } = useGetGraphQuery(filterStats, {
        skip: !dateStats[0] || !dateStats[1],
    });

    const onPdfDownload = () => {
        setDownloading(true);
        setTimeout(() => {
            // @ts-ignore
            if (pdfLink.current) {
                pdfLink.current.click();
            }
            setDownloading(false);
        }, 10000);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const loading =
        fetchingFunnelData ||
        fetchingProjects ||
        fetchingCreditUsage ||
        fetchingGraph ||
        fetchingOutreach ||
        fetchingMultipleUsersFunnelData;
    const disabled = loading || downloading;

    return (
        <JoyProvider>
            <Modal open={isOpen || downloading} onClose={onClose}>
                <ModalDialog
                    maxWidth={"550px"}
                    size="md"
                    variant="plain"
                    sx={{ gap: 0, margin: 0, padding: 0, width: "90%" }}
                >
                    <ModalClose />

                    <Box
                        sx={{
                            borderTopLeftRadius: "1rem",
                            borderTopRightRadius: "1rem",
                            paddingY: 2,
                            paddingX: 1,
                            background: "white",
                            borderBottom: "1px solid #E0E3E7",
                        }}
                    >
                        <Typography level="body-md" sx={{fontWeight:"600", fontSize:"17px"}} >Generate Analytics Report</Typography>
                    </Box>
                    <Box sx={{ backgroundColor: "white" }}>
                        <>
                            <Tabs
                                sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "white" }}
                                value={activeTab}
                                onChange={handleTabChange}
                            >
                                <Tab
                                    value={0}
                                    label={"Member Wise Funnel Filter"}
                                    sx={(theme) => ({
                                        color: activeTab === 0 ? "#0891b2" : "inherit",
                                        fontSize: theme.typography.body2,
                                        borderBottom: "2px solid",
                                        borderColor: activeTab === 0 ? "#0891b2" : "transparent",
                                    })}
                                />
                                <Tab
                                    value={1}
                                    label={"Funnel Filter"}
                                    sx={(theme) => ({
                                        color: activeTab === 1 ? "#0891b2" : "inherit",
                                        fontSize: theme.typography.body2,
                                        borderBottom: "2px solid",
                                        borderColor: activeTab === 1 ? "#0891b2" : "transparent",
                                    })}
                                />
                                <Tab
                                    value={2}
                                    label={"Statistics Filter"}
                                    sx={(theme) => ({
                                        color: activeTab === 2 ? "#0891b2" : "inherit",
                                        fontSize: theme.typography.body2,
                                        borderBottom: "2px solid",
                                        borderColor: activeTab === 2 ? "#0891b2" : "transparent",
                                    })}
                                />
                            </Tabs>
                            <Box sx={{ paddingY: 2, paddingX: 1, backgroundColor: "white" }}>
                                <CustomTabPanel value={activeTab} index={0}>
                                    <Experimental_CssVarsProvider>
                                        <Stack flexDirection={"column"} gap={0.5} sx={{ backgroundColor: "white" }}>
                                            <Stack direction={"row"} alignItems={"center"} gap={2}>
                                                <Typography level="title-lg" width={"150px"}>
                                                    Select Members
                                                </Typography>
                                                <MultiSelectComponent
                                                    userProjects={userProjects}
                                                    setUserProjects={setUserProjects}
                                                />
                                            </Stack>
                                            <Stack direction={"row"} alignItems={"center"} gap={2}>
                                                <Typography level="title-lg" width={"150px"}>
                                                    Select Date
                                                </Typography>
                                                <CustomDateRangePicker
                                                    disabled={downloading}
                                                    hideLabel
                                                    value={dateMultiUserFunnel}
                                                    onChange={setDateMultiUserFunnel}
                                                    defaultOption="Last 3 months"
                                                />
                                            </Stack>
                                        </Stack>
                                    </Experimental_CssVarsProvider>
                                </CustomTabPanel>
                                <CustomTabPanel value={activeTab} index={1}>
                                    <Experimental_CssVarsProvider>
                                        <Stack flexDirection={"column"} gap={0.5}>
                                            {user?.roleType === "ADMIN" && (
                                                <Stack direction={"row"} alignItems={"center"} gap={3}>
                                                    <Typography level="title-lg" width={"150px"}>
                                                        Select Member
                                                    </Typography>
                                                    {/* <MembersDropdown
                                                        disabled={downloading}
                                                        value={userIdFunnel}
                                                        onChange={(userId: number) => {
                                                            setUserIdFunnel(userId);
                                                            setProjectIdFunnel(0);
                                                        }}
                                                        callback={(userName) => {
                                                            setUserNameFunnel(userName);
                                                        }}
                                                    /> */}
                                                    <MembersDropdown value={userIdFunnel} onChange={setUserIdFunnel} />
                                                </Stack>
                                            )}
                                            <Stack direction={"row"} alignItems={"center"} gap={2}>
                                                <Typography level="title-lg" width={"150px"}>
                                                    Select Project
                                                </Typography>
                                                <ProjectsDropdown
                                                    disabled={downloading}
                                                    userId={userIdFunnel}
                                                    value={projectIdFunnel}
                                                    onChange={setProjectIdFunnel}
                                                    callback={(value) => setProjectNameFunnel(value)}
                                                />
                                            </Stack>
                                            <Stack direction={"row"} alignItems={"center"} gap={3}>
                                                <Typography level="title-lg" width={"150px"}>
                                                    Select Date
                                                </Typography>
                                                <CustomDateRangePicker
                                                    disabled={downloading}
                                                    hideLabel
                                                    value={dateFunnel}
                                                    onChange={setDateFunnel}
                                                    defaultOption="Last 3 months"
                                                />
                                            </Stack>
                                        </Stack>
                                    </Experimental_CssVarsProvider>
                                </CustomTabPanel>
                                <CustomTabPanel value={activeTab} index={2}>
                                    <Experimental_CssVarsProvider>
                                        <Stack flexDirection={"column"} gap={0.5}>
                                            {user?.roleType === "ADMIN" && (
                                                <Stack direction={"row"} alignItems={"center"} gap={3}>
                                                    <Typography level="title-lg" width={"150px"}>
                                                        Select Member
                                                    </Typography>
                                                    {/* <MembersDropdown
                                                        disabled={downloading}
                                                        value={userIdStats}
                                                        onChange={(userId: number) => {
                                                            setUserIdStats(userId);
                                                            setProjectIdStats(0);
                                                        }}
                                                        callback={(userName) => {
                                                            setUserNameStats(userName);
                                                        }}
                                                    /> */}
                                                    <MembersDropdown value={userIdStats} onChange={setUserIdStats} />
                                                </Stack>
                                            )}
                                            <Stack direction={"row"} alignItems={"center"} gap={2}>
                                                <Typography level="title-sm" width={"150px"}>
                                                    Select Project
                                                </Typography>
                                                <ProjectsDropdown
                                                    disabled={downloading}
                                                    userId={userIdStats}
                                                    value={projectIdStats}
                                                    onChange={setProjectIdStats}
                                                    callback={(value) => setProjectNameStats(value)}
                                                />
                                            </Stack>
                                            <Stack direction={"row"} alignItems={"center"} gap={3}>
                                                <Typography level="title-sm" width={"150px"}>
                                                    Select Date
                                                </Typography>
                                                <CustomDateRangePicker
                                                    disabled={downloading}
                                                    hideLabel
                                                    value={dateStats}
                                                    onChange={setDateStats}
                                                    defaultOption="Last 3 months"
                                                />
                                            </Stack>
                                        </Stack>
                                    </Experimental_CssVarsProvider>
                                </CustomTabPanel>
                            </Box>
                        </>
                    </Box>
                    <Box
                        sx={{
                            borderBottomLeftRadius: "1rem",
                            borderBottomRightRadius: "1rem",
                            paddingY: 1.5,
                            paddingX: 1,
                            background: "white",
                            borderTop: "1px solid #E0E3E7",
                        }}
                    >
                        <Stack flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"flex-end"}>
                            <Button variant="outline" size="sm" disabled={disabled} onClick={onPdfDownload} label={"Cancel"} />
                            <Button
                                size="sm"
                                variant="default"
                                disabled={disabled}
                                onClick={onPdfDownload}
                                label={downloading ? "Downloading" : loading ? "Loading" : "Download report"}
                            />
                        </Stack>
                    </Box>
                </ModalDialog>
            </Modal>

            <BlobProvider
                document={
                    <AnalyticsReportTemplate
                        analyticsMemberFilter={userNameFunnel}
                        analyticsProjectFilter={projectNameFunnel}
                        analyticsDateFilter={dateFunnelText}
                        usageStatsMemberFilter={userNameStats}
                        usageStatsProjectFilter={projectNameStats}
                        usageStagsDateFilter={dateStatsText}
                        multiUserFunnelData={transformMultipleUserFunnelData(
                            multiUsersFunnelData || { labels: [], datasets: [] }
                        )}
                        funnelData={transformFunnelData(funnelData?.counts || {})}
                        funnelSplitDataChannel={transformChannelSplitData(funnelData?.graphs.channelGraph || {})}
                        funnelSplitDataSource={transformSourceSplitData(
                            funnelData?.graphs.sourceGraph || {},
                            platformName
                        )}
                        outreachData={{
                            linkedin: transformObjectToArray(outreachData?.linkedin || {}),
                            email: transformObjectToArray(outreachData?.email || {}),
                            sms: transformObjectToArray(outreachData?.sms || {}),
                        }}
                        usageStats={{
                            projectStatus: transformObjectToArray(projectsData || {}),
                            creditUsage: transformObjectToArray(creditUsageData || {}),
                        }}
                        graphData={transformGraphData(graphData?.chartLabel || {})}
                        isEasySource={isEasySource}
                    />
                }
            >
                {({ url }) => {
                    if (!url) return null;
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            ref={pdfLink}
                            href={url}
                            style={{ display: "none" }}
                            download={`AnalyticsReport_${user.name}_${new Date().toLocaleDateString("en-US")}`}
                            target="_blank"
                            rel="noreferrer"
                        />
                    );
                }}
            </BlobProvider>
        </JoyProvider>
    );
};

export default GenerateReportModal;
