import { TScrapeStatus } from "./autopilot.types";

export enum QUERIES {
    AUTO_PILOT_STATS = "auto_pilot_stats",
    AUTO_PILOT_PREVOIUS_STATS = "auto_pilot_previous_stats",
    AUTO_PILOT_SETTINGS = "auto_pilot_settings",
}

export enum SET_INDEX {
    "SET-ONE" = 1,
    "SET-TWO" = 2,
}

export const STATUS_COLOR: { [key in TScrapeStatus] } = {
    PENDING: "#f0f4f8",
    IN_PROGRESS: "#fdf0e1",
    COMPLETED: "#e4fbe3",
    CANCELLED: "#fce4e5",
    PAUSED: "#fdf0e1",
} as const;
