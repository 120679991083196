import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    FetchEventsPayload,
    cancelActions,
    fetchEvents,
    setEvents,
} from "@/store/reducers/upcoming-events/upComingEvents.slice";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { setModal } from "../reducers/modals.slice";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";

function* fetchEventsSaga(action: PayloadAction<FetchEventsPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const payload = action.payload;
        const { id } = payload;
        let url = "/calendly/getCalendlyMeetings";
        if (id) {
            url += `?projectId=${id}`;
        }

        const response = yield call(new API().get, url);
        if (response.success === true) {
            yield put(setEvents(response.data));
        }
    } catch (error) {
        handleException(error);
        console.error("**error while fetching events", { error });
        yield put(setModal({ key: "showUpcomingEventsModal", value: false }));
        yield put(setErrorNotification("Error while fetching events. Please try again later."));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchEvents.type, fetchEventsSaga),
    ];
    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
