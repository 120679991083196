import * as React from "react";

import { cn } from "@/lib/utils";

export interface SeparatorProps extends React.HTMLAttributes<HTMLDivElement> {
    orientation?: "horizontal" | "vertical";
    variant?: "fullWidth" | "inset" | "middle";
    textAlign?: "left" | "center" | "right";
    children?: React.ReactNode;
    flexItem?: boolean;
    light?: boolean;
}

const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
    (
        {
            className,
            orientation = "horizontal",
            variant = "fullWidth",
            textAlign = "center",
            children,
            flexItem = false,
            light = false,
            ...props
        },
        ref
    ) => {
        const rootClassName = cn(
            "relative flex items-center",
            orientation === "horizontal" ? "w-full" : "h-auto",
            variant === "inset" ? "ml-16" : "",
            variant === "middle" && !children ? "w-1/3 mx-auto" : "",
            flexItem && orientation === "vertical" ? "self-stretch" : "",
            children ? "justify-center" : "",
            className
        );

        const lineClassName = cn(
            "flex-grow shrink-0",
            orientation === "horizontal" ? "h-[1px] w-full" : "w-[1px] h-full",
            light ? "bg-gray-200" : "bg-gray-300"
        );

        const contentClassName = cn(
            "absolute flex px-2 bg-white",
            orientation === "horizontal" ? "top-1/2 -translate-y-1/2" : "left-1/2 -translate-x-1/2 flex-col",
            textAlign === "left" ? "left-0" : "",
            textAlign === "center" ? "left-1/2 -translate-x-1/2" : "",
            textAlign === "right" ? "right-0" : ""
        );

        return (
            <div ref={ref} className={rootClassName} {...props}>
                {children ? (
                    <>
                        <div className={lineClassName} />
                        <span className={contentClassName}>{children}</span>
                        <div className={lineClassName} />
                    </>
                ) : (
                    <div className={lineClassName} />
                )}
            </div>
        );
    }
);

Separator.displayName = "Separator";

export { Separator };
