import { PayloadAction } from "@reduxjs/toolkit";
import {
    confirmPasswordReset,
    fetchSignInMethodsForEmail,
    getAuth,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
} from "firebase/auth";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { apiBase } from "../../utils/API";
import { isValidEmail } from "../../utils/helper";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { checkIfUserExist, confirmReset, resetState } from "../reducers/forgotPassword.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";

async function checkUserExists(email: string) {
    const auth = getAuth();
    try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        return signInMethods.length > 0;
    } catch (error) {
        console.error("Error checking user existence:", error);
        throw error;
    }
}

async function sendPasswordResetEmailToUser(email: string, isEasySource: boolean) {
    const auth = getAuth();
    try {
        await sendPasswordResetEmail(auth, email, {
            url: isEasySource
                ? `https://easysource-uat.hirequotient.com/signin`
                : `https://easygrowth.hirequotient.com/signin`,
            handleCodeInApp: true,
        });
    } catch (error) {
        console.error("Error checking user existence:", error);
        throw error;
    }
}

async function resetPassword(oobCode: string, newPassword: string) {
    const auth = getAuth();
    try {
        await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
    }
}

async function getPasswordResetEmail(oobCode: string) {
    const auth = getAuth();
    try {
        const email = await verifyPasswordResetCode(auth, oobCode);
        return email;
    } catch (error) {
        console.error("Error while extracting email:", error);
        throw error;
    }
}

async function sendHealthCheckReport(email: string, password: string) {
    try {
        const response = await fetch(`${apiBase}/auth/health-check`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error:", error);
    }
}

function* checkIfUserExistSaga(action: PayloadAction<{ email: string; onSuccess: () => void }>): SagaIterator {
    const { email, onSuccess } = action.payload;
    try {
        yield put(startAction({ action: action.type }));
        if (!isValidEmail(email)) {
            yield put(setErrorNotification("Please enter a valid email"));
            return;
        }
        const userExists = yield call(checkUserExists, email);
        if (userExists) {
            const state = yield select();
            const isEasySource = get(state, "signin.isEasySource");
            yield call(sendPasswordResetEmailToUser, email, isEasySource);
            if (onSuccess) {
                onSuccess();
            }
            yield put(setSuccessNotification("A password reset email has been sent to your email"));
        }
        if (!userExists) {
            yield put(setErrorNotification("User does not exist"));
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Some error occured while fetching data"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* confirmResetSaga(action: PayloadAction<{ oobCode: string; newPassword: string }>): SagaIterator {
    const { oobCode, newPassword } = action.payload;

    try {
        yield put(startAction({ action: action.type }));
        if (!oobCode) {
            yield put(setErrorNotification("Invalid code"));
            return;
        }

        const email = yield call(getPasswordResetEmail, oobCode);
        const response = yield call(sendHealthCheckReport, email, newPassword);
        if (!response.success) {
            // we are doing this because we don't want that user reset password and we don't get the email
            yield put(setErrorNotification("Some error occured while resetting password"));
            return;
        }
        yield call(resetPassword, oobCode, newPassword);
        yield put(setSuccessNotification("Password reset successfully"));
        const urlSearchParams = new URLSearchParams(window.location.search);
        const continueUrl = urlSearchParams.get("continueUrl");
        if (continueUrl) {
            window.location.href = continueUrl;
        }
    } catch (error) {
        handleException(error);
        console.log(error);
        yield put(setErrorNotification("Some error occured while resetting password"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(checkIfUserExist.type, checkIfUserExistSaga),
        // @ts-ignore
        yield takeLatest(confirmReset.type, confirmResetSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetState.type, CancelSagas, tasks);
}
