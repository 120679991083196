import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Typography from "@mui/joy/Typography";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";

import ClubbableBarChart from "./charts/ClubbableBarChart";

import { TDate } from "../../../../molecules/DateRangePicker/DateRangePicker";
import { useCachedGetMultipleUserFunnelQuery } from "../../../../store/apis/analytics/analytics.api";
import { checkMemberFunnel, selectUser } from "../../../Signin/Signin.reducer";
import Section from "../../molecules/Section/Section";
import { tooltipSetting } from "../../utils";
import CustomDateRangePicker from "../AnalyticsDateRangePicker";
import MultiSelectComponent from "../MultiSelect";

import { Button } from "@/ui";

export interface MultipleInteractionViewProps {
    date: TDate[];
    data?: any;
    loading?: "true" | "false";
    fetching?: "true" | "false";
    selectedOverview?: { key: any; value?: number };
    setSelectedOverview: any;
    filters: {
        userProjects: any;
        startDate?: string;
        endDate?: string;
    };
}

export default function MultipleInteractionView() {
    const [date, setDate] = useState<TDate[]>([dayjs().subtract(3, "months").startOf("day"), dayjs().startOf("day")]);
    const [userProjects, setUserProjects] = useState([]);
    const [includeCandidatesFromATS, setIncludeCandidatesFromATS] = useState<boolean>(false);
    const showMemberFunnel = useSelector(checkMemberFunnel);
    const user = useSelector(selectUser);

    const [filters, setFilters] = useState<any>({
        userProjects: [
            {
                userId: 0,
                projectIds: [0],
            },
        ],
        startDate: date[0]?.format(),
        endDate: date[1]?.format(),
        includeCandidatesFromATS,
    });

    const handleGenerateGraph = () => {
        if (userProjects.length === 0) {
            return;
        }

        setFilters({
            userProjects,
            startDate: date[0]?.startOf("day").format(),
            endDate: date[1]?.startOf("day").format(),
            includeCandidatesFromATS,
        });
    };

    const { data, isFetching, isLoading } = useCachedGetMultipleUserFunnelQuery(filters, {
        skip: !date[0] || !date[1],
    });

    if (user.roleType !== "ADMIN" || !showMemberFunnel) return null;

    return (
        <Section
            title="Member-wise funnel view"
            loading={`${isLoading}`}
            fetching={`${!isLoading && isFetching}`}
            minHeight={650}
        >
            <Section.Header divider>
                <MultiSelectComponent userProjects={userProjects} setUserProjects={setUserProjects} />
                <CustomDateRangePicker value={date} onChange={setDate} defaultOption="Last 3 months" />
                {dayjs(filters.startDate).isBefore("2023-12-01") && (
                    <Tooltip
                        title="Caution: Outreach tracking data before November 2023 may not be completely accurate"
                        {...tooltipSetting}
                    >
                        <ReportProblemIcon style={{ margin: "0 8px" }} htmlColor="#FF8C00" />
                    </Tooltip>
                )}
                <Switch
                    checked={includeCandidatesFromATS}
                    onChange={(e) => setIncludeCandidatesFromATS(e.target.checked)}
                />
                <Typography level="title-sm" fontSize={12}>
                    {includeCandidatesFromATS ? "ATS candidates Included" : "Toggle on to include ATS candidates"}
                </Typography>

                <Button size="sm" className="ml-auto" onClick={handleGenerateGraph} loading={isFetching}>
                    Generate graph
                </Button>
            </Section.Header>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <ClubbableBarChart data={data} isFetching={isFetching} isLoading={isLoading} />
            </div>
        </Section>
    );
}
