// import React from "react";

// import { Modal } from "@/ui"; // Your custom Modal component

// type Props = {
//     onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
//     children: React.ReactNode;
//     overlayStyles?: string; // Tailwind classes for custom styles
//     backdrop?: boolean; // New prop to control backdrop click behavior
// };

// export default function BaseModal(props: Props) {
//     const { children, overlayStyles, onClose, backdrop = false } = props;

//     return (
//         <Modal
//             open={true}
//             className="overflow-y-auto"
//             onClose={() => {
//                 if (!backdrop) {
//                     onClose();
//                 }
//             }}
//         >
//             {/* Scrollable Content */}
//             <div className="overflow-y-auto h-full p-4">{children}</div>
//         </Modal>
//     );
// }

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack, { StackProps } from "@mui/material/Stack";

const styles: StackProps["sx"] = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
    boxShadow: 24,
    borderRadius: 2,
    border: "none",
    outline: 0,
    maxHeight: "90vh",
};

type Props = {
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: React.ReactNode;
    overlayStyles?: StackProps["sx"];
    hideCloseButton?: boolean;
    backdrop?: boolean; // New prop to control backdrop click behavior
};

export default function BaseModal(props: Props) {
    const { children, overlayStyles, onClose, hideCloseButton, backdrop = false } = props;
    return (
        <Modal
            open={true}
            onClose={(_, reason) => {
                if (!backdrop) {
                    onClose();
                }
            }}
        >
            <Stack sx={(theme) => ({ ...styles, ...overlayStyles })}>
                {children}
                {!hideCloseButton && (
                    <IconButton
                        onClick={onClose}
                        size="small"
                        sx={{
                            top: 8,
                            right: 8,
                            background: "white",
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{
                            position: "absolute",
                            marginTop: 0,
                            zIndex: 100,
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                )}
            </Stack>
        </Modal>
    );
}