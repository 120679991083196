import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import styles from "../CompanyPage.module.css";

import { selectCompanyVideo, setCompanyVideo, setCompanyVideoLink, updateCompanyPage } from "@/store/reducers/companyPage/CompanyPage.reducer";


const UploadVideo = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();

    const companyVideo: any = useSelector(selectCompanyVideo);
    const isProjectUpdating = useSelector(checkIfLoading(updateCompanyPage.type));

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                <Typography className={styles.uploadTitle}>Edit video</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "40px" }}>
                <Stack>
                    <Typography sx={{ fontSize: "12px" }}>Supported format: .mp4</Typography>
                    <TextField
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(setCompanyVideo(e.target.files))}
                    />
                    <Typography my="10px" alignSelf="center">
                        OR
                    </Typography>

                    <TextField
                        placeholder="Paste your youtube video link"
                        sx={{ marginBottom: "20px" }}
                        onChange={(e) => dispatch(setCompanyVideoLink(e.target.value))}
                    />
                    <Stack flexDirection="row" justifyContent="flex-end" mt="30px">
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                                marginRight: "10px",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isProjectUpdating}
                            onClick={() => {
                                dispatch(
                                    updateCompanyPage({
                                        fileType: companyVideo?.size ? "videoFile" : "videoLink",
                                    })
                                );
                                onClose();
                            }}
                            variant="contained"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                            }}
                        >
                            {isProjectUpdating ? "Submiting...." : "Submit"}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default UploadVideo;
