import { noop } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    TFilters,
    TFiltersState,
    TFiltersStateGlobal,
} from "../../../store/apis/linkedin-workflow/linkedin-workflow.types";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";

export const defaultFilters = {
    projectId: 0,
    userId: 0,

    setProjectId: noop,
    setUserId: noop,
};

export default function useFilters(callback: (filters: TFilters) => void, delay: number = 500): TFiltersStateGlobal {
    const user = useSelector(selectUser);
    const [filters, setFilters] = useState<TFiltersState>(defaultFilters);

    useEffect(() => {
        if (user._id) {
            setFilters((ps) => ({ ...ps, userId: user._id }));
        }
    }, [user._id]);

    const setProjectId = (projectId: TFiltersState["projectId"]) => {
        setFilters((prevState) => ({ ...prevState, projectId, page: 0 }));
    };

    const setUserId = (userId: TFiltersState["userId"]) => {
        setFilters((prevState) => ({ ...prevState, userId, page: 0 }));
    };

    return {
        ...defaultFilters,
        ...filters,
        // setters

        setProjectId,
        setUserId,
        // applyFilters,
    };
}
