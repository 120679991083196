import CachedIcon from "@mui/icons-material/Cached";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Edit from "@mui/icons-material/Edit";
import { Box } from "@mui/joy";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { get } from "lodash";
import { Suspense, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import style from "./candidates.module.scss";

import EasyGrowthLogo from "../../../../assets/img/easy-growth-logo.svg";
import RB2BLogo from "../../../../assets/img/email-providers-logo/rb2b.png";
import { CustomDropdownOptionsType } from "../../../../components/CustomDropdown/CustomDropdown";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import { ErrorBoundary, FallbackUI } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { ErrorPlaceholder } from "../../../../components/ErrorPlaceholder";
import Loader from "../../../../components/Loader/Loader";
import ShowExtensionSearch from "../../../../components/ShowExtensionSearch";
import VirtualizedList from "../../../../components/VirtualizedList";
import useKeyboardNavigation from "../../../../hooks/useKeyboardNavigation";
import Sleep from "../../../../molecules/Sleep/Sleep";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { openExtensionLink } from "../../../../utils/API";
import { HQ_EASYSOURCE_WEBSITE, HQ_OPEN_PROJECT_FILTERS_EXTENSION } from "../../../../utils/Constants";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import CandidateModal from "../candidateModal/CandidateModal";
import { trimText } from "../exportCandidates/pdfDataTemplate";
import ProjectCard, { FallbackCandidateCard } from "../projectCard/ProjectCard";
import ProjectCardWithoutImage from "../projectCardWithoutImage/ProjectCardWithoutImage";
import VettingCriteriaModal from "../vettingCriteria/VettingCriteria";

import {
    checkProjectConnectedToRB2B,
    checkProjectsFetchError,
    fetchRB2BWebhookUrl,
    getProject,
    selectCurrProject,
    selectCurrentProjectStatus,
    selectRB2BWebhookUrl,
} from "@/store/reducers/allProjects/allProjects.reducer";
import {
    selectActiveCandidateInfoModalIndex,
    selectActiveModal,
    setActiveCandidateInfoModalIndex,
} from "@/store/reducers/candidates/Candidates.reducer";
import { selectVettingCriteriaText } from "@/store/reducers/create-project/CreateProject.reducer";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    checkEmailEnrich,
    checkErrorWhileFetchingCandidates,
    checkErrorWhileFetchingStats,
    checkLinkedinEnrich,
    checkPhoneEnrich,
    getProjectCandidates,
    getProjectStats,
    getShowByMaxExperience,
    getShowByMinExperience,
    getShowByPersonalEmails,
    getShowByProjectFilters,
    getShowByRecent,
    getShowByRelevance,
    isAllCandidatesSelected,
    isAllFiltersDisabled,
    removeSelectedCandidate,
    searchCandidates,
    selectCandidateCurrentPage,
    selectPageSize,
    selectProjectCandidates,
    selectProjectTotalCandidates,
    setCandidatePage,
    setPageSize,
    setSelectedCandidates,
} from "@/store/reducers/project/project.reducer";
import { IProjectView, ISelectedCandidateId } from "@/store/reducers/project/project.types";
import {
    checkCandidateListViewEnabled,
    checkEasyGrowth,
    checkIfAccountRB2B,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";

interface IProps {
    activeView: IProjectView;
    isSample?: boolean;
}

function SearchOnLinkedIn() {
    const params = useParams();
    const isFiltersDisabled = useSelector(isAllFiltersDisabled);

    const handleSearchOnLinkedInClick = () => {
        window.postMessage(
            {
                type: HQ_OPEN_PROJECT_FILTERS_EXTENSION,
                from: HQ_EASYSOURCE_WEBSITE,
                url: openExtensionLink,
                projectId: params.id,
            },
            "*"
        );
    };

    if (!isFiltersDisabled) {
        return null;
    }

    return (
        <ShowExtensionSearch>
            <Button
                onClick={handleSearchOnLinkedInClick}
                variant="text"
                sx={{
                    padding: "8px",
                    border: 0,
                    boxShadow: "none !important",
                    fontSize: "16px",
                    "& .MuiButton-endIcon": {
                        margin: 0,
                    },
                }}
            >
                Search on LinkedIn
            </Button>
        </ShowExtensionSearch>
    );
}

function ContainerCenteredText({ text }: { text: string }) {
    return (
        <div className={style["sp__relevanceLoader"]}>
            <Typography>{text}</Typography>
        </div>
    );
}

interface ContainerHeightConfig {
    allCandidatesSelected: boolean;
    isCandidatesAdding: boolean;
    isInsightsInProgress: boolean;
    emailEnrich?: boolean;
    phoneEnrich?: boolean;
    linkedInEnrich?: boolean;
}

export function calculateContainerHeight({
    allCandidatesSelected,
    isCandidatesAdding,
    isInsightsInProgress,
    emailEnrich,
    phoneEnrich,
    linkedInEnrich,
}: ContainerHeightConfig): string {
    const atLeastOneSelected =
        allCandidatesSelected ||
        isInsightsInProgress ||
        isCandidatesAdding ||
        emailEnrich ||
        phoneEnrich ||
        linkedInEnrich;
    const atLeastTwoSelected =
        (allCandidatesSelected && isCandidatesAdding) ||
        (allCandidatesSelected && isInsightsInProgress) ||
        (isCandidatesAdding && isInsightsInProgress);

    if (allCandidatesSelected && isCandidatesAdding && isInsightsInProgress) {
        return "calc(22% - 19px)";
    } else if (atLeastTwoSelected) {
        return "calc(15% - 12px)";
    } else if (atLeastOneSelected) {
        return "calc(8% - 5px)";
    } else {
        return "0%";
    }
}

const ConnectRB2B = ({ onClose }: { onClose: () => void }) => {
    const rb2bWebhookUrl = useSelector(selectRB2BWebhookUrl);
    const dispatch = useDispatch();
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(rb2bWebhookUrl || "");
    };

    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                width: "fit-content",
                padding: "1rem",
                paddingBottom: "2rem",
            }}
        >
            <Box sx={{ width: "375px" }}>
                <Stack alignItems={"center"} direction={"row"} gap={1}>
                    <img src={EasyGrowthLogo} style={{ height: "60px", width: "120px" }} alt="easysource-logo" />
                    <CompareArrowsIcon />
                    <div style={{ padding: "5px", paddingTop: "10px", background: "black" }}>
                        <img
                            src={RB2BLogo}
                            style={{
                                height: "25px",
                                width: "75px",
                            }}
                            alt="rb2b-logo"
                        />
                    </div>
                </Stack>
            </Box>
            <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={1.5}>
                Integrate with RB2B
            </Typography>

            <Box>
                <>
                    <Stack
                        padding={1}
                        justifyContent={"center"}
                        sx={{
                            borderRadius: "5px",
                            position: "relative",
                            height: "40px",
                            background: "rgba(0,0,0,0.05)",
                        }}
                    >
                        <Typography>{trimText(rb2bWebhookUrl || "", 45)}</Typography>
                        <Box
                            onClick={() => {
                                handleCopyToClipboard();
                                dispatch(setSuccessNotification("Webhook URL copied to clipboard"));
                            }}
                            sx={{
                                ":hover": {
                                    cursor: "pointer",
                                },
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                right: 0,
                                position: "absolute",
                                height: "30px",
                                width: "30px",
                            }}
                        >
                            <ContentCopyIcon />
                        </Box>
                    </Stack>
                    <Typography sx={{ pt: 1, color: "grey", fontSize: "13px", fontStyle: "italic" }}>
                        Paste this unique webhook url on your RB2B screen to make sure you&apos;re connected
                    </Typography>
                </>
            </Box>
        </BaseModal>
    );
};

export default function ProjectCandidates({ activeView, isSample }: IProps) {
    const [connectRB2BModalOpen, setConnectRB2BModalOpen] = useState(false);
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const fromBlendSearch = searchParams.get("fromSearch");
    const isNavigatedFromBlendSearch = fromBlendSearch === "true";
    const activeCandidateModalIndex = useSelector(selectActiveCandidateInfoModalIndex);
    const { emailAuthorized } = useSelector(selectUser);
    const currentPage = useSelector(selectCandidateCurrentPage);
    const candidateListView = useSelector(checkCandidateListViewEnabled);
    const isErrorWhileFetchingCandidates = useSelector(checkErrorWhileFetchingCandidates);
    // const candidatesList = useSelector(selectCurrentProjectCandidates(params.id));
    const candidatesList = useSelector(selectProjectCandidates);
    const projectData = useSelector(selectCurrProject);
    const projectStatus = useSelector(selectCurrentProjectStatus(params.id));
    const totalCandidates = useSelector(selectProjectTotalCandidates);
    const pageSize = useSelector(selectPageSize);
    const allCandidatesSelected = useSelector(isAllCandidatesSelected);
    const isErrorWhileFetchingProjects = useSelector(checkProjectsFetchError);
    const isErrorWhileFetchingStats = useSelector(checkErrorWhileFetchingStats);
    const isRelevanceLoading = useSelector(checkIfLoading(getShowByRelevance.type));
    const isCandidatesLoading = useSelector(checkIfLoading(getProjectCandidates.type));
    const isSearching = useSelector(checkIfLoading(searchCandidates.type));
    const isCandidatesFiltersLoading = useSelector(checkIfLoading(getShowByProjectFilters.type));
    const isShowByRecentLoading = useSelector(checkIfLoading(getShowByRecent.type));
    const isShowByMaxLoading = useSelector(checkIfLoading(getShowByMaxExperience.type));
    const isShowByMinLoading = useSelector(checkIfLoading(getShowByMinExperience.type));
    const isShowByPersonalEmailLoading = useSelector(checkIfLoading(getShowByPersonalEmails.type));
    const isInsightsInProgress = projectData?.generateInsightsStatus === "IN_PROGRESS";
    const isCandidatesAdding = projectStatus === "IN_PROGRESS" || projectStatus === "PENDING";
    const [isVettingModalOpen, setIsVettingModalOpen] = useState(false);
    const vettingCriteriaText = useSelector(selectVettingCriteriaText);
    const showCadidateModal = useSelector(selectActiveCandidateInfoModalIndex) !== null;
    const activeModal = useSelector(selectActiveModal);
    const isAccountRB2B = useSelector(checkIfAccountRB2B);
    const isProjectConnectedToRB2B = useSelector(checkProjectConnectedToRB2B);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isConnectRb2bLoading = useSelector(checkIfLoading(fetchRB2BWebhookUrl.type));
    const selectedCandidates = useSelector<any, ISelectedCandidateId[]>((state) =>
        get(state, "project.selectedCandidates")
    );
    const emailEnrich = useSelector(checkEmailEnrich);
    const phoneEnrich = useSelector(checkPhoneEnrich);
    const linkedInEnrich = useSelector(checkLinkedinEnrich);

    const handleSelect = (index: number, selected: boolean) => {
        const currentIndex = selectedCandidates.findIndex(
            (item: any) => item.candidateId === candidatesList[index]._id
        );
        if (selected && currentIndex !== -1) {
            dispatch(removeSelectedCandidate(candidatesList[index]._id));
        } else {
            dispatch(
                setSelectedCandidates({
                    candidateId: [candidatesList[index]._id],
                    pageNo: currentPage,
                })
            );
        }
    };

    useKeyboardNavigation(
        {
            scroll: {
                scrollContainerRef: wrapperRef,
                itemsRef: itemsRef,
                skip: showCadidateModal || activeModal,
                onSelectItem: handleSelect,
            },
        },
        [candidatesList.length, selectedCandidates]
    );
    const handleOpenVettingModal = () => {
        setIsVettingModalOpen(true);
    };

    const handleCloseVettingModal = () => {
        setIsVettingModalOpen(false);
    };

    const handlePageChange = (page: number, pageSize: number) => {
        const newPage = page;
        dispatch(setCandidatePage(newPage));
        dispatch(setPageSize(pageSize));
        dispatch(
            getProjectCandidates({
                projectId: params.id,
                start: newPage,
                limit: pageSize,
                action: getProjectCandidates.type,
                sendRequestId: true,
            })
        );
        if (wrapperRef.current) wrapperRef.current.scrollTop = 0;
    };

    const handleOnPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        handlePageChange(page, pageSize);
    };

    const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handlePageChange(0, parseInt(event.target.value, 10));
    };

    const getPaginationText = (count: number, range: number[]) => {
        return `${range[0]}-${range[1]} of ${count} ${t("common")}`;
    };

    const candidateContainerHeight = calculateContainerHeight({
        allCandidatesSelected,
        isCandidatesAdding,
        isInsightsInProgress,
        emailEnrich,
        phoneEnrich,
        linkedInEnrich,
    });

    const closeModal = () => {
        dispatch(setActiveCandidateInfoModalIndex(null));
    };

    const isLoading =
        isRelevanceLoading ||
        isCandidatesLoading ||
        isShowByMinLoading ||
        isShowByMaxLoading ||
        isShowByRecentLoading ||
        isShowByPersonalEmailLoading ||
        isCandidatesFiltersLoading ||
        isSearching;

    const isCandidateListEmpty = candidatesList?.length === 0;

    const handleProjectsReload = () => {
        const projectId = params?.id || "";
        dispatch(
            getProject({
                projectId,
                action: getProject.type,
            })
        );
    };

    const handleStatsReload = () => {
        const projectId = params?.id || "";
        dispatch(
            getProjectStats({
                projectId,
                action: getProject.type,
            })
        );
    };

    const handleCandidatesReload = () => {
        const projectId = params?.id || "";
        dispatch(
            getProjectCandidates({
                projectId,
                start: currentPage,
                limit: pageSize,
                action: getProjectCandidates.type,
            })
        );
    };

    if (isErrorWhileFetchingProjects || isErrorWhileFetchingStats || isErrorWhileFetchingCandidates) {
        const reload =
            isErrorWhileFetchingProjects || projectStatus === "ERRORED"
                ? handleProjectsReload
                : isErrorWhileFetchingStats
                  ? handleStatsReload
                  : handleCandidatesReload;

        return (
            <ErrorPlaceholder
                reload={reload}
                containerProps={{
                    height: "60vh",
                    justifyContent: "center",
                }}
            >
                <CachedIcon fontSize="small" />
            </ErrorPlaceholder>
        );
    }

    if (isErrorWhileFetchingCandidates) {
        return <ContainerCenteredText text={t("projectsPage.insightGeneration.fetchingCandidatesError")} />;
    }

    // for normal loader
    if (isLoading && !isCandidatesAdding && !isNavigatedFromBlendSearch) {
        return <Loader size="sm" />;
    }

    if (isCandidatesAdding && isCandidateListEmpty) {
        return <ContainerCenteredText text={t("projectsPage.longPollingText")} />;
    }

    const onConnectClick = () => {
        dispatch(
            fetchRB2BWebhookUrl({
                projectId: Number(params.id),
                onSuccess: () => {
                    setConnectRB2BModalOpen(true);
                },
            })
        );
    };

    const pageOptions: CustomDropdownOptionsType[] = [
        {
            label: "25 / page",
            key: "25",
            handleClick: () => handlePageChange(1, 25),
        },
        {
            label: "50 / page",
            key: "50",
            handleClick: () => handlePageChange(1, 50),
        },
        {
            label: "100 / page",
            key: "100",
            handleClick: () => handlePageChange(1, 100),
        },
        {
            label: "500 / page",
            key: "500",
            handleClick: () => handlePageChange(1, 500),
        },
        {
            label: "1000 / page",
            key: "1000",
            handleClick: () => handlePageChange(1, 1000),
        },
    ];

    return (
        <>
            {connectRB2BModalOpen && <ConnectRB2B onClose={() => setConnectRB2BModalOpen(false)} />}
            {candidatesList?.length ? (
                <>
                    <ErrorBoundary fallbackRender={() => <FallbackCandidateCard />}>
                        <div
                            ref={wrapperRef}
                            className={emailAuthorized ? style["sp__container--authorised"] : style["sp__container"]}
                            id="main_container"
                            style={{
                                // height: `calc(100vh - ${candidateContainerHeight})`,
                                // height: `calc(93% - ${candidateContainerHeight})`,
                                height: "93%",
                                overflowY:"scroll",
                            }}
                        >
                            {candidateListView && isEasyGrowth ? (
                                <VirtualizedList
                                    itemHeight={110}
                                    numItems={candidatesList.length}
                                    containerRef={wrapperRef}
                                    renderItem={({ index, style }) => {
                                        return (
                                            <div
                                                style={style}
                                                key={index}
                                                id={candidatesList[index]._id}
                                                ref={(el) => {
                                                    if (el && itemsRef.current) {
                                                        itemsRef.current[index] = el;
                                                    }
                                                }}
                                            >
                                                <ProjectCardWithoutImage index={index} data={candidatesList[index]} />
                                            </div>
                                        );
                                    }}
                                />
                            ) : (
                                <VirtualizedList
                                    itemHeight={290}
                                    numItems={candidatesList.length}
                                    containerRef={wrapperRef}
                                    renderItem={({ index, style }) => {
                                        return (
                                            <div
                                                style={style}
                                                key={index}
                                                id={candidatesList[index]._id}
                                                ref={(el) => {
                                                    if (el && itemsRef.current) {
                                                        itemsRef.current[index] = el;
                                                    }
                                                }}
                                            >
                                                <ProjectCard index={index} data={candidatesList[index]} />
                                            </div>
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </ErrorBoundary>
                    {isSample ? null : (
                        <div style={{ height:"7%" }} >
                            {(selectedCandidates.length === 1 ||
                                selectedCandidates.length === candidatesList.length) && (
                                <div className={style["arrowMessage"]}>
                                    Try Shift + Down/Up keys to make multiple selection
                                </div>
                            )}
                            <CustomPagination
                                currentPage={currentPage}
                                totalCandidates={totalCandidates}
                                pageSize={pageSize}
                                handlePageChange={handlePageChange}
                                pageOptions={pageOptions}
                                enablePageSizeChange={true}
                            />
                        </div>
                    )}
                </>
            ) : isAccountRB2B && !isProjectConnectedToRB2B ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: `calc(100vh - 300px)` }}
                >
                    <Stack alignItems={"center"} direction={"row"} gap={1}>
                        <Button variant="contained" onClick={onConnectClick}>
                            {isConnectRb2bLoading ? "Loading" : "Connect to RB2B"}
                        </Button>
                    </Stack>
                </Stack>
            ) : (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: `calc(100vh - 300px)` }}
                >
                    <Stack sx={{ alignContent: "center", alignItems: "center" }}>
                        <Sleep
                            fallback={
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <Loader size="sm" />
                                </Stack>
                            }
                        >
                            <Typography sx={{ fontSize: "18px" }}>
                                {isCandidateListEmpty
                                    ? t("projectsPage.insightGeneration.emptyMessage")
                                    : t("projectsPage.insightGeneration.fetchingCandidatesError")}
                            </Typography>
                        </Sleep>

                        {isCandidateListEmpty && vettingCriteriaText && (
                            <>
                                <Box
                                    sx={{
                                        border: "0px",
                                        marginTop: "20px",
                                        fontSize: "18px",
                                        color: "#0891B2",
                                        cursor: "pointer",
                                        transition: "font-size 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                                        "&:hover": {
                                            fontSize: "20px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        },
                                    }}
                                    onClick={handleOpenVettingModal}
                                >
                                    <Edit sx={{ color: "gray", fontSize: "32px", paddingTop: "19px" }} />
                                    Let&apos;s get started by entering vetting criteria to uncover the most qualified
                                    candidates
                                </Box>
                            </>
                        )}
                        {/* <SearchOnLinkedIn /> */}
                    </Stack>
                    {isVettingModalOpen && <VettingCriteriaModal close={handleCloseVettingModal} />}
                </Stack>
            )}
            {typeof activeCandidateModalIndex === "number" && (
                <BaseModal onClose={closeModal} overlayStyles={{ width: "70vw", height: "80vh" }}>
                    <ErrorBoundary fallbackRender={() => <FallbackUI sx={{ mt: "5rem" }} />}>
                        <Suspense fallback={<Loader size="sm" />}>
                            <CandidateModal />
                        </Suspense>
                    </ErrorBoundary>
                </BaseModal>
            )}
        </>
    );
}
