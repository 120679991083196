import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IGetUserListPayload, ITracking, IUser } from "@/store/reducers/userList/userList.types";

import { RootState } from "@/store";
import { IActionPayload } from "../loaders.reducer";

const initialState = Object.freeze({
    users: [],
    total: 0,
    totalProjectsCreated: 0,
    totalCandidatesScraped: 0,
    totalEmailsExtracted: 0,
    totalWorkflowsTriggered: 0,
    sortBy: "createdAt",
    pageSize: 500,
    currentPage: 0,
    orgId: -1,
});

const reducers = {
    setCurrentPage(state: any, { payload }: { payload: number }) {
        state.currentPage = payload;
    },
    setPageSize(state: any, { payload }: { payload: number }) {
        state.pageSize = payload;
    },
    getUserList(state: any, { payload }: { payload: IGetUserListPayload & IActionPayload }) {
        if (payload.sortBy) state.sortBy = payload.sortBy;
    },
    setUserList(state: any, action: PayloadAction<IUser[]>) {
        state.users = action.payload;
    },
    setTotalUserCount(state: any, { payload }: { payload: number }) {
        state.total = payload;
    },
    setTotalProjectsCreated(state: any, { payload }: { payload: number }) {
        state.totalProjectsCreated = payload;
    },
    setTotalCandidatesScraped(state: any, { payload }: { payload: number }) {
        state.totalCandidatesScraped = payload;
    },
    setTotalEmailsExtracted(state: any, { payload }: { payload: number }) {
        state.totalEmailsExtracted = payload;
    },
    setTotalWorkflowsTriggered(state: any, { payload }: { payload: number }) {
        state.totalWorkflowsTriggered = payload;
    },
    setSortBy(state: any, { payload }: { payload: IUser[] }) {
        state.users = payload;
    },
    setOrgId(state: any, action: PayloadAction<string>) {
        state.orgId = action.payload;
    },
    updateUserTracking(
        state: any,
        action: PayloadAction<{
            type: string;
            userId: string;
            tracking: ITracking;
        }>
    ) {},
    submitSwitchOrdId(state: any, action: PayloadAction) {},
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "userList",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const {
    getUserList,
    setUserList,
    setTotalUserCount,
    setTotalProjectsCreated,
    setTotalWorkflowsTriggered,
    setTotalCandidatesScraped,
    setTotalEmailsExtracted,
    leavePage,
    setPageSize,
    setCurrentPage,
    setOrgId,
    submitSwitchOrdId,
    updateUserTracking,
} = page.actions;

export const selectUsers = (state: RootState) => state.userList.users;
export const selectTotalUsers = (state: RootState) => state.userList.total;
export const selectTotalProjectsCreated = (state: RootState) => state.userList.totalProjectsCreated;
export const selectTotalWorkflowsTriggered = (state: RootState) => state.userList.totalWorkflowsTriggered;
export const selectTotalCandidatesScraped = (state: RootState) => state.userList.totalCandidatesScraped;
export const selectTotalEmailsExtracted = (state: RootState) => state.userList.totalEmailsExtracted;
export const selectSortBy = (state: RootState) => state.userList.sortBy;
export const selectPageSize = (state: RootState) => state.userList.pageSize;
export const selectCurrentPage = (state: RootState) => state.userList.currentPage;
export const selectOrgId = (state: RootState) => state.userList.orgId;
