import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isUndefined } from "lodash";

import {
    CHARACTER_COUNT,
    Candidate,
    ChangeCandidateTemplatePayload,
    HeaderTab,
    PersonalizedWorkflowState,
    SendUpdatedTemplatesPayload,
    SendUpdatedTemplatesResponseTemplate,
    SetCandidateIndexPayload,
    SubmitPersonalizedInputsPayload,
    TONE_OF_VOICE,
    Template,
    TemplatesView,
} from "@/pages/triggerWorkflow/types";
import { RootState } from "@/store";

const initialState: PersonalizedWorkflowState = {
    isErrorWhileFetchingCandidateNames: false,
    view: "CUSTOM",
    showEditTemplatesModal: true,
    characterCount: "medium",
    toneOfVoice: "Professional",
    outreachIntent: "",
    index: 0,
    candidates: [],
    errors: {},
    candidate: {
        id: "",
        name: "",
        fetchTemplateStatus: "IDLE",
        isTemplatesUpdated: false,
    },
    currentHeaderTab: 0,
};

const reducers = {
    setCandidatesNamesFetchError(state: PersonalizedWorkflowState, action: PayloadAction<boolean>) {
        state.isErrorWhileFetchingCandidateNames = action.payload;
    },
    setOutreachIntent(state: PersonalizedWorkflowState, { payload }: { payload: string }) {
        state.outreachIntent = payload;
    },
    setEditTemplatesModal(state: PersonalizedWorkflowState, action: PayloadAction<boolean>) {
        state.showEditTemplatesModal = action.payload;
    },
    setCandidateIndex(state: PersonalizedWorkflowState, { payload }: PayloadAction<SetCandidateIndexPayload>) {
        const { index } = payload;
        state.index = index;
        state.candidate = state.candidates[state.index];
    },
    changeCharacterCount(state: PersonalizedWorkflowState, { payload }: PayloadAction<CHARACTER_COUNT>) {
        state.characterCount = payload;
    },
    changeToneOfVoice(state: PersonalizedWorkflowState, { payload }: PayloadAction<TONE_OF_VOICE>) {
        state.toneOfVoice = payload;
    },
    submitPersonalizedInputs(
        state: PersonalizedWorkflowState,
        action: PayloadAction<SubmitPersonalizedInputsPayload>
    ) {},
    setCandidates(state: PersonalizedWorkflowState, action: PayloadAction<Candidate[]>) {
        state.candidates = action.payload;
        state.candidate = state.candidates[state.index];
    },
    nextCandidate(state: PersonalizedWorkflowState) {
        const index = state.index;
        if (index < state.candidates.length - 1) {
            state.index++;
        }
        state.candidate = state.candidates[state.index];
        // if (state.index > 2) {
        //     state.showEditTemplatesModal = true;
        // }
    },
    setTemplates(state: PersonalizedWorkflowState, action: PayloadAction<Template[]>) {
        state.candidates = state.candidates.map((candidate) => {
            const templates = action.payload.find(({ _id }) => _id === candidate.id);

            if (templates && templates?._id && templates?.hyperPersonalizedTemplate) {
                const hyperPersonalizedTemplate = templates.hyperPersonalizedTemplate;
                const hyperPersonalizedTemplateConnReq = hyperPersonalizedTemplate.connectionReq;
                const hyperPersonalizedTemplateEmail = hyperPersonalizedTemplate.email;
                const hyperPersonalizedTemplateInMail = hyperPersonalizedTemplate.inMail;
                const hyperPersonalizedTemplateFollowupEmail = hyperPersonalizedTemplate.followupEmail;
                const hyperPersonalizedTemplateSMS = hyperPersonalizedTemplate.sms;
                return {
                    ...candidate,
                    fetchTemplateStatus: "SUCCESS",
                    sms: {
                        body: hyperPersonalizedTemplateSMS ? hyperPersonalizedTemplateSMS.body : "",
                    },
                    connectionReq: {
                        body: hyperPersonalizedTemplateConnReq ? hyperPersonalizedTemplateConnReq.body : "",
                    },
                    email: {
                        body: hyperPersonalizedTemplateEmail ? hyperPersonalizedTemplateEmail.body : "",
                        subject: hyperPersonalizedTemplateEmail ? hyperPersonalizedTemplateEmail.subject : "",
                    },
                    inMail: {
                        body: hyperPersonalizedTemplateInMail ? hyperPersonalizedTemplateInMail.body : "",
                        subject: hyperPersonalizedTemplateInMail ? hyperPersonalizedTemplateInMail.subject : "",
                    },
                    followupEmail: {
                        body: hyperPersonalizedTemplateFollowupEmail ? hyperPersonalizedTemplateFollowupEmail.body : "",
                    },
                };
            }

            return candidate;
        });
        if (state.candidates[state.index]) {
            state.candidate = state.candidates[state.index];
        } else {
            state.index = 0;
            if (state.candidates[state.index]) {
                state.candidate = state.candidates[state.index];
            }
        }
    },
    setFetchTemplateStatus(
        state: PersonalizedWorkflowState,
        action: PayloadAction<{
            candidateId: string;
            status: "LOADING" | "SUCCESS" | "ERROR";
        }>
    ) {
        const { candidateId, status } = action.payload;

        state.candidates = state.candidates.map((candidate) => {
            if (candidate.id === candidateId) {
                candidate.fetchTemplateStatus = status;
            }
            return candidate;
        });
        if (state.candidate.id === candidateId) {
            state.candidate.fetchTemplateStatus = status;
        }
    },
    setFetchTemplateErrorStatus(state: PersonalizedWorkflowState, action: PayloadAction<{ candidateId: string }>) {
        const { candidateId } = action.payload;
        state.candidates.forEach((candidate) => {
            if (candidate.id === candidateId) {
                candidate.fetchTemplateStatus = "ERROR";
            }
        });
        if (state.candidate.id === candidateId) {
            state.candidate.fetchTemplateStatus = "ERROR";
        }
    },
    prevCandidate(state: PersonalizedWorkflowState) {
        const index = state.index;
        if (index > 0) {
            state.index--;
        }
        state.candidate = state.candidates[state.index];
    },
    changeCandidateTemplate(state: PersonalizedWorkflowState, action: PayloadAction<ChangeCandidateTemplatePayload>) {
        const type = action.payload.type;
        const payload = action.payload;
        if (type === "EMAIL" && state.candidate?.email) {
            if (!isUndefined(payload.body)) {
                state.candidate.email.body = payload.body;
            }

            if (!isUndefined(payload.subject)) {
                state.candidate.email.subject = payload.subject;
            }
        }

        if (type === "IN_MAIL" && state.candidate?.inMail) {
            if (!isUndefined(payload.body)) {
                state.candidate.inMail.body = payload.body;
            }

            if (!isUndefined(payload.subject)) {
                state.candidate.inMail.subject = payload.subject;
            }
        }

        if (type === "CONN_REQ" && state.candidate?.connectionReq) {
            if (!isUndefined(payload.body)) {
                state.candidate.connectionReq.body = payload.body;
            }
        }

        if (type === "FOLLOW_UP" && state.candidate?.followupEmail && !isUndefined(payload.body)) {
            state.candidate.followupEmail.body = payload.body;
        }

        if (type === "SMS" && state.candidate?.sms) {
            if (!isUndefined(payload.body)) {
                state.candidate.sms.body = payload.body;
            }
        }
    },
    setUpdatedTemplates(
        state: PersonalizedWorkflowState,
        action: PayloadAction<SendUpdatedTemplatesResponseTemplate[]>
    ) {
        const payload = action.payload;
        payload.forEach((candidate) => {
            if (state.candidate.id === candidate._id) {
                state.candidate = {
                    ...state.candidate,
                    connectionReq: candidate.connectionReq,
                    email: candidate.email,
                    inMail: candidate.inMail,
                    followupEmail: candidate.followupEmail,
                    isTemplatesUpdated: true,
                };
            }

            state.candidates.forEach((savedCandidate) => {
                if (savedCandidate.id === candidate._id) {
                    savedCandidate.connectionReq = candidate.connectionReq;
                    savedCandidate.email = candidate.email;
                    savedCandidate.inMail = candidate.inMail;
                    savedCandidate.followupEmail = candidate.followupEmail;
                    savedCandidate.isTemplatesUpdated = true;
                }
            });
        });
    },
    resetPersonalizedInputs(state: PersonalizedWorkflowState) {
        state.characterCount = "medium"; // change to null later
        state.toneOfVoice = "Professional"; // change to null later
    },
    sendUpdatedTemplates(state: PersonalizedWorkflowState, action: PayloadAction<SendUpdatedTemplatesPayload>) {},
    fetchSingleCandidateTemplates(state: PersonalizedWorkflowState, action: PayloadAction<SetCandidateIndexPayload>) {},
    resetPersonalizedState(state: PersonalizedWorkflowState) {
        return initialState;
    },
    setTemplatesView(state: PersonalizedWorkflowState, action: PayloadAction<{ view: TemplatesView }>) {
        state.view = action.payload.view;
    },
    fetchCandidateWithUpdatedOutReachContent(
        state: PersonalizedWorkflowState,
        action: PayloadAction<SetCandidateIndexPayload>
    ) {},
    clearAllGeneratedTemplates(state: PersonalizedWorkflowState) {
        const index = state.index;
        for (let i = 0; i < state.candidates.length; i++) {
            state.candidates[i].isTemplatesUpdated = false;
            if (i === index) {
                continue;
            }
            delete state.candidates[i].email;
            delete state.candidates[i].inMail;
            delete state.candidates[i].followupEmail;
            delete state.candidates[i].connectionReq;
            state.candidates[i].fetchTemplateStatus = "IDLE";
        }
    },
    changeHeaderTab(state: PersonalizedWorkflowState, action: PayloadAction<HeaderTab>) {
        state.currentHeaderTab = action.payload;
    },
    fetchCandidateNames(state: PersonalizedWorkflowState, action: PayloadAction<SubmitPersonalizedInputsPayload>) {},
    cancelActions(state: PersonalizedWorkflowState) {},
};

export const personalizedWorkflowSlice = createSlice({
    name: "personalizedWorkflow",
    initialState,
    reducers,
});

export default personalizedWorkflowSlice.reducer;

export const {
    setCandidatesNamesFetchError,
    changeHeaderTab,
    changeCharacterCount,
    changeToneOfVoice,
    submitPersonalizedInputs,
    setCandidates,
    cancelActions,
    nextCandidate,
    prevCandidate,
    setTemplates,
    setCandidateIndex,
    changeCandidateTemplate,
    sendUpdatedTemplates,
    setUpdatedTemplates,
    resetPersonalizedInputs,
    setEditTemplatesModal,
    fetchSingleCandidateTemplates,
    resetPersonalizedState,
    setFetchTemplateStatus,
    setFetchTemplateErrorStatus,
    setOutreachIntent,
    setTemplatesView,
    fetchCandidateWithUpdatedOutReachContent,
    clearAllGeneratedTemplates,
    fetchCandidateNames,
} = personalizedWorkflowSlice.actions;

export const selectCharacterCount = (state: RootState) => state.personalizedWorkflow.characterCount;

export const selectToneOfVoice = (state: RootState) => state.personalizedWorkflow.toneOfVoice;

export const selectCandidates = (state: RootState) => state.personalizedWorkflow.candidates;

export const selectIndex = (state: RootState) => state.personalizedWorkflow.index;

export const selectCandidate = (state: RootState) => state.personalizedWorkflow.candidate;

export const selectEditTemplatesModalState = (state: RootState) => state.personalizedWorkflow.showEditTemplatesModal;

export const selectTemplatesView = (state: RootState) => state.personalizedWorkflow.view;

export const selectCustomHeaderTab = (state: RootState) => state.personalizedWorkflow.currentHeaderTab;

export const selectTotalCandidatesCount = (state: RootState) => state.personalizedWorkflow.candidates.length;

export const checkFetchCandidateNamesError = (state: RootState) =>
    state.personalizedWorkflow.isErrorWhileFetchingCandidateNames;
