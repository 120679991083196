import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import BaseModal from "../../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    addSearchCandidatesToProject,
    selectCandidates,
    selectSearch,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";

import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Typography } from "@/ui";

export function useProjectData() {
    const [searchParams] = useSearchParams();
    return {
        projectId: searchParams.get("project"),
        projectName: searchParams.get("name"),
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchId: useSelector(selectSearch)._id,
        candidates: useSelector(selectCandidates),
        isSuperAdmin: useSelector(isSuperAdminUser),
        isAddingToProject: useSelector(checkIfLoading(addSearchCandidatesToProject.type)),
    };
}

export default function AddCustomCandidates() {
    const [openModal, setOpenModal] = useState(false);
    const [candidateCount, setCandidateCount] = useState<number>(50);
    const { projectId, projectName, dispatch, searchId, navigate, candidates, isSuperAdmin, isAddingToProject } =
        useProjectData();

    if (!searchId || !candidates?.length || !isSuperAdmin) return null;

    const handleCloseModal = () => {
        setOpenModal(false);
        setCandidateCount(50);
    };

    const handleAddToProject = () => {
        if (candidateCount <= 0) return;
        dispatch(
            addSearchCandidatesToProject({
                projectId,
                projectName,
                searchId,
                navigate,
                profilesCount: candidateCount,
            })
        );
    };

    const minCount = 0;

    return (
        <>
            <Button variant="outline" onClick={() => setOpenModal(true)}>
                Add Custom Prospects To Campaign
            </Button>
            {openModal && (
                <BaseModal hideCloseButton onClose={handleCloseModal}>
                    <div className="flex flex-col gap-2">
                        <Typography className="flex flex-col gap-2">Add Custom Prospects To Campaign</Typography>
                        <input
                            type="text"
                            value={candidateCount}
                            onChange={(e) =>
                                setCandidateCount(!isNaN(Number(e.target.value)) ? Number(e.target.value) : null)
                            }
                            className={`w-full text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2 
                                ${
                                    Number(candidateCount) <= minCount
                                        ? "border-red-500 focus:ring-red-500"
                                        : "border-gray-300 focus:ring-blue-500"
                                }`}
                            placeholder="Enter candidates count"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                        {Number(candidateCount) <= minCount && (
                            <span className="text-xs text-red-500 mt-1">*It should be greater than zero</span>
                        )}

                        <div className="flex flex-row gap-2 items-center justify-end">
                            <Button variant="outline" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="default" disabled={isAddingToProject} onClick={handleAddToProject}>
                                <ButtonTextWithLoading text="Submit" isLoading={isAddingToProject} />
                            </Button>
                        </div>
                    </div>
                </BaseModal>
            )}
        </>
    );
}
