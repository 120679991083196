import { Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Filters.module.css";

import { selectJobFunction, setJobFunction } from "@/store/reducers/filters/Filters.reducer";
import { optionType } from "@/store/reducers/filters/Filters.type";


const options = [
    { value: "sales", label: "Sales" },
    { value: "marketing", label: "Marketing" },
    { value: "others", label: "Others" },
];

const JobFunction = () => {
    const dispatch = useDispatch();
    const jobFunction = useSelector(selectJobFunction);

    return (
        <Stack>
            <p>Job function</p>
            <Stack flexDirection="row" className={styles.fieldContainer}>
                {options.map((item: optionType, i: number) => {
                    const isSelected = jobFunction.value === item.value;
                    return (
                        <Button
                            key={i}
                            variant={"outlined"}
                            className={`${styles.jobFunctionButton} ${isSelected && styles.jobFunctionSelected}`}
                            onClick={() => dispatch(setJobFunction(item))}
                        >
                            {item.label}
                        </Button>
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default JobFunction;
