import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import {
    selectAllFiltersWithValue,
    selectSearchQueryInAllCandidates,
} from "@/store/reducers/all-candidates/all-candidates.slice";
import {
    addToRecruiterFlow,
    getFromRecruiterFlow,
    getRecruiterFlowJobs,
    getRecruiterFlowStages,
    selectRecruiterFlowJobStages,
    selectRecruiterFlowJobs,
} from "@/store/reducers/integrations/integrations.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button } from "@/ui";

type RecruiterFlowModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export const CustomErrorTypography = styled(Typography)({
    fontSize: 14,
    marginTop: "1rem",
    textAlign: "center",
    color: "red",
});

export default function RecruiterFlowModal({
    open,
    onClose,
    id,
    selectedCandidateIds,
    variant,
}: RecruiterFlowModalProps) {
    const dispatch = useDispatch();
    const recruiterFlowJobs = useSelector(selectRecruiterFlowJobs);
    const recruiterFlowJobStages = useSelector(selectRecruiterFlowJobStages);
    const isRecruiterFlowJobsLoading = useSelector(checkIfLoading(getRecruiterFlowJobs.type));
    const isRecruiterFlowJobStagesLoading = useSelector(checkIfLoading(getRecruiterFlowStages.type));
    const isRecruiterFlowSubmitting = useSelector(checkIfLoading(addToRecruiterFlow.type));
    const isRecruiterFlowFetching = useSelector(checkIfLoading(getFromRecruiterFlow.type));
    const searchQuery = useSelector(selectSearchQueryInAllCandidates);
    const filters = useSelector(selectAllFiltersWithValue);

    const isVariantFetch = variant === "FETCH";

    const [jobId, setJobId] = useState("");
    const [stageId, setStageId] = useState("");
    const [stageName, setStageName] = useState("");

    useEffect(() => {
        if (open) {
            dispatch(getRecruiterFlowJobs({ action: getRecruiterFlowJobs.type }));
        }
    }, [open]);

    const handleCloseModal = () => {
        setJobId("");
        onClose();
    };

    const handleAddRecruiterFlow = () => {
        if (isVariantFetch) {
            if (!jobId) {
                dispatch(setErrorNotification("Please select a job"));
                return;
            }
            dispatch(
                getFromRecruiterFlow({
                    jobId: Number(jobId),
                    stageId: Number(stageId),
                    projectId: Number(id),
                    stageName: stageName,
                })
            );
            handleCloseModal();
        } else {
            if (jobId === "") {
                dispatch(setErrorNotification("Please select job"));
                return;
            }

            dispatch(
                addToRecruiterFlow({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    selectAll: false,
                    jobId: Number(jobId),
                    stageId: Number(stageId),
                    stageName: stageName,
                    filterBy: filters,
                    search: searchQuery,
                })
            );

            handleCloseModal();
        }
    };

    const handleJobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newJobId = e.target.value;
        setJobId(newJobId);
        dispatch(getRecruiterFlowStages({ jobId: Number(newJobId) }));
    };

    const isDisabled = isRecruiterFlowJobsLoading || isRecruiterFlowSubmitting || isRecruiterFlowFetching;

    const isLoading = isRecruiterFlowSubmitting || isRecruiterFlowFetching;

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"sm"}>
            <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} RecruiterFlow</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {isRecruiterFlowJobsLoading ? (
                        <Stack alignItems="center" mt={2}>
                            <CircularProgress size={24} />
                        </Stack>
                    ) : recruiterFlowJobs.length ? (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Typography>Select job:</Typography>
                            <TextField
                                hiddenLabel
                                size="small"
                                value={jobId}
                                onChange={handleJobChange}
                                select
                                sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                        fontSize: 14,
                                    },
                                    width: 200, // Fixed width
                                }}
                            >
                                {recruiterFlowJobs.map((job) => (
                                    <MenuItem value={job?.jobId?.toString()}>{job.title}</MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                    ) : (
                        <CustomErrorTypography>
                            No jobs found. Create one on your RecruiterFlow dashboard
                        </CustomErrorTypography>
                    )}
                    {jobId ? (
                        isRecruiterFlowJobStagesLoading ? (
                            <Stack alignItems="center" mt={2}>
                                <CircularProgress size={24} />
                            </Stack>
                        ) : recruiterFlowJobStages.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select job stage:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={stageId}
                                    onChange={(e) => {
                                        const selectedStage = recruiterFlowJobStages.find(
                                            (stage) => stage.stageId.toString() === e.target.value
                                        );
                                        setStageId(e.target.value);
                                        setStageName(selectedStage ? selectedStage.name : "");
                                    }}
                                    select
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                        width: 200, // Fixed width
                                    }}
                                >
                                    {recruiterFlowJobStages.map((jobStage) => (
                                        <MenuItem value={jobStage?.stageId?.toString()}>{jobStage.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <CustomErrorTypography>
                                No job stages found. Create one on your RecruiterFlow dashboard
                            </CustomErrorTypography>
                        )
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outline" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button disabled={isDisabled} onClick={handleAddRecruiterFlow}>
                    <ButtonTextWithLoading isLoading={isLoading} text="Confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
