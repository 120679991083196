import Intercom from "@intercom/messenger-js-sdk";
import { useSelector } from "react-redux";

import { checkIntercomEnabled, selectUser } from "@/store/reducers/signin/Signin.reducer";

export default function IntercomLauncher() {
    const user = useSelector(selectUser);
    const intercomEnabled = useSelector(checkIntercomEnabled);

    if (intercomEnabled) {
        Intercom({
            app_id: "iwtqqmny",
            user_id: user._id?.toString(), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            created_at: new Date(user.createdAt as string).getTime(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
            alignment: "left",
        });
    }

    return null;
}
