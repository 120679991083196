import { InfoRounded } from "@mui/icons-material";
import { Stack, Switch, Tooltip, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setCreditAlertPreference } from "@/store/reducers/my-account/myAccount.reducer";

type AlertToggleProps = {
    alertStatus: boolean;
    alertFor: string;
    disabled?: boolean;
};

function AlertToggle({ alertStatus, alertFor, disabled }: AlertToggleProps) {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(alertStatus);
    // const [alertThreshold, setAlertThreshold] = useState(50);

    useEffect(() => {
        setStatus(alertStatus);
    }, [alertStatus]);

    const handleAlertStatusChange = () => {
        setStatus(!status);
        dispatch(
            setCreditAlertPreference({
                alertFor: alertFor,
                status: !status,
                onError: () => {
                    // onError revert back to previous status
                    setStatus(status);
                },
            })
        );
    };

    // const handleAlertThresholdChange = (value: number) => {
    //     setAlertThreshold(value);
    // };

    // const options: CustomDropdownOptionsType[] = [
    //     {
    //         label: "50%",
    //         key: 50,
    //         handleClick: () => {
    //             handleAlertThresholdChange(50);
    //         },
    //     },
    //     {
    //         label: "60%",
    //         key: 60,
    //         handleClick: () => {
    //             handleAlertThresholdChange(60);
    //         },
    //     },
    //     {
    //         label: "70%",
    //         key: 70,
    //         handleClick: () => {
    //             handleAlertThresholdChange(70);
    //         },
    //     },
    //     {
    //         label: "80%",
    //         key: 80,
    //         handleClick: () => {
    //             handleAlertThresholdChange(80);
    //         },
    //     },
    //     {
    //         label: "90%",
    //         key: 90,
    //         handleClick: () => {
    //             handleAlertThresholdChange(90);
    //         },
    //     },
    //     {
    //         label: "100%",
    //         key: 100,
    //         handleClick: () => {
    //             handleAlertThresholdChange(100);
    //         },
    //     },
    // ];

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography
                level="body-sm"
                component="label"
                endDecorator={
                    <Switch sx={{ ml: 1 }} checked={status} onChange={handleAlertStatusChange} disabled={disabled} />
                }
            >
                Set Alert
            </Typography>

            <Tooltip
                title={
                    disabled
                        ? "Cannot set alert if credits are unlimited"
                        : "Set credit usage alert, you will be notified when you limit will reach 70%, 80%, 90% or 100%"
                }
            >
                <InfoRounded fontSize="small" />
            </Tooltip>

            {/* For later release */}
            {/* <MaterialProvider>
                <CustomDropdown options={options} tooltipTitle="Change view" disabled={!alertStatus}>
                    <Typography
                        level="body-sm"
                        fontSize={"14px"}
                        sx={{
                            border: "1px solid gray",
                            padding: "4px 10px",
                            borderRadius: 1,
                            textTransform: "capitalize",
                        }}
                    >
                        {alertThreshold}%
                    </Typography>
                </CustomDropdown>
            </MaterialProvider> */}
        </Stack>
    );
}

export default AlertToggle;
