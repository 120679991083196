import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CSVReader from "./CSVReader/CSVReader";

import useSearchState from "../hooks/useSearchState";
import BaseModal from "../pages/triggerWorkflow/components/BaseModal";
import {
    selectGeography,
    selectPostalCode,
    selectPostalCodeCSVUrl,
    setValues,
} from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";
import API from "../utils/API";
import handleException from "../utils/sentry";

interface CSVReaderModalType {
    onAdd: (data) => void;
    onClose: (e) => void;
    onUpload: (link: string[]) => void;
}

function CSVReaderModal({ onClose, onAdd, onUpload }: CSVReaderModalType) {
    const extractNumbers = (data) => {
        const numbers: Set<any> = new Set();

        data.forEach((item) => {
            item.forEach((subItem) => {
                const number = parseInt(subItem);
                if (!isNaN(number)) {
                    numbers.add(subItem);
                }
            });
        });

        return Array.from(numbers);
    };

    async function uploadFile(file: File) {
        try {
            const formData = new FormData();
            formData.append("files", file);
            formData.append("projectId", "postalCode");
            const response = await new API().post("upload/files", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (Array.isArray(response)) {
                onUpload(response);
            }
        } catch (error) {
            handleException(error);
            //eslint-disable-next-line no-console
            console.log("Zip code csv upload failed ", error);
        }
    }

    return (
        <BaseModal onClose={onClose}>
            <CSVReader
                onDropFile={uploadFile}
                onSuccess={(response: any) => {
                    const data = response?.data;
                    const numbers = extractNumbers(data);
                    onAdd(numbers);
                }}
            />
        </BaseModal>
    );
}

const StyledBox = styled(Box)(() => ({
    ":hover": {
        border: "1px dashed #0891b2",
    },
}));

export function PostalCode({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectPostalCode);
    const { error: geographyError } = useSelector(selectGeography);
    const { values: postalCodeCSVUrlValues } = useSelector(selectPostalCodeCSVUrl);
    const [openCSVModal, setOpenCSVModal] = useState(false);
    const isPostalCodeAvailable = !!values?.length;

    const commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: 1,
    };

    const textColor = "rgba(0,0,0,0.6)";

    return (
        <>
            <Box {...commonStyle} justifyContent="space-between">
                <StyledBox
                    {...commonStyle}
                    color={geographyError ? "error" : textColor}
                    border="1px dashed"
                    borderColor={geographyError ? "#D32F2F" : textColor}
                    borderRadius="4px"
                    p={0.2}
                    flex={1}
                    onClick={() => setOpenCSVModal(true)}
                    style={{
                        pointerEvents: isPostalCodeAvailable ? "none" : "auto",
                        opacity: isPostalCodeAvailable ? 0.7 : 1,
                    }}
                >
                    <Tooltip title={isPostalCodeAvailable ? "Pin codes uploaded" : "Upload pin codes (CSV)"}>
                        <span>
                            <IconButton size="small">
                                <UploadFileIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Typography color={textColor} fontSize={14}>
                        {isPostalCodeAvailable
                            ? `Uploaded csv of postal codes : ${values.length}`
                            : "Upload csv list of postal codes"}
                    </Typography>
                </StyledBox>

                {isPostalCodeAvailable && (
                    <Box {...commonStyle}>
                        {!!postalCodeCSVUrlValues?.length && (
                            <Tooltip title="Download postal codes">
                                <Link to={postalCodeCSVUrlValues[0]?.value}>
                                    <IconButton>
                                        <DownloadIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        )}
                        <Tooltip title="Remove postal codes">
                            <IconButton
                                onClick={() => {
                                    dispatch(
                                        setValues({
                                            key: id,
                                            value: [],
                                        })
                                    );
                                    dispatch(
                                        setValues({
                                            key: "postalCodeCSVUrl",
                                            value: [],
                                        })
                                    );
                                }}
                            >
                                <CancelIcon color="error" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                {openCSVModal && (
                    <CSVReaderModal
                        onAdd={(postalCodes) => {
                            dispatch(
                                setValues({
                                    key: id,
                                    value: postalCodes.map((value) => ({ value, excluded: false })),
                                })
                            );
                            setOpenCSVModal(false);
                        }}
                        onUpload={(link) => {
                            dispatch(
                                setValues({
                                    key: "postalCodeCSVUrl",
                                    value: link.map((value) => ({ value, excluded: false })),
                                })
                            );
                        }}
                        onClose={() => {
                            setOpenCSVModal(false);
                        }}
                    />
                )}
            </Box>
            {!!geographyError && (
                <Typography color="error" fontSize={12} component="span">
                    {geographyError}
                </Typography>
            )}
        </>
    );
}
