import { styled } from "@mui/material/styles";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export const CustomTabsContainer = styled(Tabs)({
    borderRadius: "0.7rem",
    width: "fit-content",
    backgroundColor: "white",
    // border: "1px solid #0891b2",
    "& .MuiTabs-indicator": {
        display: "none",
    },
});

export const CustomTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    borderRadius: "0.7rem",
    border: 0,
    margin: "0.5rem",
    padding: "0.5rem 1rem",
    [theme.breakpoints.up("sm")]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#000",
    backgroundColor:"white",
    "&:hover": {
        opacity: 1,
        color: "#0891b2", // Changed to cyan-600
    },
    "&.Mui-selected": {
        color: "white",
        backgroundColor: "#0891b2", // Changed to cyan-600
        fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
    },
}));
