import { useSelector } from "react-redux";

import TextLoading from "../../../components/TextLoading";
import { fetchContacts, selectAllContactsTotalCount } from "../../../store/apis/all-contacts/all-contacts.slice";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

export default function FetchLoadingWithData() {
    const isFetchingContacts = useSelector(checkIfLoading(fetchContacts.type));
    const rowsCount = useSelector(selectAllContactsTotalCount);

    if (isFetchingContacts && rowsCount) {
        return <TextLoading text="Loading..." />;
    }

    return null;
}
