import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
    BodyWithSubject,
    ErrorTypes,
    LinkedinSchema,
    OutreachErrorValue,
    OutreachErrors,
    WorkflowStepValue,
} from "./outreach.types";

import {
    BASE_TEMPLATE_ROUND_VALID_TAGS,
    BASE_TEMPLATE_VALID_TAGS,
    MAX_LINKEDIN_CONN_REQ_CHAR_COUNT,
    USER_MAX_LINKEDIN_REQ_CHART_COUNT,
} from "../../../utils/Constants";
import { extractStringsInRoundBrackets, sanitizeTemplateBody } from "../../../utils/helper";

import { RootState } from "@/store";
import { AccountType } from "@/store/reducers/signin/Signin.types";

// Extend dayjs with the customParseFormat plugin
dayjs.extend(customParseFormat);

export function modifyTimestampWithDays(originalTimestamp: string, daysToAdd: number): string {
    // Parse the original timestamp to extract the time part
    const timePart = dayjs(originalTimestamp, "YYYY-MM-DDTHH:mm:ssZ").format("HH:mm:ss");
    const currentTimestamp = dayjs();

    // Get the current timestamp and add the specified number of days
    const newTimestamp = dayjs().add(daysToAdd, "day");

    // If daysToAdd is zero, use the current time instead of the original time part

    // const finalTimePart = daysToAdd === 0 ? dayjs().format("HH:mm:ss") : timePart;

    let finalTimePart = timePart;
    if (daysToAdd === 0) {
        const timePartToday = dayjs(`${currentTimestamp.format("YYYY-MM-DD")}T${timePart}`);
        // If the timePart is in the past for today, use the current time, otherwise use the original timePart
        if (timePartToday.isBefore(currentTimestamp)) {
            finalTimePart = currentTimestamp.format("HH:mm:ss");
        }
    }

    // Extract date part from the new timestamp and combine with the chosen time part
    const resultTimestamp = dayjs(`${newTimestamp.format("YYYY-MM-DD")}T${finalTimePart}`).toISOString();

    return resultTimestamp;
}

export const moveElements = ({
    destination,
    source,
    templatesOrder,
}: {
    templatesOrder: string[];
    source: number;
    destination: number;
}) => {
    const [removed] = templatesOrder.splice(source, 1);

    templatesOrder.splice(destination, 0, removed);
};

export function getFileExtension(fileName: string): string | null {
    // Check if there's a period in the filename and it's not the first character
    const lastIndex = fileName.lastIndexOf(".");
    if (lastIndex > 0) {
        return fileName.substring(lastIndex + 1);
    }
    // Return null if there's no extension
    return null;
}

export function extractTextInCurlyBraces(input: string) {
    // We assume `sanitizeTemplateBody` sanitizes input;
    // its implementation remains unchanged and is necessary for this context.
    const sanitizedInput = sanitizeTemplateBody(input);

    // Regular expression to match text within double curly braces.
    const doubleCurlyRegex = /\{\{([^{}]+)\}\}/g;
    // Regular expression to match text within single curly braces.
    // It's adjusted to avoid matching double curly braces by using negative lookbehind and lookahead.
    const singleCurlyRegex = /(?<!\{)\{([^{}]+)\}(?!\})/g;

    const curlyBracesTexts = [];
    const doubleCurlyBracesText = [];

    // Extracting text from double curly braces
    let match;
    while ((match = doubleCurlyRegex.exec(sanitizedInput)) !== null) {
        doubleCurlyBracesText.push(match[1].trim());
    }

    // Sanitize input again to remove matched double curly braces text,
    // preventing them from being matched by the single curly braces regex.
    const sanitizedInputAfterDouble = sanitizedInput.replace(doubleCurlyRegex, "");

    // Extracting text from single curly braces
    while ((match = singleCurlyRegex.exec(sanitizedInputAfterDouble)) !== null) {
        curlyBracesTexts.push(match[1].trim());
    }

    return {
        curlyBracesTexts,
        doubleCurlyBracesText,
    };
}

/**
 * Checks if the given text contains valid round brace tags according to BASE_TEMPLATE_ROUND_VALID_TAGS
 *
 * @param text - The text to check
 * @returns boolean - Returns true if the text does not contain any invalid round brace tags.
 */
export function isTextWithValidRoundBracesTags(text: string) {
    const roundBracesTags = extractStringsInRoundBrackets(text);
    const invalidTagsWithRoundBrackets = roundBracesTags.filter((val) => BASE_TEMPLATE_ROUND_VALID_TAGS.includes(val));

    return invalidTagsWithRoundBrackets.length === 0;
}

/**
 * Checks if the given text contains valid curly brace tags according to BASE_TEMPLATE_VALID_TAGS
 *
 * @param text - The text to check
 * @returns boolean - Returns true if the text contains only valid tags or one invalid tag. Returns false otherwise.
 */
function isTextWithValidCurlyBracesTags(text: string, EXTRA_TEMPLATE_VALID_TAGS: string[], reduxState: RootState) {
    const curlyBracesTags = extractTextInCurlyBraces(text);
    const isJdTitlePresent = reduxState?.allProjects?.project?.jdTitle;
    const isJdDescriptionPresent = reduxState?.allProjects?.project?.jdDescription;

    const invalidTags = curlyBracesTags.curlyBracesTexts.filter(
        (val) => ![...BASE_TEMPLATE_VALID_TAGS, ...EXTRA_TEMPLATE_VALID_TAGS].includes(val.toLowerCase())
    );

    if (
        !(isJdTitlePresent?.length > 0) &&
        (curlyBracesTags.curlyBracesTexts.includes("jobTitle") || curlyBracesTags.curlyBracesTexts.includes("jobtitle"))
    ) {
        invalidTags.push("jobTitle");
    }

    if (
        !(isJdDescriptionPresent?.length > 0) &&
        (curlyBracesTags.curlyBracesTexts.includes("jobDescription") ||
            curlyBracesTags.curlyBracesTexts.includes("jobdescription"))
    ) {
        invalidTags.push("jobDescription");
    }

    return invalidTags.length === 0 && curlyBracesTags.doubleCurlyBracesText.length <= 1;
}


const OutreachErrorsText = {
    subject: "Subject",
    body: "Body",
    orderingErrors: "Ordering errors",
    connectionReminderMessageBody: "Connection reminder message body",
    inmailFollowupBody: "InMail follow up body",
    inMailSubject: "InMail subject",
    inMailBody: "InMail body",
    inmailFollowupSubject: "InMail follow up subject",
    inmailFollowupReminder: "InMail follow up reminder",
}

// validation functions

export function addError(errors: OutreachErrorValue, key: ErrorTypes, message: string) {
    errors[key] = message;
}

export function removeError(errors: OutreachErrorValue, key: ErrorTypes) {
    delete errors[key];
}

export function validateEmpty(text: string, errors: OutreachErrorValue, type: keyof OutreachErrors) {
    const displayName = OutreachErrorsText[type];
    if (text === "") {
        addError(errors, "EMPTY_ERROR", `${displayName} cannot be empty`);
    } else {
        removeError(errors, "EMPTY_ERROR");
    }
}

export function validateRoundBraces(errors: OutreachErrorValue, isValid: boolean, type: keyof OutreachErrors) {
    if (!isValid) {
        addError(
            errors,
            "ROUND_BRACES_ERROR",
            `Please replace (job role) and (my name) with actual information. Personalization elements can be added in curly parenthesis '{...}'`
        );
    } else {
        removeError(errors, "ROUND_BRACES_ERROR");
    }
}

export function validateCurlyBraces(errors: OutreachErrorValue, isValid: boolean, type: keyof OutreachErrors) {
    if (!isValid) {
        addError(
            errors,
            "CURLY_BRACES_ERROR",
            `Formatting error: Use {name}, {role}, {company}, or {signature} or RB2B related fields or custom field created during bulk upload for personalizing ${type}. You can also use exactly one AI prompt inside double braces {{...}}`
        );
    } else {
        removeError(errors, "CURLY_BRACES_ERROR");
    }
}

export function validateCharacterLimit(errors: OutreachErrorValue, limit: number, isExceeded: boolean) {
    if (isExceeded) {
        addError(
            errors,
            "CHARACTER_LIMIT_ERROR",
            `Character limit exceeded error: You have exceeded the character limit of ${limit} characters.`
        );
    } else {
        removeError(errors, "CHARACTER_LIMIT_ERROR");
    }
}

export function handleSubjectErrors(errors: OutreachErrors) {
    const result: OutreachErrorValue | undefined = errors.subject;
    if (result === undefined) {
        return {} as OutreachErrorValue;
    }

    return result;
}

/**
 * Validate the element object for specific event types and update its errors object accordingly.
 *
 * @param {WorkflowStepValue} element - the element object to be validated
 * @param {AccountType} [accountType] - the type of account (optional)
 */

export function validateElement({
    element,
    accountType,
    EXTRA_TEMPLATE_VALID_TAGS = [],
    reduxState,
}: {
    element: WorkflowStepValue;
    accountType?: AccountType;
    EXTRA_TEMPLATE_VALID_TAGS?: string[];
    reduxState?: RootState;
}) {
    // Directly update the element object within the function
    if (element && element.eventName === "linkedin") {
        const inMailSubject = (element.eventBody as LinkedinSchema).inMailSubject;
        element.errors.inMailSubject = element.errors?.inMailSubject ?? {};
        const inMailSubjectErrors = element.errors.inMailSubject;
        validateEmpty(inMailSubject, inMailSubjectErrors, "inMailSubject");
        validateRoundBraces(inMailSubjectErrors, isTextWithValidRoundBracesTags(inMailSubject), "inMailSubject");
        validateCurlyBraces(
            inMailSubjectErrors,
            isTextWithValidCurlyBracesTags(inMailSubject, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
            "inMailSubject"
        );

        // connection reminder body messages
        if ("inmailFollowupBody" in element.eventBody) {
            const inmailFollowupBody = element.eventBody.inmailFollowupBody;
            element.errors.inmailFollowupBody = element.errors?.inmailFollowupBody ?? {};
            const inmailFollowupBodyErrors = element.errors.inmailFollowupBody;
            const sanitizedInmailFollowupBody = sanitizeTemplateBody(inmailFollowupBody);
            validateEmpty(sanitizedInmailFollowupBody, inmailFollowupBodyErrors, "inmailFollowupBody");
            validateRoundBraces(
                inmailFollowupBodyErrors,
                isTextWithValidRoundBracesTags(sanitizedInmailFollowupBody),
                "inmailFollowupBody"
            );
            validateCurlyBraces(
                inmailFollowupBodyErrors,
                isTextWithValidCurlyBracesTags(sanitizedInmailFollowupBody, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
                "inmailFollowupBody"
            );
        }
        if ("inmailFollowupSubject" in element.eventBody) {
            const inmailFollowupSubject = element.eventBody.inmailFollowupSubject;
            element.errors.inmailFollowupSubject = element.errors?.inmailFollowupSubject ?? {};
            const inmailFollowupSubjectErrors = element.errors.inmailFollowupSubject;
            validateEmpty(inmailFollowupSubject, inmailFollowupSubjectErrors, "inmailFollowupSubject");
            validateRoundBraces(
                inmailFollowupSubjectErrors,
                isTextWithValidRoundBracesTags(inmailFollowupSubject),
                "inmailFollowupSubject"
            );
            validateCurlyBraces(
                inmailFollowupSubjectErrors,
                isTextWithValidCurlyBracesTags(inmailFollowupSubject, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
                "inmailFollowupSubject"
            );
        }
        if ("connectionReminderMessageBody" in element.eventBody) {
            const reminderMessageBody = element.eventBody.connectionReminderMessageBody;
            element.errors.connectionReminderMessageBody = element.errors?.connectionReminderMessageBody ?? {};
            const reminderMessageBodyErrors = element.errors.connectionReminderMessageBody;
            const sanitizedReminderMessageBody = sanitizeTemplateBody(reminderMessageBody);
            validateRoundBraces(
                reminderMessageBodyErrors,
                isTextWithValidRoundBracesTags(sanitizedReminderMessageBody),
                "connectionReminderMessageBody"
            );
            validateCurlyBraces(
                reminderMessageBodyErrors,
                isTextWithValidCurlyBracesTags(sanitizedReminderMessageBody, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
                "connectionReminderMessageBody"
            );
        }

        // inMailBody validation

        const inMailBody = (element.eventBody as LinkedinSchema).inMailBody;
        element.errors.inMailBody = element.errors?.inMailBody ?? {};
        const inMailBodyError = element.errors.inMailBody ?? {};
        const sanitizedInMailBody = sanitizeTemplateBody(inMailBody);
        validateEmpty(sanitizedInMailBody, inMailBodyError as OutreachErrorValue, "inMailBody");
        validateRoundBraces(
            inMailBodyError as OutreachErrorValue,
            isTextWithValidRoundBracesTags(sanitizedInMailBody),
            "inMailBody"
        );
        validateCurlyBraces(
            inMailBodyError as OutreachErrorValue,
            isTextWithValidCurlyBracesTags(sanitizedInMailBody, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
            "inMailBody"
        );
    }
    if (element && (element.eventName === "email" || element.eventName === "follow-up")) {
        const subject = (element.eventBody as BodyWithSubject).subject;
        element.errors.subject = element.errors?.subject ?? {};
        const subjectErrors = element.errors.subject;
        validateEmpty(subject, subjectErrors, "subject");
        validateRoundBraces(subjectErrors, isTextWithValidRoundBracesTags(subject), "subject");
        validateCurlyBraces(
            subjectErrors,
            isTextWithValidCurlyBracesTags(subject, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
            "subject"
        );
    }

    // check reminder messageErrors

    // Validate Body
    const body = element.eventBody.body;
    const bodyErrors = element.errors.body;
    const sanitizedBody = sanitizeTemplateBody(body);
    validateEmpty(sanitizedBody, bodyErrors, "body");
    validateRoundBraces(bodyErrors, isTextWithValidRoundBracesTags(sanitizedBody), "body");
    validateCurlyBraces(
        bodyErrors,
        isTextWithValidCurlyBracesTags(sanitizedBody, EXTRA_TEMPLATE_VALID_TAGS, reduxState),
        "body"
    );
    // Additional condition for SMS or likedin events
    if (element?.eventName === "SMS" || element?.eventName === "linkedin") {
        const charLimit = accountType
            ? USER_MAX_LINKEDIN_REQ_CHART_COUNT[accountType]
            : MAX_LINKEDIN_CONN_REQ_CHAR_COUNT;
        validateCharacterLimit(bodyErrors, charLimit, sanitizedBody.length > charLimit);
    }
}

export const SOURCE_TEXT_MAP = {
    SendGrid:
        "Your email outreach will be executed via SendGrid. EasySource does not bill you separately for this premium service",
    SES: "Your email outreach will be executed via AWS SES, Amazon’s email sending service. EasySource does not bill you separately for this premium service",
    Gmail: "Emails will be sent from your gmail account when you click 'Schedule' here",
    Outlook: "Emails will be sent from your Outlook account when you click 'Schedule' here",
};
export const SOURCE_TEXT_MAP_EASYGROWTH = {
    SendGrid:
        "Your email outreach will be executed via SendGrid. EasyGrowth does not bill you separately for this premium service",
    SES: "Your email outreach will be executed via AWS SES, Amazon’s email sending service. EasyGrowth does not bill you separately for this premium service",
    Gmail: "Emails will be sent from your gmail account when you click 'Schedule' here",
    Outlook: "Emails will be sent from your Outlook account when you click 'Schedule' here",
};
