import AddIcon from "@mui/icons-material/Add";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CachedIcon from "@mui/icons-material/Cached";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PublicIcon from "@mui/icons-material/Public";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

import {
    CriteriaType,
    ICriteria,
    Variant,
    addCriteria,
    addSearchCriteria,
    changeCriteriaType,
    checkErrorWhileFetchingVettingCriteria,
    criteriaTypeValues,
    deleteCriteria,
    editAppendText,
    editCriteria,
    fetchVettingCriteria,
    selectToggleAppendText,
    selectVettingCriteria,
    setGlobalCriteria,
    setPointTag,
    toggleAppendText,
} from "./vettingCriteria.slice";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
// import NavigateBackButton from "../../../../atoms/NavigateBackButton/NavigateBackButton";
import { ErrorPlaceholder } from "../../../../components/ErrorPlaceholder";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { ADD_TO_PROJECT } from "../../../../utils/Constants";
import { editProject, selectCurrProject } from "../../../allProjects/index.reducer";
import { checkOutreachVersion, isSuperAdminUser } from "../../../Signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { MUISelect } from "../../../triggerWorkflow/components/PersonalizedInputsModal";
import { getShowByProjectFilters, selectProjectFilters } from "../../index.reducer";
import { IStageFilters } from "../../project.types";

const MAX_CONTENT_HEIGHT = 600;

function CustomTableRow(props: ICriteria) {
    const { id, tag, text, type, appendText, global } = props;
    const dispatch = useDispatch();
    const isAppendTextEnabled = useSelector(selectToggleAppendText);
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as CriteriaType;
        dispatch(changeCriteriaType({ id, value }));
    };

    const toggleGlobalCriteria = () => {
        dispatch(
            setGlobalCriteria({
                id,
                value: !global,
            })
        );
    };

    return (
        <TableRow>
            <TableCell align="center">
                <Stack direction={"row"} spacing={2}>
                    <IconButton
                        color="error"
                        sx={{
                            height: "fit-content",
                            width: "fit-content",
                            alignSelf: "center",
                        }}
                        onClick={() => dispatch(deleteCriteria(id))}
                    >
                        <RemoveIcon fontSize="medium" />
                    </IconButton>
                    <MUISelect
                        variant="filled"
                        fullWidth
                        options={criteriaTypeValues}
                        values={type}
                        modalVariant={"FILTER"}
                        handleChange={handleChange}
                        sx={{
                            "& .MuiSelect-select": {
                                paddingLeft: "0.8rem",
                            },
                        }}
                    />
                </Stack>
            </TableCell>
            <TableCell component="th" scope="row" sx={{ padding: "15px 0" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        "& .hover-icon": {
                            visibility: "hidden", // Initially hidden
                        },
                        "& .Mui-focused .hover-icon": {
                            visibility: "visible", // Icon becomes visible when the input is focused
                        },
                    }}
                >
                    <OutlinedInput
                        multiline={true}
                        type="text"
                        size="small"
                        fullWidth
                        value={text}
                        inputProps={{
                            sx: {
                                fontSize: "14px",
                                "&::placeholder": {
                                    color: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        }}
                        sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "green",
                            },
                            backgroundColor: global ? "rgba(0, 0, 0, 0.1)" : "transparent",
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(e) =>
                            dispatch(
                                editCriteria({
                                    id,
                                    text: e.target.value,
                                })
                            )
                        }
                        endAdornment={
                            <InputAdornment
                                position="end"
                                sx={{
                                    position: "relative",
                                    "&:hover .hover-icon": {
                                        visibility: "visible", // Icon becomes visible when the adornment is hovered
                                    },
                                }}
                            >
                                <Tooltip
                                    title={global ? "Remove from global criteria" : "Set to global criteria"}
                                    arrow
                                >
                                    <IconButton
                                        className="hover-icon"
                                        sx={{
                                            color: "green",
                                            visibility: "hidden", // Initially hidden
                                        }}
                                        onClick={toggleGlobalCriteria}
                                    >
                                        <PublicIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }
                    />
                </Box>
            </TableCell>
            {isAppendTextEnabled && (
                <TableCell component="th" scope="row" sx={{ padding: "15px" }}>
                    <TextField
                        multiline={true}
                        type="text"
                        size="small"
                        fullWidth
                        value={appendText}
                        label={`${type} Extra text`}
                        placeholder="Enter..."
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(e) =>
                            dispatch(
                                editAppendText({
                                    id,
                                    text: e.target.value,
                                })
                            )
                        }
                    />
                </TableCell>
            )}
            <TableCell align="center">
                <Tooltip
                    title={
                        tag === "MUST"
                            ? "This is set as a must have criterion"
                            : "This is set as a good to have criterion"
                    }
                    arrow
                >
                    <Switch
                        checked={tag === "MUST"}
                        onChange={(e) => {
                            if (tag === "MUST") {
                                dispatch(
                                    setPointTag({
                                        id,
                                        value: "GOOD",
                                    })
                                );
                            } else {
                                dispatch(
                                    setPointTag({
                                        id,
                                        value: "MUST",
                                    })
                                );
                            }
                        }}
                    />
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

function SearchCriteriaRow(props: ICriteria) {
    const {
        id,
        tag,
        text,
        type,
        suggestion,
        variant,
        appendText,
        currentProjectFilters,
        setCurrentProjectFilters,
        global,
    } = props;
    const projectFilters: any = useSelector(selectProjectFilters);
    const isAppendTextEnabled = useSelector(selectToggleAppendText);

    const dispatch = useDispatch();
    const filterChecked = currentProjectFilters && currentProjectFilters[`vettingQ.${id}`];
    const disqualifiedCheckedFilter = currentProjectFilters && currentProjectFilters[`vettingDQ.${id}`];

    const handleSetFilters = (checked: boolean, criteria: string) => {
        const criteriaFilter = criteria === "qualified" ? "vettingQ" : "vettingDQ";
        setCurrentProjectFilters({
            ...currentProjectFilters,
            [`${criteriaFilter}.${id}`]: checked,
        });
    };

    const handleChangeType = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as CriteriaType;
        dispatch(changeCriteriaType({ id, value }));
    };

    const handleChangeText = (value: string) => {
        dispatch(
            editCriteria({
                id,
                text: value,
            })
        );
    };

    const toggleGlobalCriteria = () => {
        dispatch(
            setGlobalCriteria({
                id,
                value: !global,
            })
        );
    };

    return (
        <TableRow sx={{ verticalAlign: "top" }}>
            <TableCell align="center">
                <Stack direction={"row"} spacing={2}>
                    {variant !== "FILTER" && (
                        <IconButton
                            color="error"
                            sx={{
                                height: "fit-content",
                                width: "fit-content",
                                alignSelf: "center",
                            }}
                            onClick={() => dispatch(deleteCriteria(id))}
                        >
                            <RemoveIcon fontSize="medium" />
                        </IconButton>
                    )}
                    <MUISelect
                        variant="filled"
                        fullWidth
                        disabled={variant === "FILTER"}
                        options={criteriaTypeValues}
                        modalVariant={"FILTER"}
                        values={type}
                        handleChange={handleChangeType}
                        sx={{
                            "& .MuiSelect-select": {
                                paddingLeft: "0.8rem",
                            },
                        }}
                    />
                </Stack>
            </TableCell>
            <TableCell sx={{ padding: "8px 0" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        "& .hover-icon": {
                            visibility: "hidden", // Initially hidden
                        },
                        "& .Mui-focused .hover-icon": {
                            visibility: "visible", // Icon becomes visible when the input is focused
                        },
                    }}
                >
                    <OutlinedInput
                        multiline={true}
                        type="text"
                        size="small"
                        disabled={variant === "FILTER"}
                        fullWidth
                        value={text}
                        inputProps={{
                            sx: {
                                fontSize: "14px",
                                "&::placeholder": {
                                    color: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        }}
                        sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "green",
                            },
                            backgroundColor: global ? "rgba(0, 0, 0, 0.1)" : "transparent",
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(e) => handleChangeText(e.target.value)}
                        endAdornment={
                            <InputAdornment
                                position="end"
                                sx={{
                                    position: "relative",
                                    "&:hover .hover-icon": {
                                        visibility: "visible", // Icon becomes visible when the adornment is hovered
                                    },
                                }}
                            >
                                <Tooltip
                                    title={global ? "Remove from global criteria" : "Set to global criteria"}
                                    arrow
                                >
                                    <IconButton
                                        className="hover-icon"
                                        sx={{
                                            color: "green",
                                            visibility: "hidden", // Initially hidden
                                        }}
                                        onClick={toggleGlobalCriteria}
                                    >
                                        <PublicIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }
                    />
                </Box>

                {suggestion && (
                    <Button
                        onClick={() => handleChangeText(suggestion)}
                        sx={(theme) => ({
                            border: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: "0.25rem 0.5rem",
                            fontSize: 12,
                            fontWeight: 400,
                            fontStyle: "italic",
                            textAlign: "left",
                            marginTop: 1,
                            color: theme.palette.grey[600],
                            "& .MuiButton-startIcon": {
                                marginRight: 0.5,
                            },
                        })}
                        startIcon={
                            <span
                                style={{
                                    fontSize: "0.75rem",
                                    fontStyle: "normal",
                                }}
                            >
                                💡
                            </span>
                        }
                    >
                        {suggestion}
                    </Button>
                )}
            </TableCell>
            {isAppendTextEnabled && variant === "GENERATE" && (
                <TableCell component="th" scope="row" sx={{ padding: "15px" }}>
                    <TextField
                        multiline={true}
                        type="text"
                        size="small"
                        fullWidth
                        value={appendText}
                        label={`${type} Extra text`}
                        placeholder="Enter..."
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(e) =>
                            dispatch(
                                editAppendText({
                                    id,
                                    text: e.target.value,
                                })
                            )
                        }
                    />
                </TableCell>
            )}

            <TableCell align="center">
                <Tooltip
                    title={
                        tag === "MUST"
                            ? "This is set as a must have criterion"
                            : "This is set as a good to have criterion"
                    }
                    arrow
                >
                    <div>
                        <Switch
                            disabled={variant === "FILTER"}
                            checked={tag === "MUST"}
                            onChange={(e) => {
                                if (tag === "MUST") {
                                    dispatch(
                                        setPointTag({
                                            id,
                                            value: "GOOD",
                                        })
                                    );
                                } else {
                                    dispatch(
                                        setPointTag({
                                            id,
                                            value: "MUST",
                                        })
                                    );
                                }
                            }}
                        />
                    </div>
                </Tooltip>
            </TableCell>

            {variant === "FILTER" && (
                <>
                    <TableCell align="center">
                        <Checkbox
                            onChange={(e: any) => handleSetFilters(e.target.checked, "qualified")}
                            checked={filterChecked}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <Checkbox
                            onChange={(e: any) => handleSetFilters(e.target.checked, "disqualified")}
                            checked={disqualifiedCheckedFilter}
                        />
                    </TableCell>
                </>
            )}
        </TableRow>
    );
}

type CriteriaTableProps = {
    variant: Variant;
    height: number;
    currentProjectFilters?: IStageFilters;
    setCurrentProjectFilters?: (filters: IStageFilters) => void;
};

const CriteriaTable = ({ variant, height, currentProjectFilters, setCurrentProjectFilters }: CriteriaTableProps) => {
    const vettingCriteria = useSelector(selectVettingCriteria);
    const isAppendTextEnabled = useSelector(selectToggleAppendText);

    return (
        <TableContainer
            sx={{
                maxHeight: `${height - 130}px`,
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {variant === "SEARCH" ? (
                            <>
                                <TableCell width={"20%"} align="center">
                                    Criteria type
                                </TableCell>
                                <TableCell width={"50%"}>Criteria description</TableCell>
                            </>
                        ) : (
                            <>
                                <TableCell width={"20%"} align="center">
                                    Category
                                </TableCell>
                                <TableCell width={"50%"}>Criteria</TableCell>
                            </>
                        )}
                        {isAppendTextEnabled && variant !== "FILTER" && (
                            <TableCell width={"20%"} align="center">
                                Append Text
                            </TableCell>
                        )}

                        <TableCell width={"15%"} align="center">
                            {variant !== "FILTER" ? "Set to must have" : "Check good to have"}
                        </TableCell>

                        {/* <TableCell width={"20%"} align="center">
                            Good To Have
                        </TableCell> */}
                        {variant === "FILTER" && (
                            <>
                                <TableCell align="center" width={"10%"}>
                                    Qualified
                                </TableCell>
                                <TableCell align="center" width={"12%"}>
                                    Not Qualified
                                </TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody id="vetting-criteria-container">
                    {vettingCriteria.length > 0
                        ? vettingCriteria.map((item) => {
                              return variant === "NORMAL" ? (
                                  <CustomTableRow
                                      key={item.id}
                                      variant={variant}
                                      {...item}
                                      currentProjectFilters={currentProjectFilters}
                                      setCurrentProjectFilters={setCurrentProjectFilters}
                                  />
                              ) : (
                                  <SearchCriteriaRow
                                      key={item.id}
                                      variant={variant}
                                      {...item}
                                      currentProjectFilters={currentProjectFilters}
                                      setCurrentProjectFilters={setCurrentProjectFilters}
                                  />
                              );
                          })
                        : variant !== "SEARCH" && (
                              <TableRow>
                                  <TableCell
                                      colSpan={5}
                                      align="center"
                                      sx={{ padding: "15px 0", borderBottom: "none", borderTop: "none" }}
                                  >
                                      No criteria added or saved yet
                                  </TableCell>
                              </TableRow>
                          )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

function Loader({ children }: { children: React.ReactNode }) {
    return (
        <Stack sx={{ height: "70%" }} alignItems="center" justifyContent="center">
            {children}
        </Stack>
    );
}

type VettingCriteriaProps = {
    variant: Variant;
    height: number;
    onSuccess?: () => void;
    handleGenerateInsights?: () => void;
    checkIds?: boolean;
    currentProjectFilters?: any;
    setCurrentProjectFilters?: any;
};

export function VettingCriteria(props: VettingCriteriaProps) {
    const {
        variant,
        height,
        onSuccess,
        handleGenerateInsights,
        checkIds,
        currentProjectFilters,
        setCurrentProjectFilters,
    } = props;
    const isSuperAdmin = useSelector(isSuperAdminUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectData = useSelector(selectCurrProject);
    const isEditingProject = useSelector(checkIfLoading(editProject.type));
    const isFetching = useSelector(checkIfLoading(fetchVettingCriteria.type));
    const isGeneratingInsights = useSelector(checkIfLoading("GENERATE_INSIGHTS"));
    const isAddingToProject = useSelector(checkIfLoading(ADD_TO_PROJECT));
    const isLoading = isFetching || isAddingToProject;
    const projectFilters: IStageFilters = useSelector(selectProjectFilters);
    const isAddingFilters = useSelector(checkIfLoading(getShowByProjectFilters.type));
    const { _id } = projectData || {};
    const isBlendSearchScreen = variant === "SEARCH";

    const isErrorWhileFetchingVettingCriteria = useSelector(checkErrorWhileFetchingVettingCriteria);
    const isOutreachV2 = useSelector(checkOutreachVersion) === "v2";

    const handleEditProject = (onSuccess = () => {}) => {
        dispatch(
            editProject({
                _id,
                onSuccess,
                action: editProject.type,
                excludeVettingCriteria: false,
            })
        );
    };
    const handleEditandGenerate = (handleGenerateInsights = () => {}) => {
        if (_id) {
            dispatch(
                editProject({
                    _id,
                    handleGenerateInsights,
                    action: "GENERATE_INSIGHTS",
                    excludeVettingCriteria: false,
                })
            );
        }
    };

    const handleApplyFilters = (onSuccess = () => {}) => {
        if (_id) {
            //   dispatch(
            // setFilters({
            //     ...currentProjectFilters,
            // })
            // );
            dispatch(
                getShowByProjectFilters({
                    projectId: String(_id),
                    onSuccess,
                    projectFilters: currentProjectFilters,
                    action: getShowByProjectFilters.type,
                })
            );
        }
    };
    const handleResetFilters = (onSuccess = () => {}) => {
        if (_id) {
            const updatedProjectFilters = { ...projectFilters };
            Object.keys(updatedProjectFilters).forEach((key) => {
                if (key.startsWith("vettingQ") || key.startsWith("vettingDQ")) {
                    updatedProjectFilters[key] = false;
                }
            });
            setCurrentProjectFilters(updatedProjectFilters);
            dispatch(
                getShowByProjectFilters({
                    projectId: String(_id),
                    onSuccess,
                    projectFilters: updatedProjectFilters,
                    action: getShowByProjectFilters.type,
                })
            );
        }
    };

    useEffect(() => {
        if (_id) {
            dispatch(fetchVettingCriteria({ id: _id }));
        }
    }, [_id]);

    useEffect(() => {
        if (isBlendSearchScreen && !isFetching) {
            dispatch(addSearchCriteria());
        }
    }, [variant, isFetching]);

    const errorComponent = (
        <ErrorPlaceholder
            reload={() => {
                if (_id) {
                    dispatch(fetchVettingCriteria({ id: _id }));
                }
            }}
            containerProps={{
                minHeight: "50vh",
                justifyContent: "center",
            }}
        >
            <CachedIcon fontSize="small" />
        </ErrorPlaceholder>
    );

    return (
        <Stack
            position="relative"
            maxHeight={`${height}px`}
            // overflow={isBlendSearchScreen ? "auto" : undefined}
            sx={{
                overflowX: "auto",
            }}
        >
            <Typography fontSize={20} fontWeight={500} color="#334d6e" >
                {variant !== "NORMAL" ? "AI screening" : "Edit vetting"} criteria
            </Typography>
            {isBlendSearchScreen ? (
                <Typography fontSize={14} color="rgba(0, 0, 0, 0.6)" fontStyle="italic" marginBottom={2}>
                    EasySource will add and screen the best candidates every 24-48 hours based on AI criteria set below.
                    Additional factors such as quality of current tenure and ideal next step in career trajectory are
                    also considered in screening.
                </Typography>
            ) : (
                (variant === "FILTER" || variant === "GENERATE") && (
                    <Typography fontSize={12} color="rgba(0, 0, 0, 0.6)" fontStyle="italic" marginBottom={2}>
                        {" "}
                        {variant === "GENERATE" ? "Screen" : "Filter"} candidates using AI criteria
                    </Typography>
                )
            )}
            {isErrorWhileFetchingVettingCriteria && !isBlendSearchScreen ? (
                errorComponent
            ) : isLoading ? (
                <Loader>
                    {variant === "SEARCH" ? (
                        <TypeAnimation
                            sequence={[
                                "Analyzing your input filters...",
                                1000,
                                "Building smart screening criteria using AI...",
                            ]}
                            speed={50}
                            repeat={Infinity}
                            style={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#0891b2",
                            }}
                        />
                    ) : (
                        <Stack alignItems="center" justifyContent="center" height="100%" margin={5}>
                            <CircularProgress size={30} />
                        </Stack>
                    )}
                </Loader>
            ) : (
                <>
                    <CriteriaTable
                        height={isBlendSearchScreen ? height - 30 : height}
                        variant={variant}
                        currentProjectFilters={currentProjectFilters}
                        setCurrentProjectFilters={setCurrentProjectFilters}
                    />
                    <Stack
                        alignItems="center"
                        direction="row"
                        pt={1}
                        sx={{
                            position: "relative",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "#fff",
                            padding: "8px 5px",
                        }}
                        justifyContent="space-between"
                    >
                        <div style={{ flexDirection: "row" }}>
                            {variant !== "FILTER" && (
                                <Button
                                    style={
                                        variant === "GENERATE"
                                            ? { borderRadius: "5px", minWidth: 150, padding: 2 }
                                            : { minWidth: 40, padding: 2 }
                                    }
                                    variant={"contained"}
                                    sx={{
                                        height: 40,
                                        width: "fit-content",
                                    }}
                                    onClick={() => dispatch(addCriteria())}
                                >
                                    <AddIcon /> {variant === "GENERATE" && "Add Criteria  "}
                                </Button>
                            )}
                            {variant === "GENERATE" && (
                                <Link
                                    sx={{
                                        marginLeft: "20px",
                                    }}
                                    href="https://docs.hirequotient.com/en/articles/9562012-ai-screening-criteria-best-practices"
                                    target="_blank"
                                    variant="body2"
                                >
                                    Read best practices <ArrowOutwardIcon fontSize="inherit" />
                                </Link>
                            )}
                        </div>
                        {isSuperAdmin && (
                            <IconButton
                                sx={(theme) => ({
                                    color: theme.palette.common.white,
                                })}
                                onClick={() => dispatch(toggleAppendText())}
                            >
                                <VisibilityOffRoundedIcon />
                            </IconButton>
                        )}
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                            {isBlendSearchScreen && !isOutreachV2 && (
                                <>
                                    {/* <NavigateBackButton /> */}
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            handleEditProject();
                                            navigate(`/projects/${_id}?fromSearch=true`);
                                        }}
                                    >
                                        Skip and go to dashboard
                                    </Button>
                                </>
                            )}

                            {variant === "FILTER" ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        disabled={isAddingFilters}
                                        onClick={() => handleApplyFilters(onSuccess)}
                                    >
                                        <ButtonTextWithLoading isLoading={isAddingFilters} text={"Apply Filters"} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        disabled={isAddingFilters}
                                        onClick={() => handleResetFilters(onSuccess)}
                                    >
                                        <ButtonTextWithLoading isLoading={isAddingFilters} text={"Reset"} />
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {(isBlendSearchScreen || isSuperAdmin) && (
                                        <Button
                                            style={{ borderRadius: "5px" }}
                                            variant="contained"
                                            disabled={isEditingProject}
                                            onClick={() => {
                                                if (isBlendSearchScreen && isOutreachV2) {
                                                    handleEditandGenerate(() => {
                                                        navigate(`/projects/${_id}?fromSearch=true`);
                                                        handleGenerateInsights?.();
                                                    });
                                                } else {
                                                    handleEditProject(onSuccess);
                                                }
                                            }}
                                            endIcon={
                                                isBlendSearchScreen ? (
                                                    <NavigateNextIcon fontSize="inherit" />
                                                ) : undefined
                                            }
                                        >
                                            <ButtonTextWithLoading
                                                isLoading={isEditingProject}
                                                text={
                                                    isBlendSearchScreen
                                                        ? isOutreachV2
                                                            ? "Go to project"
                                                            : "Set outreach templates"
                                                        : "Save"
                                                }
                                            />
                                        </Button>
                                    )}
                                    {/* {variant === "GENERATE" && ( */}

                                    {!isBlendSearchScreen && (
                                        <Button
                                            style={{ borderRadius: "5px" }}
                                            variant="contained"
                                            onClick={() => handleEditandGenerate(handleGenerateInsights)}
                                            disabled={isGeneratingInsights}
                                        >
                                            <ButtonTextWithLoading isLoading={isGeneratingInsights} text="Save & Run" />
                                        </Button>
                                    )}

                                    {/* )} */}
                                </>
                            )}
                        </Stack>
                    </Stack>
                </>
            )}
        </Stack>
    );
}

export default function VettingCriteriaModal({
    close,
    variant = "NORMAL",
    handleGenerateInsights,
    checkIds,
    projectFilters,
    setProjectFilters,
}: {
    close: () => void;
    variant?: Variant;
    handleGenerateInsights?: () => void;
    checkIds?: boolean;
    projectFilters?: IStageFilters;
    setProjectFilters?: (filters: IStageFilters) => void;
}) {
    const isAppendTextEnabled = useSelector(selectToggleAppendText);

    const closeModal = () => {
        close();
    };

    return (
        <BaseModal
            onClose={closeModal}
            overlayStyles={{
                padding: "1rem 1.5rem",
                maxWidth: "70vw",
                width: isAppendTextEnabled ? "70vw" : "60vw",
            }}
        >
            <VettingCriteria
                variant={variant}
                height={MAX_CONTENT_HEIGHT}
                onSuccess={close}
                handleGenerateInsights={handleGenerateInsights}
                checkIds={checkIds}
                currentProjectFilters={projectFilters}
                setCurrentProjectFilters={setProjectFilters}
            />
        </BaseModal>
    );
}
