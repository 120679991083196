import { Autocomplete, Box, Button, CircularProgress, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Option } from "../../../common";
import { JoyProvider } from "../../../components/JoyProvider";
import { useAppDispatch } from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { getAllProjectsList, selectAllProjects } from "../../allProjects/index.reducer";
import { IProject } from "../../project/project.types";
import { editMember, selectMembers } from "../manageTeams.reducers";

export function SelectAccessProjects() {
    const { id = "" } = useParams<{ id: string }>();
    const projectList: IProject[] = useSelector(selectAllProjects);
    const options: Option[] = projectList.map((i) => ({ value: String(i._id), label: i.name }));
    const members = useSelector(selectMembers);
    const navigate = useNavigate();
    const [selectedProjects, setSelectedProjects] = useState<Option[]>([]);
    const isFetching = useSelector(checkIfLoading(getAllProjectsList.type));
    const dispatch = useAppDispatch();
    const isSubmitting = useSelector(checkIfLoading(editMember.type));
    const isLoading = isSubmitting || isFetching;

    useEffect(() => {
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    const accessAllowedProjects = useMemo(() => {
        const member = members.find((i) => i._id === Number(id));
        return member ? member.accessProjects : [];
    }, [members, id]);

    useEffect(() => {
        if (!projectList) return;
        const values = projectList
            .filter((i) => accessAllowedProjects?.includes(Number(i._id)))
            .map((i) => ({ value: String(i._id), label: i.name }));
        setSelectedProjects([...values]);
    }, [accessAllowedProjects]);

    const handleClose = () => {
        navigate(-1);
    };

    const handleSave = () => {
        dispatch(
            editMember({
                userId: Number(id),
                accessProjects: selectedProjects.map((i) => Number(i.value)),
                successCallback: handleClose,
            })
        );
    };

    return (
        <JoyProvider>
            <Modal open={true} onClose={handleClose} sx={{ backgroundColor: "white" }}>
                <ModalDialog size="sm" variant="plain" sx={{ width: 500 }}>
                    <ModalClose />
                    <Typography level="title-lg">Select Projects</Typography>
                    <Autocomplete
                        disabled={isLoading}
                        multiple
                        limitTags={2}
                        placeholder="Select one or more projects"
                        value={selectedProjects}
                        onChange={(e, value) => {
                            setSelectedProjects(value);
                        }}
                        sx={{
                            width: "100%",
                            borderColor: "#0891B2",
                            "& .MuiCircularProgress-root": {
                                color: "#0891B2",
                            },
                            "&:hover": {
                                borderColor: "#0891B2",
                            },
                            "&:focus": {
                                borderColor: "#0891B2",
                            },
                            "&:hover .MuiCircularProgress-root": {
                                color: "#0891B2",
                            },
                            "&:focus .MuiCircularProgress-root": {
                                color: "#0891B2",
                            },
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        options={options}
                        loading={isFetching}
                        endDecorator={
                            isFetching ? (
                                <Box sx={{ color: "#0891B2" }}>
                                    <CircularProgress
                                        size="sm"
                                        sx={{
                                            color: "#0891B2",
                                            "& .MuiCircularProgress-root": {
                                                color: "#0891B2",
                                            },
                                        }}
                                    />
                                </Box>
                            ) : null
                        }
                    />

                    <Button
                        size="sm"
                        sx={{
                            alignSelf: "flex-end",
                            backgroundColor: "#0891B2",
                            "&:hover": { backgroundColor: "#0891B2" },
                        }}
                        disabled={isLoading}
                        loading={isSubmitting}
                        onClick={handleSave}
                    >
                        Submit
                    </Button>
                </ModalDialog>
            </Modal>
        </JoyProvider>
    );
}
