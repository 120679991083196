import * as PopoverPrimitive from "@radix-ui/react-popover";
import { forwardRef, useCallback } from "react";

import useMounted from "@/hooks/useMounted";
import { cn } from "@/lib/utils";

export interface PopoverProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root> {
    id?: string;
    trigger?: React.ReactNode;
    children?: React.ReactNode;
    align?: "start" | "center" | "end";
    anchorEl?: Element | null;
    anchorOrigin?: {
        vertical: "top" | "center" | "bottom";
        horizontal: "left" | "center" | "right";
    };
    transformOrigin?: {
        vertical: "top" | "center" | "bottom";
        horizontal: "left" | "center" | "right";
    };
    elevation?: number;
    sideOffset?: number;
    className?: string;
    contentClassName?: string;
    open?: boolean;
    onClose?: () => void;
    transitionDuration?: number | { enter?: number; exit?: number };
    keepMounted?: boolean;
}

const defaultProps = {
    align: "center" as const,
    sideOffset: 4,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    elevation: 8,
    transitionDuration: 225,
};

const transforms: Record<string, string> = {
    "top-left": "origin-bottom-left",
    "top-center": "origin-bottom",
    "top-right": "origin-bottom-right",
    "center-left": "origin-center-left",
    "center-center": "origin-center",
    "center-right": "origin-center-right",
    "bottom-left": "origin-top-left",
    "bottom-center": "origin-top",
    "bottom-right": "origin-top-right",
};

const Popover = forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
    const {
        id,
        children,
        align,
        sideOffset,
        contentClassName,
        className,
        open,
        anchorEl,
        anchorOrigin,
        transformOrigin,
        elevation,
        onClose,
        transitionDuration,
        keepMounted,
        ...other
    } = { ...defaultProps, ...props };

    const mounted = useMounted();

    const handleClickOutside = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const getTransformOrigin = () => {
        const key = `${transformOrigin?.vertical}-${transformOrigin?.horizontal}`;
        return transforms[key] || "origin-center";
    };

    if (!mounted && !keepMounted) return null;

    return (
        <PopoverPrimitive.Root {...other} open={open} onOpenChange={onClose} modal={false}>
            <PopoverPrimitive.Anchor>
                <PopoverPrimitive.Portal>
                    <PopoverPrimitive.Content
                        ref={ref}
                        align={align}
                        id={id}
                        sideOffset={sideOffset}
                        onEscapeKeyDown={handleClickOutside}
                        onPointerDownOutside={handleClickOutside}
                        className={cn(
                            "z-50 min-w-[200px] rounded-md border bg-popover  p-2 text-popover-foreground shadow-md outline-none",
                            `shadow-elevation-${elevation}`,

                            getTransformOrigin(),
                            "data-[state=open]:animate-in data-[state=closed]:animate-out",
                            "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                            "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
                            "data-[side=bottom]:slide-in-from-top-2",
                            "data-[side=left]:slide-in-from-right-2",
                            "data-[side=right]:slide-in-from-left-2",
                            "data-[side=top]:slide-in-from-bottom-2",
                            contentClassName,
                            className
                        )}
                        style={
                            {
                                "--transition-duration": `${typeof transitionDuration === "number" ? transitionDuration : transitionDuration?.enter || 225}ms`,
                            } as React.CSSProperties
                        }
                    >
                        {children}
                    </PopoverPrimitive.Content>
                </PopoverPrimitive.Portal>
            </PopoverPrimitive.Anchor>
        </PopoverPrimitive.Root>
    );
});

Popover.displayName = "Popover";

export { Popover };
