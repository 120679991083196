import { HTMLAttributes, LegacyRef } from "react";

import useIntersectionObserver from "@/hooks/useIntersectionObserver";

interface IntersectionObserverProps extends HTMLAttributes<HTMLDivElement> {
    delay?: number;
    options?: IntersectionObserverInit;
    callback?: (entry: IntersectionObserverEntry) => void;
}

function IntersectionObserverWrapper(props: IntersectionObserverProps) {
    const { delay = 0, options, callback, children, ...rest } = props;
    const [targetRef, isVisible] = useIntersectionObserver({ delay, ...options }, callback);

    return (
        <div ref={targetRef as LegacyRef<HTMLDivElement>} {...rest}>
            {isVisible && children}
        </div>
    );
}

export default IntersectionObserverWrapper;
