import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import { MouseEvent, ReactNode, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { checkIntercomEnabled } from "@/store/reducers/signin/Signin.reducer";
import { useChatbotContext } from "../Context";

interface LauncherButtonProps {
    defaultOpen?: boolean;
    children: ReactNode;
}

function LauncherButton(props: LauncherButtonProps) {
    const { defaultOpen, children } = props;
    const { setAnchorEl } = useChatbotContext();
    const ref = useRef(null);
    const intercomEnabled = useSelector(checkIntercomEnabled);

    useEffect(() => {
        if (defaultOpen) {
            setAnchorEl(ref.current);
        }
    }, [ref, defaultOpen]);

    const fabStyle = {
        position: "absolute",
        bottom: 24,
        left: intercomEnabled ? 80 : 16,
        zIndex: 9,
    };

    return (
        <Zoom in={true} timeout={500} unmountOnExit>
            <Fab
                ref={ref}
                sx={fabStyle}
                variant="extended"
                onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
            >
                <span style={{ textTransform: "capitalize" }}>{children}</span>
            </Fab>
        </Zoom>
    );
}

export default LauncherButton;
