import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect, { type ActionMeta, type SingleValue } from "react-select";

import style from "../../../pages/project/components/selectWorkflowModal/selectWorkflowModal.module.scss";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { changeProject, fetchProjects, selectProject, selectProjects } from "@/store/reducers/workflow/workflow.reducer";
import { Option } from "../types";

export default function SelectProject() {
    const allProjects = useSelector(selectProjects);
    const [projectInput, setProjectInput] = useState("");
    const dispatch = useDispatch();
    const project = useSelector(selectProject);
    const isLoading = useSelector(checkIfLoading(fetchProjects.type));

    useEffect(() => {
        dispatch(fetchProjects({ action: fetchProjects.type }));
    }, []);

    const handleChange = (newValue: SingleValue<Option>, { action }: ActionMeta<Option>) => {
        if (action === "select-option") {
            dispatch(changeProject(newValue));
        }

        if (action === "clear" || action === "remove-value") {
            dispatch(changeProject({ label: "", value: "" }));
        }
    };
    return (
        <ReactSelect
            options={allProjects}
            inputValue={projectInput}
            isSearchable={true}
            isClearable
            onChange={handleChange}
            classNames={{
                container: (_) => style["react-select-container"],
                menu: (_) => style["react-select-menu"],
            }}
            classNamePrefix="react-select"
            onInputChange={(newValue) => setProjectInput(newValue)}
            placeholder="Select Project"
            value={project?.value ? project : undefined}
            isLoading={isLoading}
        />
    );
}
