import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
    interface Palette {
        disabled: Palette["primary"];
        customBorder: string;
    }

    interface PaletteOptions {
        disabled?: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        disabled: true;
    }
}

const getButtonStyles = (
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "disabled"
) => {
    switch (color) {
        case "success": {
            return "#4fca64";
        }
        case "error": {
            return "#d32f2f";
        }
        case "disabled": {
            return "#808080";
        }
        default: {
            return "#0891b2"; // cyan-600 from Tailwind
        }
    }
};

const getBorderFontSizeStyles = (size?: "small" | "medium" | "large") => {
    switch (size) {
        case "small": {
            return 12;
        }
        case "medium": {
            return 14;
        }
        case "large": {
            return 16;
        }
        default: {
            return 14;
        }
    }
};

let theme = createTheme({
    spacing: 8,
    typography: {
        fontFamily: "Poppins",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "@global": {
                        "*::-webkit-scrollbar": {
                            width: "5px",
                        },
                        "*::-webkit-scrollbar-track": {
                            background: "#06B6D461", // cyan-500 with transparency
                        },
                        "*::-webkit-scrollbar-thumb": {
                            background: "#06B6D4", // cyan-500
                            borderRadius: "2px",
                        },
                    },
                    scrollbarColor: "#06B6D4 #2b2b2b", // cyan-500 and track color
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "#06B6D461", // cyan-500 with transparency
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 8,
                        backgroundColor: "#06B6D4", // cyan-500
                        minHeight: 24,
                        border: "3px solid #06B6D461", // cyan-500 with transparency
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "#06B6D4", // cyan-500
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "#06B6D4", // cyan-500
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#06B6D4", // cyan-500
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: "#2b2b2b",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                colorPrimary: "#0891b2", // cyan-600
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    color:
                        ownerState.variant === "contained"
                            ? "#ffffff"
                            : ownerState.variant === "outlined"
                              ? getButtonStyles(ownerState.color)
                              : "#0891b2", // cyan-600
                    textTransform: "none",
                    border: "1px solid",
                    backgroundColor: ownerState.variant === "contained" ? getButtonStyles(ownerState.color) : "#ffffff",
                    borderColor: getButtonStyles(ownerState.color),
                    fontSize: getBorderFontSizeStyles(ownerState.size),
                    fontFamily: "Poppins",
                    borderRadius: "2.5rem",
                    "&:hover": {
                        opacity: ownerState.variant === "contained" ? "0.8" : "0.7",
                        borderColor: getButtonStyles(ownerState.color),
                        backgroundColor:
                            ownerState.variant === "contained" ? getButtonStyles(ownerState.color) : "#ffffff",
                        boxShadow: "none",
                    },
                    "&:active": {
                        boxShadow: "none",
                        borderColor: "#005cbf",
                    },
                    "&:focus": {
                        boxShadow: `0 0 0 0.2rem ${
                            ownerState.color === "success"
                                ? "rgba(79,202,100,.5)"
                                : ownerState.color === "disabled"
                                  ? "rgba(128,128,128,.5)"
                                  : "rgba(0,123,255,.5)"
                        }`,
                    },
                    "&.Mui-disabled": {
                        backgroundColor:
                            ownerState.variant === "contained" ? getButtonStyles(ownerState.color) : "#ffffff",
                        color: ownerState.variant === "contained" ? "#ffffff" : "#0891b2", // cyan-600
                        opacity: "0.7",
                    },
                }),
                text: {
                    backgroundColor: "transparent",
                    border: 0,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: "7px 10px",
                },
                head: {
                    fontWeight: 600,
                    color: "#000000",
                    fontSize: "13px",
                    opacity: 1,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: 16,
                    fontSize: 16,
                    textTransform: "none",
                    "&.Mui-selected": {
                        color: "#0891b2", // cyan-600
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: "#0891b2", // cyan-600
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "8px",
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                colorPrimary: "#0891b2", // cyan-600
            },
        },
    },
});

theme = createTheme(theme, {
    palette: {
        primary: {
            main: "#0891b2", // cyan-600
        },
        disabled: theme.palette.augmentColor({
            color: {
                main: "#808080",
            },
            name: "disabled",
        }),
    },
});

export default theme;
