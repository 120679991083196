import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { getSaveRecruiterFlowAuth, selectUser } from "../../Signin/Signin.reducer";

import { Button, Modal, Typography } from "@/ui";

export default function RecruiterFlowConnect({ onClose }: { onClose: () => void }) {
    const user: any = useSelector(selectUser);

    const dispatch = useDispatch();

    const [recruiterFlowAuth, setRecruiterFlowAuth] = useState<any>({
        apiKey: "",
        recruiterFlowEmail: "",
    });

    useEffect(() => {
        if (user.recruiterFlowCredentials) {
            setRecruiterFlowAuth({
                apiKey: user.recruiterFlowCredentials.apiKey,
                recruiterFlowEmail: user.recruiterFlowCredentials.recruiterFlowEmail,
            });
        }
    }, [user.recruiterFlowCredentials]);

    const handleSubmit = () => {
        if (!recruiterFlowAuth.apiKey) {
            dispatch(setErrorNotification("Please enter RecruiterFlow API Key"));
            return;
        }

        if (!recruiterFlowAuth.recruiterFlowEmail) {
            dispatch(setErrorNotification("Please enter RecruiterFlow Email"));
            return;
        }
        dispatch(
            getSaveRecruiterFlowAuth({
                apiKey: recruiterFlowAuth.apiKey,
                recruiterFlowEmail: recruiterFlowAuth.recruiterFlowEmail,
            })
        );
        onClose();
    };

    return (
        <Modal open={true} onClose={onClose} disableBackdropClick>
            <div style={{ minWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Connect RecruiterFlow</h2>
                <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                    <Typography className="text-sm truncate">Recruiter Flow Email</Typography>
                    <input
                        type="text"
                        placeholder="Enter RecruiterFlow Email"
                        value={recruiterFlowAuth.recruiterFlowEmail}
                        onChange={(e) =>
                            setRecruiterFlowAuth((prev) => ({
                                ...prev,
                                recruiterFlowEmail: e.target.value,
                            }))
                        }
                        className=" min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                    />
                </div>
                <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                    <Typography className="text-sm truncate">Api Key</Typography>
                    <input
                        type="text"
                        placeholder="Enter RecruiterFlow API Key"
                        value={recruiterFlowAuth.apiKey}
                        onChange={(e) =>
                            setRecruiterFlowAuth((prev) => ({
                                ...prev,
                                apiKey: e.target.value,
                            }))
                        }
                        className="min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                    />
                </div>
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant="default"
                        onClick={handleSubmit}
                        // disabled={isCredsSubmitting}
                        className="rounded-sm"
                        label={"Submit"}
                    />
                </div>
            </div>
        </Modal>
    );
}
