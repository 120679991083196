import React, { Suspense, createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

import CallButton from "./TwilloCallButton";
import TwilloCallComponent from "./TwilloCallComponent";

import PowerDialerSnackbar from "../../../../components/PowerDialerSnackbar";
import handleException from "../../../../utils/sentry";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";

type CallContextType = {
    incomingCall: any;
    setIncomingCall: (call: any) => void;
    device: any;
    setDevice: (device: any) => void;
    callInProgress: boolean;
    setCallInProgress: (value: boolean) => void;
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    editPhoneNumber: boolean;
    setEditPhoneNumber: (value: boolean) => void;
    callConnected: boolean;
    setCallConnected: (value: boolean) => void;
    duration: number;
    setDuration: (value: number) => void;
    makeOutgoingCall: (candidate: any, type: string) => Promise<void>;
    hangUpCall: () => void;
    callData: any;
    setCallData: (value: any) => void;
    selectedCountry: string;
    setSelectedCountry: (value: string) => void;
    currentCandidate: any;
    setCurrentCandidate: (value: any) => void;
    isDialing: { [campaignId: string]: boolean };
    setIsDialing: (campaignId: string, value: boolean) => void;
    twilloPowerCallCandidateList: { [campaignId: string]: any[] };
    setTwilloPowerCallCandidateList: (campaignId: string, value: any[]) => void;
    currentIndex: { [campaignId: string]: number };
    setCurrentIndex: (campaignId: string, value: number) => void;
    currentCampaign: any;
    setCurrentCampaign: any;
};

const defaultCallContextValue: CallContextType = {
    incomingCall: null,
    setIncomingCall: () => { },
    device: null,
    setDevice: () => { },
    callInProgress: false,
    setCallInProgress: () => { },
    modalOpen: false,
    setModalOpen: () => { },
    phoneNumber: "",
    setPhoneNumber: () => { },
    editPhoneNumber: true,
    setEditPhoneNumber: () => { },
    callConnected: false,
    setCallConnected: () => { },
    duration: 0,
    setDuration: () => { },
    makeOutgoingCall: async () => { },
    hangUpCall: () => { },
    callData: null,
    setCallData: () => { },
    selectedCountry: "+1",
    setSelectedCountry: () => { },
    currentCandidate: {},
    setCurrentCandidate: () => { },
    isDialing: {},
    setIsDialing: () => { },
    twilloPowerCallCandidateList: {},
    setTwilloPowerCallCandidateList: () => { },
    currentIndex: {},
    setCurrentIndex: () => { },
    currentCampaign: null,
    setCurrentCampaign: () => { },
};

const CallContext = createContext<CallContextType>(defaultCallContextValue);

export const useCallContext = () => useContext(CallContext);

interface CallProviderProps {
    children: React.ReactNode;
}

export default function CallContextProvider({ children }: CallProviderProps) {
    const [incomingCall, setIncomingCall] = useState(null);
    const [device, setDevice] = useState(null);
    const [callInProgress, setCallInProgress] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>("+1");
    const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(true);
    const [callConnected, setCallConnected] = useState<boolean>(false);
    const [duration, setDuration] = useState(0);
    const [callData, setCallData] = useState<any>({});
    const [selectedCountry, setSelectedCountry] = useState("+1");
    const [currentCandidate, setCurrentCandidate] = useState<any>({});
    const [isDialing, setIsDialing] = useState<{ [campaignId: string]: boolean }>({});
    const [twilloPowerCallCandidateList, setTwilloPowerCallCandidateList] = useState<{ [campaignId: string]: any[] }>(
        {}
    );
    const [currentIndex, setCurrentIndex] = useState<{ [campaignId: string]: number }>({});
    const [currentCampaign, setCurrentCampaign] = useState<any>(null);
    const user = useSelector(selectUser);
    const makeOutgoingCall = async (candidate: any, type: string) => {
        const campaignId = type?.startsWith("BULKCALL_") ? type?.split("_")[1] : null;
        const callType = type?.startsWith("BULKCALL_") ? "BULKCALL" : "DIALER";
        const finalPhoneNumber =
            (selectedCountry || "") + (phoneNumber?.replace(/[\s()\-]/g, "")?.replace(/^0+/, "") || "");

        if (device && !callInProgress) {
            try {
                const payload: {
                    To: string;
                    sourceDirection: "OUTGOING";
                    userId: string;
                    candidateId?: string;
                    campaignId?: string;
                    callMode: string;
                } = {
                    To: callType === "BULKCALL" ? candidate?.phone?.[0] : finalPhoneNumber,
                    sourceDirection: "OUTGOING",
                    userId: user._id.toString(),
                    callMode: callType === "BULKCALL" ? "BULKCALL" : "DIALER",
                };
                if (candidate?.phone?.find((phone) => phone !== null)) {
                    payload.candidateId = candidate._id;
                }

                if (campaignId) {
                    payload.campaignId = campaignId;
                }

                const call = await device.connect({
                    params: payload,
                });
                setCallData(call);
                setCallInProgress(true);
                call.on("initiated", () => {
                    console.log("Call initiated.");
                });
                call.on("ringing", () => {
                    console.log("Call ringing.");

                    setCurrentCandidate(candidate);
                });
                call.on("in-progress", () => {
                    console.log("Call in-progress.");
                });

                call.on("connected", (connection) => {
                    console.log("Call connected.");
                    connection.on("accept", () => {
                        console.log("Call accepted by the user.");
                        setCallInProgress(true);
                    });
                });
                call.on("accept", () => {
                    console.log("Call accepted by the user.");
                    setCallInProgress(true);
                    setCallConnected(true);
                });
                call.on("disconnect", () => {
                    console.log("Call ended.");
                    setCallInProgress(false);
                    setDuration(0);
                    setCallConnected(false);
                    setCurrentCandidate({});
                    setCallData(null);
                    device.disconnectAll();
                });

                call.on("failed", (error) => {
                    handleException(error);
                    setCallInProgress(false);
                });
                call.on("accepted", () => {
                    console.log("Call accepted.");
                });
                call.on("error", (error) => {
                    handleException(error);
                    setCallInProgress(false);
                });
            } catch (error) {
                handleException(error);
            }
        } else {
            console.log("Device not ready.");
        }
    };

    const hangUpCall = () => {
        if (device) {
            device.disconnectAll();
            setCallInProgress(false);
            setDuration(0);
            setCallConnected(false);
            setCallData(null);
            setCurrentCandidate({});
        }
    };

    const value = {
        incomingCall,
        setIncomingCall,
        device,
        setDevice,
        callInProgress,
        setCallInProgress,
        modalOpen,
        setModalOpen,
        phoneNumber,
        setPhoneNumber,
        editPhoneNumber,
        setEditPhoneNumber,
        callConnected,
        setCallConnected,
        duration,
        setDuration,
        makeOutgoingCall,
        hangUpCall,
        callData,
        setCallData,
        selectedCountry,
        setSelectedCountry,
        currentCandidate,
        setCurrentCandidate,
        isDialing,
        setIsDialing: (campaignId: string, value: boolean) =>
            setIsDialing((prev) => ({ ...prev, [campaignId]: value })),
        twilloPowerCallCandidateList,
        setTwilloPowerCallCandidateList: (campaignId: string, value: any[]) =>
            setTwilloPowerCallCandidateList((prev) => ({ ...prev, [campaignId]: value })),
        currentIndex,
        setCurrentIndex: (campaignId: string, value: number) =>
            setCurrentIndex((prev) => ({ ...prev, [campaignId]: value })),
        currentCampaign,
        setCurrentCampaign,
    };

    return (
        <CallContext.Provider value={value}>
            {children}
            <CallButton />
            <Suspense fallback={<div>Loading...</div>}>
                <TwilloCallComponent />
                <PowerDialerSnackbar />
            </Suspense>
        </CallContext.Provider>
    );
}
