import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import useMenu from "../../../hooks/useMenu";

import { selectTemplateListPerType } from "@/store/reducers/template/template.reducer";
import { ITemplate, TemplateTypes } from "@/store/reducers/template/template.types";

const menuItemSx = {
    "& .MuiMenuItem-root": {
        padding: "4px 6px",
        fontSize: "13px",
    },
};

interface ISelectTemplateProps {
    type: TemplateTypes;
    setSubject?: (subject: string) => void;
    setEditorVal: (body: string) => void;
}

export default function SelectTemplate(props: ISelectTemplateProps) {
    const { type, setSubject, setEditorVal } = props;

    const templateList = useSelector(selectTemplateListPerType(type));

    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const [selectedTemplate, setSelectedTemplate] = useState<string>("Load Template");

    function handleSetValues(template: ITemplate) {
        setSelectedTemplate(template.name);
        setEditorVal(template.body);
        if (setSubject) {
            setSubject(template.subject);
        }
    }

    return (
        <>
            <Button
                size="small"
                variant="outlined"
                sx={{
                    minWidth: "fit-content",
                }}
                onClick={handleClick}
            >
                {selectedTemplate}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ ...menuSX }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                {templateList.length ? (
                    templateList.map((template) => (
                        <MenuItem sx={menuItemSx} onClick={() => handleSetValues(template)}>
                            {template.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled sx={menuItemSx} value="">
                        <em>No saved templates</em>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
