import { Tab, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";

import AI from "./components/ai-control/AI";
import CreditPlans from "./components/credits/CreditPlans";
import EmailAliases from "./components/emailAliases/EmailAliases";
import { EditProfile } from "./components/myDetails/EditProfile";
import OutreachControl from "./components/outreach-control/OutreachControl";
import UserStats from "./components/stats/Stats";
import style from "./myAccount.module.scss";

import { JoyProvider } from "../../components/JoyProvider";
import { MaterialProvider } from "../../components/MaterialProvider";
import Navbar from "../../components/Navbar/Navbar";
import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { OrganizationMembers } from "../manageTeams/components/OrganizationMembers";
import { fetchInvitations, fetchMembers } from "../manageTeams/manageTeams.reducers";
import { checkCreditsTrackingEnabled } from "../Signin/Signin.reducer";

import Loader from "@/components/Loader/Loader";

export default function MyAccount() {
    const { tab } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const paymentStatus = searchParams.get("payment-status");

    useEffect(() => {
        dispatch(fetchMembers({ action: fetchMembers.type }));
        dispatch(fetchInvitations({ action: fetchInvitations.type }));
    }, []);

    const isFetchingMembers = useSelector(checkIfLoading(fetchMembers.type));
    const isFetchingInvitations = useSelector(checkIfLoading(fetchInvitations.type));
    const isLoading = isFetchingMembers || isFetchingInvitations;
    const isCreditsTrackingEnabled = useSelector(checkCreditsTrackingEnabled);

    useEffect(() => {
        if (!paymentStatus) return;
        if (paymentStatus === "success") dispatch(setSuccessNotification("Payment successful"));
        else if (paymentStatus === "canceled") dispatch(setErrorNotification("Payment unsuccessful"));
    }, [paymentStatus]);

    if (isLoading) {
        return (
            <div className="h-screen">
                <Loader />
            </div>
        );
    }

    const items = [
        {
            key: "details",
            label: `My details`,
            children: <EditProfile />,
        },
        {
            key: "my-virtual-users",
            label: "My virtual users",
            children: (
                <MaterialProvider>
                    <EmailAliases />
                </MaterialProvider>
            ),
        },
        {
            key: "organization-member",
            label: "Members and invitations",
            children: <OrganizationMembers />,
        },
        {
            key: "contact-data-control",
            label: "Contact data control",
            children: <UserStats />,
        },
        {
            key: "outreach-control",
            label: "Outreach control",
            children: <OutreachControl />,
        },
        {
            key: "ai-control",
            label: "AI control",
            children: <AI />,
        },
        ...(isCreditsTrackingEnabled
            ? [
                {
                    key: "credit-plans",
                    label: "Credit plans",
                    children: <CreditPlans />,
                },
            ]
            : []),
    ];

    return (
        <div className={style["myAccount"]}>
            <Navbar />
            <div className={style["myAccount__tabs"]}>
                <JoyProvider>
                    <Tabs
                        value={tab}
                        onChange={(event, newValue) => navigate(`/my-account/${newValue}`)}
                        sx={{ borderRadius: "lg", backgroundColor: "#fff", overflow: "hidden" }}
                    >
                        <TabList
                            sx={{
                                pt: 1,
                                justifyContent: "start",
                                fontSize: "14px",
                                [`&& .${tabClasses.root}`]: {
                                    flex: "initial",
                                    bgcolor: "transparent",
                                    "&:hover": {
                                        bgcolor: "transparent",
                                    },
                                    [`&.${tabClasses.selected}`]: {
                                        color: "#0891b2",
                                        "&::after": {
                                            height: 2,
                                            borderTopLeftRadius: 3,
                                            borderTopRightRadius: 3,
                                            bgcolor: "#0891b2",
                                        },
                                    },
                                },
                            }}
                        >
                            {items.map((tab) => {
                                return (
                                    <Tab key={tab.key} value={tab.key}>
                                        {tab.label}
                                    </Tab>
                                );
                            })}
                        </TabList>
                        {items.map((tab) => {
                            return (
                                <TabPanel key={Math.random()} value={tab.key}>
                                    {tab.children}
                                </TabPanel>
                            );
                        })}
                    </Tabs>
                </JoyProvider>
            </div>
            <Outlet />
        </div>
    );
}
