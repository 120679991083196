import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { isNil, isUndefined } from "lodash";
import queryString from "query-string";
import { z } from "zod";

import {
    CreateReferenceRequestPayload,
    ProjectStageOptionKey,
    UpdateReferencesRequestPayload,
} from "../all-candidates-reachout/all-candidates-reachout.types";
import { fetchBaseQueryWithZod } from "../utils";

import {
    Candidate,
    CandidateProjectActivity,
    CreateCandidateRequestPayload,
    FetchActivityTagsResponse,
    FetchCandidateEmailThreadsResponse,
    FetchCandidatesPayload,
    SendReminderEmailRequest,
    SendReminderEmailResponse,
    ThreadList,
    fetchActivityTagsResponseSchema,
    fetchCandidateEmailThreadsSchema,
    sendReminderEmailResponseSchema,
} from "@/store/reducers/all-candidates/all-candidates.types";
import {
    ActivityColumn,
    FetchContactOverviewActivitiesPayload,
    FetchProjectActivitiesResponse,
} from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";

const projectSchema = z.object({
    _id: z.number(),
    name: z.string().optional(),
    stage: z.string().optional(),
    detailedStage: z.string().optional(),
    labels: z.string().optional(),
});

type Project = z.infer<typeof projectSchema>;

const projectsSchema = z.array(
    z.object({
        _id: z.number(),
        name: z.string().optional(),
        stage: z.string().optional(),
        detailedStage: z.string().optional(),
    })
);

const activitySchema = z.object({
    body: z.string(),
    updatedAt: z.string(),
});

type Activity = z.infer<typeof activitySchema>;

const activitiesSchema = z.record(z.array(activitySchema));

type Activities = z.infer<typeof activitiesSchema>;

const cvSourceSchema = z.object({
    label: z.string(),
    value: z.string(),
});

const greenhouseMetaDataSchema = z
    .object({
        id: z.number(),
    })
    .nullable();

const fetchCandidatesResponseDataValue = z
    .union([z.string(), z.number(), z.array(z.string()), projectsSchema, activitiesSchema])
    .nullable();

type FetchCandidatesResponseDataValue = z.infer<typeof fetchCandidatesResponseDataValue>;

const fetchCandidatesResponseData = z.record(fetchCandidatesResponseDataValue);

type FetchCandidatesResponseData = z.infer<typeof fetchCandidatesResponseData>;

export const fetchCandidatesResponseSchema = z.object({
    message: z.string(),
    data: z.object({
        candidates: z.array(fetchCandidatesResponseData),
        totalCandidates: z.number(),
    }),
});

type FetchCandidatesResponse = z.infer<typeof fetchCandidatesResponseSchema>;

export type CandidatesResponse = {
    candidates: Candidate[];
    total: number;
};

function combineActivityValues(data: Activity[] | undefined): string {
    if (!data || !data.length) {
        return "";
    }

    return data?.reduce((acc: string, { body, updatedAt }, idx) => {
        acc = `
                ${acc}
                ${idx !== 0 ? "<br />" : ""}
                ${body}
            `;

        return acc;
    }, "");
}

function handleUndefinedCase(name: string | undefined): string {
    return isUndefined(name) ? "-" : name;
}

function isProjectArray(value: FetchCandidatesResponseDataValue): value is Project[] {
    if (!Array.isArray(value)) {
        return false;
    }

    if (isStringArray(value)) {
        return false;
    }

    // Check if all items in the array are of Project type
    return value.every((item) => typeof item === "object" && item !== null && "_id" in item);
}

function isActivityValue(value: unknown): value is Activity {
    return (
        typeof value === "object" &&
        value !== null &&
        "body" in value &&
        typeof (value as Activity).body === "string" &&
        "updatedAt" in value &&
        typeof (value as Activity).updatedAt === "string"
    );
}

function isActivities(value: FetchCandidatesResponseDataValue): value is Activities {
    if (typeof value !== "object" || value === null) {
        return false;
    }

    if (isStringArray(value)) {
        return false;
    }

    if (isProjectArray(value)) {
        return false;
    }

    return Object.values(value).every((val) => Array.isArray(val) && val.every(isActivityValue));
}

function isStringArray(value: any): value is string[] {
    if (!Array.isArray(value)) {
        return false;
    }

    // Check if every element in the array is a string
    return value.every((item) => typeof item === "string");
}

function mapFetchCandidatesResponseData(candidates: FetchCandidatesResponseData[]) {
    const result: Candidate[] = [];

    candidates.forEach((element) => {
        const candidate: Candidate = {
            _id: "",
        };
        Object.keys(element).forEach((key) => {
            const value = element[key];

            if (isNil(value)) {
                candidate[key] = "-";
            }

            if (isProjectArray(value)) {
                const firstElement = value[0];
                const { name, stage, detailedStage, _id } = firstElement;

                candidate["List of projects"] = _id
                    ? {
                          name: name ?? "",
                          id: _id,
                      }
                    : "-";

                candidate["Project Stage"] = handleUndefinedCase(stage);

                candidate["Contact Overview"] = handleUndefinedCase(detailedStage);
                // @ts-ignore
                candidate["Multiple projects value"] = value.map((item) => {
                    const { name, _id } = item;
                    if (_id) {
                        return {
                            name: name,
                            id: _id,
                        };
                    }
                });
            }

            if (isActivities(value)) {
                Object.entries(value).forEach(([key, value]) => {
                    candidate[key] = combineActivityValues(value);
                });
            }

            if (typeof value === "string" || isStringArray(value)) {
                candidate[key] = value;
            }
            if (key == "ATS") {
                // @ts-ignore
                candidate[key] = value;
            }
            if (key == "greenhouseMetaData") {
                // @ts-ignore
                candidate[key] = value;
            }
        });

        result.push(candidate);
    });

    return result;
}

/*
 ** fetchCandidateProjects types
 */
const projectFromListSchema = z.object({
    candidateId: z.string(),
    stage: z.string(),
    detailedStage: z.string().optional(),
    labels: z.array(z.string()),
    createdAt: z.string(),
    projectId: z.number(),
    projectName: z.string(),
    dashboardStatus: z.string(),
});

export type ProjectFromList = z.infer<typeof projectFromListSchema>;

const candidateProjectListSchema = z.array(projectFromListSchema);

export type ListOfCandidateProjects = z.infer<typeof candidateProjectListSchema>;

const fetchCandidateProjectsResponseSchema = z.object({
    message: z.string(),
    data: candidateProjectListSchema,
});

export type ProjectListResponse = z.infer<typeof fetchCandidateProjectsResponseSchema>;
//--------------------------------------------
/**
 * fetchCandidateRating types
 */
type FetchCandidateInsightsPayload = {
    candidateId: string;
    projectId: number;
};

export const insightsDescriptionSchema = z.object({
    criterion: z.string(),
    text: z.string(),
    intent: z.number(),
});

export type InsightDescription = z.infer<typeof insightsDescriptionSchema>;

const fetchCandidateInsightsDataSchema = z.object({
    value: z.number(),
    label: z.string(),
    description: z.array(insightsDescriptionSchema),
    updatedAt: z.string(),
});

export type CandidatesInsights = z.infer<typeof fetchCandidateInsightsDataSchema>;

const fetchCandidateInsightsResponseSchema = z.object({
    message: z.string(),
    data: fetchCandidateInsightsDataSchema,
});

// emailResponseStats schema
const emailResponseStatsSchema = z.object({
    bouncifyResponse: z.string().optional(),
    zeroBounceStatus: z.string().optional(),
    zeroBounceAIScore: z.number().optional(),
});

type FetchCandidatesInsightsResponseData = z.infer<typeof fetchCandidateInsightsResponseSchema>;
// ---------------------------
/***
 * fetchCandidateDetails types
 */
const fetchCandidatesResponseDataSchema = z.object({
    name: z.string(),
    profileUrl: z.string(),
    email: z.array(z.string()),
    location: z.string().nullable(),
    currentTenure: z.string().nullable(),
    labels: z.array(z.string()),
    phone: z.string().nullable(),
    industry: z.string().nullable(),
    jobTitle: z.string().nullable(),
    company: z.string().nullable(),
    yearsOfExperience: z.string().nullable(),
    funding: z.string().nullable(),
    profileImage: z.string().nullable(),
    emailResponseStats: emailResponseStatsSchema.optional(),
    emailWithTypes: z
        .object({
            personal: z.array(z.string()).optional(),
            professional: z.array(z.string()).optional(),
            primary: z.string().nullable().optional(),
        })
        .optional(),
});

export type CandidateDetails = z.infer<typeof fetchCandidatesResponseDataSchema>;

const fetchCandidateDetailsResponseSchema = z.object({
    message: z.string(),
    data: fetchCandidatesResponseDataSchema,
});

export type FetchCandidatesDetailsResponse = z.infer<typeof fetchCandidateDetailsResponseSchema>;
//-----------------------------
/**
 * fetch activities
 */
export const activityProjectSchema = z.object({
    id: z.number(),
    name: z.string(),
});

export const referToSchema = z.object({
    name: z.string().trim(),
    _id: z.number(),
});

export type ReferTo = z.infer<typeof referToSchema>;

export const prioritySchema = z.union([z.literal("LOW"), z.literal("MEDIUM"), z.literal("HIGH"), z.literal("")]);

export const fetchActivitySchema = z.object({
    _id: z.string(),
    body: z.string(),
    project: z.object({
        _id: z.number(),
        name: z.string(),
    }),
    timestamp: z.string(),
    tag: z.string(),
    createdBy: z.object({
        _id: z.number(),
        name: z.string(),
        profileImage: z.string().nullable(),
    }),
    read: z.boolean().optional(),
    referTo: referToSchema.nullable().optional(),
    priority: prioritySchema.optional(),
    createdFor: z
        .object({
            _id: z.string(),
            name: z.string(),
            labels: z.string().array(),
        })
        .optional(),
    scheduledFor: z
        .object({
            email: z.string(),
            timestamp: z.string(),
        })
        .optional(),
    dueDate: z.string().optional().nullable(), // when should activity ideally finish
});

export const contactOverviewActivitySchema = fetchActivitySchema;

export type ContactOverviewActivity = z.infer<typeof contactOverviewActivitySchema>;

export const createContactOverviewActivitySchema = z.object({
    message: z.string(),
    data: contactOverviewActivitySchema,
});

export type CreateContactOverviewActivityResponse = z.infer<typeof createContactOverviewActivitySchema>;

export type CreateActivityPayload = Partial<
    Omit<ContactOverviewActivity, "project"> & {
        projectId: number;
        candidateId: string;
        referTo?: number;
        title: string;
    }
>;

export const fetchActivitiesResponseData = z.object({
    activities: z.array(contactOverviewActivitySchema),
    totalActivities: z.number(),
});

export const fetchProjectActivitiesResponseSchema = z.object({
    message: z.string(),
    data: fetchActivitiesResponseData,
});

export type SingleActivity = z.infer<typeof fetchActivitySchema>;

const activitiesResponseDataSchema = z.record(z.array(fetchActivitySchema));

export type ActivitiesData = z.infer<typeof activitiesResponseDataSchema>;

const fetchActivitiesResponseSchema = z.object({
    data: activitiesResponseDataSchema,
    message: z.string(),
});

export type ActivitiesResponse = z.infer<typeof fetchActivitiesResponseSchema>;
//-----------------------------

/**
 * fetch organization members
 */
export const memberSchema = z.object({
    _id: z.number(),
    name: z.string(),
});

export const fetchOrganizationMembersResponseSchema = z.object({
    message: z.string(),
    data: z.array(memberSchema),
});

export type FetchOrganizationMembersResponse = z.infer<typeof fetchOrganizationMembersResponseSchema>;

export type FetchOrganizationMembersData = z.infer<typeof memberSchema>[];
//-------------------
/**
 * create activity
 */
export type ActivityMutationPayload = {
    body: string;
    candidateId: string;
    projectId: number;
    referTo?: number;
    title: string;
};
//----------------

/**
 * fetch references
 */
export const referenceSchema = z.object({
    _id: z.string(),
    referrerName: z.string(),
    referrerPhoneNo: z.string().optional(),
    relation: z.string(),
    title: z.string().nullable().optional(),
    referrerEmail: z.string().nullable().optional(),
    referrerLinkedinUrl: z.string().nullable().optional(),
    body: z.string().nullable().optional(),
    createdAt: z.string().nullable().optional(),
});

export type Reference = z.infer<typeof referenceSchema>;

export const fetchReferencesResponseSchema = z.object({
    message: z.string(),
    data: z.array(referenceSchema),
});

export type FetchReferenceResponse = z.infer<typeof fetchReferencesResponseSchema>;
//----
/**
 * update project status
 */
type UpdateProjectStatusPayload = {
    candidateIds: string[];
    projectId: number;
    detailedStage?: string;
    stage?: ProjectStageOptionKey;
};
//----
export const allCandidatesApi = createApi({
    reducerPath: "allCandidatesApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [
        "Activities",
        "References",
        "Projects",
        "Project_Activities",
        "Contact_Overview_Activities",
        "Contact_Overview_Candidates",
    ],
    endpoints: (builder) => ({
        fetchCandidateProjects: builder.query<ListOfCandidateProjects, string | undefined>({
            query: (candidateId) => `/candidate/projects/${candidateId}`,
            transformResponse: (response: ProjectListResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchCandidateProjectsResponseSchema,
            },
            providesTags: ["Projects"],
        }),
        fetchActivityTags: builder.query<
            FetchActivityTagsResponse["data"],
            { projectIds?: number[]; defaultTags?: boolean }
        >({
            query: ({ projectIds = [], defaultTags = true }) =>
                `/v2/project/tags?${queryString.stringify({ projectIds, defaultTags })}`,
            transformResponse: (response: FetchActivityTagsResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchActivityTagsResponseSchema,
            },
        }),
        allCandidatesEmails: builder.query<ThreadList, string | undefined>({
            query: (candidateId) => {
                return { url: `/outside-email/get/${candidateId}` };
            },
            transformResponse: (response: FetchCandidateEmailThreadsResponse) => {
                const { data } = response;

                const threadList = data?.reduce((acc: ThreadList, curr) => {
                    if (curr.threadId in acc) {
                        acc[curr.threadId] = acc[curr.threadId].concat(curr);
                    } else {
                        acc[curr.threadId] = [curr];
                    }
                    return acc;
                }, {});

                return threadList;
            },
            extraOptions: {
                dataSchema: fetchCandidateEmailThreadsSchema,
            },
        }),
        fetchCandidates: builder.query<CandidatesResponse, FetchCandidatesPayload>({
            query: (payload) => {
                const { pageSize, start, ...rest } = payload;

                let url = `/candidate/all?start=${payload.start}&limit=${payload.pageSize}`;

                if (rest?.filterBy) {
                    url = url + `&filterBy=${JSON.stringify(rest.filterBy)}`;
                }

                if (rest?.search) {
                    url = url + `&search=${rest.search}`;
                }

                if (rest?.sortBy) {
                    url = url + `&sortBy=${JSON.stringify(rest.sortBy)}`;
                }

                if (rest?.filterByColumn) {
                    url = url + `&filterByColumn=${JSON.stringify(rest.filterByColumn)}`;
                }
                return {
                    url,
                };
            },
            transformResponse: (response: FetchCandidatesResponse) => {
                const { data } = response;
                const { candidates, totalCandidates } = data;

                const mappedCandidates = mapFetchCandidatesResponseData(candidates);

                return { candidates: mappedCandidates, total: totalCandidates };
            },
            providesTags: ["Contact_Overview_Candidates"],
        }),
        manageCandidate: builder.mutation<void, CreateCandidateRequestPayload>({
            query: (payload) => {
                const {
                    url,
                    companyInformation,
                    contact,
                    email,
                    firstName,
                    jobTitle,
                    lastName,
                    location,
                    profileUrl,
                    project,
                    prevProject,
                    resume,
                    labels,
                } = payload;
                const formData = new FormData();
                formData.append("firstName", firstName);
                formData.append("lastName", lastName);
                formData.append("project", project);
                formData.append("prevProject", prevProject);

                if (companyInformation) {
                    formData.append("companyInformation", companyInformation);
                }

                if (contact) {
                    formData.append("contact", contact);
                }

                if (email) {
                    formData.append("email", email);
                }

                if (jobTitle) {
                    formData.append("jobTitle", jobTitle);
                }

                if (location) {
                    formData.append("location", location);
                }

                if (profileUrl) {
                    formData.append("profileUrl", profileUrl);
                }

                if (resume) {
                    formData.append("resume", resume);
                }

                if (labels?.length) {
                    formData.append("labels", JSON.stringify(labels));
                }

                return {
                    url,
                    method: "POST",
                    body: formData,
                };
            },
            invalidatesTags: ["Contact_Overview_Candidates"],
        }),
        fetchCandidateInsights: builder.query<CandidatesInsights, FetchCandidateInsightsPayload>({
            query: (args) => {
                const { candidateId, projectId } = args;
                return {
                    url: `/v2/project/${projectId}/rating/${candidateId}`,
                };
            },
            transformResponse: (response: FetchCandidatesInsightsResponseData) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchCandidateInsightsResponseSchema,
            },
        }),
        fetchCandidateDetails: builder.query<CandidateDetails, string>({
            query: (candidateId) => {
                return {
                    url: `/candidate/get/info/${candidateId}`,
                };
            },
            transformResponse: (response: FetchCandidatesDetailsResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchCandidateDetailsResponseSchema,
            },
        }),
        fetchCandidateActivities: builder.query<ActivitiesResponse["data"], string>({
            query: (candidateId) => {
                return {
                    url: `/activity/get/candidate/${candidateId}`,
                };
            },
            transformResponse: (response: ActivitiesResponse) => {
                const { data } = response;
                return data;
            },
            providesTags: ["Activities"],
            extraOptions: {
                dataSchema: fetchActivitiesResponseSchema,
            },
        }),
        fetchCandidateProjectActivities: builder.query<
            CandidateProjectActivity[],
            { candidateId: string; projectId: number }
        >({
            query: ({ candidateId, projectId }) => {
                return {
                    url: `/activity/get/${projectId}/candidate/${candidateId}`,
                };
            },
            transformResponse: (response: { data: CandidateProjectActivity[] }) => {
                const { data } = response;
                return data;
            },
            providesTags: ["Project_Activities"],
        }),
        createActivity: builder.mutation<void, CreateActivityPayload>({
            query: (payload) => {
                return {
                    url: "/activity/create",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: ["Activities", "Project_Activities", "Contact_Overview_Activities"],
        }),
        fetchBetaAICandidates: builder.mutation<any, any>({
            query: (payload) => {
                return {
                    url: "/searches/ai-candidate",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: ["Activities"],
        }),
        fetchBetaGitCandidates: builder.mutation<any, any>({
            query: (payload) => {
                return {
                    url: "/github/get",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: ["Activities"],
        }),
        updateActivity: builder.mutation<void, CreateActivityPayload & { activityId: string }>({
            query: (payload) => {
                const { activityId, ...rest } = payload;
                return {
                    url: `/activity/update/${activityId}`,
                    method: "PUT",
                    body: { ...rest },
                };
            },
            invalidatesTags: ["Activities", "Project_Activities"],
        }),
        deleteActivity: builder.mutation<void, { activityId: string }>({
            query: (payload) => {
                const { activityId } = payload;
                return {
                    url: `/activity/update/${activityId}`,
                    method: "PUT",
                    body: { isDeleted: true },
                };
            },
            invalidatesTags: ["Activities", "Project_Activities"],
        }),
        markAsComplete: builder.mutation<void, { activityId: string }>({
            query: (payload) => {
                const { activityId } = payload;
                return {
                    url: `/activity/update/${activityId}`,
                    method: "PUT",
                    body: { read: true },
                };
            },
            invalidatesTags: ["Activities"],
        }),
        fetchReferences: builder.query<Reference[], string>({
            query: (candidateId) => `/referral/get/candidate/${candidateId}`,
            transformResponse: (response: FetchReferenceResponse) => {
                const { data } = response;

                return data;
            },
            providesTags: ["References"],
            extraOptions: {
                dataSchema: fetchReferencesResponseSchema,
            },
        }),
        deleteReference: builder.mutation<void, string>({
            query: (referenceId) => {
                console.log({ referenceId });
                return {
                    url: `/referral/delete/${referenceId}`,
                    method: "PUT",
                };
            },
            invalidatesTags: ["References"],
        }),
        createReferences: builder.mutation<void, CreateReferenceRequestPayload>({
            query: (createReferencePayload) => {
                return {
                    url: `/referral/create`,
                    method: "POST",
                    body: { ...createReferencePayload },
                };
            },
            invalidatesTags: ["References"],
        }),
        updateReferences: builder.mutation<void, UpdateReferencesRequestPayload>({
            query: (updateReferencePayload) => {
                const { referralId, ...rest } = updateReferencePayload;
                return {
                    url: `/referral/update/${referralId}`,
                    method: "PUT",
                    body: { referralId, ...rest },
                };
            },
            invalidatesTags: ["References"],
        }),
        fetchOrganizationMembers: builder.query<FetchOrganizationMembersData, void>({
            query: () => {
                return { url: "organization/members/names" };
            },
            transformResponse: (response: FetchOrganizationMembersResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchOrganizationMembersResponseSchema,
            },
        }),
        updateProjectStatus: builder.mutation<void, UpdateProjectStatusPayload>({
            query: (updateProjectStatusPayload) => {
                return {
                    url: "/candidate/project-stage",
                    method: "PUT",
                    body: updateProjectStatusPayload,
                };
            },
            invalidatesTags: ["Projects"],
        }),
        sendReminderMail: builder.mutation<SendReminderEmailResponse, SendReminderEmailRequest>({
            query: (payload) => {
                return {
                    url: "/activity/send-mail",
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: ["Contact_Overview_Activities"],
            extraOptions: {
                dataSchema: sendReminderEmailResponseSchema,
            },
        }),
        fetchContactOverviewActivities: builder.query<
            {
                totalActivities: number;
                data: ActivityColumn[];
            },
            FetchContactOverviewActivitiesPayload
        >({
            query: (payload) => {
                const url = `/activity/contact-overview?${queryString.stringify(payload)}`;

                return {
                    url,
                };
            },
            transformResponse: (response: FetchProjectActivitiesResponse) => {
                const { activities, totalActivities } = response.data;

                const mappedActivities: ActivityColumn[] = activities.map((activity) => {
                    const { _id, referTo, createdFor, tag, ...rest } = activity;

                    return {
                        _id,
                        assignTo: referTo,
                        createdFor: createdFor,
                        activityType: tag,
                        activityDetails: { ...rest },
                    };
                });

                return { data: mappedActivities, totalActivities };
            },
            providesTags: ["Contact_Overview_Activities"],
            extraOptions: {
                dataSchema: fetchProjectActivitiesResponseSchema,
            },
        }),
        updateContactOverviewActivity: builder.mutation<void, CreateActivityPayload & { activityId: string }>({
            query: (payload) => {
                const { activityId, ...rest } = payload;
                return {
                    url: `/activity/update/${activityId}`,
                    method: "PUT",
                    body: { ...rest },
                };
            },
            invalidatesTags: ["Contact_Overview_Activities"],
        }),
        deleteContactOverviewActivity: builder.mutation<void, { activityId: string }>({
            query: (payload) => {
                const { activityId } = payload;
                return {
                    url: `/activity/update/${activityId}`,
                    method: "PUT",
                    body: { isDeleted: true },
                };
            },
            invalidatesTags: ["Contact_Overview_Activities"],
        }),
    }),
});

export const {
    useDeleteContactOverviewActivityMutation,
    useManageCandidateMutation,
    useFetchContactOverviewActivitiesQuery,
    useUpdateContactOverviewActivityMutation,
    useUpdateProjectStatusMutation,
    useUpdateReferencesMutation,
    useCreateReferencesMutation,
    useMarkAsCompleteMutation,
    useDeleteReferenceMutation,
    useFetchReferencesQuery,
    useFetchCandidateProjectActivitiesQuery,
    useDeleteActivityMutation,
    useUpdateActivityMutation,
    useCreateActivityMutation,
    useFetchBetaAICandidatesMutation,
    useFetchBetaGitCandidatesMutation,
    useFetchCandidateInsightsQuery,
    useFetchCandidateProjectsQuery,
    useFetchActivityTagsQuery,
    useFetchCandidatesQuery,
    useAllCandidatesEmailsQuery,
    useFetchCandidateDetailsQuery,
    useFetchCandidateActivitiesQuery,
    useFetchOrganizationMembersQuery,
    useSendReminderMailMutation,
} = allCandidatesApi;
