import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { isValidEmail } from "../../../utils/helper";
import { selectUser } from "../../Signin/Signin.reducer";
import { postGreenhouseCredentials } from "../integrations.reducer";
import { GreenHouseKey, GreenHouseState } from "../integrations.types";

import { Button, Modal, Typography } from "@/ui";

export default function GreenhouseConnect({ onClose }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const isCredsSubmitting = useSelector(checkIfLoading(postGreenhouseCredentials.type));

    const dispatch = useDispatch();
    const [greenHouse, setGreenHouse] = useState<GreenHouseState>({
        harvestApiKey: "",
        apiKey: "",
        serviceUser: "",
        clientGreenHouseUrl: "",
    });
    const [error, setError] = useState({
        harvestApiKey: false,
        apiKey: false,
        serviceUser: false,
    });

    useEffect(() => {
        if (isEmpty(user)) return;
        setGreenHouse({
            harvestApiKey: user.greenHouse?.harvestApiKey ?? "",
            apiKey: user.greenHouse?.apiKey ?? "",
            serviceUser: user.greenHouse?.serviceUser ?? "",
            clientGreenHouseUrl: user.greenHouse?.clientGreenHouseUrl ?? "",
        });
    }, [user]);

    function validateField(field: GreenHouseKey, isValid: boolean) {
        if (!isValid) {
            setError((prevError) => ({ ...prevError, [field]: true }));
            return;
        }
    }

    const handleSubmit = () => {
        validateField("harvestApiKey", Boolean(greenHouse.harvestApiKey));
        validateField("apiKey", Boolean(greenHouse.apiKey));
        validateField("serviceUser", isValidEmail(greenHouse.serviceUser));
        dispatch(
            postGreenhouseCredentials({
                action: postGreenhouseCredentials.type,
                harvestApiKey: greenHouse.harvestApiKey,
                apiKey: greenHouse.apiKey,
                serviceUser: greenHouse.serviceUser,
                clientGreenHouseUrl: greenHouse?.clientGreenHouseUrl,
                onSuccess: onClose,
            })
        );
    };

    return (
        <>
            <Modal open={true} onClose={onClose} disableBackdropClick>
                <div style={{ minWidth: "500px" }}>
                    <h2 className="text-xl font-semibold mb-5">Connect Greenhouse</h2>

                    <div>
                        <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                            <Typography className="text-sm truncate">Harvest API Key</Typography>
                            <div className="flex flex-col">
                                <input
                                    placeholder="Enter harvest api key"
                                    value={greenHouse.harvestApiKey}
                                    onChange={(e) =>
                                        setGreenHouse((prev) => ({
                                            ...prev,
                                            harvestApiKey: e.target.value,
                                        }))
                                    }
                                    className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 
                                    ${error.harvestApiKey ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"}`}
                                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                                />

                                {error.harvestApiKey && (
                                    <span className="text-xs text-red-500 mt-1">Enter valid api key</span>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                            <Typography className="text-sm truncate">Candidate Ingestion API Key</Typography>
                            <div className="flex flex-col">
                                <input
                                    placeholder="Enter candidate ingestion api key"
                                    value={greenHouse.apiKey}
                                    onChange={(e) =>
                                        setGreenHouse((prev) => ({
                                            ...prev,
                                            apiKey: e.target.value,
                                        }))
                                    }
                                    className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 
                                    ${error.apiKey ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"}`}
                                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                                />
                                {error.apiKey && <span className="text-xs text-red-500 mt-1">Enter valid api key</span>}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                            <Typography className="text-sm truncate">Service User Email</Typography>
                            <div className="flex flex-col">
                                <input
                                    placeholder="Enter email"
                                    value={greenHouse.serviceUser}
                                    onChange={(e) =>
                                        setGreenHouse((prev) => ({
                                            ...prev,
                                            serviceUser: e.target.value,
                                        }))
                                    }
                                    className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 
                                    ${error.serviceUser ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"}`}
                                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                                />
                                {error.serviceUser && (
                                    <span className="text-xs text-red-500 mt-1">Enter valid email</span>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                            <Typography className="text-sm truncate">Your Greenhouse domain</Typography>

                            <input
                                placeholder="Enter your Greenhouse domain"
                                value={greenHouse.clientGreenHouseUrl}
                                onChange={(e) =>
                                    setGreenHouse((prev) => ({
                                        ...prev,
                                        clientGreenHouseUrl: e.target.value,
                                    }))
                                }
                                className=" min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                        </div>
                        <div className="h-[14px] text-[10px] text-gray-600 italic mt-2.5">
                            <p>
                                Example: https://abcd.greenhouse.com, where abcd may be custom text for your account or
                                your company name
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-end space-x-2 mt-6">
                        <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                        <Button
                            variant="default"
                            onClick={handleSubmit}
                            disabled={isCredsSubmitting}
                            className="rounded-sm"
                            label={isCredsSubmitting ? "Submitting..." : "Submit"}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}
