import Typography from "@mui/material/Typography";

import { TotalExperience } from "../project.types";

type Props = { linkedInExp: TotalExperience };

const DefaultText = ({ children }: { children: React.ReactNode }) => (
    <Typography variant="body2" sx={{ fontSize: "12px", color: "#242222", paddingLeft: "5px" }}>
        {children}
    </Typography>
);

export default function Experience({ linkedInExp = { label: "", value: 0 } }: Props) {
    if (linkedInExp?.label && linkedInExp?.value) {
        return <DefaultText>{linkedInExp.label}</DefaultText>;
    }

    return null;
}
