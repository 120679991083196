import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";

import { RootState } from "../..";
import {
    AddToConversationPayload,
    CANDIDATE_SMS_STATUS,
    GetUserSMSConversationsPayload,
    IEmailData,
    IGetUserEmailPayload,
    IRootState,
    ISendEmailPayload,
    MarkAsReadPayload,
    MsgType,
    SetSMSDetailsPayload,
    SmsState,
    SubmitSMSResponsePayload,
} from "./message.types";

function getUserSMSStatus(): boolean | undefined {
    const user = JSON.parse(localStorage.getItem("user") || "");

    const smsStatus: boolean | undefined = get(user, "features.smsEnabled");

    return smsStatus;
}

const initialState: IRootState = {
    userEmail: [],
    userLinkedinMsgs: [],
    userSmsList: null,
    projectId: 0,
    type: "SMS",
    status: "RECEIVED",
};

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setCandidateSMSStatus(state, action: PayloadAction<{ candidateId: string }>) {
            const candidateId = action.payload.candidateId;
            state.userSmsList?.forEach(({ conversations, candidate: { id } }) => {
                if (id === candidateId)
                    conversations.forEach((i) => {
                        i.userStatus = "READ";
                    });
            });
        },
        addToConversation(state, action: AddToConversationPayload) {
            const { candidateId, data } = action.payload;
            state.userSmsList?.forEach(({ candidate, conversations }) => {
                if (candidate.id === candidateId) {
                    conversations.push(data);
                }
            });
        },
        submitSMSResponse(state, action: SubmitSMSResponsePayload) {},
        markAsRead(state, action: MarkAsReadPayload) {},
        getUserSmsConversations(state, action: GetUserSMSConversationsPayload) {},
        setSMSDetails(state, action: SetSMSDetailsPayload) {
            state.userSmsList = action.payload;
        },
        getUserEmail(state, action: PayloadAction<IGetUserEmailPayload>) {},
        setUserEmail(state, { payload }: { payload: IEmailData[] }) {
            state.userEmail = payload;
        },
        sendEmail(state, action: PayloadAction<ISendEmailPayload>) {},
        postEmailReadReceipt(state, action: PayloadAction<{ threadId: string }>) {},
        getUserLinkedinMsgs(state, action: PayloadAction<IGetUserEmailPayload>) {},
        setLinkedinMsgs(state, action: PayloadAction<[]>) {
            state.userLinkedinMsgs = action.payload;
        },
        setMsgProjectId(state, action: PayloadAction<number>) {
            state.projectId = action.payload;
        },
        setMsgType(state, action: PayloadAction<MsgType>) {
            state.type = action.payload;
        },
        setMsgStatus(state, action: PayloadAction<CANDIDATE_SMS_STATUS | "ALL">) {
            state.status = action.payload;
        },
        resetMessageState() {
            return initialState;
        },
        cancelSagas(state) {},
    },
});

export default messageSlice.reducer;

export const {
    setCandidateSMSStatus,
    addToConversation,
    submitSMSResponse,
    markAsRead,
    getUserSmsConversations,
    setSMSDetails,
    getUserEmail,
    setUserEmail,
    sendEmail,
    postEmailReadReceipt,
    getUserLinkedinMsgs,
    setLinkedinMsgs,
    setMsgProjectId,
    setMsgType,
    setMsgStatus,
    cancelSagas,
    resetMessageState,
} = messageSlice.actions;

export const selectUserEmails = (state: RootState) => state.message.userEmail;

export const selectUserLinkedinMsgs = (state: RootState) => state.message.userLinkedinMsgs;

export const selectSMS = (state: RootState) => state.message.userLinkedinMsgs;

export const selectMsgProjectId = (state: RootState) => state.message.projectId;

export const selectMsgType = (state: RootState) => state.message.type;

export const selectMsgStatus = (state: RootState) => state.message.status;

export const selectSmsList = (state: RootState) => state.message.userSmsList;

export const selectProjectId = (state: RootState) => state.message.projectId;

// export const selectCandidateConversation = (state: RootState) =>
export const selectCandidateConversation = createSelector(
    [(state: RootState) => state.message.userSmsList, (_: RootState, id: string | undefined) => id],
    (smsList: SmsState, id: string | undefined) => {
        if (!smsList || !id) {
            return null;
        }

        return smsList.find(({ candidate }) => candidate.id === id);
    }
);

export const selectTotalSMSConversationCount = (state: RootState) => {
    const msgStatus = state.message.status;
    let messages = state.message.userSmsList;

    if (messages === null) {
        return 0;
    }

    if (msgStatus !== "ALL") {
        messages = messages.filter((msg) => msg.conversations.some((c) => c.candidateStatus === msgStatus));
    }

    return messages?.reduce((acc, { conversations }) => {
        if (conversations.length) {
            acc += conversations.length;
        }

        return acc;
    }, 0);
};
