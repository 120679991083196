import MarketResearchData from "./MarketResearchData";

import BaseModal from "../../../triggerWorkflow/components/BaseModal";

interface IMarketResearchModal {
    projectId: number;
    onClose: () => void;
}
export function MarketResearch({ projectId, onClose }: IMarketResearchModal) {
    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                width: "75vw",
                padding: "1.5rem",
                overflow: "auto",
            }}
        >
            <MarketResearchData />
        </BaseModal>
    );
}
