import { Option } from "@/common";
import { Candidate, Order } from "@/store/reducers/all-candidates/all-candidates.types";
import dayjs from "dayjs";

export function formatDueDate(isoString: string): string {
    return "Due by " + dayjs(isoString).format("DD MMM YYYY, hh:mm a");
}

export const ACTIVITIES_SELECTED_PROJECT = {
    label: "All projects",
    value: 0,
} as const;

export function isLink(str: string): boolean {
    // Regular expression to match URLs
    const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/;

    // Test the string against the regex
    return urlRegex.test(str);
}

export const renameMapping: Record<string, string> = {
    "Job Title": "Job title",
    "Company Name": "Company name",
    "Linkedin URL": "LinkedIn",
    "List of projects": "List of projects",
    "Contact Overview": "Contact review",
    "Number of Employees": "Employee count",
    "Company Revenue": "Company revenue",
    "Project Stage": "Project stage",
    location: "Location",
} as const;

export function getKeyFromRenameMapping(value: string): string | undefined {
    return Object.keys(renameMapping).find((key) => renameMapping[key] === value);
}

const visibleColumns = [
    "Label",
    "Name",
    "Job Title",
    "Company Name",
    "Linkedin URL",
    "List of projects",
    "Contact Overview",
];

export const hiddenColumns = ["Funding", "Industry"];

export const shouldColumnHidden = (columnName: string) => {
    return hiddenColumns.includes(columnName);
};

export const shouldColumnVisible = (columnName: string) => {
    return visibleColumns.includes(columnName);
};

const sortOrder = [
    "Label",
    "Name",
    "location",
    "Job Title",
    "Company Name",
    "Linkedin URL",
    "List of projects",
    "Contact Overview",
    "Project Stage",
    "Funding",
    "Industry",
    "Company Revenue",
    "Number of Employees",
    "Email",
];

export const defaultSortOrder = (values: string[]) => {
    // Sort the input array based on the sortOrder
    const sortedArray = values.sort((a, b) => {
        const indexA = sortOrder.indexOf(a);
        const indexB = sortOrder.indexOf(b);

        // Elements not found in sortOrder array will be moved to the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
    });

    return sortedArray;
};

export const defaultOrder = (values: string[]) => {};

export const labelOptions: Option[] = [
    {
        label: "Candidate",
        value: "Candidate",
    },
    {
        label: "Network",
        value: "Network",
    },
    {
        label: "Client",
        value: "Client",
    },
    {
        label: "Investor",
        value: "Investor",
    },
    {
        label: "Prospects",
        value: "Prospects",
    },
];

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator(a: Candidate, b: Candidate, orderBy: keyof Candidate): number {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    const doNotSort = orderBy === "Linkedin URL";

    if (!aValue || !bValue || doNotSort) {
        return 0;
    }

    if (Array.isArray(aValue) && Array.isArray(bValue)) {
        if (aValue.length === bValue.length) {
            return 0;
        }

        if (aValue.length < bValue.length) {
            return 1;
        } else {
            return -1;
        }
    }

    if (aValue === "-" && bValue === "-") {
        return 0;
    }

    if (aValue === "-") {
        return -1;
    }

    if (bValue === "-") {
        return 1;
    }

    if (bValue < aValue) {
        return -1;
    }

    if (bValue > aValue) {
        return 1;
    }

    return 0;
}

export function getComparator<Key extends keyof Candidate>(
    order: Order,
    orderBy: Key
): (a: Candidate, b: Candidate) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
