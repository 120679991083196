import { type VariantProps, cva } from "class-variance-authority";
import { X } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const chipVariants = cva(
    "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
                secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
                destructive: "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                outline: "text-foreground border",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

export interface ChipProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, "color">,
        VariantProps<typeof chipVariants> {
    label?: React.ReactNode; // For consistency with MUI's Chip
    onDelete?: () => void;
    deleteIcon?: React.ReactNode; // Customizable delete icon
    disabled?: boolean;
    children?: React.ReactNode;
}

const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
    (
        {
            className,
            variant,
            label,
            onDelete,
            deleteIcon = <X className="h-3 w-3" />,
            disabled = false,
            children,
            ...props
        },
        ref
    ) => {
        return (
            <div
                ref={ref}
                className={cn(
                    chipVariants({ variant }),
                    "inline-flex items-center",
                    {
                        "opacity-50 pointer-events-none": disabled,
                    },

                    className
                )}
                aria-disabled={disabled || undefined}
                {...props}
            >
                {label || children}
                {onDelete && (
                    <button
                        type="button"
                        onClick={onDelete}
                        className="ml-1 flex h-4 w-4 items-center justify-center rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                        disabled={disabled}
                        aria-label="Remove"
                    >
                        {deleteIcon}
                    </button>
                )}
            </div>
        );
    }
);

Chip.displayName = "Chip";

export { Chip, chipVariants };
