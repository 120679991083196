
import WorkflowPane from "./workflows/WorkflowPane";

export default function Inbox() {
    return (
        <div style={{ height: "100%" }}>
            <WorkflowPane />
        </div>
    );
}
