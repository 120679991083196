import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CustomTab, CustomTabsContainer } from "../../../components/CustomTabs";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { useWorkflowLocationState } from "../../triggerWorkflow/components/BaseTemplates";

import {
    fetchCandidateNames,
    selectTotalCandidatesCount,
    setEditTemplatesModal,
} from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import { selectHyperPersonalizeMode, setWorkflowMode } from "@/store/reducers/workflow/workflow.reducer";


type InfoBannerProps = {
    children: React.ReactNode;
};

function InfoBanner({ children }: InfoBannerProps) {
    return (
        <Typography
            style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: "73px",
                backgroundColor: "white",
                borderRadius: "0.7rem",
                padding: "1rem",
                zIndex: 1,
            }}
        >
            {children}
        </Typography>
    );
}

function CustomTabWithCount() {
    const isLoading = useSelector(checkIfLoading(fetchCandidateNames.type));
    const count = useSelector(selectTotalCandidatesCount);
    return (
        <CustomTab
            sx={{
                backgroundColor: isLoading ? "transparent" : "rgba(0, 0, 0, 0.1)",
                borderRadius: "50%",
                width: "50px",
                height: "40px",
            }}
            label={isLoading ? <CircularProgress size={25} sx={{ padding: 0 }} /> : count}
        />
    );
}

export default function WorkflowTabs() {
    const dispatch = useDispatch();
    const [isStandardHover, setStandardHover] = useState(false);
    const [isPersonalizedHover, setPersonalizedHover] = useState(false);
    const isHyperPersonalizedMode = useSelector(selectHyperPersonalizeMode);
    const { projectId, candidateIds, isAllCandidatesSelected, filters, searchQuery, showByPersonalEmails } =
        useWorkflowLocationState();
    const [value, setValue] = useState(() => (isHyperPersonalizedMode ? 1 : 0));
    const enableStandardHover = () => setStandardHover(true);
    const disableStandardHover = () => setStandardHover(false);

    const enablePersonalizedHover = () => setPersonalizedHover(true);
    const disablePersonalizedHover = () => setPersonalizedHover(false);

    useEffect(() => {
        dispatch(
            fetchCandidateNames({
                projectID: projectId,
                filters,
                searchQuery,
                candidateIDs: candidateIds,
                showByPersonalEmails,
                isAllCandidatesSelected,
            })
        );
    }, [projectId, candidateIds, isAllCandidatesSelected, filters, searchQuery, showByPersonalEmails]);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        dispatch(setWorkflowMode(newValue === 0 ? "REGULAR_WORKFLOW" : "PERSONALIZED_WORKFLOW"));
        if (newValue === 1 && !isHyperPersonalizedMode) {
            dispatch(setEditTemplatesModal(true));
        }
    };

    return (
        <Box sx={{ position: "relative" }}>
            <CustomTabsContainer value={value} onChange={handleChange}>
                <CustomTab
                    label="Standard Outreach"
                    onMouseEnter={enableStandardHover}
                    onMouseLeave={disableStandardHover}
                />
                <CustomTab
                    label="Hyper-Personalized Outreach"
                    onMouseEnter={enablePersonalizedHover}
                    onMouseLeave={disablePersonalizedHover}
                />
                <CustomTabWithCount />
            </CustomTabsContainer>
            {isStandardHover && (
                <InfoBanner>
                    Empowering users to craft personalized messages from scratch for effective communication.
                </InfoBanner>
            )}
            {isPersonalizedHover && (
                <InfoBanner>
                    Leveraging AI to create tailored messages by analyzing candidate information and job roles, saving
                    time and effort while ensuring impactful outreach.{" "}
                </InfoBanner>
            )}
        </Box>
    );
}
