import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export default function MUILoader() {
    return (
        <Stack style={{ height: "85vh", overflow: "auto" }} justifyContent="center" alignItems="center">
            <CircularProgress
                size={40}
                style={{
                    color: "#0891b2",
                }}
            />
        </Stack>
    );
}
