import { TCustomDateLabel } from "../../../store/apis/analytics/analytics.types";
import CustomDateRangePickerEl from "../../../molecules/CustomDateRangePicker/CustomDateRangePicker";
import { TDate } from "../../../molecules/DateRangePicker/DateRangePicker";
import { customDateOptions } from "../constants";

interface AnalyticsDateRangePickerProps {
    value: TDate[];
    onChange: (value: TDate[]) => void;
    defaultOption?: TCustomDateLabel;
    disabled?: boolean;
    hideLabel?: boolean;
}

export default function AnalyticsDateRangePicker(props: AnalyticsDateRangePickerProps) {
    const { hideLabel, ...rest } = props;
    return (
        <CustomDateRangePickerEl
            options={customDateOptions}
            rangePickerProps={{
                disableFuture: true,
                disableHighlightToday: true,
                buttonProps: { sx: { height: 40 } },
                clearable: true,
                hideLabel,
            }}
            {...rest}
        />
    );
}
