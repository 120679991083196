import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "@/lib/utils";

const TooltipProvider = TooltipPrimitive.Provider;

type TooltipProps = React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    title: string;
    children: React.ReactNode;
    position?: "top" | "right" | "bottom" | "left";
};

const Tooltip = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, TooltipProps>(
    ({ title, children, position = "bottom", ...props }, ref) => (
        <TooltipProvider>
            <TooltipPrimitive.Root>
                <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
                <TooltipPrimitive.Content
                    ref={ref}
                    side={position}
                    align="center"
                    sideOffset={5}
                    className={cn(
                        "w-[400px] z-50 overflow-hidden rounded-md bg-gray-900 px-3 py-1.5 text-xs text-white shadow-lg",
                        "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95",
                        "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                        props.className
                    )}
                    {...props}
                >
                    {title}
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Root>
        </TooltipProvider>
    )
);

Tooltip.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip };
