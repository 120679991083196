import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    IAddToATSPayload,
    IAddToBambooHRPayload,
    IAddToGreenhousePayload,
    IAddToLeverPayload,
    IAddToNucleusPayload,
    IAddToRecruiterFlowPayload,
    IAddToZohoRecruitPayload,
    IBambooHRJob,
    IBambooHRStatus,
    IFetchLaborEdgePayload,
    IGetFromBambooHRPayload,
    IGetFromGreenhouse,
    IGetFromLever,
    IGetFromRecruiterFlowPayload,
    IGetGreenhouseJobStagesPayload,
    IGetRecruiterFlowJobsStagesPayload,
    IGreenhouseJob,
    IGreenhouseJobStage,
    IIntegrationRootState,
    IJazzHRJob,
    IJazzHRJobStage,
    IJobviteJob,
    ILeverJob,
    ILeverStage,
    ILoxoCredentials,
    INucleusProject,
    IPostBambooHRCredentialsPayload,
    IPostGreenhouseCredentialsPayload,
    IPostJobviteCredentialsPayload,
    ISendSlackInvitationPayload,
    IZohoAddPayload,
    IZohoHRJobs,
    IZohoHRStatus,
} from "./integrations.types";

import { IActionPayload } from "../loaders.reducer";

import { RootState } from "@/store";

const initialState: IIntegrationRootState = {
    loxoAtsCredentials: {
        agencySlug: "",
        apiKey: "",
        apiPassword: "",
    },
    greenhouseJobs: [],
    greenhouseJobStages: [],
    leverJobs: [],
    leverStages: [],
    jobviteJobs: [],
    leverExportCandidates: [],
    bambooHRJobs: [],
    bambooHRStatuses: [],
    zohoHRJobs: [],
    zohoHRStatuses: [],
    jazzHRJobs: [],
    laborEdgeJobs: [],
    jazzHRJobStages: [],
    laborEdgeStatuses: [],
    laborEdgeSpecialties: [],
    laborEdgeProfessions: [],
    recruiterFlowJobs: [],
    recruiterFlowStages: [],
    nucleusJobs: [],
    nucleusStages: [],
    nucleusProjects: [],
};

const reducers = {
    fetchLoxoCredentials(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    postLoxoCredentials(state: typeof initialState, { payload }: { payload: ILoxoCredentials & IActionPayload }) {},

    postGreenhouseCredentials(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IPostGreenhouseCredentialsPayload & IActionPayload;
        }
    ) {},
    setLoxoCredentials(state: typeof initialState, { payload }: { payload: ILoxoCredentials }) {
        state.loxoAtsCredentials = payload;
    },
    addToLoxoAgency(state: typeof initialState, { payload }: { payload: IAddToATSPayload & IActionPayload }) {},
    addToGreenhouseAgency(
        state: typeof initialState,
        { payload }: { payload: IAddToGreenhousePayload & IActionPayload }
    ) {},

    getRecruiterFlowJobs(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    getRecruiterFlowStages(state: typeof initialState, action: PayloadAction<IGetRecruiterFlowJobsStagesPayload>) {},

    setRecruiterFlowJobs(state: typeof initialState, { payload }: { payload: any[] }) {
        state.recruiterFlowJobs = payload;
    },
    setRecruiterFlowStages(state: typeof initialState, { payload }: { payload: any[] }) {
        state.recruiterFlowStages = payload;
    },

    getGreenhouseJobs(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    getGreenhouseJobStages(state: typeof initialState, action: PayloadAction<IGetGreenhouseJobStagesPayload>) {},
    setGreenhouseJobs(state: typeof initialState, { payload }: { payload: IGreenhouseJob[] }) {
        state.greenhouseJobs = payload;
    },
    setGreenhouseJobStages(state: typeof initialState, action: PayloadAction<IGreenhouseJobStage[]>) {
        state.greenhouseJobStages = action.payload;
    },
    addToRecruiterFlow(
        state: typeof initialState,
        { payload }: { payload: IAddToRecruiterFlowPayload & IActionPayload }
    ) {},
    getFromRecruiterFlow(
        state: typeof initialState,
        { payload }: { payload: IGetFromRecruiterFlowPayload & IActionPayload }
    ) {},
    getFromGreenhouse(state: typeof initialState, action: PayloadAction<IGetFromGreenhouse>) {},
    sendSlackInvitation(state: typeof initialState, { payload }: { payload: ISendSlackInvitationPayload }) {},
    revokeSlackInvitation(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    getFromZohoRecruit(state: typeof initialState, { payload }: { payload: IZohoAddPayload }) {},
    getLeverJobs(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setLeverJobs(state: typeof initialState, { payload }: { payload: ILeverJob[] }) {
        state.leverJobs = payload;
    },
    getLeverStages(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setLeverStages(state: typeof initialState, { payload }: { payload: ILeverStage[] }) {
        state.leverStages = payload;
    },
    addToLever(state: typeof initialState, { payload }: { payload: IAddToLeverPayload & IActionPayload }) {},
    getFromLever(state: typeof initialState, { payload }: { payload: IGetFromLever & IActionPayload }) {},
    postJobviteCredentials(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IPostJobviteCredentialsPayload & IActionPayload;
        }
    ) {},
    addToJobvite(state: typeof initialState, { payload }: { payload: IAddToGreenhousePayload & IActionPayload }) {},
    getJobviteJobs(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setJobviteJobs(state: typeof initialState, { payload }: { payload: IJobviteJob[] }) {
        state.jobviteJobs = payload;
    },
    setLeverExportCandidates(state: typeof initialState, action: PayloadAction<any>) {
        state.leverExportCandidates = action.payload;
    },
    postBambooHRCredentials(state: typeof initialState, action: PayloadAction<IPostBambooHRCredentialsPayload>) {},
    getBambooHRJobs(state: typeof initialState, action: PayloadAction) {},
    setBambooHRJobs(state: typeof initialState, action: PayloadAction<IBambooHRJob[]>) {
        state.bambooHRJobs = action.payload;
    },
    getBambooHRStatuses(state: typeof initialState, action: PayloadAction) {},
    setBambooHRStatuses(state: typeof initialState, action: PayloadAction<IBambooHRStatus[]>) {
        state.bambooHRStatuses = action.payload;
    },
    getLaborEdgeJobs(state: typeof initialState, { payload }: { payload: any }, action: PayloadAction) {},
    setLaborEdgeJobs(state: typeof initialState, action: PayloadAction<IZohoHRJobs[]>) {
        state.laborEdgeJobs = action.payload;
    },

    getZohoHRJobs(state: typeof initialState, action: PayloadAction) {},
    setZohoHRJobs(state: typeof initialState, action: PayloadAction<IZohoHRJobs[]>) {
        state.zohoHRJobs = action.payload;
    },
    getZohoHRStatuses(state: typeof initialState, action: PayloadAction) {},
    setZohoHRStatuses(state: typeof initialState, action: PayloadAction<IZohoHRStatus[]>) {
        state.zohoHRStatuses = action.payload;
    },
    addToBambooHR(state: typeof initialState, action: PayloadAction<IAddToBambooHRPayload>) {},
    addToZohoRecruit(state: typeof initialState, action: PayloadAction<IAddToZohoRecruitPayload>) {},
    getFromBambooHR(state: typeof initialState, action: PayloadAction<IGetFromBambooHRPayload>) {},
    getJazzHRJobs(state: typeof initialState, action: PayloadAction) {},
    addJazzHRJob(state: typeof initialState, action: PayloadAction<IJazzHRJob>) {},
    pushJazzHRJobs(state: typeof initialState, action: PayloadAction<IJazzHRJob>) {},
    setJazzHRJobs(state: typeof initialState, { payload }: { payload: IJazzHRJob[] }) {
        state.jazzHRJobs = payload;
    },
    getJazzHRJobStages(state: typeof initialState, action: PayloadAction) {},
    setJazzHRJobStages(state: typeof initialState, { payload }: { payload: IJazzHRJobStage[] }) {
        state.jazzHRJobStages = payload;
    },
    getCandidatesFromLabEdge(state: typeof initialState, action: PayloadAction<IFetchLaborEdgePayload>) {},
    getLaborEdgeStatuses(state: typeof initialState, action: PayloadAction) {},
    setLaborEdgeStatuses(
        state: typeof initialState,
        action: PayloadAction<IIntegrationRootState["laborEdgeStatuses"]>
    ) {
        state.laborEdgeStatuses = action.payload;
    },

    getLaborEdgeSpecialties(state: typeof initialState, action: PayloadAction) {},
    setLaborEdgeSpecialties(
        state: typeof initialState,
        action: PayloadAction<IIntegrationRootState["laborEdgeSpecialties"]>
    ) {
        state.laborEdgeSpecialties = action.payload;
    },

    getLaborEdgeProfessions(state: typeof initialState, action: PayloadAction) {},
    setLaborEdgeProfessions(
        state: typeof initialState,
        action: PayloadAction<IIntegrationRootState["laborEdgeProfessions"]>
    ) {
        state.laborEdgeProfessions = action.payload;
    },

    sendCandidatesToLabEdge(
        state: typeof initialState,
        action: PayloadAction<{
            candidateIds: string[];
            professionId: number;
            specialtyId: number;
            jobTypeId: string;
            jobId: number;
            statusId: number;
            primarySpecialtyId: number;
            projectId: number;
        }>
    ) {},
    resetIntegration(state: typeof initialState) {},
    getNucleusJobs(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setNucleusJobs(state: typeof initialState, { payload }: { payload: any[] }) {
        state.nucleusJobs = payload;
    },
    getNucleusStages(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setNucleusStages(state: typeof initialState, { payload }: { payload: any[] }) {
        state.nucleusStages = payload;
    },
    addToNucleus(state: typeof initialState, { payload }: { payload: IAddToNucleusPayload & IActionPayload }) {},

    getNucleusProjects(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setNucleusProjects(state: typeof initialState, { payload }: { payload: INucleusProject[] }) {
        state.nucleusProjects = payload;
    },
};

export const integrationSlice = createSlice({
    name: "integrations",
    initialState,
    reducers,
});

export default integrationSlice.reducer;

export const {
    setLeverExportCandidates,
    fetchLoxoCredentials,
    postLoxoCredentials,
    postGreenhouseCredentials,
    setLoxoCredentials,
    resetIntegration,
    addToLoxoAgency,
    addToGreenhouseAgency,
    getGreenhouseJobs,
    setGreenhouseJobs,
    sendSlackInvitation,
    revokeSlackInvitation,
    getFromZohoRecruit,
    addToZohoRecruit,
    getLeverJobs,
    setLeverJobs,
    getLeverStages,
    getLaborEdgeJobs,
    setLaborEdgeJobs,
    setLeverStages,
    addToLever,
    postJobviteCredentials,
    addToJobvite,
    getJobviteJobs,
    setJobviteJobs,
    getRecruiterFlowJobs,
    getRecruiterFlowStages,
    setRecruiterFlowJobs,
    setRecruiterFlowStages,
    getFromLever,
    getFromGreenhouse,
    getGreenhouseJobStages,
    setGreenhouseJobStages,
    postBambooHRCredentials,
    getBambooHRJobs,
    setBambooHRJobs,
    getBambooHRStatuses,
    setBambooHRStatuses,
    addToBambooHR,
    getFromBambooHR,
    getZohoHRJobs,
    setZohoHRJobs,
    getZohoHRStatuses,
    setZohoHRStatuses,
    getJazzHRJobs,
    addJazzHRJob,
    setJazzHRJobs,
    pushJazzHRJobs,
    getJazzHRJobStages,
    setJazzHRJobStages,
    getCandidatesFromLabEdge,
    getLaborEdgeStatuses,
    setLaborEdgeStatuses,
    getLaborEdgeSpecialties,
    setLaborEdgeSpecialties,
    getLaborEdgeProfessions,
    setLaborEdgeProfessions,
    sendCandidatesToLabEdge,
    getNucleusJobs,
    setNucleusJobs,
    getNucleusStages,
    getFromRecruiterFlow,
    addToRecruiterFlow,
    setNucleusStages,
    addToNucleus,
    getNucleusProjects,
    setNucleusProjects,
} = integrationSlice.actions;

export const selectGreenhouseJobs = (state: RootState) => state.integrations.greenhouseJobs;
export const selectGreenhouseJobStages = (state: RootState) => state.integrations.greenhouseJobStages;
export const selectLeverJobs = (state: RootState) => state.integrations.leverJobs;
export const selectLeverStages = (state: RootState) => state.integrations.leverStages;
export const selectJobviteJobs = (state: RootState) => state.integrations.jobviteJobs;
export const selectLeverExportCandidates = (state: RootState) => state.integrations.leverExportCandidates;
export const selectBambooHRJobs = (state: RootState) => state.integrations.bambooHRJobs;
export const selectBambooHRStatuses = (state: RootState) => state.integrations.bambooHRStatuses;
export const selectZohoHRJobs = (state: RootState) => state.integrations.zohoHRJobs;
export const selectZohoHRStatuses = (state: RootState) => state.integrations.zohoHRStatuses;
export const selectJazzHRJobs = (state: RootState) => state.integrations.jazzHRJobs;
export const selectJazzHRJobStages = (state: RootState) => state.integrations.jazzHRJobStages;
export const selectLaborEdgeStatuses = (state: RootState) => state.integrations.laborEdgeStatuses;
export const selectLaborEdgeSpecialties = (state: RootState) => state.integrations.laborEdgeSpecialties;
export const selectLaborEdgeProfessions = (state: RootState) => state.integrations.laborEdgeProfessions;
export const selectLaborEdgeJobs = (state: RootState) => state.integrations.laborEdgeJobs;
export const selectNucleusJobs = (state: RootState) => state.integrations.nucleusJobs;
export const selectNucleusStages = (state: RootState) => state.integrations.nucleusStages;
export const selectNucleusProjects = (state: RootState) => state.integrations.nucleusProjects;
export const selectRecruiterFlowJobs = (state: RootState) => state.integrations.recruiterFlowJobs;
export const selectRecruiterFlowJobStages = (state: RootState) => state.integrations.recruiterFlowStages;
