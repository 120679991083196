import React from 'react';
import { Box, Divider, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

const DoNotDisturb: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [enableDnd, setEnableDnd] = React.useState(false);

    React.useEffect(() => {
        setEnableDnd(user?.twilioConfig?.isDndEnabled || false);
    }, [user]);

    return (
        <>
            <div style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}> Do Not Disturb </div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                Enable Do Not Disturb to stop receiving calls temporarily.
                <Switch
                    checked={enableDnd}
                    onChange={(e) => {
                        setEnableDnd(e.target.checked);
                        dispatch(
                            updateUser({
                                ...user,
                                twilioConfig: {
                                    ...user.twilioConfig,
                                    isDndEnabled: e.target.checked,
                                },
                                action: updateUser.type,
                            })
                        );
                    }}
                />
            </Box>
            <Divider />
        </>
    );
};

export default DoNotDisturb;