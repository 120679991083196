import ChatsPane from "./chats/ChatsPane";
import { Filters } from "./filters/Filters";
import MessagesPane from "./messages/MessagesPane";

export function Inbox() {
    return (
        <div
            className="h-full border border-divider flex-1 w-full mx-auto pt-0 grid"
            style={{
                gridTemplateColumns: window.innerWidth < 640 ? "1fr" : "minmax(min-content, min(400px, 400px)) 1fr",
            }}
        >
            <div
                className="hidden sm:block border-r border-b border-divider absolute"
                style={{
                    zIndex: `calc(11 * (var(--FiltersPane-slideIn, 0)))`,
                    width: `calc(240px * (var(--FiltersPane-slideIn, 0)))`,
                }}
            >
                <Filters />
            </div>

            <div
                className="w-full h-full z-10 transition-transform sm:sticky sm:translate-x-0 fixed"
                style={{
                    transform:
                        window.innerWidth < 640
                            ? "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))"
                            : "none",
                    transition: "transform 0.4s, width 0.4s",
                    maxWidth: "400px",
                }}
            >
                <ChatsPane />
            </div>
            <MessagesPane />
        </div>
    );
}
