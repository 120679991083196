import { ChangeEvent } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";

import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";
import { selectUnsubscribeEmail, toggleUnsubscribeEmail } from "@/store/reducers/workflow/workflow.reducer";

export default function UnsubscribeEmailToggle() {
    const dispatch = useDispatch();
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const enableUnsubscribeEmail = useSelector(selectUnsubscribeEmail);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(toggleUnsubscribeEmail());
    };

    if (!isEasyGrowth) return null;

    return (
        <Stack>
            <Tooltip arrow title={undefined}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={enableUnsubscribeEmail}
                            onChange={handleChange}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    }
                    sx={{
                        "& .MuiFormControlLabel-label": {
                            fontSize: 14,
                        },
                    }}
                    label={`Unsubscribe link is ${enableUnsubscribeEmail ? "enabled" : "disabled"}`}
                />
            </Tooltip>
            <FormHelperText>
                {enableUnsubscribeEmail
                    ? `Recipients will see a line 'If you don't want to receive such emails going ahead, just click here to unsubscribe' at the bottom of your email`
                    : `Recipients will not receive an unsubscribe or opt-out link in your email campaign`}
            </FormHelperText>
        </Stack>
    );
}
