import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Signin/Signin.reducer";

const UserProfile: React.FC = () => {
    const user = useSelector(selectUser);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: 14,
                marginTop: 2,
                marginBottom: 2,
            }}
        >
            <Box sx={{ display: "flex", fontWeight: 400, flexDirection: "column" }}>
                <Box>{user?.displayName} </Box>
                <Box>
                    Twillio phone number : {user?.twilioPhoneNumber}
                    <Tooltip
                        title={
                            <>
                                Free caller registry: Add to the registry to improve call delivery rates and increase
                                trust by ensuring their calls are not marked as spam or fraudulent by call-blocking apps
                                or carriers. Refer to Twilio article:
                                <a
                                    href="https://help.twilio.com/articles/9375068873499-Outbound-calls-blocked-or-labeled-as-spam-or-scam-likely"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "#0891b2",
                                        backgroundColor: "white",
                                        marginLeft: 5,
                                        padding: 2,
                                    }}
                                >
                                    Link
                                </a>
                            </>
                        }
                    >
                        <IconButton size="small">
                            <InfoOutlinedIcon fontSize="small" sx={{ fontSize: "15px" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ mt: 2 }}>
                    Audio & Devices
                    <Tooltip
                        title={
                            "Kindly keep in mind that the audio devices being used for the browser will also be used for the phone call."
                        }
                    >
                        <IconButton size="small">
                            <InfoOutlinedIcon fontSize="small" sx={{ fontSize: "15px" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};

export default UserProfile;
