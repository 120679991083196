import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import ModalFilter from "../CallFilter";
import AudioPlayer from "../PlayAudioRecording";

import { getTwilloCallLogs, selectTwilloCalls } from "@/store/reducers/project/project.reducer";

const DirectionCell = ({ original }) => (
    <div style={{ marginLeft: 10 }}>
        {original.direction === "OUTGOING" ? (
            <CallMadeOutlinedIcon
                color={
                    original?.statuses[original?.statuses?.length - 2]?.status === "completed" ||
                        original?.statuses[original?.statuses?.length - 2]?.status === "ringing"
                        ? "success"
                        : "error"
                }
            />
        ) : (
            <CallMadeOutlinedIcon
                style={{ transform: "rotate(180deg)" }}
                color={
                    original?.statuses[original?.statuses?.length - 2]?.status === "completed" ||
                        original?.statuses[original?.statuses?.length - 2]?.status === "ringing"
                        ? "success"
                        : "error"
                }
            />
        )}
    </div>
);

const CallHistoryTab = () => {
    const [twilioCallsData, setTwilioCallsData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 25,
    });
    const [filter, setFilter] = useState({
        from: "",
        to: "",
        callType: "",
        fromDate: "",
        toDate: "",
        candidateName: "",
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const dispatch = useDispatch();
    const twilloCalls = useSelector(selectTwilloCalls);
    const isLoading = useSelector(checkIfLoading(getTwilloCallLogs.type));

    useEffect(() => {
        setTwilioCallsData(twilloCalls?.callLogs);
    }, [twilloCalls?.callLogs]);

    useEffect(() => {
        dispatch(getTwilloCallLogs({ page: pagination.pageIndex, limit: pagination.pageSize, filter: filter }));
    }, [pagination.pageIndex, pagination.pageSize, isFilterOpen]);

    const HeaderText = ({ column, title }: any) => (
        <Tooltip title={title || column.columnDef.header}>
            <Typography component="span" fontSize={12}>
                {column.columnDef.header}
            </Typography>
        </Tooltip>
    );

    const columns = useMemo(
        () => [
            {
                header: "",
                accessorKey: "direction",
                Header: HeaderText,
                size: 80,
                Cell: ({ row: { original } }) => <DirectionCell original={original} />,
            },
            {
                header: "Candidate",
                accessorKey: "candidateName",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return <div>{original.candidateName}</div>;
                },
            },
            {
                header: "From",
                accessorKey: "from",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return (
                        <div style={{ position: "relative" }}>
                            {original.from?.split(":")[1] ? original.from?.split(":")[1] : original.from}
                        </div>
                    );
                },
            },
            {
                header: "To",
                accessorKey: "to",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return original.to?.split(":")[1] ? original.to?.split(":")[1] : original.to;
                },
            },
            {
                header: "Duration",
                accessorKey: "callDuration",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return (original.callDuration || 0) + " " + "s";
                },
            },
            {
                header: "Status",
                accessorKey: "status",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return (
                        <Chip
                            size="small"
                            sx={{ fontSize: 10 }}
                            label={
                                original?.statuses?.length > 1
                                    ? original?.statuses[original?.statuses?.length - 2]?.status !== "ringing"
                                        ? original?.statuses[original?.statuses?.length - 2]?.status
                                        : "completed"
                                    : "cancelled"
                            }
                        />
                    );
                },
            },
            {
                header: "Date",
                accessorKey: "createdAt",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return dayjs(original?.createdAt).format("D/M/YYYY, h:mm A");
                },
            },
            {
                header: "Recording",
                accessorKey: "callRecording",
                Header: HeaderText,
                Cell({ row: { original } }) {
                    return original.callRecording ? <AudioPlayer recording={original.callRecording} /> : null;
                },
            },
        ],
        [twilioCallsData]
    );

    const table = useMaterialReactTable({
        enableRowVirtualization: true,
        enableColumnVirtualization: true,
        columns,
        data: twilioCallsData || [],
        enableColumnOrdering: true,
        enableColumnPinning: true,
        enableColumnResizing: true,
        enableStickyHeader: true,
        enablePagination: true,
        manualPagination: true,
        onPaginationChange: (newPaginationUpdater) => {
            const newPagination =
                typeof newPaginationUpdater === "function" ? newPaginationUpdater(pagination) : newPaginationUpdater;
            setPagination({
                pageIndex: newPagination.pageIndex,
                pageSize: newPagination.pageSize,
            });
        },
        rowCount: twilloCalls?.totalDocuments,
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100, 500],
            shape: "rounded",
            variant: "outlined",
        },
        state: {
            pagination,
            isLoading,
        },
    });

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ModalFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                />
            </Box>
            <MaterialReactTable table={table} />
        </>
    );
};

export default CallHistoryTab;
