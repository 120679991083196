import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import {
    addToZohoRecruit,
    getFromZohoRecruit,
    getZohoHRJobs,
    getZohoHRStatuses,
    selectZohoHRJobs,
    selectZohoHRStatuses,
} from "@/store/reducers/integrations/integrations.reducer";

import { Button } from "@/ui";

type ZohoHRModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export default function ZohoHRModal({ open, onClose, id, selectedCandidateIds, variant }: ZohoHRModalProps) {
    const dispatch = useDispatch();
    const zohoHRJobs = useSelector(selectZohoHRJobs);
    const zohoHRStatuses = useSelector(selectZohoHRStatuses);

    const isJobsLoading = useSelector(checkIfLoading(getZohoHRJobs.type));
    const isStatusesLoading = useSelector(checkIfLoading(getZohoHRStatuses.type));

    const isCandidateLoading = useSelector(checkIfLoading(getFromZohoRecruit.type));

    const isDataLoading = isJobsLoading || isStatusesLoading;
    const isDisabled = isCandidateLoading;
    const isVariantFetch = variant === "FETCH";

    const [jobId, setJobId] = useState("");
    const [statusId, setStatusId] = useState("");
    const [subStatusId, setSubStatusId] = useState("");

    useEffect(() => {
        if (open) {
            dispatch(getZohoHRJobs());
            dispatch(getZohoHRStatuses());
        }
    }, [open]);

    const handleCloseModal = () => {
        setJobId("");
        setStatusId("");
        setSubStatusId("");
        onClose();
    };

    const handlePostCandidate = () => {
        if (!jobId) {
            dispatch(setErrorNotification("Please select all the fields"));
            return;
        }

        if (isVariantFetch) {
            dispatch(
                getFromZohoRecruit({
                    projectId: Number(id),
                    jobId: jobId,
                    statusId,
                    onSuccess: handleCloseModal,
                })
            );
        } else {
            if (!subStatusId) {
                dispatch(setErrorNotification("Please select all the fields"));
                return;
            }
            dispatch(
                addToZohoRecruit({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    jobId,
                    statusId,
                    subStatusId,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    const statusArraryForPushCandidates = zohoHRStatuses.flatMap((status) => {
        const statusObject = {
            label: status.label,
            value: status.value,
            parent: true,
            parentId: null,
        };
        const subStatusObjectArray = status.subJobStatus.map((subStatus) => {
            return {
                label: subStatus.label,
                value: subStatus.value,
                parent: false,
                parentId: status.value,
            };
        });
        return [statusObject, ...subStatusObjectArray];
    });

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"xs"}>
            <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} ZohoHR</DialogTitle>
            <DialogContent>
                {isDataLoading ? (
                    <Stack alignItems="center" mt={2}>
                        <CircularProgress size={24} />
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        {zohoHRJobs.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select job:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={jobId}
                                    onChange={(e) => setJobId(e.target.value)}
                                    select
                                    placeholder="Select..."
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                    }}
                                >
                                    {zohoHRJobs.map((job) => (
                                        <MenuItem
                                            key={job.Job_Opening_ID}
                                            value={isVariantFetch ? job.Job_Opening_ID : job.id}
                                        >
                                            {job.Posting_Title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <Typography fontSize={14} textAlign="center" color="red">
                                No jobs found. Create one on your dashboard
                            </Typography>
                        )}
                        {zohoHRStatuses.length ? (
                            <>
                                {isVariantFetch ? (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Typography>Select status:</Typography>

                                        <TextField
                                            hiddenLabel
                                            size="small"
                                            value={statusId}
                                            onChange={(e) => setStatusId(e.target.value)}
                                            select
                                            placeholder="Select..."
                                            sx={{
                                                "& .MuiSelect-select.MuiInputBase-input": {
                                                    fontSize: 14,
                                                },
                                            }}
                                        >
                                            {zohoHRStatuses.map((status) => {
                                                return (
                                                    <MenuItem key={status.value} value={status.value}>
                                                        {status.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Stack>
                                ) : (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Typography>Select status:</Typography>

                                        <TextField
                                            hiddenLabel
                                            size="small"
                                            value={subStatusId}
                                            onChange={(e) => {
                                                setSubStatusId(e.target.value);
                                                const subStatusObject = statusArraryForPushCandidates.find((item) => {
                                                    return item.value === e.target.value;
                                                });
                                                const parent = statusArraryForPushCandidates.find((item) => {
                                                    return item.value === subStatusObject?.parentId;
                                                });
                                                setStatusId(parent?.value || "");
                                            }}
                                            select
                                            placeholder="Select..."
                                            sx={{
                                                "& .MuiSelect-select.MuiInputBase-input": {
                                                    fontSize: 14,
                                                },
                                            }}
                                        >
                                            {statusArraryForPushCandidates.map((item) => {
                                                return (
                                                    <MenuItem
                                                        sx={(theme) => ({
                                                            color: item.parent ? theme.palette.grey[900] : "inherit",
                                                            background: item.parent
                                                                ? theme.palette.grey[200]
                                                                : "inherit",
                                                        })}
                                                        disabled={item.parent}
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Stack>
                                )}
                            </>
                        ) : (
                            <Typography fontSize={14} textAlign="center" color="red">
                                No statuses found. Create one on your dashboard
                            </Typography>
                        )}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Button disabled={isDisabled} variant="outline" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button disabled={isDisabled} onClick={handlePostCandidate}>
                    <ButtonTextWithLoading isLoading={false} text="Confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
