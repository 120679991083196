import AddIcon from "@mui/icons-material/Add";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import EditIcon from "@mui/icons-material/Edit";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Radio,
    RadioGroup,
    Rating,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { withConfigModeHOC } from "../../hocs/withConfigMode";
import { useCandidateModalContext } from "../../pages/project/components/candidateModal/Context";
import style from "../../pages/project/components/profileTab/profileTab.module.scss";
import {
    changeStarRating,
    refreshCandidateRating,
    selectSingleCandidateInsightGenerating,
} from "@/store/reducers/project/project.reducer";
import {
    FieldMatch,
    LeadScore,
    LeadScoreCriteria,
    StarRating,
    TypeMatch,
    TypeMatchNotFound,
} from "@/store/reducers/project/project.types";
import { useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

type TypeMatchToIconMapping = Record<TypeMatch, React.ReactNode>;

const typeMatchToIconMapping: TypeMatchToIconMapping = {
    mustNot: <ClearRoundedIcon color="error" />,
    must: <CheckRoundedIcon color="success" />,
    preferred: <DoneAllRoundedIcon color="success" />,
    empty: "",
};

type FieldToTypeMatch = Record<FieldMatch, Record<TypeMatch, string>>;
type FieldToTypeMatchNotMatch = Record<FieldMatch, Record<TypeMatchNotFound, string>>;

function fieldToTypeMappingNotMatch(criteria: LeadScoreCriteria): string {
    const { fieldMatch, valueNotFound, valueArray } = criteria;

    const mapping: FieldToTypeMatchNotMatch = {
        country: {
            valueFound: "Prospect's location did not match with any of the criteria shared.",
            valueNotFound: "Prospect's location is not mentioned.",
        },
        location: {
            valueFound: "Prospect's location did not match with any of the criteria shared.",
            valueNotFound: "Prospect's location is not mentioned.",
        },
        employeeCount: {
            valueFound: `Employee count ${valueArray?.[0] ? valueArray[0] : ""} did not match with any of the criteria shared.`,
            valueNotFound: "Employee count for the prospect's company is unavailable.",
        },
        industry: {
            valueFound: `Industry type ${(valueArray ?? []).join(", ")} did not match with any of the criteria shared.`,
            valueNotFound: "Industry type for the prospect's company is unavailable",
        },
        role: {
            valueFound: "Prospect's job title did not match with criteria shared.",
            valueNotFound: "Prospect's job title is not mentioned.",
        },
    };

    const match = mapping[fieldMatch];
    if (valueNotFound) {
        return match.valueNotFound;
    }
    return match.valueFound;
}

function fieldToTypeMapping({
    fieldMatch,
    typeMatch,
    valueMatch,
}: {
    typeMatch: TypeMatch;
    fieldMatch: FieldMatch;
    valueMatch: string;
}): string {
    const mapping: FieldToTypeMatch = {
        role: {
            mustNot: `It's junk! The prospect aligns with the 'Not a fit' job title of ${valueMatch}`,
            must: `Good fit! The prospect's title matches with ${valueMatch}`,
            preferred: `Great fit! The prospect's title matches with the preferred title ${valueMatch}`,
            empty: "",
        },
        industry: {
            mustNot: `It's junk! The prospect aligns with 'Not a fit' industry ${valueMatch}`,
            must: `Good fit! The prospect's industry matches ${valueMatch}`,
            preferred: `Great fit! The prospect's industry matches with the preferred industry ${valueMatch}`,
            empty: "",
        },
        employeeCount: {
            mustNot: `It's junk! The prospect's company has an employee count outside requirements`,
            must: `Good fit! The prospect's company has employee count matching the range ${valueMatch}`,
            preferred: `Great fit! The prospect's company has employee count matching the preferred range ${valueMatch}`,
            empty: "",
        },
        country: {
            mustNot: `It's junk! The prospect is located in an undesired location`,
            must: `Good fit! The prospect's location matches with ${valueMatch}`,
            preferred: `Great fit! The prospect's location matches with ${valueMatch}`,
            empty: "",
        },
        location: {
            mustNot: `It's junk! The prospect is located in an undesired location`,
            must: `Good fit! The prospect's location matches with ${valueMatch}`,
            preferred: `Great fit! The prospect's location matches with ${valueMatch}`,
            empty: "",
        },
    };

    return mapping[fieldMatch][typeMatch];
}

type LeadScoreRatingProps = {
    leadScore: LeadScore;
    starRating?: StarRating;
    projectId: string;
    candidateId: string;
    vettingFixed: boolean;
};

export function BDStarRating({ projectId, candidateId, vettingFixed, leadScore }: LeadScoreRatingProps) {
    const dispatch = useAppDispatch();
    const [showEditPopup, setShowEditPopup] = useState(false);
    const singleCandidateRefreshRating = useSelector(selectSingleCandidateInsightGenerating);
    const { starRating, handleStarRatingChange } = useCandidateModalContext();

    const isStarRatingChanging = useSelector(checkIfLoading(changeStarRating.type));

    const handleRefreshRating = () => {
        dispatch(
            refreshCandidateRating({
                projectId,
                candidateIds: [candidateId],
                action: refreshCandidateRating.type,
            })
        );
    };

    const isRefreshingRating = singleCandidateRefreshRating === candidateId;

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center">
                        <Typography fontWeight={600} color={"#6C6C6C"} mr={1}>
                            Prospect lead rating
                        </Typography>
                        <Rating
                            value={starRating.value || 0}
                            precision={0.5}
                            // disabled={isStarRatingChanging}x
                            onChange={(event, newValue) => {
                                dispatch(
                                    changeStarRating({
                                        ...starRating,
                                        label: newValue?.toString() || "0",
                                        value: newValue || 0,
                                        candidateId,
                                        enableFixedVetting: vettingFixed,
                                    })
                                );

                                handleStarRatingChange({
                                    ...starRating,
                                    label: newValue?.toString() || "0",
                                    value: newValue || 0,
                                });
                            }}
                        />
                    </Stack>
                }
                action={
                    <Stack flexDirection="row">
                        <IconButton
                            disabled={isRefreshingRating}
                            size="small"
                            onClick={() => setShowEditPopup(true)}
                            sx={{ marginRight: "120px" }}
                        >
                            <EditIcon sx={{ color: "#fff" }} />
                        </IconButton>
                        <Tooltip title="Refresh rating">
                            <IconButton disabled={isRefreshingRating} size="small" onClick={handleRefreshRating}>
                                <RefreshRoundedIcon
                                    sx={{
                                        animation: isRefreshingRating ? "spin 2s linear infinite" : "none",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
                sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            />
            {leadScore.score.criteria?.length ? (
                <List>
                    {leadScore.score.criteria.map((criteria, index) => {
                        const { notMatch } = criteria;
                        const text = notMatch ? fieldToTypeMappingNotMatch(criteria) : fieldToTypeMapping(criteria);
                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    notMatch ? (
                                        <HorizontalRuleIcon sx={{ color: "grey" }} />
                                    ) : (
                                        typeMatchToIconMapping[criteria.typeMatch]
                                    )
                                }
                            >
                                <ListItemText
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontSize: 14,
                                            maxWidth: "80%",
                                        },
                                    }}
                                    primary={`• ${text}`}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}

            {showEditPopup && (
                <EditDescription
                    onClose={() => setShowEditPopup(false)}
                    starRating={starRating}
                    candidateId={candidateId}
                    vettingFixed={vettingFixed}
                />
            )}
        </Card>
    );
}

export const LeadScoreRating = withConfigModeHOC<{}, LeadScoreRatingProps>({
    Eg: BDStarRating,
});

const EditDescription = ({
    onClose,
    candidateId,
    starRating,
    vettingFixed,
}: {
    onClose: () => void;
    candidateId: string;
    starRating: StarRating;
    vettingFixed?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [newStarRating, setNewStarRating] = useState<StarRating>();
    const [enableFixedVetting, setEnabledFixedVetting] = useState(() =>
        vettingFixed !== undefined ? vettingFixed : false
    );

    useEffect(() => {
        setNewStarRating(starRating);
    }, [starRating]);

    const onChangeValue = ({
        text,
        criterion,
        intent,
        index,
    }: {
        text: string;
        criterion: string;
        intent: number;
        index: number;
    }) => {
        const data: StarRating = JSON.parse(JSON.stringify(newStarRating));
        if (data?.description && data.description[index]) {
            data.description[index] = {
                text,
                criterion,
                intent,
            };
        }
        setNewStarRating(data);
    };

    const onSubmit = () => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));
        const description: any = newStarRating?.description?.filter((item) => !item?.delete);

        if (description) {
            data.description = description;
        }
        dispatch(
            changeStarRating({
                ...data,
                candidateId,
                enableFixedVetting,
            })
        );
        onClose();
    };

    const onDelete = ({ index }: { index: number }) => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));
        data.description[index].delete = true;
        setNewStarRating(data);
    };

    const onAddCriteria = () => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));
        const newDescription = {
            text: "",
            criterion: "",
            intent: 0,
            delete: true,
        };
        data.description.push(newDescription);
        setNewStarRating(data);
    };

    const onChangeStar = ({ value, key }: { value: string | number; key: string }) => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));

        if (key === "label") {
            data["label"] = value;
        } else {
            data["value"] = value;
        }
        setNewStarRating(data);
    };

    return (
        <Dialog
            open={true}
            classes={{
                paper: style.popupContainer,
            }}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Edit star rating description</Typography>
                    <FormControlLabel
                        sx={(theme) => ({
                            cursor: "pointer",
                            "& .MuiTypography-root": {
                                fontSize: theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightMedium,
                            },
                        })}
                        control={
                            <Switch
                                size="small"
                                checked={enableFixedVetting}
                                onChange={(e) => setEnabledFixedVetting(e.target.checked)}
                            />
                        }
                        label={enableFixedVetting ? "Vetting Fixed" : "Enable Fix Vetting"}
                    />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div>
                    <FormHelperText>Label</FormHelperText>
                    <TextField
                        value={newStarRating?.label}
                        fullWidth
                        onChange={(e) =>
                            onChangeStar({
                                value: e.target.value,
                                key: "label",
                            })
                        }
                    />
                    <FormHelperText>Value</FormHelperText>
                    <TextField
                        type="number"
                        value={newStarRating?.value}
                        fullWidth
                        sx={{ marginBottom: "10px" }}
                        onChange={(e) =>
                            onChangeStar({
                                value: parseFloat(e.target.value),
                                key: "value",
                            })
                        }
                    />
                    {!isEmpty(newStarRating?.description) &&
                        newStarRating?.description.map((item: any, i) => {
                            return (
                                <Stack key={i} flexDirection="row" mb="10px" className={style.editCriteraContainer}>
                                    <FormHelperText>{i + 1}</FormHelperText>

                                    <Stack ml="10px" width="100%">
                                        <Stack flexDirection="row" justifyContent="space-between">
                                            <FormHelperText>Text</FormHelperText>
                                            <Tooltip title="Delete">
                                                <IconButton size="small" onClick={() => onDelete({ index: i })}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                        <TextField
                                            value={item.text}
                                            fullWidth
                                            onChange={(e) =>
                                                onChangeValue({
                                                    ...item,
                                                    text: e.target.value,
                                                    index: i,
                                                })
                                            }
                                        />
                                        <FormHelperText>Criterion</FormHelperText>
                                        <TextField
                                            value={item.criterion}
                                            fullWidth
                                            onChange={(e) =>
                                                onChangeValue({
                                                    ...item,
                                                    criterion: e.target.value,
                                                    index: i,
                                                })
                                            }
                                        />
                                        <FormHelperText>Intent</FormHelperText>
                                        <RadioGroup name="radio-buttons-group" value={item.intent} row>
                                            <FormControlLabel
                                                value={1}
                                                control={<Radio />}
                                                label="1"
                                                onClick={() =>
                                                    onChangeValue({
                                                        ...item,
                                                        intent: 1,
                                                        index: i,
                                                    })
                                                }
                                            />
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label="0"
                                                onClick={() =>
                                                    onChangeValue({
                                                        ...item,
                                                        intent: 0,
                                                        index: i,
                                                    })
                                                }
                                            />
                                        </RadioGroup>
                                    </Stack>
                                </Stack>
                            );
                        })}

                    <Button startIcon={<AddIcon />} onClick={onAddCriteria}>
                        Add criteria
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onSubmit}>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};
