import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { trimText } from "../../pages/project/components/exportCandidates/pdfDataTemplate";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { setModal } from "../../store/reducers/modals.slice";
import { formatDate } from "../../utils/helper";
import VirtualizedSelect from "../VirtualizedSelect";

import { getOrgList, selectOrganizations } from "@/store/reducers/org-list/orgList.reducers";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { setOrgId, submitSwitchOrdId } from "@/store/reducers/userList/userList.reducers";

const OrgsDropdown = () => {
    const dispatch = useDispatch();
    const isOrgsFetching = useSelector(checkIfLoading(getOrgList.type));
    const organizations = useSelector(selectOrganizations);
    const user = useSelector(selectUser);

    const handleClick = (id: string) => {
        dispatch(setOrgId(id));
        dispatch(submitSwitchOrdId());
        dispatch(setModal({ key: "showSwitchToOrgModal", value: false }));
    };

    useEffect(() => {
        if (organizations?.length === 0) {
            dispatch(getOrgList());
        }
    }, [dispatch, organizations?.length]);

    return (
        <VirtualizedSelect
            inputProps={{
                placeholder: "Search organization",
            }}
            size="sm"
            variant="soft"
            value={user?.orgData?._id.toString()}
            disabled={isOrgsFetching}
            enableSearch={true}
            options={organizations
                ?.filter(org => org.value !== 1806 || user?._id === 2165 || user?._id === 12)
                .map((m) => ({ label: m.label, value: m.value, ...m })) ?? []}
            onChange={(option) => handleClick(option.value)}
            defaultOption={{
                label: `${user?.orgData?.name}  (${user?.orgData?._id})`,
                value: user?.orgData?._id.toString(),
            }}
            itemHeight={55}
            renderOption={(item) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                padding: "0.25rem 0",
                                paddingRight: "0.5rem",
                            }}
                        >
                            <div>
                                {trimText(item.name, 20)}{" "}
                                <span>
                                    {`(${item.members?.length} ${item.members?.length === 1 ? "member" : "members"})`}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span style={{ fontSize: "0.8rem" }}>Org Id: {item._id}</span>
                                <span style={{ fontSize: "0.8rem" }}> {formatDate(item.createdAt)}</span>
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default OrgsDropdown;
