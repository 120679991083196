import { useEffect } from "react";
import { useSelector } from "react-redux";

import Footer from "./Footer";

import { useGetSMSConversationsQuery, useSendSMSMutation } from "../../../../store/apis/super-inbox/super-inbox.api";
import { useSuperInboxContext } from "../../Context";
import { ChannelProps } from "../channels/ChannelTabs";
import ChatBubble from "../messages/ChatBubble";
import MessageInput from "../messages/MessageInput";
import MessagesConversationContainer from "../messages/MessagesConversationContainer";

import { checkSMSEnabled, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Avatar } from "@/ui/Avatar/Avatar";

interface SMSProps extends ChannelProps {}

export default function SMS(props: SMSProps) {
    const { candidateProfile, setTags } = props;
    const {
        filters: { projectId, userId },
        candidateParamId,
    } = useSuperInboxContext();

    const isSMSEnabled = useSelector(checkSMSEnabled);
    const user = useSelector(selectUser);
    const canSendSms = userId ? user?._id === userId : true;
    const [sendSMS, { isLoading: isSubmittingSMS }] = useSendSMSMutation();
    const { isLoading, isFetching, data } = useGetSMSConversationsQuery({
        candidateId: candidateParamId,
        projectIds: [projectId],
        userId,
    });

    useEffect(() => {
        setTags({
            tags: data?.tags ?? [],
            projects: data?.projects ?? [],
        });
    }, [data]);

    return (
        <>
            <MessagesConversationContainer
                isLoading={isLoading || isFetching}
                message={
                    !isSMSEnabled
                        ? "You do not have EasySource SMS capabilities integrated yet. Please contact your account manager if you wish to activate this feature"
                        : undefined
                }
            >
                {data?.messages?.map((message, index) => {
                    const isYou = message.senderType === "USER";
                    const isSMSFailed = ["FAILED", "UNDELIVERED", "OPTED_OUT"].includes(message.candidateStatus);
                    const isQueued = message.candidateStatus === "QUEUED";

                    return (
                        <div
                            key={index}
                            className={`flex ${isYou ? "flex-row-reverse" : "flex-row"} ${isYou ? "space-x-reverse" : ""} gap-2`}
                        >
                            {!isYou && (
                                <Avatar
                                    src={candidateProfile?.profileImage}
                                    className="w-[33px] h-[33px] bg-white"
                                    fallback={
                                        <svg
                                            className="w-6 h-6"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                        </svg>
                                    }
                                />
                            )}
                            <ChatBubble
                                isQueued={isQueued}
                                isMessageFailed={isSMSFailed}
                                variant={isYou ? "sent" : "received"}
                                body={message.body}
                                time={message.time}
                                senderType={message.senderType}
                                candidateStatus={message.candidateStatus}
                                candidateProfile={candidateProfile}
                                theme="dark"
                            />
                        </div>
                    );
                })}
            </MessagesConversationContainer>
            {canSendSms ? (
                <MessageInput
                    disabled={isLoading || !data?.projects?.length}
                    isLoading={isSubmittingSMS}
                    onSubmit={(bodyText, callback) => {
                        if (data?.projects?.length) {
                            sendSMS({
                                candidateId: candidateParamId,
                                smsBody: bodyText,
                                projectId: data?.projects[0]._id,
                            }).then(callback);
                        }
                    }}
                />
            ) : (
                <Footer date={data?.lastUpdated} />
            )}
        </>
    );
}
