import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
    Button,
    IconButton,
    InputBase,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
    alpha,
    styled,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { debounce } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { ActionMeta, CSSObjectWithLabel, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import {
    addAllContactsFilter,
    applyAllContactsFilters,
    selectAllContactsFilterDrawerState,
    selectAllContactsFilters,
    setAllContactsFilterValue,
    toggleAllContactsFiltersDrawer,
} from "../../store/apis/all-contacts/all-contacts.slice";

import { ReadOnlyOption } from "@/common";
import { AllContactsTableItemKeys } from "@/store/apis/all-contacts/all-contacts.types";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 14,
        width: "100%",
        padding: "8px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function FilterAccordion({ filterKey, children }: { filterKey: AllContactsTableItemKeys; children: React.ReactNode }) {
    const filter = useSelector(selectAllContactsFilters)[filterKey];
    const [expanded, setExpanded] = useState(true);

    if (!filter) {
        return null;
    }

    const handleChange = (e: React.SyntheticEvent<Element, Event>) => {
        e.stopPropagation();
        setExpanded((prev) => !prev);
    };
    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: 1 }}>
                    <Typography variant="body2">{filter?.label}</Typography>
                    <IconButton sx={{ padding: 0 }} onClick={(e) => e.stopPropagation()}>
                        <RemoveRoundedIcon />
                    </IconButton>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}

const allowedFields = [
    "Job Title",
    "Company Name",
    "City",
    "Country/Region",
    "Industry",
    "Number of Employees",
    "Seniority",
    "Company name",
    "LinkedIn URL",
    "LinkedIn companyUrl",
    "LinkedIn profile URL",
    "Job function",
];

function FiltersInput({ filterKey }: { filterKey: AllContactsTableItemKeys }) {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const filters = useSelector(selectAllContactsFilters)[filterKey]?.value;
    const [values, setValues] = useState<ReadOnlyOption[]>(() => (filters?.length ? filters : []));

    const dispatchFilters = debounce(
        (newValues: ReadOnlyOption[]) =>
            dispatch(
                setAllContactsFilterValue({
                    key: filterKey,
                    value: newValues,
                })
            ),
        500
    );

    const handleChange = (value: MultiValue<ReadOnlyOption>, actionMeta: ActionMeta<ReadOnlyOption>) => {
        const newValues = actionMeta.action !== "clear" ? value.map(({ label, value }) => ({ label, value })) : [];
        setValues(newValues);
        dispatchFilters(newValues);
    };

    return (
        <CreatableSelect
            styles={{
                option: (baseStyles) =>
                    ({
                        ...baseStyles,
                        fontSize: "14px !important",
                    }) as CSSObjectWithLabel,
                noOptionsMessage: (baseStyles) =>
                    ({
                        ...baseStyles,
                        fontSize: "14px !important",
                    }) as CSSObjectWithLabel,
                placeholder: (baseStyles) =>
                    ({
                        ...baseStyles,
                        fontSize: "14px !important",
                    }) as CSSObjectWithLabel,
                input: (baseStyles) =>
                    ({
                        ...baseStyles,
                        fontSize: "14px !important",
                    }) as CSSObjectWithLabel,
                menu: (baseStyles) =>
                    ({
                        ...baseStyles,
                        maxHeight: "120px !important",
                        overflowY: "auto",
                    }) as CSSObjectWithLabel,
            }}
            inputValue={inputValue}
            isClearable
            isMulti
            onChange={handleChange}
            onInputChange={(newValue) => setInputValue(newValue)}
            placeholder="type..."
            createOptionPosition="first"
            isDisabled={!allowedFields.includes(filterKey)}
            value={values}
        />
    );
}

function AllContactFiltersTitle() {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
                padding: theme.spacing(2),
            })}
        >
            <Typography variant="h6">Filters</Typography>
            <IconButton>
                <CloseRoundedIcon
                    sx={(theme) => ({
                        color: theme.palette.common.white,
                    })}
                />
            </IconButton>
        </Stack>
    );
}

export const availableFields: AllContactsTableItemKeys[] = [
    "Record ID",
    "First Name",
    "Last Name",
    "Average Pageviews",
    "Became a Marketing Qualified Lead Date",
    "Company Name",
    "Contact priority",
    "Contact unworked",
    "Country/Region",
    "Create Date",
    "Created by user ID",
    "Currently in workflow",
    "Departments",
    "Email",
    "Email Domain",
    "Event Revenue",
    "First marketing email send date",
    "Industry",
    "Job function",
    "Job Title",
    "Last marketing email name",
    "Last marketing email send date",
    "Last Modified Date",
    "Latest Source",
    "Latest Source Date",
    "Latest Source Drill-Down 1",
    "Latest Source Drill-Down 2",
    "Lifecycle Stage",
    "Likelihood to close",
    "LinkedIn URL",
    "Marketing contact status",
    "Marketing contact status source name",
    "Marketing contact status source type",
    "Marketing contact until next update",
    "Marketing emails delivered",
    "Number of Employees",
    "Number of event completions",
    "Number of Form Submissions",
    "Number of Pageviews",
    "Number of Sessions",
    "Number of Unique Forms Submitted",
    "Original Source",
    "Original Source Drill-Down 1",
    "Original Source Drill-Down 2",
    "Phone Number",
    "Sends Since Last Engagement",
    "Seniority",
    "Time First Seen",
    "Today's Date",
    "Website URL",
    "Associated Company",
    "Associated Contact create attribution",
    "Associated Company IDs",
    "Associated Contact create attribution IDs",
    "First marketing email open date",
    "Last marketing email open date",
    "Marketing emails opened",
    "Mobile Phone Number",
    "Email hard bounce reason",
    "Fax Number",
    "Salutation",
    "City",
    "Company name",
    "State/Region",
    "Lead Status",
    "Opted out of email: Company Updates",
    "Opted out of email: Marketing Information",
    "Opted out of email: One to One",
    "Opted out of email: Product Updates",
    "Unsubscribed from all email",
    "Annual Revenue",
    "IP City",
    "IP Country",
    "IP Country Code",
    "IP State Code/Region Code",
    "IP State/Region",
    "IP Timezone",
    "Time Zone",
    "LinkedIn companyUrl",
    "Work email",
    "Hiring Flag",
    "HR-DM Flag",
    "Contact owner",
    "Full name",
    "location",
    "Owner Assigned Date",
    "Marketing emails bounced",
    "Opted out of email: Customer Service Communication",
    "Opted out of email: Discovery/Demo Booked",
    "HubSpot Team",
    "LinkedIn profile URL",
    "First marketing email reply date",
    "Last marketing email reply date",
    "Marketing emails replied",
    "Twitter Username",
    "Became a Sales Qualified Lead Date",
    "Became a Lead Date",
];

function FiltersList({ toggle }: { toggle: () => void }) {
    const dispatch = useDispatch();
    const [query, searchQuery] = useState("");
    const appliedFilters = Object.keys(useSelector(selectAllContactsFilters));
    const handleFilterClick = (filterKey: AllContactsTableItemKeys) => {
        dispatch(
            addAllContactsFilter({
                key: filterKey,
                label: filterKey,
                numeric: false,
            })
        );
        toggle();
    };

    const filters = availableFields.filter((f) => f.toLocaleLowerCase().includes(query.toLocaleLowerCase()));

    return (
        <Stack
            sx={(theme) => ({
                backgroundColor: theme.palette.common.white,
                borderTopLeftRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: theme.spacing(2),
            })}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            spacing={1}
        >
            <Stack spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography
                        variant="body1"
                        sx={(theme) => ({
                            fontWeight: theme.typography.fontWeightMedium,
                        })}
                    >
                        Contact Properties
                    </Typography>
                    <IconButton onClick={toggle}>
                        <CloseRoundedIcon
                            sx={(theme) => ({
                                color: theme.palette.grey[500],
                            })}
                        />
                    </IconButton>
                </Stack>
                <BootstrapInput value={query} onChange={(e) => searchQuery(e.target.value)} />
            </Stack>
            <Box sx={{ height: 400, overflowY: "auto" }}>
                {filters
                    .filter((f) => !appliedFilters.includes(f))
                    .map((i) => {
                        return (
                            <ListItem disablePadding key={i}>
                                <ListItemButton onClick={() => handleFilterClick(i)}>
                                    <ListItemText
                                        primary={i}
                                        sx={(theme) => ({
                                            "& .MuiTypography-root": {
                                                fontSize: theme.typography.fontSize,
                                            },
                                        })}
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
            </Box>
        </Stack>
    );
}

function AllContactsFiltersContent() {
    const dispatch = useDispatch();
    const [showFiltersList, setShowFiltersList] = useState(false);
    const filters = useSelector(selectAllContactsFilters);
    const isFiltersLoading = useSelector(checkIfLoading(applyAllContactsFilters.type));

    const toggle = () => setShowFiltersList((prev) => !prev);
    const filtersArr = Object.keys(filters) as AllContactsTableItemKeys[];

    const handleApplyFilters = () => {
        dispatch(applyAllContactsFilters());
        dispatch(toggleAllContactsFiltersDrawer());
    };

    const isDisabled = isFiltersLoading;

    return (
        <Stack
            spacing={2}
            sx={(theme) => ({
                padding: theme.spacing(2),
                height: "100%",
            })}
            onClick={(e) => e.stopPropagation()}
        >
            <Typography variant="body1" fontWeight={600}>
                Advanced Filters ({filtersArr.length})
            </Typography>
            <Stack>
                {filtersArr.map((key) => {
                    return (
                        <FilterAccordion key={key} filterKey={key}>
                            <FiltersInput filterKey={key} />
                        </FilterAccordion>
                    );
                })}
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="center">
                <Button
                    onClick={toggle}
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    disabled={isDisabled}
                    sx={(theme) => ({
                        width: "fit-content",
                        height: "fit-content",
                        fontSize: theme.typography.button,
                        textTransform: "capitalize",
                        alignSelf: "center",
                    })}
                >
                    Add Filter
                </Button>
                <Button
                    onClick={handleApplyFilters}
                    variant="contained"
                    disabled={isDisabled}
                    endIcon={<SendRoundedIcon />}
                    sx={(theme) => ({
                        width: "fit-content",
                        height: "fit-content",
                        fontSize: theme.typography.button,
                        textTransform: "capitalize",
                        alignSelf: "center",
                    })}
                >
                    Submit
                </Button>
            </Stack>
            {showFiltersList && (
                <Box
                    sx={{
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        margin: "0 !important",
                    }}
                    role="presentation"
                    onClick={toggle}
                    onKeyDown={toggle}
                >
                    <Box
                        sx={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <Stack
                            sx={(theme) => ({
                                backgroundColor: theme.palette.common.white,
                                borderTopLeftRadius: theme.shape.borderRadius,
                                borderTopRightRadius: theme.shape.borderRadius,
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                padding: theme.spacing(2),
                            })}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            spacing={1}
                        >
                            <Stack spacing={1}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography
                                        variant="body1"
                                        sx={(theme) => ({
                                            fontWeight: theme.typography.fontWeightMedium,
                                        })}
                                    >
                                        Contact Properties
                                    </Typography>
                                    <IconButton onClick={toggle}>
                                        <CloseRoundedIcon
                                            sx={(theme) => ({
                                                color: theme.palette.grey[500],
                                            })}
                                        />
                                    </IconButton>
                                </Stack>
                                <BootstrapInput />
                            </Stack>
                            <Box sx={{ maxHeight: 300, overflowY: "auto" }}>
                                <FiltersList toggle={toggle} />
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            )}
        </Stack>
    );
}

export default function AllContactsFilters() {
    const dispatch = useDispatch();
    const open = useSelector(selectAllContactsFilterDrawerState);
    const toggle = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === "keydown") {
            return;
        }

        dispatch(toggleAllContactsFiltersDrawer());
    };
    return (
        <Drawer anchor="right" open={open} onClose={toggle}>
            <Box
                sx={{
                    width: 500,
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "auto 1fr",
                    flex: 1,
                    position: "relative",
                }}
                onClick={toggle}
                onKeyDown={toggle}
            >
                <AllContactFiltersTitle />
                <AllContactsFiltersContent />
            </Box>
        </Drawer>
    );
}
