import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAccountRootState, ICheckoutPayload, IEmailType, IUserPlan } from "./myAccount.types";

import { RootState } from "@/store";
import { IActionPayload } from "@/store/reducers/loaders.reducer";

const initialState: IAccountRootState = {
    plans: [],
    userPlan: {
        name: "Free",
        createdAt: "2023-04-27T07:49:01.798Z",
        validTill: "Forever",
        remainingCredits: 30,
        totalCredits: 100,
    },
    emailType: "",
    accountType: "",
    aliasName: {
        alias: "",
        outboundEmail: "",
    },
    defaultAlias: "",
    virtualUserSignature: "",
    virtualUser: {
        alias: "",
        outboundEmail: "",
    },
    allOrgMembers: [],
    profileCreditInfo: "",
    linkedinOutreachCredit: "",
    smsOutreachCredit: "",
    emailCredit: "",
    organizationDetails: "",
    emailEnrichmentCredit: "",
    phoneEnrichmentCredit: "",
    exclusionList: [],
};

const reducers = {
    fetchPlans(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    setPlans(state: typeof initialState, { payload }: { payload: any[] }) {
        state.plans = payload;
    },
    setVirtualUser(state: typeof initialState, { payload }: { payload: any }) {
        state.allOrgMembers = payload;
    },
    setVirtualUserSignature(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUserSignature = payload;
    },
    fetchUserPlan(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    setUserPlan(state: typeof initialState, { payload }: { payload: IUserPlan }) {
        state.userPlan = payload;
    },
    createCheckoutSession(state: typeof initialState, { payload }: { payload: IActionPayload & ICheckoutPayload }) { },
    resetMyAccount(state: typeof initialState) { },
    setEmailType(state: typeof initialState, { payload }: { payload: IEmailType }) {
        state.emailType = payload;
    },
    addVirtualUserAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.aliasName = payload;
    },
    setDefaultAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.defaultAlias = payload;
    },
    setSmtpAuthorization(state: typeof initialState, { payload }: { payload: any }) { },
    updateAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUser = payload;
    },
    deleteAlias(state: typeof initialState, { payload }: { payload: any }) { },
    fetchAlias(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    fetchExclusionList(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    disconnectAlias(state: typeof initialState, { payload: any }) { },

    // Credits tracking apis
    fetchProfileCreditInfo(state: typeof initialState, { payload }: { payload: IActionPayload & any }) { },
    setProfileCreditsInfo(state: typeof initialState, { payload }: { payload: any }) {
        state.profileCreditInfo = payload;
    },

    // Linkedin Outreach Credits
    fetchLinkedinOutreachCredit(state: typeof initialState, { payload }: { payload: IActionPayload & any }) { },
    setLinkedinOutreachCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.linkedinOutreachCredit = payload;
    },

    // SMS Outreach Credits
    fetchSmsOutreachCredit(state: typeof initialState, { payload }: { payload: IActionPayload & any }) { },
    setSmsOutreachCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.smsOutreachCredit = payload;
    },

    // Email Credits
    fetchEmailCredit(state: typeof initialState, { payload }: { payload: IActionPayload & any }) { },
    setEmailCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.emailCredit = payload;
    },

    // Organization Details
    fetchOrganizationDetails(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    setOrganizationDetails(state: typeof initialState, { payload }: { payload: any }) {
        state.organizationDetails = payload;
    },

    // Email Enrichment credits
    fetchEmailEnrichmentCredit(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    setEmailEnrichmentCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.emailEnrichmentCredit = payload;
    },

    // Phone Enrichment credits
    fetchPhoneEnrichmentCredit(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    setPhoneEnrichmentCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.phoneEnrichmentCredit = payload;
    },

    // Alert Toggle
    setCreditAlertPreference(_state: typeof initialState, _action: PayloadAction<any>) {

    },

    setAccountType(state: typeof initialState, { payload }: { payload: any }) {
        state.accountType = payload;
    },
    setExclusionList(state: typeof initialState, { payload }: { payload: any }) {
        state.exclusionList = payload;
    },
};

export const myAccountSlice = createSlice({
    name: "myAccount",
    initialState,
    reducers,
});

export default myAccountSlice.reducer;

export const fetchPlans = myAccountSlice.actions.fetchPlans;
export const setPlans = myAccountSlice.actions.setPlans;
export const fetchUserPlan = myAccountSlice.actions.fetchUserPlan;
export const setUserPlan = myAccountSlice.actions.setUserPlan;
export const createCheckoutSession = myAccountSlice.actions.createCheckoutSession;
export const resetMyAccount = myAccountSlice.actions.resetMyAccount;
export const setEmailType = myAccountSlice.actions.setEmailType;
export const setDefaultAlias = myAccountSlice.actions.setDefaultAlias;
export const setSmtpAuthorization = myAccountSlice.actions.setSmtpAuthorization;
export const addVirtualUserAlias = myAccountSlice.actions.addVirtualUserAlias;
export const fetchAlias = myAccountSlice.actions.fetchAlias;
export const fetchExclusionList = myAccountSlice.actions.fetchExclusionList;
export const setVirtualUser = myAccountSlice.actions.setVirtualUser;
export const setVirtualUserSignature = myAccountSlice.actions.setVirtualUserSignature;
export const updateAlias = myAccountSlice.actions.updateAlias;
export const deleteAlias = myAccountSlice.actions.deleteAlias;
export const disconnectAlias = myAccountSlice.actions.disconnectAlias;
export const fetchProfileCreditInfo = myAccountSlice.actions.fetchProfileCreditInfo;
export const setProfileCreditsInfo = myAccountSlice.actions.setProfileCreditsInfo;
export const fetchLinkedinOutreachCredit = myAccountSlice.actions.fetchLinkedinOutreachCredit;
export const setLinkedinOutreachCredit = myAccountSlice.actions.setLinkedinOutreachCredit;
export const fetchSmsOutreachCredit = myAccountSlice.actions.fetchSmsOutreachCredit;
export const setSmsOutreachCredit = myAccountSlice.actions.setSmsOutreachCredit;
export const fetchEmailCredit = myAccountSlice.actions.fetchEmailCredit;
export const setEmailCredit = myAccountSlice.actions.setEmailCredit;
export const fetchOrganizationDetails = myAccountSlice.actions.fetchOrganizationDetails;
export const setOrganizationDetails = myAccountSlice.actions.setOrganizationDetails;
export const fetchEmailEnrichmentCredit = myAccountSlice.actions.fetchEmailEnrichmentCredit;
export const setEmailEnrichmentCredit = myAccountSlice.actions.setEmailEnrichmentCredit;
export const fetchPhoneEnrichmentCredit = myAccountSlice.actions.fetchPhoneEnrichmentCredit;
export const setPhoneEnrichmentCredit = myAccountSlice.actions.setPhoneEnrichmentCredit;
export const setAccountType = myAccountSlice.actions.setAccountType;
export const setExclusionList = myAccountSlice.actions.setExclusionList;
export const setCreditAlertPreference = myAccountSlice.actions.setCreditAlertPreference;

export const selectalias = (state: RootState) => state.myAccount.aliasName;
export const selectDefaultAlias = (state: RootState) => state.myAccount.defaultAlias;
export const selectEmailType = (state: RootState) => state.myAccount.emailType;
export const selectUserPlan = (state: RootState) => state.myAccount.userPlan;
export const selectVirtualUser = (state: RootState) => state.myAccount.allOrgMembers;
export const selectProfileCreditInfo = (state: RootState) => state.myAccount.profileCreditInfo;
export const selectLinkedinOutreachCredit = (state: RootState) => state.myAccount.linkedinOutreachCredit;
export const selectSmsOutreachCredit = (state: RootState) => state.myAccount.smsOutreachCredit;
export const selectEmailCredit = (state: RootState) => state.myAccount.emailCredit;
export const selectOrganizationDetails = (state: RootState) => state.myAccount.organizationDetails;
export const selectEmailEnrichmentCredit = (state: RootState) => state.myAccount.emailEnrichmentCredit;
export const selectPhoneEnrichmentCredit = (state: RootState) => state.myAccount.phoneEnrichmentCredit;
export const selectAccountType = (state: RootState) => state.myAccount.accountType;
