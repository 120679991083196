import React, { useEffect, useState } from "react";
import CallMadeOutlined from "@mui/icons-material/CallMadeOutlined";
import TtyOutlined from "@mui/icons-material/Tty";
import { IconButton, Tooltip as JoyTooltip } from "@mui/joy";
import { Box, CircularProgress, Divider, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { getTwilloCallLogs, selectTwilloCalls } from "@/store/reducers/project/project.reducer";

const TwilioLogs: React.FC<any> = ({ candidate }) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const twilloCalls = useSelector(selectTwilloCalls);

    const loading = useSelector(checkIfLoading(getTwilloCallLogs.type));

    useEffect(() => {
        if (modalOpen) {
            dispatch(getTwilloCallLogs({ page: pageNumber, limit: pageSize, filter: { candidateId: candidate._id } }));
        }
    }, [pageNumber, pageSize, candidate._id, modalOpen]);

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <CallLogsModal logs={twilloCalls} toggleModal={setModalOpen} modalOpen={modalOpen} loading={loading} />
            <Box sx={{ width: "32px", alignContent: "center", borderRadius: "50%" }}>
                <JoyProvider>
                    <JoyTooltip title="Call logs" placement="bottom" size="sm">
                        <IconButton size="sm" color="neutral" onClick={() => setModalOpen(true)} disabled={modalOpen}>
                            <TtyOutlined />
                        </IconButton>
                    </JoyTooltip>
                </JoyProvider>
            </Box>
        </div>
    );
};

export default TwilioLogs;

const CallLogsModal = ({ logs, toggleModal, modalOpen, loading }) => {
    return (
        <Modal open={modalOpen} onClose={() => toggleModal(false)}>
            {loading ? (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: 400,
                        overflow: "auto",
                        height: "40vh",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <CircularProgress />
                    </div>
                </Box>
            ) : (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: 400,
                        overflow: "auto",
                        height: "40vh",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography id="call-list-modal" variant="h6" component="h2" gutterBottom>
                        Call Logs
                    </Typography>
                    {logs?.callLogs?.length === 0 && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            No call logs.
                        </Typography>
                    )}

                    {logs?.callLogs?.map((item, index) => (
                        <Box key={index}>
                            {item?.direction === "OUTGOING" ? (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            <CallMadeOutlined
                                                fontSize="small"
                                                sx={{ position: "relative", top: "0.26em" }}
                                            />{" "}
                                            <span style={{ marginLeft: "0.5em", color: "black" }}>{item?.to}</span>
                                        </Typography>

                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {item?.statuses?.[item?.statuses?.length - 2]?.status === "ringing"
                                                ? "completed"
                                                : item?.statuses?.[item?.statuses?.length - 2]?.status || "completed"}
                                        </Typography>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            gutterBottom
                                            sx={{ fontSize: "0.7rem" }}
                                        >
                                            {dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                                        </Typography>
                                    </div>
                                    <Divider />
                                </>
                            ) : (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            <CallMadeOutlined
                                                fontSize="small"
                                                sx={{
                                                    position: "relative",
                                                    top: "0.26em",
                                                    transform: "rotate(180deg)",
                                                }}
                                            />{" "}
                                            <span style={{ marginLeft: "0.5em", color: "black" }}>
                                                {item?.from?.split(":")?.[1] ? item?.from?.split(":")?.[1] : item?.from}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {item?.statuses?.[item?.statuses?.length - 2]?.status === "ringing"
                                                ? "completed"
                                                : item?.statuses?.[item?.statuses?.length - 2]?.status || "cancelled"}
                                        </Typography>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            gutterBottom
                                            sx={{ fontSize: "0.7rem" }}
                                        >
                                            {dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                                        </Typography>
                                    </div>
                                </>
                            )}
                        </Box>
                    ))}
                </Box>
            )}
        </Modal>
    );
};
