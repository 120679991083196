import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useMaterialReactTable } from "material-react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ProjectsDropdown from "../../../components/Dropdowns/ProjectsDropdown";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { addContactsToProject, selectAllCandidatesSearchResults } from "@/store/reducers/all-candidates/all-candidates.slice";

interface IContactsModalProps {
    open: boolean;
    onClose: () => void;
    isSelectAll: boolean;
    candidateIds: string[];
}

function ContactsModal(props: IContactsModalProps) {
    const { open, candidateIds, onClose } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(addContactsToProject.type));
    const isAllSelected = useSelector(selectAllCandidatesSearchResults);

    const [projectId, setProjectId] = useState<number>(0);
    const [projectName, setProjectName] = useState("");

    const handleSubmit = () => {
        dispatch(
            addContactsToProject({
                projectId: projectId.toString(),
                selectAll: isAllSelected,
                candidateIds,
                projectName,
                onSuccess() {
                    onClose();
                },
            })
        );
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
            }}
        >
            <DialogTitle>Add {t("common")} To project</DialogTitle>
            <DialogContent sx={{ minWidth: "35rem", maxWidth: "35rem" }}>
                <Stack>
                    <Stack flexDirection="column" padding={"20px 0 20px 0"} gap={2}>
                        <div style={{ display: "flex" }}>
                            <span style={{ marginRight: "10px", marginTop: "5px" }}>Select Project</span>
                            <ProjectsDropdown
                                defaultOptionLabel={"New " + t("newProject")}
                                projectId={projectId}
                                handleChange={setProjectId}
                            />
                        </div>
                        {projectId === 0 && (
                            <TextField
                                size="small"
                                label="Project Name"
                                variant="outlined"
                                fullWidth
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        )}
                    </Stack>

                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Stack>
                            <Typography sx={{ color: "#6f6f6e", fontSize: "13px", fontStyle: "italic" }}>
                                All selected candidates will be added to the project
                            </Typography>
                            <Typography sx={{ color: "#6f6f6e", fontSize: "13px", fontStyle: "italic" }}>
                                Candidates already present in your project will not be duplicated
                            </Typography>
                        </Stack>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{
                                borderRadius: "12px",
                                minWidth: "93px",
                                minHeight: "40px",
                                marginLeft: "10px",
                            }}
                            disabled={!(isLoading || projectName || projectId)}
                        >
                            {isLoading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Proceed"}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export function AddContactsToProject({ table }: { table: ReturnType<typeof useMaterialReactTable> }) {
    const [open, setOpen] = useState(false);

    const isSelectAll = table.getIsAllRowsSelected();
    const selectedRows = table.getSelectedRowModel();

    const candidateIds = selectedRows.rows.map((row) => row.original._id);

    const enableAddToProjectButton = candidateIds.length > 0 || isSelectAll;

    return (
        <Box>
            <Button
                onClick={() => setOpen(true)}
                {...(!enableAddToProjectButton && { sx: { display: "none" } })}
                variant="contained"
                size="small"
            >
                Add To Project
            </Button>
            <ContactsModal
                open={open}
                isSelectAll={isSelectAll}
                candidateIds={candidateIds}
                onClose={() => setOpen(false)}
            />
        </Box>
    );
}
