import { zodResolver } from "@hookform/resolvers/zod";
import JoiButton from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Stack from "@mui/joy/Stack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";

import styles from "./EditSignature.module.scss";

import { JoyProvider } from "../../../components/JoyProvider";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import { useAppDispatch } from "../../../store";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

import { toggleEditSignatureModal } from "@/store/reducers/outreach/outreach.slice";

export const profileFormSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    userEmail: z.string(),
    outboundEmail: z.string(),
    workspaceName: z.string().trim().min(1, { message: "Please enter workspace name." }),
    companyName: z.string(),
    companyUrl: z.string(),
    role: z.union([z.literal(1), z.literal(0)]).optional(),
    linkedinUrl: z.string(),
    signature: z.string().optional(),
});

type ProfileForm = z.infer<typeof profileFormSchema>;

export function EditSignature() {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();
    const reduxDispatch = useDispatch();

    const { control, handleSubmit, reset } = useForm<ProfileForm>({
        resolver: zodResolver(profileFormSchema),
        values: {
            name: user.name ?? "",
            userEmail: user.email ?? "",
            outboundEmail: user.authorizedEmailAddress ?? "",
            workspaceName: user.orgData?.name ?? "",
            companyName: user.companyName ?? "",
            companyUrl: user.companyLink ?? "",
            role: user?.roleType === "ADMIN" ? 0 : 1,
            linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
            signature: user?.signature ?? "",
        },
    });
    const handelCancel = () => {
        reduxDispatch(toggleEditSignatureModal(false));
    };
    const onSubmit: SubmitHandler<ProfileForm> = (data) => {
        const { companyName, companyUrl, linkedinUrl, name, outboundEmail, userEmail, workspaceName, role, signature } =
            data;
        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                roleType: role === 0 ? "ADMIN" : "MEMBER",
                action: updateUser.type,
                signature,
            })
        );
        handelCancel();
    };

    return (
        <JoyProvider>
            <Stack>
                <Card variant="outlined" component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={1.5} sx={{ maxHeight: "28vh", overflow: "hidden" }}>
                        <Controller
                            control={control}
                            name="signature"
                            render={({ field, fieldState: { error } }) => {
                                const { value, onChange } = field;
                                return (
                                    <FormControl error={!!error?.message}>
                                        <FormLabel>Signature</FormLabel>
                                        <RichTextEditor
                                            // style={{border:"1px solid blue",height:'25vh'}}
                                            className={styles.signatureEditor}
                                            enableImageModule
                                            value={value}
                                            onChange={(value) => onChange(value)}
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                );
                            }}
                        />
                    </Stack>
                    <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
                        <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                            <JoiButton size="sm" variant="outlined" color="neutral" onClick={() => handelCancel()}>
                                Cancel
                            </JoiButton>
                            <JoiButton size="sm" variant="solid" type="submit">
                                Save
                            </JoiButton>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Stack>
        </JoyProvider>
    );
}
