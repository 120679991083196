import ChatIcon from "@mui/icons-material/Chat";
import { Badge, IconButton, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { setWarningNotification } from "@/store/reducers/notification/notification.reducer";
import { useChatbotContext } from "../../Context";
import HelpComponent from "../HelpComponent";

import { selectUser } from "@/store/reducers/signin/Signin.reducer";

function Home() {
    const { setTabIndex } = useChatbotContext();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    // const [showChatbot, setShowChatbot] = useState(false);
    const params = useParams();
    const projectId = params.id;

    const handleClick = () => {
        if (projectId) {
            setTabIndex(2);
        } else {
            dispatch(setWarningNotification("Please choose a project to access this feature"));
        }
    };
    return (
        <>
            <div style={{ padding: "16px" }}>
                <img
                    alt="logo"
                    style={{ maxHeight: "32px", maxWidth: "100%", marginBottom: "60px" }}
                    src="https://downloads.intercomcdn.com/i/o/529822/72cb63e8ff9c128fa16f1af3/1bb87d41d15fe27b500a4bfcde01bb0e.png"
                />

                {user.name && <h1 style={{ color: "rgb(121, 134, 134)" }}>Hi, {user.name.split(" ")[0]} 👋</h1>}

                <h2>How can we help?</h2>
            </div>

            <div style={{ padding: "8px" }}>
                <Box
                    onClick={handleClick}
                    sx={{
                        marginBottom: "5px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "14px",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                        backgroundColor: "white",
                        transition: "color 0.3s ease",
                        "&:hover": {
                            color: "#1e81b0",
                            cursor: "pointer",
                        },
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "16px",
                                transition: "color 0.3s ease",
                                "&:hover": {
                                    color: "#1e81b0",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            Execute actions using our AI agent
                        </Typography>
                        <Typography sx={{ color: "#757575", fontSize: "12px" }}>AI agent & team can help</Typography>
                    </Box>
                    <Badge>
                        <IconButton>
                            <ChatIcon style={{ color: "#000000" }} />
                        </IconButton>
                    </Badge>
                </Box>

                <HelpComponent />

                {/* 
                // this div will be activated later
                <Box
                    // onClick={handleClick}
                    sx={{
                        marginBottom: "5px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "14px",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                        backgroundColor: "white",
                        transition: "color 0.3s ease",
                        "&:hover": {
                            color: "#1e81b0",
                            cursor: "pointer",
                        },
                        // boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "16px",
                                transition: "color 0.3s ease",
                                "&:hover": {
                                    color: "#1e81b0",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            Contact team?
                        </Typography>
                        <Typography sx={{ color: "#757575", fontSize: "12px" }}>Get a quick customer support</Typography>
                    </Box>
                    
                    <IconButton>
                        <Groups2Icon style={{ color: "#000000" }} />
                    </IconButton>
                </Box> */}
            </div>
        </>
    );
}

export default Home;
