import { useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PauseIcon from "@mui/icons-material/Pause";
import Phone from "@mui/icons-material/Phone";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { CallModalContent } from "../../project/components/TwilloModule/CallModal";
import { useCallContext } from "../../project/components/TwilloModule/TwilloCallContext";
import {
    getListOfPowerDialerCampaigns,
    getTwilloPowerCallCandidateList,
    selectTwilloPowerCallCandidateList,
    selectTwilloPowerCallListCreate,
    updateBulkCallCampaign,
} from "@/store/reducers/project/project.reducer";
import TableData from "./TableData";

const CandidatePowerDialer = () => {
    const twilloPowerCallCandidateListFromStore = useSelector(selectTwilloPowerCallCandidateList);
    const powerDialerCampaigns = useSelector(selectTwilloPowerCallListCreate);
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        makeOutgoingCall,
        setPhoneNumber,
        callData,
        setCallData,
        device,
        isDialing,
        setIsDialing,
        twilloPowerCallCandidateList,
        setTwilloPowerCallCandidateList,
        currentIndex,
        setCurrentIndex,
        setCurrentCampaign,
    } = useCallContext();
    const navigate = useNavigate();
    const isLoading = useSelector(checkIfLoading(getTwilloPowerCallCandidateList.type));
    const [isPaused, setIsPaused] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isLocallySaved, setIsLocallySaved] = useState(false);
    const nextCallRef = useRef(() => { });
    const campaign = powerDialerCampaigns?.bulkCallLogs?.[0];
    const campaignStatus = campaign?.status;
    const campaignResumeIndex = campaign?.resumeFrom?.index;
    const [resumeIndex, setResumeIndex] = useState(campaignResumeIndex || -1);
    const makePowerDialerCall = useCallback(
        (contact) => {
            setPhoneNumber(String(contact.phone));
            makeOutgoingCall(contact, `BULKCALL_${id}`);
        },
        [makeOutgoingCall, setPhoneNumber, id]
    );

    useEffect(() => {
        dispatch(getListOfPowerDialerCampaigns({ campaignId: id }));
        dispatch(getTwilloPowerCallCandidateList({ campaignId: id }));
    }, [id, isEdit]);

    useEffect(() => {
        if (callData === null && isDialing[id]) {
            const timer = setTimeout(() => {
                nextCallRef.current();
            }, 600); // Add a small delay to ensure the call has truly ended
            return () => clearTimeout(timer);
        }
    }, [callData, isDialing, id]);

    nextCallRef.current = () => {
        if (isDialing[id] && currentIndex[id] < twilloPowerCallCandidateList[id].length - 1) {
            const newIndex = currentIndex[id] + 1;
            setCurrentIndex(id, newIndex);
            makePowerDialerCall(twilloPowerCallCandidateList[id][newIndex]);
        } else {
            endCampaign();
        }
    };

    const endCampaign = () => {
        setIsDialing(id, false);
        setCurrentIndex(id, -1);
        setCurrentCampaign(null);
        device.disconnectAll();
        dispatch(updateBulkCallCampaign({ id, status: "COMPLETED", resumeFrom: { index: "-1" } }));
        setCallData({});
    };

    const startDialing = () => {
        setIsDialing(id, true);
        setCurrentIndex(id, 0);
        setIsPaused(false);
        setIsEdit(false);
        setResumeIndex(-1);
        setCurrentCampaign(powerDialerCampaigns?.bulkCallLogs?.[0]);

        if (twilloPowerCallCandidateList[id].length > 0) {
            makePowerDialerCall(twilloPowerCallCandidateList[id][0]);
            dispatch(
                updateBulkCallCampaign({
                    id,
                    status: "IN_PROGRESS",
                    isCampaignStarted: true,
                    resumeFrom: { index: "-1" },
                })
            );
        }
    };

    const resumeDialing = useCallback(
        (resumeFrom) => {
            setIsDialing(id, true);
            setCurrentIndex(id, resumeFrom);

            if (twilloPowerCallCandidateList[id].length > 0) {
                makePowerDialerCall(twilloPowerCallCandidateList[id][resumeFrom]);
            }
        },
        [twilloPowerCallCandidateList, makePowerDialerCall, id, setIsDialing, setCurrentIndex]
    );

    useEffect(() => {
        setTwilloPowerCallCandidateList(id, twilloPowerCallCandidateListFromStore?.candidates || []);
    }, [twilloPowerCallCandidateListFromStore, id]);

    useEffect(() => {
        if (powerDialerCampaigns?.bulkCallLogs?.[0]?.isCampaignStarted && !isLocallySaved) {
            setResumeIndex(campaignResumeIndex);

            setIsPaused(campaignStatus === "PAUSED");
        } else {
            setResumeIndex(-1);
        }
    }, [campaignResumeIndex, campaignStatus, id, isLocallySaved]);

    const handleBack = () => {
        navigate("/call-logs/power-dialer");
    };

    const handlePause = () => {
        setIsDialing(id, false);
        device.disconnectAll();
        setIsPaused(true);
        setIsLocallySaved(true);
        setResumeIndex(currentIndex[id]);
        dispatch(updateBulkCallCampaign({ id, status: "PAUSED", resumeFrom: { index: currentIndex[id] } }));
    };

    const handleResume = () => {
        resumeDialing(resumeIndex);
        setIsPaused(false);
        setResumeIndex(-1);
        setIsLocallySaved(true);
        dispatch(
            updateBulkCallCampaign({ id, status: "IN_PROGRESS", isCampaignStarted: true, resumeFrom: { index: "-1" } })
        );
    };

    const handleEditToggle = () => {
        if (isEdit) {
            const updatedCandidateIds = twilloPowerCallCandidateList[id].map((candidate) => candidate._id);
            dispatch(updateBulkCallCampaign({ id, candidateIds: updatedCandidateIds }));
        }
        setIsEdit((prev) => !prev);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <IconButton onClick={handleBack} sx={{ color: "primary.main" }}>
                    <ArrowBackIcon />
                </IconButton>
                {isDialing[id] && !isPaused && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={handlePause} sx={{ color: "primary.main" }}>
                            <PauseIcon />
                        </IconButton>
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Pause
                        </Typography>
                    </Box>
                )}

                {isPaused && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={handleResume} sx={{ color: "primary.main" }}>
                            <RefreshIcon />
                        </IconButton>
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Resume
                        </Typography>
                    </Box>
                )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginRight: "10px" }}>
                    {isDialing[id] &&
                        currentIndex[id] >= 0 &&
                        currentIndex[id] < twilloPowerCallCandidateList?.[id]?.length && (
                            <CallModalContent
                                candidate={twilloPowerCallCandidateList[id][currentIndex[id]]}
                                editPhoneNumber={false}
                                setEditPhoneNumber={() => { }}
                                handleBlockCandidate={() => { }}
                                block={false}
                                powerDialer={true}
                            />
                        )}
                </div>

                <div style={{ width: "100%" }}>
                    <TableData
                        isLoading={isLoading}
                        twilloPowerCallCandidateList={twilloPowerCallCandidateList[id] || []}
                        setTwilloPowerCallCandidateList={setTwilloPowerCallCandidateList}
                        isCampaignStarted={
                            isDialing[id] || powerDialerCampaigns?.bulkCallLogs?.[0]?.isCampaignStarted || !isEdit
                        }
                        campaignId={id}
                        resumeIndex={resumeIndex}
                        currentIndex={currentIndex[id]}
                    />
                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <>
                            {!isDialing[id] ? (
                                <Button
                                    variant="contained"
                                    onClick={startDialing}
                                    disabled={
                                        twilloPowerCallCandidateList[id]?.length === 0 ||
                                        Object.values(isDialing).some((dialing) => dialing)
                                    }
                                    startIcon={<Phone />}
                                    sx={{ borderRadius: 1 }}
                                >
                                    Start Dialing
                                </Button>
                            ) : (
                                <Box>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Now dialing: {twilloPowerCallCandidateList[id][currentIndex[id]]?.name} -{" "}
                                        {twilloPowerCallCandidateList[id][currentIndex[id]]?.phone}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            device.disconnectAll();
                                            nextCallRef.current;
                                        }}
                                        endIcon={<KeyboardArrowRight />}
                                        sx={{ borderRadius: 1 }}
                                    >
                                        Next Call
                                    </Button>
                                </Box>
                            )}
                        </>
                        <>
                            {!powerDialerCampaigns?.bulkCallLogs?.[0]?.isCampaignStarted && !isLoading && (
                                <Button
                                    variant="contained"
                                    onClick={handleEditToggle}
                                    sx={{ borderRadius: 1 }}
                                    disabled={isDialing[id]}
                                >
                                    {isEdit ? "Save" : "Edit"}
                                </Button>
                            )}
                        </>
                    </Box>
                </div>
            </div>
        </Box>
    );
};

export default CandidatePowerDialer;
