import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";

import { Option } from "../../../common";
import { RootState } from "../..";

export type Tag = "MUST" | "PRETEND" | "MUST_NOT";

export type CriteriaType = "jobTitle" | "country" | "city" | "industry" | "employeCount";

export const leadTypeValues = [
    {
        label: "Title",
        value: "role",
    },
    {
        label: "Country/Region",
        value: "country",
    },
    {
        value: "industry",
        label: "Industry",
    },
    {
        value: "employeeCount",
        label: "Company size",
    },
];

export const employeeCount = [
    {
        label: "1 - 20",
        value: "1 - 20",
    },
    {
        label: "21 - 50",
        value: "21 - 50",
    },
    {
        label: "51 - 100",
        value: "51 - 100",
    },
    {
        label: "101 - 500",
        value: "101 - 500",
    },
    {
        label: "500+",
        value: "500+",
    },
];

export const leadTagValues = {
    MUST: "Must",
    PREFERRED: "Great Fit",
    NOT_MUST: "Must not",
};

export const orderOption = [
    {
        label: "High",
        value: "high",
    },
    {
        label: "Medium",
        value: "medium",
    },
    {
        label: "Low",
        value: "low",
    },
];

export interface ICriteria {
    id: string;
    type: string;
    data: {
        MUST: Option[] | [];
        PREFERRED: Option[] | [];
        NOT_MUST: Option[] | [];
    };
    // values: string[];
    // tag: string;
    // appendText?: string;
    deleted?: boolean;
    order: string;
}

export interface SetCriteriaPayload extends ICriteria {
    _id: string;
}

type IRootState = {
    criteriaName: string;
    criteria: ICriteria[];
    criteriaId: string;
    leadRatingCriteriaList: [];
    enableAppendText: boolean;
};

const initialState: IRootState = {
    criteriaName: "",
    criteria: [],
    criteriaId: "",
    leadRatingCriteriaList: [],
    enableAppendText: false,
};

export type SubmitCriteriaForInsightGenerationPayload = PayloadAction<{
    onClose?: () => void;
}>;

const reducers = {
    submitCriteriaForInsightGeneration(
        state: typeof initialState,
        action: SubmitCriteriaForInsightGenerationPayload
    ) {},
    setCriteriaName(state: typeof initialState, action: PayloadAction<string>) {
        state.criteriaName = action.payload;
    },
    addInitNewCriteria(state: typeof initialState) {
        state.criteria.push(
            {
                id: nanoid(),
                type: "role",
                data: {
                    MUST: [],
                    PREFERRED: [],
                    NOT_MUST: [],
                },
                order: "high",
            },
            {
                id: nanoid(),
                type: "country",
                data: {
                    MUST: [],
                    PREFERRED: [],
                    NOT_MUST: [],
                },
                order: "medium",
            },
            {
                id: nanoid(),
                type: "employeeCount",
                data: {
                    MUST: [],
                    PREFERRED: [],
                    NOT_MUST: [],
                },
                order: "medium",
            },
            {
                id: nanoid(),
                type: "industry",
                data: {
                    MUST: [],
                    PREFERRED: [],
                    NOT_MUST: [],
                },
                order: "medium",
            }
        );
    },
    addCriteria(state: typeof initialState) {
        state.criteria.push({
            id: nanoid(),
            type: "",
            data: {
                MUST: [],
                PREFERRED: [],
                NOT_MUST: [],
            },
            order: "high",
        });

        setTimeout(() => {
            const container = document.getElementById("vetting-criteria-container");
            if (!container) return;
            const lastChild = container.childNodes[container.childNodes?.length - 1];
            //@ts-ignore
            if (lastChild) lastChild?.scrollIntoView({ behaviour: "smooth" });
        }, 100);
    },
    setCriteria(state: typeof initialState, { payload }: { payload: any }) {
        state.criteriaId = payload.criteriaId;
        state.criteria = payload.criteria;
        state.criteriaName = payload?.criteriaName || state.criteriaName;
    },
    editCriteria(state: typeof initialState, { payload }: { payload: { index: number; key: string; item: any } }) {
        const { index, key, item } = payload;
        // console.log({ index, key, item });
        const criteria = JSON.parse(JSON.stringify(state.criteria));
        criteria[index][key] = item;
        state.criteria = criteria;
    },
    editAppendText(state: typeof initialState, { payload }: { payload: { id: string; text: string } }) {
        const { id, text } = payload;
        const criteria = state.criteria.find((item) => item.id === id);
        if (criteria) {
            // criteria["appendText"] = text;
        }
    },
    deleteCriteria(state: typeof initialState, { payload }: { payload: number }) {
        state.criteria[payload].deleted = state.criteria[payload].deleted ? false : true;
    },
    // setCriteria(
    //     state: typeof initialState,
    //     action: PayloadAction<SetCriteriaPayload[]>
    // ) {
    //     const { payload } = action;
    //     if (payload.length) {
    //         const newCriteria = payload.map((item) => {
    //             const { _id, ...rest } = item;
    //             return { ...rest, id: _id };
    //         });
    //         state.criteria = newCriteria;
    //     }
    // },
    setPointTag(state: typeof initialState, action: PayloadAction<{ id: string; value: Tag }>) {
        const { id, value } = action.payload;
        state.criteria.forEach((item) => {
            if (item.id === id) {
                // item.tag = "";
            }
        });
    },
    resetCriteria(state: typeof initialState) {
        state.criteria = [];
    },
    toggleAppendText(state: typeof initialState) {
        state.enableAppendText = !state.enableAppendText;
    },
    changeCriteriaType(state: typeof initialState, action: PayloadAction<{ id: string; value: CriteriaType }>) {
        const { id, value } = action.payload;
        state.criteria.forEach((item) => {
            if (item.id === id) {
                item.type = "";
            }
        });
    },
    addLeadRatingCriteria(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: { onClose: () => void };
        }
    ) {},
    getLeadRatingCriteriaList(state: typeof initialState) {
        state.criteriaId = "";
        state.criteria = [];
    },
    setLeadRatingCriteriaList(state: typeof initialState, { payload }: { payload: any }) {
        state.leadRatingCriteriaList = payload;
        if (payload.length) {
            payload?.forEach((i: any) => {
                if (i?.isSelected) {
                    state.criteriaId = i?._id;
                    state.criteriaName = i?.criteriaName;
                }
            });
        }
    },
    updateLeadRatingCriteria(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: { onClose?: () => void };
        }
    ) {},
    leavePage(state: any) {},
};

export const leadRatingSlice = createSlice({
    name: "leadRating",
    initialState,
    reducers,
});

export default leadRatingSlice.reducer;

export const {
    submitCriteriaForInsightGeneration,
    setCriteriaName,
    addInitNewCriteria,
    addCriteria,
    editAppendText,
    editCriteria,
    deleteCriteria,
    resetCriteria,
    setCriteria,
    setPointTag,
    changeCriteriaType,
    toggleAppendText,
    addLeadRatingCriteria,
    updateLeadRatingCriteria,
    getLeadRatingCriteriaList,
    setLeadRatingCriteriaList,
    leavePage,
} = leadRatingSlice.actions;

export const selectLeadRatingCriteria = (state: RootState) => state.leadRating.criteria;
export const selectLeadRatingCriteriaList = (state: RootState) => state.leadRating.leadRatingCriteriaList;
export const selectToggleAppendText = (state: RootState) => state.leadRating.enableAppendText;
export const selectCriteriaId = (state: RootState) => state.leadRating.criteriaId;
export const selectCriteriaName = (state: RootState) => state.leadRating.criteriaName;
