import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../..";
import { IActionPayload } from "../loaders.reducer";

export type INudge = "installExtension" | "showEmail" | "addToWorkflow";

type ISetNudgePayload = {
    installExtension: boolean;
    showEmail: boolean;
    addToWorkflow: boolean;
};

export interface INudgePayload {
    type: INudge;
    value: boolean;
}

type IRootState = {
    installExtension: boolean;
    showEmail: boolean;
    addToWorkflow: boolean;
};

const initialState: IRootState = {
    installExtension: true,
    showEmail: true,
    addToWorkflow: true,
};

const reducers = {
    editNudge(state: typeof initialState, { payload }: { payload: INudgePayload & IActionPayload }) {},
    setNudges(state: typeof initialState, { payload }: { payload: ISetNudgePayload }) {
        if ("installExtension" in payload) state.installExtension = payload.installExtension;
        if ("addToWorkflow" in payload) state.addToWorkflow = payload.addToWorkflow;
        if ("showEmail" in payload) state.showEmail = payload.showEmail;
    },
    leaveNudge(state: typeof initialState) {},
};

export const reducerPage = createSlice({
    name: "nudges",
    initialState,
    reducers,
});

export default reducerPage.reducer;
export const { editNudge, setNudges, leaveNudge } = reducerPage.actions;

export const selectInstallExtensionNudge = (state: RootState) => state.nudges.installExtension;
export const selectShowEmailNudge = (state: RootState) => state.nudges.showEmail;
export const selectAddToWorkflowNudge = (state: RootState) => state.nudges.addToWorkflow;
