import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "../MarketingPage.module.css";
import { uploadFile } from "../../../../../utils/file";
import { setMarketingCompanyLogo } from "@/store/reducers/marketing-page/MarketingPage.reducer";

const EditNameLogo = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [logo, setLogo] = useState<any>(null);

    async function uploadLogo(file?: File) {
        if (!file) return;
        setIsUploading(true);
        const response = await uploadFile(file, "marketing");
        if (Array.isArray(response)) {
            setLogo(response);
        }
        setIsUploading(false);
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                <Typography className={styles.uploadTitle} variant="h5">
                    Select Logo
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "40px" }}>
                <Stack>
                    <TextField
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => uploadLogo(e.target.files?.[0])}
                    />
                    <Stack flexDirection="row" justifyContent="flex-end" mt="30px">
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                                marginRight: "10px",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!logo}
                            onClick={() => {
                                if (logo && logo[0]) {
                                    dispatch(setMarketingCompanyLogo(logo[0]));
                                }
                                onClose();
                            }}
                            variant="contained"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                            }}
                        >
                            {isUploading ? "Uploading..." : "Submit"}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default EditNameLogo;
