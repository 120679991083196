import React from "react";

import { cn } from "@/lib/utils";

interface PageOption {
    handleClick: () => void;
    key: string;
    label: string;
}

interface CustomPaginationProps {
    currentPage: number;
    totalCandidates: number;
    pageSize: number;
    handlePageChange: (page: number, pageSize: number) => void;
    pageOptions?: PageOption[];
    enablePageSizeChange?: boolean;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
    currentPage,
    totalCandidates,
    pageSize,
    handlePageChange,
    pageOptions,
    enablePageSizeChange = false,
}) => {
    const getPaginationLabel = () => {
        const start = (currentPage - 1) * pageSize + 1;
        const end = Math.min(currentPage * pageSize, totalCandidates);
        return `${start}-${end} of ${totalCandidates} items`;
    };

    const totalPages = Math.ceil(totalCandidates / pageSize);

    const [isOpen, setIsOpen] = React.useState(false);

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i, pageSize)}
                    className={cn("h-8 min-w-[32px] px-2 text-xs font-normal", {
                        "border border-cyan-600 text-cyan-600 rounded": currentPage === i,
                        "text-black hover:bg-gray-50": currentPage !== i,
                    })}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    return (
        <div className="flex justify-end items-center p-2.5 text-xs border-b-0">
            <span className="text-xs font-normal">{getPaginationLabel()}</span>

            <div className="flex items-center gap-1 mx-2">
                {/* {renderPageNumbers()} */}

                {currentPage !== 1 && (
                    <button
                        onClick={() => handlePageChange(currentPage - 1, pageSize)}
                        className="h-8 px-2 text-xs font-normal border rounded border-gray-300 text-black hover:bg-gray-50"
                    >
                        Previous
                    </button>
                )}

                {currentPage !== totalPages && (
                    <button
                        onClick={() => handlePageChange(currentPage + 1, pageSize)}
                        className="h-8 px-2 text-xs font-normal border rounded border-gray-300 text-black hover:bg-gray-50"
                    >
                        Next
                    </button>
                )}
            </div>

            {enablePageSizeChange && pageOptions && (
                <div className="relative">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="h-8 px-3.5 text-xs font-normal text-black rounded border border-cyan-600 focus:outline-none bg-white flex items-center gap-1"
                    >
                        {`${pageSize}/page`}
                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>

                    {isOpen && (
                        <div className="absolute bottom-full right-0 mb-1 w-40 bg-white border border-gray-200 rounded-md shadow-lg">
                            {pageOptions.map((option) => (
                                <button
                                    key={option.key}
                                    className="w-full px-4 py-2 text-xs text-left hover:bg-gray-50 flex items-center justify-between"
                                    onClick={() => {
                                        option.handleClick();
                                        setIsOpen(false);
                                    }}
                                >
                                    <span>{option.label}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomPagination;
