import { useCallback, useEffect, useRef, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    getProjectCandidates,
    resetRequestId,
    searchCandidates,
    selectSearchQuery,
    selectShowCandidateSearch,
    setSearchQuery,
    setShowCandidateSearch,
} from "@/store/reducers/project/project.reducer";

const CustomOutlineInput = styled((props: OutlinedInputProps) => <OutlinedInput {...props} />)(() => ({
    borderRadius: "1rem",
    padding: 0,
    "& .MuiOutlinedInput-input": {
        padding: "0.5rem 0.7rem",
        fontSize: "12px",
        borderRadius: "0.7rem",
    },
}));

const SEARCH_REGEX = /^\s*$/;

export default function Search() {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const globalSearchQuery = useSelector(selectSearchQuery);
    const [values, setValues] = useState({ query: globalSearchQuery, error: false });
    const showSearch = useSelector(selectShowCandidateSearch);
    const inputRef = useRef<null | HTMLInputElement>();
    const isSearching = useSelector(checkIfLoading(searchCandidates.type));

    useEffect(() => {
        if (showSearch) {
            inputRef?.current?.focus();
        }
    }, [showSearch]);

    const debouncedDispatchSearchQuery = useCallback(
        debounce((value) => {
            dispatch(setSearchQuery(value));
        }, 500),
        []
    );

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const value = values.query;
        const isError = SEARCH_REGEX.test(value);
        if (isError) {
            setValues((prev) => {
                return {
                    ...prev,
                    error: isError,
                };
            });
        } else {
            dispatch(
                searchCandidates({
                    projectId: id,
                    action: searchCandidates.type,
                    searchQuery: values.query,
                })
            );
            // @ts-ignore
            dispatch(resetRequestId());
        }
    };

    const handleClick = () => {
        dispatch(setShowCandidateSearch(!showSearch));
        setValues({ query: "", error: false });
        dispatch(setSearchQuery(""));
        // @ts-ignore
        dispatch(resetRequestId());
        if (showSearch) {
            dispatch(
                getProjectCandidates({
                    projectId: id,
                    action: getProjectCandidates.type,
                })
            );
        }
    };

    const handleEscapeClick = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.code === "Escape") {
            handleClick();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setValues((prev) => {
            const isError = SEARCH_REGEX.test(value);
            return {
                ...prev,
                query: value,
                error: prev.error ? isError : prev.error,
            };
        });
        debouncedDispatchSearchQuery(value);
    };

    return (
        <>
            <Tooltip title={t("projectsPage.candidateSection.searchCandidates")} placement="top">
                <IconButton aria-label="delete" onClick={handleClick}>
                    {showSearch ? <CancelRoundedIcon fontSize="small" /> : <SearchRoundedIcon fontSize="inherit" />}
                </IconButton>
            </Tooltip>
            {showSearch && (
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl>
                        <CustomOutlineInput
                            placeholder="Please enter text"
                            value={values.query}
                            error={values.error}
                            onChange={handleChange}
                            inputRef={inputRef}
                            onKeyDown={handleEscapeClick}
                            inputProps={{
                                required: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton type="submit" color="primary" disabled={isSearching}>
                                        <SendRoundedIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </form>
            )}
        </>
    );
}
