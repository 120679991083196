import {
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    MenuItem,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import D3Funnel from "d3-funnel";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import useWindowSize from "../../../../hooks/useWindowSize";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import {
    getProjectWorkflows,
    getWorkflowEmailStats,
    getWorkflowLinkedInStats,
    selectProjectWorkflows,
    selectWorkflowEmailStats,
    selectWorkflowLinkedInStats,
} from "@/store/reducers/project/project.reducer";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    tabPanelStyle?: React.CSSProperties;
    [key: string]: any;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, tabPanelStyle, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={tabPanelStyle}>{children}</Box>}
        </div>
    );
}

function CenteredBox({ children }: { children: React.ReactNode }) {
    return (
        <Box height={"100%"} display="flex" alignItems="center" justifyContent="center">
            {children}
        </Box>
    );
}

function GridItem({ label, value }: { label: string; value: number }) {
    return (
        <Stack direction="row" alignItems="center">
            <Typography mb={2}>{label} :</Typography>
            <Typography ml={2} mb={2}>
                {value}
            </Typography>
        </Stack>
    );
}

function OverviewTab() {
    const emailStats = useSelector(selectWorkflowEmailStats);
    const linkedInStats = useSelector(selectWorkflowLinkedInStats);
    const emailStatsChartRef = useRef<HTMLDivElement | null>(null);

    const [tab, setTab] = useState(0);

    const handleTabChange = (e: React.SyntheticEvent, newValue: number) => setTab(newValue);

    useEffect(() => {
        if (tab === 0) {
            const data = [
                { label: "Email Sent", value: emailStats?.emailSent || 0 },
                { label: "Emails opened", value: emailStats?.openRate || 0 },
                {
                    label: "No. of responses",
                    value: emailStats?.responseRate || 0,
                },
            ];
            const options = {
                block: {
                    dynamicHeight: true,
                    minHeight: 15,
                    fill: {
                        type: "gradient",
                        scale: ["#0891b2", "#FDC064", "#27BC36"],
                    },
                    tooltip: {
                        enabled: true,
                        format: "{l} : {f}",
                    },
                    label: { enabled: true },
                },
            };

            const chart = new D3Funnel(emailStatsChartRef.current);
            chart.draw(data, options);
        }
    }, [tab]);

    return (
        <Box height="100%">
            <Box borderBottom={1} borderColor="divider">
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={{
                        backgroundColor: "#FAFAFA",
                    }}
                >
                    <Tab label="Email" {...a11yProps(0)} />
                    <Tab label="LinkedIn" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel
                value={tab}
                index={0}
                tabPanelStyle={{
                    padding: "16px 0",
                }}
            >
                <Card variant="elevation" sx={{ borderRadius: 3, height: "100%" }}>
                    <Box p="2rem 3rem">
                        <Typography color={"#334D6E"} fontSize={20} fontWeight={600} mb={2}>
                            Email Statistics
                        </Typography>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Box width={"50%"}>
                                <GridItem label="Email Sent" value={emailStats?.emailSent ?? 0} />
                                <GridItem label="Emails opened" value={emailStats?.openRate ?? 0} />
                                <GridItem label="No. of clicks" value={emailStats?.clickRate ?? 0} />
                                <GridItem label="No. of responses" value={emailStats?.responseRate ?? 0} />
                            </Box>
                            <Box width={"50%"} display="flex" justifyContent="center" height={150}>
                                <Box ref={emailStatsChartRef} />
                            </Box>
                        </Stack>
                    </Box>
                </Card>
            </TabPanel>
            <TabPanel
                value={tab}
                index={1}
                tabPanelStyle={{
                    padding: "16px 0",
                }}
            >
                <Card variant="elevation" sx={{ borderRadius: 3, height: "100%" }}>
                    <Box p="2rem 3rem">
                        <Typography color={"#334D6E"} fontSize={20} fontWeight={600} mb={2}>
                            LinkedIn Statistics
                        </Typography>
                        <Stack direction="row">
                            <Box width={"50%"}>
                                <Typography
                                    fontStyle="italic"
                                    sx={{ textDecoration: "underline" }}
                                    mb={2}
                                    color={"#334D6E"}
                                    fontWeight={600}
                                >
                                    Connection Request
                                </Typography>
                                <GridItem label="Connection request sent" value={linkedInStats?.sent ?? 0} />
                                <GridItem label="Connection request accepted" value={linkedInStats?.accepted ?? 0} />
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box width={"50%"} pl={4}>
                                <Typography
                                    fontStyle="italic"
                                    sx={{ textDecoration: "underline" }}
                                    mb={2}
                                    color={"#334D6E"}
                                    fontWeight={600}
                                >
                                    Inmail
                                </Typography>
                                <GridItem label="Inmail sent" value={linkedInStats?.inMail ?? 0} />
                            </Box>
                        </Stack>
                    </Box>
                </Card>
            </TabPanel>
        </Box>
    );
}

function SummaryTab() {
    const handleSummaryModal = (row: any) => {
        console.log(row);
    };

    const columns: GridColDef[] = [
        {
            field: "candidate",
            headerName: "Candidate",
            flex: 1,
            valueGetter: (params) => params.row.candidate.name,
        },
        {
            field: "name",
            headerName: "Workflow",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "Created On",
            flex: 1,
            valueGetter: (params) => dayjs(params.row.createdAt).locale("en").format("M/D/YYYY, hh:mm A"),
        },
        {
            field: "triggeredAt",
            headerName: "Triggered On",
            flex: 1,
            valueGetter: (params) => dayjs(params.row.triggeredAt).locale("en").format("M/D/YYYY, hh:mm A"),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" onClick={() => handleSummaryModal(params.row)}>
                    View
                </Button>
            ),
        },
    ];

    return <DataGrid rows={[]} columns={columns} hideFooter />;
}

export default function StatsTab() {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const projectWorkflows = useSelector(selectProjectWorkflows);
    const checkWorkflowsLoading = useSelector(checkIfLoading(getProjectWorkflows.type));
    const checkWorkflowEmailStatsLoading = useSelector(checkIfLoading(getWorkflowEmailStats.type));

    const dispatch = useDispatch();
    const size = useWindowSize();
    const overflowTabHeight = size.height - 280;
    const workflowFromUrl = searchParams.get("workflow");

    const [tab, setTab] = useState(0);
    const [workflow, setWorkflow] = useState(workflowFromUrl || "all");

    const handleTabChange = (e: React.SyntheticEvent, newValue: number) => setTab(newValue);

    useEffect(() => {
        if (!id) return;

        dispatch(
            getWorkflowEmailStats({
                action: getWorkflowEmailStats.type,
                workflowId: workflow,
                projectId: id,
            })
        );
        dispatch(
            getWorkflowLinkedInStats({
                action: getWorkflowLinkedInStats.type,
                workflowId: workflow,
                projectId: id,
            })
        );
    }, [workflow]);

    return (
        <Box mt={2} bgcolor="#fff" borderRadius={3} height="100%">
            {checkWorkflowsLoading ? (
                <CenteredBox>
                    <CircularProgress />
                </CenteredBox>
            ) : isEmpty(projectWorkflows) ? (
                <CenteredBox>
                    <Typography>No workflows triggered</Typography>
                </CenteredBox>
            ) : (
                <>
                    <Box
                        borderBottom={1}
                        borderColor="divider"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Tabs value={tab} onChange={handleTabChange}>
                            {/* <Tab label="Project Summary" {...a11yProps(0)} /> */}
                            <Tab label="Overview" {...a11yProps(0)} />
                        </Tabs>
                        <TextField
                            select
                            label="Select Workflow"
                            value={workflow}
                            onChange={(e) => setWorkflow(e.target.value)}
                            sx={{
                                margin: "auto 1rem auto auto",
                                minWidth: 200,
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {projectWorkflows.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {/* <TabPanel
                        value={tab}
                        index={0}
                        tabPanelStyle={{
                            padding: "8px 24px",
                        }}
                        style={{
                            height: overflowTabHeight,
                        }}
                    >
                        <SummaryTab />
                    </TabPanel> */}
                    <TabPanel
                        value={tab}
                        index={0}
                        tabPanelStyle={{
                            height: "100%",
                            padding: "16px 24px",
                        }}
                        style={{
                            height: overflowTabHeight,
                        }}
                    >
                        {checkWorkflowEmailStatsLoading ? (
                            <CenteredBox>
                                <CircularProgress />
                            </CenteredBox>
                        ) : (
                            <OverviewTab />
                        )}
                    </TabPanel>
                </>
            )}
        </Box>
    );
}
