import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useMenu from "../hooks/useMenu";
import {
    useFetchAutoPilotSettingsQuery,
    useSaveAutopilotSettingsMutation,
} from "../store/apis/autopilot/autopilot.api";

import { setProjectAutoPilotStatus, submitAutoSearchStatus } from "@/store/reducers/allProjects/allProjects.reducer";
import { checkIsV2Autopilot } from "@/store/reducers/signin/Signin.reducer";
import { Tooltip } from "@/ui";

export function AutopilotDropdown({ projectId, autoSource }: { projectId: number; autoSource: boolean }) {
    const dispatch = useDispatch();
    const [saveAutopilotSettings] = useSaveAutopilotSettingsMutation();
    const isV2Autopilot = useSelector(checkIsV2Autopilot);

    const [projectIdToFetch, setProjectIdToFetch] = useState<number | null>(null);

    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    const { data: fetchAutoPilotSettings } = useFetchAutoPilotSettingsQuery(
        {
            projectId: Number(projectIdToFetch),
        },
        {
            skip: !projectIdToFetch,
        }
    );

    const handleChange = (newValue: number) => {
        const newStatus = newValue === 0 ? false : true;

        saveAutopilotSettings({
            projectId: String(projectId),
            autoPilotSettings: {
                ...fetchAutoPilotSettings?.autopilotSettings,
                enableReachout: newStatus,
                dailyCandidateLimit: Array.isArray(fetchAutoPilotSettings?.autopilotSettings?.dailyCandidateLimit)
                    ? fetchAutoPilotSettings?.autopilotSettings?.dailyCandidateLimit?.[0]
                    : fetchAutoPilotSettings?.autopilotSettings?.dailyCandidateLimit,
            },
        });

        dispatch(submitAutoSearchStatus({ status: newStatus, project: String(projectId) }));
        dispatch(
            setProjectAutoPilotStatus({
                projectId: projectId,
                autoSource: newStatus,
            })
        );
        handleClose();
        setProjectIdToFetch(null);
    };

    const getColor = () => {
        if (autoSource) {
            return "success";
        }
        return "disabled";
    };

    return (
        <>
            <Box flexShrink={0} marginLeft={0.5}>
                <Button
                    size="small"
                    sx={{
                        fontSize: 10,
                        height: "22px",
                        minWidth: 48,
                        "& .MuiButton-endIcon": {
                            marginLeft: 0.5,
                        },
                    }}
                    variant="outlined"
                    color={getColor()}
                    onClick={(e) => {
                        e.stopPropagation();
                        setProjectIdToFetch(projectId);
                        handleClick(e);
                    }}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                >
                    {autoSource ? "Auto Pilot" : "Manual"}
                </Button>
            </Box>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                <Tooltip
                    title={
                        !fetchAutoPilotSettings?.isOutreachTemplateAvailable && isV2Autopilot
                            ? "To access this feature, please complete one outreach activity"
                            : ""
                    }
                >
                    <div>
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();

                                handleChange(1);
                            }}
                            disabled={!fetchAutoPilotSettings?.isOutreachTemplateAvailable && isV2Autopilot}
                        >
                            Auto Pilot
                        </MenuItem>
                    </div>
                </Tooltip>
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        handleChange(0);
                    }}
                >
                    Manual
                </MenuItem>
            </Menu>
        </>
    );
}
