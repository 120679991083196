import GlobalStyles from "@mui/joy/GlobalStyles";

export default function Header() {
    return (
        <div className="flex sm:hidden items-center justify-between fixed top-0 w-full border-b border-background-level1 shadow-sm z-[9995]">
            <GlobalStyles
                styles={(_theme) => ({
                    ":root": {
                        "--Header-height": "56px",
                    },
                })}
            />
        </div>
    );
}
