import AddRoundedIcon from "@mui/icons-material/AddRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import TravelExploreRoundedIcon from "@mui/icons-material/TravelExploreRounded";
import { Button, IconButton, IconButtonProps, Tooltip, styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import {
    addCurrentCompanySuggestion,
    fetchCompanySuggestions,
    selectCompanySuggestions,
} from "@/store/reducers/filters/Filters.reducer";
import { CompanySuggestion } from "@/store/reducers/filters/Filters.type";


const CustomIconButton = styled(IconButton)<IconButtonProps>(() => ({
    padding: "3px !important",
    "& .MuiSvgIcon-root": {
        height: 16,
        width: 16,
    },
}));

function Suggestion(props: CompanySuggestion) {
    const dispatch = useDispatch();
    const { company_name, location, social_url } = props;
    const handleAdd = () => {
        dispatch(
            addCurrentCompanySuggestion({
                label: company_name,
                value: company_name,
            })
        );
    };

    const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        window.open(social_url, "_blank", "rel=noopener noreferrer");
    };

    return (
        <Stack
            component={Button}
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={handleAdd}
            sx={(theme) => ({
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                width: "fit-content",
                padding: "0.3rem 0.5rem",
            })}
        >
            <Stack direction="row" spacing={0.3} alignItems="center">
                <CustomIconButton>
                    <AddRoundedIcon fontSize="small" />
                </CustomIconButton>
                <Typography variant="caption" sx={(theme) => ({ color: theme.palette.grey[600] })}>
                    {company_name}
                    {location ? `, ${location}` : null}
                </Typography>
            </Stack>
            <CustomIconButton
                onClick={handleLinkClick}
                sx={{
                    "& .MuiSvgIcon-root": {
                        height: 14,
                        width: 14,
                    },
                }}
            >
                <OpenInNewRoundedIcon fontSize="small" />
            </CustomIconButton>
        </Stack>
    );
}

export function AddMoreCompanies() {
    const dispatch = useDispatch();
    const isFetchingCompanies = useSelector(checkIfLoading(fetchCompanySuggestions.type));
    return (
        <Tooltip title={isFetchingCompanies ? null : "Show Similar Companies"} arrow>
            <IconButton onClick={() => dispatch(fetchCompanySuggestions())} disabled={isFetchingCompanies}>
                <ButtonTextWithLoading
                    isLoading={isFetchingCompanies}
                    text={<TravelExploreRoundedIcon />}
                    progressStyle={{ color: "#0891b2" }}
                />
            </IconButton>
        </Tooltip>
    );
}

export function CompanySuggestions() {
    const suggestions = useSelector(selectCompanySuggestions);

    if (!suggestions?.length) {
        return null;
    }

    return (
        <Stack
            direction="row"
            sx={{
                maxWidth: "100%",
                gap: "0.5rem",
                maxHeight: "300px",
                overflowY: "auto",
                padding: "0.5rem",
            }}
            flexWrap="wrap"
        >
            {suggestions.map((i: CompanySuggestion) => {
                return <Suggestion {...i} key={i.company_name} />;
            })}
        </Stack>
    );
}
