import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Modal, Sheet, Stack } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import { TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DetailedView from "./DetailedView";
import FilterMenu from "./FilterMenu";
import GiftCard from "./GiftCard";
import SendGiftModal from "./SendGiftModal";

import GiftIcon from "../../../../assets/img/giftIcon.svg";
import { JoyProvider } from "../../../../components/JoyProvider";
import Loader from "../../../../components/Loader/Loader";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { easyGrowthLogo } from "../../../../constant/Constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import { getSendosoGifts, isAllProductsLoaded, sendosoGifts } from "@/store/reducers/project/project.reducer";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";
import { Button } from "@/ui";

export default function SendosoModal({ open, onClose, candidate, country, setApplyCountry, applyCountry }) {
    const [selectedCountryCodes, setSelectedCountryCodes] = useState([]);
    const isAllSendosoProductsLoaded = useSelector(isAllProductsLoaded);
    const isEasySource = useSelector(checkEasySource);
    const [searchProducts, setSearchProducts] = useState(null);
    const sendosoGiftsData = useSelector(sendosoGifts) ?? null;
    const loading = useSelector(checkIfLoading(getSendosoGifts.type));
    const [loadingAfter, setLoadingAfter] = useState(false);
    const [minPrice, setMinPrice] = useState("");
    const [selectedRange, setSelectedRange] = useState("");

    const cardRefs = useRef([]);
    const [maxPrice, setMaxPrice] = useState("");
    const [detailedView, setDetailedView] = useState(null);
    const [openSendGiftModal, setOpenSendGiftModal] = useState(false);

    const dispatch = useDispatch();

    const loadMore = () => {
        //if maxPrice , minPrice and selectedCountryCodes are empty, then fetch all gifts else fetch gifts based on the filter
        const payload = {
            text_search: searchProducts,
            price_lte_usd: Number(maxPrice),
            price_gte_usd: Number(minPrice),
            ship_to_country_codes: selectedCountryCodes.map((country) => country.code),
            after: sendosoGiftsData?.pagination?.after,
        };
        dispatch(getSendosoGifts(payload));
        setLoadingAfter(true);
    };

    const handleDetailedView = (product) => {
        setDetailedView(product);
    };

    const filterGifts = () => {
        const payload = {
            text_search: searchProducts,
            price_lte_usd: Number(maxPrice),
            price_gte_usd: Number(minPrice),
            ship_to_country_codes: selectedCountryCodes.map((country) => country.code),
        };
        dispatch(getSendosoGifts(payload));
        setLoadingAfter(false);
    };
    const clearFilter = () => {
        setSelectedCountryCodes([]);
        handleRangeChange({ target: { value: "" } });
        setMinPrice("");
        setMaxPrice("");
        setApplyCountry((prevApplyCountry: any) => ({
            ...prevApplyCountry,
            [candidate?.candidateId]: [],
        }));
        dispatch(getSendosoGifts({ text_search: searchProducts }));
    };

    useEffect(() => {
        if (country?.code && !applyCountry) {
            setSelectedCountryCodes([{ name: country?.name, code: country?.code }]);
        }
    }, [country, applyCountry]);

    // Create a debounced version of the dispatch call
    const debouncedSearch = useCallback(
        debounce((text_search, price_gte_usd, price_lte_usd) => {
            const payload = { text_search, price_gte_usd, price_lte_usd };
            dispatch(getSendosoGifts(payload));
        }, 300), // 300ms debounce delay
        []
    );

    // Call the debounced function whenever searchProducts changes
    useEffect(() => {
        if (searchProducts === null) {
            return;
        }

        debouncedSearch(searchProducts, minPrice, maxPrice);

        // Cancel the debounce on unmount or if searchProducts changes
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchProducts, debouncedSearch]);

    const handleChange = (event) => {
        setSearchProducts(event.target.value);
        setLoadingAfter(false);
    };
    const handleBack = () => {
        setDetailedView(null);
    };
    const handleRangeChange = (event) => {
        setSelectedRange(event.target.value);
        if (event.target.value === "custom") {
            return;
        }

        const [min, max] = event.target.value.split("-");
        setMinPrice(min);
        setMaxPrice(max);
    };

    const handleClose = () => {
        setDetailedView(null);
        setMaxPrice("");
        setMinPrice("");
        onClose();
    };

    return (
        <JoyProvider>
            <SendGiftModal
                open={openSendGiftModal}
                onClose={() => setOpenSendGiftModal(false)}
                candidate={candidate}
                detailedView={detailedView}
                closeSendosoModal={() => onClose()}
            />
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Sheet
                    variant="plain"
                    sx={{
                        width: "150vh",
                        borderRadius: "sm",
                        height: "90vh",

                        boxShadow: "lg",
                        overflow: "auto",
                        backgroundColor: "white",
                    }}
                >
                    <IconButton sx={{ m: 3, position: "absolute", right: 0, top: 0 }} onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                    <div style={{ display: "flex" }}>
                        <Typography
                            variant="h3"
                            style={{ fontWeight: 400, marginTop: "30px", marginLeft: "30px", fontSize: "22px" }}
                        >
                            Send gifts via
                            {isEasySource ? (
                                <span style={{ marginLeft: "5px", color: "#334d6e", fontWeight: 400 }}>
                                    Easy<strong>Source</strong>
                                </span>
                            ) : (
                                <span style={{ position: "absolute", top: 31 }}>
                                    <img src={easyGrowthLogo} width={160} alt="logo" />
                                </span>
                            )}
                        </Typography>
                    </div>
                    <Typography
                        variant="h3"
                        style={{ fontWeight: 400, marginTop: "5px", marginLeft: "150px", fontSize: "14px" }}
                    >
                        Powered by Sendoso
                    </Typography>

                    {/* </div> */}
                    {/* <img style={{ marginTop: "30px", marginLeft: "30px", width: "100px", height: "50px" }}
                        src="https://cdn.prod.website-files.com/651ad5ceeba626e1de2f2ce8/651c92a16a856b0f0d5656ca_SendosoLogo_RGB.svg" alt="sendoso logo" /> */}

                    <div style={{ display: detailedView ? "block" : "none" }}>
                        <DetailedView
                            detailedView={detailedView}
                            setOpenSendGiftModal={setOpenSendGiftModal}
                            handleBack={handleBack}
                        />
                    </div>
                    <div style={{ display: detailedView ? "none" : "block" }}>
                        <MaterialProvider>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <TextField
                                    label="Search gifts"
                                    variant="outlined"
                                    size="small"
                                    value={searchProducts}
                                    onChange={(e) => handleChange(e)}
                                    sx={{
                                        mb: 2,
                                        width: "50%",
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#0891B2", // Border color when focused
                                            },
                                        },

                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#0891B2", // Label color when focused
                                        },
                                    }}
                                />
                            </div>
                        </MaterialProvider>

                        {loading && !loadingAfter ? (
                            <div style={{ marginTop: "20vh" }}>
                                <Loader />
                            </div>
                        ) : (
                            <div>
                                {!sendosoGiftsData ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "60vh",
                                        }}
                                    >
                                        <SentimentDissatisfiedIcon sx={{ fontSize: "3rem", color: "grey" }} />
                                        <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                                            Some error occured while loading gifts please try again later!
                                        </Typography>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex" }}>
                                        <FilterMenu
                                            selectedCountryCodes={selectedCountryCodes}
                                            setSelectedCountryCodes={setSelectedCountryCodes}
                                            candidate={candidate}
                                            setApplyCountry={setApplyCountry}
                                            minPrice={minPrice}
                                            maxPrice={maxPrice}
                                            setMinPrice={setMinPrice}
                                            setMaxPrice={setMaxPrice}
                                            selectedRange={selectedRange}
                                            handleRangeChange={handleRangeChange}
                                            filterGifts={filterGifts}
                                            clearFilter={clearFilter}
                                        />
                                        <div style={{ height: "60vh", overflow: "auto" }}>
                                            {sendosoGiftsData?.products?.length !== 0 ? (
                                                <Stack sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                    {sendosoGiftsData?.products?.map((product, index) => {
                                                        const imageUrl =
                                                            product?.variants[0]?.images[0]?.url ?? GiftIcon;
                                                        return (
                                                            <GiftCard
                                                                key={product.id}
                                                                product={product}
                                                                index={index}
                                                                handleDetailedView={handleDetailedView}
                                                                cardRefs={cardRefs}
                                                                imageUrl={imageUrl}
                                                            />
                                                        );
                                                    })}
                                                </Stack>
                                            ) : (
                                                <Stack
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "col",
                                                        flexWrap: "wrap",
                                                        p: "40px",
                                                        marginLeft: "50vh",
                                                        marginTop: "20vh",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <SentimentDissatisfiedIcon
                                                            sx={{ fontSize: "3rem", color: "grey" }}
                                                        />
                                                        <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                                                            No Gifts Found
                                                        </Typography>
                                                    </div>
                                                </Stack>
                                            )}
                                            {!isAllSendosoProductsLoaded &&
                                                sendosoGiftsData?.products?.length !== 0 && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        <Button
                                                            variant="outline"
                                                            disabled={loading}
                                                            onClick={() => loadMore()}
                                                            endDecorator={
                                                                loading && (
                                                                    <div className="ml-2 mt-5">
                                                                        <Loader size="sm" />
                                                                    </div>
                                                                )
                                                            }
                                                        >
                                                            {"Load More"}
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Sheet>
            </Modal>
        </JoyProvider>
    );
}
