import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import useOAuth2 from "../../../hooks/useOAuth2/useOAuth2";
import { selectUser, selectZohoRefreshCreds, setUser } from "../../Signin/Signin.reducer";

import { Button, Modal, Typography } from "@/ui";

export default function ZohoRecruitConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const zohoRecruitCreds = useSelector(selectZohoRefreshCreds);
    const zohoRefreshToken = zohoRecruitCreds?.refresh_token;

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: `https://accounts.zoho.com/oauth/v2/auth`,
        clientId: `${import.meta.env.VITE_REACT_APP_ZOHO_CLIENT_ID}`,
        redirectUri: `${import.meta.env.VITE_REACT_APP_BASE_URL}/auth/zoho`,
        scope: "ZohoRECRUIT.modules.all,ZohoRecruit.setup.operation.all,ZohoRecruit.bulk.read",
        responseType: "code",
        extraQueryParameters: { prompt: "consent", access_type: "offline" },
        exchangeCodeForTokenServerURL: `${import.meta.env.VITE_REACT_APP_BASE_URL}/api/zohoRecruit/save-creds`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(setSuccessNotification(response.message));
            dispatch(
                setUser({
                    ...user,
                    zohoRecruitCreds: {
                        ...user.zohoRecruitCreds,
                        refresh_token: "0000000",
                    },
                })
            );
        },
        onError: () => dispatch(setErrorNotification("Error in connecting zoho recruit")),
    });

    const handleConnect = () => {
        getAuth();
    };

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ maxWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Connect Zoho Recruit</h2>

                <div style={{ display: "flex", alignItems: "center", marginBottom: "2px" }}>
                    <Typography className="text-sm">
                        Zoho Recruit is a cloud based applicant tracking system that’s built to provide diverse,
                        end-to-end hiring solutions for staffing agencies, corporate HRs and temporary workforce.
                    </Typography>
                </div>
                
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant={zohoRefreshToken ? "outline" : "default"}
                        onClick={handleConnect}
                        disabled={loading}
                        className="rounded-sm"
                        label={zohoRefreshToken ? "Connected" : "Connect With OAuth"}
                    />
                </div>
            </div>
        </Modal>
    );
}
