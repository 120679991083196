import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExpandSearchModal } from "./ExpandSearchModal";
import { useProjectData } from "./hooks";

import { addSearchCandidatesToProject } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

import { selectIsFixedSearch } from "@/store/reducers/search/search.slice";
import { Button } from "@/ui";

export default function AddListToProject() {
    const {
        projectId,
        projectName,
        candidates,
        dispatch,
        searchId,
        navigate,
        isAddingToProject,
        isScreeningWithAI,
        status,
    } = useProjectData();
    const { t } = useTranslation();
    const fixedSearchValues = useSelector(selectIsFixedSearch);
    const isFixedSearch = fixedSearchValues?.values?.[0]?.value;
    if (!searchId) return null;

    const isSearchCompleted = ["COMPLETED", "CANCELLED", "ZERO_PROFILE", "IN_PROGRESS"].includes(status || "");
    const isDisabled = isAddingToProject || isScreeningWithAI || !isSearchCompleted;
    const [openModal, setOpenModal] = useState(false);
    const handleAddToProject = () => {
        dispatch(
            addSearchCandidatesToProject({
                projectId,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep: false,
            })
        );
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const showExpandModal = candidates?.length < 20 && isFixedSearch;

    return (
        <>
            <Button
                variant="outline"
                disabled={isDisabled}
                onClick={showExpandModal ? handleOpenModal : handleAddToProject}
            >
                <ButtonTextWithLoading
                    isLoading={isAddingToProject}
                    text={t("blendSearch.addTo")}
                    progressStyle={{ color: "#0891b2" }}
                />
            </Button>
            {openModal && (
                <ExpandSearchModal
                    onClose={() => setOpenModal(false)}
                    handleClick={handleAddToProject}
                    isLoading={isAddingToProject}
                />
            )}
        </>
    );
}
