import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { setModal } from "../reducers/modals.slice";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import {
    addContactsFromCSVResponse,
    addContactsToExclusionList,
    allContactsCSVUpload,
    applyAllContactsFilters,
    cancelActions,
    clearAllSelectedContacts,
    fetchContacts,
    getContactReachout,
    setAllContacts,
    setAllContactsSearchQuery,
    setContactReachout,
} from "../apis/all-contacts/all-contacts.slice";

import {
    AddContactsToExclusionListPayload,
    AllContactsCSVUploadPayload,
    AllContactsFiltersType,
    AllContactsTableItemKeys,
    FetchContactsRequest,
    FetchContactsResponse,
    FilterBy,
    SearchContactsPayload,
} from "../apis/all-contacts/all-contacts.types";
import handleException from "../../utils/sentry";

function handleRequestPayload(
    filters: Partial<AllContactsFiltersType>,
    searchQuery: string | undefined
): FetchContactsRequest {
    const appliedFilters: FilterBy = {};

    (Object.keys(filters) as AllContactsTableItemKeys[]).forEach((k) => {
        const values = filters[k]?.value.map(({ value }) => value);
        if (values?.length) {
            appliedFilters[k] = values;
        }
    });

    const requestPayload: FetchContactsRequest = {};

    if (Object.keys(appliedFilters).length) {
        requestPayload.filterBy = appliedFilters;
    }

    if (searchQuery) {
        requestPayload.search = searchQuery;
    }

    return requestPayload;
}

function* fetchContactsSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        let url = `/contact-list/contacts?start=0&limit=500`;

        const requestPayload: FetchContactsRequest = {};

        const response: FetchContactsResponse = yield call(new API().post, url, requestPayload);

        if ("data" in response && response?.data?.length) {
            yield put(setAllContacts(response.data));
        } else {
            throw new Error("error while fetching all contacts");
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* searchContactsSaga(action: SearchContactsPayload) {
    try {
        const searchQuery = action.payload;

        if (!searchQuery) {
            return;
        }

        yield put(startAction({ action: action.type }));

        let url = `/contact-list/contacts?start=0&limit=500`;

        const requestPayload: FetchContactsRequest = {
            search: searchQuery,
        };

        const response: FetchContactsResponse = yield call(new API().post, url, requestPayload);

        if ("data" in response && response?.data?.length) {
            yield put(setAllContacts(response.data));
        } else {
            throw new Error("error while fetching all contacts");
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* allContactsCSVUploadSaga(action: AllContactsCSVUploadPayload): SagaIterator {
    try {
        const payload = action.payload;
        yield put(startAction({ action: action.type }));

        const response: FetchContactsResponse = yield call(new API().post, `/contact-list/contact/create`, payload);

        if (response?.data?.length) {
            yield put(addContactsFromCSVResponse(response.data));

            yield put(setModal({ key: "showAllContactsCSVModal", value: false }));
        } else {
            throw new Error("invalid response");
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* applyAllContactsFiltersSaga(action: PayloadAction): SagaIterator {
    try {
        const state = yield select();

        const appliedFilters: Partial<AllContactsFiltersType> = get(state, "allContacts.filters");

        const searchQuery: string | undefined = get(state, "allContacts.tableState.searchQuery");

        let url = `/contact-list/contacts?start=0&limit=500`;

        const requestPayload = handleRequestPayload(appliedFilters, searchQuery);

        yield put(startAction({ action: action.type }));

        const response: FetchContactsResponse = yield call(new API().post, url, requestPayload);

        if ("data" in response && response?.data?.length) {
            yield put(setAllContacts(response.data));
        } else {
            throw new Error("error while fetching all contacts");
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addContactsToExclusionListSaga(action: AddContactsToExclusionListPayload): SagaIterator {
    try {
        const payload = action.payload;
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().post, `/contact-list/contacts/add-to-opt-out-list`, {
            contactIds: payload,
        });

        if (response?.data) {
            yield put(setSuccessNotification("Contacts successfully added to the exclusion list."));

            yield put(clearAllSelectedContacts());
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        console.error("***error in fetchContactsSaga", error);
        yield put(setErrorNotification("An error occurred when trying to add contacts to the exclusion list."));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getContactReachoutSaga({ payload }: { payload: string }): SagaIterator {
    try {
        yield put(startAction({ action: getContactReachout.type }));

        const response = yield call(new API().post, `/contact-list/contact-reachout`, { contactId: payload });

        if (!response?.data) return;

        yield put(setContactReachout(response.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getContactReachoutSaga", error);
    } finally {
        yield put(stopAction({ action: getContactReachout.type }));
    }
}

export default function* rootSagas() {
    // @ts-ignore
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchContacts.type, fetchContactsSaga),
        // @ts-ignore
        yield takeLatest(setAllContactsSearchQuery.type, searchContactsSaga),
        // @ts-ignore
        yield takeLatest(allContactsCSVUpload.type, allContactsCSVUploadSaga),
        // @ts-ignore
        yield takeLatest(applyAllContactsFilters.type, applyAllContactsFiltersSaga),
        // @ts-ignore
        yield takeLatest(addContactsToExclusionList.type, addContactsToExclusionListSaga),
        // @ts-ignore
        yield takeLatest(getContactReachout.type, getContactReachoutSaga),
    ];

    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
