import CleanHandsRoundedIcon from "@mui/icons-material/CleanHandsRounded";
import {
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BDProjectMenuItem } from "./bd.types";

import { ButtonTextWithLoading } from "../../components/ButtonTextWithLoading";
import { withConfigModeHOC } from "../../hocs/withConfigMode";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectValuePropositionModal, setModal } from "../../store/reducers/modals.slice";

import { submitValuePropositionInputs } from "@/store/reducers/create-project/CreateProject.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button } from "@/ui";

const AddValuePropositionMenuItem = ({ sx, handleClose }: BDProjectMenuItem) => {
    const dispatch = useAppDispatch();
    const openValuePropositionModal = () => dispatch(setModal({ key: "showValuePropositionModal", value: true }));
    return (
        <MenuItem
            sx={sx}
            onClick={() => {
                handleClose();
                openValuePropositionModal();
            }}
        >
            <ListItemIcon>
                <CleanHandsRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
                sx={{
                    "& .MuiListItemText-primary": {
                        fontSize: "14px",
                    },
                }}
            >
                Add Value Proposition
            </ListItemText>
        </MenuItem>
    );
};

export const BDValuePropositionMenuItem = withConfigModeHOC<{}, BDProjectMenuItem>({
    Eg: AddValuePropositionMenuItem,
});

export type Fields = {
    productName: string;
    productIndustry: string;
    productType: string;
    productDescription: string;
};

export type SubmitValuePropositionPayload = PayloadAction<Fields>;

const typeOptions = ["Product", "Service", "Product & Service"];

const industryOptions = [
    "Internet and Technology",
    "Healthcare",
    "Pharma",
    "Finance and Banking",
    "Manufacturing",
    "Retail",
    "E-commerce",
    "Energy and Utilities",
    "Hospitality and Tourism",
    "Media and Entertainment",
    "Logistics",
    "Other",
];

type CustomSelectProps = {
    value: string;
    handleChange: (value: string) => void;
    options: string[];
    label: string;
};

function CustomSelect({ value, handleChange, options, label }: CustomSelectProps) {
    return (
        <FormControl fullWidth>
            <InputLabel size="small">{label}</InputLabel>
            <Select size="small" value={value} label={label} onChange={(event) => handleChange(event.target.value)}>
                {options.map((i) => (
                    <MenuItem value={i} key={i}>
                        {i}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const initialState: Fields = {
    productName: "",
    productIndustry: "",
    productType: "",
    productDescription: "",
};

function AddValuePropositionModal() {
    const dispatch = useDispatch();
    const showModal = useSelector(selectValuePropositionModal);
    const isSubmitting = useSelector(checkIfLoading(submitValuePropositionInputs.type));
    const [fields, setFields] = useState<Fields>(initialState);

    useEffect(() => {
        return () => {
            setFields(initialState);
        };
    }, []);

    const selectInputValues: CustomSelectProps[] = [
        {
            options: typeOptions,
            value: fields.productType,
            label: "Type",
            handleChange: (value) => setFields((prev) => ({ ...prev, productType: value })),
        },
        {
            options: industryOptions,
            value: fields.productIndustry,
            label: "Industry",
            handleChange: (value) => setFields((prev) => ({ ...prev, productIndustry: value })),
        },
    ];

    const handleModalClose = () => dispatch(setModal({ value: false, key: "showValuePropositionModal" }));

    const handleSubmit = () => {
        if (!fields.productName || !fields.productType || !fields.productIndustry) {
            dispatch(setErrorNotification("Please enter valid inputs..."));
        } else {
            dispatch(submitValuePropositionInputs(fields));
        }
    };

    if (!showModal) {
        return null;
    }

    return (
        <BaseModal onClose={handleModalClose}>
            <Stack gap={2.5}>
                <Typography variant="h6">Value Proposition</Typography>
                <Stack gap={2}>
                    {selectInputValues.map(({ handleChange, label, options, value }) => {
                        return (
                            <CustomSelect handleChange={handleChange} label={label} options={options} value={value} />
                        );
                    })}
                    <TextField
                        size="small"
                        variant="outlined"
                        placeholder="*Product Name"
                        type="text"
                        value={fields.productName}
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) =>
                            setFields((prev) => ({
                                ...prev,
                                productName: e.target.value,
                            }))
                        }
                    />
                    <TextField
                        multiline
                        size="small"
                        variant="outlined"
                        placeholder="*Product Description"
                        type="text"
                        value={fields.productDescription}
                        onChange={(e) =>
                            setFields((prev) => ({
                                ...prev,
                                productDescription: e.target.value,
                            }))
                        }
                    />
                </Stack>
                <Stack justifyContent="flex-end" direction="row" gap={1}>
                    <Button variant="default" onClick={handleModalClose} label={"Cancel"} />

                    <Button variant="default" disabled={isSubmitting} onClick={handleSubmit}>
                        <ButtonTextWithLoading isLoading={isSubmitting} text="Submit" progressSize={16} />
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
}

export const BDAddValuePropositionModal = withConfigModeHOC({
    Eg: AddValuePropositionModal,
});
