import { useEffect, useRef, useState } from "react";
import { Box, Button, CssVarsProvider, Stack, Typography } from "@mui/joy";
import { CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import useLocalStorageState from "use-local-storage-state";

import { checkCalendlyAuthorized } from "@/store/reducers/signin/Signin.reducer";

type Step = {
    title: string;
    content: string;
    id: string;
};

const steps = (isCalendlyAuthorized: boolean | undefined): Step[] => {
    return [
        {
            title: "Outreach Flow",
            content: "Select your outreach elements (email, LinkedIn, SMS)",
            id: "outreach-flow",
        },
        {
            title: "Calendly and personalization",
            content: isCalendlyAuthorized
                ? "Add a Calendly link and tags such as {name} from here"
                : "Add tags such as {name} from here",
            id: "calendly-personalization",
        },
        {
            title: "Outreach Template",
            content: "Modify, preview, and save your template",
            id: "outreach-template",
        },
        {
            title: "Schedule outreach",
            content: "Execute your outreach!",
            id: "schedule-outreach",
        },
    ];
};

export default function Onboarding(): JSX.Element {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const popoverRef = useRef<HTMLDivElement | null>(null);
    const hightlightRef = useRef<HTMLDivElement | null>(null);
    const stepRef = useRef(0);
    stepRef.current = currentStep;
    const isCalendlyAuthorized = useSelector(checkCalendlyAuthorized);
    const [, showTour] = useLocalStorageState("outreach-tour");
    const [, setHideTourModal] = useLocalStorageState("outreach-tour-modal");

    const handleNextStep = () => {
        if (currentStep + 1 === steps(isCalendlyAuthorized).length) handleFinish();
        else {
            let nextStep = currentStep + 1;
            setCurrentStep(nextStep);
        }
    };

    const handlePreviousStep = () => {
        let prevStep = currentStep - 1;
        setCurrentStep(prevStep);
    };

    const getPosition = () => {
        const step = steps(isCalendlyAuthorized)[stepRef.current];
        if (!step || typeof window !== "object") return;

        const triggerElement = document.getElementById(step.id);

        if (triggerElement && popoverRef.current && hightlightRef.current) {
            const triggerElementStyle = window.getComputedStyle(triggerElement);
            const triggerRect = triggerElement.getBoundingClientRect();
            const popoverRefStyle = popoverRef.current.style;

            // Adusting the height , width , position of the highlight component
            hightlightRef.current.style.left = `${triggerRect.left}px`;
            hightlightRef.current.style.top = `${triggerRect.top + window.scrollY}px`;
            hightlightRef.current.style.width = `${triggerRect.width + (currentStep === 1 ? 5 : 0)}px`;
            hightlightRef.current.style.height = `${triggerRect.height}px`;
            hightlightRef.current.style.borderRadius = triggerElementStyle.getPropertyValue("border-radius");

            const popoverWidth = popoverRef.current.offsetWidth;
            const popoverHeight = popoverRef.current.offsetHeight;

            let popoverLeft = 0;
            let popoverTop = 0;

            if (stepRef.current === 0) {
                popoverLeft = triggerRect.left + triggerRect.width + 10;
                popoverTop = triggerRect.bottom / 2 - popoverHeight / 2;
            } else if (stepRef.current === 2) {
                popoverLeft = triggerRect.left - popoverWidth - 10;
                popoverTop = triggerRect.bottom / 2 - popoverHeight / 2;
            } else {
                popoverLeft = triggerRect.left + triggerRect.width / 2 - popoverWidth / 2;
                popoverTop = triggerRect.bottom + window.scrollY + 5;
                const isOverflowing = popoverLeft < 0 || popoverLeft + popoverWidth > window.innerWidth;
                // If overflowing , then manually setting the positions of the popover
                if (isOverflowing) {
                    if (popoverLeft + popoverWidth > window.innerWidth) {
                        if (triggerRect.right - popoverWidth > 0) {
                            popoverLeft = triggerRect.left - popoverWidth + triggerRect.width;
                        } else {
                            popoverLeft = window.innerWidth / 2 - popoverWidth / 2;
                        }
                    }

                    if (triggerRect.right - popoverWidth < 0) {
                        if (triggerRect.left + popoverWidth < window.innerWidth) {
                            popoverLeft = triggerRect.left;
                        } else {
                            popoverLeft = window.innerWidth / 2 - popoverWidth / 2;
                        }
                    }
                }
            }

            // positioning the popover
            popoverRefStyle.left = `${popoverLeft}px`;
            popoverRefStyle.top = `${popoverTop}px`;

            // scrolling to appropriate position
            window.scrollTo({
                top: popoverTop - window.innerHeight / 2,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        // Update the popover placement on resize
        window.addEventListener("resize", getPosition);
        return () => window.removeEventListener("resize", getPosition);
    }, []);

    useEffect(() => {
        getPosition();
    }, [currentStep]);

    const handleFinish = () => {
        showTour(false);
        setHideTourModal(true);
        setCurrentStep(-1);
    };
    if (currentStep === -1 || !steps(isCalendlyAuthorized)[currentStep]) return <></>;

    return (
        <>
            <CssVarsProvider>
                <CssBaseline />
                <Box
                    sx={{
                        position: "absolute",
                        width: "100vw",
                        height: "100%",
                        zIndex: 10000000,
                        background: "rgba(0,0,0,0.5)",
                        mixBlendMode: "hard-light",
                        overflow: "hidden",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            background: "gray",
                        }}
                        ref={hightlightRef}
                    ></Box>
                </Box>
                <Box
                    sx={{
                        width: "100vw",
                        height: "100%",
                        position: "absolute",
                        zIndex: 10000000000000,
                    }}
                >
                    <Box
                        sx={{
                            width: "fit-content",
                            position: "relative",
                            background: "white",
                            borderRadius: "8px",
                        }}
                        ref={popoverRef}
                    >
                        <Box sx={{ maxWidth: "90vw", width: "300px", padding: 2 }}>
                            <Stack alignItems={"start"}>
                                <Typography sx={{ width: "full", fontSize: "1rem", fontWeight: 500 }}>
                                    {steps(isCalendlyAuthorized)[currentStep].title}
                                    <span
                                        style={{
                                            fontSize: "0.9rem",
                                            fontWeight: "normal",
                                            fontStyle: "italic",
                                            paddingLeft: "0.5rem",
                                        }}
                                    >
                                        ({currentStep + 1}/{steps(isCalendlyAuthorized).length})
                                    </span>
                                </Typography>
                                <Typography sx={{ paddingTop: "0.5rem" }}>
                                    {steps(isCalendlyAuthorized)[currentStep].content}
                                </Typography>
                                <Stack
                                    marginTop={2}
                                    alignSelf={"flex-end"}
                                    gap={0.5}
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "full" }}
                                >
                                    {currentStep > 0 && (
                                        <Button
                                            size="sm"
                                            sx={{
                                                "&:hover": {
                                                    textDecoration: "undeline",
                                                },
                                            }}
                                            variant="plain"
                                            onClick={() => handlePreviousStep()}
                                        >
                                            Back
                                        </Button>
                                    )}
                                    <Button size="sm" onClick={() => handleNextStep()}>
                                        {currentStep + 1 === steps(isCalendlyAuthorized).length ? "Finish" : "Next"}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </CssVarsProvider>
        </>
    );
}
