import React from "react";

import { cn } from "@/lib/utils";

type Variant =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "overline";
type Align = "inherit" | "left" | "center" | "right" | "justify";
type Color = "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";

interface TypographyProps {
    children: React.ReactNode;
    variant?: Variant;
    component?: React.ElementType;
    align?: Align;
    color?: Color;
    noWrap?: boolean;
    gutterBottom?: boolean;
    paragraph?: boolean;
    className?: string;
}

const variantClasses: Record<Variant, string> = {
    h1: "text-6xl font-light",
    h2: "text-5xl font-light",
    h3: "text-4xl font-normal",
    h4: "text-3xl font-normal",
    h5: "text-2xl font-normal",
    h6: "text-xl font-medium",
    subtitle1: "text-base font-normal",
    subtitle2: "text-sm font-medium",
    body1: "text-base font-normal",
    body2: "text-sm font-normal",
    button: "text-sm font-medium uppercase",
    caption: "text-xs font-normal",
    overline: "text-xs font-normal uppercase",
};

const alignClasses: Record<Align, string> = {
    inherit: "",
    left: "text-left",
    center: "text-center",
    right: "text-right",
    justify: "text-justify",
};

const colorClasses: Record<Color, string> = {
    initial: "",
    inherit: "text-inherit",
    primary: "text-cyan-600",
    secondary: "text-purple-600",
    textPrimary: "text-gray-900",
    textSecondary: "text-gray-600",
    error: "text-red-600",
};

export const Typography: React.FC<TypographyProps> = ({
    children,
    variant = "body1",
    component,
    align = "inherit",
    color = "initial",
    noWrap = false,
    gutterBottom = false,
    paragraph = false,
    className,
    ...props
}) => {
    const Component = component || (paragraph ? "p" : variantMapping[variant] || "span");

    const classes = cn(
        variantClasses[variant],
        alignClasses[align],
        color !== "initial" && colorClasses[color],
        noWrap && "whitespace-nowrap overflow-hidden text-ellipsis",
        gutterBottom && "mb-4",
        paragraph && "mb-4",
        className
    );

    return (
        <Component className={classes} {...props}>
            {children}
        </Component>
    );
};

const variantMapping: Record<Variant, React.ElementType> = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    subtitle1: "h6",
    subtitle2: "h6",
    body1: "p",
    body2: "p",
    button: "span",
    caption: "span",
    overline: "span",
};

export default Typography;
