import { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDropzone, FileWithPath } from "react-dropzone";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { addFiles } from "../../../store/reducers/workflow-upload.slice";
import documents from "../../../assets/img/documents.svg";
import FileTile from "./FileTile";

const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#0891B2",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

export default function UploadSection({ close }: { close: () => void }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const projectId = location.state?.projectId;
    const [myFiles, setMyFiles] = useState<FileWithPath[]>([]);
    const isFilesUploading = useSelector(checkIfLoading(addFiles.type));

    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        setMyFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const removeFile = (file: FileWithPath) => () => {
        setMyFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            "application/zip": [".zip"],
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        },
        multiple: true,
        maxFiles: 20,
        maxSize: 10000000,
        onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const files = myFiles.map((file) => {
        return <FileTile key={file.size} name={file.name} handleRemove={removeFile(file)} />;
    });

    const handleSubmit = () => {
        if (myFiles.length) {
            dispatch(addFiles({ files: myFiles, projectId, close }));
        }
    };

    return (
        <Stack spacing={2}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Stack alignItems="center" sx={{ position: "relative" }}>
                    <Box component="img" src={documents} alt="" sx={{ maxHeight: "200px", maxWidth: "200px" }} />
                    <Typography variant="h4" align="center" sx={{ fontSize: "18px" }}>
                        Drop your pdf, docs here, or{" "}
                        <Typography
                            component="button"
                            variant="h4"
                            sx={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                fontWeight: "600",
                                fontSize: "16px",
                            }}
                        >
                            Browse
                        </Typography>
                    </Typography>
                    <Typography variant="body1" align="center">
                        Supports: .pdf, .docx, .doc, .zip
                    </Typography>
                </Stack>
            </div>
            <Stack spacing={1.5}>
                {files}
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        height: "fit-content",
                        width: "fit-content",
                        alignSelf: "center",
                    }}
                >
                    <ButtonTextWithLoading text="Submit" isLoading={isFilesUploading} />
                </Button>
            </Stack>
        </Stack>
    );
}
