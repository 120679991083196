import { CheckboxProps, FormControlLabel, Checkbox as MUICheckbox } from "@mui/material";
import { styled } from "@mui/material/styles";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
    },
    "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
        ...theme.applyStyles("dark", {
            backgroundColor: "#30404d",
        }),
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
        ...theme.applyStyles("dark", {
            background: "rgba(57,75,89,.5)",
        }),
    },
    ...theme.applyStyles("dark", {
        boxShadow: "0 0 0 1px rgb(16 22 26 / 40%)",
        backgroundColor: "#394b59",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    }),
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#0097A7", // Cyan 600
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "#0097A7", // Cyan 700 (hover effect)
    },
});

function CustomCheckbox(props: CheckboxProps) {
    return (
        <MUICheckbox
            sx={{ "&:hover": { bgcolor: "transparent" } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
        />
    );
}

export default function Checkbox(props: CheckboxProps & { children?: React.ReactNode }) {
    if (props?.children) {
        return <FormControlLabel control={<CustomCheckbox {...props} />} label={props.children} />;
    }

    return <CustomCheckbox {...props} />;
}
