import { Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { getCookieInputValues } from "../../../store/reducers/cookiesInput.slice";
import { selectLiCookie } from "@/store/reducers/signin/Signin.reducer";
import { selectEnableConnectionReq, selectEnableInMailSwitch } from "@/store/reducers/template/template.reducer";

export function AuthorizationWarningDescription() {
    const customCookies = useSelector(getCookieInputValues);
    const isLiCookieAvailable = useSelector(selectLiCookie);
    const isCustomCookiesAvailable = Object.keys(customCookies).every((key) => customCookies[key].cookie);
    const isConnectionRequestEnable = useSelector(selectEnableConnectionReq);
    const isInMailEnable = useSelector(selectEnableInMailSwitch);
    const shouldShowWarning =
        (isConnectionRequestEnable || isInMailEnable) && !isCustomCookiesAvailable && !isLiCookieAvailable;

    if (!shouldShowWarning) {
        return null;
    }

    return (
        <>
            <Typography variant="body2" fontStyle="italic" color="red">
                Your linkedin is not authenticated, so linkedin outreach will be disabled for this workflow.
            </Typography>
            <Divider />
        </>
    );
}
