import { zodResolver } from "@hookform/resolvers/zod";
import CardOverflow from "@mui/joy/CardOverflow";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
// import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";

import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { useAppDispatch } from "../../../../store";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "../../../Signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import styles from "../../myAccount.module.scss";

import { Button, Card, CardActions, Input, Typography } from "@/ui";

const profileFormSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    userEmail: z.string(),
    outboundEmail: z.string(),
    workspaceName: z.string().trim().min(1, { message: "Please enter workspace name." }),
    companyName: z.string(),
    companyUrl: z.string(),
    role: z.union([z.literal(1), z.literal(0)]).optional(),
    linkedinUrl: z.string(),
    signature: z.string().optional(),
});

type ProfileForm = z.infer<typeof profileFormSchema>;

export function EditProfile() {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [exitConfirmation, setExitConfirmation] = useState<boolean>(false);
    const user = useSelector(selectUser);
    const isLoading = useSelector(checkIfLoading(updateUser.type));
    const dispatch = useAppDispatch();

    const { control, handleSubmit, reset } = useForm<ProfileForm>({
        resolver: zodResolver(profileFormSchema),
        values: {
            name: user.name ?? "",
            userEmail: user.email ?? "",
            outboundEmail: user.authorizedEmailAddress ?? "",
            workspaceName: user.orgData?.name ?? "",
            companyName: user.companyName ?? "",
            companyUrl: user.companyLink ?? "",
            role: user?.roleType === "ADMIN" ? 0 : 1,
            linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
            signature: user?.signature ?? "",
        },
    });

    const changeMode = () => setIsEditMode(false);
    const onSubmit: SubmitHandler<ProfileForm> = (data) => {
        const { companyName, companyUrl, linkedinUrl, name, outboundEmail, userEmail, workspaceName, role, signature } =
            data;
        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                roleType: role === 0 ? "ADMIN" : "MEMBER",
                action: updateUser.type,
                signature,
                onSuccess: changeMode,
            })
        );
    };

    return (
        <>
            <JoyProvider>
                <Card variant="outlined" className="p-2 bg-[#fbfcfe]">
                    <Stack
                        direction="row"
                        display={"grid"}
                        gridTemplateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
                        spacing={1.5}
                        sx={{
                            maxHeight: "calc(100vh - 250px)",
                            overflow: "scroll",
                        }}
                    >
                        <Stack>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Name</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled={!isEditMode}
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="userEmail"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>User Email</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="outboundEmail"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Outbound Email</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="workspaceName"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Workspace Name</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled={!isEditMode}
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="companyName"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Company Name</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled={!isEditMode}
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="companyUrl"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Company URL</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled={!isEditMode}
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="linkedinUrl"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Linkedin URL</FormLabel>
                                            <Input
                                                fullWidth
                                                style={{
                                                    padding: 4,
                                                    fontSize: "14px",
                                                    border: "1px solid lightgrey",
                                                    color: "#575457",
                                                }}
                                                disabled
                                                {...field}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                        </Stack>
                        <Stack>
                            <Controller
                                control={control}
                                name="role"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& .css-p41fjr-JoySelect-root.Mui-disabled": {
                                                    color: "#b4b4b6",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>User Role</FormLabel>
                                            <Select
                                                placeholder="Select a role"
                                                sx={{ minWidth: 200 }}
                                                {...field}
                                                disabled={!isEditMode}
                                            >
                                                <Option value={0} sx={{ color: "#575457" }}>
                                                    Admin
                                                </Option>
                                                <Option value={1} sx={{ color: "#575457" }}>
                                                    Member
                                                </Option>
                                            </Select>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="signature"
                                render={({ field, fieldState: { error } }) => {
                                    const { value, onChange } = field;
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Signature</FormLabel>
                                            <div>
                                                <RichTextEditor
                                                    enableImageModule
                                                    onChange={(value) => onChange(value)}
                                                    value={value?.replaceAll("\n", "<br>")}
                                                    className={isEditMode ? styles.signatureEditor : styles.disabled}
                                                    readOnly={!isEditMode}
                                                />
                                            </div>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                        </Stack>
                    </Stack>
                    <CardOverflow
                        sx={{
                            borderTop: "1px solid",
                            borderColor: "divider",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <CardActions>
                            {isEditMode && (
                                <>
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        color="neutral"
                                        onClick={() => {
                                            setExitConfirmation(true);
                                        }}
                                        className="mr-2"
                                        label="Cancel"
                                    />

                                    <Button
                                        size="sm"
                                        variant="default"
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                        label={isLoading ? "Saving..." : "Save"}
                                    />
                                </>
                            )}
                            {!isEditMode && (
                                <Button
                                    size="sm"
                                    variant="default"
                                    type="submit"
                                    onClick={() => {
                                        setIsEditMode(true);
                                    }}
                                    label={"Edit"}
                                />
                            )}
                        </CardActions>
                    </CardOverflow>
                </Card>
            </JoyProvider>
            {exitConfirmation && (
                <MaterialProvider>
                    <BaseModal
                        onClose={() => setExitConfirmation(false)}
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 550,
                        }}
                        hideCloseButton={false}
                    >
                        <Typography className="text-xl font-semibold mb-2">Going somewhere else?</Typography>

                        <Typography className="text-sm">You will loose any unsaved changes to your message.</Typography>

                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                            <Button
                                variant="outline"
                                className="mr-2"
                                onClick={() => {
                                    setExitConfirmation(false);
                                }}
                                label="Keep editing"
                            />
                            <Button
                                variant="default"
                                onClick={() => {
                                    reset(), setIsEditMode(false), setExitConfirmation(false);
                                }}
                                label="Continue"
                            />
                        </Stack>
                    </BaseModal>
                </MaterialProvider>
            )}
        </>
    );
}
