import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { getContractList, leavePage, setContractList, updateContractList } from "../reducers/contract-tracking/contractTracking.reducers";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { setErrorNotification, setSuccessNotification } from "../reducers/notification/notification.reducer";

import API from "@/utils/API";
import { CancelSagas } from "@/utils/saga.utils";
import handleException from "@/utils/sentry";

function* getContractListSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, `/super-admin/org-contract`);
        if (!response?.data) return;

        const contracts = response.data;
        yield put(setContractList(contracts));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateContractListSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `super-admin/org-contract`, action.payload);

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
            yield put(
                getContractList({
                    action: getContractList.type,
                })
            );
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getContractList.type, getContractListSaga),
        // @ts-ignore
        yield takeLatest(updateContractList.type, updateContractListSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
