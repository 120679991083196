import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty, isUndefined } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import API from "../../../utils/API";
import appendToOutReach from "../../../utils/appendToOutReach";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import { startAction, stopAction } from "../../reducers/loaders.reducer";

import { IProjectStage } from "@/pages/project/project.types";
import {
    Candidate,
    CandidateNameResponse,
    HyperPersonalizedTemplateResponse,
    SendUpdatedTemplatesPayload,
    SendUpdatedTemplatesResponse,
    SetCandidateIndexPayload,
    SubmitPersonalizedInputsPayload,
} from "@/pages/triggerWorkflow/types";
import {
    cancelActions,
    fetchCandidateNames,
    fetchCandidateWithUpdatedOutReachContent,
    fetchSingleCandidateTemplates,
    sendUpdatedTemplates,
    setCandidateIndex,
    setCandidates,
    setCandidatesNamesFetchError,
    setFetchTemplateStatus,
    setTemplates,
    setUpdatedTemplates,
    submitPersonalizedInputs,
} from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import { changeModalState } from "@/store/reducers/workflow/workflow.reducer";

function* fetchCandidateNamesSaga(action: PayloadAction<SubmitPersonalizedInputsPayload>) {
    try {
        yield put(setCandidatesNamesFetchError(false));
        yield put(startAction({ action: action.type }));
        const {
            candidateIDs,
            projectID,
            isAllCandidatesSelected: selectAll,
            filters,
            searchQuery,
            showByPersonalEmails,
        } = action.payload;
        const hyperPersonalizedPayload: {
            candidateIds: string[];
            dummy?: boolean;
            selectAll?: boolean;
            projectId: number;
            filterBy?: string[];
            search?: string;
        } = {
            candidateIds: candidateIDs,
            projectId: Number(projectID),
        };

        if (selectAll) {
            hyperPersonalizedPayload.selectAll = true;
        }

        if (projectID === "9999") {
            hyperPersonalizedPayload.dummy = true;
        }

        // filters
        let filterByPayload: string[] = [];
        if (filters) {
            const appliedFilters = Object.keys(filters)
                .filter((key) => filters[key as IProjectStage])
                .concat(showByPersonalEmails ? ["personalEmail"] : []);
            filterByPayload = [...appliedFilters];
            hyperPersonalizedPayload.filterBy = filterByPayload;
        }

        // search
        if (searchQuery) {
            hyperPersonalizedPayload.search = searchQuery;
        }

        const candidateNamesResponse: CandidateNameResponse = yield call(
            new API().post,
            "/hyper-personalization/candidate-name",
            hyperPersonalizedPayload
        );

        if (isEmpty(candidateNamesResponse?.candidates)) {
            throw new Error("error while fetching names");
        } else {
            const candidates: Candidate[] = candidateNamesResponse.candidates.map(({ _id, name }) => ({
                id: _id,
                name,
                fetchTemplateStatus: "IDLE",
                isTemplatesUpdated: false,
            }));

            yield put(setCandidates(candidates));
        }
    } catch (error) {
        handleException(error);
        console.error("*error while fetching names*", { error });
        yield put(setCandidatesNamesFetchError(true));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitPersonalizedInputsSaga(action: PayloadAction<SubmitPersonalizedInputsPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const {
            candidateIDs,
            projectID,
            fetchNamesAction,
            isAllCandidatesSelected: selectAll,
            filters,
            searchQuery,
            showByPersonalEmails,
        } = action.payload;

        const state = yield select();
        const characterCount = get(state, "personalizedWorkflow.characterCount");
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");

        let outreachIntent = get(state, "personalizedWorkflow.outreachIntent");

        const customTemplates = get(state, "customTemplates");

        outreachIntent = appendToOutReach({
            email: customTemplates?.email,
            followUp: customTemplates?.followUp?.body,
            inMail: customTemplates?.inMail,
            linkedInReq: customTemplates?.linkedInReq?.body,
            sms: isSMSEnabledForUser ? customTemplates?.sms?.body : null,
            outReachIntent: outreachIntent,
        });

        if (!fetchNamesAction) {
            throw new Error("action not provided");
        }

        yield put(startAction({ action: fetchNamesAction }));

        const hyperPersonalizedPayload: {
            candidateIds: string[];
            dummy?: boolean;
            selectAll?: boolean;
            projectId: number;
            filterBy?: string[];
            search?: string;
        } = {
            candidateIds: candidateIDs,
            projectId: Number(projectID),
        };

        if (selectAll) {
            hyperPersonalizedPayload.selectAll = true;
        }

        if (projectID === "9999") {
            hyperPersonalizedPayload.dummy = true;
        }

        // filters
        let filterByPayload: string[] = [];
        if (filters) {
            const appliedFilters = Object.keys(filters)
                .filter((key) => filters[key as IProjectStage])
                .concat(showByPersonalEmails ? ["personalEmail"] : []);
            filterByPayload = [...appliedFilters];
            hyperPersonalizedPayload.filterBy = filterByPayload;
        }

        // search
        if (searchQuery) {
            hyperPersonalizedPayload.search = searchQuery;
        }

        const candidateNamesResponse: CandidateNameResponse = yield call(
            new API().post,
            "/hyper-personalization/candidate-name",
            hyperPersonalizedPayload
        );

        yield put(stopAction({ action: fetchNamesAction }));

        // INFO: in this case checking for empty candidates and throwing is right because, it's never the case when
        // user is going to do empty outreach(outreach without any candidates)
        if (isEmpty(candidateNamesResponse?.candidates)) {
            throw new Error("error while fetching names");
        }

        const candidates: Candidate[] = candidateNamesResponse.candidates.map(({ _id, name }) => ({
            id: _id,
            name,
            fetchTemplateStatus: "IDLE",
            isTemplatesUpdated: false,
        }));

        yield put(setCandidates(candidates));

        // INFO: in this case checking for empty candidates and throwing is right because, it's never the case when
        // user is going to do empty outreach(outreach without any candidates)
        if (isEmpty(candidates)) {
            throw new Error("no candidates found");
        }

        const candidatesIdsInOrder = candidates.map(({ id }) => id);

        yield put(changeModalState("DEFAULT"));

        let personalizationPayload = {};
        if (projectID === "9999") {
            personalizationPayload = {
                dummy: true,
                candidateIds: candidatesIdsInOrder.slice(0, 3),
            };
        } else {
            personalizationPayload = {
                projectId: projectID,
                candidateIds: candidatesIdsInOrder.slice(0, 1),
                toneOfVoice,
                characterCount,
                outreachIntent,
            };
        }

        const personalizationResponse: HyperPersonalizedTemplateResponse = yield call(
            new API().post,
            "/hyper-personalization/send",
            personalizationPayload
        );
        if (isEmpty(personalizationResponse?.templates)) return;

        yield put(setTemplates(personalizationResponse.templates));
        // yield put(setEditTemplatesModal(true));
    } catch (error) {
        console.error({ error });
        handleException(error);
        yield put(setErrorNotification("error while fetching personalized templates"));
    } finally {
        yield put(stopAction({ action: action.type }));
        if (action.payload.fetchNamesAction) {
            yield put(stopAction({ action: action.payload.fetchNamesAction }));
        }
    }
}

function* sendUpdatedTemplatesSaga(action: PayloadAction<SendUpdatedTemplatesPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const { projectId } = action.payload;

        const state = yield select();
        let updatedCandidate = get(state, "personalizedWorkflow.candidate");
        const characterCount = get(state, "personalizedWorkflow.characterCount");
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");
        let outreachIntent = get(state, "personalizedWorkflow.outreachIntent");
        const customTemplates = get(state, "customTemplates");
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");

        if (!isSMSEnabledForUser && "sms" in updatedCandidate) {
            const { sms, ...rest } = updatedCandidate;
            updatedCandidate = rest;
        }

        outreachIntent = appendToOutReach({
            email: customTemplates?.email,
            followUp: customTemplates?.followUp?.body,
            inMail: customTemplates?.inMail,
            linkedInReq: customTemplates?.linkedInReq?.body,
            outReachIntent: outreachIntent,
            sms: isSMSEnabledForUser ? customTemplates?.sms?.body : null,
        });

        const response: SendUpdatedTemplatesResponse = yield call(new API().post, "/hyper-personalization/edit", {
            projectId,
            candidateIds: [updatedCandidate.id],
            connReq: updatedCandidate.connectionReq,
            toneOfVoice,
            characterCount,
            outreachIntent,
            ...updatedCandidate,
        });
        if (isEmpty(response?.templates)) return;

        yield put(setUpdatedTemplates(response.templates));
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error({ error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchSingleCandidateTemplatesSaga(action: PayloadAction<SetCandidateIndexPayload>): SagaIterator {
    try {
        const { projectId, id: candidateId, action: loadingAction } = action.payload;
        const state = yield select();
        const characterCount = get(state, "personalizedWorkflow.characterCount");
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");
        const candidates: Candidate[] = get(state, "personalizedWorkflow.candidates");
        let outreachIntent = get(state, "personalizedWorkflow.outreachIntent");
        const customTemplates = get(state, "customTemplates");
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");

        outreachIntent = appendToOutReach({
            email: customTemplates?.email,
            followUp: customTemplates?.followUp?.body,
            inMail: customTemplates?.inMail,
            linkedInReq: customTemplates?.linkedInReq?.body,
            outReachIntent: outreachIntent,
            sms: isSMSEnabledForUser ? customTemplates?.sms?.body : null,
        });

        const isCandidateTemplatesExists = candidates.find(({ id }) => id === candidateId);

        if (
            isUndefined(isCandidateTemplatesExists?.connectionReq) ||
            isUndefined(isCandidateTemplatesExists?.email) ||
            isUndefined(isCandidateTemplatesExists?.inMail)
        ) {
            yield put(startAction({ action: loadingAction }));
            yield put(setFetchTemplateStatus({ candidateId, status: "LOADING" }));

            const personalizationResponse: HyperPersonalizedTemplateResponse = yield call(
                new API().post,
                "/hyper-personalization/send",
                {
                    projectId,
                    candidateIds: [candidateId],
                    toneOfVoice,
                    characterCount,
                    outreachIntent,
                }
            );

            if (isEmpty(personalizationResponse?.templates)) {
                throw new Error("Personalization templates are empty");
            }
            yield put(setTemplates(personalizationResponse.templates));
            yield put(setFetchTemplateStatus({ candidateId, status: "SUCCESS" }));
        }
    } catch (error) {
        console.error({ error });
        handleException(error);
        yield put(setErrorNotification("error while fetching personalized templates"));
        yield put(
            setFetchTemplateStatus({
                candidateId: action.payload.id,
                status: "ERROR",
            })
        );
    } finally {
        yield put(stopAction({ action: action.payload.action }));
    }
}

function* fetchCandidateWithUpdatedOutReachContentSaga(action: PayloadAction<SetCandidateIndexPayload>): SagaIterator {
    try {
        const { projectId, id: candidateId, action: loadingAction } = action.payload;
        const state = yield select();
        const characterCount = get(state, "personalizedWorkflow.characterCount");
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");
        let outreachIntent = get(state, "personalizedWorkflow.outreachIntent");
        const customTemplates = get(state, "customTemplates");
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");

        outreachIntent = appendToOutReach({
            email: customTemplates?.email,
            followUp: customTemplates?.followUp?.body,
            inMail: customTemplates?.inMail,
            linkedInReq: customTemplates?.linkedInReq?.body,
            outReachIntent: outreachIntent,
            sms: isSMSEnabledForUser ? customTemplates?.sms?.body : null,
        });
        yield put(startAction({ action: loadingAction }));
        yield put(setFetchTemplateStatus({ candidateId, status: "LOADING" }));

        const personalizationResponse: HyperPersonalizedTemplateResponse = yield call(
            new API().post,
            "/hyper-personalization/send",
            {
                projectId,
                candidateIds: [candidateId],
                toneOfVoice,
                characterCount,
                outreachIntent,
            }
        );
        if (isEmpty(personalizationResponse?.templates)) {
            throw new Error("Personalization templates are empty");
        }
        yield put(setTemplates(personalizationResponse.templates));
        yield put(setFetchTemplateStatus({ candidateId, status: "SUCCESS" }));
    } catch (error) {
        console.error({ error });
        handleException(error);
        yield put(setErrorNotification("error while fetching personalized templates"));
        yield put(
            setFetchTemplateStatus({
                candidateId: action.payload.id,
                status: "ERROR",
            })
        );
    } finally {
        yield put(stopAction({ action: action.payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchCandidateWithUpdatedOutReachContent.type, fetchCandidateWithUpdatedOutReachContentSaga),
        // @ts-ignore
        yield takeLatest(submitPersonalizedInputs.type, submitPersonalizedInputsSaga),
        // @ts-ignore
        yield takeLatest(sendUpdatedTemplates.type, sendUpdatedTemplatesSaga),
        // @ts-ignore
        yield takeEvery(setCandidateIndex.type, fetchSingleCandidateTemplatesSaga),
        // @ts-ignore
        yield takeEvery(fetchSingleCandidateTemplates.type, fetchSingleCandidateTemplatesSaga),
        // @ts-ignore
        yield takeEvery(fetchCandidateNames.type, fetchCandidateNamesSaga),
    ];
    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
