import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { fetchBaseQueryWithZod } from "../utils";

enum EQueryKeys {
    CS_MEMBERS = "cs_members",
    FETCH_PROJECT_LIST = "fetch_project_list",
    FETCH_CS_MEMBER = "fetch_cs_member",
    FETCH_CLIENT_NAME = "fetch_client_name",
}

export const notificationApi = createApi({
    reducerPath: "notificationApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [
        EQueryKeys.CS_MEMBERS,
        EQueryKeys.FETCH_PROJECT_LIST,
        EQueryKeys.FETCH_CS_MEMBER,
        EQueryKeys.FETCH_CLIENT_NAME,
    ],
    endpoints: (builder) => ({
        getCSTemplates: builder.mutation<any, { projectIds: number[]; type: string }>({
            query: (payload) => ({
                url: `notification/fetch-email-notification-template`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        getEmailActivityLogs: builder.mutation<any, any>({
            query: (payload) => ({
                url: `notification/get-email-activity-logs`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        sendCSMail: builder.mutation<
            any,
            {
                fromEmail: string;
                toEmail: string;
                emailBody: string;
                subject: string;
                toUserId: number;
                projectId: number[];
                orgId: number;
                type: string;
                scheduledFor: string;
                attachments: string[];
            }
        >({
            query: (payload) => ({
                url: `notification/send-email-notification`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        getCSMembers: builder.query<any, any>({
            query: (_filters) => ({
                url: `/v2/project/cs-users`,
                method: "GET",
            }),
            keepUnusedDataFor: 2,
            transformResponse(baseQueryReturnValue: any) {
                return baseQueryReturnValue.data;
            },
            providesTags: [EQueryKeys.CS_MEMBERS, EQueryKeys.FETCH_CS_MEMBER],
        }),
        fetchNotificationList: builder.query<any, { primaryTaggedUsers: number[] }>({
            query: (payload) => {
                const { primaryTaggedUsers } = payload;
                const filteredUsers = primaryTaggedUsers.filter((userId) => userId !== 0);
                return {
                    url: `/v2/project/user-tagged-to-primary-user`,
                    method: "POST",
                    body: {
                        primaryTaggedUsers: filteredUsers,
                    },
                };
            },
            providesTags: [EQueryKeys.FETCH_PROJECT_LIST],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        fetchProjectList: builder.query<any, { userId: number }>({
            query: (payload) => ({
                url: `/v2/project/project-tagged-to-user`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        addNotificationList: builder.mutation<any, { userId: number[]; projectId: number[]; notificationList: any[] }>({
            query: (payload) => ({
                url: `notification/add-notifiction-list`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
            invalidatesTags: [EQueryKeys.FETCH_PROJECT_LIST],
        }),
        createNotification: builder.mutation<any, any>({
            query: (payload) => ({
                url: `notification/create-automated-email`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: [EQueryKeys.FETCH_PROJECT_LIST],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        saveMember: builder.mutation<any, any>({
            query: (payload) => ({
                url: `super-admin/cs-member`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: [EQueryKeys.FETCH_CS_MEMBER],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        csTagging: builder.mutation<any, any>({
            query: (payload) => ({
                url: `super-admin/client-cs-tagging`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        updateNotification: builder.mutation<any, any>({
            query: (payload) => ({
                url: `notification/update-automated-email`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: [EQueryKeys.FETCH_PROJECT_LIST],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        stopNotification: builder.mutation<any, any>({
            query: (payload) => ({
                url: `notification/cancel-automated-email`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: [EQueryKeys.FETCH_PROJECT_LIST],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        fetchClientName: builder.query<any, any>({
            query: (filters) => {
                return {
                    url: `super-admin/user?${queryString.stringify(filters)}`,
                    method: "GET",
                };
            },
            providesTags: [EQueryKeys.FETCH_CLIENT_NAME],
            keepUnusedDataFor: 2,
            transformResponse(baseQueryReturnValue: any) {
                return baseQueryReturnValue.data;
            },
        }),
    }),
});

export const {
    useGetCSTemplatesMutation,
    useSendCSMailMutation,
    useGetEmailActivityLogsMutation,
    useAddNotificationListMutation,
    useFetchProjectListQuery,
    useFetchNotificationListQuery,
    useGetCSMembersQuery,
    useCreateNotificationMutation,
    useUpdateNotificationMutation,
    useStopNotificationMutation,
    useFetchClientNameQuery,
    useSaveMemberMutation,
    useCsTaggingMutation,
} = notificationApi;
