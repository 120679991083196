import { createSlice } from "@reduxjs/toolkit";

import { notificationType } from "./notification.types";

import { RootState } from "../../store";

const initialState: notificationType = {
    success: "",
    error: "",
    warning: "",
};

const reducers = {
    setSuccessNotification(state: typeof initialState, { payload }: { payload: string }) {
        state.success = payload;
        state.warning = "";
        state.error = "";
    },
    setErrorNotification(state: typeof initialState, { payload }: { payload: string }) {
        state.success = "";
        state.warning = "";
        state.error = payload;
    },
    setWarningNotification(state: typeof initialState, { payload }: { payload: string }) {
        state.success = "";
        state.error = "";
        state.warning = payload;
    },
    leaveRoutes(state: typeof initialState) {},
};

export const reducersPage = createSlice({
    name: "notification",
    initialState,
    reducers,
});

export default reducersPage.reducer;

export const { setSuccessNotification, setErrorNotification, setWarningNotification, leaveRoutes } =
    reducersPage.actions;

export const selectSuccessNotification = (state: RootState) => state.notification.success;
export const selectErrorNotification = (state: RootState) => state.notification.error;
export const selectWarningNotification = (state: RootState) => state.notification.warning;
