import {
    Alert,
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addToLever,
    getFromLever,
    getLeverJobs,
    getLeverStages,
    selectLeverJobs,
    selectLeverStages,
    setLeverExportCandidates,
} from "../../../integrations/integrations.reducer";
import { ILeverOpportunity, ILeverOpportunityType, ILeverStageType } from "../../../integrations/integrations.types";

import { Button } from "@/ui";

type LeverModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

const headers = [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "linkedInProfileUrl", key: "links" },
    { label: "emails", key: "emails" },
    { label: "resume", key: "resume" },
];

export default function LeverModal({ open, onClose, id, selectedCandidateIds, variant }: LeverModalProps) {
    const dispatch = useDispatch();
    const leverJobs = useSelector(selectLeverJobs);
    const leverStages = useSelector(selectLeverStages);
    const isLeverJobsLoading = useSelector(checkIfLoading(getLeverJobs.type));
    const isLeverStagesLoading = useSelector(checkIfLoading(getLeverStages.type));
    const isLeverSubmitting = useSelector(checkIfLoading(addToLever.type));
    const isLeverFetching = useSelector(checkIfLoading(getFromLever.type));
    const [dateError, setDateError] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(setLeverExportCandidates([]));
        };
    }, []);

    const [opportunityType, setOpportunityType] = useState<ILeverOpportunityType>({
        type: "GENERAL",
        jobId: "",
    });
    const [stageType, setStageType] = useState<ILeverStageType>({
        open: false,
        stageId: "",
    });
    const [createdAtStart, setCreatedAtStart] = useState<Dayjs | null>(null);
    const [createdAtEnd, setCreatedAtEnd] = useState<Dayjs | null>(null);

    const isVariantFetch = variant === "FETCH";

    useEffect(() => {
        if (isVariantFetch && open) {
            setOpportunityType((prev) => ({ ...prev, type: "SPECIFIC" }));
            dispatch(
                getLeverJobs({
                    action: getLeverJobs.type,
                })
            );
        }
    }, [variant, open]);

    const handleCloseModal = () => {
        setOpportunityType({
            type: "GENERAL",
            jobId: "",
        });
        setStageType({
            open: false,
            stageId: "",
        });
        onClose();
    };

    const handleOpportunityChange = (value: ILeverOpportunity) => {
        if (value === "SPECIFIC") {
            dispatch(
                getLeverJobs({
                    action: getLeverJobs.type,
                })
            );
        }
        setOpportunityType({
            ...opportunityType,
            type: value as ILeverOpportunity,
        });
    };

    const handleStageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            dispatch(
                getLeverStages({
                    action: getLeverStages.type,
                })
            );
        }
        setStageType({
            ...stageType,
            open: e.target.checked,
        });
    };

    const handleLeverAdd = () => {
        if (opportunityType.type === "SPECIFIC" && !opportunityType.jobId) {
            dispatch(setErrorNotification("Please select job"));
            return;
        }

        if (stageType.open && !stageType.stageId) {
            dispatch(setErrorNotification("Please select stage"));
            return;
        }

        if (isVariantFetch) {
            dispatch(
                getFromLever({
                    action: getFromLever.type,
                    job: opportunityType,
                    stage: stageType,
                    createdAtStart,
                    createdAtEnd,
                    projectId: Number(id),
                    onSuccess: handleCloseModal,
                })
            );
        } else {
            dispatch(
                addToLever({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    action: addToLever.type,
                    job: opportunityType,
                    stage: stageType,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{!isVariantFetch ? "Send to Lever" : "Fetch from Lever"}</DialogTitle>

            <DialogContent>
                <Stack spacing={3} sx={{ mt: 2 }}>
                    {!isVariantFetch && (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Typography variant="body2">Select Opportunity Type:</Typography>
                            <TextField
                                select
                                value={opportunityType.type}
                                onChange={(e) => handleOpportunityChange(e.target.value as ILeverOpportunity)}
                                sx={{ width: 200 }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="GENERAL">General</option>
                                <option value="SPECIFIC">Specific Job</option>
                            </TextField>
                        </Stack>
                    )}

                    {opportunityType.type === "SPECIFIC" && (
                        <>
                            {isLeverJobsLoading ? (
                                <Stack alignItems="center">
                                    <CircularProgress size={24} />
                                </Stack>
                            ) : leverJobs?.length ? (
                                <Stack spacing={2}>
                                    <Alert severity="info">
                                        These are recent published/internal postings from the past 6 months that have
                                        not been archived.
                                    </Alert>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Typography variant="body2">Select Job:</Typography>
                                        <Autocomplete
                                            options={leverJobs.map((item) => ({
                                                label: item.text,
                                                id: item.id,
                                            }))}
                                            onChange={(_, newValue) => {
                                                setOpportunityType({
                                                    ...opportunityType,
                                                    jobId: newValue?.id || "",
                                                });
                                            }}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Select Job" sx={{ width: 200 }} />
                                            )}
                                            sx={{ width: 200 }}
                                        />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Typography variant="body2" align="center">
                                    No jobs found. Create one on your lever dashboard
                                </Typography>
                            )}
                        </>
                    )}

                    <FormControlLabel
                        control={<Switch checked={stageType.open} onChange={handleStageChange} />}
                        label={
                            <Typography variant="body2">
                                {isVariantFetch ? "Fetch" : "Map"} candidates {isVariantFetch ? "from" : "to"} specific
                                stage
                            </Typography>
                        }
                    />

                    {stageType.open && (
                        <>
                            {isLeverStagesLoading ? (
                                <Stack alignItems="center">
                                    <CircularProgress size={24} />
                                </Stack>
                            ) : leverStages?.length ? (
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography variant="body2">Select Stage:</Typography>
                                    <Autocomplete
                                        options={leverStages.map((item) => ({
                                            label: item.text,
                                            id: item.id,
                                        }))}
                                        onChange={(_, newValue) => {
                                            setStageType({
                                                ...stageType,
                                                stageId: newValue?.id || "",
                                            });
                                        }}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Stage" sx={{ width: 200 }} />
                                        )}
                                        sx={{ width: 200 }}
                                    />
                                </Stack>
                            ) : (
                                <Typography variant="body2" align="center">
                                    No stages found. Create one on your lever dashboard
                                </Typography>
                            )}
                        </>
                    )}

                    {isVariantFetch && (
                        <>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="body2">Select created after:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={createdAtStart}
                                        onChange={(newValue, ctx) => {
                                            setCreatedAtStart(newValue);
                                            const { validationError } = ctx;
                                            if (validationError === null) {
                                                setDateError(false);
                                            }
                                        }}
                                        onError={() => setDateError(true)}
                                        slotProps={{
                                            textField: {
                                                sx: {
                                                    "& .MuiInputBase-input": {
                                                        fontSize: 15,
                                                        width: 135,
                                                        py: 1,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="body2">Select created before:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={createdAtEnd}
                                        onChange={(newValue, ctx) => {
                                            setCreatedAtEnd(newValue);
                                            const { validationError } = ctx;
                                            if (validationError === null) {
                                                setDateError(false);
                                            }
                                        }}
                                        onError={() => setDateError(true)}
                                        slotProps={{
                                            textField: {
                                                sx: {
                                                    "& .MuiInputBase-input": {
                                                        fontSize: 15,
                                                        width: 135,
                                                        py: 1,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </>
                    )}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant="outline" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        isLeverSubmitting || isLeverFetching || isLeverJobsLoading || isLeverStagesLoading || dateError
                    }
                    onClick={handleLeverAdd}
                >
                    {isLeverSubmitting || isLeverFetching ? "Loading" : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
