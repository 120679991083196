import * as React from "react";

import { cn } from "@/lib/utils";

export interface ProgressProps extends React.HTMLAttributes<HTMLDivElement> {
    value?: number;
    variant?: "determinate" | "indeterminate";
    color?: "primary" | "secondary";
    thickness?: number;
}

const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(
    ({ className, value, variant = "determinate", color = "primary", thickness = 4, ...props }, ref) => {
        const rootClassName = cn(
            "relative overflow-hidden",
            variant === "determinate" ? "transition-all" : "",
            className
        );

        const barClassName = cn(
            "absolute top-0 left-0 w-full h-full transition-transform",
            color === "primary" ? "bg-cyan-600" : "bg-blue-500",
            variant === "indeterminate" ? "animate-indeterminate" : ""
        );

        return (
            <div ref={ref} className={rootClassName} style={{ height: thickness }} {...props}>
                <div
                    className={barClassName}
                    style={{
                        transform: variant === "determinate" ? `translateX(${value ? value - 100 : -100}%)` : undefined,
                    }}
                />
            </div>
        );
    }
);

Progress.displayName = "Progress";

export { Progress };
