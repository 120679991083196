import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    checkLinkedinScrapeModalOpen,
    closeLinkedinScrapingModal,
    selectFromPage,
    selectLinkedinUrl,
    selectProfileCount,
    setFromPage,
    setLinkedinUrl,
    setProfileCount,
} from "../../store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { submitSearch } from "../../store/reducers/search/search.slice";
import { validateLinkedinSalesNavUrl } from "../../utils/validateLinkedinUrl";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Modal } from "@/ui";

export function LinkedinScrapingModal() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const linkedinUrl: string = useSelector(selectLinkedinUrl);
    const profileCount: number = useSelector(selectProfileCount);
    const fromPage: number = useSelector(selectFromPage);
    const isModalOpen: boolean = useSelector(checkLinkedinScrapeModalOpen);
    const isValidLinkedinUrl = linkedinUrl ? validateLinkedinSalesNavUrl(linkedinUrl) : true;
    const isPreviewing = useSelector(checkIfLoading("addSalesNavUrlProfiles"));
    const isExpanding = useSelector(checkIfLoading("addExpandableSearch"));
    const isSubmitting = useSelector(checkIfLoading(submitSearch.type));
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const handleSubmitScrapingModalForm = (isPreview: boolean, isExpandable: boolean = false) =>
        dispatch(
            submitSearch({
                navigate,
                isFromLinkedinScraperModal: true,
                addSalesNavUrlProfiles: isPreview,
                addExpandableSearch: isExpandable,
            })
        );
    // const onProceed = (isPreview = false) => {
    //     if (!linkedinUrl || !isValidLinkedinUrl) return;
    //     handleSubmitScrapingModalForm(isPreview);
    // };
    const onProceed = (isPreview = false, isExpandable = false) => {
        if (isExpandable) {
            if (!profileCount || profileCount === 0) {
                dispatch(setErrorNotification("Profile Count must be sent"));
                return;
            } else if (profileCount > 500) {
                dispatch(setErrorNotification("Max limit is 500"));
                return;
            } else {
                handleSubmitScrapingModalForm(isPreview, isExpandable);
            }
        } else {
            if (!linkedinUrl || !isValidLinkedinUrl) return;
            handleSubmitScrapingModalForm(isPreview);
        }
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                dispatch(closeLinkedinScrapingModal());
            }}
        >
            <h2 className="text-lg text-center font-semibold text-gray-800 w-[500px] mb-4">
                Upload LinkedIn Sales Navigator URL
            </h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onProceed();
                }}
            >
                <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium mb-1">Enter url</label>
                    <input
                        required
                        type="text"
                        placeholder="LinkedIn Sales Navigator URL"
                        value={linkedinUrl}
                        onChange={(e) => dispatch(setLinkedinUrl(e.target.value))}
                        className={` w-full text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2 
                        ${
                            !isValidLinkedinUrl
                                ? "border-red-500 focus:ring-red-500"
                                : "border-gray-300 focus:ring-blue-500"
                        }`}
                        style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                    />
                    {!isValidLinkedinUrl && (
                        <span className="text-xs text-red-500 mb-3">Invalid LinkedIn Sales Navigator URL</span>
                    )}
                    <div className="flex flex-row mt-3">
                        <div className="flex flex-col mr-[15px]">
                            <label className="text-sm font-medium mb-1">From page</label>
                            <input
                                required
                                type="number"
                                placeholder="From page"
                                value={fromPage}
                                onChange={(e) => dispatch(setFromPage(parseInt(e.target.value)))}
                                min="1"
                                className="text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                        </div>
                        <div className="flex flex-col flex-1">
                            <label className="text-sm font-medium mb-1">Profile count</label>
                            <input
                                required
                                type="number"
                                placeholder="Profile count"
                                value={profileCount}
                                onChange={(e) => dispatch(setProfileCount(parseInt(e.target.value)))}
                                min="1"
                                className="text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-end space-x-2 mt-3">
                    <Button
                        variant="outline"
                        onClick={() => {
                            dispatch(closeLinkedinScrapingModal());
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="outline"
                        disabled={isPreviewing}
                        onClick={() => {
                            onProceed(true);
                        }}
                    >
                        {isPreviewing ? "Previewing..." : "Preview"}
                    </Button>
                    {isSuperAdmin && (
                        <Button
                            variant="outline"
                            disabled={isExpanding}
                            onClick={() => {
                                onProceed(false, true);
                            }}
                        >
                            {" "}
                            {isExpanding ? "Expanding..." : "Expand"}
                        </Button>
                    )}

                    <Button onClick={() => onProceed(false)} disabled={isSubmitting}>
                        {isSubmitting ? "Processing..." : "Proceed"}
                    </Button>
                </div>
            </form>
        </Modal>
    );
}
