import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "../../Signin/Signin.reducer";

import { Button, Modal, Typography } from "@/ui";

export default function JazzHRConnect({ onClose }: { onClose: () => void }) {
    const user: any = useSelector(selectUser);

    const {
        companyName,
        companyUrl,
        linkedinUrl,
        name,
        outboundEmail,
        userEmail,
        workspaceName,
        role,
        signature,
    }: any = user;
    const isCredsSubmitting = useSelector(checkIfLoading(updateUser.type));

    const dispatch = useDispatch();

    const [JazzHR, setJazzHR] = useState({
        jazzHRFeedURL: user.jazzHR?.jazzHRFeedURL,
        jazzHRAPIKey: user.jazzHR?.jazzAPIKey,
    });

    useEffect(() => {
        setJazzHR({
            jazzHRFeedURL: user.jazzHR?.jazzHRFeedURL ?? "",
            jazzHRAPIKey: user.jazzHR?.jazzHRAPIKey ?? "",
        });
    }, [user.jazzHR]);

    const handleSubmit = () => {
        if (JazzHR.jazzHRAPIKey?.length === 0) {
            dispatch(setErrorNotification("Please enter jazzHR API Key "));
            return;
        }
        if (JazzHR.jazzHRFeedURL?.length === 0) {
            dispatch(setErrorNotification("Please enter jazzHR Feed URL "));
            return;
        }

        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                action: updateUser.type,
                jazzHR: JazzHR,
                onSuccess: onClose,
            })
        );
    };

    return (
            <Modal open={true} onClose={onClose} disableBackdropClick>
                <div style={{ minWidth: "500px" }}>
                    <h2 className="text-xl font-semibold mb-5">Connect JazzHR</h2>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">JazzHR Feed Url</Typography>
                        <input
                            type="text"
                            placeholder="Enter JazzHR Feed Url"
                            value={JazzHR.jazzHRFeedURL}
                            onChange={(e) =>
                                setJazzHR((prev) => ({
                                    ...prev,
                                    jazzHRFeedURL: e.target.value,
                                }))
                            }
                            className=" min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Api Key</Typography>
                        <input
                            type="text"
                            placeholder="Enter JazzHR Api Key"
                            value={JazzHR.jazzHRAPIKey}
                            onChange={(e) =>
                                setJazzHR((prev) => ({
                                    ...prev,
                                    jazzHRAPIKey: e.target.value,
                                }))
                            }
                            className="min-w-[300px] text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                    <div className="flex justify-end space-x-2 mt-6">
                        <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                        <Button
                            variant="default"
                            onClick={handleSubmit}
                            disabled={isCredsSubmitting}
                            className="rounded-sm"
                            label={isCredsSubmitting ? "Submitting..." : "Submit"}
                        />
                    </div>
                </div>
            </Modal>
    );
}
