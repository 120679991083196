import { useParams } from "react-router-dom";

import Project from "../pages/project/project";
import ProjectWalkthrough from "../pages/projectWalkthrough/projectWalkthrough";

import styles from "@/container/AppContainer/AppContainer.module.css";

export default function SampleDataRoute() {
    const { id } = useParams();

    if (Number(id) === 9999) {
        return <ProjectWalkthrough />;
    }

    return (
        <div
            className={styles["projectPageContainer"]}
        >
            <Project />
        </div>
    );
}
