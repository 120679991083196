import { DialogTitle, Divider, ModalClose, ModalDialog } from "@mui/joy";
import JoyModal, { ModalProps } from "@mui/joy/Modal";
import { ReactNode } from "react";

import { JoyProvider } from "../JoyProvider";

interface modalPropsType extends Omit<ModalProps, 'children'> {
    title?: string;
    open: boolean;
    onClose: () => void;
    children?: ReactNode;
    width?: number | string;
    titleDivider?: boolean;
    className?: string;
    titleStyle?: React.CSSProperties;
}

/**
 * @function Modal
 * @description A Joy UI Modal component.
 * @param {Object} props Component props
 * @param {string} [props.title] Modal title
 * @param {boolean} props.open Whether the modal is open
 * @param {Function} props.onClose Callback when modal is closed
 * @param {ReactNode} [props.children] Modal children
 * @param {number|string} [props.width] Modal width
 * @param {boolean} [props.titleDivider] Whether to show a divider below the title
 * @param {string} [props.className] Additional class name for the modal
 * @param {React.CSSProperties} [props.titleStyle] Additional styles for the title
 * @returns {ReactElement} Modal component
 */
export default function Modal(props: modalPropsType) {
    const { title, open, onClose, children, width, titleDivider, className, titleStyle = {}, ...rest } = props;

    return (
        <JoyProvider>
            <JoyModal
                open={open}
                onClose={onClose}
                sx={{
                    overflow: "hidden",
                }}
            >
                <ModalDialog
                    sx={{
                        overflowY: "auto",
                        padding: 3,
                        width: width ? width : "auto",
                    }}
                >
                    <ModalClose />
                    <DialogTitle level="h3" variant="plain">
                        {title}
                    </DialogTitle>
                    {titleDivider ? <Divider /> : null}
                    {children}
                </ModalDialog>
            </JoyModal>
        </JoyProvider>
    );
}
