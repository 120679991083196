import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../..";
import {
    AutoSearchLink,
    AutoSearchLinkKeys,
    AutoSearchStatus,
    ScrapeType,
} from "@/store/reducers/edit-auto-search/editAutoSearch.types";

export type AutoSearchState = {
    project: number;
    scrapeLinks: {
        [key: string]: AutoSearchLink;
    };
};

export const initialAutoSearchLink: AutoSearchLink = {
    _id: "",
    completed: false,
    cookie: "",
    maxPagesToScrape: 0,
    pagesScraped: 0,
    profilesScraped: 0,
    type: "LINKEDIN",
    url: "",
};

const initialState: AutoSearchState = {
    project: -1,
    scrapeLinks: {},
};

type ScrapeLinkId = {
    _id: string;
};

type AutoSearchLinkStringPayload = PayloadAction<
    ScrapeLinkId & {
        value: string;
    }
>;

type MaxPagesToScrapePayload = PayloadAction<ScrapeLinkId & { value: number }>;

type ChangeAutoSearchTypePayload = PayloadAction<ScrapeLinkId & { type: ScrapeType }>;

type DeleteAutoSearchLinkPayload = PayloadAction<ScrapeLinkId>;

export type SubmitAutoSearchLinksPayload = PayloadAction<{
    id: number;
    scrapeLinks: AutoSearchLink[];
}>;

export const editAutoSearchSlice = createSlice({
    name: "editAutoSearch",
    initialState,
    reducers: {
        addAutoSearchLink(state) {
            const id = nanoid();
            const link = { ...initialAutoSearchLink, _id: id };
            state.scrapeLinks[id] = link;
        },
        deleteAutoSearchLink(state, action: DeleteAutoSearchLinkPayload) {
            const { _id } = action.payload;

            delete state.scrapeLinks[_id];
        },
        changeURL(state, action: AutoSearchLinkStringPayload) {
            const { _id, value } = action.payload;
            console.log({ _id, value });
            state.scrapeLinks[_id].url = value;
        },
        changeCookie(state, action: AutoSearchLinkStringPayload) {
            const { _id, value } = action.payload;
            state.scrapeLinks[_id].cookie = value;
        },
        changeAutoSearchType(state, action: ChangeAutoSearchTypePayload) {
            const { _id, type } = action.payload;
            state.scrapeLinks[_id].type = type;
        },
        changeMaxPagesToScrape(state, action: MaxPagesToScrapePayload) {
            const { _id, value } = action.payload;
            state.scrapeLinks[_id].maxPagesToScrape = value;
        },
        resetEditAutoSearchValues(state) {
            return initialState;
        },
        setAutoSearchState(state, action: PayloadAction<AutoSearchLink[]>) {
            action.payload.forEach((item) => {
                const { _id } = item;
                state.scrapeLinks[_id] = { ...item };
            });
        },
        submitAutoSearchDetails(state, action: SubmitAutoSearchLinksPayload) {},
        setEditAutoSearchId(state, action: PayloadAction<number>) {
            state.project = action.payload;
        },
        fetchAutoSearchDetails(state, action: PayloadAction<number>) {},
        cancelSagas(state) {},
    },
});

export default editAutoSearchSlice.reducer;

export const {
    deleteAutoSearchLink,
    addAutoSearchLink,
    changeAutoSearchType,
    changeCookie,
    changeMaxPagesToScrape,
    changeURL,
    setEditAutoSearchId,
    fetchAutoSearchDetails,
    cancelSagas,
    setAutoSearchState,
    resetEditAutoSearchValues,
    submitAutoSearchDetails,
} = editAutoSearchSlice.actions;

export const selectEditAutoSearchProjectId = (state: RootState) => state.editAutoSearch.project;

export const selectAutoSearchLinks = (state: RootState) => state.editAutoSearch.scrapeLinks;
