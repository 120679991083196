import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";

import { Button } from "@/ui";

export default function NavigateBackButton(props: any) {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <Button
            variant="default"
            color="info"
            onClick={goBack}
            startDecorator={<NavigateBeforeIcon style={{fontSize:"20x"}}  />}
        >
            {props?.children ?? "Go Back"}
        </Button>
    );
}
