import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { fetchBaseQueryWithZod } from "../utils";

enum EQueryKeys {
    TYPE_AHEAD_OPTIONS = "type_ahead_options",
}

export const searchApi = createApi({
    reducerPath: "searchApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [EQueryKeys.TYPE_AHEAD_OPTIONS],
    endpoints: (builder) => ({
        getTypeAheadOptions: builder.query<any, { label: string; value: string; category: string }>({
            query: (payload) => ({
                url: `/v2/linkedin-type-ahead/get-type-ahead?${queryString.stringify(payload)}`,
                method: "GET",
            }),
            keepUnusedDataFor: 30,
            transformResponse(baseQueryReturnValue: any) {
                return baseQueryReturnValue.data;
            },
            providesTags: [EQueryKeys.TYPE_AHEAD_OPTIONS],
        }),
    }),
});

export const { useGetTypeAheadOptionsQuery } = searchApi;
