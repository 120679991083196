import { useSelector } from "react-redux";

import IntegrationsData from "./components/IntegrationData";

import Navbar from "../../components/Navbar/Navbar";
import { selectUser } from "../Signin/Signin.reducer";

export default function Integrations() {
    const user = useSelector(selectUser);
    return (
        <div style={{ height: user.emailAuthorized ? "calc(100vh - 60px)" : "calc(100vh - 86px)", overflowY: "auto" }}>
            <Navbar />
            <IntegrationsData />
        </div>
    );
}
