import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

import { tooltipSetting } from "@/utils/tooltip";
import { ReactNode } from "react";

import { cn } from "@/lib/utils";

interface ListItemComponentProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
    active?: boolean;
    title?: ReactNode;
    divider?: boolean;
    sx?: string;
    disableHoverEffect?: boolean;
    loading?: string;
    children: ReactNode;
}

export default function ListItemComponent(props: ListItemComponentProps) {
    const { children, disableHoverEffect, active, loading = "false", title, divider = false, sx, ...rest } = props;

    const commonStyles = {
        alignItems: "center",
        justifyContent: "space-between",
    };

    if (loading === "true") return <Skeleton height={40} width={"100%"} animation="wave" />;

    const listItemClasses = cn(
        "flex flex-row items-center justify-between py-2 px-4",
        divider && "border-b border-gray-300",
        !disableHoverEffect && !active && "hover:bg-gray-100 transition-colors duration-600",
        active && "bg-gray-200",
        sx
    );

    return (
        <Tooltip {...tooltipSetting} followCursor title={title}>
            <div
                className={listItemClasses}
                style={{
                    ...(active && { backgroundColor: "rgba(0,0,0,0.1)" }),
                    ...commonStyles,
                }}
                {...rest}
            >
                {children}
            </div>
        </Tooltip>
    );
}
