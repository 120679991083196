import { SyntheticEvent, useEffect } from "react";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import { Experimental_CssVarsProvider } from "@mui/material";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { JoyProvider } from "../../components/JoyProvider";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { candidateReachoutsTabs, invertedCandidateReachoutsTabs } from "../../utils/Constants";
import {
    getContactReachout,
    selectCandidatesReachoutsTab,
    setCandidatesReachoutTab,
} from "./all-candidates-reachout.slice";
import { CandidateReachoutTabs } from "./all-candidates-reachout.types";
import { Activity } from "./components/Activity";
import ContactInfo from "./components/ContactInfo";
import { Insights } from "./components/Insights";
import { Interactions } from "./components/Interactions";
import { References } from "./components/ReferencesNew";

export function AllCandidatesReachOutBodyContainer({ children }: { children: React.ReactNode }) {
    return <Stack sx={{ width: "100%" }}>{children}</Stack>;
}

export function CandidateReachOutTabs() {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const tab = useSelector(selectCandidatesReachoutsTab);

    useEffect(() => {
        const activityId = searchParams.get("activityId");
        if (activityId) {
            const value = invertedCandidateReachoutsTabs["activity"];

            dispatch(setCandidatesReachoutTab(value));
        }
    }, [searchParams]);

    const handleChange = (_: SyntheticEvent<Element, Event> | null, newValue: string | number | null) => {
        if (typeof newValue === "number") {
            const tabValue = newValue as CandidateReachoutTabs;
            const value = candidateReachoutsTabs[tabValue];
            if (value) {
                dispatch(setCandidatesReachoutTab(tabValue));
            }
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateRows: "50px 1fr",
                gridTemplateColumns: "1fr",
            }}
        >
            <Tabs value={tab} onChange={handleChange} sx={{ bgcolor: "transparent" }}>
                <TabList
                    sx={{
                        pl: 2,
                        [`&& .${tabClasses.root}`]: {
                            bgcolor: "transparent",
                            [`&.${tabClasses.selected}`]: {
                                "&::after": {
                                    height: "2px",
                                    bgcolor: "#0891b2",
                                },
                            },
                        },
                    }}
                >
                    {Object.values(candidateReachoutsTabs).map((k) => {
                        return (
                            <Tab key={k} sx={{ p: 1 }}>
                                {capitalize(k)}
                            </Tab>
                        );
                    })}
                </TabList>
                <TabPanel value={0} sx={{ p: 0 }}>
                    <Activity />
                </TabPanel>
                <TabPanel value={1} sx={{ p: 0 }}>
                    <Insights />
                </TabPanel>
                <TabPanel value={2} sx={{ p: 0 }}>
                    <Experimental_CssVarsProvider>
                        <Interactions />
                    </Experimental_CssVarsProvider>
                </TabPanel>
                <TabPanel value={3} sx={{ p: 0 }}>
                    <References />
                </TabPanel>
            </Tabs>
        </Box>
    );
}

const AllCandidatesReachout = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(getContactReachout.type));

    useEffect(() => {
        if (!params?.id) return;
        dispatch(getContactReachout(params.id));
    }, [params?.id]);

    return (
        <JoyProvider>
            <Stack height="100%">
                <Experimental_CssVarsProvider>
                    <Navbar />
                </Experimental_CssVarsProvider>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box
                        flexDirection="row"
                        height="100%"
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "minmax(380px, 1fr) 2.5fr",
                            gridTemplateRows: "1fr",
                            gap: "10px",
                            borderRadius: "0.7rem",
                        }}
                    >
                        <ContactInfo />
                        <Stack
                            flex={2}
                            sx={(theme) => ({
                                backgroundColor: theme.palette.common.white,
                                border: `1px solid ${theme.palette.divider}`,
                                borderRadius: "0.3rem",
                            })}
                        >
                            <CandidateReachOutTabs />
                        </Stack>
                    </Box>
                )}
            </Stack>
        </JoyProvider>
    );
};

export default AllCandidatesReachout;
