import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import IconButton from "@mui/joy/IconButton";
import { Typography } from "@mui/material";

import GiftIcon from "../../../../assets/img/giftIcon.svg";

import { Button } from "@/ui";

const DetailedView = ({ detailedView, setOpenSendGiftModal, handleBack }) => {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <IconButton sx={{ m: 1 }} onClick={() => handleBack()}>
                    <ArrowBackIosNewOutlinedIcon fontSize="small" />
                    <Typography sx={{ fontWeight: 300, ml: 1, fontSize: "0.8rem" }}>Back to gifts</Typography>
                </IconButton>
            </div>
            <div style={{ display: "flex" }}>
                <img
                    width="500px"
                    height={500}
                    style={{ margin: "20px" }}
                    src={detailedView?.variants[0]?.images[0]?.url ?? GiftIcon}
                    // loading="lazy"
                    alt=""
                />

                <div style={{ display: "flex", margin: "40px", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "1.6rem", fontWeight: 600 }}>{detailedView?.name}</Typography>
                    <Typography sx={{ fontSize: "1rem", fontWeight: 300 }}>{detailedView?.description}</Typography>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <Typography sx={{ fontSize: "1.2rem", fontWeight: 500 }}>
                            $ {detailedView?.variants[0].estimated_total_price?.price_per_unit}
                        </Typography>
                    </div>
                    <Typography sx={{ fontSize: "0.6rem", fontWeight: 300 }}>* Estimated all-in cost</Typography>
                    <Typography sx={{ fontSize: "0.8rem", fontWeight: 500, mt: 2 }}>
                        {" "}
                        How this works for your recipient
                        <a
                            href="https://docs.hirequotient.com/en/articles/9718280-use-sendoso-on-easysource"
                            target="_blank"
                            rel="noreferrer"
                            className="text-cyan-700 ml-1"
                        >
                            Read more here
                        </a>
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", fontWeight: 300, fontStyle: "italic", mt: 1 }}>
                        Step 1: Once you confirm purchase, your gift recipient will receive an email from{" "}
                        <a href="mailto:mail@gift.sendoso.com" className="text-cyan-700 m-1">
                            mail@gift.sendoso.com
                        </a>
                        with your name and company.
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", fontWeight: 300, fontStyle: "italic", mt: 1 }}>
                        Step 2: Your recipient can then choose to accept the gift and share their address
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", fontWeight: 300, fontStyle: "italic", mt: 1 }}>
                        Step 3: You will receive a gift invoice from HireQuotient for the corresponding amount, which
                        you can pay
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", fontWeight: 300, fontStyle: "italic", mt: 1 }}>
                        Step 4: You can track order status directly on the candidate card or enquire on{" "}
                        <a href="mailto:customer-support@hirequotient.com" className="text-cyan-700 m-1">
                            customer-support@hirequotient.com
                        </a>
                        , till your gift is delivered successfully
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", fontWeight: 400, fontStyle: "italic", mt: 1 }}>
                        Congratulations! You have successfully selected a gift via EasySource using Sendoso
                    </Typography>
                    <Button className="h-[36px] w-[200px] mt-[20px]" onClick={() => setOpenSendGiftModal(true)}>
                        Send Gift Now
                    </Button>
                </div>
            </div>
        </>
    );
};
export default DetailedView;
