import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { useSaveMultipleOutreachTemplates } from "../hooks/useSaveOutreach.hook";

import { useWarningTemplatesSaveMutation } from "@/store/apis/outreach/outreach.api";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { selectIsWarningModalOpen, setWarningModalOpen } from "@/store/reducers/outreach/outreach.slice";

export function WarningModal() {
    const dispatch = useDispatch();
    const { projectId } = useOutreachParams();
    const isWarningModalOpen = useSelector(selectIsWarningModalOpen);
    const [warningTemplatesSave, { isLoading: isSaveLoading }] = useWarningTemplatesSaveMutation();

    const {
        saveAllTemplates,
        saveMutationState: { isLoading: isWorkflowLoading },
    } = useSaveMultipleOutreachTemplates({
        successCallback: () => {
            dispatch(setSuccessNotification("Template saved successfully"));
        },
    });

    const saveTemplates = async () => {
        await onClose(); // Wait for onClose to complete.
        saveAllTemplates(false); // Call saveAllTemplates after onClose finishes.
    };

    const onClose = async () => {
        return new Promise<void>((resolve) => {
            const payload = {
                projectId: Number(projectId),
                warningIgnoredByUser: true,
            };

            // Call the function for saving warning templates.
            warningTemplatesSave(payload);

            // Dispatch the action to close the modal.
            dispatch(setWarningModalOpen({ duplicates: [], open: false }));

            // Resolve the promise after everything is completed.
            resolve();
        });
    };

    if (!isWarningModalOpen?.open) return null;

    return (
        <BaseModal onClose={onClose} overlayStyles={{ maxWidth: "400px" }}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography sx={{ fontSize: "16px" }} variant="h6">
                        System detected the following templates are set to default or sample text:
                    </Typography>

                    {isWarningModalOpen?.duplicates?.length > 0 && (
                        <Stack gap={0.5}>
                            {isWarningModalOpen.duplicates.map((template, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    sx={{ fontSize: "14px", color: "text.secondary", marginLeft: 2 }}
                                >
                                    • {template}
                                </Typography>
                            ))}
                        </Stack>
                    )}

                    <Typography sx={{ mt: 2 }} variant="body1">
                        Do you still want to save your workflow?
                    </Typography>
                </Stack>

                <Stack gap={1.3} flexDirection="row" mt={1}>
                    <CustomButton
                        onClick={saveTemplates}
                        variant="contained"
                        disabled={isWorkflowLoading}
                        sx={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        Yes,Save Anyway
                    </CustomButton>

                    <CustomButton
                        onClick={onClose}
                        disabled={isSaveLoading}
                        variant="outlined"
                        sx={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        No,Continue Editing
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
