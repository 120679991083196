import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";

import useSearchState from "../../../../hooks/useSearchState";
import AutocompleteWithExclusion from "../../../../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectFilter, setValues } from "../../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../../store/reducers/search/search.types";

interface GeographyProps {
    id: SearchStateKeys;
    multiple?: boolean;
    freeSolo?: boolean;
}

export function BSAutocompleteGeography({ id, multiple = true, freeSolo = false }: GeographyProps) {
    const { query, setQuery, isLoading, dispatch } = useSearchState(id);

    const { values, options, error } = useSelector(selectFilter(id));

    const handleChange = (value: string | null) => {
        dispatch(
            setValues({
                key: id,
                value: [{ value: value || "", excluded: false }],
            })
        );
    };

    if (!multiple) {
        return (
            <Autocomplete
                fullWidth
                size="small"
                ChipProps={{ color: "success" }}
                value={values[0]?.value || ""}
                options={Object.keys(options)}
                loading={isLoading}
                onChange={(_, value) => {
                    handleChange(value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{ sx: { fontSize: 14 } }}
                        label="Location"
                        placeholder="Location"
                        value={query}
                        error={Boolean(error)}
                        helperText={error}
                        onChange={(e) => setQuery(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                            sx: { fontSize: 14 },
                        }}
                    />
                )}
            />
        );
    }

    const handleChangeMultiSelect = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value: value || "",
            })
        );
    };

    return (
        <AutocompleteWithExclusion
            multiple={multiple}
            freeSolo={freeSolo}
            size="small"
            label="Geography"
            placeholder="Geography"
            value={values}
            options={Object.keys(options)}
            onChange={handleChangeMultiSelect}
            error={error}
            loading={isLoading}
            inputValue={query}
            onInputChange={setQuery}
            fullWidth
        />
    );
}
